// import React, {useState, useEffect} from 'react'
// import CommonTable from '../../../../ReUsableComponent/CommonTable'
// import axios from 'axios'
// import {useParams} from 'react-router-dom'
// import whiteplus from '../../EHRIcon/whiteplus.svg'
// import Modal from 'react-bootstrap/Modal'

// const AddVaccination = ({onShow, onHide, onClick}) => {
//   const APIkey = process.env.REACT_APP_SERVERAPI

//   console.log(APIkey)

//   const {tab} = useParams()
//   const [data, setData] = useState([])
//   const [perPage, setPerPage] = useState(10)
//   const [currentPage, setCurrentPage] = useState(1)
//   const [searchQuery, setSearchQuery] = useState('')
//   const [showModal, setShowModal] = useState(false)
//   const [modalData, setModalData] = useState([])
//   const [loadingModalData, setLoadingModalData] = useState(false)
//   const [selectedId, setSelectedId] = useState<any>([])

//   const [searchCriteria, setSearchCriteria] = useState({
//     vaccineName: '',
//     diseaseTargeted: '',
//     manufacture: '',
//     dosage: '',
//     typeOfVaccine: '',
//   })

//   useEffect(() => {
//     fetchVaccineData()
//   }, [])

//   const handleRowClick = (id) => {
//     console.log('Row clicked with ID:', id)
//   }

//   const handleCloseModal = () => {
//     onHide()
//   }

//   //onclicksave

//   const Component = ({id}) => {
//     const handleCheckbox = async (e) => {
//       let {value, checked} = e.target

//       if (checked) {
//         setSelectedId((prev) => [...prev, value])
//       } else {
//         setSelectedId((prev) => prev?.filter((item) => item !== value))
//       }
//     }

//     return (
//       <input
//         type='checkbox'
//         value={id}
//         checked={selectedId?.some((item: any) => item == id)}
//         onChange={(e) => handleCheckbox(e)}
//       />
//     )
//   }
//   console.log('selectedId : ', selectedId)

//   const AddVaccinecolumns = [
//     {key: ['srNo'], header: 'Sr.No'},
//     {
//       key: ['select'],
//       header: 'Select',
//       isAction: true,
//       ActionComponent: Component,
//     },
//     {key: ['vaccineName'], header: 'Vaccine Name'},
//     {key: ['diseasesTargeted'], header: 'Diseases Targeted'},
//     {key: ['manufacturer'], header: 'Manufacturer Name'},
//     {key: ['dosage'], header: 'Dosage'},
//     {key: ['vaccineType'], header: 'Types Of Vaccine'},
//   ]

//   const fetchVaccineData = async () => {
//     setLoadingModalData(true) // Show loading indicator before starting the fetch
//     try {
//       const getloginInfo = localStorage.getItem('appLoginInfo')
//       if (getloginInfo) {
//         const Token = JSON.parse(getloginInfo).data.accessToken
//         const header = {headers: {'x-access-token': Token}}

//         const response = await axios.get(`${APIkey}/api/admin/getVaccinationList`, header)
//         const responseData = response.data.data

//         const formattedData = responseData.map((item, index) => ({
//           srNo: index + 1,
//           vaccineName: item.vaccineName,
//           manufacturer: item.manufacture,
//           vaccineType: item.typeOfVaccine,
//           diseasesTargeted: item.diseaseTargeted,
//           dosage: item.dosageSchedule,
//           _id: item._id,
//         }))

//         setModalData(formattedData)
//       } else {
//         console.log('No login info found in localStorage')
//       }
//     } catch (error) {
//       console.error('Error fetching vaccination data:', error)
//     } finally {
//       setLoadingModalData(false) // Hide loading indicator after fetching is complete
//     }
//   }

//   const handleSearchCriteriaChange = (e) => {
//     const {name, value} = e.target
//     setSearchCriteria((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }))
//   }

//   const handleSearch = async () => {
//     setLoadingModalData(true)
//     try {
//       const getloginInfo = localStorage.getItem('appLoginInfo')
//       if (getloginInfo) {
//         const Token = JSON.parse(getloginInfo).data.accessToken
//         const header = {headers: {'x-access-token': Token}}

//         // Filter out empty search criteria before sending the request
//         const nonEmptyCriteria = {}
//         Object.keys(searchCriteria).forEach((key) => {
//           if (searchCriteria[key].trim() !== '') {
//             nonEmptyCriteria[key] = searchCriteria[key]
//           }
//         })

//         const response = await axios.get(`${APIkey}/api/admin/searchVaccination`, {
//           params: nonEmptyCriteria,
//           ...header,
//         })

//         const responseData = response.data.data
//         const searchData = responseData.map((item, index) => ({
//           srNo: index + 1,
//           vaccineName: item.vaccineName,
//           manufacturer: item.manufacture,
//           vaccineType: item.typeOfVaccine,
//           diseasesTargeted: item.diseaseTargeted,
//           dosage: item.dosageSchedule,
//           _id: item._id,
//         }))
//         setModalData(searchData)
//       } else {
//         console.log('No Login Info found in Local Storage')
//       }
//     } catch (error) {
//       console.log('Error fetching vaccination data:', error)
//     } finally {
//       setLoadingModalData(false)
//     }
//   }

//   const resetFun = () => {
//     setSearchCriteria({
//       vaccineName: '',
//       diseaseTargeted: '',
//       manufacture: '',
//       dosage: '',
//       typeOfVaccine: '',
//     })
//   }

//   const saveData = async () => {
//     try {
//       const getloginInfo = localStorage.getItem('appLoginInfo')
//       if (getloginInfo) {
//         const Token = JSON.parse(getloginInfo).data.accessToken
//         const headers = {'x-access-token': Token}

//         const response = await axios.post(
//           `${APIkey}/api/admin/createPreferredVaccination`,
//           {vaccinationId: selectedId}, // IDs to post
//           {headers}
//         )
//         console.log('API response:', response)
//         if (response) {
//           onHide()
//         }
//       } else {
//         console.log('No login info found in localStorage')
//       }
//     } catch (error) {
//       console.error('Error sending data to the API:', error)
//     }
//   }
//   return (
//     <>
//        <Modal show={onShow} onHide={handleCloseModal} size='lg' centered>
//         <Modal.Header className='h-25px dialoge align-items-center pb-3'>
//           <div className='title-header'>Add Vaccine</div>
//         </Modal.Header>
//         <Modal.Body className='p-2 dialoge'>
//           <div className='clinical-time-container p-2'>
//             <div className='d-flex gap-3'>
//               <div className='mb-3'>
//                 <div className='addvaccinetitle'>Vaccine Name:</div>
//                 <input
//                   type='text'
//                   name='vaccineName'
//                   value={searchCriteria.vaccineName}
//                   className='SearchInputbox'
//                   placeholder=''
//                   onChange={handleSearchCriteriaChange}
//                 />
//               </div>

//               <div className='mb-3'>
//                 <div className='addvaccinetitle'>Diseases Targeted:</div>
//                 <input
//                   type='text'
//                   className='SearchInputbox'
//                   name='diseaseTargeted'
//                   value={searchCriteria.diseaseTargeted}
//                   placeholder=''
//                   onChange={handleSearchCriteriaChange}
//                 />
//               </div>

//               <div className='mb-3'>
//                 <div className='addvaccinetitle'>Manufacturer Name:</div>
//                 <input
//                   type='text'
//                   className='SearchInputbox'
//                   name='manufacture'
//                   value={searchCriteria.manufacture}
//                   placeholder=''
//                   onChange={handleSearchCriteriaChange}
//                 />
//               </div>

//               <div className='mb-3'>
//                 <div className='addvaccinetitle'>Dosage:</div>
//                 <input
//                   type='text'
//                   className='SearchInputbox'
//                   name='dosage'
//                   value={searchCriteria.dosage}
//                   placeholder=''
//                   onChange={handleSearchCriteriaChange}
//                 />
//               </div>

//               <div className='mb-3'>
//                 <div className='addvaccinetitle'>Type of Vaccine:</div>
//                 <input
//                   type='text'
//                   className='SearchInputbox'
//                   placeholder=''
//                   name='typeOfVaccine'
//                   value={searchCriteria.typeOfVaccine}
//                   onChange={handleSearchCriteriaChange}
//                 />
//               </div>
//             </div>
//             <div>
//               <div className='mybtns'>
//                 <button type='button' onClick={handleSearch} className='search-orange-btn'>
//                   Search
//                 </button>
//                 <button
//                   type='button'
//                   className='clear-grey-btn'
//                   onClick={() => [console.log('clear btn '), resetFun()]}
//                 >
//                   Clear
//                 </button>
//               </div>
//             </div>
//           </div>

//           <div className='clinical-time-container mt-2'>
//             {loadingModalData ? (
//               <p>Loading...</p>
//             ) : (
//               <CommonTable
//                 data={modalData}
//                 columns={AddVaccinecolumns}
//                 currentPage={currentPage}
//                 perPage={perPage}
//                 totalCount={modalData.length}
//                 setCurrentPage={setCurrentPage}
//                 setPerPage={setPerPage}
//                 searchQuery={searchQuery}
//                 setQuery={setSearchQuery}
//                 onRowClick={handleRowClick}
//                 headerColor='#eb2226'
//               />
//             )}
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <button type='button' className='primarybtnBig' onClick={saveData} >
//             Save
//           </button>
//           <button type='button' className='tertiarybig' onClick={handleCloseModal}>
//             Clear
//           </button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   )
// }

// export default AddVaccination

import React, {useState, useEffect} from 'react'
import CommonTable from '../../../../ReUsableComponent/CommonTable'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import tablecolor from '../../../../ReUsableComponent/ReyClinicalinputs.json'

const AddVaccination = ({onShow, onHide, onClick}) => {
  const APIkey = process.env.REACT_APP_SERVERAPI
  const {tab} = useParams()
  const [data, setData] = useState([])
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState([])
  const [loadingModalData, setLoadingModalData] = useState(false)
  const [selectedId, setSelectedId] = useState<string[]>([])

  const [searchCriteria, setSearchCriteria] = useState({
    vaccineName: '',
    diseaseTargeted: '',
    manufacture: '',
    dosage: '',
    typeOfVaccine: '',
  })

  useEffect(() => {
    fetchVaccineData()
  }, [])

  const handleRowClick = (id) => {
    console.log('Row clicked with ID:', id)
  }

  const handleCloseModal = () => {
    onHide()
  }

  const Component = ({id}) => {
    const handleCheckbox = (e) => {
      const {value, checked} = e.target

      if (checked) {
        setSelectedId((prev) => {
          if (!prev.includes(value)) {
            return [...prev, value]
          }
          return prev
        })
      } else {
        setSelectedId((prev) => prev.filter((item) => item !== value))
      }
    }

    return (
      <input
        type='checkbox'
        value={id}
        checked={selectedId.some((item) => item === id)}
        onChange={(e) => handleCheckbox(e)}
      />
    )
  }

  const AddVaccinecolumns = [
    {key: ['srNo'], header: 'Sr.No'},
    {
      key: ['select'],
      header: 'Select',
      isAction: true,
      ActionComponent: Component,
    },
    {key: ['vaccineName'], header: 'Vaccine Name'},
    {key: ['diseasesTargeted'], header: 'Diseases Targeted'},
    {key: ['manufacturer'], header: 'Manufacturer Name'},
    {key: ['dosage'], header: 'Dosage'},
    {key: ['vaccineType'], header: 'Types Of Vaccine'},
  ]

  const fetchVaccineData = async () => {
    setLoadingModalData(true) // Show loading indicator before starting the fetch
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const headers = {'x-access-token': Token}

        const response = await axios.get(`${APIkey}/api/admin/getVaccinationList`, {headers})
        const responseData = response.data.data

        const formattedData = responseData.map((item, index) => ({
          srNo: index + 1,
          vaccineName: item.vaccineName,
          manufacturer: item.manufacture,
          vaccineType: item.typeOfVaccine,
          diseasesTargeted: item.diseaseTargeted,
          dosage: item.dosageSchedule,
          _id: item._id,
        }))

        setModalData(formattedData)
      } else {
        console.log('No login info found in localStorage')
      }
    } catch (error) {
      console.error('Error fetching vaccination data:', error)
    } finally {
      setLoadingModalData(false) // Hide loading indicator after fetching is complete
    }
  }

  const handleSearchCriteriaChange = (e) => {
    const {name, value} = e.target
    setSearchCriteria((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSearch = async () => {
    setLoadingModalData(true)
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      console.log('SearchCt', searchCriteria)
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        console.log('token', Token)

        const header = {
          headers: {'x-access-token': Token},
        }

        // Filter out empty search criteria before sending the request

        const response = await axios.post(
          `${APIkey}/api/admin/searchVaccination`,
          searchCriteria,
          header
        )

        const responseData = response.data.data
        const searchData = responseData.map((item, index) => ({
          srNo: index + 1,
          vaccineName: item.vaccineName,
          manufacturer: item.manufacture,
          vaccineType: item.typeOfVaccine,
          diseasesTargeted: item.diseaseTargeted,
          dosage: item.dosageSchedule,
          _id: item._id,
        }))
        setModalData(searchData)
      } else {
        console.log('No Login Info found in Local Storage')
      }
    } catch (error) {
      console.log('Error fetching vaccination data:', error)
    } finally {
      setLoadingModalData(false)
    }
  }

  const resetFun = () => {
    setSearchCriteria({
      vaccineName: '',
      diseaseTargeted: '',
      manufacture: '',
      dosage: '',
      typeOfVaccine: '',
    })
    fetchVaccineData()
  }

  const saveData = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const headers = {'x-access-token': Token}

        const response = await axios.post(
          `${APIkey}/api/admin/createPreferredVaccination`,
          {vaccinationId: selectedId}, // IDs to post
          {headers}
        )
        console.log('API response:', response)
        if (response) {
          onHide()
        }
      } else {
        console.log('No login info found in localStorage')
      }
    } catch (error) {
      console.error('Error sending data to the API:', error)
    }
  }

  return (
    <>
      <Modal show={onShow} onHide={handleCloseModal} size='lg' centered>
        <Modal.Header closeButton className='h-25px dialoge align-items-center pb-3'>
          <div className='PageHeaderTitle'>Add Vaccine</div>
          <div className='cross-button'></div>
        </Modal.Header>
        <Modal.Body className='p-2 dialoge'>
          <div className='dashboard-card p-2'>
            <div className='d-flex gap-3'>
              <div className='mb-3'>
                <div className='addvaccinetitle'>Vaccine Name:</div>
                <input
                  type='text'
                  name='vaccineName'
                  value={searchCriteria.vaccineName}
                  className='SearchInputbox'
                  placeholder=''
                  onChange={handleSearchCriteriaChange}
                />
              </div>

              <div className='mb-3'>
                <div className='addvaccinetitle'>Diseases Targeted:</div>
                <input
                  type='text'
                  className='SearchInputbox'
                  name='diseaseTargeted'
                  value={searchCriteria.diseaseTargeted}
                  placeholder=''
                  onChange={handleSearchCriteriaChange}
                />
              </div>

              <div className='mb-3'>
                <div className='addvaccinetitle'>Manufacturer Name:</div>
                <input
                  type='text'
                  className='SearchInputbox'
                  name='manufacture'
                  value={searchCriteria.manufacture}
                  placeholder=''
                  onChange={handleSearchCriteriaChange}
                />
              </div>

              <div className='mb-3'>
                <div className='addvaccinetitle'>Dosage:</div>
                <input
                  type='text'
                  className='SearchInputbox'
                  name='dosage'
                  value={searchCriteria.dosage}
                  placeholder=''
                  onChange={handleSearchCriteriaChange}
                />
              </div>

              <div className='mb-3'>
                <div className='addvaccinetitle'>Type of Vaccine:</div>
                <input
                  type='text'
                  className='SearchInputbox'
                  placeholder=''
                  name='typeOfVaccine'
                  value={searchCriteria.typeOfVaccine}
                  onChange={handleSearchCriteriaChange}
                />
              </div>
            </div>
            <div>
              <div className='d-flex justify-content-end'>
                <button type='button' onClick={handleSearch} className='primarybtnBig'>
                  Search
                </button>
                &nbsp;&nbsp;
                <button
                  type='button'
                  className='tertiarybig'
                  onClick={() => [console.log('clear btn'), resetFun()]}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>

          <div className='dashboard-card mt-2'>
            {loadingModalData ? (
              <p>Loading...</p>
            ) : (
              <CommonTable
                data={modalData}
                columns={AddVaccinecolumns}
                currentPage={currentPage}
                perPage={perPage}
                totalCount={modalData.length}
                setCurrentPage={setCurrentPage}
                setPerPage={setPerPage}
                setQuery={setSearchQuery}
                onRowClick={handleRowClick}
                headerColor={tablecolor.primaryTable.color}
                issetPaginationCompo={true}
                isPagelength={true}
                isSearched={true}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='mt-3'>
            <button
              className='primarybtnBig '
              onClick={saveData}
              disabled={selectedId.length === 0}
            >
              Save
            </button>
            &nbsp;&nbsp;&nbsp;
            <button className='tertiarybig ' onClick={handleCloseModal}>
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddVaccination
