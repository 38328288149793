import React, {useEffect, useState} from 'react'
import CommonTable from '../../../../ReUsableComponent/CommonTable'
import axios from 'axios'
// import whiteplus from '../../EHRIcon/whiteplus.svg'
// import Deleteicon from '../../EHRIcon/Deleteicon.svg'
// import Vector from '../../EHRIcon/Vector.svg'
import { noop } from 'react-query/types/core/utils'
import DeleteModel from '../../../../ReUsableComponent/DeleteModel';
import EditChiefComplain from './EditChiefComplain'
import AddChiefComplain from './AddChiefComplain'
// import Eyebutton from '../../EHRIcon/Eyebutton.svg'
import ViewChiefComplain from './ViewChiefComplain'
import tablecolor from '../../../../ReUsableComponent/ReyClinicalinputs.json'

const ChiefComplain = () => {
  const APIkey = process.env.REACT_APP_SERVERAPI
  const [data, setData] = useState([])
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [saveData, setSaveData] = useState(false)


  const [deleteId, setDeleteId] = useState(null)
  const [editId,setEditData]=useState(null)
  const [isEditMode, setIsEditMode] = useState(false);


  const [ showViewModal, setShowViewModal ] = useState(false ); //for view modal
  const [viewData,setViewData]=useState('')
  const [viewId,setViewId]=useState(null)


  const handleAddClick = () => {
    console.log('Add clicked!')
    setShowAddModal(true)
  }

  const handleRowClick = (row) => {
    console.log('Row clicked!', row)
  }

  const columns = [
    {key: ['srNo'], header: 'Sr.No'},
    {key: ['chiefComplaintName'], header: 'Chief Complaint'},
    {key: ['notes'], header: 'Notes'},
    {key: ['actions'], header: 'Actions', isAction: true},
  ]

  const fetchChiefComplainData = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const header = {headers: {'x-access-token': Token}}
        const response = await axios.get(
          `${APIkey}/api/admin/getPreferredChiefComplaintList`,
          header
        )
        console.log('Fetched data:', response.data.data)
        const responseData = response.data.data

        console.log('Fetched data:')
        const formattedData = responseData.map((item, index) => ({
          srNo: index + 1,
          chiefComplaintName: item.chiefComplaintName,
          notes: item.notes,
          _id: item._id,
          actions: (
            <div className='actionbtn'>
                  <button onClick={()=>handleView(item._id)} className='editbtn'>
                  <img 
                  // src={Eyebutton} 
                  alt='view'/>
                </button>
              <button className='editbtn ' onClick={() => handleEdit(item._id)}>
                <img 
                // src={Vector} 
                alt='Edit' />
              </button>
              <button className='editbtn'  onClick={() => openDeleteModal(item._id)}>
                <img 
                // src={Deleteicon} 
                alt='Delete' />
              </button>
            </div>
          ),
        }))
        setData(formattedData)
      } else {
        console.log('No login info found in localstorage')
      }
    } catch (error) {
      console.log('Error fetching data:', error)
    }
  }
  useEffect(() => {
    fetchChiefComplainData()
  }, [showAddModal, showEditModal, showDeleteModal])

  const handleCloseModal = () => {
    setShowAddModal(false)
    setShowEditModal(false)
    setShowDeleteModal(false)
    setShowViewModal(false)
  }

  const openDeleteModal = (id) => {
    setDeleteId(id)
    setShowDeleteModal(true)
  }

  const handleDelete = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const header = {headers: {'x-access-token': Token}}

        await axios.delete(`${APIkey}/api/admin/deletePreferredChiefComplaint/${deleteId}`, header)
        console.log('Row deleted successfully')

        fetchChiefComplainData()
        setShowDeleteModal(false)
      } else {
        console.log('No login info found in localstorage')
      }
    } catch (error) {
      console.error('Error deleting Problem List:', error)
    }
  }



  const handleEdit=(_id)=>{
    setEditData(_id);
    setShowEditModal(true);
    setIsEditMode(true); 
  }


  const handleView = (_id) => {
    setViewData(_id)
    setViewId(_id)
    setShowViewModal(true)

  }


  const [ searchCriteria,setSearchCriteria]=useState({
    chiefComplaintName:'',
    notes:'',
  })
  const handleSearchCriteriaChange=(e)=>{
    const {name,value}= e.target
    setSearchCriteria((prevState)=>({
      ...prevState,
      [name]:value,
    }))
  }

  const handleSearch = async()=>{
    try{
      const getloginInfo=localStorage.getItem('appLoginInfo');
  if(getloginInfo){
    const Token = JSON.parse(getloginInfo).data.accessToken
    console.log("token" ,Token);
    
    const header = {
      headers: {'x-access-token': Token},
    }
    
      const response = await axios.post(`${APIkey}/api/admin/searchPreferredChiefComplaint`,searchCriteria,header)
      const responseData = response.data.data
      const searchData = responseData.map((item,index)=>({
        srNo: index + 1,
        chiefComplaintName: item.chiefComplaintName,
        notes: item.notes,
        actions: (
          <div className='actionbtn'>
            <button onClick={() => handleView(item._id)} className='editbtn'>
              <img 
              // src={Eyebutton} 
              alt='view' />
            </button>
            <button onClick={() => handleEdit(item._id)} className='editbtn'>
              <img 
              // src={Vector} 
              alt='Edit' />
            </button>
            <button onClick={() => openDeleteModal(item._id)} className='editbtn'>
              <img 
              // src={Deleteicon}
               alt='Delete' />
            </button>
          </div>
        ),

  
      }))
      setData(searchData)
  }
    }catch(error){
   console.log('Error fetching data')
    }
  }





  return (
   <>
    <div className='clinical-time-header'>
        <div className='titletextformat'>
          <div className='PageHeaderTitle'>Chief Complain</div>
          <div>
            {' '}
            <button className='add-Vaccine-btn'  onClick={handleAddClick} >
              <img
              //  src={whiteplus} 
              alt='Add Vaccine' /> Add Chief Complain
            </button>
          </div>
        </div>
      </div>



<div className='d-flex gap-2 col-md-12 mt-3'>
<div className='col-md-3'>
          <input type='text' className='SearchInputbox' placeholder='Chief Complain' name='chiefComplaintName' onChange={handleSearchCriteriaChange}  />
        </div>

        <div className='col-md-3'>
          <input type='text' className='SearchInputbox' placeholder='Notes' name='notes' onChange={handleSearchCriteriaChange}  />
        </div>

        <div className='col-md-6'>
          <button type='button' className='AddBtn' onClick={handleSearch}>
           Search
          </button>
        </div>

</div>
<br />
      <div className='clinical-time-container'>
      <CommonTable
        data={data}
        totalCount={data.length}
        perPage={perPage}
        setPerPage={setPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        columns={columns}
        searchQuery={searchQuery}
        setQuery={setSearchQuery}
        onRowClick={handleRowClick}
        headerColor={tablecolor.primaryTable.color}
        issetPaginationCompo={true}
        isPagelength={true}
        isSearched={true}    
         />
        </div>



        <DeleteModel
        openDelete={showDeleteModal}
        closedelete={handleCloseModal}
        deletedEntity={handleDelete}
        deleteID={deleteId}
        deleteFunction={handleDelete}
        />


        <EditChiefComplain
        onHide={handleCloseModal}
        onShow={showEditModal}
        editId={editId}/>

        <AddChiefComplain
        onHide={handleCloseModal}
        onShow={showAddModal}
        onClick={saveData}/>

        <ViewChiefComplain onshow={showViewModal} onhide={handleCloseModal} viewId={viewId} />
      </>



  )
}

export default ChiefComplain
