// import React, {useEffect, useState} from 'react'
// import axios from 'axios'
// import Modal from 'react-bootstrap/Modal'

// const EditPatientInstructions = ({onShow, onHide, editId}) => {
//   const APIkey = process.env.REACT_APP_SERVERAPI || ''
//   const [category, setCategory] = useState('')
//   const [instruction, setInstruction] = useState('')

//   const handleCloseEditModal = () => {
//     setCategory('')
//     setInstruction('')
//     onHide()
//   }

//   useEffect(() => {
//     const fetchEditData = async () => {
//       try {
//         const getLoginInfo = localStorage.getItem('appLoginInfo')
//         console.log('getLoginInfo', getLoginInfo)
//         if (getLoginInfo !== null) {
//           const {
//             data: {accessToken},
//           } = JSON.parse(getLoginInfo)
//           const headers = {'x-access-token': accessToken}
//           const response = await axios.get(
//             `${APIkey}/api/admin/getPreferredPatientInstructionById/${editId}`,
//             {headers}
//           )
//           const data = response.data.data
//           setCategory(data.category)
//           setInstruction(data.instruction)
//         } else {
//           console.log('No login info found in localStorage')
//         }
//       } catch (error) {
//         console.error('Error in fetching data:', error)
//       }
//     }


//       fetchEditData()
//   }, [])

//   const handleUpdate = async () => {
//     try {
//       const getLoginInfo = localStorage.getItem('appLoginInfo')
//       if (getLoginInfo !== null) {
//         const {accessToken} = JSON.parse(getLoginInfo).data
//         const headers = {'x-access-token': accessToken}

//         const response = await axios.post(
//           `${APIkey}/api/admin/updatePreferredPatientInstruction/${editId}`,
//           {headers}
//         )
//         console.log('Updated Data:', response.data.data)
//         handleCloseEditModal()
//       } else {
//         console.error('No login info found in localStorage')
//       }
//     } catch (error) {
//       console.error('Error updating data:', error)
//     }
//   }

//   return (
//     <>
//       <Modal show={onShow} onHide={handleCloseEditModal} size='lg' centered>
//         <Modal.Header closeButton className='h-25px dialoge align-items-center pb-3'>
//           <div className='PageHeaderTitle'>Edit Patient Instructions</div>
//         </Modal.Header>
//         <Modal.Body className='p-2 dialoge'>
//           <div className='dashboard-card col-md-12 p-2'>
//             <div className='row'>
//               <div className='col-md-6 mb-3'>
//                 <div className='addvaccinetitle'>Category:</div>
//                 <input type='text' className='SearchInputbox' value={category} readOnly disabled />
//               </div>
//               <div className='col-md-6 mb-3'>
//                 <div className='addvaccinetitle'>Instructions:</div>
//                 <input
//                   type='text'
//                   className='SearchInputbox'
//                   value={instruction}
//                   onChange={(e) => setInstruction(e.target.value)}
//                 />
//               </div>
//             </div>
//             <div className='my-2 d-flex justify-content-end gap-3'>
//               <button type='button' className='primarybtnBig' onClick={handleUpdate}>
//                 Update
//               </button>
//               <button type='button' className='tertiarybig' onClick={handleCloseEditModal}>
//                 Cancel
//               </button>
//             </div>
//           </div>
//         </Modal.Body>
//       </Modal>
//     </>
//   )
// }

// export default EditPatientInstructions


import React, {useEffect, useState} from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'

const EditPatientInstructions = ({onShow, onHide, editId}) => {
  const APIkey = process.env.REACT_APP_SERVERAPI || ''
  const [category, setCategory] = useState('')
  const [instruction, setInstruction] = useState('')

  const handleCloseEditModal = () => {
    setCategory('')
    setInstruction('')
    onHide()
  }

  useEffect(() => {
    if (onShow && editId) {
      const fetchEditData = async () => {
        try {
          const getLoginInfo = localStorage.getItem('appLoginInfo')
          if (getLoginInfo !== null) {
            const {accessToken} = JSON.parse(getLoginInfo).data
            const headers = {'x-access-token': accessToken}

            const response = await axios.get(
              `${APIkey}/api/admin/getPreferredPatientInstructionById/${editId}`,
              {headers}
            )
            const data = response.data.data
            setCategory(data.category)
            setInstruction(data.instruction)
          } else {
            console.log('No login info found in localStorage')
          }
        } catch (error) {
          console.error('Error in fetching data:', error)
        }
      }

      fetchEditData()
    }
  }, [onShow, editId, APIkey])

  const handleUpdate = async () => {
    try {
      const getLoginInfo = localStorage.getItem('appLoginInfo')
      if (getLoginInfo !== null) {
        const {accessToken} = JSON.parse(getLoginInfo).data
        const headers = {'x-access-token': accessToken}

        const response = await axios.post(
          `${APIkey}/api/admin/updatePreferredPatientInstruction/${editId}`,
          {category, instruction}, // Corrected the request body to include category and instruction
          {headers}
        )
        console.log('Updated Data:', response.data.data)
        handleCloseEditModal()
      } else {
        console.error('No login info found in localStorage')
      }
    } catch (error) {
      console.error('Error updating data:', error)
    }
  }

  return (
    <>
      <Modal show={onShow} onHide={handleCloseEditModal} size='lg' centered>
        <Modal.Header closeButton className='h-25px dialoge align-items-center pb-3'>
          <div className='PageHeaderTitle'>Edit Patient Instructions</div>
        </Modal.Header>
        <Modal.Body className='p-2 dialoge'>
          <div className='dashboard-card col-md-12 p-2'>
            <div className='row'>
              <div className='col-md-6 mb-3'>
                <div className='addvaccinetitle'>Category:</div>
                <input type='text' className='SearchInputbox' value={category} readOnly disabled />
              </div>
              <div className='col-md-6 mb-3'>
                <div className='addvaccinetitle'>Instructions:</div>
                <input
                  type='text'
                  className='SearchInputbox'
                  value={instruction}
                  onChange={(e) => setInstruction(e.target.value)}
                />
              </div>
            </div>
            <div className='my-2 d-flex justify-content-end gap-3'>
              <button type='button' className='primarybtnBig' onClick={handleUpdate}>
                Update
              </button>
              <button type='button' className='tertiarybig' onClick={handleCloseEditModal}>
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EditPatientInstructions
