import React, { useEffect, useState } from 'react';
import SearchableDropdown from '../ReusablePages/ReusableAppointment/SearchableDropdown';
import DownArrow from '../EHRIcon/DownArrow.png';
import UpArrow from '../EHRIcon/UpArrow.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import SearchableGroup from '../../../ReUsableComponent/SearchableGroup';
import patientChartIcon from '../EHRIcon/patientChart.svg';
import TableComponent from '../../../ReUsableComponent/PaginationCompo';
import PaginationCompo from '../../../ReUsableComponent/PaginationCompo';
import Datepicker from '../../../ReUsableComponent/Datepicker';
// import toDoIcon from '../EHRIcon/ToDo.svg'

function AppointmentSearch({ screenSwitch }) {
    const APIkey = process.env.REACT_APP_SERVERAPI || '';
    const navigate = useNavigate();
    const [downSearch, setDownSearch] = useState(false);
    const [patientAppointmentList, setPatientAppointmentList] = useState<any[] | null>([]);
    const [patientAppointmentSearchList, setPatientAppointmentSearchList] = useState<any[] | null>(null);

    const [withList, setwithList] = useState([])
    const [visitReasonData, setVisitReasonData] = useState([])
    const [appointmentTypeData, setAppointmentTypeData] = useState([])
    const [appointmentStatusData, setAppointmentStatusData] = useState([])


    //pagination ....
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [totalAppointment, setTotalAppointment] = useState<any>();
    const [totalsearchAppointment, setTotalsearchAppointment] = useState<any>();


    const [mrn, setMrn] = useState<string | null>()
    const [patientFullName, setPatientFullName] = useState<string | null>()
    const [appointmentDate, setAppointmentDate] = useState<string | null>()
    const [appointmentStartTime, setAppointmentStartTime] = useState<string | null>()
    const [appointmentEndTime, setAppointmentEndTime] = useState<string | null>()
    const [userId, setUserId] = useState<string | null>()
    const [reasonForVisitId, setReasonForVisitId] = useState<string | null>()
    const [appointmentTypeId, setAppointmentTypeId] = useState<string | null>()
    const [appointmentStatusId, setAppointmentStatusId] = useState<string | null>()


    useEffect(() => {
        const getPatientAppointmentList = async () => {
            try {
                let getloginInfo: string | null = localStorage.getItem('appLoginInfo');
                if (getloginInfo !== null) {
                    const Token = JSON.parse(getloginInfo).data.accessToken;
                    const Id = JSON.parse(getloginInfo).data.clinicId;

                    const header = {
                        headers: { 'x-access-token': Token }
                    };

                 

                    const response = await axios.get(`${APIkey}/api/admin/getAllAppointment?page=${currentPage}&limit=${perPage}&search=${searchQuery}`, header);
                    setTotalAppointment(response?.data?.data?.pagination?.totalRecords);
                    setPatientAppointmentList(response?.data?.data?.appointment_list);
                

                    const withList = await axios.get(`${APIkey}/api/admin/getDesignationList/${Id}`, header);

                    const userList = await axios.get(`${APIkey}/api/admin/listUsersByClinicId/${Id}`, header)


                    setwithList(withList.data.data.map(i => (
                        {
                            label: i.designation,
                            options: userList.data.data.filter(j => j.designationId === i._id).map(j => {
                                return {
                                    value: `${j.firstName} ${j.lastName} - ${i.designation}`,
                                    label: `${j.firstName} ${j.lastName}`,
                                    Did: j.designationId,
                                    Uid: j._id
                                };
                            })
                        })))

                    const visitReasonList = await axios.get(`${APIkey}/api/admin/getReasonForVisitList`, header);



                    setVisitReasonData(visitReasonList.data.data.map(i => (
                        {
                            label: i.reason,
                            value: i.reason,
                            id: i._id
                        })))


                    const appointmentTypeList = await axios.get(`${APIkey}/api/admin/getAppointmentTypeList`, header);

                    setAppointmentTypeData(appointmentTypeList.data.data.map(i => (
                        {
                            label: i.appointmentType,
                            value: i.appointmentType,
                            id: i._id
                        })))

                    const appointmentStatusList = await axios.get(`${APIkey}/api/admin/getAppointmentStatusList`, header);

                    setAppointmentStatusData(appointmentStatusList.data.data.map(i => (
                        {
                            label: i.appointmentStatus,
                            value: i.appointmentStatus,
                            id: i._id
                        })));


                } else {
                    console.log("No login info found in localStorage");
                }
            } catch (err) {

                console.log(err);

            }
        };
        getPatientAppointmentList();
    }, [currentPage, perPage , searchQuery]);


    useEffect(()=> {

    },[])

    const handlePageChange = (pageNumber: number): void => {
        setCurrentPage(pageNumber);
        console.log(pageNumber);
    };

    const handlePerPageChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        setPerPage(Number(e.target.value));
        setCurrentPage(1); // Reset to first page when changing items per page
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchQuery(e.target.value);
    };



    const dob = (date) => moment(date).format('L')

    const day = (day) => moment(day).format('dddd')

 

    const filteredAppointments = patientAppointmentList

    console.log(filteredAppointments);
    



    const filteredSearchAppointments: any[] | undefined = patientAppointmentSearchList?.filter((item: any) => {
        return (
          
            item?.patientDetails?.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item?.patientDetails?.firstName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            dob(item.appointmentDate).toLocaleLowerCase().includes(searchQuery.toLowerCase()) ||
            day(item?.appointmentDate).toLocaleLowerCase().includes(searchQuery.toLowerCase()) ||
            item?.appointmentTime.toLocaleLowerCase().includes(searchQuery.toLowerCase()) ||
            `${item?.usersDetails?.firstName}${item?.usersDetails?.lastName}`.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.reasonforvisitDetails.reason.toLocaleLowerCase().includes(searchQuery.toLowerCase()) ||
            item.appointmentTypeDetails.appointmentType.toLocaleLowerCase().includes(searchQuery.toLowerCase()) 
            ||
            item?.appointmentstatusDetails?.appointmentStatus?.toLocaleLowerCase().includes(searchQuery.toLowerCase())

        );
    });


    
    

    const indexOfLastItem = currentPage * perPage;
    const indexOfFirstItem = indexOfLastItem - perPage;
    const searchList = filteredSearchAppointments?.slice(indexOfFirstItem, indexOfLastItem);
    
    
    
    const totalSearchList = filteredSearchAppointments?.length;
    
   


    const [inputTypedate, setInputTypedate] = useState('text');
    const [inputTypeStartTime, setInputTypeStartTime] = useState('text');
    const [inputTypeEndTime, setInputTypeEndTime] = useState('text');

    const handleFocus = () => {
        setInputTypedate('date');
    };
    const handleBlur = () => {
        setInputTypedate('text');
    };

    const handleFocusforstartTime = () => {
        setInputTypeStartTime('time');
    };
    const handleFocusforendTime = () => {
        setInputTypeEndTime('time');
    };
    const handleBlurforStartTime = () => {
        setInputTypeStartTime('text')
    }
    const handleBlurforEndTime = () => {
        setInputTypeEndTime('text')
    }



    const getmrn = (e) => {
        setMrn(e.target.value)
    }

    const getFullPatientName = (e) => {
        setPatientFullName(e.target.value)
    }
    const getTheDate = (e) => {
    
        setAppointmentDate(e.target.value)
    }
    const getThestartTime = (e) => {
        setAppointmentStartTime(e.target.value)
    }
    const getTheEndTime = (e) => {
        setAppointmentEndTime(e.target.value)
    }

    const getThewith = (e) => {
        setUserId(e.Uid);

    }

    const getTheReason = (e) => {
        setReasonForVisitId(e)
    }

    const getTheVisitType = (e) => {
        setAppointmentTypeId(e)
    }
    const getTheStatus = (e) => {
        setAppointmentStatusId(e)
    }

    const submitSearch = async (data) => {
        try {
            let getloginInfo: string | null = localStorage.getItem('appLoginInfo')

            if (getloginInfo !== null) {
                const Token = JSON.parse(getloginInfo).data.accessToken;
                const header = {
                    headers: { 'x-access-token': Token }
                }

                const response = await axios.post(`${APIkey}/api/admin/getAllAppointmentSearch`, data, header);

                console.log('Response from server: ', response);

                setCurrentPage(1);
                setPatientAppointmentSearchList(response.data.data.appointment_list);
                setTotalsearchAppointment(response.data.data.pagination.totalRecords)


                if (response) {
                    setDownSearch(true)
                    // setHidePagination(true)
                }

            } else {
                console.log('No access token found in localStorage');
            }
        } catch (error) {
            // Handle errors
            console.error('Error occurred while adding patient: ', error);
        }
    }

    const handleSearch = () => {
        let data = {
            mrn,
            patientFullName,
            appointmentDate,
            appointmentStartTime,
            appointmentEndTime,
            userId,
            reasonForVisitId,
            appointmentTypeId,
            appointmentStatusId

        }

        submitSearch(data)

    }

    const handleCancel = () => {

        setPatientAppointmentSearchList(null)
        setMrn(null)
        setPatientFullName(null)
        setAppointmentDate(null)
        setAppointmentStartTime(null)
        setAppointmentEndTime(null)
        setUserId(null)
        setReasonForVisitId(null)

        setAppointmentTypeId(null)
        setAppointmentStatusId(null)
    }

  

    return (
        <div>
            <div className='PageHeader'>
                <div>
                    <span className='PageHeaderTitle'>
                        Appointment Search
                    </span>
                </div>
                <div>
                    <button onClick={() => screenSwitch(true)} className='switchViewbtnLeft  '>
                        Calender
                    </button>
                    <button className='switchViewbtnRight switchViewbtnActive '>
                        List View
                    </button>
                </div>
            </div>

            <div className='card mt-1 pt-1'>

                <div className='row w-100 align-items-center  p-1 px-2 py-2   ' >
                    <div className='col  ' >
                        <input type="text"
                            onChange={getmrn}
                            value={mrn || ''}
                            className='form-control SearchInputbox'
                            placeholder='MRN' />
                    </div>
                    <div className='col '>
                        <input type="text"
                            onChange={getFullPatientName}
                            value={patientFullName || ''}
                            className='form-control SearchInputbox'
                            placeholder='Patient Name' />
                    </div>
                    <div className='col '>
                    <input
                    type={inputTypedate}
               
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={getTheDate}
                    className='form-control SearchInputbox'
                    placeholder='Appointment Date'
                 />
                        {/* <Datepicker 
                        selectedDate={appointmentDate}
                        gettingDate={getTheDate}
                        /> */}
                     
                    </div>


                    <div className='col d-flex w-100 align-items-center gap-2' >


                        <input type={inputTypeStartTime}
                            className='form-control SearchInputbox'
                            onFocus={handleFocusforstartTime}
                            onBlur={handleBlurforStartTime}
                            onChange={getThestartTime}
                            value={appointmentStartTime || ''}
                            placeholder='Appointment Time 1' />

                        <span className='patient-info-title'>
                            to
                        </span>

                        <input
                            type={inputTypeEndTime}
                            onFocus={handleFocusforendTime}
                            onBlur={handleBlurforEndTime}
                            onChange={getTheEndTime}
                            value={appointmentEndTime || ''}
                            className='form-control SearchInputbox'
                            placeholder='Appointment Time 2' />

                    </div>
                    <div className='col '>
                        <SearchableGroup
                            InitialValue={userId}
                            gettingData={getThewith}
                            options={withList}
                            placeHolder='With' />
                    </div>


                </div >
                <div className='row w-100 align-items-center  p-1 px-2 py-2   ' >
                    <div className='col '>
                        <SearchableDropdown

                            InitialValue={reasonForVisitId}
                            options={visitReasonData}
                            gettingData={getTheReason}
                            placeHolder='Reason for Visit' />
                    </div>
                    <div className='col  '>
                        <SearchableDropdown
                            InitialValue={appointmentTypeId}
                            options={appointmentTypeData}
                            gettingData={getTheVisitType}
                            placeHolder='Visit Type' />
                    </div>
                    <div className='col  ' >
                        <SearchableDropdown
                            InitialValue={appointmentStatusId}
                            gettingData={getTheStatus}
                            options={appointmentStatusData}
                            placeHolder='Status' />

                    </div>
                    <div className='col ' >

                    </div>

                    <div className='col  d-flex justify-content-end align-items-center gap-2'>
                        <div >
                            <button className='blueButton' onClick={handleSearch} >
                                Search
                            </button>
                        </div>
                        <div className=''>
                            <button
                                onClick={handleCancel}
                                className='grayButton' >
                                Clear
                            </button>
                        </div>
                    </div>
                </div >
                {
                    // downSearch && (

                    searchList != null ?
                        (<div className='w-100 p-1'>
                            <div className='row w-100 align-items-center  p-1 px-2 py-2   ' >
                                {/* Search inputs */}
                            </div>
                            <div className='w-100 p-2'>
                                <div className=" pb-2 d-flex justify-content-between">
                                    <div className="input-group" style={{ width: '130px' }}>
                                        <span className="input-group-text" style={{ height: '35px', width: '55px' }}>Show </span>
                                        <select className="form-select" onChange={handlePerPageChange} defaultValue={perPage} style={{ height: '35px', width: '50px' }}>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                            <option value="40">40</option>
                                            <option value="50">50</option>
                                        </select>
                                    </div>
                                    <div>

                                    <input
                                        type="text"
                                         className="form-control  SearchInputbox"
                                        placeholder="Search"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                    </div>

                                </div>

                                <table className='   w-100 fullCurve '>
                                    <thead className=''>
                                        <tr className='tableHead'>
                                            <th></th>
                                            <th>MRN</th>
                                            <th>Last Name</th>
                                            <th>First Name</th>
                                            <th>Date</th>
                                            <th>Day</th>
                                            <th>Start Time</th>
                                            <th>Provider</th>
                                            <th>Reason of Visit</th>
                                            <th>Visit Type</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className=''>
                                        {
                                          searchList?.map((item, index) => (
                                                <tr key={index} className='tableBody'>
                                                    <td>

                                                    </td>
                                                    <td title={item?.patientDetails?.mrn}>{item?.patientDetails?.mrn}</td>
                                                    <td title={item?.patientDetails?.lastName}>{item?.patientDetails?.lastName}</td>
                                                    <td title={item?.patientDetails?.firstName}>{item?.patientDetails?.firstName}</td>
                                                    <td title={moment(item?.appointmentDate).format('L')}>{moment(item?.appointmentDate).format('L')}</td>
                                                    <td title={moment(item?.appointmentDate).format('dddd')}>{moment(item?.appointmentDate).format('dddd')}</td>
                                                    <td title={item?.appointmentTime}>{item?.appointmentTime}</td>
                                                    <td title={`${item?.usersDetails?.firstName} ${item?.usersDetails?.lastName}`}>{item?.usersDetails?.firstName} {item?.usersDetails?.lastName}</td>
                                                    <td title={item?.reasonforvisitDetails?.reason}>{item?.reasonforvisitDetails?.reason}</td>
                                                    <td title={item?.appointmentTypeDetails?.appointmentType}>{item?.appointmentTypeDetails?.appointmentType}</td>
                                                    <td title={item?.appointmentstatusDetails?.appointmentStatus}>{item?.appointmentstatusDetails?.appointmentStatus}</td>
                                                    <td className='d-flex gap-3 justify-content-center align-items-center pt-1 '>
                                                        <div>
                                                            <img
                                                                onClick={() => navigate("/appointment/charts")}
                                                                style={{ cursor: 'pointer' }}
                                                                src={patientChartIcon}
                                                                alt='Patient Chart'
                                                                title='Patient Chart' />
                                                            {/* <img
                                                                onClick={() => navigate(`/appointment/charts/vitals/${item._id}`)}
                                                                style={{ cursor: 'pointer' }}
                                                                src={patientChartIcon}
                                                                alt='Patient Chart'
                                                                title='Patient Chart' /> */}
                                                        </div>
                                                        {/* <div>

                                                        <img src={toDoIcon} alt='To DO' title='To Do' />
                                                    </div> */}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>

                                <div className={totalSearchList ? 'd-flex justify-content-between align-item-center' : 'd-flex justify-content-center align-item-center'}  >

                                    <div className='d-flex align-item-center py-2 '>
                                        <span className='patient-info-title'>
                                            {totalSearchList ? `Total ${totalSearchList} entries` : 'No records found'}
                                        </span>

                                    </div>

                                    {/* {totalsearchAppointment > perPage && (
                                        <div>
                                            <nav className='py-2' aria-label="Page navigation example">
                                                <ul className="pagination justify-content-center">
                                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                        <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>&lt;</button>
                                                    </li>
                                                    {Array.from(Array(Math.ceil(totalsearchAppointment / perPage)).keys()).map((number) => (
                                                        <li key={number} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
                                                            <button className="page-link" onClick={() => handlePageChange(number + 1)}>
                                                                {number + 1}
                                                            </button>
                                                        </li>
                                                    ))}
                                                    <li className={`page-item ${currentPage === Math.ceil(totalsearchAppointment / perPage) ? 'disabled' : ''}`}>
                                                        <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>&gt;</button>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    )} */}
                                        <PaginationCompo
                                        totalData={ totalSearchList}
                                        perPage={perPage}
                                        currentPage={currentPage}
                                        handlePageChange={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>)
                        :
                        (<div className='w-100 p-1'>
                            <div className='row w-100 align-items-center  p-1 px-2 py-2 '>
                                {/* Search inputs */}
                            </div>
                            <div className='w-100 p-2'>
                                <div className=" pb-2 d-flex justify-content-between">
                                    <div className="input-group" style={{ width: '130px' }}>
                                        <span className="input-group-text" style={{ height: '35px', width: '55px' }}>Show </span>
                                        <select className="form-select" onChange={handlePerPageChange} defaultValue={perPage} style={{ height: '35px', width: '50px' }}>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                            <option value="40">40</option>
                                            <option value="50">50</option>
                                        </select>
                                    </div>
                                    <div>

                                    <input
                                        type="text"
                                        className="form-control  SearchInputbox"
                                        placeholder="Search"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                    </div>

                                </div>

                                <table className='   w-100 fullCurve '>
                                    <thead className=''>
                                        <tr className='tableHead'>
                                         
                                            <th>&nbsp;MRN</th>
                                            <th>Last Name</th>
                                            <th>First Name</th>
                                            <th>Date</th>
                                            <th>Day</th>
                                            <th>Start Time</th>
                                            <th>Provider</th>
                                            <th>Reason of Visit</th>
                                            <th>Visit Type</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className=''>
                                        {filteredAppointments?.map((item, index) => (
                                            <tr key={index} className='tableBody'>
                                        
                                                <td title={item?.patientDetails?.mrn}>&nbsp;{item?.patientDetails?.mrn}</td>
                                                <td title={item?.patientDetails?.lastName}>{item?.patientDetails?.lastName}</td>
                                                <td title={item?.patientDetails?.firstName}>{item?.patientDetails?.firstName}</td>
                                                <td title={moment(item?.appointmentDate).format('L')}>{moment(item?.appointmentDate).format('L')}</td>
                                                <td title={moment(item?.appointmentDate).format('dddd')}>{moment(item?.appointmentDate).format('dddd')}</td>
                                                <td title={item?.appointmentTime}>{item?.appointmentTime}</td>
                                                <td title={`${item?.usersDetails?.firstName} ${item?.usersDetails?.lastName}`}>{item?.usersDetails?.firstName} {item?.usersDetails?.lastName}</td>
                                                <td title={item?.reasonforvisitDetails?.reason}>{item?.reasonforvisitDetails?.reason}</td>
                                                <td title={item?.appointmentTypeDetails?.appointmentType}>{item?.appointmentTypeDetails?.appointmentType}</td>
                                                <td title={item?.appointmentstatusDetails?.appointmentStatus}>{item?.appointmentstatusDetails?.appointmentStatus}</td>
                                                <td className='d-flex gap-2 justify-content-center align-items-center pt-1 '>
                                                    <div>
                                                        <img
                                                            onClick={() => navigate("/appointment/charts/")}
                                                            style={{ cursor: 'pointer' }}
                                                            src={patientChartIcon}
                                                            alt='Patient Chart'
                                                            title='Patient Chart' />
                                                               {/* <img
                                                                onClick={() => navigate(`/appointment/charts/vitals/${item._id}`)}
                                                                style={{ cursor: 'pointer' }}
                                                                src={patientChartIcon}
                                                                alt='Patient Chart'
                                                                title='Patient Chart' /> */}
                                                    </div>
                                                    {/* <div>

                                                        <img src={toDoIcon} alt='To DO' title='To Do' />
                                                    </div> */}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>


                                <div className='d-flex justify-content-between align-item-center'>

                                    <div className='d-flex align-item-center py-2 '>
                                        <span className='patient-info-title'>
                                            {totalAppointment ? `Total ${totalAppointment} entries` : `No records found`}
                                        </span>

                                    </div>
                                    {/* {totalAppointment > perPage && (
                                        <div>
                                            <nav className='py-2' aria-label="Page navigation example">
                                                <ul className="pagination justify-content-center">
                                                    <li className={`page-item ${currentPage === 0 ? 'disabled' : ''}`}>
                                                        <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>&lt;</button>
                                                    </li>
                                                    {Array.from(Array(Math.ceil(totalAppointment / perPage)).keys()).map((number) => (
                                                        <li key={number} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
                                                            <button className="page-link" onClick={() => handlePageChange(number + 1)}>
                                                                {number + 1}
                                                            </button>
                                                        </li>
                                                    ))}
                                                    <li className={`page-item ${currentPage === Math.ceil(totalAppointment / perPage) ? 'disabled' : ''}`}>
                                                        <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>&gt;</button>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    )} */}
                                    <PaginationCompo
                                        totalData={ totalAppointment}
                                        perPage={perPage}
                                        currentPage={currentPage}
                                        handlePageChange={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>)
                    // )
                }
            </div>



        </div>




    );
}

export default AppointmentSearch;
