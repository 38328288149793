import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'
import {useParams} from 'react-router-dom'
import CryptoConverter from '../../../../ReUsableComponent/CryptoConverter'

const ViewMedication = ({onshow,onhide,viewId,}) => {
    const APIkey = process.env.REACT_APP_SERVERAPI
    const [medication,setMedication]=useState('')
    const [storageRequirements,setStorageRequirements]=useState('')
    const [notes,setNotes]=useState('')
    const [ brandName,setBrandName]=useState('')
    const [duration,setDuration]=useState('')
    const [form,setForm]=useState('') //Types of medicine
    const [frequency,setFrequency]=useState('') //dosage instruction
    const [routeOfAdministration,setRouteOfAdministration]=useState('')//way to take medicine

    useEffect(() => {
        const fetchEditData = async () => {
         
          try {
            const getLoginInfo = localStorage.getItem('appLoginInfo')
            console.log('getLoginInfo: ', getLoginInfo)
    
            if (getLoginInfo !== null) {
              const { data : {accessToken}} = JSON.parse(getLoginInfo) // Corrected property name
    
              const headers = {'x-access-token': accessToken}
    
              const response = await axios.get(
                `${APIkey}/api/admin/getPreferredMedicationById/${viewId}`,
                {headers}
              )
              const data = response.data.data
              console.log("medication", data)

              setBrandName(data.brandName)
              setDuration(data.duration)
              setForm(data.form)
              setFrequency(data.frequency)
              setMedication(data.medication)
              setNotes(data.notes)
              setRouteOfAdministration(data.routeOfAdministration)
              setStorageRequirements(data.storageRequirements)
    
            

            } else {
              console.error('No login info found in localStorage')
            }
          } catch (error) {
            console.error('Error fetching data:', error)
          } 
        }
    
        if (viewId) {
            fetchEditData()
        }
    },[viewId])


    const handleCloseModal = () => {

        setBrandName('')
        setDuration('')
        setForm('')
        setFrequency('')
        setMedication('')
        setNotes('')
        setRouteOfAdministration('')
        setStorageRequirements('')
        onhide()
    }




  return (
    <>

    <Modal show={onshow} onHide={handleCloseModal} size='lg' centered>
        <Modal.Header closeButton className='h-25px dialoge align-items-center pb-3'>
        <div className='PageHeaderTitle'> Medication Information</div>
        </Modal.Header>
        <Modal.Body className='p-2 dialoge'>
            <div className='dashboard-card'>
                <div className='d-flex gap-5'>
                <div className='mb-2'>
             <label className='viewlabels'>Medicine Name:</label>
             <div className='vaccine-label-data'>{medication}</div></div>

             <div className='mb-2'>
             <label className='viewlabels'>Brand Name:</label>
             <div className='vaccine-label-data'>{brandName}</div></div>

             <div className='mb-2'>
             <label className='viewlabels'>Type of Medicine:</label>
             <div className='vaccine-label-data'>{form}</div></div>

             <div className='mb-2'>
             <label className='viewlabels'>Dosage Instruction:</label>
             <div className='vaccine-label-data'>{frequency}</div></div>

             <div className='mb-2'>
             <label className='viewlabels'>Way To Take Medicine:</label>
             <div className='vaccine-label-data'>{routeOfAdministration}</div></div>

            
                </div>

                <div className='d-flex gap-2'>
                <div className='mb-3 '>
             <label className='viewlabels'>Storage Requirement:</label>
             <div className='vaccine-label-data'>{storageRequirements}</div></div>

             <div className='mb-3 '>
             <label className='viewlabels'>Duration:</label>
             <div className='vaccine-label-data'>{duration}</div></div>

             

                </div>
                <div className='d-flex'>
                <div className='mb-3 '>
             <label className='viewlabels'>Notes:</label>
             <div className='vaccine-label-data'>{notes}</div></div>

                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>   
        <div className='my-1 d-flex justify-content-end gap-2'>
        <button type='button' className='tertiarybig' onClick={handleCloseModal}>
              Close
            </button>
        </div>
        </Modal.Footer>
    </Modal>

    
    </>
  )
}

export default ViewMedication
