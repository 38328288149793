import React, {useEffect, useState} from 'react';
import SearchableDropdown from './SearchableDropdown';
import inputText from '../../../ReUsableComponent/ReyClinicalinputs.json';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import TitleComponent from '../../../ReUsableComponent/TitleComponent';

function NameCompo(props) {
  const APIkey = process.env.REACT_APP_SERVERAPI;

  const getloginInfo: string | null = localStorage.getItem('loginInfo');

  const [PrefixData, setPrefixData] = useState<any[] | undefined>([]);

  const [SuffixData, setSuffixData] = useState<any[] | undefined>([]);
  const {id} = useParams();

  //   useEffect(() => {
  //     const getprefixSuffixList = async (_id) => {
  //       try {

  //         if (getloginInfo !== null) {
  //           const token = JSON.parse(getloginInfo).data.accessToken;

  //           const header = {
  //             headers: {'x-access-token': token},
  //           };

  //           const prefixList = await axios.get(
  //             !props.isUserFixes
  //               ? `${APIkey}/api/admin/getClinicPrefixList`
  //               : `${APIkey}/api/admin/getPrefixList`,
  //             header
  //           );

  //           console.log(prefixList.data.data)

  //          // const prefixList = await axios.get(!props.isUserFixes ? `${APIkey}/api/admin/getClinicPrefixList` : `${APIkey}/api/admin/getPrefixList`, header);

  //         //   setPrefixData(
  //         //     prefixList.data.data.map((i) => ({
  //         //         label: !props.isUserFixes ? i.prefixName : i.prefix,
  //         //         value: !props.isUserFixes ? i.prefixName : i.prefix,
  //         //       id: i._id,
  //         //     }))
  //         //   );

  //         setPrefixData(
  //             prefixList.data.data.map((i) => ({
  //                 label: i.prefix,
  //                 value: i.prefix,
  //               id: i._id,
  //             }))
  //           );

  //           const suffixList = await axios.get(!props.isUserFixes ? `${APIkey}/api/admin/getClinicSuffixList` : `${APIkey}/api/admin/getSuffixList`, header);

  //           setSuffixData(
  //             suffixList?.data?.data?.map((i) => ({
  //               label: !props.isUserFixes ?  i.suffixName : i.suffix,
  //               value: !props.isUserFixes ?  i.suffixName : i.suffix,
  //               id: i._id,
  //             }))
  //           );
  //         } else {
  //           console.log('No login info found in localStorage');
  //         }
  //       } catch (err) {
  //         console.error('Error:', err);
  //       }
  //     };

  //     getprefixSuffixList(id);
  //   }, [id, props.isUserFixes]);

  // useEffect(() => {
  //     const getprefixSuffixList = async (_id) => {
  //       try {
  //         // Retrieve login information from local storage
  //         const getloginInfo = localStorage.getItem('appLoginInfo'); // Ensure this is defined

  //         // Check if login info exists
  //         if (getloginInfo !== null) {
  //           // Parse the access token from login info
  //           const token = JSON.parse(getloginInfo).data.accessToken;

  //           // Check if the token is available
  //           if (!token) {
  //             console.error('Access token not found');
  //             return; // Exit if token is not found
  //           }

  //           // Set the header for the API request
  //           const header = {
  //             headers: { 'x-access-token': token },
  //           };

  //           // Fetch the prefix list based on the condition of isUserFixes prop
  //           const prefixList = await axios.get(
  //             !props.isUserFixes
  //               ? `${APIkey}/api/admin/getClinicPrefixList`
  //               : `${APIkey}/api/admin/getPrefixList`,
  //             header
  //           );

  //           console.log(prefixList.data.data); // Log the prefix list data

  //           // Update the prefix data in the state
  //           setPrefixData(
  //             prefixList.data.data.map((i) => ({

  //               label: !props.isUserFixes ? i.prefixName : i.prefix, // Set label to prefix
  //               value: !props.isUserFixes ? i.prefixName : i.prefix, // Set value to prefix
  //               id: i._id, // Set the ID
  //             }))
  //           );

  //           // Fetch the suffix list based on the condition of isUserFixes prop
  //           const suffixList = await axios.get(
  //             !props.isUserFixes
  //               ? `${APIkey}/api/admin/getClinicSuffixList`
  //               : `${APIkey}/api/admin/getSuffixList`,
  //             header
  //           );

  //           // Update the suffix data in the state
  //           setSuffixData(
  //             suffixList?.data?.data?.map((i) => ({
  //               label: !props.isUserFixes ? i.suffixName : i.suffix, // Set label based on condition
  //               value: !props.isUserFixes ? i.suffixName : i.suffix, // Set value based on condition
  //               id: i._id, // Set the ID
  //             }))
  //           );
  //         } else {
  //           console.log('No login info found in localStorage');
  //         }
  //       } catch (err) {
  //         console.error('Error:', err); // Log any errors
  //       }
  //     };

  //     // Call the function with the provided id
  //     getprefixSuffixList(id);
  //   }, [id, props.isUserFixes]); // Dependencies to re-run effect when id or props.isUserFixes change

  useEffect(() => {
    const getprefixSuffixList = async (_id) => {
      try {
        if (getloginInfo !== null) {
          const Token = JSON.parse(getloginInfo).data.accessToken;

          const header = {
            headers: {'x-access-token': Token},
          };

          const prefixList = await axios.get(`${APIkey}/api/admin/getClinicPrefixList`, header);

          setPrefixData(
            prefixList.data.data.map((i) => ({
              label: i.prefixName,
              value: i.prefixName,
              id: i._id,
            }))
          );

          const suffixList = await axios.get(`${APIkey}/api/admin/getClinicSuffixList`, header);

          setSuffixData(
            suffixList.data.data.map((i) => ({
              label: i.suffixName,
              value: i.suffixName,
              id: i._id,
            }))
          );
        } else {
          console.log('No login info found in localStorage');
        }

        if (getloginInfo !== null) {
          const Token = JSON.parse(getloginInfo).data.accessToken;

          const header = {
            headers: {'x-access-token': Token},
          };

          const prefixList = await axios.get(`${APIkey}/api/admin/getClinicPrefixList`, header);

          setPrefixData(
            prefixList.data.data.map((i) => ({
              label: i.prefixName,
              value: i.prefixName,
              id: i._id,
            }))
          );

          const suffixList = await axios.get(`${APIkey}/api/admin/getClinicSuffixList`, header);

          setSuffixData(
            suffixList.data.data.map((i) => ({
              label: i.suffixName,
              value: i.suffixName,
              id: i._id,
            }))
          );
        } else {
          console.log('No login info found in localStorage');
        }
      } catch (err) {
        console.error('Error:', err);
      }
    };

    // Call the function with the provided id
    getprefixSuffixList(id);
  }, [id, props.isUserFixes]); // Dependencies to re-run effect when id or props.isUserFixes change

  const getprefix = (e) => {
    props.getPrefixData(e);
  };

  const getsuffix = (e) => {
    props.getSuffixData(e);
  };
  const firstName = (e) => {
    props.getfName(e.target.value);
  };
  const lastName = (e) => {
    props.getlName(e.target.value);
  };
  const middleName = (e) => {
    props.getmName(e.target.value);
  };

  return (
    <>
      <div className={`col-md-6 col-lg-${props.colno} `}>
        <div className='d-flex w-100 gap-2 mb-4'>
          <div className='w-25'>
            <TitleComponent type='label' className='px-1'>
            {inputText.prefix.label}
            </TitleComponent>

            <SearchableDropdown
              options={PrefixData}
              InitialValue={props.prefixValue}
              gettingData={getprefix}
            />
          </div>

          <div className={props.suffixprefixhide !== 1 ? 'w-75' : 'w-100'}>
          <TitleComponent type='label' className='px-1 required'>
            {inputText.lastName.label}
          </TitleComponent>
            <input
              type='text'
              value={props.lastNameValue}
              maxLength={50}
              className='form-control SearchInputbox'
              onChange={lastName}
            />
            {props.lastNameErr && (
              <span className='form-label errMsg'>
                {/* {inputText.lastName.ErrMsg} */}
                <i className='fa-solid fa-triangle-exclamation'></i>
                &nbsp;
                {props.lastNameErr}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className={`col-md-6 col-lg-${props.colno} mb-4`}>
        <div className=' '>
        <TitleComponent type='label' className='px-1 required'>
        {inputText.firstName.label}
        </TitleComponent>
          <input
            type='text'
            value={props.firstNameValue}
            maxLength={50}
            className='form-control SearchInputbox'
            onChange={firstName}
          />
          {props.firstNameErr && (
            <span className='form-label errMsg'>
              <i className='fa-solid fa-triangle-exclamation'></i>
              &nbsp;
              {/* {inputText.firstName.ErrMsg} */}
              {props.firstNameErr}
            </span>
          )}
        </div>
      </div>

      <div className={`col-md-6 col-lg-${props.colno} mb-4`}>
        <div className='d-flex w-100 gap-2'>
          <div className={props.suffixprefixhide !== 1 ? 'w-75' : 'w-100'}>
          <TitleComponent type='label' className='px-1'>
          {inputText.MiddleName.label}
          </TitleComponent>
            <input
              type='text'
              value={props.middleNameValue}
              onChange={middleName}
              maxLength={50}
              className='form-control SearchInputbox'
            />
            {props.middleNameErr && (
              <span className='form-label errMsg'>
                <i className='fa-solid fa-triangle-exclamation'></i>
                &nbsp;
                {/* {inputText.firstName.ErrMsg} */}
                {props.middleNameErr}
              </span>
            )}
          </div>

          <div className='w-25 '>
          <TitleComponent type='label' className='px-1'>
          {inputText.Suffix.label}
          </TitleComponent>
            <SearchableDropdown
              options={SuffixData}
              InitialValue={props.suffixValue}
              gettingData={getsuffix}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default NameCompo;
