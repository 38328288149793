
import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';

const TagsInput = ({ duplicate, max, getTheTags, tagsValue }) => {
  // const [tags, setTags] = useState<any>([]);
  const [inputValue, setInputValue] = useState<any>('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const APIkey = process.env.REACT_APP_SERVERAPI;


  const inputRef = useRef(null);

  const [suggestions, setSuggestions] = useState<any>([])







  useEffect(() => {
    const getTheTags = async () => {
      try {
        let getloginInfo: string | null = localStorage.getItem('appLoginInfo')

        if (getloginInfo !== null) {

          const Token = JSON.parse(getloginInfo)?.data?.accessToken;

          const Id = JSON.parse(getloginInfo).data.clinicId;

          const userId = JSON.parse(getloginInfo).data.id;

          const header = {
            headers: { 'x-access-token': Token }
          }



          const getTheallTags = await axios.get(`${APIkey}/api/admin/getAllDocumentsTags`, header);

          setSuggestions(getTheallTags?.data?.data);


        }
        else {
          console.log("No login info found in localStorage");
        }
      }
      catch (err) {
        console.log(err);
      }
    }
    getTheTags()
  }, [])


  const addTag = () => {
    if (!inputValue.trim()) return;

    if (max !== null && tagsValue.length >= max) {
      console.log('max tags limit reached');
      return;
    }

    if (!duplicate && tagsValue.includes(inputValue)) {
      console.log(`duplicate found "${inputValue}"`);
      return;
    }

    getTheTags([...tagsValue, inputValue]);
    setInputValue('');
  };



  const deleteTag = index => {
    getTheTags(tagsValue.filter((_, i) => i !== index));
  };


  const handleChange = e => {
    setInputValue(e.target.value);
    setTimeout(() => {

      // setCloseSuggestions(true)


      const filtered: any = suggestions?.filter(suggestion =>
        suggestion.toLowerCase().startsWith(e.target.value.toLowerCase())
      );


      setFilteredSuggestions(filtered);

    }, 1000);

  };




  const handleKeyDown = e => {
    if (e.key === 'Enter' || e.key === 'Tab' || e.key === ',') {
      e.preventDefault();
      addTag();
    }
    else if (e.key === 'Backspace' && !inputValue) {
      deleteTag(tagsValue.length - 1);
    }
  };


  const getTheData = (suggestion) => {

    if (max !== null && tagsValue.length >= max) {
      return;
    }


    if (!duplicate && tagsValue.includes(suggestion)) {
      return;
    } else {

      getTheTags([...tagsValue, suggestion])
    }
  }


  return (
    <>
      <div className='inputTag' >

        <div className="tags-input-wrapper w-100 " >
          {tagsValue.map((tag, index) => (
            <span key={index} className="tag">
              {tag}
              <a href="#" onClick={() => deleteTag(index)}>
                &times;
              </a>
            </span>
          ))}


          <input
            type="text"
            ref={inputRef}
            value={inputValue}
            maxLength={50}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />


        </div>

        {
          (filteredSuggestions.length >= 0 && inputValue) &&
          <div className='card mt-1 inputTagsSuggestions' >
            <ul className=' appointmentUl  m-0 py-2 px-2' >
              {

                filteredSuggestions?.map((suggestion) => {

                  return (

                    <li onClick={() => [getTheData(suggestion), setInputValue('')]} className='patient-info-title' key={suggestion}>
                      {suggestion}
                    </li>
                  )
                })

              }

            </ul>
          </div>
        }
      </div>

    </>

  );
};


export default TagsInput 