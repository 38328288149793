// import React, {useState} from 'react'
// import CommonTable from '../../../../ReUsableComponent/CommonTable'
// import plusredbtn from '../../EHRIcon/plusredbtn.svg'
// import { useNavigate } from 'react-router-dom'
// const PreferredList = () => {
//   const [activeTab, setActiveTab] = useState('vaccination')
//   const [data, setData] = useState([
//     {
//       _id: '1',
//       date: '2023-08-01',
//       vaccineName: 'COVID-19',
//       dosage: '2 ml',
//       frequency: 'Once',
//       route: 'Intramuscular',
//       instructions: 'None',
//       notes: 'First dose',
//     },
//     // Add more data entries if needed
//   ])
//   const [perPage, setPerPage] = useState(10)
//   const [currentPage, setCurrentPage] = useState(1)
//   const [searchQuery, setSearchQuery] = useState('')

//   const columns = [
//     {key: ['date'], header: 'Date', isDate: true},
//     {key: ['vaccineName'], header: 'Vaccine Name'},
//     {key: ['dosage'], header: 'Dosage'},
//     {key: ['frequency'], header: 'Frequency'},
//     {key: ['route'], header: 'Route'},
//     {key: ['instructions'], header: 'Instructions'},
//     {key: ['notes'], header: 'Notes'},
//   ]

//   const handleRowClick = (id) => {
//     console.log('Row clicked with ID:', id)
//   }

//   const handleAddClick = () => {
//     console.log('Add clicked!')
//   }
//   const navigate = useNavigate()

//   const handleTabClick = (tab)=>{
//     setActiveTab(tab);
//     navigate(`/${tab}`)
//   }

//   return (
//     <>
//       <div className='clinical-time-container'>
//         <div className='tab-header'>
//           <div className='tabNameStyle'>
//             <div
//               className={activeTab === 'Vaccination' ? 'activeTab' : 'inactiveTab'}
//               onClick={() => handleTabClick('vaccination')}
//             >
//               <div className='tabname'>Vaccination</div>
//             </div>
//             <div
//               className={activeTab === 'allergies' ? 'activeTab' : 'inactiveTab'}
//               onClick={() => handleTabClick('allergies')}
//             >
//               <div className='tabname'>Allergies</div>
//             </div>
//             <div
//               className={activeTab === 'problemList' ? 'activeTab' : 'inactiveTab'}
//               onClick={() => handleTabClick('problemList')}
//             >
//               <div className='tabname'>Problem List</div>
//             </div>
//             <div
//               className={activeTab === 'medication' ? 'activeTab' : 'inactiveTab'}
//               onClick={() => handleTabClick('medication')}
//             >
//               <div className='tabname'>Medication</div>
//             </div>
//             <div
//               className={activeTab === 'preferredSetting' ? 'activeTab' : 'inactiveTab'}
//               onClick={() => handleTabClick('preferredSetting')}
//             >
//               <div className='tabname'>Preferred Setting</div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className='clinical-time-container mt-1'>
//         {activeTab === 'vaccination' && (
//           <>
//             <div className='clinical-time-header'>Vaccination</div>
//             <div>
//               <div className='d-flex gap-2 col-md-12 mt-3'>
//                 <div className='col-md-1.5'>
//                   <label className='vaccine-text mt-1'>Vaccine:</label>
//                 </div>
//                 <div className='col-md-1.5'>
//                   <input type='text' className='SearchInputbox' placeholder='Vaccine Name' />
//                 </div>
//                 <div className='col-md-1.5'>
//                   <input type='text' className='SearchInputbox' placeholder='Dosage' />
//                 </div>
//                 <div className='col-md-1.5'>
//                   <input type='text' className='SearchInputbox' placeholder='Frequency' />
//                 </div>
//                 <div className='col-md-1.5'>
//                   <input type='text' className='SearchInputbox' placeholder='Route' />
//                 </div>
//                 <div className='col-md-1.5'>
//                   <input type='text' className='SearchInputbox' placeholder='Instructions' />
//                 </div>
//                 <div className='col-md-1.5'>
//                   <input type='text' className='SearchInputbox' placeholder='Notes' />
//                 </div>
//                 <div className='col-md-2'>
//                   <button type='button' className='AddBtn' onClick={handleAddClick}>
//                     <img src={plusredbtn} /> Add
//                   </button>
//                 </div>
//               </div>
//               <br />
//             </div>
//           </>
//         )}

//         {activeTab === 'allergies' && (
//           <>
//             <div className='clinical-time-header'>Allergies</div>
//             <div>
//               <div className='d-flex gap-2 col-md-12 mt-3'>
//                 <div className='col-md-1.5'>
//                   <label className='vaccine-text mt-1'>Allergy:</label>
//                 </div>
//                 <div className='col-md-2'>
//                   <input type='text' className='SearchInputbox' placeholder='Allergy Name' />
//                 </div>
//                 <div className='col-md-2'>
//                   <input type='text' className='SearchInputbox' placeholder='Allergy Type' />
//                 </div>

//                 <div className='col-md-2'>
//                   <input type='text' className='SearchInputbox' placeholder='Notes' />
//                 </div>
//                 <div className='col-md-2'>
//                   <button type='button' className='AddBtn' onClick={handleAddClick}>
//                     <img src={plusredbtn} /> Add
//                   </button>
//                 </div>
//               </div>
//               <br />
//             </div>
//           </>
//         )}
//         {activeTab === 'problemList' && (
//           <>
//             <div className='clinical-time-header'>Problems List</div>
//             <div>
//               <div className='d-flex gap-2  mt-3'>
//                 <div>
//                   <label className='vaccine-text  mt-1'>Vaccine:</label>
//                 </div>
//                 <div>
//                   <input type='date' className='SearchInputbox' placeholder='MM/DD/YYYY' />
//                 </div>
//                 <div>
//                   <input type='text' className='SearchInputbox' placeholder='Vaccine Name' />
//                 </div>

//                 <div>
//                   <input type='text' className='SearchInputbox' placeholder='Dosage' />
//                 </div>
//                 <div>
//                   <input type='text' className='SearchInputbox' placeholder='Frequency' />
//                 </div>
//                 <div>
//                   <input type='text' className='SearchInputbox' placeholder='Route' />
//                 </div>
//                 <div>
//                   <input type='text' className='SearchInputbox' placeholder='Instructions' />
//                 </div>
//                 <div>
//                   <input type='text' className='SearchInputbox' placeholder='Notes' />
//                 </div>
//                 <div className='myaddbtn'>
//                   <div>
//                     <img src={plusredbtn} />
//                   </div>
//                   <div>
//                     <button type='button' className='AddBtn' onClick={handleAddClick}>
//                       Add
//                     </button>
//                   </div>
//                 </div>
//               </div>
//               <br />
//             </div>
//           </>
//         )}
//         {activeTab === 'medication' && (
//           <>
//             <div className='clinical-time-header'>Medication</div>
//             <div>
//               <div className='d-flex gap-2  mt-3'>
//                 <div>
//                   <label className='vaccine-text  mt-1'>Vaccine:</label>
//                 </div>
//                 <div>
//                   <input type='date' className='SearchInputbox' placeholder='MM/DD/YYYY' />
//                 </div>
//                 <div>
//                   <input type='text' className='SearchInputbox' placeholder='Vaccine Name' />
//                 </div>
//                 <div>
//                   <input type='text' className='SearchInputbox' placeholder='Dosage' />
//                 </div>
//                 <div>
//                   <input type='text' className='SearchInputbox' placeholder='Frequency' />
//                 </div>
//                 <div>
//                   <input type='text' className='SearchInputbox' placeholder='Route' />
//                 </div>
//                 <div>
//                   <input type='text' className='SearchInputbox' placeholder='Instructions' />
//                 </div>
//                 <div>
//                   <input type='text' className='SearchInputbox' placeholder='Notes' />
//                 </div>
//                 {/* <div className='d-flex'>
//                   <button type='button' className='AddBtn' onClick={handleAddClick}>
//                     <img src={plusredbtn} /> Add
//                   </button>
//                 </div> */}{' '}
//                 <div className='myaddbtn'>
//                   <div>
//                     <img src={plusredbtn} />
//                   </div>
//                   <div>
//                     <button type='button' className='AddBtn' onClick={handleAddClick}>
//                       Add
//                     </button>
//                   </div>
//                 </div>
//               </div>
//               <br />
//             </div>
//           </>
//         )}
//         {activeTab === 'preferredSetting' && (
//           <>
//             <div>
//               <div className='clinical-time-header'>Preferred Setting</div>
//               <div className='d-flex gap-2 col-md-12'>
//                 <div className='col-md-11'>
//                   <div className='d-flex gap-2 col-md-12 mt-3'>
//                     <div className='col-md-1.5'>
//                       <label className='vaccine-text  mt-1 '>Preferred Medications:</label>
//                     </div>
//                     <div className='col-md-1.5'>
//                       <input type='date' className='SearchInputbox ' placeholder='MM/DD/YYYY' />
//                     </div>
//                     <div className='col-md-1.5'>
//                       <input
//                         type='text'
//                         className='SearchInputbox '
//                         placeholder='Medication Name'
//                       />
//                     </div>

//                     <div className='col-md-1.5'>
//                       <input type='text' className='SearchInputbox ' placeholder='Dosage' />
//                     </div>
//                     <div className='col-md-1.5'>
//                       <input type='text' className='SearchInputbox ' placeholder='Frequency' />
//                     </div>
//                     <div className='col-md-1.5'>
//                       <input type='text' className='SearchInputbox ' placeholder='Route' />
//                     </div>
//                     <div className='col-md-1.5'>
//                       <input type='text' className='SearchInputbox ' placeholder='Instructions' />
//                     </div>
//                     <div className='col-md-1.5'>
//                       <input type='text' className='SearchInputbox ' placeholder='Notes' />
//                     </div>
//                     <div className='myaddbtn '>
//                       <div>
//                         <img src={plusredbtn} />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className='col-md-1'>
//                   <button type='button' className='AddBtn' onClick={handleAddClick}>
//                     Add
//                   </button>
//                 </div>
//               </div>
//               <br />
//             </div>
//           </>
//         )}

//         {/* CommonTable component called for active tabs */}
//         {(activeTab === 'vaccination' ||
//           activeTab === 'allergies' ||
//           activeTab === 'problemList' ||
//           activeTab === 'medication') && (
//           <div className='fulltable card'>
//             <div className='my-2 px-3'>
//               <CommonTable
//                 data={data}
//                 totalCount={data.length}
//                 perPage={perPage}
//                 setPerPage={setPerPage}
//                 currentPage={currentPage}
//                 setCurrentPage={setCurrentPage}
//                 columns={columns}
//                 searchQuery={searchQuery}
//                 setQuery={setSearchQuery}
//                 onRowClick={handleRowClick}
//               />
//             </div>
//           </div>
//         )}
//       </div>
//     </>
//   )
// }

// export default PreferredList

import React, {useState} from 'react'
import Vaccination from '../PreferredList/Vaccination'
import Allergies from './Allergies'
import ProblemList from './ProblemList'
import Medication from './Medication'
import PreferredSetting from '../PreferredList/PreferredSetting'
import ChiefComplain from './ChiefComplain'
import HealthIssues from './HealthIssues'
import PatientInstructions from './PatientInstructions'

const PreferredList = () => {
  const [activeTab, setActiveTab] = useState('vaccination')

  const handleTabClick = (tab) => {
    setActiveTab(tab)
  }

  return (
    <>
      <div className='clinical-time-container '>
        <div className='tab-header '>
          <div className='tabNameStyle gap-4'>
            <div
              className={activeTab === 'vaccination' ? 'activeTab' : 'inactiveTab'}
              onClick={() => handleTabClick('vaccination')}
            >
              <div className='tabname'>Vaccination</div>
            </div>
            <div
              className={activeTab === 'allergies' ? 'activeTab' : 'inactiveTab'}
              onClick={() => handleTabClick('allergies')}
            >
              <div className='tabname'>Allergies</div>
            </div>
            <div
              className={activeTab === 'problemList' ? 'activeTab' : 'inactiveTab'}
              onClick={() => handleTabClick('problemList')}
            >
              <div className='tabname'>Problem List</div>
            </div>
            <div
              className={activeTab === 'medication' ? 'activeTab' : 'inactiveTab'}
              onClick={() => handleTabClick('medication')}
            >
              <div className='tabname'>Medication</div>
            </div>
            <div
              className={activeTab === 'chiefComplain' ? 'activeTab' : 'inactiveTab'}
              onClick={() => handleTabClick('chiefComplain')}
            >
              <div className='tabname'>Chief Complain</div>
            </div>
            <div
              className={activeTab === 'healthIssues' ? 'activeTab' : 'inactiveTab'}
              onClick={() => handleTabClick('healthIssues')}
            >
              <div className='tabname'>Health Issues</div>
            </div>



            <div
              className={activeTab === 'patientInstruction' ? 'activeTab' : 'inactiveTab'}
              onClick={() => handleTabClick('patientInstruction')}
            >
              <div className='tabname'>Patient Instructions</div>
            </div>






            <div
              className={activeTab === 'preferredSetting' ? 'activeTab' : 'inactiveTab'}
              onClick={() => handleTabClick('preferredSetting')}
            >
              <div className='tabname'>Preferred Setting</div>
            </div>
          </div>
        </div>
      </div>

      {/* Render the active tab content */}
      <div className='clinical-time-container mt-1'>
        {activeTab === 'vaccination' && <Vaccination />}
        {activeTab === 'allergies' && <Allergies />}
        {activeTab === 'problemList' && <ProblemList />}
        {activeTab === 'medication' && <Medication />}
        {activeTab === 'preferredSetting' && <PreferredSetting />}
        {/* {activeTab === 'preferredSetting' && <PreferredSetting />} */}
        {activeTab === 'chiefComplain' &&  <ChiefComplain/>}
        {activeTab === 'healthIssues' &&  <HealthIssues/>}
        {activeTab === 'patientInstruction' && <PatientInstructions/>}
      </div>
    </>
  )
}

export default PreferredList
