import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'
const EditMedication = ({onShow,onHide, editId}) => {
    const APIkey = process.env.REACT_APP_SERVERAPI || '';
    const [ medication,setMedicineName] = useState('');
    const [ brandName,setBrandName] = useState('');  
    const [form,setForm]=useState('');   //TypesofMedicine
    const [frequency,setFrequency]=useState('');      //DosageInstruction
    const [routeOfAdministration,setRouteofAdministration]=useState('');  //WaytotakeMedicine
    const [storageRequirement,setStorageRequirement]=useState('');
    const [notes,setNotes]=useState('');


    const handleCloseEditModal = () => {
        setMedicineName('')
        setBrandName('')
        setForm('')
        setFrequency('')
        setRouteofAdministration('')
        setStorageRequirement('')
        setNotes('')
        onHide()
    }
    
useEffect(()=>{
  const fetchEditData = async()=>{
    try{
      const getLoginInfo = localStorage.getItem('appLoginInfo')
      console.log( getLoginInfo)
      if(getLoginInfo!==null){
        const {data:{accessToken}}=JSON.parse(getLoginInfo)
        const headers = {'x-access-token': accessToken}


        const response = await axios.get(
          `${APIkey}/api/admin/getPreferredMedicationById/${editId}`,
          {headers}
        )

        const data = response.data.data
        

        setMedicineName(data.medication)
        setBrandName(data.brandName)
        setForm(data.form)
        setFrequency(data.frequency)
        setRouteofAdministration(data.routeOfAdministration)
        setStorageRequirement(data.storageRequirements)
        setNotes(data.notes)
      }else{
        console.log('No login info found in localStorage');
      }
    }catch(error){
      console.log('Error fetching data:',error);
    }
  }
  if(editId){
    fetchEditData()
  }
},[editId])




const handleUpdate = async()=>{
  try{
    const getLoginInfo = localStorage.getItem('appLoginInfo')
    if(getLoginInfo !== null){
      const Token = JSON.parse(getLoginInfo).data.accessToken 
      const headers = {'x-access-token': Token}

      const response = await axios.post(`${APIkey}/api/admin/updatePreferredMedication/${editId}`,
          {notes},
      {headers})
      console.log('Update Data:',response.data.data)
      handleCloseEditModal()
  }else{
    console.error('No login Info found in localStorage')
}
  }catch(error){
    console.error('No login Info found in localStorage')
  }
}

  return (
    <>
    <Modal show={onShow} onHide={handleCloseEditModal} size='lg' centered>
      <Modal.Header closeButton className='h-25px  dialoge align-items-center pb-3'>
      <div className='PageHeaderTitle'>Edit Medication</div>
      </Modal.Header>
      <Modal.Body className='p-2 dialoge'>
        <div className='dashboard-card col-md-12 p-2'>
          <div className='row'>
                {/* MEdication Name */}
                <div className='col-md-3 mb-3'>
              <div className='addvaccinetitle'>Medication Name:</div>
              <input type='text' className='SearchInputbox' value={medication} readOnly disabled/>
            </div>
            {/* Brand Name */}
            <div className='col-md-3 mb-3'>
              <div className='addvaccinetitle'>Brand Name:</div>
              <input type='text' className='SearchInputbox' value={brandName} readOnly disabled/>
            </div>

            {/* Types of Medicine */}
            <div className='col-md-3 mb-3'>
              <div className='addvaccinetitle'>Type of medicine:</div>
              <input type='text' className='SearchInputbox' value={form} readOnly disabled/>
            </div>


            {/* Frequency */}
            <div className='col-md-3 mb-3'>
              <div className='addvaccinetitle'>Dosage Instruction:</div>
              <input type='text' className='SearchInputbox' value={frequency} readOnly disabled/>
            </div>

            {/* Route of Administration */}
            <div className='col-md-3 mb-3'>
              <div className='addvaccinetitle'>Way to take Medicine:</div>
              <input type='text' className='SearchInputbox' value={routeOfAdministration} readOnly disabled/>
            </div>


            {/* Storage Requirements */}
            <div className='col-md-3 mb-3'>
              <div className='addvaccinetitle'>Storage Requirements:</div>
              <input type='text' className='SearchInputbox' value={storageRequirement} readOnly disabled/>
            </div>


            {/* Notes */}
            <div className='col-md-3 mb-3'>
              <div className='addvaccinetitle'>Notes:</div>
              <input type='text' className='SearchInputbox' value={notes} onChange={(e)=>setNotes(e.target.value)} />
            </div>





          </div>
      
        </div>
        <div className='my-2 d-flex justify-content-end gap-3'>
            <button type='button' className='primarybtnBig' onClick={handleUpdate}>Update</button>
            <button type='button' className='tertiarybig' onClick={handleCloseEditModal}>
              Cancel
            </button>
          </div>
      </Modal.Body>
    </Modal>
    </>
  )
}

export default EditMedication
