import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
// import './ClinicalSettingsNav.css'; // Make sure to create and import your CSS file

function ClinicalSettingsNav() {
  return (
    <>
      <div className='d-flex gap-2'>
        <ul className='clinicalSettingMenu'>
          <li>
            <NavLink
              to='/clinicalSetting/accountInfo'
              className={({ isActive }) => (isActive ? ' activeClinicalSettingMenu navLink profileColour' : 'navLink profileColour')}
            >
              Account Information
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/clinicalSetting/usersInfo'
              className={({ isActive }) => (isActive ? 'activeClinicalSettingMenu navLink profileColour' : 'navLink profileColour')}
            >
              Users Information
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/clinicalSetting/updatePermission'
              className={({ isActive }) => (isActive ? 'activeClinicalSettingMenu navLink profileColour' : 'navLink profileColour')}
            >
              Update Permission
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/clinicalSetting/preferredList'
              className={({ isActive }) => (isActive ? 'activeClinicalSettingMenu navLink profileColour' : 'navLink profileColour')}
            >
              Preferred List
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/clinicalSetting/clinicalTime'
              className={({ isActive }) => (isActive ? 'activeClinicalSettingMenu navLink profileColour' : 'navLink profileColour')}
            >
              Clinical Time
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/clinicalSetting/calendar'
              className={({ isActive }) => (isActive ? 'activeClinicalSettingMenu navLink profileColour' : 'navLink profileColour')}
            >
              Calendar
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/clinicalSetting/slotTime'
              className={({ isActive }) => (isActive ? 'activeClinicalSettingMenu navLink profileColour' : 'navLink profileColour')}
            >
              Visit Reason
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/clinicalSetting/chartSettings'
              className={({ isActive }) => (isActive ? 'activeClinicalSettingMenu navLink profileColour' : 'navLink profileColour')}
            >
              Patient Chart
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/clinicalSetting/patientEncounterTemplate'
              className={({ isActive }) => (isActive ? 'activeClinicalSettingMenu navLink profileColour' : 'navLink profileColour')}
            >
             Patient Encounter Form
            </NavLink>
          </li>
        </ul>
        <div style={{ paddingLeft: '180px', width:'100%'}}>
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default ClinicalSettingsNav;
