import React, {useState, useEffect} from 'react'
import CommonTable from '../../../../ReUsableComponent/CommonTable'
// import Vector from '../../EHRIcon/Vector.svg'
// import Deleteicon from '../../EHRIcon/Deleteicon.svg'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import pluswhit from '../../../../ImgesLogos/pluswhit.svg'
//   import Eyebutton from '../../EHRIcon/Eyebutton.svg'
// import AddVaccine from './AddVaccine'
import AddVaccination from '../PreferredList/AddVaccination'
import EditVaccination from '../PreferredList/EditVaccination'

import DeleteModel from '../../../../ReUsableComponent/DeleteModel'
import { Modal } from 'react-bootstrap'
import ViewVaccination from './ViewVaccination'
import tablecolor from '../../../../ReUsableComponent/ReyClinicalinputs.json'


const Vaccination = () => {
  const APIkey = process.env.REACT_APP_SERVERAPI || ''
  const {tab} = useParams()
  const [data, setData] = useState([])
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const [showAddVaccine, setShowAddVaccine] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [saveData, setSaveData] = useState(false)
  const [modalData, setModalData] = useState([])
  const [loadingModalData, setLoadingModalData] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)


  const [ showViewModal, setShowViewModal ] = useState(false );
  const [viewData,setViewData]=useState('')
  const [viewId,setViewId]=useState(null)

  
  const [isEditMode, setIsEditMode] = useState(false)
  const [editId, setEditData] = useState(null)
  
  const [deleteId, setDeleteId] = useState(null) // New state for storing the ID of the item to delete

  const columns = [
    {key: ['srNo'], header: 'Sr.No'},
    {key: ['vaccineName'], header: 'Vaccine Name'},
    {key: ['manufacturer'], header: 'Manufacturer'},
    {key: ['vaccineType'], header: 'Types of Vaccine'},
    {key: ['diseasesTargeted'], header: 'Diseases Targeted'},
    {key: ['dosage'], header: 'Dosage'},
    {key: ['vaccineIdentifier'], header: 'Vaccine Identifier'},
    {key: ['notes'], header: 'Notes'},
    {key: ['actions'], header: 'Actions', isAction: true},
  ]

  //Searching for preferred vaccines Logic below
  const [searchCriteria, setSearchCriteria] = useState({
    vaccineName: '',
    manufacture: '',
    typeOfVaccine: '',
    diseaseTargeted: '',
    dosage: '',
    vaccineIdentifier: '',
  })
  const handleSearchCriteriaChange=(e)=>{
    const {name,value}= e.target
    setSearchCriteria((prevState)=>({
      ...prevState,
      [name]:value,
    }))
  }
  
  const handleSearch = async()=>{
    try{
      const getloginInfo=localStorage.getItem('appLoginInfo');
  if(getloginInfo){
    const Token = JSON.parse(getloginInfo).data.accessToken
    console.log("token" ,Token);
    
    const header = {
      headers: {'x-access-token': Token},
    }
    
      const response = await axios.post(`${APIkey}/api/admin/searchPreferredVaccination`,searchCriteria,header)
      const responseData = response.data.data
      console.log(responseData)
      const searchData = responseData.map((item,index)=>({
        srNo: index + 1,
        vaccineName: item.vaccineName,
                 manufacturer: item.manufacture,
                 vaccineType: item.typeOfVaccine,
                 diseasesTargeted: item.diseaseTargeted,
                 dosage: item.dosageSchedule,
                 vaccineIdentifier: item.vaccineIdentifier,
                 notes: item.notes,
        actions: (
          <div className='actionbtn'>
            <button onClick={() => handleView(item._id)} className='editbtn'>
              <img 
              // src={Eyebutton} 
              alt='view' />
            </button>
            <button onClick={() => handleEdit(item._id)} className='editbtn'>
              <img 
              // src={Vector} 
              alt='Edit' />
            </button>
            <button onClick={() => openDeleteModal(item._id)} className='editbtn'>
              <img 
              // src={Deleteicon} 
              alt='Delete' />
            </button>
          </div>
        ),
  
  
      }))
      setData(searchData)
  }
    }catch(error){
   console.log('Error fetching data')
    }
  }








  const handleRowClick = (id) => {
    console.log('Row clicked with ID:', id)
  }

  const fetchData = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const header = {headers: {'x-access-token': Token}}

        const response = await axios.get(`${APIkey}/api/admin/getPreferredVaccinationList`, header)
        console.log('Fetched data:', response.data.data)

        const responseData = response.data.data
        console.log(responseData)

        const formattedData = responseData.map((item, index) => {
          const truncatedDosage = item.dosageSchedule.split(';')[0] || 'N/A'

          return {
            srNo: index + 1,
            vaccineName: item.vaccineName,
            manufacturer: item.manufacture,
            vaccineType: item.typeOfVaccine,
            diseasesTargeted: item.diseaseTargeted,
            dosage: truncatedDosage,
            vaccineIdentifier: item.vaccineIdentifier,
            notes: item.notes,
            actions: (
              <div className='actionbtn'>
                   <button onClick={()=>handleView(item._id)} className='editbtn'>
                  <img 
                  // src={Eyebutton} 
                  alt='view'/>
                </button>
                <button onClick={() => handleEdit(item._id)} className='editbtn'>
                  <img 
                  // src={Vector} 
                  alt='Edit' />
                </button>
                <button onClick={() => openDeleteModal(item._id)} className='editbtn'>
                  <img 
                  // src={Deleteicon}
                   alt='Delete' />
                </button>
             
             
              </div>
            ),
          }
        })

        setData(formattedData)
      } else {
        console.log('No login info found in localStorage')
      }
    } catch (error) {
      console.error('Error fetching vaccination data:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [tab, showModal, showEditModal,])

  const handleEdit = (_id) => {
    setEditData(_id)
    setIsEditMode(true)
    setShowEditModal(true)
  }

  const handleView = (_id) => {
    setViewData(_id)
    setViewId(_id)
    setShowViewModal(true)

  }

  const openDeleteModal = (id) => {
    setDeleteId(id) // Store the ID of the item to delete
    setShowDeleteModal(true) // Show the delete modal
  }

  const openAddModal = (_id)=>{
    setViewData(_id)
    setShowViewModal(true);
  }

  const handleDelete = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const header = {headers: {'x-access-token': Token}}

        await axios.delete(`${APIkey}/api/admin/deletePreferredVaccination/${deleteId}`, header)
        console.log('Row deleted successfully')

        fetchData()
        setShowDeleteModal(false) // Close the modal after successful deletion
      } else {
        console.log('No login info found in localStorage')
      }
    } catch (error) {
      console.error('Error deleting vaccination:', error)
    }
  }

  const handleAddClick = async () => {
    setIsEditMode(false)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setEditData(null)
    setShowEditModal(false)
    setShowDeleteModal(false)
    setShowViewModal(false)
  }

 const handleClearClick = ()=>{
   setSearchCriteria({
    vaccineName: '',
    manufacture: '',
    typeOfVaccine: '',
    diseaseTargeted: '',
    dosage: '',
    vaccineIdentifier: '',
    
   })
   fetchData()
 }
  return (
    <>
      <div className='clinical-time-header'>
        <div className='titletextformat d-flex'>
          <div className='PageHeaderTitle'>Vaccination</div>
          <div>
            {' '}
            <button className='add-Vaccine-btn' onClick={handleAddClick}>
              <img 
              src={pluswhit} 
              alt='Add Vaccine' /> Add Vaccine
            </button>
          </div>
        </div>
      </div>

      <div>
        <div className='d-flex gap-2  mt-3'>
         
          <div className=''>
            <input
              type='text'
              className='SearchInputbox'
              name='vaccineName'
              placeholder='Vaccine Name'
              value={searchCriteria.vaccineName}
              onChange={handleSearchCriteriaChange}
            />
          </div>
          <div className=''>
            <input
              type='text'
              className='SearchInputbox'
              name='manufacture'
              placeholder='Manufacturer'
              value={searchCriteria.manufacture}
              onChange={handleSearchCriteriaChange}
            />
          </div>
          <div className=''>
            <input
              type='text'
              className='SearchInputbox'
              name='typeOfVaccine'
              placeholder='Types of Vaccine'
              value={searchCriteria.typeOfVaccine}
              onChange={handleSearchCriteriaChange}
            />
          </div>
          <div className=''>
            <input
              type='text'
              className='SearchInputbox'
              name='diseaseTargeted'
              placeholder='Diseases Targeted'
              value={searchCriteria.diseaseTargeted}
              onChange={handleSearchCriteriaChange}
            />
          </div>
          <div className=''>
            <input
              type='text'
              className='SearchInputbox'
              name='dosage'
              placeholder='Dosage'
              value={searchCriteria.dosage}
              onChange={handleSearchCriteriaChange}
            />
          </div>
          <div className=''>
            <input
              type='text'
              className='SearchInputbox'
              name='vaccineIdentifier'
              placeholder='Vaccine Identifier'
              value={searchCriteria.vaccineIdentifier}
              onChange={handleSearchCriteriaChange}
            />
          </div>
          {/* <div className='col-md-1.5'>
            <input
              type=''
              className='SearchInputbox'
              name='notes'
              placeholder='Notes'
              value={searchCriteria.notes}
              onChange={handleSearchCriteriaChange}
            />
          </div> */}
          <div className='gap-1 d-flex'>
            <button type='button' className='AddBtn' onClick={handleSearch}>
              Search
            </button>
            <button type='button' className='grayButton' onClick={handleClearClick}>
            Clear
          </button>
          </div>
        </div>
        <br />
      </div>
      <div className='clinical-time-container'>
        <div>
          {/* <div className='my-2 px-3'> */}
          {data.length ? (
            <CommonTable
              data={data}
              columns={columns}
              currentPage={currentPage}
              perPage={perPage}
              totalCount={data.length}
              setCurrentPage={setCurrentPage}
              setPerPage={setPerPage}
              searchQuery={searchQuery}
              setQuery={setSearchQuery}
              onRowClick={handleRowClick}
              headerColor={tablecolor.primaryTable.color}
              issetPaginationCompo={true}
              isPagelength={true}
              isSearched={true}
            />
          ) : (
            <p>No vaccination data available</p>
          )}
        </div>
      </div>
      {/* </div> */}

      <DeleteModel
        openDelete={showDeleteModal}
        closedelete={handleCloseModal}
        deletedEntity={handleDelete}
        deleteID={deleteId}
        deleteFunction={handleDelete}
      />
      <AddVaccination onShow={showModal} onHide={handleCloseModal} onClick={saveData} />
      <EditVaccination onshow={showEditModal} onHide={handleCloseModal} editId={editId} />
      <ViewVaccination onshow={showViewModal} onhide={handleCloseModal} viewId={viewId} />






    </>
  )
}

export default Vaccination
