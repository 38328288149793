import React, { useEffect, useState } from 'react';
import clinic from '../../../ImgesLogos/clinic.svg';
import users from '../../../ImgesLogos/users.svg';
import filterlines from '../../../ImgesLogos/filter-lines.svg';
import arrowright from '../../../ImgesLogos/arrowright.svg';
import Subscription from '../../../ImgesLogos/Subscription.svg'
import axios from 'axios';
import { toast } from 'react-toastify';
import Capa_1 from '../EHRIcon/Capa1.svg'
import staff from '../EHRIcon/staff.svg'
import appointment from '../EHRIcon/appointment.svg'
import DashboardChart from '../../../SuperAdmin/Pages/Dashboard/DashboardChart';
import ClinicDashboardChart from '../dashboard/ClinicDashboardChart';



const APIkey = process.env.REACT_APP_SERVERAPI;

const DashboardWrapper = () => {

  const [totalUsers, setTotalUsers] = useState(0);
  const [totalPatients, setTotalPatients] = useState(0);
  const [totalAppointments, setTotalAppointments] = useState(0);


  useEffect(() => {

    const fetchClinicDashBoard = async()=>{
      try{
        const getLoginInfo = localStorage.getItem('appLoginInfo');
        if(getLoginInfo){
          const { accessToken } = JSON.parse(getLoginInfo).data;
          const response = await axios.get(`${APIkey}/api/admin/clinicDashboard/totalCounts`, {
            headers: { 'x-access-token': accessToken },
          });
          const responseData = response.data.data
          console.log("Dashboard:",responseData);
          setTotalAppointments(responseData.totalAppointments)
          setTotalUsers(responseData.totalUsers)
          setTotalPatients(responseData.totalPatients)
        }

      }catch(error){
        console.log("Error:",error);


      }
    }
    fetchClinicDashBoard();
   },[]);
  
  return (
<>
<div className='dashboard-wrapper-clinic d-flex '>
  <div className='col-md-4'>
<div className='dashboard-card '>
          <div className='card-header d-flex'>
            <img src={Capa_1} alt='Clinics' className='dashboard-image' />
            <div className='card-title'>Patient</div>
            <img src={filterlines} alt='Filter' className='filter-icon' />
          </div>
          <div className='card-body'>
            <div className='card-subtitle'>Total</div>
            <div className='card-data d-flex'>
              <span className='total-number'>{totalPatients} </span>
              <span className='percentage'>- 45% from the day</span>
            </div>
          </div>
          <div className='card-footer d-flex'>
            View More
            <img src={arrowright} alt='Arrow Right' />
          </div>
        </div>
        <div className='dashboard-card mt-3'>
          <div className='card-header d-flex'>
            <img src={staff} alt='Clinics' className='dashboard-image' />
            <div className='card-title'>Staff</div>
            <img src={filterlines} alt='Filter' className='filter-icon' />
          </div>
          <div className='card-body'>
            <div className='card-subtitle'>Total</div>
            <div className='card-data d-flex'>
              <span className='total-number'>{totalUsers} </span>
              <span className='percentage'>- 45% from the day</span>
            </div>
          </div>
          <div className='card-footer d-flex'>
            View More
            <img src={arrowright} alt='Arrow Right' />
          </div>
        </div>
        </div>
<div className="col-md-4">
        <div className='dashboard-card '>
          <div className='card-header d-flex'>
            <img src={appointment} alt='Clinics' className='dashboard-image' />
            <div className='card-title'>Appointment</div>
            <img src={filterlines} alt='Filter' className='filter-icon' />
          </div>
          <div className='card-body'>
            <div className='card-subtitle'>Total</div>
            <div className='card-data d-flex'>
              <span className='total-number'>{totalAppointments} </span>
              <span className='percentage'>- 45% from the day</span>
            </div>
          </div>
          <div className='card-footer d-flex'>
            View More
            <img src={arrowright} alt='Arrow Right' />
          </div>
          </div>
          <div className='dashboard-card mt-3'>
          <div className='card-header d-flex'>
            {/* <img src={clinic} alt='Clinics' className='dashboard-image' /> */}
            <div className='card-title'>Today Appointment </div>
            <img src={filterlines} alt='Filter' className='filter-icon' />
          </div>
          <div className='card-body'>
            <table className='tableBody'>
          <tr className='tableBody'> 
            <td>Image</td>
            <td>Name</td>
          <td>10:30</td>
          <td><img src={arrowright} alt='Arrow Right' /></td></tr>
          <tr className='tableBody'> 
            <td>Image</td>
            <td>Name</td>
          <td>10:30</td>
          <td><img src={arrowright} alt='Arrow Right' /></td></tr>
          <tr className='tableBody'> 
            <td>Image</td>
            <td>Name</td>
          <td>10:30</td>
          <td><img src={arrowright} alt='Arrow Right' /></td></tr>
          </table>
          </div>
          <div className='card-footer d-flex'>
        
            
          </div>
        </div>
        </div>

        <div className='dashboard-card col-md-4 '>
          <div className='card-header d-flex'>
            {/* <img src={clinic} alt='Clinics' className='dashboard-image' /> */}
            <div className='card-title'>Assigned Tasks</div>
            <img src={filterlines} alt='Filter' className='filter-icon' />
            </div>
            <div>
            <table className='tableBody'>
          <tr className='tableBody'> 
            <td>Patient Follow-up</td>
            <td>Due to 6 Aug 2024</td>
          <td>Almost Done</td>
          <td><img src={arrowright} alt='Arrow Right' /></td></tr>
          <tr className='tableBody'> 
            <td>Patient Follow-up</td>
            <td>Due to 6 Aug 2024</td>
          <td>Almost Done</td>
          <td><img src={arrowright} alt='Arrow Right' /></td></tr>
          <tr className='tableBody'> 
            <td>Update Patient Records</td>
            <td>Due to 6 Aug 2024</td>
            <td>In Progress</td>
          <td><img src={arrowright} alt='Arrow Right' /></td></tr>
          </table>
          </div>
          {/* <div className='card-body'>
            <div className='card-subtitle'>Total</div>
            <div className='card-data d-flex'>
              <span className='total-number'>{totalPatients} </span>
              <span className='percentage'>- 45% from the day</span>
            </div>
          </div> */}
          {/* <div className='card-footer d-flex'>
            View More
            <img src={arrowright} alt='Arrow Right' />
          </div> */}
        </div>
</div>

<div className='dashboard-card'>
        <h5>Annual Growth of Patient</h5>
        <ClinicDashboardChart/>
      </div>
</>
  )
}

export default DashboardWrapper;