import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import ConvertTo12Hour from '../../../../ReUsableComponent/ConvertTo12Hour';
import CryptoConverter from '../../../../ReUsableComponent/CryptoConverter';
import HistoryLogIcon from '../../EHRIcon/HistoryLog.svg';
import PatientHistoryLogPopUp from './PatientHistoryLogPopUp';

function PatientOrVisitDetailsPopUp({ open, closePatientOrVisitDetails, futureAppointment, patientDetails, AppointmentData }) {

    const APIkey = process.env.REACT_APP_SERVERAPI;

    const [folderNameData, setFolderNameData] = useState<any>([])

    const handelClose = () => {
        closePatientOrVisitDetails()
    }


    // console.log("pd" , patientDetails);
    


    const [patientCategory, setPatientCategory] = useState<any>()
    const [modeOfReminder, setmodeOfReminder] = useState<any>()
    const [preferredLanguage, setPreferredLanguage] = useState<any>()

    const [appointmentById, setAppointmentById] = useState<any>()
    const [bookedByData, setBookedByData] = useState<any>()
    const [updatedByData, setUpdatedByData] = useState<any>()
    const [openHistoryLog, setOpenHistoryLog] = useState<any>(false)



    useEffect(() => {
        const getThealllist = async () => {
            try {
                let getloginInfo = localStorage.getItem('appLoginInfo');
                
                if (getloginInfo !== null) {
                    const loginInfo = JSON.parse(getloginInfo);
                    const Token = loginInfo.data.accessToken;
                    const Id = loginInfo.data.clinicId;
    
                    const header = {
                        headers: { 'x-access-token': Token }
                    };
    
                    const patientCategoryRequest = axios.get(`${APIkey}/api/admin/getPatientCategoryList`, header);
                    const modeOfReminderRequest = axios.get(`${APIkey}/api/admin/getModeOfReminderList`, header);
                    const preferredLanguageRequest = axios.get(`${APIkey}/api/admin/getPreferredLanguageList`, header);
                    const appointmentByIdRequest = axios.get(`${APIkey}/api/admin/getAppointmentById/${AppointmentData?._id}`, header);
                    const bookedByUserRequest = axios.get(`${APIkey}/api/admin/getUserById/${Id}/${AppointmentData?.createdBy}`, header);
    
                    const [patientCategoryResponse, modeOfReminderResponse, preferredLanguageResponse, appointmentByIdResponse, bookedByUserResponse] = await Promise.all([
                        patientCategoryRequest,
                        modeOfReminderRequest,
                        preferredLanguageRequest,
                        appointmentByIdRequest,
                        bookedByUserRequest
                    ]);
    
                    const patientCategory = patientCategoryResponse?.data?.data?.find(i => i?._id == patientDetails?.patientCategoryId)?.patientCategory;
                    const modeOfReminder = modeOfReminderResponse?.data?.data?.find(i => i?._id == patientDetails?.modeOfReminderId)?.modeOfReminder;
                    const preferredLanguage = preferredLanguageResponse?.data?.data?.find(i => i._id == patientDetails?.preferredLanguage)?.preferredLanguage;
    
                    setPatientCategory(patientCategory);
                    setmodeOfReminder(modeOfReminder);
                    setPreferredLanguage(preferredLanguage);
                    setAppointmentById(appointmentByIdResponse?.data?.data);
                    setBookedByData(bookedByUserResponse?.data?.data);
    
                    if (AppointmentData?.updatedBy) {
                        const updatedByUserResponse = await axios.get(`${APIkey}/api/admin/getUserById/${Id}/${AppointmentData?.updatedBy}`, header);
                        setUpdatedByData(updatedByUserResponse?.data?.data);
                    }
                } else {
                    console.log("No login info found in localStorage");
                }
            } catch (err) {
                console.log(err);
            }
        };
    
        getThealllist();
    }, []);
    



    let date = (date) => moment(date).format('MM/DD/YYYY')


    const getTime = (dateTimeString) => {
        // Parse the date-time string with moment.js
        const time = moment(dateTimeString).format('HH:mm');
        return time;
    };


    const closeHistoryLog =()=> {
        setOpenHistoryLog(false)
    }

    console.log(openHistoryLog);
    

   const bookedBy = `${bookedByData?.firstName} ${bookedByData?.lastName} on ${date(appointmentById?.createdDate)} ${ConvertTo12Hour(getTime(appointmentById?.createdDate))}`
   
                                                        
   const UpdatedBy = `${updatedByData?.firstName} ${updatedByData?.lastName} on ${date(appointmentById?.updatedDate)} ${ConvertTo12Hour(getTime(appointmentById?.updatedDate))}`

    return (
        <>
        <Modal
            show={open}
            onHide={handelClose}
            className=''
            size='xl'
            centered
        >
            <Modal.Header className='dialoge px-6 py-3' closeButton>
                <Modal.Title >
                    Patient / Visit Detail (Visit ID: 2109015)
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='dialoge px-4 py-0'>
                <div className='row'>
                    <div className='col-md-12 col-lg-6  '>
                        <div className='card'>
                            <div className='PatientChartCardHeader'>
                                Patient Details
                            </div>
                            <table className='CurveTable'>
                                <tbody>
                                    <tr className='tableBody'>
                                        <td>
                                            Patient Name:
                                        </td>
                                        <td>
                                            {patientDetails?.firstName} {patientDetails?.lastName}
                                        </td>
                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            MRN:
                                        </td>
                                        <td>
                                            {patientDetails?.mrn}
                                        </td>
                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            SSN:
                                        </td>
                                        <td>
                                            {CryptoConverter(patientDetails?.ssn)}
                                        </td>
                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            DOB:
                                        </td>
                                        <td>
                                            {date(patientDetails?.dob)}
                                        </td>
                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            Patient Category:
                                        </td>
                                        <td>
                                            {patientCategory && patientCategory}
                                        </td>
                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            Mode of Reminder:
                                        </td>
                                        <td>
                                            {modeOfReminder && modeOfReminder}
                                        </td>
                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            Email:
                                        </td>
                                        <td>
                                            {CryptoConverter(patientDetails?.emailAddress)}
                                        </td>
                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            Preferred Language:
                                        </td>
                                        <td>
                                            {preferredLanguage && preferredLanguage}
                                        </td>
                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            Address:
                                        </td>
                                        <td>
                                            {patientDetails?.addressLine1}{patientDetails?.addressLine2 ? ',' : ''} {patientDetails?.addressLine2}
                                        </td>
                                    </tr>

                                    <tr className='tableBody'>
                                        <td>
                                            Cell Phone No.:
                                        </td>
                                        <td>
                                            {patientDetails?.mobileCountryCode}  {CryptoConverter(patientDetails?.mobileNo)}
                                        </td>
                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            Home Phone No.:
                                        </td>
                                        <td>
                                            {patientDetails?.homeCountryCode} {CryptoConverter(patientDetails?.homeNo)}
                                        </td>
                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            Work Phone No.:
                                        </td>
                                        <td>
                                            {patientDetails?.workCountryCode} {CryptoConverter(patientDetails?.workPhoneNo)}
                                        </td>
                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            Guarantor:
                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            Additional Contact:
                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    {/* <tr className='tableBody'>
                                        <td>
                                            Card on File:
                                        </td>
                                        <td>
                                            No
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='col-md-12 col-lg-6  '>
                        <div className='card'>
                            <div className='PatientChartCardHeader'>
                                Visit Details
                            </div>
                            <table className='CurveTable'>
                                <tbody>
                                    <tr className='tableBody'>
                                        <td>
                                            Visit Status:
                                        </td>
                                        <td>
                                            {appointmentById?.appStatusData?.appointmentStatus}
                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            Visit Type:
                                        </td>
                                        <td>
                                            {appointmentById?.appTypeData?.appointmentType}
                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            Visit Time:
                                        </td>
                                        <td>
                                            {ConvertTo12Hour(appointmentById?.appointmentTime)}
                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            Insurance:
                                        </td>
                                        <td>
                                            {appointmentById?.patientinsuranceDetails?.insurancePlanName}
                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            Collected Copay:
                                        </td>
                                        <td>
                                            {/* View Outstanding Copay */}
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            Patient Balance ($):
                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            Patient Unapplied Credit ($):
                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            Provider:
                                        </td>
                                        <td>
                                            {appointmentById?.providerData?.firstName} {appointmentById?.providerData?.lastName}
                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            Pri.Authorization:
                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                    </tr>

                                    <tr className='tableBody'>
                                        <td>
                                            Sec.Authorization:
                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            Ter.Authorization:
                                        </td>
                                        <td>

                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            Booked By:
                                        </td>
                                        <td>
                                        {bookedBy}
                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <tr className='tableBody ' >
                                        <td>
                                            Last Modified By:
                                        </td>
                                        {
                                            updatedByData ? (
                                                <>
                                                    <td className=''>
                                                   {UpdatedBy}
                                                    </td>
                                                    <td className=''>
                                                        <div
                                                        style={{cursor:"pointer"}} 
                                                        onClick={()=> setOpenHistoryLog(true)}       
                                                        className=''>
                                                            <img src={HistoryLogIcon} alt="" />
                                                        </div>
                                                    </td>
                                                </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <td>

                                                        </td>
                                                        <td>

                                                        </td>
                                                    </>
                                                )


                                        }


                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            Resorce:
                                        </td>
                                        <td>
                                           
                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            POS Code:
                                        </td>
                                        <td>
                                           {appointmentById?.posId}
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            Comment:
                                        </td>
                                        <td>
                                            {appointmentById?.notes}
                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <tr className='tableBody'>
                                        <td>
                                            Cancelled Reason:
                                        </td>
                                        <td>
                                         
                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div>
                    <div className='card mt-2'>
                        <div className='PatientChartCardHeader'>
                            Future Appointments
                        </div>
                        <table className='CurveTable'>
                            <tbody>
                                <tr className='tableBody'>
                                    <td>
                                        Patient Have Future Appointment:
                                    </td>
                                    <td>
                                        {
                                            futureAppointment.map((i: any, index) => {

                                                let date = moment(i?.appointmentDate).format('YYYY-MM-DD');


                                                return (
                                                    <span>
                                                        {date} {ConvertTo12Hour(i?.appointmentTime)}{futureAppointment.length === index + 1 ? '' : ', '} &nbsp;&nbsp;
                                                    </span>
                                                )
                                            })
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='dialoge'>
            </Modal.Footer>

         

        </Modal>
           {
            openHistoryLog && (
                <PatientHistoryLogPopUp 
                open={openHistoryLog} 
                closePopUp={closeHistoryLog} 
                 AppointmentId ={AppointmentData?._id}
                 
                />
            )
        }
        </>

    )
}

export default PatientOrVisitDetailsPopUp