import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import CryptoJS from 'crypto-js'
const EditCsCalender = ({ onShow, onHide, editId }) => {
  const APIkey = process.env.REACT_APP_SERVERAPI || '';
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [notes, setNotes] = useState('');
  const [occasion, setOccasion] = useState('');

  const handleCloseEditModal = () => {
    setFrom('');
    setTo('');
    setNotes('');
    setOccasion('');
    onHide();
  };

  // // Helper function to format the date to dd/mm/yyyy for display
  // const formatToDisplayDate = (dateStr) => {
  //   if (!dateStr) return '';
  //   const date = new Date(dateStr);
  //   const day = String(date.getDate()).padStart(2, '0');
  //   const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  //   const year = date.getFullYear();
  //   return `${day}/${month}/${year}`; // Returns dd/mm/yyyy format
  // };

  // Helper function to format the date to yyyy-mm-dd (for input[type="date"])
  const formatToDateInput = (dateStr) => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    return date.toISOString().split('T')[0]; // Returns yyyy-mm-dd format
  };

  const fetchEditData = async () => {
    try {
      const getLoginInfo = localStorage.getItem('appLoginInfo');
      if (getLoginInfo !== null) {
        const { accessToken, clinicId } = JSON.parse(getLoginInfo).data;
        const headers = { 'x-access-token': accessToken };
        const response = await axios.get(
          `${APIkey}/api/admin/getCalenderById/${editId}`,
          { headers }
        );
        const data = response.data.data;

        // Set formatted date for input fields (yyyy-mm-dd)
        setFrom(formatToDateInput(data.from));
        setTo(formatToDateInput(data.to));

        // Set occasion and notes
        setOccasion(data.occasion);
        setNotes(data.notes);
      } else {
        console.log('No login info found in localStorage');
      }
    } catch (error) {
      console.error('Error in fetching data:', error);
    }
  };

  useEffect(() => {
    if (onShow && editId) {
      fetchEditData();
    }
  }, [onShow, editId]); 

  const handleUpdate = async () => {
    try {
      const getLoginInfo = localStorage.getItem('appLoginInfo');
      if (getLoginInfo !== null) {
        const { accessToken, clinicId } = JSON.parse(getLoginInfo).data;
        const headers = { 'x-access-token': accessToken };

        const response = await axios.post(
          `${APIkey}/api/admin/updateCalendar/${editId}`,
          { from, to, occasion, notes },
          { headers }
        );
        console.log('Updated Data:', response.data.data);
        handleCloseEditModal();
      } else {
        console.error('No login info found in localStorage');
      }
    } catch (error) {
      console.log('Error updating data:', error);
    }
  };

  return (
    <>
      <Modal show={onShow} onHide={handleCloseEditModal} size="lg" centered>
        <Modal.Header closeButton className="h-25px dialoge align-items-center pb-3">
          <div className="PageHeaderTitle">Edit Calendar</div>
        </Modal.Header>
        <Modal.Body className="p-2 dialoge">
          <div className="dashboard-card col-md-12 p-2">
            <div className="row">
              <div className="col-md-4 mb-3">
                <div className="addvaccinetitle">From:</div>
                <input
                  type="date"
                  className="SearchInputbox"
                  value={from}
                  onChange={(e) => setFrom(e.target.value)}
                />

                <div className="addvaccinetitle">Notes:</div>
                <input
                  type="text"
                  className="SearchInputbox"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </div>
              <div className="col-md-4 mb-3">
                <div className="addvaccinetitle">To:</div>
                <input
                  type="date"
                  className="SearchInputbox"
                  value={to}
                  onChange={(e) => setTo(e.target.value)}
                />
              </div>
              <div className="col-md-4 mb-3">
                <div className="addvaccinetitle">Occasion:</div>
                <input
                  type="text"
                  className="SearchInputbox"
                  value={occasion}
                  onChange={(e) => setOccasion(e.target.value)}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="my-2 d-flex justify-content-end gap-3">
            <button type="button" className="primarybtnBig" onClick={handleUpdate}>
              Update
            </button>
            <button type="button" className="tertiarybig" onClick={handleCloseEditModal}>
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditCsCalender;
