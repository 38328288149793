import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'

const ViewPatientInstructions = ({ onshow, onhide, viewId }) => {
    const APIkey = process.env.REACT_APP_SERVERAPI
    const [notes, setNotes] = useState('')
    const [category, setCategory] = useState('')
    const [instruction, setInstructions] = useState('')

    useEffect(() => {
        const fetchPatientInstructionsdata = async () => {
            try {
                const getLoginInfo = localStorage.getItem('appLoginInfo')
                console.log('getLoginInfo: ', getLoginInfo)
                if (getLoginInfo) {
                    const { data: { accessToken } } = JSON.parse(getLoginInfo) // Corrected property name

                    const headers = { 'x-access-token': accessToken }

                    const response = await axios.get(
                        `${APIkey}/api/admin/getPreferredPatientInstructionById/${viewId}`,
                        { headers }
                    )
                    const data = response.data.data
                    console.log("chief complaint data ", data)
                    setCategory(data.category)
                    setNotes(data.notes)
                    setInstructions(data.instruction)
                } else {
                    console.error('No login info found in localStorage')
                }
            } catch (error) { // Catch the error properly
                console.log('Error fetching data:', error)
            }
        }
        if (viewId) {
            fetchPatientInstructionsdata()
        }

    }, [viewId, onshow, APIkey]);

    const handleCloseModal = () => {
        setInstructions('')
        setNotes('')
        setCategory('')
        onhide()
    }

    return (
        <>
            <Modal show={onshow} onHide={handleCloseModal} size='lg' centered>
                <Modal.Header closeButton className='h-25px dialoge align-items-center pb-3'>
                    <div className='PageHeaderTitle'> Patient Instruction Information</div>
                </Modal.Header>
                <Modal.Body className='p-2 dialoge'>
                    <div className='dashboard-card'>
                        <div className='col-md-12'>
                            <div className='mb-3'>
                                <label className='viewlabels'>Patient Instructions:</label>
                                <div className='vaccine-label-data'>{instruction}</div>
                            </div>
                            <div className='mb-3'>
                                <label className='viewlabels'>Category:</label>
                                <div className='vaccine-label-data'>{category}</div>
                            </div>
                            <div className='mb-3'>
                                <label className='viewlabels'>Notes:</label>
                                <div className='vaccine-label-data'>{notes}</div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='my-2 d-flex justify-content-end gap-3'>
                        <button type='button' className='tertiarybig' onClick={handleCloseModal}>
                            Close
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ViewPatientInstructions
