import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'
import {Modal} from 'react-bootstrap'
import CommonTable from '../../../ReUsableComponent/CommonTable'
import DeleteModel from '../../../ReUsableComponent/DeleteModel' // Import the DeleteModel component
import tablecolor from '../../../ReUsableComponent/ReyClinicalinputs.json'
// import searchiconwhite from '../EHRIcon/searchiconwhite.svg'
import fluentedit from '../../../ImgesLogos/fluentedit.svg';
import fluentdelete from '../EHRIcon/fluentdelete.svg'
import eyeView from '../EHRIcon/eyeView.svg'
import {actions} from 'react-table'
import ViewUserInfo from './ViewUserInfo'
import {log} from 'console'

const APIkey = process.env.REACT_APP_SERVERAPI

interface User {
  _id: string
  userCode: string
  npiId: string
  username: string
  designation: {
    designation: string
  }
  role: {
    role: string
  }
  email: string
  createdDate: string
  status: string
}

const UserInfo = () => {
  const [userInfo, setUserInfo] = useState<User[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [deleteUserId, setDeleteUserId] = useState<string | null>(null)
  const [totalCount, setTotalCount] = useState(0)
  const [formData, setFormData] = useState<Partial<User>>({})


  

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const openDeleteModal = (userId: string) => {
    setDeleteUserId(userId)
    setIsDeleteModalOpen(true)
  }
  const closeDeleteModal = () => setIsDeleteModalOpen(false)

  useEffect(() => {
    const fetchUserList = async () => {
      try {
        const getLoginInfo = localStorage.getItem('appLoginInfo')
        if (getLoginInfo) {
          const {accessToken, clinicId} = JSON.parse(getLoginInfo).data
          const {data} = await axios.get(`${APIkey}/api/admin/listUsersByClinicId/${clinicId}`, {
            headers: {'x-access-token': accessToken},
          })

          // Format the fetched data based on the columns
          const formattedData = (data?.data || []).map((item) => ({
            userCode: item.userCode,
            npiId: item.npiId,
            username: item.username,
            designation: item.designation,
            role: item.role,
            email: item.email,
            createdDate: new Date(item.createdDate).toLocaleDateString(), // Convert to a readable date
           
            status: item.status === 1 ? 'active' : 'inactive', 
          
            actions: (
              <>
                <div>
                  <img
                    src={eyeView}
                    onClick={() => handleView(item._id)}
                    className='px-2'
                    alt='View'
                  />
                  <img
                    src={fluentedit}
                    onClick={() => handleEdit(item._id)}
                    className='px-2'
                    alt='Edit'
                  />
                  <img
                    src={fluentdelete}
                    onClick={() => openDeleteModal(item._id)}
                    className='px-2'
                    alt='Delete'
                  />
                </div>
              </>
            ),
          }))

          

          setUserInfo(formattedData)
          setTotalCount(data?.totalCount || 0)
        }
      } catch (error) {
        toast.error('Failed to load users')
      }
    }

    fetchUserList()
  }, [currentPage, perPage, searchQuery])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleEdit = async (userId: string) => {
    try {
      const getLoginInfo = localStorage.getItem('appLoginInfo')
      if (getLoginInfo) {
        const {accessToken, clinicId} = JSON.parse(getLoginInfo).data
        const {data} = await axios.get(`${APIkey}/api/admin/getUserById/${clinicId}/${userId}`, {
          headers: {'x-access-token': accessToken},
        })

        // Correctly handle nested designation
        setFormData({
          ...data.data,
          designation: data.data.designation?.designation || '',
        })

        openModal()
      }
    } catch (error) {
      toast.error('Failed to load user details')
    }
  }

  const updateData = async () => {
    try {
      const getLoginInfo = localStorage.getItem('appLoginInfo')
      if (getLoginInfo) {
        const {accessToken, clinicId} = JSON.parse(getLoginInfo).data
        await axios.post(
          `${APIkey}/api/admin/updateUserById/${clinicId}/${formData._id}`,
          formData,
          {headers: {'x-access-token': accessToken}}
        )
        toast.success('User details updated successfully')
        closeModal()
      }
    } catch (error) {
      toast.error('Error saving data')
    }
  }

  const handleDelete = async (userId: string) => {
    try {
      const getLoginInfo = localStorage.getItem('appLoginInfo')
      if (getLoginInfo) {
        const {accessToken, clinicId} = JSON.parse(getLoginInfo).data
        await axios.delete(`${APIkey}/api/admin/deleteUserById/${clinicId}/${userId}`, {
          headers: {'x-access-token': accessToken},
        })
        toast.success('User deleted successfully')
        closeDeleteModal()
        setUserInfo(userInfo.filter((user) => user._id !== userId))
      }
    } catch (error) {
      toast.error('Failed to delete user')
    }
  }

  // const handleSearch = (query: string) => {
  //   setSearchQuery(query)
  // }

  const columns = [
    {key: ['userCode'], header: 'User ID'},
    {key: ['npiId'], header: 'NPI'},
    {key: ['username'], header: 'User Name'},
    {key: ['designation', 'designation'], header: 'Designation'}, // Corrected column key
    {key: ['role', 'role'], header: 'Role'}, // Corrected column key
    {key: ['email'], header: 'Email'},
    {key: ['createdDate'], header: 'Activation Date', isDate: true},
    {key: ['status'], header: 'Status'},
    {
      key: ['actions'],
      header: 'Action',
      // isAction: true,
      // ActionComponent: ({ id }: { id: string }) => (
      //   // <div>
      //   //   <img src={settingeyeicon}  onClick={()=>handleView(id)} className="px-2" alt="View" />
      //   //   <img src={editcs} onClick={() => handleEdit(id)} className="px-2" alt="Edit" />
      //   //   <img src={deletcs} onClick={() => openDeleteModal(id)} className="px-2" alt="Delete" />
      //   // </div>
      // ),
    },
  ]

  const [showViewModal, setShowViewModal] = useState(false)
  const [viewData, setViewData] = useState('')
  const [viewId, setViewId] = useState(null)
  const [clinicId, setClinicId] = useState(null)
  const [isViewModalOpen, setIsViewModalOpen] = useState(false)

  const handleView = (userId) => {
    setViewData(userId)
    setViewId(userId)
    setShowViewModal(true)
    setIsViewModalOpen(true)
    // console.log(userId)
  }
  const handleCloseViewModal = () => {
    setShowViewModal(false)
    setIsViewModalOpen(false)
  }







// const [data,setData]=useState([])

  const [SearchCriteria, setSearchCriteria] = useState({
    npiId: '',
    username: '',
    designation: '',
    createdDate: '',
    status: '',
    clinicId: clinicId,
  })

  const handleSearchCriteriaChange = (e) => {
    const {name, value} = e.target
    setSearchCriteria((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSearch = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      if (getloginInfo) {
        const {accessToken, clinicId} = JSON.parse(getloginInfo).data

        const updatedSearchCriteria = { ...SearchCriteria, clinicId };

        const header = {
          headers: {'x-access-token': accessToken},
        }
        const response = await axios.post(
          `${APIkey}/api/admin/listUsersSearch`,
          updatedSearchCriteria,
          header
        )

        // console.log(response.data.data)
        const responseData = response.data.data
        const searchData = responseData.map((item) => ({
          npiId: item.npiId,
          username: item.username,
          designation: item.designation.designation,
          createdDate: item.createdDate,
          status: item.status,
          
        }))



        setUserInfo(searchData)
        console.log(searchData)
      }
    } catch (error) {
      console.log('Error fetching data')
    }
  }




 




 





  return (
    <>
      <div className='container-fluid p-3' style={{backgroundColor: '#FFFFFF'}}>
        <div className='d-flex justify-content-between align-items-center mb-3'>
          <div className='PageHeaderTitle'>User Information</div>
        </div>
        <div className='d-flex gap-2 col-md-12 mb-3'>
          User Search:
          <div className='col-md-2'>

          <input
  type='hidden'
  className='SearchInputbox'
  placeholder='NPI'
  name='clinicId'
  value={SearchCriteria.clinicId || ''}  // Ensure it's always a string
  onChange={handleSearchCriteriaChange}
/>

            <input
              type='text'
              className='SearchInputbox'
              placeholder='NPI'
              name='npiId'
              value={SearchCriteria.npiId}
              onChange={handleSearchCriteriaChange}
            />
          </div>
          <div className='col-md-2'>
            <input
              type='text'
              className='SearchInputbox'
              placeholder='User Name'
              name='username'
              value={SearchCriteria.username}
              onChange={handleSearchCriteriaChange}
            />
          </div>
          <div className='col-md-2'>
            <input
              type='text'
              className='SearchInputbox'
              placeholder='Designation'
              name='designation'
              value={SearchCriteria.designation}
              onChange={handleSearchCriteriaChange}
            />
          </div>
          <div className='col-md-2'>
            <input
              type='text'
              className='SearchInputbox'
              placeholder='Activation Date'
              name='createdDate'
              value={SearchCriteria.createdDate}
              onChange={handleSearchCriteriaChange}
            />
          </div>
          <div className='col-md-2'>
            <input
              type='text'
              className='SearchInputbox'
              placeholder='Status'
              name='status'
              value={SearchCriteria.status}
              onChange={handleSearchCriteriaChange}
            />
          </div>
          <div className='col-md-1'>
            <button
              type='button'
               className='blueButton'
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
        </div>

        <Modal show={isModalOpen} onHide={closeModal} size='lg'>
          <Modal.Header closeButton style={{background: '#F2F3F7'}} className='py-0'>
            <Modal.Title className='PageHeaderTitle'>Edit User Information</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{background: '#F2F3F7', padding: '5px 10px'}}>
            <div style={{background: 'white', borderRadius: '8px', padding: '10px'}}>
              <div className='container'>
                <div className='row mb-3'>
                  <div className='col'>
                    <label className='form-label m-0 required'>NPI:</label>
                    <input
                      type='text'
                      className='SearchInputbox'
                      value={formData.npiId || ''}
                      disabled
                    />
                  </div>

                  <div className='col'>
                    <label className='form-label m-0'>User ID:</label>
                    <input
                      type='text'
                      className='SearchInputbox'
                      value={formData.userCode || ''}
                      disabled
                    />
                  </div>

                  <div className='col'>
                    <label className='form-label m-0 required'>User Name:</label>
                    <input
                      type='text'
                      name='username'
                      className='form-control SearchInputbox'
                      value={formData.username || ''}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className='col'>
                    <label className='form-label m-0'>Designation:</label>
                    <input
                      type='text'
                      className='SearchInputbox'
                      value={formData.designation?.designation || ''}
                      disabled
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col'>
                    <label className='form-label m-0'>Role:</label>
                    <input
                      type='text'
                      className='SearchInputbox'
                      value={formData.role?.role || ''}
                      disabled
                    />
                  </div>
                  <div className='col'>
                    <label className='form-label m-0'>Email:</label>
                    <input
                      type='email'
                      name='email'
                      className='form-control SearchInputbox'
                      value={formData.email || ''}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className='col'>
                    <label className='form-label m-0'>Activation Date:</label>
                    {}
                    <input
                      type='text'
                      className='SearchInputbox'
                      value={new Date(formData.createdDate || '').toLocaleDateString() || ''}
                      disabled
                    />
                  </div>
                  <div className='col'>
                    <label className='form-label m-0'>Status:</label>
                    <input
                      type='text'
                      className='SearchInputbox'
                      value={formData.status || ''}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className='py-1'>
            <button onClick={updateData} className='primarybtnBig'>
              Save
            </button>
            <button className='tertiarybig' onClick={closeModal}>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        {/* Delete Modal Integration */}
        <DeleteModel
          openDelete={isDeleteModalOpen}
          closedelete={closeDeleteModal}
          deleteID={deleteUserId}
          deleteFunction={handleDelete}
          deletedEntity='User'
        />

        <CommonTable
          columns={columns}
          data={userInfo}
          totalCount={totalCount}
          setCurrentPage={setCurrentPage}
          setPerPage={setPerPage}
          searchQuery={searchQuery}
          perPage={perPage}
          currentPage={currentPage}
          setQuery={setSearchQuery}
          headerColor={tablecolor.primaryTable.color}
          issetPaginationCompo={true}
          isPagelength={true}
          isSearched={true}
        />

        <ViewUserInfo onshow={isViewModalOpen} onhide={handleCloseViewModal} viewId={viewId} />
      </div>

    </>
  )
}


export default UserInfo
