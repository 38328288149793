import React, { useState, useEffect } from 'react';
import { getResponsiveStyles } from '../utils/utils'; 

const TitleComponent = ({ type = 'title', children, className="" }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateScreenWidth = () => setScreenWidth(window.innerWidth);

    window.addEventListener('resize', updateScreenWidth);

    return () => window.removeEventListener('resize', updateScreenWidth);
  }, []);

  // Get styles based on the current screen width and type
  const styles = getResponsiveStyles(screenWidth, type);

  return (
    <div
    // className={`${className}`} // Default + passed class names
    style={{
        fontSize: styles.fontSize,
        fontWeight: styles.fontWeight,
        lineHeight: styles.lineHeight,
      }}
    >
      <span className={className}>{children}</span>
    </div>
  );
};

export default TitleComponent;