import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {Modal} from 'react-bootstrap'
import fluentedit from '../../../ImgesLogos/fluentedit.svg'
import 'bootstrap/dist/css/bootstrap.min.css'
import downaerrow from '../EHRIcon/downaerrow.svg'
// import fallback from '../EHRIcon/fallback.png'
import PhoneInputCompo from '../../../ReUsableComponent/PhoneInputCompo'
import ModalImage from 'react-modal-image';
import AddressComponent from '../../../ReUsableComponent/AddressComponent'
import ContactComponent from '../../../SuperAdmin/Pages/ReausableComponent/ContactComponent'
import NameCompo from '../../../SuperAdmin/Pages/ReausableComponent/NameCompo'

const APIkey = process.env.REACT_APP_SERVERAPI

function AccountInfo() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [clinicInfo, setClinicInfo] = useState<any>({})
  const [loading, setLoading] = useState(true)
  const [formData, setFormData] = useState<any>({})
  const [countryId, setcountryId] = useState<string | undefined>(undefined)
  const [stateId, setstateId] = useState<string | undefined>(undefined)
  const [addressLine1, setAddressLine1] = useState<string | undefined>(undefined)
  const [cityId, setcityId] = useState<string | undefined>(undefined)
  const [addressLine2, setAddressLine2] = useState<string | undefined>()
  const [errcountry, setErrcountry] = useState<string | undefined>()
  const [errState, setErrState] = useState<string | undefined>()
  const [errCity, setErrCity] = useState<string | undefined>()
  const [errCellPhone, setErrCellPhone] = useState('')
  const [errWorkPhone, setErrWorkPhone] = useState('')
  const [errExt, setErrExt] = useState('')
  const [extID, setExt] = useState()
  const [status, setStatus] = useState()
  const [contactNumber1, setcellNumber] = useState()
  const [countryCode1, setcountryCode1] = useState()
  const [contactNumber2, setworkNumber] = useState()
  const [errZipcode, setErrZipcode] = useState('')
  const [errLastName, setErrLastName] = useState('')
  const [prefixId, setprefix] = useState()
  const [firstName, setfirstName] = useState()
  const [lastName, setLastName] = useState()
  const [middleName, setmiddleName] = useState()
  const [suffixId, setsuffix] = useState()
  const [errFirstName, setErrFirstName] = useState('')
  const [errMiddleName, setErrMiddleName] = useState('')
  const [errEmail, setErrEmail] = useState('')
  const [countryCode2, setcountryCode2] = useState()
  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const SaveData = async () => {
    try {
      const getloginInfo: string | null = localStorage.getItem('appLoginInfo')
      if (getloginInfo !== null) {
        const {
          data: {accessToken, clinicId},
        } = JSON.parse(getloginInfo)

        const header = {
          headers: {'x-access-token': accessToken},
        }

        const response = await axios.post(
          `${APIkey}/api/admin/updateClinicById/${clinicId}`,
          formData,
          header
        )
        console.log('API Response:', response.data)
        setClinicInfo(response.data.data)
        closeModal()
      }
    } catch (error) {
      console.error('Error saving data:', error)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const getloginInfo: string | null = localStorage.getItem('appLoginInfo')
        if (getloginInfo !== null) {
          const {
            data: {accessToken, clinicId},
          } = JSON.parse(getloginInfo)

          const header = {
            headers: {'x-access-token': accessToken},
          }

          const response = await axios.get(`${APIkey}/api/admin/getClinicById/${clinicId}`, header)
          console.log('API Response:', response.data)
          setClinicInfo(response.data.data)
          setFormData(response.data.data)
          setAddressLine1(response.data.data.addressLine1)
          setcountryId(response.data.data)
          setstateId(response.data.data)
          setcityId(response.data.data)
          setLoading(false)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [])

  const handleFileUpload = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setFormData({
          ...formData,
          clinicLogo: reader.result,
        })
        setClinicLogo(reader?.result);
      }
      reader.readAsDataURL(file)
    }
  }
  const [clinicLogo, setClinicLogo] = useState<any>();
  const getCity = (e) => {
    setcityId(e)
  }
  const getState = (e) => {
    setstateId(e)
  }

  const getCountry = (e) => {
    setcountryId(e)
  }
  const getAddress1 = (e) => {
    setAddressLine1(e)
  }

  const getTheEmail = (e) => {
    setemailAddress(e.target.value)
  }
  const getExt = (e) => {
    setExt(e)
  }
  const getprefix = (e) => {
    setprefix(e)
  }

  const getFirstName = (e) => {
    setfirstName(e)
  }
  const getLastName = (e) => {
    setLastName(e)
  }
  const getMiddleName = (e) => {
    setmiddleName(e)
  }

  const getsuffix = (e) => {
    setsuffix(e)
  }

  const getCellNumber = (e) => {
    setcellNumber(e.getTheNumber)
    setcountryCode1(e.gettheCode)
  }

  const getWorkNumber = (e) => {
    setworkNumber(e.getTheNumber)
    setcountryCode2(e.gettheCode)
  }

  return (
    <div className='sizescreen w-100 clinical-time-container '>
      <div style={{backgroundColor: '#FFFFFF', width: '100%', padding: '10px'}}>
        <div className='d-flex justify-content-between align-items-center mb-3'>
          <div className=' PageHeaderTitle'>Account Information</div>
          <div onClick={openModal} style={{cursor: 'pointer'}}>
            <img 
            src={fluentedit} 
            alt='Edit Account' />
          </div>
        </div>

        <div className='py-3'>
          <div className='h5'>Clinic Information:</div>
          <img
            src={clinicInfo.clinicLogo 
              // || fallback
            }
            alt='Clinic Information'
            className='img-fluid'
          />
        </div>

        <div className='row mb-3'>
          <div className='col-md-4'>
            <div className='clinic'>Clinic Name:</div>
            <div>{clinicInfo.clinicName}</div>
          </div>
          <div className='col-md-4'>
            <div>Clinic Website:</div>
            <div>{clinicInfo.clinicWebsite}</div>
          </div>
          <div className='col-md-4'>
            <div>Address:</div>
            <div>{clinicInfo.addressLine1}</div>
          </div>
        </div>

        <div className='py-3'>
          <div className='h5'>
            <b>Contact Person:</b>
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col-md-4'>
            <div>Name:</div>
            <div>
              {clinicInfo.firstName} {clinicInfo.lastName}
            </div>
          </div>
          <div className='col-md-4'>
            <div>Email:</div>
            <div>{clinicInfo.email}</div>
          </div>
          <div className='col-md-4'>
            <div>Cell Phone No.:</div>
            <div>
              {clinicInfo.countryCode1} {clinicInfo.contactNumber1}
            </div>
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col-md-2'>
            <div>Work Phone No.:</div>
            <div>
              {clinicInfo.countryCode2} {clinicInfo.contactNumber2}
            </div>
          </div>
          <div className='col-md-1'>
            <div>Ext:</div>
            <div>{clinicInfo.ext}</div>
          </div>
          <div className='col-md-1'></div>
          <div className='col-md-4'>
            <div>Refer by:</div>
            <div>{clinicInfo.contactPerson?.referBy}</div>
          </div>
          <div className='col-md-4'>
            <div>Group ID:</div>
            <div>{clinicInfo.groupId}</div>
          </div>
        </div>

        <div className='row mb-3'>
          {/* <div className='col-md-4'>
            <div>Registration No.:</div>
            <div>{clinicInfo.registrationNo}</div>
          </div>
          <div className='col-md-4'>
            <div>License No.:</div>
            <div>{clinicInfo.licenseNo}</div>
          </div> */}
        </div>
      </div>

      <Modal show={isModalOpen} onHide={closeModal} size='lg'>
        <Modal.Header closeButton style={{background: '#F2F3F7'}} className='py-0'>
          <Modal.Title className='PageHeaderTitle'>Edit Account Information</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{background: '#F2F3F7', padding: '5px 10px'}}>
          <div style={{background: 'white', borderRadius: '8px', padding: '10px'}}>
            <div className='container'>
              <label className='form-label '>
                <b>Clinic Information:</b>
              </label>
              <div className='row mb-3'>
                <div className='col'>
                  <label className='form-label m-0 required'>Clinic Name</label>
                  <input
                    type='text'
                    className='SearchInputbox'
                    defaultValue={clinicInfo.clinicName}
                    disabled
                  />
                </div>
                <div className='col'>
                  <label className='form-label m-0 required'>Clinic Logo</label>
                  <label className='uploadbtn d-flex align-items-center justify-content-center patient-info-title'>
                    <div className=''>
                      <i className='fa fa-cloud-upload mx-2' aria-hidden='true'></i>
                      Upload Your Clinic Logo &nbsp;
                      <label
                        htmlFor='upload-photo1'
                        style={{color: '#0027FF', textDecoration: 'underLine', cursor: 'pointer'}}
                      />
                    </div>
                    <input
                      type='file'
                      onChange={handleFileUpload}
                      id='upload-photo1'
                      accept='image/*'
                      style={{display: 'none'}}
                      
                    />
                       <div className='w-25 form-control '   >
                                        <ModalImage
                                            small={clinicLogo}
                                            large={clinicLogo}
                                            alt=""
                                            hideDownload={true} // Optionally hide download button
                                            hideZoom={true}     // Optionally hide zoom button
                                        />
                                    </div>
                  </label>
                </div>
                <div className='col'>
                  <label className='form-label m-0'>Clinic Website</label>
                  <input
                    type='text'
                    className='form-control SearchInputbox'
                    defaultValue={clinicInfo.clinicWebsite}
                    name='clinicWebsite'
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className='row mb-3 '>
                {/* <div className='col'>
                  <label className='form-label m-0 required'>Address Line 1</label>
                  <input
                    type='text'
                    className='form-control SearchInputbox'
                    defaultValue={clinicInfo.addressLine1}
                    name='addressLine1'
                    onChange={handleInputChange}
                  />
                </div>
                <div className='col'>
                  <label className='form-label m-0'>Address Line 2</label>
                  <input
                    type='text'
                    className='form-control SearchInputbox'
                    defaultValue={clinicInfo.addressLine2}
                    name='addressLine2'
                    onChange={handleInputChange}
                  />
                </div> */}
                {/* <div className='col'>
                  <label className='form-label m-0 required'>City</label>
                  <select
                    className='form-control SearchInputbox'
                    defaultValue='Select City'
                    name='cityId'
                    onChange={handleInputChange}
                  >
                    <option>{clinicInfo.cityId}</option>
                    <option value='California'>California</option>
                    <option value='Texas'>Texas</option>
                  </select>
                </div>
                <div className='col'>
                  <label className='form-label m-0 required'>State</label>
                  <select
                    className='form-control SearchInputbox'
                    defaultValue='Select State'
                    name='stateId'
                    onChange={handleInputChange}
                  >
                    <option>{clinicInfo.stateId}</option>
                    <option value='California'>California</option>
                    <option value='Texas'>Texas</option>
                  </select>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <label className='form-label m-0 required'>Country</label>
                  <select
                    className='form-control SearchInputbox'
                    defaultValue='Select Country'
                    name='countryId'
                    onChange={handleInputChange}
                  >
                    <option>{clinicInfo.countryId}</option>
                  </select>
                </div>
                <div className='col'>
                  <label className='form-label m-0 required'>Zip</label>
                  <input
                    type='text'
                    className='form-control SearchInputbox'
                    defaultValue={clinicInfo.zipcodeId}
                    name='zipcodeId'
                    onChange={handleInputChange}
                  />
                </div>
              </div> */}

                <div className='row mb-3 '>
                  <AddressComponent
                    col={'4'}
                    addressLine1Value={addressLine1}
                    countryValue={countryId}
                    stateValue={stateId}
                    cityValue={cityId}
                    Cityget={getCity}
                    StateGet={getState}
                    countryget={getCountry}
                    Address1get={getAddress1}
                    // Address2get={getAddress2}
                    cityErr={errCity}
                    stateErr={errState}
                    countryErr={errcountry}
                  />
                </div>
                <label className='form-label '>Contact Person:</label>
                {/* <div className='row mb-3'>
                <div className='col'>
                  <label className='form-label m-0'>Prefix</label>
                  <select
                    className='form-control SearchInputbox'
                    defaultValue='select'
                    name='prefixId'
                    onChange={handleInputChange}
                  >
                    <option>{clinicInfo.prefixId}</option>
                    <option value='Mr.'>Mr.</option>
                    <option value='Mrs.'>Mrs.</option>
                  </select>
                </div>
                <div className='col'>
                  <label className='form-label m-0 required'>First Name</label>
                  <input
                    type='text'
                    className='form-control SearchInputbox'
                    defaultValue={clinicInfo.firstName}
                    name='firstName'
                    onChange={handleInputChange}
                  />
                </div>
                <div className='col'>
                  <label className='form-label m-0 required'>Last Name</label>
                  <input
                    type='text'
                    className='form-control SearchInputbox'
                    defaultValue={clinicInfo.lastName}
                    name='lastName'
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <label className='form-label m-0 required'>Cell Phone</label>
                  <PhoneInputCompo
                    type='text'
                    className='form-control SearchInputbox'
                    defaultValue={clinicInfo.contactNumber1}
                    name='contactNumber1'
                    onChange={handleInputChange}
                  />
                </div>
                <div className='col'>
                  <label className='form-label m-0'>Work Phone</label>
                  <PhoneInputCompo
                    type='text'
                    className='form-control SearchInputbox'
                    defaultValue={clinicInfo.contactNumber2}
                    name='contactNumber2'
                    onChange={handleInputChange}
                  />
                </div>
                <div className='col'>
                  <label className='form-label m-0'>Ext</label>
                  <input
                    type='text'
                    className='form-control SearchInputbox'
                    defaultValue={clinicInfo.ext}
                    name='ext'
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <label className='form-label m-0'>Email</label>
                  <input
                    type='text'
                    className='form-control SearchInputbox'
                    defaultValue={clinicInfo.email}
                    name='email'
                    onChange={handleInputChange}
                  />
                </div>
                <div className='col'>
                  <label className='form-label m-0'>Refer By</label>
                  <input
                    type='text'
                    className='form-control SearchInputbox'
                    defaultValue={clinicInfo.contactPerson?.referBy}
                    name='contactPerson.referBy'
                    onChange={handleInputChange}
                  />
                </div>
              </div> */}
                <div className='row'>
                  <NameCompo
                    colno={'4'}
                    prefixValue={prefixId}
                    lastNameValue={lastName}
                    firstNameValue={firstName}
                    middleNameValue={middleName}
                    suffixValue={suffixId}
                    getPrefixData={getprefix}
                    getSuffixData={getsuffix}
                    getfName={getFirstName}
                    getlName={getLastName}
                    getmName={getMiddleName}
                    lastNameErr={errLastName}
                    firstNameErr={errFirstName}
                    middleNameErr={errMiddleName}
                  />
                </div>
                <div className='row'>
                  <ContactComponent
                    col={4}
                    adminfield={1}
                    contactno1Value={`${countryCode1}${contactNumber1}`}
                    contactno2Value={`${countryCode2}${contactNumber2}`}
                    extValue={extID}
                    getTheEmail={getTheEmail}
                    getTheCellNumber={getCellNumber}
                    getTheWorkNumber={getWorkNumber}
                    getTheExt={getExt}
                    emailErr={errEmail}
                    workNoErr={errWorkPhone}
                    cellNoErr={errCellPhone}
                    extErr={errExt}
                  />
                  <div className='col'>
                    <label className='form-label m-0'>Refer By</label>
                    <input
                      type='text'
                      className='form-control SearchInputbox'
                      defaultValue={clinicInfo.contactPerson?.referBy}
                      name='contactPerson.referBy'
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className='col'>
                    <label className='form-label m-0'>Group ID:</label>
                    <input
                      type='text'
                      className='form-control SearchInputbox'
                      defaultValue={clinicInfo.contactPerson?.groupId}
                      name='contactPerson.referBy'
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className='col'>
                    <label className='form-label m-0'>Registration No. :</label>
                    <input
                      type='text'
                      className='form-control SearchInputbox'
                      defaultValue={clinicInfo.contactPerson?.referBy}
                      name='contactPerson.referBy'
                      onChange={handleInputChange}
                    />
                    </div>
                    <div className='col'>
                    <label className='form-label m-0'>License No. :</label>
                    <input
                      type='text'
                      className='form-control SearchInputbox'
                      defaultValue={clinicInfo.contactPerson?.referBy}
                      name='contactPerson.referBy'
                      onChange={handleInputChange}
                    />
                    </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{background: '#F2F3F7'}}>
          <button className='primarybtnBig' onClick={SaveData}>
            Save
          </button>
          <button className='tertiarybig' onClick={closeModal}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default AccountInfo
function setemailAddress(value: any) {
  throw new Error('Function not implemented.')
}
