import React, { useEffect, useState } from 'react';
// import plusredbtn from '../../EHRIcon/plusredbtn.svg';
import CommonTable from '../../../../ReUsableComponent/CommonTable';
import axios from 'axios';
import tablecolor from '../../../../ReUsableComponent/ReyClinicalinputs.json'


const PreferredSetting = () => {
  // State management
  const [data, setData] = useState([
    // Initial data for the table (add more rows as needed)
    { date: '2024-08-16', medicationName: 'Medication A', dosage: '50mg', frequency: 'Daily', route: 'Oral', instructions: 'Take with food', notes: 'None' }
  ]);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  // Handle add click
  const handleAddClick = () => {
    console.log('Add clicked!');
    // Logic to add a new item to the data array
  };

  // Handle row click
  const handleRowClick = (row) => {
    console.log('Row clicked:', row);
    // Logic to handle row click
  };





  // Define table columns
  const columns = [
   
    { key: ['medicationName'], header: 'Medication Name' },
    { key: ['dosage'], header: 'Dosage' },
    { key: ['frequency'], header: 'Frequency' },
    { key: ['route'], header: 'Route' },
    { key: ['instructions'], header: 'Instructions' },
    { key: ['notes'], header: 'Notes' }
    
  ];

  return (
    <div className=' mt-1'>
      <div className='clinical-time-header'>Preferred Setting</div>
      <div className='d-flex gap-2 col-md-12 mt-3'>
        <div className='col-md-11'>
          <div className='d-flex gap-2 col-md-12 mt-3'>
            <div className='col-md-1.5'>
              <label className='vaccine-text mt-1'>Preferred Medications:</label>
            </div>
            <div className='col-md-1.5'>
              <input type='date' className='SearchInputbox' placeholder='MM/DD/YYYY' />
            </div>
            <div className='col-md-1.5'>
              <input type='text' className='SearchInputbox' placeholder='Medication Name' />
            </div>
            <div className='col-md-1.5'>
              <input type='text' className='SearchInputbox' placeholder='Dosage' />
            </div>
            <div className='col-md-1.5'>
              <input type='text' className='SearchInputbox' placeholder='Frequency' />
            </div>
            <div className='col-md-1.5'>
              <input type='text' className='SearchInputbox' placeholder='Route' />
            </div>
            <div className='col-md-1.5'>
              <input type='text' className='SearchInputbox' placeholder='Instructions' />
            </div>
            <div className='col-md-1.5'>
              <input type='text' className='SearchInputbox' placeholder='Notes' />
            </div>
          </div>
        </div>
        <div className='myaddbtn'>
          <div>
            <img 
            // src={plusredbtn} 
            alt='Add' />
          </div>
          <div>
            <button type='button' className='AddBtn' onClick={handleAddClick}>
              Add
            </button>
          </div>
        </div>
      </div>
      <br />
      <div className='fulltable card'>
      <CommonTable
        data={data}
        totalCount={data.length}
        perPage={perPage}
        setPerPage={setPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        columns={columns}
        searchQuery={searchQuery}
        setQuery={setSearchQuery}
        onRowClick={handleRowClick}
        headerColor={tablecolor.primaryTable.color}
        issetPaginationCompo={true}
        isPagelength={true}
        isSearched={true}

      />
      </div>
    </div>
  );
};

export default PreferredSetting;
