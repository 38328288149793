import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'
import CommonTable from '../../../ReUsableComponent/CommonTable'
import DeleteModel from '../../../ReUsableComponent/DeleteModel'
import {Modal} from 'react-bootstrap'
// import deletcs from '../EHRIcon/deletcs.svg'
import searchiconwhite from '../EHRIcon/searchiconwhite.svg'
// import editcs from '../EHRIcon/editcs.svg'
import EditSlotTime from '../ClinicalSettings/EditSlotTime'
import Eyebutton from '../../EHRIcon/Eyebutton.svg'
// import Vector from '../../EHRIcon/Vector.svg'
import tablecolor from '../../../ReUsableComponent/ReyClinicalinputs.json'

const APIkey = process.env.REACT_APP_SERVERAPI

const SlotTime = () => {
  const [slottimedata, setSlottimeData] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState<string | null>(null);
  const [editItemId, setEditItemId] = useState<string | null>(null);
  const [editId, setEditData] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const [formData, setFormData] = useState<any>({
    combinedDateRange: '',
    numberOfDays: 0,
    occasion: '',
    notes: '',
  });

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    fetchUserList();
  }, [currentPage, perPage, searchQuery]);

  const fetchUserList = async () => {
    try {
      const getLoginInfo = localStorage.getItem('appLoginInfo');
      if (getLoginInfo) {
        const { accessToken, clinicId } = JSON.parse(getLoginInfo).data;
        const { data } = await axios.get(`${APIkey}/api/admin/getReasonForVisitList`, {
          headers: { 'x-access-token': accessToken },
        });
        const formattedData = (data?.data || []).map((item) => ({
          createdDate: new Date(item.createdDate).toLocaleDateString(),
          reason: item.reason,
          time: item.time,
          notes: item.notes,
          color: (
            <div
              style={{
                backgroundColor: item.color,
                width: '40px',
                height: '20px',
                borderRadius: '4px',
                display: 'inline-block',
              }}
            />
          ),
          actions: (
            <div>
              <img
                // src={editcs}
                className='px-2'
                alt='Edit'
                onClick={() => handleEdit(item.id)}
              />
              <img
                // src={deletcs}
                className='px-2'
                alt='Delete'
                onClick={() => {
                  setDeleteItemId(item.id);
                  setIsDeleteModalOpen(true);
                }}
              />
            </div>
          ),
        }));

        setSlottimeData(formattedData);
        setTotalCount(data?.totalCount || 0);
      }
    } catch (error) {
      toast.error('Failed to load users');
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const getLoginInfo = localStorage.getItem('appLoginInfo');
      if (getLoginInfo) {
        const { accessToken, clinicId } = JSON.parse(getLoginInfo).data;
        const itemToEdit = slottimedata.find((item: any) => item._id === id);
        if (itemToEdit) {
          const { combinedDateRange, numberOfDays, occasion, notes } = itemToEdit;
          setFormData({ combinedDateRange, numberOfDays, occasion, notes });
          setEditItemId(id);
        }

        // API request to update
        await axios.put(`${APIkey}/api/admin/deleteReasonForVisit/${id}`, formData, {
          headers: { 'x-access-token': accessToken },
        });
        toast.success('Calendar entry updated successfully');
        setSlottimeData(slottimedata.map((item: any) => (item._id === id ? formData : item)));
        setEditItemId(null);
      }
    } catch (error) {
      toast.error('Failed to update calendar entry');
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const columns = [
    { key: ['createdDate'], header: 'Date' },
    { key: ['reason'], header: 'Reason for Visit' },
    { key: ['time'], header: 'Time' },
    { key: ['color'], header: 'Color' },
    { key: ['notes'], header: 'Notes' },
    {
      key: [''],
      header: 'Action',
      isAction: true,
      ActionComponent: ({ id }: { id: string }) => (
        <div>
          <img 
          // src={editcs} 
          className='px-2' alt='Edit' onClick={() => handleEdit(id)} />
          <img
            // src={deletcs}
            className='px-2'
            alt='Delete'
            onClick={() => {
              setDeleteItemId(id);
              setIsDeleteModalOpen(true);
            }}
          />
        </div>
      ),
    },
  ];

  const handleEdit = (id) => {
    setEditData(id);
    setShowEditModal(true);
  };

  const handleCloseModal = () => {
    setShowEditModal(false);
  };

  const [searchCriteria, setSearchCriteria] = useState({
    reason: '',
    time: '',
    color: '',
    notes: '',
  });

  const handleSearchCriteriaChange = (e) => {
    const { name, value } = e.target;
    setSearchCriteria((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSearch = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo');
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken;
        const header = {
          headers: { 'x-access-token': Token },
        };

        const response = await axios.post(
          `${APIkey}/api/admin/searchReasonForVisit`,
          searchCriteria,
          header
        );
        const responseData = response.data.data;
        const searchData = responseData.map((item, index) => ({
          srNo: index + 1,
          reason: item.reason,
          time: item.time,
          createdDate: new Date(item.createdDate).toLocaleDateString(),
          color: (
            <div
              style={{
                backgroundColor: item.color,
                width: '10px',
                height: '20px',
                borderRadius: '4px',
                display: 'inline-block',
              }}
            />
          ),
          notes: item.notes,
          actions: (
            <div className='actionbtn'>
              <button onClick={() => handleEdit(item._id)} className='editbtn'>
                <img 
                // src={editcs} 
                alt='Edit' />
              </button>
              <button onClick={() => handleDelete(item._id)} className='editbtn'>
                <img 
                // src={deletcs} 
                alt='Delete' />
              </button>
            </div>
          ),
        }));
        setSlottimeData(searchData);
      }
    } catch (error) {
      console.log('Error fetching data');
    }
  };

  const handleClearClick = () => {
    setSearchCriteria({
      reason: '',
      time: '',
      color: '',
      notes: '',
    });
    fetchUserList(); // Fetch the user list again after clearing the search criteria
  };

  return (
    <div className='p-3 clinical-time-container' style={{backgroundColor: '#FFFFFF'}}>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <div className='clinicNavTitle'>Visit Reason </div>
      </div>
      <div className='d-flex col-md-12 gap-2 mb-3'>
        {/* <div className='col-md-2'>
          <label className='form-label' style={{ color: '#464E5F', fontSize: '14px', fontWeight: '500' }}>
          </label>
        </div> */}
        <div className='col-md-1'>
          <label
            className='form-label'
            style={{color: '#5E6278', fontWeight: '600', fontSize: '12px', marginTop: '20px'}}
          >
            Slot Timing:
          </label>
        </div>
        <div className='col-md-2 '>
          <label htmlFor='colorInput' className='addvaccinetitle'>
            Reason:
          </label>

          <input
            type='text'
            className='SearchInputbox'
            placeholder='Reason For Visit'
            name='reason'
            value={searchCriteria.reason}
            onChange={handleSearchCriteriaChange}
          />
        </div>
        <div className='col-md-2 '>
          {/* <label htmlFor='colorInput' className='addvaccinetitle'>Time Interval:</label> */}

          <label htmlFor='colorInput' className='addvaccinetitle'>
            Time Interval:
          </label>

          <input
            type='text'
            className='SearchInputbox'
            placeholder='Time Interval'
            name='time'
            value={searchCriteria.time}
            onChange={handleSearchCriteriaChange}
          />
        </div>
        <div className='col-md-2'>
          <label htmlFor='colorInput' className='addvaccinetitle'>
            Color:
          </label>
          <input
            type='color'
            className='SearchInputbox colorinput'
            placeholder='Color'
            name='color'
            value={searchCriteria.color}
            onChange={handleSearchCriteriaChange}
          />
        </div>
        <div className='col-md-2'>
          <label htmlFor='colorInput' className='addvaccinetitle'>
            Notes:
          </label>

          <input
            type='text'
            className='SearchInputbox'
            placeholder='Notes'
            name='notes'
            value={searchCriteria.notes}
            onChange={handleSearchCriteriaChange}
          />
        </div>
        <div className='col-md-2 d-flex gap-1 ' style={{marginTop: '20px'}}>
          <button type='button' className='blueButton' onClick={handleSearch}>
            Search
          </button>
          <button type='button' className='grayButton' onClick={handleClearClick}>
            Clear
          </button>
        </div>
      </div>

      {/* <Modal show={isModalOpen} onHide={closeModal} size="lg">
        <Modal.Header closeButton style={{ background: '#F2F3F7' }} className="py-0">
          <Modal.Title>Edit Slot Time</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: '#F2F3F7', padding: '5px 10px' }}>
          <div style={{ background: 'white', borderRadius: '8px', padding: '10px' }}>
            <div className="container">
              <div className="row mb-3">
                <div className="col">
                  <label className="form-label m-0 required">NPI:</label>
                  <input type="text" className="SearchInputbox" value={formData.npiId || ''} disabled />
                </div>

                <div className="col">
                  <label className="form-label m-0">User ID:</label>
                  <input type="text" className="SearchInputbox" value={formData.userCode || ''} disabled />
                </div>

                <div className="col">
                  <label className="form-label m-0 required">User Name:</label>
                  <input
                    type="text"
                    name="username"
                    className="form-control SearchInputbox"
                    value={formData.username || ''}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col">
                  <label className="form-label m-0">Designation:</label>
                  <input type="text" className="SearchInputbox" value={formData.designation?.designation || ''} disabled />
                </div>
              </div>
              <div className="row mb-3">
              <div className="col">
                  <label className="form-label m-0">Role:</label>
                  <input type="text" className="SearchInputbox" value={formData.role?.role || ''} disabled />
                </div>
                <div className="col">
                  <label className="form-label m-0">Email:</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control SearchInputbox"
                    value={formData.email || ''}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col">
                  <label className="form-label m-0">Activation Date:</label>
                  <input
                    type="text"
                    className="SearchInputbox"
                    value={new Date(formData.createdDate || '').toLocaleDateString() || ''}
                    disabled
                  />
                </div>
                <div className="col">
                  <label className="form-label m-0">Status:</label>
                  <input type="text" className="SearchInputbox" value={formData.status || ''} disabled />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="py-1">
          <button
          className='primarybtnBig'
            onClick={updateData}
            style={{
              background: '#EB2227',
              color: '#FFFFFF',
              borderRadius: '5px',
              border: 'none',
              fontSize: '15px',
              padding: '6px 10px',
            }}
          >
            Save
          </button>
          <button className='tertiarybig' onClick={closeModal}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal> */}

      <div className='clinical-time-container'>
        <CommonTable
          data={slottimedata}
          totalCount={totalCount}
          perPage={perPage}
          setPerPage={setPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          columns={columns}
          setQuery={setQuery}
          headerColor={tablecolor.primaryTable.color}
          issetPaginationCompo={true}
          isPagelength={true}
          isSearched={true}
        />
      </div>

      <DeleteModel
        openDelete={isDeleteModalOpen}
        closedelete={() => setIsDeleteModalOpen(false)}
        deleteID={deleteItemId}
        deleteFunction={handleDelete}
        deletedEntity='calendar entry'
      />

      <EditSlotTime onHide={handleCloseModal} onShow={showEditModal} editId={editId} />
    </div>
  )
};

export default SlotTime;


