// import axios from "axios";
// import React, { useCallback, useEffect, useRef, useState } from "react";
// import { ToastContainer, toast } from "react-toastify";
// import AddNewDocument from "../Patients/ReusablePatients/AddNewDocument";
// import openYellowFolder from '../EHRIcon/OpenFolderIcon.svg'
// import closeYellowFolder from '../EHRIcon/CloseFolderIcon.svg'
// import openBlueFolder from '../EHRIcon/OpenBlueFolder.svg'
// import closeBlueFolder from '../EHRIcon/CloseBlueFolder.svg'

// export function DocumentCenterMenu() {

//     const APIkey = process.env.REACT_APP_SERVERAPI;

//     const inputRef = useRef(null);

//     const [folderList, setFolderList] = useState<any>([])

//     const [addFolderfield, setAddFolderfield] = useState<any>(false)
//     const [folderName, setFolderName] = useState<any>('New Folder')

//     const [loadFolder, setLoadFolder] = useState<any>(false)
//     const [folderNameErr, setFolderNameErr] = useState<any>(false)
//     const [openFolder, setOpenFolder] = useState<any>()

//     const [addDocumentsPopUp, setAddDocumentsPopUp] = useState<any>(false)

//     useEffect(() => {
//         const getTheFolder = async () => {
//             try {
//                 let getloginInfo: string | null = localStorage.getItem('appLoginInfo')

//                 if (getloginInfo !== null) {

//                     const Token = JSON.parse(getloginInfo)?.data?.accessToken;

//                     const Id = JSON.parse(getloginInfo).data.clinicId;

//                     const userId = JSON.parse(getloginInfo).data.id;

//                     const header = {
//                         headers: { 'x-access-token': Token }
//                     }

//                     const getallFolders = await axios.get(`${APIkey}/api/admin/getFolderList/${Id}`, header)

//                     console.log("getallFolder :", getallFolders?.data?.data);

//                     setFolderList(getallFolders?.data?.data);

//                 }
//                 else {
//                     console.log("No login info found in localStorage");
//                 }
//             }
//             catch (err) {
//                 console.log(err);
//             }
//         }

//         getTheFolder();
//     }, [addFolderfield, loadFolder])

//     const addFolder = async (data) => {
//         try {
//             const getloginInfo: string | null = localStorage.getItem('appLoginInfo');

//             if (getloginInfo !== null) {
//                 const Token = JSON.parse(getloginInfo).data.accessToken;
//                 const header = {
//                     headers: { 'x-access-token': Token },
//                 };

//                 const response = await axios.post(`${APIkey}/api/admin/createFolder`, data, header);
//                 console.log('Response : ', response);

//                 if (response) {
//                     setLoadFolder(false);
//                     toast.success('Folder added successfully!', {
//                         position: 'top-right'
//                     });
//                 }
//             }
//         }
//         catch (err) {
//             console.log(err);
//         }

//     }

//     const handleAddfield = (e) => {
//         if (e.key === 'Enter' || e.key === 'Tab') {
//             e.preventDefault();

//             let data = {
//                 folderName
//             }
//             setLoadFolder(true)

//             if (!folderName || folderName.trim() === '' || folderName.trim() == 'New Folder') {

//                 setFolderNameErr(true);

//                 setLoadFolder(false)

//             } else {

//                 addFolder(data);

//                 setFolderNameErr(false)
//                 setAddFolderfield(false);
//                 setFolderName('New Folder');
//             }

//         }
//     }

//     const enterTheFolder = useCallback((folderId) => {
//         if (openFolder === folderId) {
//             setOpenFolder(null);
//         } else {
//             setOpenFolder(folderId);

//         }
//     }, [openFolder]);

//     const closeDocumentPopUp = () => {
//         setAddDocumentsPopUp(false)
//     }

//     return (
//         <>
//             <div className="mt-2 PageHeader">
//                 <div>
//                     <span className="PageHeaderTitle">Documents</span>
//                 </div>
//                 <div>
//                     <button
//                         onClick={() => setAddDocumentsPopUp(true)}
//                         className="blueButton" >
//                         Add Document
//                     </button>
//                 </div>
//             </div>

// <div className="row">

//             <div className="col-md-2" >

//                 <div className=" card py-2 px-1   ">
//                     <div className="d-flex justify-content-end align-items-center my-1 ">
//                         <button className="blueButtonSmall px-3" onClick={() => setAddFolderfield(true)}>
//                             <i className="fa-solid fa-plus " ></i>
//                         </button>
//                     </div>
//                     {
//                         loadFolder ?
//                             <div className="d-flex justify-content-center">
//                                 <div className='loader'></div>
//                             </div>
//                             :
//                             <ul className="appointmentUl px-1">

//                                 {
//                                     folderList?.map((i: any, index: any) => {
//                                         return (
//                                             <>
//                                                 <li key={index}
//                                                     className="d-flex gap-4 mx-1 justify-content-start align-items-center "
//                                                     onClick={() => enterTheFolder(i?._id)}
//                                                 >
//                                                     <div>
//                                                         {/* <img src={closeFolder} alt="" className="folderIcon" /> */}

//                                                         {
//                                                             i?.userGenerated === true ?
//                                                                 (openFolder == i?._id ?
//                                                                     <img src={openBlueFolder} alt="" /> :
//                                                                     <img src={closeBlueFolder} alt="" />)
//                                                                 : (openFolder == i?._id ?
//                                                                     <img src={openYellowFolder} alt="" /> :
//                                                                     <img src={closeYellowFolder} alt="" />)
//                                                         }
//                                                     </div>
//                                                     <div className="elipsisText">
//                                                         <span className="folderName ">
//                                                             {i?.folderName}
//                                                         </span>
//                                                     </div>
//                                                 </li>
//                                             </>

//                                         )

//                                     })

//                                 }

//                                 {
//                                     addFolderfield && (

//                                         <li
//                                             className="d-flex gap-4 mx-1 justify-content-start align-items-center "
//                                         >

//                                             <img src={closeYellowFolder} alt="" />

//                                             <input
//                                                 style={folderNameErr ? { borderColor: "red" } : { borderColor: "" }}
//                                                 className="form-control SearchInputbox"
//                                                 autoFocus
//                                                 ref={inputRef}
//                                                 onChange={(e) => setFolderName(e.target.value)}
//                                                 onBlur={() => [setAddFolderfield(false), setFolderNameErr(false), setFolderName("New Folder")]}
//                                                 onKeyDown={handleAddfield}
//                                                 onFocus={e => e.target.select()}
//                                                 value={folderName}
//                                                 type="text"
//                                                 name=""
//                                                 id=""
//                                             />

//                                         </li>
//                                     )

//                                 }
//                             </ul>
//                     }
//                 </div>

//             </div>

//             <div className="col-md-10">

//                 <div className=" card p-2">
//                     <div className="row my-1 align-items-center">

//                         <div className="col-lg-2 ">
//                             <input type="text"
//                                 className="form-control SearchInputbox"
//                                 placeholder="Category"
//                             />
//                         </div>
//                         <div className="col-lg-2 ">
//                             <input type="text"
//                                 className="form-control SearchInputbox"
//                                 placeholder="Patient ID"
//                             />

//                         </div>
//                         <div className="col-lg-2 ">
//                             <input type="text"
//                                 className="form-control SearchInputbox"
//                                 placeholder="Patient Name"
//                             />
//                         </div>
//                         <div className="col-lg-2 ">
//                             <input type="text"
//                                 placeholder="Description"
//                                 className="form-control SearchInputbox" />
//                         </div>
//                         <div className="col-lg-2  d-flex gap-2 ">
//                             <button className="blueButton">
//                                 Search
//                             </button>
//                             <button className="grayButton">
//                                 Clear
//                             </button>
//                         </div>
//                     </div>
//                     <div className="row ">
//                         <div className="col-md-3 offset-md-9">
//                             <input type="text" className=" form-control SearchInputbox" />
//                         </div>

//                     </div>

//                     <div className="card mt-2 ">

//                         <table className='w-100 fullCurve' >
//                             <thead >
//                                 <tr className='tableHead'>
//                                     <th>
//                                         Date
//                                     </th>
//                                     <th>
//                                         Time
//                                     </th>
//                                     <th>
//                                         Document Name
//                                     </th>
//                                     <th>
//                                         Description
//                                     </th>
//                                     <th>
//                                         Document Tags
//                                     </th>
//                                     <th>
//                                         Document Type
//                                     </th>
//                                     <th>
//                                         Uploaded On
//                                     </th>
//                                     <th>
//                                         Uploaded By
//                                     </th>
//                                     <th>

//                                     </th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 <tr className='tableBody'>
//                                     <td>

//                                     </td>
//                                     <td>

//                                     </td>
//                                     <td>

//                                     </td>
//                                     <td>

//                                     </td>
//                                     <td>

//                                     </td>
//                                     <td>

//                                     </td>
//                                     <td>

//                                     </td>
//                                     <td>

//                                     </td>

//                                     <td>
//                                         <div className='d-flex align-items-center gap-2 my-1'>
//                                             <button className='blueButton'>
//                                                 <i className="fa-solid fa-pen"></i>
//                                             </button>
//                                             <button className='blueButton'>
//                                                 <i className="fa fa-trash" aria-hidden="true"></i>
//                                             </button>
//                                         </div>
//                                     </td>
//                                 </tr>
//                             </tbody>
//                         </table>

//                     </div>

//                 </div>

//             </div>
//             </div>

//             {
//                 addDocumentsPopUp &&
//                 <AddNewDocument
//                     disabled={false}
//                     open={addDocumentsPopUp}
//                     closeDocumentPopUp={closeDocumentPopUp}
//                     patientData={''}
//                 />
//             }
//             <ToastContainer />
//         </>
//     )
// }

import axios from 'axios';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ToastContainer, toast} from 'react-toastify';
import AddNewDocument from '../Patients/ReusablePatients/AddNewDocument';
import openYellowFolder from '../EHRIcon/OpenFolderIcon.svg';
import closeYellowFolder from '../EHRIcon/CloseFolderIcon.svg';
import openBlueFolder from '../EHRIcon/OpenBlueFolder.svg';
import closeBlueFolder from '../EHRIcon/CloseBlueFolder.svg';
import EditDocument from '../Patients/ReusablePatients/EditDocument';
import CommonTable from '../../../ReUsableComponent/CommonTable';
import tablecolor from '../../../ReUsableComponent/ReyClinicalinputs.json';

export function DocumentCenterMenu() {
  const APIkey = process.env.REACT_APP_SERVERAPI;

  const inputRef = useRef(null);

  const [folderList, setFolderList] = useState<any>([]);
  const [documentByFolderIdList, setDocumentByFolderIdList] = useState<any>([]);

  const [addFolderfield, setAddFolderfield] = useState<any>(false);
  const [folderName, setFolderName] = useState<any>('New Folder');

  const [loadFolder, setLoadFolder] = useState<any>(false);
  const [folderNameErr, setFolderNameErr] = useState<any>(false);
  const [openFolder, setOpenFolder] = useState<any>();

  const [addDocumentsPopUp, setAddDocumentsPopUp] = useState<any>(false);
  const [EditDocumentPopUp, setEditDocumentPopUp] = useState<any>(false);
  const [editdocumentId, setEditdocumentId] = useState<any>('');

  const [searchQuery, setSearchQuery] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const getDocumentByFolderID = async () => {
      try {
        let getloginInfo: string | null = localStorage.getItem('appLoginInfo');

        if (getloginInfo !== null) {
          const Token = JSON.parse(getloginInfo)?.data?.accessToken;

          const clinicId = JSON.parse(getloginInfo).data.clinicId;

          const userId = JSON.parse(getloginInfo).data.id;

          const header = {
            headers: {'x-access-token': Token},
          };
          if (openFolder) {
            const getallDocumentById = await axios.get(
              `${APIkey}/api/admin/getPatientDocumentsByFolder/${openFolder}`,
              header
            );
            setDocumentByFolderIdList(getallDocumentById?.data?.data);
          } else {
            const getallDocumentById = await axios.get(
              `${APIkey}/api/admin/getPatientDocumentsList`,
              header
            );
            console.log(
              'show',
              getallDocumentById.data.data.map((item) => ({
                srNo: item.page,
                visitDate: item.visitDate,
                visitTime: item.visitTime,
                mrn: item.patientDetails.mrn,
                providerId: item.patientDetails.providerId,
                PatientName: `${item.patientDetails.firstName} ${item.patientDetails.lastName}`,
                folderName: item.folderDetails.folderName,
                documentName: item.documentName,
                description: item.description,
                documentTags: item.documentTags[0],
                documenttype: item.documenttype.documentTypeName,
                createdDate: item.documenttype.createdDate,
                uploadedby: `${item.uploadedby.firstName} ${item.uploadedby.lastName}`,
                documentstatus: item.documentstatus.documentStatusName,
                // Action: Action(item.cData._id, item._id, item),
              }))
            );
            setDocumentByFolderIdList(
              getallDocumentById.data.data.map((item) => ({
                // srNo: item.serial,
                visitDate: item.visitDate,
                visitTime: item.visitTime,
                mrn: item.patientDetails.mrn,         
                providerId: item.patientDetails.providerId,
                PatientName: `${item.patientDetails.firstName} ${item.patientDetails.lastName}`,
                folderName: item.folderDetails.folderName,   
                documentName: item.documentName,
                description: item.description,
                documentTags: item.documentTags[0],    
                documenttype: item.documenttype.documentTypeName,
                createdDate: item.documenttype.createdDate,
                uploadedby: `${item.uploadedby.firstName} ${item.uploadedby.lastName}`,
                documentstatus: item.documentstatus.documentStatusName,
                // Action: Action(item.cData._id, item._id, item),
              }))
            );
          }
        } else {
          console.log('No login info found in localStorage');
        }
      } catch (err) {
        console.log(err);
        setDocumentByFolderIdList([]);
      }
    };

    getDocumentByFolderID();
  }, [openFolder]);

  useEffect(() => {
    const getTheFolder = async () => {
      try {
        let getloginInfo: string | null = localStorage.getItem('appLoginInfo');

        if (getloginInfo !== null) {
          const Token = JSON.parse(getloginInfo)?.data?.accessToken;

          const Id = JSON.parse(getloginInfo).data.clinicId;

          const userId = JSON.parse(getloginInfo).data.id;

          const header = {
            headers: {'x-access-token': Token},
          };

          const getallFolders = await axios.get(`${APIkey}/api/admin/getFolderList`, header);

          console.log('getallFolder :', getallFolders?.data?.data);

          setFolderList(getallFolders?.data?.data);
        } else {
          console.log('No login info found in localStorage');
        }
      } catch (err) {
        console.log(err);
      }
    };

    getTheFolder();
  }, [addFolderfield, loadFolder]);

  const addFolder = async (data) => {
    try {
      const getloginInfo: string | null = localStorage.getItem('appLoginInfo');

      if (getloginInfo !== null) {
        const Token = JSON.parse(getloginInfo).data.accessToken;
        const header = {
          headers: {'x-access-token': Token},
        };

        const response = await axios.post(`${APIkey}/api/admin/createFolder`, data, header);
        console.log('Response : ', response);

        if (response) {
          setLoadFolder(false);
          toast.success('Folder added successfully!', {
            position: 'top-right',
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddfield = (e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();

      let data = {
        folderName,
      };
      setLoadFolder(true);

      if (!folderName || folderName.trim() === '' || folderName.trim() == 'New Folder') {
        setFolderNameErr(true);

        setLoadFolder(false);
      } else {
        addFolder(data);

        setFolderNameErr(false);
        setAddFolderfield(false);
        setFolderName('New Folder');
      }
    }
  };

  const enterTheFolder = useCallback(
    (folderId) => {
      if (openFolder === folderId) {
        setOpenFolder(null);
      } else {
        setOpenFolder(folderId);
      }
    },
    [openFolder]
  );

  const closeDocumentPopUp = () => {
    setAddDocumentsPopUp(false);
    setEditDocumentPopUp(false);
  };

  const handleRowClick = (row) => {
    console.log('Row clicked:', row);
    // Logic to handle row click
  };

  const columns = [
    {key: ['srNo'], header: 'Sr No.'},
    {key: ['visitDate'], header: 'Visit Date', isDate: true},
    {key: ['visitTime'], header: 'Time'},
    {key: ['mrn'], header: 'MRN'},
    {key: ['providerId'], header: 'Provider'},
    {key: ['PatientName'], header: 'Patient Name'},
    {key: ['folderName'], header: 'Folder Name'},
    {key: ['documentName'], header: 'Document Name'},
    {key: ['description'], header: 'Description'},
    {key: ['documentTags'], header: 'Document Tag'},
    {key: ['documenttype'], header: 'Document Type'},
    {key: ['createdDate'], header: 'Uploaded On', isDate: true},
    {key: ['uploadedby'], header: 'Uploaded By'},
    {key: ['documentstatus'], header: 'Status'},
    {key: ['Action'], header: 'Actions', isAction: true},
  ];

  return (
    <>
      <div className='mt-2 PageHeader'>
        <div>
          <span className='titleFont'>Documents</span>
        </div>
        <div>
          <button onClick={() => setAddDocumentsPopUp(true)} className='blueButton'>
            Add Document
          </button>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-2'>
          <div className=' card py-2 px-1   '>
            <div className='d-flex justify-content-end align-items-center my-1 '>
              <button className='blueButtonSmall px-3' onClick={() => setAddFolderfield(true)}>
                <i className='fa-solid fa-plus '></i>
              </button>
            </div>
            {loadFolder ? (
              <div className='d-flex justify-content-center'>
                <div className='loader'></div>
              </div>
            ) : (
              <ul className='appointmentUl px-1'>
                <li
                  // key={index}
                  className='d-flex gap-4 mx-1 justify-content-start align-items-center '
                  onClick={() => enterTheFolder(null)}
                >
                  <div>
                    {openFolder == null ? (
                      <img src={openBlueFolder} alt='' />
                    ) : (
                      <img src={closeBlueFolder} alt='' />
                    )}
                  </div>
                  <div className='elipsisText'>
                    <span className='folderName '>All</span>
                  </div>
                </li>
                {folderList?.map((i: any, index: any) => {
                  return (
                    <>
                      <li
                        key={index}
                        className='d-flex gap-4 mx-1 justify-content-start align-items-center '
                        onClick={() => enterTheFolder(i?._id)}
                      >
                        <div>
                          {/* <img src={closeFolder} alt="" className="folderIcon" /> */}

                          {i?.userGenerated === true ? (
                            openFolder == i?._id ? (
                              <img src={openBlueFolder} alt='' />
                            ) : (
                              <img src={closeBlueFolder} alt='' />
                            )
                          ) : openFolder == i?._id ? (
                            <img src={openYellowFolder} alt='' />
                          ) : (
                            <img src={closeYellowFolder} alt='' />
                          )}
                        </div>
                        <div className='elipsisText'>
                          <span className='folderName '>{i?.folderName}</span>
                        </div>
                      </li>
                    </>
                  );
                })}

                {addFolderfield && (
                  <li className='d-flex gap-4 mx-1 justify-content-start align-items-center '>
                    <img src={closeYellowFolder} alt='' />

                    <input
                      style={folderNameErr ? {borderColor: 'red'} : {borderColor: ''}}
                      className='form-control SearchInputbox'
                      autoFocus
                      ref={inputRef}
                      onChange={(e) => setFolderName(e.target.value)}
                      onBlur={() => [
                        setAddFolderfield(false),
                        setFolderNameErr(false),
                        setFolderName('New Folder'),
                      ]}
                      onKeyDown={handleAddfield}
                      onFocus={(e) => e.target.select()}
                      value={folderName}
                      type='text'
                      name=''
                      id=''
                    />
                  </li>
                )}
              </ul>
            )}
          </div>
        </div>

        <div className='col-md-10 '>
          <div className=' card p-2'>
            <div className='my-2 d-flex gap-2 align-items-center'>
              <div className=''>
                <label htmlFor='' className='formLabelTitle'>
                  Document Name :
                </label>
                <input
                  type='text'
                  className='form-control SearchInputbox'
                  placeholder='Document Name'
                />
              </div>
              <div className=''>
                <label htmlFor='' className='formLabelTitle'>
                  Document Type :
                </label>
                <input
                  type='text'
                  className='form-control SearchInputbox'
                  placeholder='Document Type'
                />
              </div>
              <div className=''>
                <label htmlFor='' className='formLabelTitle'>
                  Folder Name :
                </label>
                <input
                  type='text'
                  className='form-control SearchInputbox'
                  placeholder='Folder Name'
                />
              </div>
              <div className=''>
                <label htmlFor='' className='formLabelTitle'>
                  MRN :
                </label>
                <input type='text' placeholder='MRN' className='form-control SearchInputbox' />
              </div>
              <div className=''>
                <label htmlFor='' className='formLabelTitle'>
                  Patient Name :
                </label>
                <input
                  type='text'
                  placeholder='Patient Name'
                  className='form-control SearchInputbox'
                />
              </div>
              <div className=''>
                <label htmlFor='' className='formLabelTitle'>
                  Description :
                </label>
                <input
                  type='text'
                  placeholder='Description'
                  className='form-control SearchInputbox'
                />
              </div>
              <div className='d-flex gap-2 '>
                <button className='blueButton'>Search</button>
                <button className='grayButton'>Clear</button>
              </div>
            </div>

            <div className='card mt-2 '>
              {/* <table className='w-100 fullCurve'>
                <thead>
                  <tr className='tableHead'>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Document Name</th>
                    <th>Description</th>
                    <th>Document Tags</th>
                    <th>Document Type</th>
                    <th>Uploaded On</th>
                    <th>Uploaded By</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {documentByFolderIdList.map((item) => {

                    return (
                      <tr className='tableBody'>
                        <td></td>
                        <td></td>
                        <td>{item.documentName}</td>
                        <td>{item.description}</td>
                        <td>{item.documentTags.join(', ')}</td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <td>
                          <div className='d-flex align-items-center gap-2 my-1'>
                            <button className='blueButton' onClick={() => [setEditDocumentPopUp(true), setEditdocumentId(item._id) ]}>
                              <i className='fa-solid fa-pen'></i>
                            </button>
                          
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table> */}
              <CommonTable
                columns={columns}
                data={documentByFolderIdList}
                totalCount={documentByFolderIdList.length}
                setCurrentPage={setCurrentPage}
                setPerPage={setPerPage}
                searchQuery={searchQuery}
                perPage={perPage}
                currentPage={currentPage}
                setQuery={setSearchQuery}
                headerColor={tablecolor.primaryTable.color}
                issetPaginationCompo={true}
                isPagelength={true}
                isSearched={true}
                onRowClick={handleRowClick}
              />
            </div>
          </div>
        </div>
      </div>

      {addDocumentsPopUp && (
        <AddNewDocument
          disabled={false}
          open={addDocumentsPopUp}
          closeDocumentPopUp={closeDocumentPopUp}
          patientData={''}
        />
      )}
      {EditDocumentPopUp && (
        <EditDocument
          disabled={false}
          open={EditDocumentPopUp}
          documentId={editdocumentId}
          closeDocumentPopUp={closeDocumentPopUp}
          patientData={''}
        />
      )}
      <ToastContainer />
    </>
  );
}
