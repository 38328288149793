import axios from 'axios';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import AddInsurance from '../Appointment/AddInsurance';
import {useParams} from 'react-router-dom';
import EditInsurance from './ReusablePatients/EditInsurance';
import DeleteModel from '../../../ReUsableComponent/DeleteModel';
import {ToastContainer} from 'react-toastify';
import TitleComponent from '../../../ReUsableComponent/TitleComponent';
import CommonButton from '../../../SuperAdmin/Pages/ReausableComponent/CommonButton';
import CommonTable from '../../../ReUsableComponent/CommonTable';
import ViewInsuranceDetails from './ViewInsuranceDetails';

function PatientInsurance() {
  const APIkey = process.env.REACT_APP_SERVERAPI || '';

  const {id} = useParams();

  const [patientInsuarance, setPatientInsuarance] = useState<any>(null);

  const [openAddInsurance, setOpenAddInsurance] = useState(false);
  const [openEditInsurance, setOpenEditInsurance] = useState(false);
  const [openviewInsurance, setOpenViewInsurance] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [insuranceId, setInsuranceId] = useState('');
  const [deleteID, setdeleteID] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchQuery, setQuery] = useState('');

  const deletedEntity: any = deleteID && patientInsuarance.filter((i: any) => i._id === deleteID);

  useEffect(() => {
    const getallInsuarance = async () => {
      try {
        const getloginInfo: string | null = localStorage.getItem('appLoginInfo');

        if (getloginInfo !== null) {
          const Token = JSON.parse(getloginInfo).data.accessToken;
          const header = {
            headers: {'x-access-token': Token},
          };

          const response = await axios.get(
            `${APIkey}/api/admin/getPatientInsuranceByPatientId/${id}`,
            header
          );
          // join remaining ...
          console.log(response);

          setPatientInsuarance(response.data.data);
          // setPatientdata(response.data.data);
        } else {
          console.log('No login info found in localStorage');
        }
      } catch (err) {
        console.log(err);
      }
    };
    getallInsuarance();
  }, [openAddInsurance, openEditInsurance]);

  const openAddInsurancePopUp = () => {
    setOpenAddInsurance(true);
  };

  const openEditInsurancePopUp = (id) => {
    setOpenEditInsurance(true);
    setInsuranceId(id);
  };

  const openviewInsurancePopUp = (id) => {
    setOpenViewInsurance(true);
    setInsuranceId(id);
  };

  const deleteFunction = async (deleteId) => {
    try {
      let getloginInfo: string | null = localStorage.getItem('appLoginInfo');

      if (getloginInfo !== null) {
        const Token = JSON.parse(getloginInfo).data.accessToken;
        const header = {
          headers: {'x-access-token': Token},
        };

        const deleteClinic = await axios.delete(
          `${APIkey}/api/admin/deletePatientInsurance/${deleteId}`,
          header
        );
        console.log(deleteClinic);

        setOpenDelete(false);

        const updatedList = patientInsuarance.filter((item: any) => item._id !== deleteId);

        setPatientInsuarance(updatedList);
      } else {
        console.log('No login info found in localStorage');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const insuranceDelete = (id) => {
    setOpenDelete(true);
    setdeleteID(id);
    console.log('clinic ID for delete ... ', id);
  };

  const closeAddInsurancePopUp = () => {
    setOpenAddInsurance(false);
    setOpenEditInsurance(false);
    setOpenViewInsurance(false);
    setOpenDelete(false);
  };

  const columns = [
    {
      key: ['createdDate'],
      header: 'Date',
      isDate: true,
    },
    {
      key: ['layerOfCoverageDetails', 'layerOfCoverage'],
      header: 'Layer Of Coverage',
    },
    {
      key: ['patientInsuranceTypeDetails', 'insuranceType'],
      header: 'Insurance Type',
    },
    {
      key: ['insurancePlanName'],
      header: 'Insurance Plan Name',
    },
    {
      key: ['patientInsuranceId'],
      header: 'Patient Insurance ID',
    },
    {
      key: ['insuranceEffectiveDate'],
      header: 'Insurance Effective Date',
      isDate: true,
    },
    {
      key: ['insuranceEndDate'],
      header: 'Insurance End Date',
      isDate: true,
    },
    {
      key: ['payorPlanDetails', 'payorPlanType'],
      header: 'Payor/Plan Type',
    },
    {
      key: ['groupId'],
      header: 'Group ID',
    },
    {
      key: [],
      header: 'Actions',
      isAction: true,
      ActionComponent: ({id}) => (
        <div className='d-flex gap-2'>
          <button className='blueButtonSmall' onClick={() => openviewInsurancePopUp(id)}>
            <i className='fa fa-eye' aria-hidden='true'></i>
          </button>
          <button className='blueButtonSmall' onClick={() => openEditInsurancePopUp(id)}>
            <i className='fa-solid fa-pen'></i>
          </button>
          <button className='blueButtonSmall' onClick={() => insuranceDelete(id)}>
            <i className='fa-solid fa-trash'></i>
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className='card '>
        <div className='p-2 gap-5'>
          {/* <span className='patient-info-title'>Add New Insurance</span> */}
          <div className='lowerBorder' style={{display: 'flex', justifyContent: 'space-between'}}>
            <div className='p-2 '>
              <TitleComponent type='sectionTitle'> Insurance List</TitleComponent>
            </div>
            <div className='p-2 '>
              <CommonButton
                color='white'
                background='#3699FF'
                onClick={openAddInsurancePopUp}
              >
                {/* <i className='fa fa-plus' style={{color:"white"}}  aria-hidden='true'></i> */}
                Add Insurance
              </CommonButton>
            </div>
          </div>
        </div>
        <div className='p-2'>
          <CommonTable
            data={patientInsuarance}
            totalCount={patientInsuarance?.length || 0}
            perPage={perPage}
            setPerPage={setPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            columns={columns}
            searchQuery={searchQuery}
            setQuery={setQuery}
            isPagelength={true}
            isSearched={true}
          />
        </div>
        {patientInsuarance?.length == 0 && (
          <div className='d-flex justify-content-center py-1 '>
            <span className='patient-info-title'>No records found</span>
          </div>
        )}
      </div>

      <ToastContainer />

      {openviewInsurance && (
        <ViewInsuranceDetails
          open={openviewInsurance}
          closeInsurance={closeAddInsurancePopUp}
          patientId={id} insuranceData={patientInsuarance}    />
      )}

      {openAddInsurance && (
        <AddInsurance
          open={openAddInsurance}
          closeInsurance={closeAddInsurancePopUp}
          patientId={id}
        />
      )}

      {openEditInsurance && (
        <EditInsurance
          open={openEditInsurance}
          closeInsurance={closeAddInsurancePopUp}
          patientId={id}
          insuranceId={insuranceId}
        />
      )}
      {openDelete && (
        <DeleteModel
          openDelete={openDelete}
          closedelete={closeAddInsurancePopUp}
          deleteID={deleteID}
          deletedEntity={deletedEntity[0].insurancePlanName}
          deleteFunction={deleteFunction}
        />
      )}
    </>
  );
}

export default PatientInsurance;
