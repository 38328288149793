import {useState} from 'react';
// import * as Yup from 'yup'
import clsx from 'clsx';
import {Link} from 'react-router-dom';
// import {useFormik} from 'formik'
// import {requestPassword} from '../../core/_requests'
import {toAbsoluteUrl} from '../../../_metronic/helpers';
import axios from 'axios';
import TitleComponent from '../../../ReUsableComponent/TitleComponent';
import CommonButton, {
  CommonbigButton,
  CommonLoginDisplayButton,
} from '../../Pages/ReausableComponent/CommonButton';
// const initialValues = {
//   email: 'admin@demo.com',
// }

// const forgotPasswordSchema = Yup.object().shape({
//   email: Yup.string()
//   .email('Wrong email format')
//   .min(3, 'Minimum 3 symbols')
//   .max(50, 'Maximum 50 symbols')
//   .required('Email is required'),
// })

export function RetrieveUsername() {
  const APIKey = process.env.REACT_APP_SERVERAPI;

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [invalid, setinvalid] = useState<undefined | boolean>(false);
  const [openconfirmPopup, setOpenconfirmPopup] = useState(false);
  // const [loading, setLoading] = useState(false)
  // const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: forgotPasswordSchema,
  //   onSubmit: (values, {setStatus, setSubmitting}) => {
  //     setLoading(true)
  //     setHasErrors(undefined)
  //     setTimeout(() => {
  //       requestPassword(values.email)
  //         .then(({data: {result}}) => {
  //           setHasErrors(false)
  //           setLoading(false)
  //         })
  //         .catch(() => {
  //           setHasErrors(true)
  //           setLoading(false)
  //           setSubmitting(false)
  //           setStatus('The login detail is incorrect')
  //         })
  //     }, 1000)
  //   },
  // })

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setinvalid(false);

      if (email) {
        let data = {email};

        // forgotPassword
        const result = await axios.post(`${APIKey}/api/auth/forgotUsername`, data);

        console.log('response : ', result);

        if (result) {
          setLoading(false);
          setOpenconfirmPopup(true);
        }
      } else {
        setError(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setinvalid(true);
    }
  };

  return (
    <>
      <div className='loginpage '>
        <div className='card custom-card-width-login align-items-center'>
          <div className='card-body '>
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework p-3'
              noValidate
              id='kt_login_password_reset_form'
              onSubmit={handleSubmit}
            >
              <div className='text-center mb-10'>
                {/* begin::Title */}
                {/* <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1> */}
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/ReyCLinicalLogoSvg.svg')}
                  className='h-24px h-lg-35px app-sidebar-logo-default mb-6'
                />
                <TitleComponent type='loginTitle' className='align-items-left d-flex'>
                  {' '}
                  Retrieve your Username{' '}
                </TitleComponent>
                {/* end::Title */}

                {/* begin::Link */}
                <div className='text-gray-500 fw-semibold fs-6 title-center'>
                  <TitleComponent type='loginBodyText' className=' d-flex pt-4'>
                    {' '}
                    Enter the email associated to your account and we'll send an email with your
                    username{' '}
                  </TitleComponent>
                </div>
                {/* end::Link */}
              </div>

              {/* begin::Title */}
              {invalid && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>
                    Sorry, looks like there are some errors detected, please try again.
                  </div>
                </div>
              )}
              {openconfirmPopup && (
                <div className='mb-lg-15 alert alert-success'>
                  <div className='alert-text font-weight-bold'>
                    <span>Username Recovery Email Sent Successfully.</span>
                  </div>
                </div>
              )}
              {/* end::Title */}

              {/* begin::Form group */}
              {/* <div className='fv-row mb-8'>      
        <input
          type='email'
          value={email}
          placeholder='Email associate to your account'
          autoComplete='off'
          onChange={(e)=> setEmail(e.target.value)}
          // {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            // {'is-invalid': formik.touched.email && formik.errors.email},
            // {
            //   'is-valid': formik.touched.email && !formik.errors.email,
            // }
          )}
        />
            {error && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>Please enter an Email</span>
            </div>
          </div>
        )}
      </div> */}
              <TitleComponent
                type='loginBodyText'
                className='d-flex justify-content-center align-items-center my-4'
              >
                <input
                  type='text'
                  style={{
                    backgroundColor: '#F5F8FA',
                    color: '#181C32',
                    padding: '10px 24px',
                    gap: '10px',
                    borderRadius: '4px',
                    width: '100%',
                    border: '1px solid #DCDCDC',
                  }}
                  className='styled-input'
                  placeholder='Email associate to your account'
                />
              </TitleComponent>
              {/* end::Form group */}

              {/* begin::Form group */}
              {loading ? (
                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                  <span></span> <div className='loader'></div>
                </div>
              ) : (
                <>
                  <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                    {/* <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4' > */}
                    <CommonLoginDisplayButton>Continue</CommonLoginDisplayButton>
                    {/* {loading && (
            <span className='indicator-progress'>
            Please wait...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )} */}
                    {/* </button> */}
                  </div>
                  <span style={{display: 'flex', justifyContent: 'end', marginTop: '8px'}}>
                    <Link to='/admin' style={{color: '#181C32'}}>
                      <TitleComponent type='popUpButton' className='my-4'>
                        Back to Login
                      </TitleComponent>
                    </Link>
                  </span>
                </>
              )}
              {/* end::Form group */}
            </form>
          </div>
        </div>
      </div>
      <div className='image-back-wrapper'></div>
    </>
  );
}
