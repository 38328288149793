import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';

const EditSlotTime = ({ onShow, onHide, editId }) => {
  const APIkey = process.env.REACT_APP_SERVERAPI || '';

  const [reason, setReason] = useState('');
  const [color, setColor] = useState('#000000'); // Provide a default color to avoid format issues
  const [time, setTime] = useState('');
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchEditData = async () => {
      setLoading(true); // Start loading
      setError(''); // Reset error state

      try {
        const getLoginInfo = localStorage.getItem('appLoginInfo');
        if (getLoginInfo) {
          const { accessToken } = JSON.parse(getLoginInfo).data;
          const response = await axios.get(
            `${APIkey}/api/admin/getReasonForVisitById/${editId}`,
            {
              headers: { 'x-access-token': accessToken },
            }
          );

          const responseData = response.data.data;
          console.log(responseData);

          // Ensure valid data for all fields
          setReason(responseData.reason || '');
          setColor(responseData.color || '#000000'); // Set a default color if empty
          setTime(responseData.time || '');
          setNotes(responseData.notes || '');
        } else {
          console.log('No login info found in localStorage');
        }
      } catch (error) {
        setError('Failed to load visit reason details.');
        console.log('Failed to load visit reason details', error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    if (editId) {
      fetchEditData();
    }
  }, [editId, APIkey]);

  const updateData = async () => {
    if (!reason || !color || !time) {
      setError('Please fill out all required fields.');
      return;
    }

    try {
      setLoading(true);
      setError('');

      const getLoginInfo = localStorage.getItem('appLoginInfo');
      if (getLoginInfo) {
        const { accessToken } = JSON.parse(getLoginInfo).data;
        await axios.post(
          `${APIkey}/api/admin/updateReasonForVisit/${editId}`,
          {
            reason,
            color,
            time,
            notes,
          },
          {
            headers: { 'x-access-token': accessToken },
          }
        );
        onHide(); // Close the modal after updating
      }
    } catch (error) {
      setError('Failed to update visit reason details.');
      console.log('Failed to update visit reason details', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseEditModal = () => {
    setReason('');
    setColor('#000000'); // Reset to default color on close
    setTime('');
    setNotes('');
    onHide();
  };

  return (
    <Modal show={Boolean(onShow)} onHide={handleCloseEditModal} size="lg" centered>
      <Modal.Header closeButton>
        <div className="PageHeaderTitle">Edit Visit Reason</div>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            {error && <div className="alert alert-danger">{error}</div>}
            <div className="dashboard-card col-md-12 p-2">
              <div className="row">
                <div className="col-md-4">
                  <div className="addvaccinetitle">Reason of Visit:</div>
                  <input type="text" className="SearchInputbox" value={reason} readOnly disabled />
                  <div className="addvaccinetitle">Notes:</div>
                  <input
                    type="text"
                    className="SearchInputbox"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </div>
                <div className="col-md-4">
                  <div className="addvaccinetitle">Time:</div>
                  <input
                    type="text"
                    className="SearchInputbox"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                  />
                </div>
                <div className="col-md-4">
                  <div className="addvaccinetitle">Color:</div>
                  <input
                    type="color"
                    className="SearchInputbox"
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="my-2 d-flex justify-content-end gap-3">
          <button type="button" className="primarybtnBig" onClick={updateData} disabled={loading}>
            Update
          </button>
          <button type="button" className="tertiarybig" onClick={handleCloseEditModal}>
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EditSlotTime;
