import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'
import {useParams} from 'react-router-dom'
import CryptoConverter from '../../../../ReUsableComponent/CryptoConverter'
import { isDate } from 'util/types'

const ViewVaccination = ({onshow,onhide,viewId,}) => {
    const APIkey = process.env.REACT_APP_SERVERAPI
    const [vaccineName, setVaccineName] = useState('')
    const [manufacture, setManufacturer] = useState('')
    const [typeOfVaccine, setTypeOfVaccine] = useState('')
    const [diseasetargeted, setDiseasesTargeted] = useState('')
    const [dosage, setDosage] = useState('')
    const [vaccineIdentifier, setVaccineIdentifier] = useState('')
    const [notes, setNotes] = useState('')
    const [regionAvailability,setRegionAvailability] = useState('')
    const [storageRequirements,setStorageRequirements]=useState('')
    const [approvalStatus,setApprovalStatus]=useState('')
    const [dateofapproval,setDateofapproval]=useState('')
    const [sideEffects,setSideEffects]=useState('')
    const [additionalInformation,setAdditionalInfo]=useState('')
    const [efficiency,setEfficiency]=useState('')


    useEffect(() => {
        const fetchEditData = async () => {
         
          try {
            const getLoginInfo = localStorage.getItem('appLoginInfo')
            console.log('getLoginInfo: ', getLoginInfo)
    
            if (getLoginInfo !== null) {
              const { data : {accessToken}} = JSON.parse(getLoginInfo) // Corrected property name
    
              const headers = {'x-access-token': accessToken}
    
              const response = await axios.get(
                `${APIkey}/api/admin/getPreferredVaccinationById/${viewId}`,
                {headers}
              )
              const data = response.data.data
              console.log("vaction", data)
    
              setVaccineName(data.vaccineName)
              setManufacturer(data.manufacture)
              setTypeOfVaccine(data.typeOfVaccine)
              setDiseasesTargeted(data.diseaseTargeted)
              setDosage(data.dosageSchedule)
              setVaccineIdentifier(data.vaccineIdentifier)
              setNotes(data.notes)
              setAdditionalInfo(data.additionalInformation)
              setApprovalStatus(data.approvalStatus)
              setDateofapproval(CryptoConverter(data.dateOfApproval))
              setRegionAvailability(data.regionAvailability)
              setStorageRequirements(data.storageRequirements)
              setSideEffects(data.sideEffects)
              setEfficiency(data.efficiency)

            } else {
              console.error('No login info found in localStorage')
            }
          } catch (error) {
            console.error('Error fetching data:', error)
          } 
        }
    
        if (viewId) {
            fetchEditData()
        }
    },[viewId])
    
    console.log(viewId)

      
      const handleCloseModal = () => {
        setVaccineName('')
        setManufacturer('')
        setTypeOfVaccine('')
        setDiseasesTargeted('')
        setDosage('')
        setVaccineIdentifier('')
        setNotes('')
        setAdditionalInfo('')
        setApprovalStatus('')
        setDateofapproval('')
        setRegionAvailability('')
        setStorageRequirements('')
        setSideEffects('')
        setEfficiency('')
        

        onhide()
      }


  return (
    <Modal show={onshow} onHide={handleCloseModal} size='lg' centered>
      <Modal.Header closeButton className='h-25px dialoge align-items-center pb-3' >
        <div className='PageHeaderTitle'> Vaccine Information</div>
      </Modal.Header>
      <Modal.Body className='p-2 dialoge'>
        <div className='dashboard-card'>
            <div className='col-md-12 d-flex'>
            <div className='col-md-3 mb-3'>
                <div className='mb-2'>
             <label className='viewlabels'>VaccineName:</label>
             <div className='vaccine-label-data'>{vaccineName}</div></div>

<div className='mb-2'>
             <label className='viewlabels'>Approve Status:</label>
             <div className='vaccine-label-data'>{approvalStatus}</div></div>
           


            <div className='mb-2'>
             <label className='viewlabels'>Storage requirements:</label>
             <div className='vaccine-label-data' >{storageRequirements}</div>
             </div>


             <label className='viewlabels'>Additional Information:</label>
             <div className='vaccine-label-data'>{additionalInformation}</div>
            
             
             
            </div>
            <div className='col-md-3 mb-3'>
                <div className='mb-2'>
              <div className='viewlabels'>Manufacture:</div>
              <div className='vaccine-label-data'>{manufacture}</div>
              </div>

<div className='mb-2'>
              <div className='viewlabels'>Dosage Schedule:</div>
              <div className='vaccine-label-data'>{dosage}</div>
              </div>

<div className='mb-2'>
              <div className='viewlabels'>Region Availibility:</div>
              <div className='vaccine-label-data'>{regionAvailability}</div>
              </div>
       
            </div>
            <div className='col-md-3 mb-3'>
                <div className='mb-2'>
              <label className='viewlabels'>Type Of vaccine:</label>
             <div className='vaccine-label-data'> {typeOfVaccine}</div>
            </div>

            <div className='mb-2'>
              <label className='viewlabels'>Efficiency:</label>
             <div className='vaccine-label-data'> {efficiency}</div>
            </div>


            <div className='mb-2'>
              <label className='viewlabels'>Vaccine Identifier:</label>
             <div className='vaccine-label-data'> {vaccineIdentifier}</div>
            </div>
            </div>





            <div className='col-md-3 '>
                <div className='mb-2'>
              <label className='viewlabels'>Diseases Targeted:</label>
             <div className='vaccine-label-data'> {diseasetargeted}</div>
             </div>

             <div className='mb-2'>
              <label className='viewlabels'>Side Effects:</label>
             <div className='vaccine-label-data'> {sideEffects}</div>
             </div>

             {/* <div className='mb-2'>
              <label className='viewlabels'>Date of Approval:</label>
             <div className='vaccine-label-data'> {dateofapproval}</div>
             </div> */}
            </div>
            </div>
        </div>
      </Modal.Body>
      <Modal.Footer>   
        <div className='my-2 d-flex justify-content-end gap-3'>
        <button type='button' className='tertiarybig' onClick={handleCloseModal}>
              Close
            </button>
        </div>
        </Modal.Footer>
   </Modal>
  )
}

export default ViewVaccination
