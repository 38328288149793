import React from 'react'
import {useState,useEffect} from 'react'
import CommonTable from '../../../../ReUsableComponent/CommonTable'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'
import tablecolor from '../../../../ReUsableComponent/ReyClinicalinputs.json'

const AddChiefComplain = ({onShow, onHide,onClick}) => {
    const APIkey = process.env.REACT_APP_SERVERAPI
    const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const [modalData, setModalData] = useState([])
  const [selectedId, setSelectedId] = useState<string[]>([])



const saveData = async()=>{
    try{
        const getloginInfo = localStorage.getItem('appLoginInfo')
        if(getloginInfo){
            const Token = JSON.parse(getloginInfo).data.accessToken
            const headers = {'x-access-token': Token}

            const response = await axios.post(
                `${APIkey}/api/admin/createPreferredChiefComplaint`,
                {chiefComplaintId: selectedId},
                {headers}
              )
              console.log("response",response);
              if(response){

                onHide()
              }

        }else{

            console.log('No login info found in localStorage')


        }
    }catch(error){
        console.log('Error in posting data:', error)
    }

    
}

const Component = ({id}) => {
    const handleCheckbox = (e) => {
      const {value, checked} = e.target

      if (checked) {
        setSelectedId((prev) => {
          if (!prev.includes(value)) {
            return [...prev, value]
          }
          return prev
        })
      } else {
        setSelectedId((prev) => prev.filter((item) => item !== value))
      }
    }

    return (
      <input
        type='checkbox'
        value={id}
        checked={selectedId.some((item) => item === id)}
        onChange={(e) => handleCheckbox(e)}
      />
    )
  }



const AddChiefComplainColumns = [
    {key: ['srNo'], header: 'Sr.No'},
    {
        key: ['select'],
        header: 'Select',
        isAction: true,
        ActionComponent: Component,
      },
    {key: ['chiefComplaintName'], header: 'Chief Complaint'},
]


const fetchChiefComplainData = async ()=>{
    try{
        const getloginInfo = localStorage.getItem('appLoginInfo')
        if(getloginInfo) {
            const Token = JSON.parse(getloginInfo).data.accessToken
            const headers = {'x-access-token': Token}
            const response = await axios.get(`${APIkey}/api/admin/getChiefComplaintList`, {headers})


            const responseData = response.data.data

            const formattedData = responseData.map((item, index) => ({
                srNo: index + 1,
                chiefComplaintName: item.chiefComplaintName,
                _id: item._id,
            }))
            setModalData(formattedData)


        }else{
            console.log('No login found in LocalStorage')

        }
    }catch(error){
        console.log('Error fetching medication data:', error)
    }
}



useEffect(()=>{
    fetchChiefComplainData()
},[])


const handleRowClick = (row)=>{
    console.log('Row clicked:', row)
}


const handleCloseModal = () => {
    onHide()
  }
  
  return (
    <>

    <Modal show={onShow} onHide={handleCloseModal} size='xl' className='w-100'centered>
        <Modal.Header closeButton className=' h-25px dialoge align-items-center pb-3'>
        <div className='PageHeaderTitle'>Add Chief Complain:</div>
        </Modal.Header>
        <Modal.Body className='p-2 dialoge'>
        <div className='dashboard-card mt-2'>
            {/*  {loadingModalData ? (
              <p>Loading...</p>
            ) : ( */}
            <CommonTable
              columns={AddChiefComplainColumns}
              data={modalData}
              totalCount={modalData.length}
              setCurrentPage={setCurrentPage}
              setPerPage={setPerPage}
              searchQuery={searchQuery}
              perPage={perPage}
              currentPage={currentPage}
              setQuery={setSearchQuery}
              headerColor={tablecolor.primaryTable.color}
                issetPaginationCompo = {false}
                isPagelength={false}
                isSearched={false}
              onRowClick={handleRowClick}
            />
            {/* )} */}
          </div>

        </Modal.Body>
        <Modal.Footer>
        <div className='mt-3'>
            <button
              className='primarybtnBig '
              onClick={saveData}
              disabled={selectedId.length === 0}
            >
              Save
            </button>
            &nbsp;&nbsp;&nbsp;
            <button className='tertiarybig ' onClick={handleCloseModal}>
              Close
            </button>
          </div>
            </Modal.Footer>
    </Modal>
    </>
  )













}
export default AddChiefComplain
