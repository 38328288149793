import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'

const ViewHealthIssues = ({onshow,onhide,viewId,}) => {
    const APIkey = process.env.REACT_APP_SERVERAPI
    const [healthIssue, setHealthIssue] = useState('')
    const [notes, setNotes]= useState('')

    useEffect(() => {
        const fetchHealthIssuesData = async () => {
         
          try {
            const getLoginInfo = localStorage.getItem('appLoginInfo')
            console.log('getLoginInfo: ', getLoginInfo)
    
            if (getLoginInfo !== null) {
              const { data : {accessToken}} = JSON.parse(getLoginInfo) // Corrected property name
    
              const headers = {'x-access-token': accessToken}
    
              const response = await axios.get(
                `${APIkey}/api/admin/getPreferredHealthIssueById/${viewId}`,
                {headers}
              )
              const data = response.data.data
              console.log("chief complain data ", data)
              
              setHealthIssue(data.healthIssue)
              setNotes(data.notes)
    
  

            } else {
              console.error('No login info found in localStorage')
            }
          } catch (error) {
            console.error('Error fetching data:', error)
          } 
        }
    
        if (viewId) {
            fetchHealthIssuesData()
        }
    },[viewId])

    
    const handleCloseModal = () => {
        setHealthIssue('')
        setNotes('')
      onhide()
    }
  return (
   <>
   <Modal show={onshow} onHide={handleCloseModal} size='lg' centered>
    <Modal.Header closeButton className='h-25px dialoge align-items-center pb-3'>
    <div className='PageHeaderTitle'> Health Issues Information</div>
    </Modal.Header>

    <Modal.Body className='p-2 dialoge'>
      
        <div className='dashboard-card '>
            <div className='col-md-12'>
                <div className='mb-3'>
             <label className='viewlabels'>Health Issue:</label>
             <div className='vaccine-label-data'>{healthIssue}</div>

            </div>
            <div className='mb-3'>
             <label className='viewlabels'>Notes:</label>
             <div className='vaccine-label-data'>{notes}</div>

            </div>
        </div>
        </div>
       
                
    </Modal.Body>
    <Modal.Footer>
    <div className='my-2 d-flex justify-content-end gap-3'>
        <button type='button' className='tertiarybig' onClick={handleCloseModal}>
              Close
            </button>
        </div>
    </Modal.Footer>

   </Modal>
   </>
  )
}

export default ViewHealthIssues
