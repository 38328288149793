// import React, { useEffect, useState } from 'react'
// import AdditionInfoPopups from './ReusablePatients/AdditionInfoPopups'
// import axios from 'axios'
// import moment from 'moment';
// import { useParams } from 'react-router-dom';
// import AdditionInfoPopupsEdit from './ReusablePatients/AdditionInfoPopupsEdit';

// function PatientAdditionalInfo(props) {

//   const APIkey = process.env.REACT_APP_SERVERAPI;

//   const { id } = useParams()

//   const changeTab = () => {
//     props.changeTab(props.nextTab)
//   }
//   const [listOfAdditionalInfo, setListOfAdditionalInfo] = useState<any>([])
//   const [listOfPatientAdditionalInfo, setListOfPatientAdditionalInfo] = useState([])

//   const [openalternateAddress, setAlternateAddress] = useState(false)
//   const [popupId, setPopupId] = useState()
//   const [additionalInfoId, setAdditionalInfoId] = useState()
//   const [openPopUp, setOpenPopUp] = useState(false)
//   const [openEditPopUp, setOpenEditPopUp] = useState(false)
//   const [headerName, setHeaderName] = useState(false)







//   useEffect(() => {
//     const getallList = async () => {

//       try {
//         let getloginInfo = localStorage.getItem('appLoginInfo');
//         // console.log(getloginInfo);


//         if (getloginInfo !== null) {
//           const Token = JSON.parse(getloginInfo)?.data?.accessToken;
//           const clinicID = JSON.parse(getloginInfo)?.data?.clinicId;

//           const header = {
//             headers: { 'x-access-token': Token }
//           }

//           const getListOfAdditionalInformation = await axios.get(`${APIkey}/api/admin/getPatientAdditionalInfoTypeList`, header);

//           console.log(getListOfAdditionalInformation);

//           setListOfAdditionalInfo(getListOfAdditionalInformation?.data?.data);

//           const getPatientAdditionalInfo = await axios.get(`${APIkey}/api/admin/getPatientAdditionalInfo/${id}`, header);

//           setListOfPatientAdditionalInfo(getPatientAdditionalInfo?.data?.data);

//           console.log("getPatientAdditionalInfo", getPatientAdditionalInfo);
//         }
//         else {
//           console.log("No login info found in localStorage");
//         }
//       }
//       catch (err) {
//         console.log(err);
//       }


//     }
//     getallList()
//   }, [openPopUp, openEditPopUp])


//   const openPopUpfun = (id, header) => {
//     setPopupId(id)
//     setOpenPopUp(true)
//     setHeaderName(header)

//   }
//   const setOpenEditPopUpfun = (id, AdditionalinfoId, header) => {
//     setPopupId(id)
//     console.log(AdditionalinfoId);

//     setAdditionalInfoId(AdditionalinfoId)
//     setOpenEditPopUp(true)

//     setHeaderName(header)
//   }

//   const closePopUp = () => {
//     setOpenPopUp(false)
//     setOpenEditPopUp(false)
//   }


//   const date = (d) => moment(d).format('L')

//   console.log(listOfAdditionalInfo);
  
//   return (
//     <>
//       <div className='card  '>

//         {
//           listOfAdditionalInfo.map((i: any) => (

//             i._id ==  listOfAdditionalInfo[0]?._id ?
//               <div key={i._id}>
//                 <div className='d-flex gap-2 align-items-center p-2 lowerBorder'>
//                   <span className='title-header'>{i?.patientInfoType}</span>
//                   <button className='blueButtonSmall' onClick={() => openPopUpfun(i._id, i?.patientInfoType)}><i className="fa fa-plus"></i></button>
//                 </div>
//                 <div className='p-2'>

//                   <table className='fullCurve w-100   '>

//                     <thead className=''>
//                       <tr className='tableHead'>
//                         <th>
//                           &nbsp; Date
//                         </th>
//                         <th>
//                           Address Type
//                         </th>
//                         <th>
//                           Address
//                         </th>
//                         <th>
//                           Cell Phone No.
//                         </th>
//                         <th>
//                           Home Phone No.
//                         </th>
//                         <th>
//                           Email
//                         </th>
                    
//                         <th>
//                           Preferred Contact Method
//                         </th>
//                         <th>
//                           Notes
//                         </th>
//                         <th>

//                         </th>
//                       </tr>

//                     </thead>
//                     <tbody>

//                       {
//                         listOfPatientAdditionalInfo.filter((j: any) => j?.patientInfoTypeId == i._id).map((k: any) => (
//                           <tr className='tableBody' key={k._id}>
//                             <td title={date(k.createdDate)} >
//                               &nbsp; {date(k.createdDate)}
//                             </td>
//                             <td title={k?.addressType?.addressType}>
//                               {k?.addressType?.addressType}
//                             </td>
//                             <td title={`${k?.addressLine1}${k?.addressLine2 ? "," :''} ${k?.addressLine2 != null ? k?.addressLine2 :''}`}>
//                             {k?.addressLine1}{k?.addressLine2 ? "," :''} {k?.addressLine2 != null ? k?.addressLine2 :''}
//                             </td>
//                             <td title={`${k.mobileCountryCode} ${k.mobileNo}`}>
//                               {k.mobileCountryCode} {k.mobileNo}
//                             </td>
//                             <td title={`${k.homeCountryCode} ${k.homeNo}`}>
//                               {k.homeCountryCode} {k.homeNo}
//                             </td>
//                             <td title={k.emailAddress}>
//                               {k.emailAddress}
//                             </td>
                       
//                             <td title={k?.preferredcontactmethod?.preferredContactMethod}>
//                               {k?.preferredcontactmethod?.preferredContactMethod}
//                             </td>
//                             <td title={k.notes}>
//                               {k.notes}
//                             </td>
//                             <td className=' d-flex gap-2'>
//                               <button className='blueButtonSmall' onClick={() => setOpenEditPopUpfun(i._id, k._id, i?.patientInfoType)}>
//                                 <i className="fa-solid fa-pen"></i></button>
//                             </td>
//                           </tr>
//                         ))}


//                     </tbody>
//                   </table>
//                 </div>

//               </div>

//               :

//               <div className='' key={i._id}>

//                 <div className='d-flex gap-2 align-items-center p-2 lowerBorder'>
//                   <span className='title-header'>{i?.patientInfoType}</span>
                
// {
//                   i._id ==  listOfAdditionalInfo[1]?._id ? 
             
//                   <button
//                   hidden={ listOfPatientAdditionalInfo.filter((j: any) => j?.patientInfoTypeId == i._id)[0] ? true : false}
//                     onClick={() => openPopUpfun(i._id, i?.patientInfoType)}
//                     className='blueButtonSmall '><i className="fa fa-plus"></i>
//                     </button>

//                   :
//                   <button
//                     onClick={() => openPopUpfun(i._id, i?.patientInfoType)}
//                     className='blueButtonSmall '><i className="fa fa-plus"></i>
//                     </button>
//         }

//                 </div>

//                 <div className='p-2'>

//                   <table className='fullCurve w-100   '>
//                     <thead className=''>

//                       <tr className='tableHead'>

//                         <th>
//                           &nbsp; Date
//                         </th>
//                         <th>
//                           Name
//                         </th>
//                         <th>
//                           Relation With Patient
//                         </th>
//                         <th>
//                           Cell Phone No.
//                         </th>
//                         <th>
//                           Home Phone No.
//                         </th>
//                         <th>
//                           Email
//                         </th>
//                         <th>
//                           Address
//                         </th>
//                         <th>
//                           Preferred Contact Method
//                         </th>

//                         <th>
//                           Notes
//                         </th>
//                         <th>

//                         </th>
//                       </tr>

//                     </thead>
//                     <tbody>
//                       {


//                         listOfPatientAdditionalInfo.filter((j: any) => j?.patientInfoTypeId == i._id).map((k: any) => (

//                           <tr className='tableBody' key={k._id}>

//                             <td title={date(k.createdDate)}>
//                               &nbsp;  {date(k.createdDate)}
//                             </td>
//                             <td title={`${k.firstName} ${k.lastName}`}>
//                               {k.firstName} {k.lastName}
//                             </td>
//                             <td title={k?.relationShipType?.relationship}>
//                               {k?.relationShipType?.relationship}
//                             </td>
//                             <td title={`${k.mobileCountryCode} ${k.mobileNo}`}>
//                               {k.mobileCountryCode} {k.mobileNo}
//                             </td>
//                             <td title={`${k.homeCountryCode} ${k.homeNo}`}>
//                               {k.homeCountryCode} {k.homeNo}
//                             </td>
//                             <td title={k.emailAddress}>
//                               {k.emailAddress}
//                             </td>
//                             <td title={`${k?.addressLine1}${k?.addressLine2 ? "," :''} ${k?.addressLine2 != null ? k?.addressLine2 :''}`}>
//                             {k?.addressLine1}{k?.addressLine2 ? "," :''} {k?.addressLine2 != null ? k?.addressLine2 :''}
//                             </td>
//                             <td title={k?.preferredcontactmethod?.preferredContactMethod}>
//                               {k?.preferredcontactmethod?.preferredContactMethod}
//                             </td>
//                             <td title={k.notes}>
//                               {k.notes}
//                             </td>



//                             <td className=' d-flex gap-2'>
//                               <button className='blueButtonSmall' onClick={() => setOpenEditPopUpfun(i._id, k._id, i?.patientInfoType)} >
//                                 <i className="fa-solid fa-pen"></i></button>
                          
//                             </td>
//                           </tr>
//                         ))


//                       }
//                     </tbody>
//                   </table>
//                 </div>

//               </div>
//           ))
//         }

//         {/* <div className=''>

//           <div className='d-flex gap-2 align-items-center p-2 lowerBorder'>
//             <span className='title-header'>Next Of Kin</span>
//             <button
//               onClick={openNextOfKin}
//               className='blueButtonSmall '><i className="fa fa-plus"></i></button>
//           </div>

//           <div className='p-2'>

//             <table className='fullCurve w-100   '>
//               <thead className=''>
//                 <tr className='tableHead'>

//                   <th>
//                     &nbsp; Date
//                   </th>
//                   <th>
//                     Name
//                   </th>
//                   <th>
//                     Relation With Patient
//                   </th>
//                   <th>
//                     Cell Phone No.
//                   </th>
//                   <th>
//                     Home Phone No.
//                   </th>
//                   <th>
//                     Email
//                   </th>
//                   <th>
//                     Address
//                   </th>
//                   <th>
//                     Preferred Contact Method
//                   </th>

//                   <th>
//                     Notes
//                   </th>
//                   <th>

//                   </th>
//                 </tr>

//               </thead>
//               <tbody>
//                 <tr className='tableBody'>

//                   <td>
//                     &nbsp; 07/15/2015
//                   </td>
//                   <td>
//                     Mary Johnson
//                   </td>
//                   <td>
//                     Mother
//                   </td>
//                   <td>
//                     555-123-4567
//                   </td>
//                   <td>
//                     555-987-6543
//                   </td>
//                   <td>
//                     home@email.com
//                   </td>
//                   <td>
//                     123 Maple St, Anytown, USA
//                   </td>
//                   <td>
//                     Cell Phone
//                   </td>
//                   <td>
//                     Allergic to penicillin
//                   </td>



//                   <td className=' d-flex gap-2'>
//                     <button className='blueButtonSmall'>
//                       <i className="fa-solid fa-pen"></i></button>
//                     <button className='blueButtonSmall'>
//                       <i className="fa-solid fa-trash"></i>
//                     </button>
//                   </td>
//                 </tr>

//               </tbody>
//             </table>
//           </div>

//         </div>
//         <div className=''>

//           <div className='d-flex gap-2 align-items-center p-2 lowerBorder'>
//             <span className='title-header'>Guarantor</span>
//             <button className='blueButtonSmall ' onClick={openGuarantor}><i className="fa fa-plus"></i></button>
//           </div>

//           <div className='p-2'>

//             <table className='fullCurve w-100   '>
//               <thead className=''>
//                 <tr className='tableHead'>

//                   <th>
//                     &nbsp; Date
//                   </th>
//                   <th>
//                     Name
//                   </th>
//                   <th>
//                     Relation With Patient
//                   </th>
//                   <th>
//                     Cell Phone No.
//                   </th>
//                   <th>
//                     Home Phone No.
//                   </th>
//                   <th>
//                     Email
//                   </th>
//                   <th>
//                     Address
//                   </th>
//                   <th>
//                     Preferred Contact Method
//                   </th>

//                   <th>
//                     Notes
//                   </th>
//                   <th>

//                   </th>
//                 </tr>

//               </thead>
//               <tbody>
//                 <tr className='tableBody'>

//                   <td>
//                     &nbsp; 07/15/2015
//                   </td>
//                   <td>
//                     Home
//                   </td>
//                   <td>
//                     123 Maple St, Anytown, USA
//                   </td>
//                   <td>
//                     555-123-4567
//                   </td>
//                   <td>
//                     555-987-6543
//                   </td>
//                   <td>
//                     home@email.com
//                   </td>
//                   <td>
//                     Mother
//                   </td>
//                   <td>
//                     Primary residence
//                   </td>
//                   <td>
//                     07/15/2015
//                   </td>



//                   <td className=' d-flex gap-2'>
//                     <button className='blueButtonSmall'>
//                       <i className="fa-solid fa-pen"></i></button>
//                     <button className='blueButtonSmall'>
//                       <i className="fa-solid fa-trash"></i>
//                     </button>
//                   </td>
//                 </tr>

//               </tbody>
//             </table>
//           </div>

//         </div>
//         <div className=''>

//           <div className='d-flex gap-2 align-items-center p-2 lowerBorder'>
//             <span className='title-header'>Emergency Contact</span>
//             <button className='blueButtonSmall ' onClick={openEmergencyContact}><i className="fa fa-plus"></i></button>
//           </div>

//           <div className='p-2'>

//             <table className='fullCurve w-100   '>
//               <thead className=''>
//                 <tr className='tableHead'>

//                   <th>
//                     &nbsp; Date
//                   </th>
//                   <th>
//                     Name
//                   </th>

//                   <th>
//                     Relation With Patient
//                   </th>
//                   <th>
//                     Cell Phone No.
//                   </th>
//                   <th>
//                     Home Phone No.
//                   </th>
//                   <th>
//                     Email
//                   </th>
//                   <th>
//                     Address
//                   </th>
//                   <th>
//                     Preferred Contact Method
//                   </th>
//                   <th>
//                     Notes
//                   </th>
//                   <th>

//                   </th>
//                 </tr>

//               </thead>
//               <tbody>
//                 <tr className='tableBody'>

//                   <td>
//                     &nbsp; 07/15/2015
//                   </td>
//                   <td>
//                     Home
//                   </td>
//                   <td>
//                     123 Maple St, Anytown, USA
//                   </td>
//                   <td>
//                     555-123-4567
//                   </td>
//                   <td>
//                     555-987-6543
//                   </td>
//                   <td>
//                     home@email.com
//                   </td>
//                   <td>
//                     Mother
//                   </td>
//                   <td>
//                     Primary residence
//                   </td>
//                   <td>
//                     07/15/2015
//                   </td>



//                   <td className=' d-flex gap-2'>
//                     <button className='blueButtonSmall'>
//                       <i className="fa-solid fa-pen"></i></button>
//                     <button className='blueButtonSmall'>
//                       <i className="fa-solid fa-trash"></i>
//                     </button>
//                   </td>
//                 </tr>

//               </tbody>
//             </table>
//           </div>

//         </div>
//         <div className=''>

//           <div className='d-flex gap-2 align-items-center p-2 lowerBorder'>
//             <span className='title-header'>Referral</span>
//             <button className='blueButtonSmall' onClick={openReferral}><i className="fa fa-plus"></i></button>
//           </div>

//           <div className='p-2'>

//             <table className='fullCurve w-100   '>
//               <thead className=''>
//                 <tr className='tableHead'>

//                   <th>
//                     &nbsp; Date
//                   </th>
//                   <th>
//                     Name
//                   </th>
//                   <th>
//                     Relation With Patient
//                   </th>

//                   <th>
//                     Cell Phone No.
//                   </th>
//                   <th>
//                     Home Phone No.
//                   </th>
//                   <th>
//                     Email
//                   </th>
//                   <th>
//                     Address
//                   </th>

//                   <th>
//                     Preferred Contact Method
//                   </th>
//                   <th>
//                     Notes
//                   </th>
//                   <th>

//                   </th>
//                 </tr>

//               </thead>
//               <tbody>
//                 <tr className='tableBody'>

//                   <td>
//                     &nbsp; 07/15/2015
//                   </td>
//                   <td>
//                     Home
//                   </td>
//                   <td>
//                     123 Maple St, Anytown, USA
//                   </td>
//                   <td>
//                     555-123-4567
//                   </td>
//                   <td>
//                     555-987-6543
//                   </td>
//                   <td>
//                     home@email.com
//                   </td>
//                   <td>
//                     Mother
//                   </td>
//                   <td>
//                     Primary residence
//                   </td>
//                   <td>
//                     07/15/2015
//                   </td>


//                   <td className=' d-flex gap-2'>
//                     <button className='blueButtonSmall'>
//                       <i className="fa-solid fa-pen"></i></button>
//                     <button className='blueButtonSmall'>
//                       <i className="fa-solid fa-trash"></i>
//                     </button>
//                   </td>
//                 </tr>

//               </tbody>
//             </table>
//           </div>

//         </div>
//         <div className=''>

//           <div className='d-flex gap-2 align-items-center p-2 lowerBorder'>
//             <span className='title-header'>Family History</span>
//             <button className='blueButtonSmall' onClick={openFamilyHistory}><i className="fa fa-plus"></i></button>
//           </div>

//           <div className='p-2'>

//             <table className='fullCurve w-100   '>
//               <thead className=''>
//                 <tr className='tableHead'>

//                   <th>
//                     &nbsp; Date
//                   </th>
//                   <th>
//                     Name
//                   </th>

//                   <th>
//                     Relation With Patient
//                   </th>
//                   <th>
//                     Cell Phone No.
//                   </th>
//                   <th>
//                     Home Phone No.
//                   </th>
//                   <th>
//                     Email
//                   </th>
//                   <th>
//                     Address
//                   </th>
//                   <th>
//                     Preferred Contact Method
//                   </th>
//                   <th>
//                     Notes
//                   </th>
//                   <th>

//                   </th>
//                 </tr>

//               </thead>
//               <tbody>
//                 <tr className='tableBody'>

//                   <td>
//                     &nbsp; 07/15/2015
//                   </td>
//                   <td>
//                     Home
//                   </td>
//                   <td>
//                     123 Maple St, Anytown, USA
//                   </td>
//                   <td>
//                     555-123-4567
//                   </td>
//                   <td>
//                     555-987-6543
//                   </td>
//                   <td>
//                     home@email.com
//                   </td>
//                   <td>
//                     Mother
//                   </td>
//                   <td>
//                     Primary residence
//                   </td>
//                   <td>
//                     07/15/2015
//                   </td>



//                   <td className=' d-flex gap-2'>
//                     <button className='blueButtonSmall'>
//                       <i className="fa-solid fa-pen"></i></button>
//                     <button className='blueButtonSmall'>
//                       <i className="fa-solid fa-trash"></i>
//                     </button>
//                   </td>
//                 </tr>

//               </tbody>
//             </table>
//           </div>

//         </div> */}



//       </div>

//       {
//         openPopUp && (
//           <>
//             <AdditionInfoPopups
//               listOfAdditionalInfo={listOfAdditionalInfo}
//               open={openPopUp}
//               close={closePopUp}
//               headerName={headerName}
//               popUpNo={popupId}
//             />
//           </>
//         )
//       }

//       {

//         openEditPopUp && (
//           <>
//             <AdditionInfoPopupsEdit
//               open={openEditPopUp}
//               close={closePopUp}
//               headerName={headerName}
//               popUpNo={popupId}
//               AdditionalInfoId={additionalInfoId}
//               listOfAdditionalInfo={listOfAdditionalInfo}

//             />
//           </>
//         )
//       }



//       {/* 
//       {

//         opennextOfKin && (
//           <>
//             <AdditionInfoPopups
//               open={opennextOfKin}
//               close={closePopUp}
//               popUpNo={2}
//               headerName="Next Of Kin" />
//           </>
//         )
//       }


//       {

//         openguarantor && (
//           <>
//             <AdditionInfoPopups
//               open={openguarantor}
//               close={closePopUp}
//               popUpNo={3}
//               headerName="Guarantor" />
//           </>
//         )
//       }


//       {

//         openemergencyContact && (
//           <>
//             <AdditionInfoPopups
//               open={openemergencyContact}
//               close={closePopUp}
//               popUpNo={4}
//               headerName="Emergency Contact" />
//           </>
//         )
//       }
//       {

// openreferral && (
//           <>
//             <AdditionInfoPopups
//               open={openreferral}
//               close={closePopUp}
//               popUpNo={5}
//               headerName="Referral" />
//           </>
//         )
//       }


//       {

// openfamilyHistory && (
//           <>
//             <AdditionInfoPopups
//               open={openfamilyHistory}
//               close={closePopUp}
//               popUpNo={6}
//               headerName="Family History" />
//           </>
//         )
//       } */}
//     </>

//   )
// }

// export default PatientAdditionalInfo

import React, {useEffect, useState} from 'react'
import AdditionInfoPopups from './ReusablePatients/AdditionInfoPopups'
import axios from 'axios'
import moment from 'moment'
import {useParams} from 'react-router-dom'
import AdditionInfoPopupsEdit from './ReusablePatients/AdditionInfoPopupsEdit'
import CommonTable from '../../../ReUsableComponent/CommonTable'
import tablecolor from '../../../ReUsableComponent/ReyClinicalinputs.json'

function PatientAdditionalInfo(props) {
  const APIkey = process.env.REACT_APP_SERVERAPI
  const {id} = useParams()
  const changeTab = () => {
    props.changeTab(props.nextTab)
  }
  const [listOfAdditionalInfo, setListOfAdditionalInfo] = useState<any>([])
  const [listOfPatientAdditionalInfo, setListOfPatientAdditionalInfo] = useState([])
  const [openalternateAddress, setAlternateAddress] = useState(false)
  const [popupId, setPopupId] = useState()
  const [additionalInfoId, setAdditionalInfoId] = useState()
  const [openPopUp, setOpenPopUp] = useState(false)
  const [openEditPopUp, setOpenEditPopUp] = useState(false)
  const [headerName, setHeaderName] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [totalCount, setTotalCount] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  useEffect(() => {
    const getallList = async () => {
      try {
        let getloginInfo = localStorage.getItem('appLoginInfo')
        if (getloginInfo !== null) {
          const Token = JSON.parse(getloginInfo)?.data?.accessToken
          const clinicID = JSON.parse(getloginInfo)?.data?.clinicId
          const header = {
            headers: {'x-access-token': Token},
          }
          const getListOfAdditionalInformation = await axios.get(
            `${APIkey}/api/admin/getPatientAdditionalInfoTypeList`,
            header
          )
          console.log(getListOfAdditionalInformation)
          setListOfAdditionalInfo(getListOfAdditionalInformation?.data?.data)
          const getPatientAdditionalInfo = await axios.get(
            `${APIkey}/api/admin/getPatientAdditionalInfo/${id}`,
            header
          )
          setListOfPatientAdditionalInfo(getPatientAdditionalInfo?.data?.data)
          console.log('getPatientAdditionalInfo', getPatientAdditionalInfo)
        } else {
          console.log('No login info found in localStorage')
        }
      } catch (err) {
        console.log(err)
      }
    }
    getallList()
  }, [openPopUp, openEditPopUp])
  const openPopUpfun = (id, header) => {
    setPopupId(id)
    setOpenPopUp(true)
    setHeaderName(header)
  }
  const setOpenEditPopUpfun = (id, AdditionalinfoId, header) => {
    setPopupId(id)
    // console.log(AdditionalinfoId)
    setAdditionalInfoId(AdditionalinfoId)
    setOpenEditPopUp(true)
    setHeaderName(header)
  }
  const closePopUp = () => {
    setOpenPopUp(false)
    setOpenEditPopUp(false)
  }
  const date = (d) => moment(d).format('L')

  

  const columnsAlternateaddress = [
    {key: ['createdDate'], header: 'Date', isDate: true},
    {
      key: ['addressType', 'addressType'],
      header: 'Address Type',
    },
    {
      key: ['addressLine1'],
      header: 'Address',
    },
    {
      key: ['mobileNo'],
      header: 'Cell Phone No.',
    },
    {
      key: ['homeNo'],
      header: 'Home Phone No.',
    },
    {
      key: ['emailAddress'],
      header: 'Email',
    },
    {
      key: ['preferredcontactmethod', 'preferredContactMethod'],
      header: 'Preferred Contact Method',
    },
    {
      key: ['notes'],
      header: 'Notes',
    },
    {
      key: ['_id'],
      header: 'Action',
      isAction: true,
      ActionComponent: ({ id : i ,id: k}) => (
        <button className='blueButtonSmall' onClick={() => setOpenEditPopUpfun(i._id, k._id, i?.patientInfoType)}>
                                <i className="fa-solid fa-pen"></i></button>
      ),
    },
  ]

  // function Action(id: string, item) {
  //   return (
  //     <div className='d-flex gap-2'>
  //       <button className='blueButtonSmall' onClick={() => handleShowDetails(item)}>
  //         <i className='fa-solid fa-eye'></i>
  //       </button>
  //       <button className='blueButtonSmall' onClick={() => setOpenEditPopUpfun(i._id, id, i.patientInfoType)}>
  //         <i className='fa-solid fa-pencil'></i>
  //       </button>
  //       <button className='blueButtonSmall' onClick={() => clinicdelete(id)}>
  //         <i className='fa-solid fa-trash'></i>
  //       </button>
  //     </div>
  //   )
  // }

  const columns = [
    {
      key: ['createdDate'],
      header: 'Date',
      isDate: true,
      // formatter: (date: string) => `&nbsp; ${new Date(date).toLocaleDateString()}`, // Optional formatter
    },
    {
      key: ['firstName'],
      header: 'Name',
      // formatter: (firstName: string, lastName: string) => `${firstName} ${lastName}`,
    },
    {
      key: ['relationShipType', 'relationship'],
      header: 'Relation With Patient',
    },
    {
      key: ['mobileNo'],
      header: 'Cell Phone No.',
      // formatter: (code: string, number: string) => `${code} ${number}`,
    },
    {
      key: ['homeNo'],
      header: 'Home Phone No.',
      // formatter: (code: string, number: string) => `${code} ${number}`,
    },
    {
      key: ['emailAddress'],
      header: 'Email',
    },
    {
      key: ['addressLine1'],
      header: 'Address',
      // formatter: (line1: string, line2: string) => `${line1}${line2 ? ', ' + line2 : ''}`,
    },
    {
      key: ['preferredcontactmethod', 'preferredContactMethod'],
      header: 'Preferred Contact Method',
    },
    {
      key: ['notes'],
      header: 'Notes',
    },
    {
      key: ['_id'],
      header: 'Action',
      isAction: true,
      ActionComponent: ({ id : i}) => (
        <button
          className='blueButtonSmall'
          onClick={() => setOpenEditPopUpfun(i._id, additionalInfoId, i.patientInfoType)}
        >
          <i className='fa-solid fa-pen'></i>
        </button>
      ),
    },
  ];
  

  return (
    <>
      <div className='card  '>
        {listOfAdditionalInfo.map((i: any) =>
          i._id == listOfAdditionalInfo[0]?._id ? (
            <div key={i._id}>
              <div className='d-flex gap-2 align-items-center p-2 lowerBorder'>
                <span className='title-header'>{i?.patientInfoType}</span>
                <button
                  className='blueButtonSmall'
                  onClick={() => openPopUpfun(i._id, i?.patientInfoType)}
                >
                  <i className='fa fa-plus'></i>
                </button>
              </div>
              <div className='p-2'>
                {/* <CommonTable
                  data={listOfPatientAdditionalInfo.filter((j: any) => j?.patientInfoTypeId == i._id)}
                  columns={columnsAlternateaddress}
                  totalCount={listOfPatientAdditionalInfo.filter((j: any) => j?.patientInfoTypeId == i._id).length}
                  perPage={10}
                  setPerPage={setPerPage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  isPagelength={true}
                  isSearched={false}
                  headerColor={tablecolor.primaryTable.color}
                  issetPaginationCompo={true}
                  setQuery={setSearchQuery}
                /> */}

                <table className='fullCurve w-100   '>
                    <thead className=''>
                      <tr className='tableHead'>
                        <th>
                          &nbsp; Date
                        </th>
                        <th>
                          Address Type
                        </th>
                        <th>
                          Address
                        </th>
                        <th>
                          Cell Phone No.
                        </th>
                        <th>
                          Home Phone No.
                        </th>
                        <th>
                          Email
                        </th>
                        <th>
                          Preferred Contact Method
                        </th>
                        <th>
                          Notes
                        </th>
                        <th>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        listOfPatientAdditionalInfo.filter((j: any) => j?.patientInfoTypeId == i._id).map((k: any) => (
                          <tr className='tableBody' key={k._id}>
                            <td title={date(k.createdDate)} >
                              &nbsp; {date(k.createdDate)}
                            </td>
                            <td title={k?.addressType?.addressType}>
                              {k?.addressType?.addressType}
                            </td>
                            <td title={`${k?.addressLine1}${k?.addressLine2 ? "," :''} ${k?.addressLine2 != null ? k?.addressLine2 :''}`}>
                            {k?.addressLine1}{k?.addressLine2 ? "," :''} {k?.addressLine2 != null ? k?.addressLine2 :''}
                            </td>
                            <td title={`${k.mobileCountryCode} ${k.mobileNo}`}>
                              {k.mobileCountryCode} {k.mobileNo}
                            </td>
                            <td title={`${k.homeCountryCode} ${k.homeNo}`}>
                              {k.homeCountryCode} {k.homeNo}
                            </td>
                            <td title={k.emailAddress}>
                              {k.emailAddress}
                            </td>
                            <td title={k?.preferredcontactmethod?.preferredContactMethod}>
                              {k?.preferredcontactmethod?.preferredContactMethod}
                            </td>
                            <td title={k.notes}>
                              {k.notes}
                            </td>
                            <td className=' d-flex gap-2'>
                              <button className='blueButtonSmall' onClick={() => setOpenEditPopUpfun(i._id, k._id, i?.patientInfoType)}>
                                <i className="fa-solid fa-pen"></i></button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
              </div>
            </div>
          ) : (
            <div className='' key={i._id}>
              <div className='d-flex gap-2 align-items-center p-2 lowerBorder'>
                <span className='title-header'>{i?.patientInfoType}</span>
                {i._id == listOfAdditionalInfo[1]?._id ? (
                  <button
                    hidden={
                      listOfPatientAdditionalInfo.filter(
                        (j: any) => j?.patientInfoTypeId == i._id
                      )[0]
                        ? true
                        : false
                    }
                    onClick={() => openPopUpfun(i._id, i?.patientInfoType)}
                    className='blueButtonSmall '
                  >
                    <i className='fa fa-plus'></i>
                  </button>
                ) : (
                  <button
                    onClick={() => openPopUpfun(i._id, i?.patientInfoType)}
                    className='blueButtonSmall '
                  >
                    <i className='fa fa-plus'></i>
                  </button>
                )}
              </div>
              <div className='p-2'>
                {/* <table className='fullCurve w-100   '>
                  <thead className=''>
                    <tr className='tableHead'>
                      <th>&nbsp; Date</th>
                      <th>Name</th>
                      <th>Relation With Patient</th>
                      <th>Cell Phone No.</th>
                      <th>Home Phone No.</th>
                      <th>Email</th>
                      <th>Address</th>
                      <th>Preferred Contact Method</th>
                      <th>Notes</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {listOfPatientAdditionalInfo
                      .filter((j: any) => j?.patientInfoTypeId == i._id)
                      .map((k: any) => (
                        <tr className='tableBody' key={k._id}>
                          <td title={date(k.createdDate)}>&nbsp; {date(k.createdDate)}</td>
                          <td title={`${k.firstName} ${k.lastName}`}>
                            {k.firstName} {k.lastName}
                          </td>
                          <td title={k?.relationShipType?.relationship}>
                            {k?.relationShipType?.relationship}
                          </td>
                          <td title={`${k.mobileCountryCode} ${k.mobileNo}`}>
                            {k.mobileCountryCode} {k.mobileNo}
                          </td>
                          <td title={`${k.homeCountryCode} ${k.homeNo}`}>
                            {k.homeCountryCode} {k.homeNo}
                          </td>
                          <td title={k.emailAddress}>{k.emailAddress}</td>
                          <td
                            title={`${k?.addressLine1}${k?.addressLine2 ? ',' : ''} ${
                              k?.addressLine2 != null ? k?.addressLine2 : ''
                            }`}
                          >
                            {k?.addressLine1}
                            {k?.addressLine2 ? ',' : ''}{' '}
                            {k?.addressLine2 != null ? k?.addressLine2 : ''}
                          </td>
                          <td title={k?.preferredcontactmethod?.preferredContactMethod}>
                            {k?.preferredcontactmethod?.preferredContactMethod}
                          </td>
                          <td title={k.notes}>{k.notes}</td>
                          <td className=' d-flex gap-2'>
                            <button
                              className='blueButtonSmall'
                              onClick={() => setOpenEditPopUpfun(i._id, k._id, i?.patientInfoType)}
                            >
                              <i className='fa-solid fa-pen'></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table> */}
                <CommonTable
                  data={listOfPatientAdditionalInfo.filter((j: any) => j?.patientInfoTypeId === i._id)}
                  columns={columns} 
                  totalCount={listOfPatientAdditionalInfo.filter((j: any) => j?.patientInfoTypeId === i._id).length}
                  perPage={10} 
                  setPerPage={setPerPage} 
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  isPagelength={true}
                  isSearched={false}
                  headerColor={tablecolor.primaryTable.color}
                  issetPaginationCompo={true}
                  setQuery={setSearchQuery}
                />
              </div>
            </div>
          )
        )}
      </div>
      {openPopUp && (
        <>
          <AdditionInfoPopups
            listOfAdditionalInfo={listOfAdditionalInfo}
            open={openPopUp}
            close={closePopUp}
            headerName={headerName}
            popUpNo={popupId}
          />
        </>
      )}
      {openEditPopUp && (
        <>
          <AdditionInfoPopupsEdit
            open={openEditPopUp}
            close={closePopUp}
            headerName={headerName}
            popUpNo={popupId}
            AdditionalInfoId={additionalInfoId}
            listOfAdditionalInfo={listOfAdditionalInfo}
          />
        </>
      )}
    </>
  )
}
export default PatientAdditionalInfo

// import React, {useEffect, useState} from 'react'
// import AdditionInfoPopups from './ReusablePatients/AdditionInfoPopups'
// import axios from 'axios'
// import moment from 'moment'
// import {useParams} from 'react-router-dom'
// import AdditionInfoPopupsEdit from './ReusablePatients/AdditionInfoPopupsEdit'
// import CommonTable from '../../../ReUsableComponent/CommonTable'

// function PatientAdditionalInfo(props) {
//   const APIkey = process.env.REACT_APP_SERVERAPI
//   const {id} = useParams()
//   const changeTab = () => {
//     props.changeTab(props.nextTab)
//   }

//   const [listOfAdditionalInfo, setListOfAdditionalInfo] = useState([])
//   const [listOfPatientAdditionalInfo, setListOfPatientAdditionalInfo] = useState([])
//   const [openPopUp, setOpenPopUp] = useState(false)
//   const [openEditPopUp, setOpenEditPopUp] = useState(false)
//   const [popupId, setPopupId] = useState()
//   const [additionalInfoId, setAdditionalInfoId] = useState()
//   const [headerName, setHeaderName] = useState('')

//   useEffect(() => {
//     const getallList = async () => {
//       try {
//         let getloginInfo = localStorage.getItem('appLoginInfo')
//         if (getloginInfo !== null) {
//           const Token = JSON.parse(getloginInfo)?.data?.accessToken
//           const clinicID = JSON.parse(getloginInfo)?.data?.clinicId
//           const header = {
//             headers: {'x-access-token': Token},
//           }

//           const getListOfAdditionalInformation = await axios.get(
//             `${APIkey}/api/admin/getPatientAdditionalInfoTypeList`,
//             header
//           )
//           setListOfAdditionalInfo(getListOfAdditionalInformation?.data?.data)

//           const getPatientAdditionalInfo = await axios.get(
//             `${APIkey}/api/admin/getPatientAdditionalInfo/${id}`,
//             header
//           )
//           setListOfPatientAdditionalInfo(getPatientAdditionalInfo?.data?.data)
//         } else {
//           console.log('No login info found in localStorage')
//         }
//       } catch (err) {
//         console.log(err)
//       }
//     }
//     getallList()
//   }, [openPopUp, openEditPopUp])

//   const openPopUpfun = (id, header) => {
//     setPopupId(id)
//     setOpenPopUp(true)
//     setHeaderName(header)
//   }

//   const setOpenEditPopUpfun = (id, additionalInfoId, header) => {
//     setPopupId(id)
//     setAdditionalInfoId(additionalInfoId)
//     setOpenEditPopUp(true)
//     setHeaderName(header)
//   }

//   const closePopUp = () => {
//     setOpenPopUp(false)
//     setOpenEditPopUp(false)
//   }

//   const date = (d) => moment(d).format('L')

//   // Column configurations for the tables
//   const additionalInfoColumns = [
//     {key: ['createdDate'], header: 'Date', isDate: true},
//     {key: ['addressType', 'addressType'], header: 'Address Type'},
//     {key: ['addressLine1', 'addressLine2'], header: 'Address', isEncrypted: false},
//     {key: ['mobileCountryCode', 'mobileNo'], header: 'Cell Phone No.'},
//     {key: ['homeCountryCode', 'homeNo'], header: 'Home Phone No.'},
//     {key: ['emailAddress'], header: 'Email'},
//     {key: ['preferredcontactmethod', 'preferredContactMethod'], header: 'Preferred Contact Method'},
//     {key: ['notes'], header: 'Notes'},
//     {
//       key: [],
//       header: 'Actions',
//       isAction: true,
//       ActionComponent: ({id}) => (
//         <button
//           className='blueButtonSmall'
//           // onClick={() => setOpenEditPopUpfun(id)}
//         >
//           <i className='fa-solid fa-pen'></i>
//         </button>
//       ),
//     },
//   ]

//   const patientInfoColumns = [
//     {key: ['createdDate'], header: 'Date', isDate: true},
//     {key: ['firstName'], header: 'Name'},
//     {key: ['relationShipType', 'relationship'], header: 'Relation With Patient'},
//     {key: ['mobileCountryCode', 'mobileNo'], header: 'Cell Phone No.'},
//     {key: ['homeCountryCode', 'homeNo'], header: 'Home Phone No.'},
//     {key: ['emailAddress'], header: 'Email'},
//     {key: ['addressLine1', 'addressLine2'], header: 'Address'},
//     {key: ['preferredcontactmethod', 'preferredContactMethod'], header: 'Preferred Contact Method'},
//     {key: ['notes'], header: 'Notes'},
//     {
//       key: [],
//       header: 'Actions',
//       isAction: true,
//       ActionComponent: ({id}) => (
//         <button
//           className='blueButtonSmall'
//           // onClick={() => setOpenEditPopUpfun(id)}
//         >
//           <i className='fa-solid fa-pen'></i>
//         </button>
//       ),
//     },
//   ]

//   return (
//     <>
//       <div className='card'>
//         {listOfAdditionalInfo.map((i) => (
//           <div
//           //  key={i._id}
//           >
//             <div className='d-flex gap-2 align-items-center p-2 lowerBorder'>
//               <span className='title-header'>
//                 {/* {i?.patientInfoType} */}
//                 </span>
//               <button
//                 className='blueButtonSmall'
//                 // onClick={() => openPopUpfun(i._id, i?.patientInfoType)}
//               >
//                 <i className='fa fa-plus'></i>
//               </button>
//             </div>
//             <div className='p-2'>

//                 <CommonTable
//                   data={listOfPatientAdditionalInfo}
//                   columns={additionalInfoColumns}
//                   totalCount={listOfPatientAdditionalInfo.length}
//                   perPage={10} // Set as per your requirement
//                   setPerPage={() => {}}
//                   currentPage={1} // Manage page state
//                   setCurrentPage={() => {}}
//                   setQuery={() => {}}
//                   isSearched={false}
//                   isPagelength={false}
//                   onRowClick={() => {}}
//                 />

//                 <CommonTable
//                   data={listOfPatientAdditionalInfo}
//                   columns={patientInfoColumns}
//                   totalCount={listOfPatientAdditionalInfo.length}
//                   perPage={10} // Set as per your requirement
//                   setPerPage={() => {}}
//                   currentPage={1} // Manage page state
//                   setCurrentPage={() => {}}
//                   setQuery={() => {}}
//                   isSearched={false}
//                   isPagelength={false}
//                   onRowClick={() => {}}
//                 />

//             </div>
//           </div>
//         ))}
//       </div>
//       {openPopUp && (
//         <AdditionInfoPopups
//           listOfAdditionalInfo={listOfAdditionalInfo}
//           open={openPopUp}
//           close={closePopUp}
//           headerName={headerName}
//           popUpNo={popupId}
//         />
//       )}
//       {openEditPopUp && (
//         <AdditionInfoPopupsEdit
//           open={openEditPopUp}
//           close={closePopUp}
//           headerName={headerName}
//           popUpNo={popupId}
//           AdditionalInfoId={additionalInfoId}
//           listOfAdditionalInfo={listOfAdditionalInfo}
//         />
//       )}
//     </>
//   )
// }

// export default PatientAdditionalInfo
