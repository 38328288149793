import React, { useEffect, useState } from 'react'
import UpArrow from '../EHRIcon/UpArrow.png'
import DownArrow from '../EHRIcon/DownArrow.png'
import eyeIcon from '../EHRIcon/eyeIcon.svg'
import notes from '../EHRIcon/Notes.svg'

import axios from 'axios'
import { useParams } from 'react-router-dom';
import moment from 'moment';
import PatientOrVisitDetailsPopUp from './ReusablePatients/PatientOrVisitDetailsPopUp'
import CommonTable from '../../../ReUsableComponent/CommonTable'
import tablecolor from '../../../ReUsableComponent/ReyClinicalinputs.json';
import { isDate } from 'util/types'


function PatientAppointmentDetails({patientData}) {
    

    const APIkey = process.env.REACT_APP_SERVERAPI;

    const { id } = useParams()

  


    const [pastAndCurrentAppointmentList, setPastAndCurrentAppointmentList] = useState([])
    const [futureAppointmentList, setFutureAppointmentList] = useState([])
    const [rescheduleAppointmentList, setRescheduleAppointmentList] = useState([])
    const [cancelledAppointmentList, setCancelledAppointmentList] = useState([])
    const [noShowAppointmentList, setNoShowAppointmentList] = useState([])

    const [dowmFutureAppoint, setDownFutureAppoint] = useState(false)
    const [dowmCP, setDownCP] = useState(false)
    const [dowmReschedule, setDownReschedule] = useState(false)
    const [dowmCancelled, setDownCancelled] = useState(false)
    const [dowmNoShow, setDownNoShow] = useState(false)

    const [openPopUp, setopenPopUp] = useState(false)


    const [appointmentDetails , setAppointmentDetails ] = useState({})
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [searchQuery, setSearchQuery] = useState<string>('');


    let randomData = [
        {
            rd: " xyz"
        }
    ]


    const getTheAppointmentList = async () => {
        try {
            let getloginInfo: string | null = localStorage.getItem('appLoginInfo')

            if (getloginInfo !== null) {

                const Token = JSON.parse(getloginInfo).data.accessToken;

                const Id = JSON.parse(getloginInfo).data.clinicId;

                const header = {
                    headers: { 'x-access-token': Token }
                }



                const cDate = new Date()
                const dDate = (d: any) => new Date(d)


                const patientAppointmentData = await axios.get(`${APIkey}/api/admin/getAllAppointment?page=1&limit=50000`, header);
                console.log(patientAppointmentData.data.data.appointment_list);

                setPastAndCurrentAppointmentList(
                    patientAppointmentData?.data?.data?.appointment_list.filter(i => i?.patientId == id).filter(j => {
                        return (cDate >= dDate(j.appointmentDate));
                    })
                );


                setFutureAppointmentList(patientAppointmentData?.data?.data?.appointment_list?.filter(i => i?.patientId == id)?.filter((j: any) => cDate <= dDate(j?.appointmentDate)));
                setRescheduleAppointmentList(patientAppointmentData?.data?.data?.appointment_list?.filter(i => i?.patientId == id)?.filter((j: any) => (j?.appointmentStatusId == "65e58e2d274befb8a9f8b71c")));
                setCancelledAppointmentList(patientAppointmentData?.data?.data?.appointment_list?.filter(i => i?.patientId == id)?.filter((j: any) => (j?.appointmentStatusId == "65e58e0b274befb8a9f8b6e2")));
                setNoShowAppointmentList(patientAppointmentData?.data?.data?.appointment_list?.filter(i => i?.patientId == id)?.filter((j: any) => (j?.appointmentStatusId == "65e58e25274befb8a9f8b70b")));


            }
            else {
                console.log("No login info found in localStorage");
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getTheAppointmentList()
    }, [])



    const closePatientOrVisitDetails =()=> {
        setopenPopUp(false)
    }



    const openAppointmentVisitfun =(id)=> {
        setopenPopUp(true)
    }


    const getAppointment =(app)=> {
        setAppointmentDetails(app)
    }

    const listdate = (c) => moment(c).format('L')
    const listDay = (c) => moment(c).format('dddd')

    const columns = [
        { key: ['clinicDetails','clinicName'], header: 'Clinic Name', accessor: (row) => row?.clinicDetails?.clinicName || '-' },
        { key: ['appointmentDate'], header: 'Visit Date', isDate:true },
        { key: ['day'], header: 'Day', },
        { key: ['appointmentTime'], header: 'Visit Time', accessor: 'appointmentTime' },
        { key: ['appointmentTypeDetails','appointmentType'], header: 'Visit Type', accessor: (row) => row?.appointmentTypeDetails?.appointmentType || '-' },
        { key: ['appointmentstatusDetails','appointmentStatus'], header: 'Visit Status', accessor: (row) => row?.appointmentstatusDetails?.appointmentStatus || '-' },
        { key: ['usersDetails','firstName'], header: 'Provider', accessor: (row) => `${row?.usersDetails?.firstName || ''} ${row?.usersDetails?.lastName || ''}` },
        { key: ['reasonforvisitDetails','reason'], header: 'Reason For Visit', accessor: (row) => row?.reasonforvisitDetails?.reason || '-' },
        {
          key: ['actions'],
          header: '',
          isAction: true,
          ActionComponent: (row) => (
            <div className='d-flex gap-2'>
              <img src={notes} alt="Notes" style={{ cursor: 'pointer' }} />
              <img
                src={eyeIcon}
                alt="View"
                style={{ cursor: 'pointer' }}
                onClick={() => [openAppointmentVisitfun(row._id), getAppointment(row)]}
              />
            </div>
          ),
        },
      ];


    return (
        <div className='card p-2 '>

            <div className='row align-items-center p-2'>
                <div className='col-md-3 '>
                    <div className='d-flex align-items-center gap-1'>
                        <div className='w-50 '>
                            <span className='patient-info-title ' >
                                Schedule Date:
                            </span>
                        </div>
                        <div>
                            <input type="text" className='form-control SearchInputbox' />
                        </div>

                        <div>
                            <span className='patient-info-title' >
                                To
                            </span>
                        </div>
                        <div>
                            <input type="text" className='form-control SearchInputbox' />
                        </div>
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='d-flex align-items-center gap-1'>
                        <div className='w-50 d-flex justify-content-end '>
                            <span className='patient-info-title '  >
                                Visit Type:
                            </span>
                        </div>
                        <div className='SearchInputbox'>
                            <select name="" id="" className='form-select  SearchInputbox'>
                                <option value=""></option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='d-flex gap-2'>
                        <div >
                            <button
                                className='blueButton'
                                onClick={() => setopenPopUp(!openPopUp)}
                            >
                                Search
                            </button>
                        </div>
                        <div className=''>
                            <button className='grayButton'
                            >
                                Clear
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col-md-3 '>
                    <div className=' d-flex align-items-center justify-content-end gap-2'>

                        <div className=' d-flex w-50 SearchInputbox'>
                            <select name="" id="" className='form-select  SearchInputbox'>
                                <option value=""></option>
                            </select>
                        </div>
                        <div>
                            icon
                        </div>
                    </div>
                </div>
            </div>


            <div className="card my-1">
                <div className={dowmFutureAppoint ? 'PatientChartCardHeader' : 'PatientChartCardHeadercondition'}>
                    <div className="d-flex" >

                        <div>
                            <span className=" " >
                                Future Appointment - {futureAppointmentList.length}
                            </span>
                        </div>
                    </div>

                    <div className="d-flex">

                        <div className="px-2 PatientChartCardHeaderIcon"
                            style={{ cursor: 'pointer' }}
                            onClick={() => setDownFutureAppoint(!dowmFutureAppoint)}
                        >
                            {
                                dowmFutureAppoint ?
                                    <img src={UpArrow} alt="" /> :
                                    <img src={DownArrow} alt="" />
                            }
                        </div>
                        <div>

                        </div>
                    </div>


                </div>
                {
                    dowmFutureAppoint &&
                    <div>

                        <div className="">
                            <table className="" style={{ width: "100%" }}>
                                <thead className="tableHead" >

                                    <tr>
                                        <th>Clinic Name</th>
                                        <th>Future Date</th>
                                        <th>Day</th>
                                        <th >Visit Time</th>
                                        <th >Visit Type</th>
                                        <th >Visit Status</th>
                                        <th >Provider</th>
                                        <th >Reason For Visit</th>
                                        <th></th>

                                    </tr>
                                </thead>
                                <tbody className="">

                                    {
                                        futureAppointmentList?.map((item: any) => (

                                            <tr className="tableBody" key={item._id}>

                                                <td>
                                                    {item?.clinicDetails?.clinicName}
                                                </td>
                                                <td>
                                                    {listdate(item.appointmentDate)}
                                                </td>
                                                <td>
                                                    {listDay(item.appointmentDate)}
                                                </td>
                                                <td>
                                                    {item.appointmentTime}
                                                </td>
                                                <td>
                                                    {item?.appointmentTypeDetails?.appointmentType}
                                                </td>
                                                <td>
                                                    {item?.appointmentstatusDetails?.appointmentStatus}
                                                </td>
                                                <td>
                                                    {item?.usersDetails?.firstName} {item?.usersDetails?.lastName}
                                                </td>
                                                <td>
                                                    {item?.reasonforvisitDetails?.reason}
                                                </td>
                                                <td className='d-flex gap-2'>
                                                    <img 
                                                    src={notes} 
                                                    alt="" 
                                                    style={{cursor : 'pointer'}} />

                                                    <img 
                                                    src={eyeIcon} 
                                                    alt="" 
                                                    style={{cursor : 'pointer'}}
                                                     onClick={()=> [openAppointmentVisitfun(item._id) , , getAppointment(item)]} />
                                                </td>

                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="PatientChartCardFooter">
                            <div>
                                <span>
                                    {/* Total Numbers of Records: {vitalsData.length} */}
                                </span>
                            </div>
                            <div>

                                <div className='pagination-container'>

                                </div>
                            </div>
                        </div>
                    </div>

                }
            </div>
            <div className="card my-1">
                <div className={dowmCP ? 'PatientChartCardHeader' : 'PatientChartCardHeadercondition'}>
                    <div className="d-flex" >

                        <div>
                            <span className=" " >
                                Current & Past Appointments - {pastAndCurrentAppointmentList.length}
                            </span>
                        </div>
                    </div>

                    <div className="d-flex">

                        <div className="px-2 PatientChartCardHeaderIcon"
                            style={{ cursor: 'pointer' }}
                            onClick={() => setDownCP(!dowmCP)}
                        >
                            {
                                dowmCP ?
                                    <img src={UpArrow} alt="" /> :
                                    <img src={DownArrow} alt="" />

                            }
                        </div>
                        <div>

                        </div>
                    </div>


                </div>
                {
                    dowmCP &&
                    <div>

<div className="w-100">
      <CommonTable
        columns={columns}
        data={pastAndCurrentAppointmentList}
        currentPage={currentPage}
        perPage={perPage}
        totalCount={pastAndCurrentAppointmentList.length}
        setCurrentPage={setCurrentPage}
        setPerPage={setPerPage}
        searchQuery={searchQuery}
        setQuery={setSearchQuery}
        headerColor={tablecolor.primaryTable.color}
        issetPaginationCompo={true}
        isPagelength={true}
        isSearched={true}
      />
    </div>
                        <div className="PatientChartCardFooter">
                            <div>
                                <span>
                                    {/* Total Numbers of Records: {vitalsData.length} */}
                                </span>
                            </div>
                            <div>

                                <div className='pagination-container'>

                                </div>
                            </div>
                        </div>
                    </div>

                }
            </div>
            <div className="card my-1">
                <div className={dowmReschedule ? 'PatientChartCardHeader' : 'PatientChartCardHeadercondition'}>
                    <div className="d-flex" >

                        <div>
                            <span className=" " >
                                Reschedule Appointments - {rescheduleAppointmentList.length}
                            </span>
                        </div>
                    </div>

                    <div className="d-flex">

                        <div className="px-2 PatientChartCardHeaderIcon"
                            style={{ cursor: 'pointer' }}
                            onClick={() => setDownReschedule(!dowmReschedule)}
                        >
                            {
                                dowmReschedule ?
                                    <img src={UpArrow} alt="" /> :
                                    <img src={DownArrow} alt="" />

                            }
                        </div>
                        <div>

                        </div>
                    </div>


                </div>
                {
                    dowmReschedule &&
                    <div>

                        <div className="">
                            <table className="w-100" >
                                <thead className="tableHead" >

                                    <tr>
                                        <th>Clinic Name</th>
                                        <th>Visit Date</th>
                                        <th>Day</th>
                                        <th >Visit Time</th>
                                        <th >Visit Type</th>
                                        <th >Visit Status</th>
                                        <th >Provider</th>
                                        <th>Reason For Visit </th>
                                        <th>Reason For Reschedule</th>
                                        <th></th>

                                    </tr>
                                </thead>
                                <tbody className="">

                                    {
                                        rescheduleAppointmentList.map((item: any) => (

                                            <tr className="tableBody">

                                                <td>
                                                    {item?.clinicDetails?.clinicName}
                                                </td>

                                                <td>
                                                    {listdate(item.appointmentDate)}
                                                </td>
                                                <td>
                                                    {listDay(item?.appointmentDate)}
                                                </td>
                                                <td>
                                                    {item?.appointmentTime}
                                                </td>
                                                <td>
                                                    {item?.appointmentTypeDetails?.appointmentType}
                                                </td>
                                                <td>
                                                    {item?.appointmentstatusDetails?.appointmentStatus}
                                                </td>
                                                <td>
                                                    {item?.usersDetails?.firstName} {item?.usersDetails?.lastName}
                                                </td>
                                                <td>
                                                    {item?.reasonforvisitDetails?.reason}
                                                </td>
                                                <td>
                                                    {/* {item.notes} */}
                                                </td>
                                                <td className='d-flex gap-2'>
                                                    <img src={notes} alt="" style={{cursor : 'pointer'}} />

                                                    <img 
                                                    src={eyeIcon} 
                                                    alt="" 
                                                    style={{cursor : 'pointer'}} 
                                                    onClick={()=> [openAppointmentVisitfun(item._id), getAppointment(item)]}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="PatientChartCardFooter">
                            <div>
                                <span>
                                    {/* Total Numbers of Records: {vitalsData.length} */}
                                </span>
                            </div>
                            <div>

                                <div className='pagination-container'>

                                </div>
                            </div>
                        </div>
                    </div>

                }
            </div>
            <div className="card my-1">
                <div className={dowmCancelled ? 'PatientChartCardHeader' : 'PatientChartCardHeadercondition'}>
                    <div className="d-flex" >

                        <div>
                            <span className=" " >
                                Cancelled Appointments - {cancelledAppointmentList.length}
                            </span>
                        </div>
                    </div>

                    <div className="d-flex">

                        <div className="px-2 PatientChartCardHeaderIcon"
                            style={{ cursor: 'pointer' }}
                            onClick={() => setDownCancelled(!dowmCancelled)}
                        >
                            {
                                dowmCancelled ?
                                    <img src={UpArrow} alt="" /> :
                                    <img src={DownArrow} alt="" />

                            }
                        </div>
                        <div>

                        </div>
                    </div>


                </div>
                {
                    dowmCancelled &&
                    <div>

                        <div className="">
                            <table className="" style={{ width: "100%" }}>
                                <thead className="tableHead" >

                                    <tr>
                                        <th>Clinic Name</th>
                                        <th>Visit Date</th>
                                        <th>Day</th>
                                        <th >Visit Time</th>
                                        <th >Visit Type</th>
                                        <th >Visit Status</th>
                                        <th >Provider</th>
                                        <th>Reason for Cancel </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className="">

                                    {
                                        cancelledAppointmentList.map((item: any) => (

                                            <tr className="tableBody">

                                                <td>
                                                    {item?.clinicDetails?.clinicName}
                                                </td>
                                                <td>
                                                    {listdate(item.appointmentDate)}
                                                </td>
                                                <td>
                                                    {listDay(item.appointmentDate)}

                                                </td>
                                                <td>
                                                    {item.appointmentTime}
                                                </td>
                                                <td>
                                                    {item?.appointmentTypeDetails?.appointmentType}
                                                </td>
                                                <td>
                                                    {item?.appointmentstatusDetails?.appointmentStatus}
                                                </td>
                                                <td>
                                                    {item?.usersDetails?.firstName} {item?.usersDetails?.lastName}
                                                </td>
                                                <td>
                                                    {/* {item.oxygenSat} */}
                                                </td>


                                                <td className='d-flex gap-2'>
                                                    <img src={notes} alt="" style={{cursor : 'pointer'}} />

                                                    <img
                                                     src={eyeIcon}
                                                      alt=""
                                                       style={{cursor : 'pointer'}} 
                                                       onClick={()=> [openAppointmentVisitfun(item._id), getAppointment(item)]}
                                                       />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="PatientChartCardFooter">
                            <div>
                                <span>
                                    {/* Total Numbers of Records: {vitalsData.length} */}
                                </span>
                            </div>
                            <div>

                                <div className='pagination-container'>

                                </div>
                            </div>
                        </div>
                    </div>

                }
            </div>
            <div className="card my-1">
                <div className={dowmNoShow ? 'PatientChartCardHeader' : 'PatientChartCardHeadercondition'}>
                    <div className="d-flex" >

                        <div>
                            <span className=" " >
                                No Show Appointments - {noShowAppointmentList.length}
                            </span>
                        </div>
                    </div>

                    <div className="d-flex">

                        <div className="px-2 PatientChartCardHeaderIcon"
                            style={{ cursor: 'pointer' }}
                            onClick={() => setDownNoShow(!dowmNoShow)}
                        >
                            {
                                dowmNoShow ?
                                    <img src={UpArrow} alt="" /> :
                                    <img src={DownArrow} alt="" />

                            }
                        </div>
                        <div>

                        </div>
                    </div>


                </div>
                {
                    dowmNoShow &&
                    <div>

                        <div className="">
                            <table className="w-100" >
                                <thead className="tableHead" >

                                    <tr>
                                        <th>Clinic Name</th>
                                        <th>Visit Date</th>
                                        <th>Day</th>
                                        <th >Visit Time</th>
                                        <th >Visit Type</th>
                                        <th >Visit Status</th>
                                        <th >Provider</th>
                                        <th>no Show Reason</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className="">

                                    {
                                        noShowAppointmentList.map((item: any) => (

                                            <tr className="tableBody">

                                                <td>
                                                    {item?.clinicDetails?.clinicName}
                                                </td>
                                                <td>
                                                    {listdate(item.appointmentDate)}
                                                </td>
                                                <td>
                                                    {listDay(item.appointmentDate)}
                                                </td>
                                                <td>
                                                    {item.appointmentTime}
                                                </td>
                                                <td>
                                                    {item?.appointmentTypeDetails?.appointmentType}
                                                </td>
                                                <td>
                                                    {item?.appointmentstatusDetails?.appointmentStatus}
                                                </td>
                                                <td>
                                                    {item?.usersDetails?.firstName} {item?.usersDetails?.lastName}
                                                </td>
                                                <td>
                                                    {/* {item.oxygenSat} */}
                                                </td>

                                                <td className='d-flex gap-2'>
                                                    <img src={notes} alt="" style={{cursor : 'pointer'}} />
                                                    
                                                    <img
                                                     src={eyeIcon}
                                                      alt=""
                                                       style={{cursor : 'pointer'}}
                                                       onClick={()=> [openAppointmentVisitfun(item._id) , getAppointment(item)]}
                                                       />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="PatientChartCardFooter">
                            <div>
                                <span>
                                    {/* Total Numbers of Records: {vitalsData.length} */}
                                </span>
                            </div>
                            <div>

                                <div className='pagination-container'>

                                </div>
                            </div>
                        </div>
                    </div>

                }
            </div>

            {

            }

            {
                openPopUp &&
                (
                  <PatientOrVisitDetailsPopUp
                  open={true}
                  futureAppointment={futureAppointmentList}
                  closePatientOrVisitDetails={closePatientOrVisitDetails}
                  patientDetails={patientData || []}
                    AppointmentData = {appointmentDetails}
                  />
                )
            }
        </div>

    )
}

export default PatientAppointmentDetails
