import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {useNavigate} from 'react-router-dom';
import CommonButton, {CommonbigButton, CommonPopupButton} from '../SuperAdmin/Pages/ReausableComponent/CommonButton';
import TitleComponent from './TitleComponent';

function CancelConfirmation({openCancel, closeCancel, Routes}) {
  const navigate = useNavigate();

  const handleCloseCancel = () => {
    closeCancel();
  };

  const handleCancel = (e) => {
    closeCancel();
    navigate(e);
  };

  return (
    <Modal show={openCancel} onHide={handleCloseCancel} size='lg' centered>
      <Modal.Header closeButton style={{background: '#F2F3F7'}} className='py-1 px-3'>
        <Modal.Title className='px-0'>
          <TitleComponent type='title'> Cancel Confirmation</TitleComponent>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='fs-4 px-3 py-1 fw-bold dialoge'>
        <div className='card p-3 sectionTitle'>Are you sure you want to cancel this ?</div>
      </Modal.Body>

      <Modal.Footer className='p-2 dialoge'>
        <div className='d-flex gap-2'>
          <CommonPopupButton onClick={() => handleCancel(Routes)}>Yes</CommonPopupButton>
          <CommonPopupButton onClick={handleCloseCancel} color='#818181' background='#D7D7D7'>
            Cancel
          </CommonPopupButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default CancelConfirmation;
