import React, {useEffect, useState} from 'react'
import NameCompo from '../../../SuperAdmin/Pages/ReausableComponent/NameCompo'
import CryptoJS from 'crypto-js'

import SerchableDropdown from '../ReusablePages/ReusableAppointment/SearchableDropdown'
import AddressComponent from '../../../ReUsableComponent/AddressComponent'
import ContactComponent from '../../../SuperAdmin/Pages/ReausableComponent/ContactComponent'
import axios from 'axios'
import GenderComponent from '../../../ReUsableComponent/GenderComponent'
import {useParams} from 'react-router-dom'
import moment from 'moment'

import inputText from '../../../ReUsableComponent/ReyClinicalinputs.json'
import MaskedInput from 'react-text-mask'
import PatientInfoValidation from './ReusablePatients/PatientInfoValidation'
import NameCompoForClinic from '../../../SuperAdmin/Pages/ReausableComponent/NameCompoForClinic'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Datepicker from '../../../ReUsableComponent/Datepicker'
import { useNavigate } from 'react-router-dom';
import TitleComponent from '../../../ReUsableComponent/TitleComponent'
import { CommonbigButton } from '../../../SuperAdmin/Pages/ReausableComponent/CommonButton'
import CancelConfirmation from '../../../ReUsableComponent/CancelConfirmation'
// import inputText from '../../../ReUsableComponent/ReyClinicalinputs.json'

function PatientInformation(props) {
  const {id} = useParams()

  const APIkey = process.env.REACT_APP_SERVERAPI

  const [changeTabsave, setchangeTabsave] = useState('')

  const [loading, setLoading] = useState(false)

  const [religionList, setReligionList] = useState([])
  const [bloodgroupList, setBloodgroupList] = useState([])
  const [raceGroupList, setRaceGroupList] = useState([])
  const [raceList, setRaceList] = useState([])
  const [ethiniCityList, setEthiniCityList] = useState([])
  const [patientCategoryList, setPatientCategoryList] = useState([])
  const [modeOfReminderList, setModeOfReminderList] = useState([])
  const [preferredLanguageList, setpreferredLanguageList] = useState([])
  const [meritalList, setMeritalList] = useState([])

  const [suffixId, setsuffix] = useState<string | undefined>(undefined)
  const [prefixId, setprefix] = useState<string | undefined>(undefined)
  const [lastName, setLastName] = useState<string | undefined>('')
  const [firstName, setFirstName] = useState<string | undefined>('')
  const [middleName, setMiddleName] = useState<string | undefined>('')
  const [alternateName, setAlternateName] = useState<string | undefined>('')
  const [genderId, setGender] = useState<string | undefined>(undefined)
  const [dob, setDob] = useState<string | undefined>(undefined)
  const [ssn, setSsn] = useState<string | undefined>()
  const [religionId, setReligion] = useState<string | undefined>(undefined)
  const [bloodGroupId, setBloodGroup] = useState<string | undefined>(undefined)
  const [raceGroupId, setRaceGroup] = useState<string | undefined>(undefined)
  const [raceId, setRace] = useState<string | undefined>(undefined)
  const [ethinicityId, setEthinicity] = useState<string | undefined>(undefined)
  const [externalMrn, setExternalMrn] = useState<string | undefined>(undefined)
  const [patientCategoryId, setPatientCategory] = useState<string | undefined>(undefined)
  const [modeOfReminderId, setmodeOfReminder] = useState<string | undefined>(undefined)
  const [preferredLanguage, setpreferredLanguage] = useState<string | undefined>(undefined)
  const [excludeFromPatientStatement, setexcludeFromPatientStatement] = useState<
    boolean | undefined
  >(false)
  const [phiexpemtedFromReport, setphiexpemtedFromReport] = useState<boolean | undefined>(false)
  const [declineRaceEthinicityLanguage, setdeclineRaceEthinicityLanguage] = useState<
    boolean | undefined
  >(false)
  const [addressLine1, setaddressLine1] = useState<string | undefined>(undefined)
  const [addressLine2, setaddressLine2] = useState<string | undefined>(undefined)
  const [countryId, setcountryId] = useState<string | undefined>(undefined)
  const [stateId, setstateId] = useState<string | undefined>(undefined)
  const [cityId, setcityId] = useState<string | undefined>(undefined)
  const [zipcodeId, setzipcodeId] = useState<string | undefined>(undefined)
  const [emailAddress, setemailAddress] = useState<string | undefined>(undefined)
  const [mobileCountryCode, setmobileCountryCode] = useState<string | undefined>(undefined)
  const [mobileNo, setmobileNo] = useState<string | undefined>(undefined)
  const [homeCountryCode, sethomeCountryCode] = useState<string | undefined>(undefined)
  const [homeNo, sethomeNo] = useState<string | undefined>(undefined)
  const [faxNo, setfaxNo] = useState<string | undefined>(undefined)
  const [faxCountryCode, setFaxCountryCode] = useState<string | undefined>(undefined)
  const [workCountryCode, setworkCountryCode] = useState<string | undefined>(undefined)
  const [workPhoneNo, setworkPhoneNo] = useState<string | undefined>(undefined)
  const [workExtNo, setworkExtNo] = useState<string | undefined>(undefined)
  const [maritalStatusID, setMaritalStatusId] = useState<string | undefined>(undefined)
  const [cancelPopUp, setCancelPopUp] = useState<any>(false);
  const [errLastName, setErrLastName] = useState<string | undefined>()
  const [errFirstName, setErrFirstName] = useState<string | undefined>()
  const [errMiddleName, setErrMiddleName] = useState<string | undefined>()
  const [errAlternateName, setErrAlternateName] = useState<string | undefined>()
  const [errGender, setErrGender] = useState<string | undefined>()
  const [errDob, setErrDob] = useState<string | undefined>()
  const [errSsn, setErrSsn] = useState<string | undefined>()
  const [errExternal, setErrExternal] = useState<string | undefined>()
  const [errAddressLine1, setErrAddressLine1] = useState<string | undefined>()
  const [errAddressLine2, setErrAddressLine2] = useState<string | undefined>()
  const [errcountry, setErrcountry] = useState<string | undefined>()
  const [errState, setErrState] = useState<string | undefined>()
  const [errCity, setErrCity] = useState<string | undefined>()
  const [errzip, setErrzip] = useState<string | undefined>()

  const [errEmail, setErrEmail] = useState<string | undefined>()
  const [errCellPhone, setErrCellPhone] = useState<string | undefined>()
  const [errHomePhone, setErrHomePhone] = useState<string | undefined>()
  const [errFax, setErrFax] = useState<string | undefined>()

  const [errWorkPhone, setErrWorkPhone] = useState<string | undefined>()
  const [errWorkExt, setErrWorkExt] = useState<string | undefined>()
  const navigate = useNavigate();
  const changeTab = () => {
    navigate(-1)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch login information from localStorage
        const getloginInfo = localStorage.getItem('appLoginInfo')

        if (getloginInfo) {
          const {accessToken: Token, clinicId: Id} = JSON.parse(getloginInfo).data

          // Set headers for authorization
          const headers = {
            headers: {'x-access-token': Token},
          }

          // Array of API calls using Promise.all for concurrent fetching
          const [
            religionResponse,
            bloodGroupResponse,
            raceGroupResponse,
            raceResponse,
            ethnicityResponse,
            patientCategoryResponse,
            modeOfReminderResponse,
            preferredLanguageResponse,
            maritalListResponse,
            patientByIdResponse,
          ] = await Promise.all([
            axios.get(`${APIkey}/api/admin/getReligionList/${Id}`, headers),
            axios.get(`${APIkey}/api/admin/getBloodGroupList`, headers),
            axios.get(`${APIkey}/api/admin/getRaceGroupList`, headers),
            axios.get(`${APIkey}/api/admin/getRaceList`, headers),
            axios.get(`${APIkey}/api/admin/getEthiniCityList`, headers),
            axios.get(`${APIkey}/api/admin/getPatientCategoryList`, headers),
            axios.get(`${APIkey}/api/admin/getModeOfReminderList`, headers),
            axios.get(`${APIkey}/api/admin/getPreferredLanguageList`, headers),
            axios.get(`${APIkey}/api/admin/getMaritalList`, headers),
            axios.get(`${APIkey}/api/admin/getPatientById/${id}`, headers),
          ])

          // Function to map API response data to required format
          const mapData = (data, key) =>
            data.data.data.map((item) => ({
              label: item[key],
              value: item[key],
              id: item._id,
            }))

          // Set state for each data fetched
          setReligionList(mapData(religionResponse, 'religion'))
          setBloodgroupList(mapData(bloodGroupResponse, 'bloodGroup'))
          setRaceGroupList(mapData(raceGroupResponse, 'raceGroup'))
          setRaceList(mapData(raceResponse, 'race'))
          setEthiniCityList(mapData(ethnicityResponse, 'ethiniCity'))
          setPatientCategoryList(mapData(patientCategoryResponse, 'patientCategory'))
          setModeOfReminderList(mapData(modeOfReminderResponse, 'modeOfReminder'))
          setpreferredLanguageList(mapData(preferredLanguageResponse, 'preferredLanguage'))
          setMeritalList(
            maritalListResponse.data.data.map((item) => ({
              label: item.marital,
              value: item.marital,
              id: item._id,
            }))
          )

          // Process patient data
          const {
            data: {data: patientData},
          } = patientByIdResponse
          console.log(patientData)

          const decrypted = (value) =>
            value ? CryptoJS.AES.decrypt(value, 'reyEhr').toString(CryptoJS.enc.Utf8) : ''

          setsuffix(patientData.suffixId)
          setprefix(patientData.prefixId)
          setLastName(patientData.lastName)
          setFirstName(patientData.firstName)
          setMiddleName(patientData.middleName)
          setAlternateName(patientData.alternateName)
          setGender(patientData.genderId)
          setDob(moment(patientData.dob).format('YYYY-MM-DD'))
          setSsn(decrypted(patientData.ssn))
          setReligion(patientData.religionId)
          setBloodGroup(patientData.bloodGroupId)
          setRaceGroup(patientData.raceGroupId)
          setRace(patientData.raceId)
          setMaritalStatusId(patientData.maritalStatusID)
          setEthinicity(patientData.ethinicityId)
          setExternalMrn(patientData.externalMrn)
          setPatientCategory(patientData.patientCategoryId)
          setmodeOfReminder(patientData.modeOfReminderId)
          setpreferredLanguage(patientData.preferredLanguage)
          setexcludeFromPatientStatement(patientData.excludeFromPatientStatement)
          setphiexpemtedFromReport(patientData.phiexpemtedFromReport)
          setdeclineRaceEthinicityLanguage(patientData.declineRaceEthinicityLanguage)
          setaddressLine1(patientData.addressLine1)
          setaddressLine2(patientData.addressLine2)
          setcountryId(patientData.countryId)
          setstateId(patientData.stateId)
          setcityId(patientData.cityId)
          setzipcodeId(patientData.zipcodeId)
          setemailAddress(decrypted(patientData.emailAddress))
          setmobileCountryCode(patientData.mobileCountryCode)
          setmobileNo(decrypted(patientData.mobileNo))
          sethomeCountryCode(patientData.homeCountryCode)
          sethomeNo(decrypted(patientData.homeNo))
          setFaxCountryCode(patientData.faxCountryCode)
          setfaxNo(patientData.faxNo)
          setworkCountryCode(patientData.workCountryCode)
          setworkPhoneNo(decrypted(patientData.workPhoneNo))
          setworkExtNo(patientData.workExtNo)
        } else {
          console.log('No login info found in localStorage')
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData() // Call fetchData function when component mounts or id changes
  }, [id]) // Dependency array to re-run effect when id changes

  const getprefix = (e) => {
    setprefix(e)
  }

  const getsuffix = (e) => {
    setsuffix(e)
  }

  const getFirstName = (e) => {
    setFirstName(e)
  }

  const getLastName = (e) => {
    setLastName(e)
  }

  const getMiddleName = (e) => {
    setMiddleName(e)
  }

  const getTheAlternateName = (e) => {
    setAlternateName(e.target.value)
  }

  const getTheSex = (e) => {
    setGender(e)
  }

  const getDob = (e) => {
    setDob(e.target.value)
  }
  const getTheSsn = (e) => {
    const value = e.target.value.replace(/\D/g, '')
    setSsn(value)
    console.log(value)
  }

  const getTheReligion = (e) => {
    setReligion(e)
  }

  const getBloodGroup = (e) => {
    setBloodGroup(e)
  }

  const getTheRaceGroup = (e) => {
    setRaceGroup(e)
  }

  const getTheRace = (e) => {
    setRace(e)
  }

  const getEthiniCity = (e) => {
    setEthinicity(e)
  }

  const ExternalMRN = (e) => {
    setExternalMrn(e.target.value)
  }

  const getThePatientCategory = (e) => {
    setPatientCategory(e)
  }

  const getTheMarital = (e) => {
    setMaritalStatusId(e)
  }

  const getTheModeofReminder = (e) => {
    setmodeOfReminder(e)
  }

  const getPreferredLang = (e) => {
    setpreferredLanguage(e)
  }

  const getexcludeFromPatientStatement = (e) => {
    setexcludeFromPatientStatement(!excludeFromPatientStatement)
  }

  const getphiexpemtedFromReport = (e) => {
    setphiexpemtedFromReport(!phiexpemtedFromReport)
  }

  const getdeclineRaceEthinicityLanguage = () => {
    setdeclineRaceEthinicityLanguage(!declineRaceEthinicityLanguage)
  }

  const getAddress1 = (e) => {
    setaddressLine1(e)
  }
  const getAddress2 = (e) => {
    setaddressLine2(e)
  }

  const getCity = (e) => {
    setcityId(e)
  }
  const getState = (e) => {
    setstateId(e)
  }

  const getCountry = (e) => {
    setcountryId(e)
  }
  const getZipcode = (e) => {
    setzipcodeId(e)
  }

  const getTheEmail = (e) => {
    setemailAddress(e.target.value)
  }

  const getCellNumber = (e) => {
    setmobileCountryCode(e.gettheCode)
    setmobileNo(e.getTheNumber)
  }

  const getWorkNumber = (e) => {
    setworkCountryCode(e.gettheCode)
    setworkPhoneNo(e.getTheNumber)
  }

  const getHomeNumber = (e) => {
    sethomeCountryCode(e.gettheCode)
    sethomeNo(e.getTheNumber)
  }

  const getExt = (e) => {
    setworkExtNo(e)
  }

  const getFax = (e) => {
    // console.log(e);

    setFaxCountryCode(e.gettheCode)
    setfaxNo(e.getTheNumber)
  }

  const closeCancel = () => {
    setCancelPopUp(false);
  };

  const updatePatient = async (data) => {
    console.log(data)

    try {
      let getloginInfo: string | null = localStorage.getItem('appLoginInfo')

      if (getloginInfo !== null) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const header = {
          headers: {'x-access-token': Token},
        }

        const response = await axios.post(
          `${APIkey}/api/admin/updatePatientById/${id}`,
          data,
          header
        )
        console.log('this is response ', response)

        if (response) {
          setLoading(false)
          props.changedData()
          toast.success('Patient Information Updated ')
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const submitData = (e) => {
    e.preventDefault()

    setLoading(true)

    const data = {
      addressLine1,
      addressLine2,
      alternateName,
      bloodGroupId,
      cityId,
      countryId,
      declineRaceEthinicityLanguage,
      dob,
      emailAddress,
      ethinicityId,
      excludeFromPatientStatement,
      externalMrn,
      faxCountryCode,
      faxNo,
      firstName,
      genderId,
      homeCountryCode,
      homeNo,
      lastName,
      middleName,
      mobileCountryCode,
      mobileNo,
      modeOfReminderId,
      patientCategoryId,
      phiexpemtedFromReport,
      preferredLanguage,
      prefixId,
      raceGroupId,
      raceId,
      religionId,
      ssn,
      stateId,
      suffixId,
      workCountryCode,
      workExtNo,
      workPhoneNo,
      zipcodeId,
      maritalStatusID,
    }

    let err = PatientInfoValidation(data)

    if (Object.keys(err).length === 0) {
      updatePatient(data)
      // console.log("form Submitted ...");

      setErrLastName(err.lastName)
      setErrFirstName(err.firstName)
      setErrMiddleName(err.middleName)
      setErrAlternateName(err.alternateName)
      setErrGender(err.gender)
      setErrDob(err.dob)
      setErrSsn(err.ssn)
      setErrExternal(err.externalId)
      setErrzip(err.zipcodeId)

      setErrEmail(err.emailAddress)
      setErrCellPhone(err.mobileNo)
      setErrHomePhone(err.homeNo)
      setErrFax(err.faxNo)
      setErrWorkPhone(err.workPhoneNo)
      setErrWorkExt(err.workExtNo)
    } else {
      // console.log("formNotSubmitt...");
      setLoading(false)

      setErrLastName(err.lastName)
      setErrFirstName(err.firstName)
      setErrMiddleName(err.middleName)
      setErrAlternateName(err.alternateName)
      setErrGender(err.gender)
      setErrDob(err.dob)
      setErrSsn(err.ssn)
      setErrExternal(err.externalId)
      setErrAddressLine1(err.addressLine1)
      setErrAddressLine2(err.addressLine2)
      setErrcountry(err.countryId)
      setErrState(err.stateId)
      setErrCity(err.cityId)
      setErrzip(err.zipcodeId)

      setErrEmail(err.emailAddress)
      setErrCellPhone(err.mobileNo)
      setErrHomePhone(err.homeNo)
      setErrFax(err.faxNo)
      setErrWorkPhone(err.workPhoneNo)
      setErrWorkExt(err.workExtNo)
    }
    navigate(-1)
  }

  return (
    <>
      <div className='card '>
      <div className='px-4 py-2 mb-3' style={{borderBottom: '1px solid #EFF2F5'}}>
        <TitleComponent type='sectionTitle' className='px-1'>Patient Details</TitleComponent>
        </div>
        {/* 
            <div className='row col-md-12 mb-4' style={{marginLeft:"2px"}}>
              <div className='col-md-2' style={{width: '24%'}}>
                <label className='patient-info-title'>Prefix:</label>
                <select
                className='form-select'
                data-control='select2'
                data-placeholder='Select an option'
              >
                <option value=''>Please Select</option>
                <option value='1'>Option1</option>
                <option value='2'>Option2</option>
                <option value='3'>Option3</option>
              </select>
              </div>

              <div className='col-md-2' style={{width: '24%'}}>
                <label className='patient-info-title required'>Last Name:</label>
                <input type='text' className='form-control textbox-height' />
              </div>
              <div className='col-md-2' style={{width: '24%'}}>
                <label className='patient-info-title required'>First Name:</label>
                <input type='text' className='form-control textbox-height' />
              </div>
              <div className='col-md-2' style={{width: '24%'}}>
                <label className='patient-info-title required'>Middle Name:</label>
                <input type='text' className='form-control textbox-height' />
              </div>
            </div> */}

        <div className='row  px-4'>
          <NameCompoForClinic
            colno='3'
            prefixValue={prefixId}
            lastNameValue={lastName}
            firstNameValue={firstName}
            middleNameValue={middleName}
            suffixValue={suffixId}
            getPrefixData={getprefix}
            getSuffixData={getsuffix}
            getfName={getFirstName}
            getlName={getLastName}
            getmName={getMiddleName}
            lastNameErr={errLastName}
            firstNameErr={errFirstName}
            middleNameErr={errMiddleName}
          />

          <div className={`col-md-6 col-lg-3 `}>
            <div className=' '>
            <TitleComponent type='label' className='px-1'>
                {inputText.alternateName.label}
              </TitleComponent>
              <input
                type='text'
                value={alternateName}
                maxLength={50}
                onChange={getTheAlternateName}
                className='form-control SearchInputbox'
              />
              {errAlternateName && (
                <span className='form-label errMsg'>
                  <i className='fa-solid fa-triangle-exclamation'></i>
                  &nbsp;
                  {errAlternateName}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className='row   p-4'>
          {/* <div className='col-md-3' >
                <label className='patient-info-title'>Suffix:</label>
                <select
                className='form-select'
                data-control='select2'
                data-placeholder='Select an option'
              >
                <option value=''>Please Select</option>
                <option value='1'>Option1</option>
                <option value='2'>Option2</option>
                <option value='3'>Option3</option>
              </select>
              </div>

              <div className='col-md-2' style={{width: '24%'}}>
                <label className='patient-info-title'>Alternate Name:</label>
                <input type='text' className='form-control textbox-height' />
              </div>
              <div className='col-md-2' style={{width: '24%'}}>
                <label className='patient-info-title required'>Sex:</label>
                <select
                className='form-select'
                data-control='select2'
                data-placeholder='Select an option'
              >
                <option value=''>Please Select</option>
                <option value='1'>Male</option>
                <option value='2'>Female</option>
               
              </select>
              </div> */}

          <div className='col-md-6 col-lg-3'>
          <TitleComponent type='label' className='px-1 required'>{inputText.Sex.label}</TitleComponent>
            <GenderComponent getTheGender={getTheSex} genderValue={genderId} />
            {errGender && (
              <span className='form-label errMsg'>
                <i className='fa-solid fa-triangle-exclamation'></i>
                &nbsp;
                {errGender}
              </span>
            )}
          </div>
          <div className='col-md-6  col-lg-3'>
          <TitleComponent type='label' className='px-1 required'>{inputText.dateOfBirth.label}</TitleComponent>
                 {/* <Datepicker 
                      selectedDate={dob || ''}
                      gettingDate={getDob}
                      disabled={false}
                      /> */}
                <input
                    type='date'
                    value={dob || ''}
                    onChange={getDob}
                    className='form-control SearchInputbox' />
                    
            {errDob && (
              <span className='form-label errMsg'>
                <i className='fa-solid fa-triangle-exclamation'></i>
                &nbsp;
                {errDob}
              </span>
            )}
          </div>

          <div className='col-md-6  col-lg-3'>
          <TitleComponent type='label' className='px-1'>Marital Status:</TitleComponent>
            <SerchableDropdown
              InitialValue={maritalStatusID}
              gettingData={getTheMarital}
              options={meritalList}
            />
          </div>
          <div className='col-md-6  col-lg-3'>
          <TitleComponent type='label' className='px-1 required'>{inputText.SSN.label}</TitleComponent>
            <MaskedInput
              mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              className='form-control SearchInputbox'
              onChange={getTheSsn}
              value={ssn || ''}
              placeholder={inputText.SSN.placeholder}
            />
            {/* errSsn */}
            {errSsn && (
              <span className='form-label errMsg'>
                <i className='fa-solid fa-triangle-exclamation'></i>
                &nbsp;
                {errSsn}
              </span>
            )}
          </div>
        </div>

        <div className='row p-4'>
          {/* <div className='col-md-2' style={{width: '24%'}}>
                <label className='patient-info-title'>Marital Status:</label>
                <select
                className='form-select'
                data-control='select2'
                data-placeholder='Select an option'
              >
                <option value=''>Please Select</option>
                <option value='1'>Married</option>
                <option value='2'>UnMarried</option>
               
              </select>
              </div> */}

          {/* <div className='col-md-2' style={{width: '24%'}}>
                <label className='patient-info-title'>SSN:</label>
                <input type='text' className='form-control textbox-height' />
              </div> */}
          {/* <div className='col-md-2' style={{width: '24%'}}>
                <label className='patient-info-title required'>Religion:</label>
                <select
                className='form-select'
                data-control='select2'
                data-placeholder='Select an option'
              >
                <option value=''>Please Select</option>
                <option value='1'>Hindu</option>
                <option value='2'>Muslim</option>
               
              </select>
              </div> */}
          <div className='col-md-6  col-lg-3'>
          <TitleComponent type='label' className='px-1'>Religion:</TitleComponent>
            <SerchableDropdown
              options={religionList}
              gettingData={getTheReligion}
              InitialValue={religionId}
            />
          </div>
          <div className='col-md-6  col-lg-3'>
          <TitleComponent type='label' className='px-1'>Blood Group:</TitleComponent>
            <SerchableDropdown
              options={bloodgroupList}
              gettingData={getBloodGroup}
              InitialValue={bloodGroupId}
            />
          </div>
          <div className='col-md-6  col-lg-3'>
          <TitleComponent type='label' className='px-1'>Race group:</TitleComponent>
            <SerchableDropdown
              InitialValue={raceGroupId}
              gettingData={getTheRaceGroup}
              options={raceGroupList}
            />
          </div>

          <div className='col-md-6  col-lg-3'>
          <TitleComponent type='label' className='px-1'>Race:</TitleComponent>
            <SerchableDropdown InitialValue={raceId} gettingData={getTheRace} options={raceList} />
          </div>
        </div>

        <div className='row p-4 '>
          {/* <div className='col-md-2' style={{width: '24%'}}>
                <label className='patient-info-title'>Race group:</label>
                <select
                className='form-select'
                data-control='select2'
                data-placeholder='Select an option'
              >
                <option value=''>Please Select</option>
                <option value='1'>Option1</option>
                <option value='2'>Option2</option>
               
              </select>
              </div> */}

          {/* <div className='col-md-2' style={{width: '24%'}}>
              <label className='patient-info-title'>Race:</label>
                <select
                className='form-select'
                data-control='select2'
                data-placeholder='Select an option'
              >
                <option value=''>Please Select</option>
                <option value='1'>Option1</option>
                <option value='2'>Option2</option>
               
              </select>
              </div> */}
          {/* <div className='col-md-2' style={{width: '24%'}}>
                <label className='patient-info-title required'>Ethnicity:</label>
                <select
                className='form-select'
                data-control='select2'
                data-placeholder='Select an option'
              >
                <option value=''>Please Select</option>
                <option value='1'>Option1</option>
                <option value='2'>Option2</option>
               
              </select>
              </div> */}

          <div className='col-md-6  col-lg-3'>
          <TitleComponent type='label' className='px-1'>Ethnicity:</TitleComponent>
            <SerchableDropdown
              options={ethiniCityList}
              gettingData={getEthiniCity}
              InitialValue={ethinicityId}
            />
          </div>
          <div className='col-md-6  col-lg-3'>
          <TitleComponent type='label' className='px-1'>External MRN:</TitleComponent>
            <input
              type='text'
              maxLength={50}
              value={externalMrn}
              onChange={ExternalMRN}
              className='SearchInputbox form-control'
            />
            {errExternal && (
              <span className='form-label errMsg'>
                <i className='fa-solid fa-triangle-exclamation'></i>
                &nbsp;
                {errExternal}
              </span>
            )}
          </div>

          <div className='col-md-6  col-lg-3'>
          <TitleComponent type='label' className='px-1'> Patient Category:</TitleComponent>
            <SerchableDropdown
              options={patientCategoryList}
              gettingData={getThePatientCategory}
              InitialValue={patientCategoryId}
            />
          </div>
          <div className='col-md-6  col-lg-3'>
          <TitleComponent type='label' className='px-1'> Mode of reminder:</TitleComponent>
            <SerchableDropdown
              options={modeOfReminderList}
              gettingData={getTheModeofReminder}
              InitialValue={modeOfReminderId}
            />
          </div>
        </div>

        <div className='row  p-4'>
          <div className='col-md-6  col-lg-3'>
          <TitleComponent type='label' className='px-1'> Preferred Language:</TitleComponent>
            <SerchableDropdown
              options={preferredLanguageList}
              gettingData={getPreferredLang}
              InitialValue={preferredLanguage}
            />
          </div>
          {/* <div className='col-md-2' style={{width: '24%'}}>
                <label className='patient-info-title'>Patient Category:</label>
                <select
                className='form-select'
                data-control='select2'
                data-placeholder='Select an option'
              >
                <option value=''>Please Select</option>
                <option value='1'>Option1</option>
                <option value='2'>Option2</option>
               
              </select>
              </div> */}

          {/* <div className='col-md-2' style={{width: '24%'}}>
              <label className='patient-info-title'>Mode of reminder:</label>
                <select
                className='form-select'
                data-control='select2'
                data-placeholder='Select an option'
              >
                <option value=''>Please Select</option>
                <option value='1'>Option1</option>
                <option value='2'>Option2</option>
               
              </select>
              </div> */}
          {/* <div className='col-md-2' style={{width: '24%'}}>
                <label className='patient-info-title required'>Preferred Language:</label>
                <select
                className='form-select'
                data-control='select2'
                data-placeholder='Select an option'
              >
                <option value=''>Please Select</option>
                <option value='1'>Option1</option>
                <option value='2'>Option2</option>
               
              </select>
              </div> */}
        </div>

        <div className='d-flex gap-8 p-4 '>
          <div className=' flex align-items-center '>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-felx gap-2'>
              <input
                className=' '
                type='checkbox'
                // value={excludeFromPatientStatement}
                onChange={getexcludeFromPatientStatement}
                checked={excludeFromPatientStatement}
                id='flexCheckDefault1'
              />
              <label htmlFor='flexCheckDefault1'>
              <TitleComponent type='label'> 
                Exclude From Patient Statement
                </TitleComponent>
              </label>
            </div>
          </div>

          <div className=' flex align-items-center'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-felx gap-2'>
              <input
                className=' '
                type='checkbox'
                // value={phiexpemtedFromReport}
                onChange={getphiexpemtedFromReport}
                checked={phiexpemtedFromReport}
                id='flexCheckDefault2'
              />
              <label htmlFor='flexCheckDefault2'>
              <TitleComponent type='label'> 
                PHI / Exempted from Reports
                </TitleComponent>
              </label>
            </div>
          </div>
          <div className=' flex align-items-center'>
            <div className='form-check form-check-custom form-check-solid form-check-sm d-felx gap-2'>
              <input
                className=''
                type='checkbox'
                // value={declineRaceEthinicityLanguage}
                checked={declineRaceEthinicityLanguage}
                onChange={getdeclineRaceEthinicityLanguage}
                id='flexCheckDefault3'
              />
              <label htmlFor='flexCheckDefault3'>
               <TitleComponent type='label'> Patient declined / unable to provide Race, Ethnicity and / or preferred language </TitleComponent>
              </label>
            </div>
          </div>
        </div>

        {/* <div className='row  mb-4' style={{marginLeft:"2px"}}>
              <div className='col-md-3' >
                <label className='patient-info-title'>Patient Status:</label>
              <SerchableDropdown />
              </div> */}

        {/* </div> */}

        <div className='px-4 py-2 ' style={{borderBottom: '1px solid #EFF2F5'}}>
         <TitleComponent type='sectionTitle'>Contact Details </TitleComponent>
        </div>

        {/* <div className='row' style={{marginLeft:"2px"}}>
              <div className='col-md-2' style={{width: '24%'}}>
                <label className='patient-info-title'>Address 1:</label>
                <input type='text' className='form-control textbox-height' />
              </div>

              <div className='col-md-2' style={{width: '24%'}}>
                <label className='patient-info-title'>Address 2:</label>
                <input type='text' className='form-control textbox-height' />
              </div>
              <div className='col-md-2' style={{width: '24%'}}>
                <label className='patient-info-title required'>City:</label>
                <input type='text' className='form-control textbox-height' />
              </div>
              <div className='col-md-2' style={{width: '24%'}}>
                <label className='patient-info-title required'>State:</label>
                <input type='text' className='form-control textbox-height' />
              </div>
            </div> */}

        <div className='row  p-4'>
          <AddressComponent
            col={'3'}
            zipcodevalue={zipcodeId}
            address1value={addressLine1}
            address2value={addressLine2}
            countryValue={countryId}
            stateValue={stateId}
            cityValue={cityId}
            Address1get={getAddress1}
            Address2get={getAddress2}
            Cityget={getCity}
            StateGet={getState}
            countryget={getCountry}
            zipcodeget={getZipcode}
            address1Err={errAddressLine1}
            address2Err={errAddressLine2}
            cityErr={errCity}
            stateErr={errState}
            countryErr={errcountry}
            zipErr={errzip}
          />
        </div>

        {/* <div className='row  mb-4' style={{marginLeft:"2px"}}> */}
        {/* <div className='col-md-2' style={{width: '24%'}}>
                <label className='patient-info-title'>Country:</label>
                <input type='text' className='form-control textbox-height' />
              </div> */}

        {/* <div className='col-md-2' style={{width: '24%'}}>
                <label className='patient-info-title required'>Zip:</label>
                <input type='text' className='form-control textbox-height' />
              </div> */}
        {/* <div className='col-md-2' style={{width: '24%'}}>
                <label className='patient-info-title'>Email:</label>
                <input type='text' className='form-control textbox-height' />
              </div>
              <div className='col-md-2' style={{width: '24%'}}>
                <label className='patient-info-title'>Cell Phone:</label>
                <input type='text' className='form-control textbox-height' />
              </div>
            </div> */}

        {/* <div className='row  mb-4' style={{marginLeft:"2px"}}>
              <div className='col-md-2' style={{width: '24%'}}>
                <label className='patient-info-title'>Home Phone:</label>
                <input type='text' className='form-control textbox-height' />
              </div>

              <div className='col-md-2' style={{width: '24%'}}>
                <label className='patient-info-title'>Fax:</label>
                <input type='text' className='form-control textbox-height' />
              </div>

              <div className='col-md-2' style={{width: '24%'}}>
              <label className='patient-info-title'>Work Phone:</label>
              <div style={{display: 'flex'}}>
                <input
                  type='text'
                  className='form-control textbox-height'
                  name='workPhone'
                  placeholder=''
                  style={{width: '70%'}}
                />

                <input
                  type='text'
                  className='form-control textbox-height'
                  name='workPhone'
                  placeholder='Ex.'
                  style={{marginLeft: '5px', width: '70px'}}
                />
              </div>
              </div>
              
            </div> */}

        <div className='row mb-4 p-4'>
          <ContactComponent
            col={'3'}
            emailValue={emailAddress}
            contactno1Value={`${mobileCountryCode}${mobileNo}`}
            contactno2Value={`${workCountryCode}${workPhoneNo}`}
            extValue={workExtNo}
            contactno3Value={`${homeCountryCode}${homeNo}`}
            faxValue={`${faxCountryCode}${faxNo}`}
            getTheEmail={getTheEmail}
            getTheCellNumber={getCellNumber}
            getTheWorkNumber={getWorkNumber}
            getTheHomeNumber={getHomeNumber}
            getTheExt={getExt}
            getTheFax={getFax}
            emailErr={errEmail}
            cellNoErr={errCellPhone}
            homeNoErr={errHomePhone}
            faxErr={errFax}
            workNoErr={errWorkPhone}
            extErr={errWorkExt}
            ext = {false}
          />
        </div>

        <div className='row p-4'>
          <div className='col-md-2  d-flex gap-2 justify-content-end' style={{width: '100%'}}>
            {/* <a href="#header"> */}
          <CommonbigButton color='#818181' background='#D7D7D7'
              // onClick={changeTab}
              onClick={() => setCancelPopUp(true)}
            >
              Cancel
            </CommonbigButton>
            {/* </a> */}

            {loading ? (
              <div className='d-flex align-items-center justify-content-center '>
                <div className='loader'></div>
              </div>
            ) : (
              <CommonbigButton  onClick={submitData}>
                Save
              </CommonbigButton >
            )}
          </div>
        </div>
      </div>
      <ToastContainer
      // position="bottom-left"
      />
       {cancelPopUp && (
        <CancelConfirmation openCancel={cancelPopUp} closeCancel={closeCancel} Routes='/patient' />
      )}
    </>
  )
}

export default PatientInformation
