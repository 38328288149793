import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'



const EditProblemList = ({onShow,onHide,editId}) => {
const APIkey = process.env.REACT_APP_SERVERAPI || ''

const [icdCode,setIcdCode] = useState('')
const [icdName,setIcdName] = useState('')
const [notes,setNotes]= useState('')

const handleCloseEditModal = () => {
    setIcdCode('')
    setIcdName('')
    setNotes('')
    onHide()
}

useEffect(()=>{
    const fetchEditData = async()=>{
        try{
            const getLoginInfo = localStorage.getItem('appLoginInfo')
            console.log('hello there: ', getLoginInfo)
            if(getLoginInfo!==null){
                const {data:{accessToken}}=JSON.parse(getLoginInfo)
                const headers = {'x-access-token': accessToken}
                const response = await axios.get(
                    `${APIkey}/api/admin/getPreferredIcd/${editId}`,
                    {headers}
                  )
                  const data = response.data.data
                  setIcdCode(data.icdCode)
                  setIcdName(data.icdName)
                  setNotes(data.notes);

            }else{
                console.log('No login info found in localStorage');
            }
        }catch{
console.error('Error in fetching data');
        }
    }
    if(editId){
        fetchEditData()
    }
},[editId])




const handleUpdate = async()=>{
    try{
        const getLoginInfo: any = localStorage.getItem('appLoginInfo') 
        if(getLoginInfo !== null){
            const Token = JSON.parse(getLoginInfo).data.accessToken 
            const headers = {'x-access-token': Token}

            const response = await axios.post(`${APIkey}/api/admin/updatePreferredIcd/${editId}`,
                {notes},
            {headers})
            console.log('Update Data:',response.data.data)
            handleCloseEditModal()
        }else{
            console.error('No login Info found in localStorage')
        }
    }catch(error){
console.error('Error updating data:',error)
    }
}









  return (
   <>
   <Modal show={onShow} onHide={handleCloseEditModal} size='lg' centered>
    <Modal.Header closeButton className='h-25px dialoge align-items-center pb-3'>
    <div className='PageHeaderTitle'>Edit Problem List</div>
    </Modal.Header>
    <Modal.Body className='p-2 dialoge'>
    <div className='dashboard-card col-md-12 p-2'>
    <div className='row'>
    <div className='col-md-4 mb-3'>
              <div className='addvaccinetitle'>ICD code:</div>
              <input type='text' className='SearchInputbox'  value={icdCode} readOnly disabled/>
            </div>

            <div className='col-md-4 mb-3'>
              <div className='addvaccinetitle'>ICD Name:</div>
              <input type='text' className='SearchInputbox' value={icdName} readOnly disabled />
            </div>

            
            <div className='col-md-4 mb-3'>
              <div className='addvaccinetitle'>Notes:</div>
              <input type='text' className='SearchInputbox' value={notes} onChange={(e)=>setNotes(e.target.value)} />
            </div>
    </div>
    </div>
    <div className='my-2 d-flex justify-content-end gap-3'>
            <button type='button' className='primarybtnBig' onClick={handleUpdate}>Update</button>
            <button type='button' className='tertiarybig' onClick={handleCloseEditModal}>
              Cancel
            </button>
          </div>
    </Modal.Body>
   </Modal>
   </>
  )
}

export default EditProblemList
