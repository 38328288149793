import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'
import CryptoConverter from '../../../../ReUsableComponent/CryptoConverter'
const ViewAllergies = ({onshow,onhide,viewId,}) => {
    const APIkey = process.env.REACT_APP_SERVERAPI
    const [allergyname, setAllergyName] = useState('')//
    const [allergenType, setAllergenType] = useState('')//
    const [severity, setSeverity] = useState('')//
    const [reaction, setReaction] = useState('')//
    const [statusOfAllergy,setStatusOfAllergy]=useState('')//
    const [treatment,setTreatment]=useState('')//
    const [notes, setNotes] = useState('')//
    const [recordedBy,setRecordedBy]=useState('')//
    const [allergyCode, setAllergyCode] = useState('')//



    // const [allergytype, setAllergType] = useState('')
  
    // const [ dateDiagnosed,setDateDiagnosed]=useState('')
    // const [reactionSeverity,setReactionSeverity] = useState('')
    // const [onsetDate,setOnsetDate]=useState('')
    
useEffect(()=>{
    const fetchallergydata =async()=>{
        try{
            const getLoginInfo = localStorage.getItem('appLoginInfo')
            console.log('getLoginInfo: ', getLoginInfo)
    
            if (getLoginInfo !== null) {
                const { data : {accessToken}} = JSON.parse(getLoginInfo) // Corrected property name
      
                const headers = {'x-access-token': accessToken}
      
                const response = await axios.get(
                  `${APIkey}/api/admin/getPreferredAllergyById/${viewId}`,
                  {headers}
                )
                const data = response.data.data
                console.log("allergy", data)


                setAllergyName(data.allergyName)
                setAllergenType(data.allergenType)
                setSeverity(data.severity)
                setReaction(data.reaction)
                setStatusOfAllergy(data.statusOfAllergy)
                setTreatment(data.treatment)
                setNotes(data.notes)
                setRecordedBy(data.recordedBy)
                setAllergyCode(data.allergyCode)
      
              
  
              } else {
                console.error('No login info found in localStorage')
              }

        }catch(error){
            console.log('error in fetching allergy data:', error)
        }
    }

    if(viewId){
        fetchallergydata()
    }
},[viewId])

const handleCloseModal=()=>{
    setAllergyName('')
    setAllergenType(   '')
    setSeverity('')
    setReaction('')
    setStatusOfAllergy('')
    setTreatment('')
    setNotes('')
    setRecordedBy('')
    setAllergyCode('')

    onhide()

}


  return (
   <>
   <Modal show={onshow} onHide={handleCloseModal} size='lg' centered>
   <Modal.Header closeButton className='h-25px dialoge align-items-center pb-3' >

   <div className='PageHeaderTitle'> Allergy Information</div>
    </Modal.Header>
    <Modal.Body className='p-2 dialoge'>
        <div className='dashboard-card'>
            <div className='col-md-12 d-flex'>
            <div className='col-md-3 mb-3'>
                <div className='mb-2'>
             <label className='viewlabels'>Allergy Name:</label>
             <div className='vaccine-label-data'>{allergyname}</div></div>

<div className='mb-2'>
             <label className='viewlabels'> Status:</label>
             <div className='vaccine-label-data'>{statusOfAllergy}</div></div>
           


            <div className='mb-2'>
             <label className='viewlabels'>Recorded By:</label>
             <div className='vaccine-label-data' >{recordedBy}</div>
             </div>
     </div>




     <div className='col-md-3 mb-3'>
                <div className='mb-2'>
             <label className='viewlabels'>Allergy Type:</label>
             <div className='vaccine-label-data'>{allergenType}</div></div>

<div className='mb-2'>
             <label className='viewlabels'> Treatment:</label>
             <div className='vaccine-label-data'>{treatment}</div></div>
           


            <div className='mb-2'>
             <label className='viewlabels'>Allergy Code :</label>
             <div className='vaccine-label-data' >{allergyCode}</div>
             </div>
     </div>

     <div className='col-md-3 mb-3'>
                <div className='mb-2'>
             <label className='viewlabels'>Severity:</label>
             <div className='vaccine-label-data'>{severity}</div></div>

<div className='mb-2'>
             <label className='viewlabels'> Notes:</label>
             <div className='vaccine-label-data'>{notes}</div></div>

     </div>
     <div className='col-md-3 mb-3'>
                <div className='mb-2'>
             <label className='viewlabels'>Reaction:</label>
             <div className='vaccine-label-data'>{reaction}</div></div>


     </div>

            </div>
        </div>
    </Modal.Body>
    <Modal.Footer>   
        <div className='my-2 d-flex justify-content-end gap-3'>
        <button type='button' className='tertiarybig' onClick={handleCloseModal}>
              Close
            </button>
        </div>
        </Modal.Footer>
   </Modal>
   </>
  )
}

export default ViewAllergies
