import React, {useState} from 'react'
import ReactApexChart from 'react-apexcharts'
import {ApexOptions} from 'apexcharts'

const DashboardChart: React.FC = () => {
  const [selectedRange, setSelectedRange] = useState('All')

  const data = {
    All: {
      users: [11, 40, 28, 51, 42, 109, 66, 101, 78, 103, 104, 105],
      clinics: [11, 32, 45, 32, 34, 52, 41, 109, 100, 101, 99, 87],
    },
    '1M': {
      users: [103, 104],
      clinics: [100, 101],
    },
    '3M': {
      users: [78, 103, 104, 105],
      clinics: [100, 101, 99, 87],
    },
    '1Y': {
      users: [66, 101, 78, 103, 104, 105, 99, 99, 99, 99, 99, 98],
      clinics: [41, 109, 100, 101, 99, 87, 93, 88, 45, 80, 90, 100],
    },
  }

  const chartOptions: {series: ApexAxisChartSeries; options: ApexOptions} = {
    series: [
      {
        name: 'Users',
        data: data[selectedRange].users,
      },
      {
        name: 'Clinics',
        data: data[selectedRange].clinics,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ['#1B283F', '#18A0FB'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      xaxis: {
        type: 'category',
        categories: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ],
        labels: {
          format: 'month',
          style: {
            colors: ['black'],
            fontSize: '12px',
          },
        },
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
        y: {
          formatter: (val: number) => `${val.toFixed(0)}`,
        },
      },
      yaxis: {
        labels: {
          formatter: (val: number) => `${val.toFixed(0)}`,
        },
      },
      grid: {
        borderColor: '#e7e7e7',
      },
      markers: {
        size: 0,
        hover: {
          size: 7,
        },
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
      },
    },
  }

  return (
    <div id='chart'>
      <div className='toolbar'>
        <button  className={`toolbaroption ${selectedRange === 'All' ? 'active' : ''}`}
      onClick={() => setSelectedRange('All')}>
          All
        </button>
        <button className={`toolbaroption ${selectedRange === '1M' ? 'active' : ''}`} onClick={() => setSelectedRange('1M')}>
          1M
        </button>
        <button className={`toolbaroption ${selectedRange === '3M' ? 'active' : ''}`} onClick={() => setSelectedRange('3M')}>
          3M
        </button>
        <button className={`toolbaroption ${selectedRange === '1Y' ? 'active' : ''}`} onClick={() => setSelectedRange('1Y')}>
          1Y
        </button>
      </div>
      <ReactApexChart
        series={chartOptions.series}
        options={chartOptions.options}
        type='line'
        height={350}
      />
    </div>
  )
}

export default DashboardChart
