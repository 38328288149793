import React, {useEffect, useState} from 'react'
import plusredbtn from '../../EHRIcon/plusredbtn.svg'
import CommonTable from '../../../../ReUsableComponent/CommonTable'
import axios from 'axios'
// import whiteplus from '../../EHRIcon/whiteplus.svg'
// import Deleteicon from '../../EHRIcon/Deleteicon.svg'
import {useParams} from 'react-router-dom'
// import Vector from '../../EHRIcon/Vector.svg';
import AddProblemList from './AddProblemList';
import DeleteModel from '../../../../ReUsableComponent/DeleteModel';
import EditProblemList from './EditProblemList';
// import Eyebutton from '../../EHRIcon/Eyebutton.svg'
import ViewProblemList from './ViewProblemList';
import tablecolor from '../../../../ReUsableComponent/ReyClinicalinputs.json'


const ProblemList = () => {
  const APIkey = process.env.REACT_APP_SERVERAPI || ''
  // State management
  const [data, setData] = useState([])
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')

  const [showAddModal, setShowAddModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null)

  const [showEditModal,setShowEditModal]=useState(false)
  const [editId,setEditData]=useState(null)
  const [isEditMode, setIsEditMode] = useState(false);

  const [ showViewModal, setShowViewModal ] = useState(false );
  const [viewData,setViewData]=useState('')
  const [viewId,setViewId]=useState(null)





  // Handle add click
  const handleAddClick = () => {
    console.log('Add clicked!')
    setShowAddModal(true)
  }

  const handleRowClick = (row) => {
    console.log('Row clicked:', row)
    // Logic to handle row click
  }

  const columns = [
    {key: ['srNo'], header: 'Sr.No'},
    {key: ['icdCode'], header: 'ICD Code'},

    {key: ['icdName'], header: 'ICD Name'},
    {key: ['notes'], header: 'Notes'},
    {key: ['actions'], header: 'Actions', isAction: true},
  ]

  const fetchProblemListData = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const header = {headers: {'x-access-token': Token}}
        const response = await axios.get(`${APIkey}/api/admin/getPreferredIcdList`, header)
        console.log('Fetched data:', response.data.data)

        const responseData=response.data.data
        console.log(responseData);
const formattedData = responseData.map((item, index) => ({
  
  srNo: index + 1,
  icdCode:item.icdCode,
  icdName:item.icdName,
  notes:item.notes,
  actions:(
    <div className='actionbtn'>
       <button onClick={()=>handleView(item._id)} className='editbtn'>
                  <img  
                  // src={Eyebutton}
                   alt='view'/>
                </button>
      <button className='editbtn ' onClick={()=>handleEdit(item._id)}>
      <img 
      // src={Vector} 
      alt='Edit' />
      </button>
      <button className='editbtn' onClick={()=>openDeleteModal(item._id)}>
                <img 
                // src={Deleteicon} 
                
                alt='Delete'  />
                </button>
               
      
    </div>
  )
}))
setData(formattedData);
      }else{
        console.log('No login info found in localstorage');
      }
    } catch (error) {
      console.error('Error fetching vaccination data:', error)
    }
  }

  useEffect(() => {
    fetchProblemListData()
  }, [showAddModal, showEditModal, showDeleteModal])

const handleCloseModal = ()=>{
  setShowAddModal(false);
  setShowDeleteModal(false);
  setShowEditModal(false);
  setShowViewModal(false);
}


const openDeleteModal=(id)=>{
  setDeleteId(id);
  setShowDeleteModal(true);
}


const handleView = (_id) => {
  setViewData(_id)
  setViewId(_id)
  setShowViewModal(true)

}




  const handleDelete = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const header = {headers: {'x-access-token': Token}}

        await axios.delete(`${APIkey}/api/admin/deletePreferredIcd/${deleteId}`, header)
        console.log('Row deleted successfully')

        fetchProblemListData()
        setShowDeleteModal(false) // Close the modal after successful deletion
      } else {
        console.log('No login info found in localStorage')
      }
    } catch (error) {
      console.error('Error deleting Problem List:', error)
    }
  }

  const handleEdit = (_id) => {
    setEditData(_id)
    setShowEditModal(true)
    setIsEditMode(true)
  }

  //searchCriteria
  const [searchCriteria, setSearchCriteria] = useState({
    icdCode: '',
    icdName: '',
  })

  const handleSearchCriteriaChange = (e) => {
    const {name, value} = e.target
    setSearchCriteria((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSearch = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        console.log('token', Token)

        const header = {
          headers: {'x-access-token': Token},
        }

        const response = await axios.post(
          `${APIkey}/api/admin/searchPreferredIcd`,
          searchCriteria,
          header
        )
        const responseData = response.data.data
        const searchData = responseData.map((item, index) => ({
          srNo: index + 1,
          icdCode: item.icdCode,
          icdName: item.icdName,
          actions: (
            <div className='actionbtn'>
              <button onClick={() => handleView(item._id)} className='editbtn'>
                <img 
                // src={Eyebutton} 
                alt='view' />
              </button>
              <button onClick={() => handleEdit(item._id)} className='editbtn'>
                <img 
                // src={Vector} 
                alt='Edit' />
              </button>
              <button onClick={() => openDeleteModal(item._id)} className='editbtn'>
                <img 
                // src={Deleteicon} 
                alt='Delete' />
              </button>
            </div>
          ),
  
        }))
        setData(searchData)
      }
    } catch (error) {
      console.log('Error fetching data')
    }
  }
  const handleClearClick = ()=>{
    setSearchCriteria({
      icdCode: '',
      icdName: '',
    })
    fetchProblemListData()
  }

  return (
    <>
      <div className='clinical-time-header'>
        <div className='titletextformat'>
          <div className='PageHeaderTitle'>Problem List</div>
          <div>
            {' '}
            <button className='add-Vaccine-btn' onClick={handleAddClick}>
              <img 
              // src={whiteplus} 
              alt='Add Vaccine' /> Add Problem List
            </button>
          </div>
        </div>
      </div>

      <div className='d-flex gap-2  mt-3'>
        <div className='col-md-2'>
          <input
            type='text'
            className='SearchInputbox'
            placeholder='ICD Code'
            name='icdCode'
            value={searchCriteria.icdCode}
            onChange={handleSearchCriteriaChange}
          />
        </div>
        <div className=''>
          <input
            type='text'
            className='SearchInputbox'
            placeholder='ICD Name'
            name='icdName'
            value={searchCriteria.icdName}
            onChange={handleSearchCriteriaChange}
          />
        </div>

        <div className='gap-1 d-flex'>
          <button type='button' className='AddBtn' onClick={handleSearch}>
            Search
          </button>
          <button type='button' className='grayButton' onClick={handleClearClick} >
            Clear
          </button>
        </div>
      </div>
      <br />
      <div className='clinical-time-container'>
        <CommonTable
          data={data}
          totalCount={data.length}
          perPage={perPage}
          setPerPage={setPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          columns={columns}
          searchQuery={searchQuery}
          setQuery={setSearchQuery}
          onRowClick={handleRowClick}
          issetPaginationCompo={true}
          headerColor={tablecolor.primaryTable.color}
          isPagelength={false}
          isSearched={false}
        />
      </div>

      <DeleteModel
        openDelete={showDeleteModal}
        closedelete={handleCloseModal}
        deletedEntity={handleDelete}
        deleteID={deleteId}
        deleteFunction={handleDelete}
      />

        <AddProblemList onShow={showAddModal} onHide={handleCloseModal}/>
        <EditProblemList onShow={showEditModal} onHide={handleCloseModal} editId={editId}/>
        <ViewProblemList onshow={showViewModal} onhide={handleCloseModal} viewId={viewId}/>
    </>
  )
}

export default ProblemList
