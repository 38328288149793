import React, {useEffect, useState} from 'react';
import inputText from './ReyClinicalinputs.json';
// import SearchableDropdown from '../SuperAdmin/Pages/ReausableComponent/SearchableDropdown';
import SearchableDropdown from '../app/pages/ReusablePages/ReusableAppointment/SearchableDropdown';
import axios from 'axios';
import TitleComponent from './TitleComponent';

function AddressComponent(
  // { Address1get,
  //     Address2get,
  //     Cityget,
  //     StateGet,
  //     countryget,
  //     zipcodeget,
  //     address1value,
  //     address2value,
  //     countryValue,
  //     stateValue,
  //     cityValue,
  //     zipcodevalue,
  //     address1Err,
  //     address2Err,
  //     cityErr,
  //     stateErr,
  //     countryErr,
  //     zipErr,
  //     col
  // }
  props
) {
  const APIkey = process.env.REACT_APP_SERVERAPI;

  const [contryOption, setCountryOption] = useState([]);
  const [stateOPtion, setStateOption] = useState([]);
  const [cityOption, setCityOption] = useState([]);

  useEffect(() => {
    const getTheCountry = async () => {
      try {
        const response = await axios.get(`${APIkey}/api/admin/getCountryList`);
        setCountryOption(
          response?.data?.data?.map((i) => ({
            label: i?.name,
            value: i?.name,
            id: i?.id,
          }))
        );

        // const countryRes = await axios.get(`${APIkey}/api/admin/getStateList/${countryid}`);
        // setStateOption(countryRes.data.data.map((i) => ({
        //     label: i.name,
        //     value: i.name,
        //     id: i.id
        // })));

        // const cityRes = await axios.get(`${APIkey}/api/admin/getCityList/${stateId}`);

        // setCityOption(cityRes.data.data.map((i) => ({
        //     label: i.name,
        //     value: i.name,
        //     id: i.id
        // })));
      } catch (err) {
        console.log(err);
      }
    };
    getTheCountry();

    if (props.countryValue && props.stateValue) {
      const getThecityState = async (countryid, stateId) => {
        try {
          const response = await axios.get(`${APIkey}/api/admin/getCountryList`);
          setCountryOption(
            response.data.data.map((i) => ({
              label: i.name,
              value: i.name,
              id: i.id,
            }))
          );

          const countryRes = await axios.get(`${APIkey}/api/admin/getStateList/${countryid}`);
          setStateOption(
            countryRes.data.data.map((i) => ({
              label: i.name,
              value: i.name,
              id: i.id,
            }))
          );

          const cityRes = await axios.get(`${APIkey}/api/admin/getCityList/${stateId}`);

          setCityOption(
            cityRes.data.data.map((i) => ({
              label: i.name,
              value: i.name,
              id: i.id,
            }))
          );
        } catch (err) {
          console.log(err);
        }
      };
      getThecityState(props.countryValue, props.stateValue);
    }
  }, [props.countryValue, props.stateValue]);

  const getAddress1 = (e) => {
    props.Address1get(e.target.value);
  };
  const getAddress2 = (e) => {
    props.Address2get(e.target.value);
  };

  const getCountry = async (e) => {
    props.countryget(e);

    try {
      const response = await axios.get(`${APIkey}/api/admin/getStateList/${e}`);

      setStateOption(
        response.data.data.map((i) => ({
          label: i.name,
          value: i.name,
          id: i.id,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };
  const getState = async (e) => {
    props.StateGet(e);

    try {
      const response = await axios.get(`${APIkey}/api/admin/getCityList/${e}`);

      setCityOption(
        response.data.data.map((i) => ({
          label: i.name,
          value: i.name,
          id: i.id,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getCity = (e) => {
    props.Cityget(e);
  };
  const getZip = (e) => {
    const maxLength = 6; // Maximum number of digits allowed
    let value = e.target.value; // Get the value entered by the user

    // If the length of the value exceeds the maximum length, trim it
    if (value.length > maxLength) {
      value = value.slice(0, maxLength);
    }

    // Pass the trimmed value to the parent component using props
    props.zipcodeget(value);
  };

  return (
    <>
      <div className={`col-md-6  col-lg-${props.col} mb-4`}>
        <TitleComponent type='label' className="px-1 required">{inputText.Address1.label}</TitleComponent>
        <input
          type='text'
          value={props.address1value}
          className='form-control SearchInputbox'
          maxLength={100}
          onChange={getAddress1}
        />
        {props.address1Err && (
          <span className='form-label errMsg'>
            <i className='fa-solid fa-triangle-exclamation'></i>
            &nbsp;
            {/* {inputText.Address1.ErrMsg} */}
            {props.address1Err}
          </span>
        )}
      </div>
      <div className={`col-md-6  col-lg-${props.col} mb-4`}>
        <TitleComponent type='label' className="px-1">{inputText.Address2.label}</TitleComponent>
        <input
          type='text'
          value={props.address2value}
          className='form-control SearchInputbox'
          maxLength={100}
          onChange={getAddress2}
        />
        {props.address2Err && (
          <span className='form-label errMsg'>
            <i className='fa-solid fa-triangle-exclamation'></i>
            &nbsp;
            {/* {inputText.Address1.ErrMsg} */}
            {props.address2Err}
          </span>
        )}
      </div>
      <div className={`col-md-6  col-lg-${props.col} mb-4`}>
        <TitleComponent type='label' className="px-1 required">{inputText.country.label}</TitleComponent>
        <SearchableDropdown
          options={contryOption}
          InitialValue={props.countryValue}
          gettingData={getCountry}
        />
        {props.countryErr && (
          <span className='form-label errMsg'>
            <i className='fa-solid fa-triangle-exclamation'></i>
            &nbsp;
            {inputText.country.ErrMsg}
          </span>
        )}
      </div>

      <div className={`col-md-6  col-lg-${props.col} mb-4`}>
        <TitleComponent type='label' className="px-1 required">{inputText.state.label}</TitleComponent>
        <SearchableDropdown
          options={stateOPtion}
          InitialValue={props.stateValue}
          gettingData={getState}
        />
        {props.stateErr && (
          <span className='form-label errMsg'>
            <i className='fa-solid fa-triangle-exclamation'></i>
            &nbsp;
            {inputText.state.ErrMsg}
          </span>
        )}
      </div>
      <div className={`col-md-6  col-lg-${props.col} mb-4`}>
        <TitleComponent type='label' className="px-1 required">{inputText.city.label}</TitleComponent>
        <SearchableDropdown
          options={cityOption}
          InitialValue={props.cityValue}
          gettingData={getCity}
        />
        {props.cityErr && (
          <span className='form-label errMsg'>
            <i className='fa-solid fa-triangle-exclamation'></i>
            &nbsp;
            {inputText.city.ErrMsg}
          </span>
        )}
      </div>
      <div className={`col-md-6  col-lg-${props.col} mb-4`}>
        <TitleComponent type='label' className="px-1 required">
          {inputText.zip.label}
        </TitleComponent>
        <input
          type='number'
          value={props.zipcodevalue}
          onChange={getZip}
          className='form-control SearchInputbox'
        />
        {props.zipErr && (
          <span className='form-label errMsg'>
            <i className='fa-solid fa-triangle-exclamation'></i>
            &nbsp;
            {/* {inputText.zip.ErrMsg} */}
            {props.zipErr}
          </span>
        )}
      </div>
    </>
    /* <div className='row mt-2'>
                <div className='col-md-6  col-lg-4'>
                    <label htmlFor="" className='form-label required'>{inputText.state.label}</label>
                    <SearchableDropdown
                        options={stateOPtion}
                        InitialValue={stateValue}
                        gettingData={getState} />
                    {stateErr && (
                        <span className='form-label errMsg'>
                               <i className="fa-solid fa-triangle-exclamation"></i> 
                      &nbsp;
                            {inputText.state.ErrMsg}
                        </span>
                    )}
                </div>
                <div className='col-md-6  col-lg-4'>
                    <label htmlFor="" className='form-label required'>{inputText.city.label}</label>
                    <SearchableDropdown
                     options={cityOption}
                     InitialValue={cityValue}
                     gettingData={getCity} />
                    {cityErr && (
                        <span className='form-label errMsg'>
                               <i className="fa-solid fa-triangle-exclamation"></i> 
                      &nbsp;
                            {inputText.city.ErrMsg}
                        </span>
                    )}
                </div>
                <div className='col-md-6  col-lg-4'>
                    <label htmlFor="" className='form-label required'>{inputText.zip.label}</label>
                    <input type="text"
                        value={zipcodevalue}
                        onChange={getZip} className='form-control SearchInputbox' />
                    {
                        zipErr && (
                            <span className='form-label errMsg'>
                                   <i className="fa-solid fa-triangle-exclamation"></i> 
                      &nbsp;
                                {/* {inputText.zip.ErrMsg} */
    /* {zipErr}
                            </span> */
    /* )
                    } */
    /* </div> */

    /* </div>
     */

    // </div>
  );
}

export default AddressComponent;
