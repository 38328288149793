import React, { useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { Link, useNavigate } from 'react-router-dom';

import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';

import CryptoJS from 'crypto-js';

import inputText from "../../../ReUsableComponent/ReyClinicalinputs.json"

import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import ReactPaginate from 'react-paginate'
import 'font-awesome/css/font-awesome.min.css' // You may need to adjust the path


import SearchableDropdown from '../ReusablePages/ReusableAppointment/SearchableDropdown';
import SearchableGroup from '../../../ReUsableComponent/SearchableGroup';
import MultiSelectDropdown from '../ReusablePages/ReusableAppointment/MultiSelectDropdown';
import AppointmentSearch from './AppointmentSearch';
import AddInsurance from './AddInsurance';
import PatientSearchInApp from './PatientSearchInApp';
import axios from 'axios';
import takeVitals from '../EHRIcon/takeVitals.svg'
import patientChart from '../EHRIcon/patientChart.svg'
import patientDemo from '../EHRIcon/patientdemo.svg'
import clinicalNotes from '../EHRIcon/clinicalNotes.svg'
import vitalsHistory from '../EHRIcon/vitalsHistory.svg'
import vitalsDetail from '../EHRIcon/vitalsDetail.svg'
import statusType from '../EHRIcon/statusType.svg'
// import visitType from '../EHRIcon/VisitType.svg'
// import changeDuration from '../EHRIcon/changeDuration.svg'
// import eligibilitySEarviceType from '../EHRIcon/EligibilityServiceTYpe.svg'
// import cancelAppointment from '../EHRIcon/CancelAppointment.svg'
// import Todo from '../EHRIcon/TODO.svg'
import stickyNotes from '../EHRIcon/StickyNotes.svg'
import examprep from '../EHRIcon/ExamPrep.svg'
// import comments from '../EHRIcon/Comments.svg'
import externalAppointment from '../EHRIcon/ExternalAppointment(s).svg'
import EditAppointmentIcon from '../EHRIcon/EditAppointmentIcon.svg'
import sideDown from '../EHRIcon/gridicons_dropdown.svg'
// import Medication from '../EHRIcon/Medication.svg'
import EditAppointment from './EditAppointment';
import { ToastContainer } from 'react-toastify';
import Datepicker from '../../../ReUsableComponent/Datepicker';
import ErrorMsgShow from '../../../ReUsableComponent/ErrorMsgShow';


// import reactSelect from 'react-select';


// import { FaPlus, FaMinus } from 'react-icons/fa';
const localizer = momentLocalizer(moment)

export function AppointmentMenu() {

  interface clinicNameList {
    value: any;
    label: any;
    id: any;

  }




  const APIkey = process.env.REACT_APP_SERVERAPI;


  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)


  const [patientDatabyId, setPatientDataById] = useState<Object | null>();
  const [patientId, setPatientId] = useState('')
  const [mrn, setMrn] = useState('')
  const [lastName, setLastName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [ssn, setSsn] = useState('')
  const [dob, setDob] = useState('')
  const [Countrycode1, setCountrycode1] = useState('')
  const [mobileno, setmobileno] = useState('')
  const [Countrycode2, setCountrycode2] = useState('')
  const [homeNo, setHomeno] = useState('')


  const [showPopup, setShowPopup] = useState(false)
  const [editAppointmentPopup, setEditAppointmentPopup] = useState(false)
  const [showHealthPlanClickPopup, setShowHealthPlanClickPopup] = useState(false)
  const [showVisitReasonClickPopup, setShowVisitReasonClickPopup] = useState(false)



  const [patientInsuranceList, setPatientInsuranceList] = useState([])
  const [appointmentModeData, setAppointmentModeData] = useState([])
  const [appointmentTypeData, setAppointmentTypeData] = useState([])
  const [visitReasonData, setVisitReasonData] = useState([])
  const [resourceData, setResourceData] = useState([])
  const [clinicNameList, setClinicNameList] = useState<clinicNameList[]>([])
  const [withList, setwithList] = useState([])

  const [events, setEvents] = useState([])
  console.log("events", events)

  const [patientAppointmentList, setPatientAppointmentList] = useState([])


  const options = [
    // "Option 1" , 
    // "Option 2" , 
    // "Option 3" , 
    // "Option 4" , 
  ]



  // For Date Label 


  //all Variable Appointment Info starts here ... 
  const [patientInsuranceId, setPatientInsuranceId] = useState<any>(undefined)
  const [userId, setUserId] = useState<string | undefined | null>()
  const [appointmentMode, setAppointmentMode] = useState<string | undefined | null>(undefined)
  const [appointmentType, setAppointmentType] = useState<string | undefined | null>(undefined)
  const [clinicName, setClinicName] = useState<string | undefined | null>(undefined)
  const [designationId, setdesignation] = useState<string | undefined | null>(undefined)
  const [getDay, setGetDay] = useState<string | undefined | null>(undefined);
  const [appointmentDate, setVisitDate] = useState<any>();
  const [appointmentTime, setVisitTime] = useState<string | undefined | null>(undefined);
  const [repeat, setRepeat] = useState<Number | undefined | null>(undefined);
  const [daysBetweenVisits, setDaysBetweenVisits] = useState<Number | undefined | null>(undefined);
  const [reasonForVisit, setVisitReason] = useState<String | undefined | null>(undefined);
  const [visitLength, setVisitLength] = useState('')
  const [resourceId, setResource] = useState<String | undefined | null>(undefined);
  const [colorCode, setColordiv] = useState('')
  const [notes, setNotes] = useState<any>();


  const [confirmAppointmentPop, setconfirmAppointmentPop] = useState<boolean | undefined>(false)

  const [editAppointmentId, setEditAppointmentId] = useState<string>('')
  const [editappPatientId, setEditappPatientId] = useState<string>('')

  //All error Here ... 
  const [errMRN, setErrMRN] = useState<any>()
  const [errAppointMentMode, setErrAppointMentMode] = useState<any>()
  const [errAppointMentType, setErrAppointMentType] = useState<any>()
  const [errClinicName, setErrClinicName] = useState<any>()
  const [errDesignation, setErrDesignation] = useState<any>()
  const [errAppointmentDate, setErrAppointmentDate] = useState<any>()
  const [errAppointmentTime, setErrAppointmentTime] = useState<any>()
  const [errPatientInsuranceId, setErrPatientInsuranceId] = useState(false)
  const [errvisitReason, setErrvisitReason] = useState(false)



  const [showSubMenuList, setShowSubMenuList] = useState<any>(false);




  // console.log("this is UserId  ..." +  userId + " this is designation Id " , designationId);





  // const events = [



  //   {
  //     title: 'Brown, Linda K. Dr. Emily Roberts',
  //     start: new Date(),
  //     end: new Date(),
  //   },

  //   // Add more events here
  // ]








  // const CustomEvent = ({ event }) => (
  //   <div>
  //     <div>{event.title.split('Dr.')[0]}</div>
  //     <div>{event.title.split('Dr.')[1]}</div>
  //   </div>
  // );
  const CustomEvent = ({ event }) => {
    const part1 = event.title // Split by 'Dr.' using a regular expression
    const part2 = event.with
    const part3 = event.provider



    return (
      <div style={{}}>

        <li style={{ fontSize: "12px" }}>{part1}</li>
        <span>{part3}({part2})</span>
      </div>
    )
  }

  //const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState<any>(null)
  const [showModal, setShowModal] = useState(false)

  const handleEventSelect = (event) => {
    setSelectedEvent(event)
    setShowModal(true)
  }

  const handleCloseModal = () => {

    setShowModal(false)
    setShowSubMenuList('')
  }

  const [showAddNewPatientClickPopup, setShowAddNewPatientClickPopup] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchPatientQuery, setSearchPatientQuery] = useState('')
  const [searchHealthPlanQuery, setSearchHealthPlanQuery] = useState('')

  const [rows, setRows] = useState([{ lastName: '', startWith: '' }])
  const [rowsHelthPlan, setRowsHelthPlan] = useState([{ payerID: '', startWith: '' }])

  const [showTable, setShowTable] = useState(false)
  const [showTableHealthPlan, setShowTableHealthPlan] = useState(false)
  // const [showTableVisitReason, setShowTableVisitReason] = useState(false)

  const [createAppointmentStatus, setCreateAppointmentStatus] = useState(false)



  //switching page ... 
  const [showListPage, setShowListPage] = useState(true)




  const [patientData, setPatientData] = useState([
    {
      id: 1,
      lastName: 'Doe',
      firstName: 'John',
      phone: '(555) 123-4567',
      dateOfBirth: '03/12/1990',
      sex: 'Male',
      primaryInsurance: 'PI12345',
    },
    {
      id: 2,
      lastName: 'Smith',
      firstName: 'Alice',
      phone: '(555) 123-4567',
      dateOfBirth: '03/11/1990',
      sex: 'Male',
      primaryInsurance: 'PI22345',
    },
    {
      id: 3,
      lastName: 'Peter',
      firstName: 'Parker',
      phone: '(555) 123-4567',
      dateOfBirth: '01/12/1996',
      sex: 'Male',
      primaryInsurance: 'PI22345',
    },
    {
      id: 4,
      lastName: 'Mitchell',
      firstName: 'Marsh',
      phone: '(555) 123-4567',
      dateOfBirth: '01/08/1995',
      sex: 'Male',
      primaryInsurance: 'PI22345',
    },
    {
      id: 5,
      lastName: 'Roy',
      firstName: 'Peter',
      phone: '(555) 123-4567',
      dateOfBirth: '01/08/1995',
      sex: 'Male',
      primaryInsurance: 'PI22345',
    },
    {
      id: 6,
      lastName: 'Kagiso',
      firstName: 'Rabada',
      phone: '(555) 123-4567',
      dateOfBirth: '01/08/1995',
      sex: 'Male',
      primaryInsurance: 'PI22345',
    },
    {
      id: 7,
      lastName: 'David',
      firstName: 'Malan',
      phone: '(555) 123-4567',
      dateOfBirth: '01/08/1995',
      sex: 'Male',
      primaryInsurance: 'PI22345',
    },
    {
      id: 8,
      lastName: 'Josh',
      firstName: 'Buttler',
      phone: '(555) 123-4567',
      dateOfBirth: '01/08/1995',
      sex: 'Male',
      primaryInsurance: 'PI22345',
    },
    {
      id: 9,
      lastName: 'Smith',
      firstName: 'Steve',
      phone: '(555) 123-4567',
      dateOfBirth: '01/08/1995',
      sex: 'Male',
      primaryInsurance: 'PI22345',
    },
    {
      id: 10,
      lastName: 'Smith',
      firstName: 'Parker',
      phone: '(555) 123-4567',
      dateOfBirth: '01/08/1995',
      sex: 'Male',
      primaryInsurance: 'PI22345',
    },
    {
      id: 11,
      lastName: 'David',
      firstName: 'Joy',
      phone: '(555) 123-4567',
      dateOfBirth: '01/08/1995',
      sex: 'Male',
      primaryInsurance: 'PI22345',
    },
  ])

  const [payerData, setPayerData] = useState([
    {
      id: '',
      payerID: '1246',
      payerName: 'HealthShield Insurance',
    },
    {
      id: '',
      payerID: '1246',
      payerName: 'MediCare Plus',
    },
    {
      id: '',
      payerID: '1246',
      payerName: 'HealthShield Insurance',
    },
    {
      id: '',
      payerID: '1246',
      payerName: 'MediCare Plus',
    },
    {
      id: '',
      payerID: '1246',
      payerName: 'HealthShield Insurance',
    },
    {
      id: '',
      payerID: '1246',
      payerName: 'MediCare Plus',
    },
    {
      id: '',
      payerID: '1246',
      payerName: 'HealthShield Insurance',
    },
    {
      id: '',
      payerID: '1246',
      payerName: 'MediCare Plus',
    },
    {
      id: '',
      payerID: '1246',
      payerName: 'HealthShield Insurance',
    },
    {
      id: '',
      payerID: '1246',
      payerName: 'MediCare Plus',
    },
    {
      id: '',
      payerID: '1246',
      payerName: 'HealthShield Insurance',
    },
    {
      id: '',
      payerID: '1246',
      payerName: 'MediCare Plus',
    },
    {
      id: '',
      payerID: '1246',
      payerName: 'HealthShield Insurance',
    },
    {
      id: '',
      payerID: '1246',
      payerName: 'MediCare Plus',
    },
    {
      id: '',
      payerID: '1246',
      payerName: 'HealthShield Insurance',
    },
    {
      id: '',
      payerID: '1246',
      payerName: 'MediCare Plus',
    },
    {
      id: '',
      payerID: '1246',
      payerName: 'HealthShield Insurance',
    },
    {
      id: '',
      payerID: '1246',
      payerName: 'MediCare Plus',
    },
  ])





  const handleSearchClick = () => {
    setShowPopup(true)
  }
  //Edit Appointment Pop up 
  const hadleEditAppointment = (AppointMentId, patientId): void => {
    setSelectedEvent(false)
    setEditAppointmentPopup(true)

    setEditAppointmentId(AppointMentId)
    setEditappPatientId(patientId)
  }

  // console.log(editAppointmentId);


  //healthClickSearchClick
  const healthClickSearchClick = () => {
    setShowHealthPlanClickPopup(true)
  }

  const visitReasonClickSearch = () => {
    setShowVisitReasonClickPopup(true)
  }

  const openAddNewPatient = () => {
    setShowPopup(false)
    setShowAddNewPatientClickPopup(true)
  }

  const handleClosePopup = () => {
    setShowPopup(false)
    setEditAppointmentPopup(false)
  }

  const handleCloseHealthPlanPopup = () => {
    setShowHealthPlanClickPopup(false)
  }

  const handleCloseVisitResonPopup = () => {
    setShowVisitReasonClickPopup(false)
  }

  const handleCloseAddNewPatientPopup = () => {
    setShowAddNewPatientClickPopup(false)
  }

  const handleAddRow = () => {
    setRows([...rows, { lastName: '', startWith: '' }])
  }

  const handleAddHealthPlanRow = () => {
    setRowsHelthPlan([...rowsHelthPlan, { payerID: '', startWith: '' }])
  }

  const handleDeleteRow = (index) => {
    const updatedRows = [...rows]
    updatedRows.splice(index, 1)
    setRows(updatedRows)
  }



  const [fields, setfields] = useState()

  const handleDeleteRowHealthPlan = (index) => {
    const updatedRowsHelthPlan = [...rowsHelthPlan]
    updatedRowsHelthPlan.splice(index, 1)
    setRowsHelthPlan(updatedRowsHelthPlan)
  }

  // Pagination settings
  const patientsPerPage = 10 // Number of patients per page
  const [currentPage, setCurrentPage] = useState(0)

  const payerPerPage = 10
  const visitReasonPerPage = 5

  // Function to handle page change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected)
  }

  // const indexOfLastPatient = (currentPage + 1) * patientsPerPage
  // const indexOfFirstPatient = indexOfLastPatient - patientsPerPage

  const filteredPatientData = patientData.filter(
    (patients) =>
      patients.lastName.toLowerCase().includes(searchPatientQuery.toLowerCase()) ||
      patients.firstName.toLowerCase().includes(searchPatientQuery.toLowerCase()) ||
      patients.phone.toLowerCase().includes(searchPatientQuery.toLowerCase()) ||
      patients.dateOfBirth.toLowerCase().includes(searchPatientQuery.toLowerCase()) ||
      patients.sex.toLowerCase().includes(searchPatientQuery.toLowerCase()) ||
      patients.primaryInsurance.toLowerCase().includes(searchPatientQuery.toLowerCase())
  )

  const indexOfLastPatient = (currentPage + 1) * patientsPerPage
  const indexOfFirstPatient = indexOfLastPatient - patientsPerPage
  const currentPatientData = filteredPatientData.slice(indexOfFirstPatient, indexOfLastPatient)

  //Helth Plan Start

  const filteredHealthPlanData = payerData.filter(
    (payer) =>
      payer.payerID.toLowerCase().includes(searchHealthPlanQuery.toLowerCase()) ||
      payer.payerName.toLowerCase().includes(searchHealthPlanQuery.toLowerCase())
  )

  const indexOfLastPayer = (currentPage + 1) * payerPerPage
  const indexOfFirstPayer = indexOfLastPayer - payerPerPage
  const currentPayer = filteredHealthPlanData.slice(indexOfFirstPayer, indexOfLastPayer)

  //Helth Plan End

  // const indexOfLastPayer = (currentPage + 1) * payerPerPage
  // const indexOfFirstPayer = indexOfLastPayer - payerPerPage

  // const indexOfLastVisitReson = (currentPage + 1) * visitReasonPerPage
  // const indexOfFirstVisitReson = indexOfLastVisitReson - visitReasonPerPage

  //const currentPatients = patientData.slice(indexOfFirstPatient, indexOfLastPatient)
  // const currentPayer = payerData.slice(indexOfFirstPayer, indexOfLastPayer)

  // const filteredVisitReason = visitReasonData.filter((visitReason) =>
  //   visitReason.reasonForVisit.toLowerCase().includes(searchQuery.toLowerCase())
  // )

  // const indexOfLastVisitReason = (currentPage + 1) * visitReasonPerPage
  // const indexOfFirstVisitReason = indexOfLastVisitReason - visitReasonPerPage
  // const currentVisitReason = filteredVisitReason.slice(
  //   indexOfFirstVisitReason,
  //   indexOfLastVisitReason
  // )
  //const currentVisitReason = visitReasonData.slice(indexOfFirstVisitReson, indexOfLastVisitReson)

  const handleShowTable = () => {
    setShowTable(true)
  }

  const handleShowTableHealthPlan = () => {
    setShowTableHealthPlan(true)
  }

  // const handleShowTableVisitReason = () => {
  //   setShowTableVisitReason(true)
  // }

  // getting data from searchable DropDown


  useEffect(() => {

    if (patientId) {
      const currentTime = new Date();
      // console.log("after 1 hour time :" , currentTime);
      const oneHourLater = new Date(currentTime.getTime() + 60 * 60 * 1000);
      const hours = oneHourLater.getHours().toString().padStart(2, '0');
      const minutes = oneHourLater.getMinutes().toString().padStart(2, '0');
      const newTime = `${hours}:${minutes}`;
      setVisitTime(newTime);
    }

  }, [patientId])




  const [officeFilter, setOfficerFilter] = useState([])


  const gettingOfficeFilter = (data) => {
    setOfficerFilter(data);
  }


  const gettingProviderFilter = (data) => {

    console.log(data);

  }







  const getThePatient = (databyId) => {

    console.log("databyId",databyId);
    

    const decrypted = (inputText) => inputText ?  CryptoJS.AES.decrypt(inputText, 'reyEhr').toString(CryptoJS.enc.Utf8) : '';

    // console.log("decrypted ssn : ", decrypted(databyId?.ssn));


    setPatientId(databyId?._id)

    setPatientDataById(databyId);
    setMrn(databyId?.mrn)
    setLastName(databyId?.name.split(' ')[1] || '');
    setFirstName(databyId?.name.split(' ')[0] || '')
    setSsn(decrypted(databyId?.ssn))

    let Date = moment(databyId?.dob).format('YYYY-MM-DD');

    setDob(Date);

    setCountrycode1(databyId?.mobileNo.split(' ')[0] || '');
    setmobileno(databyId?.mobileNo.split(' ')[1] || '');


    setCountrycode2(databyId?.home.split(' ')[0] || '');
    setHomeno(databyId?.home.split(' ')[1] || '')

  }






  const getAllfieldList = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo');
  
      if (getloginInfo !== null) {
        const loginInfo = JSON.parse(getloginInfo);
        const Token = loginInfo.data.accessToken;
        const Id = loginInfo.data.clinicId;
        const header = {
          headers: { 'x-access-token': Token }
        };
  
        await fetchAppointmentModeList(Id, header);
        await fetchAppointmentTypeList(Id, header);
        await fetchVisitReasonList(Id, header);
        await fetchResourceList(Id, header);
        await fetchClinicNameList(Id, header);
        await fetchDesignationAndUserList(Id, header);
  
        if (patientId) {
          await fetchPatientInsuranceList(patientId, header);
        }
  
        await fetchPatientAppointmentList(header);
      } else {
        console.log("No login info found in localStorage");
      }
    } catch (err) {
      console.log(err);
    }
  };
  
  const fetchAppointmentModeList = async (Id, header) => {
    try {
      const response = await axios.get(`${APIkey}/api/admin/getAppointmentModeList`, header);
      setAppointmentModeData(response.data.data.map(i => ({
        label: i.appointmentMode,
        value: i.appointmentMode,
        id: i._id
      })));
    } catch (err) {
      console.log(err); 
    }
  };
  
  const fetchAppointmentTypeList = async (Id, header) => {
    try {
      const response = await axios.get(`${APIkey}/api/admin/getAppointmentTypeList`, header);
      setAppointmentTypeData(response.data.data.map(i => ({
        label: i.appointmentType,
        value: i.appointmentType,
        id: i._id
      })));
    } catch (err) {
      console.log(err);
    }
  };
  
  const fetchVisitReasonList = async (Id, header) => {
    try {
      const response = await axios.get(`${APIkey}/api/admin/getReasonForVisitList`, header);
      setVisitReasonData(response.data.data.map(i => ({
        label: i.reason,
        value: i.reason,
        id: i._id
      })));
    } catch (err) {
      console.log(err);
    }
  };
  
  const fetchResourceList = async (Id, header) => {
    try {
      const response = await axios.get(`${APIkey}/api/admin/getResourceList`, header);
      setResourceData(response.data.data.map(i => ({
        label: i.resource,
        value: i.resource,
        id: i._id
      })));
    } catch (err) {
      console.log(err);
    }
  };
  
  const fetchClinicNameList = async (Id, header) => {
    try {
      const response = await axios.get(`${APIkey}/api/admin/getGroupIdWiseClinic`, header);
      setClinicNameList(response.data.data.map(i => ({
        label: i.clinicName,
        value: i.clinicName,
        id: i._id
      })));
    } catch (err) {
      console.log(err);
    }
  };
  
  const fetchDesignationAndUserList = async (Id, header) => {
    try {
      const designationResponse = await axios.get(`${APIkey}/api/admin/getDesignationList/${Id}`, header);
      const userResponse = await axios.get(`${APIkey}/api/admin/listUsersByClinicId/${Id}`, header);
  
      setwithList(designationResponse?.data?.data?.map(i => ({
        label: <span style={{ textOverflow: 'ellipsis', overflow: "hidden", whiteSpace: "nowrap" }} title={i?.designation} >{i?.designation}</span>,
        options: userResponse?.data?.data?.users.filter(j => j?.designationId === i?._id)?.map(j => ({
          value: `${j?.firstName} ${j?.lastName} - ${i?.designation}`,
          label: <span style={{ textOverflow: 'ellipsis', overflow: "hidden", whiteSpace: "nowrap" }} title={`${j?.firstName} ${j?.lastName}`}>{j?.firstName} {j?.lastName}</span>,
          Did: j?.designationId,
          Uid: j?._id
        }))
      })));
    } catch (err) {
      console.log(err);
    }
  };
  
  const fetchPatientInsuranceList = async (patientId, header) => {
    try {
      const response = await axios.get(`${APIkey}/api/admin/getPatientInsuranceByPatientId/${patientId}`, header);
      setPatientInsuranceList(response?.data?.data?.map(i => ({
        label: i.insurancePlanName,
        value: i.insurancePlanName,
        id: i._id
      })));
    } catch (err) {
      console.log(err);
    }
  };
  
  const fetchPatientAppointmentList = async (header) => {
    try {
      const response = await axios.get(`${APIkey}/api/admin/getAllAppointment?page=1&limit=50000`, header);
      const appointmentList = response.data.data.appointment_list;
      console.log("appointmentList", appointmentList)
  
      setPatientAppointmentList(appointmentList);
  
      setEvents(appointmentList?.map(i => {
        const appointmentDate = i?.appointmentDate;
        const appointmentTime = i?.appointmentTime;
  
        if (!appointmentDate || !appointmentTime) return null;
  
        const [hour, minute] = appointmentTime.split(':').map(Number);
        const [visitLength] = i?.visitLength.split(' ');
        const appointmentDateTime = new Date(appointmentDate);
        const year = appointmentDateTime.getFullYear();
        const month = appointmentDateTime.getMonth();
        const day = appointmentDateTime.getDate();
  
        let endHour = hour;
        let endMinute = minute + Number(visitLength);
  
        if (endMinute >= 60) {
          endHour += Math.floor(endMinute / 60);
          endMinute = endMinute % 60;
        }
  
        return {
          AppointMentId: i?._id,
          colorCode: i?.colorCode,
          patientId: i?.patientId,
          title: `${i?.patientDetails?.firstName} ${i?.patientDetails?.lastName}`,
          with: `${i?.designationDetails?.designation}`,
          provider: `${i?.usersDetails?.firstName} ${i?.usersDetails?.lastName}`,
          start: new Date(year, month, day, hour, minute),
          end: new Date(year, month, day, endHour, endMinute)
        };
      }).filter(Boolean));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const getloginInfo: any = localStorage.getItem('appLoginInfo');
    const loginInfo = JSON.parse(getloginInfo);
    const Token = loginInfo.data.accessToken;
    const header = {
      headers: { 'x-access-token': Token }
    };
    fetchPatientAppointmentList(header)
  }, [createAppointmentStatus])
  
  
  useEffect(() => {
    getAllfieldList();
  }, [confirmAppointmentPop, showHealthPlanClickPopup, patientId, editAppointmentPopup]);
  



  const repeatData = Array.from({ length: 21 }, (_, index) => ({
    value: index,
    label: index,
    id: index
  }));


  const DaysBetweenVisitsdata = Array.from({ length: 31 }, (_, index) => ({
    value: index,
    label: index,
    id: index
  }));


  const getThePatientInsuranceId = (e) => {
    setPatientInsuranceId(e)
  }



  const getTheAppointMode = (data) => {

    setAppointmentMode(data)


  }
  const getAppointmentType = (data) => {

    setAppointmentType(data)


  }
  const getClinicName = (data) => {
    setClinicName(data)
  }




  const gettingWith = (data) => {



    setdesignation(data?.Did)
    setUserId(data?.Uid)


  }

  const today = new Date().toISOString().split('T')[0];

  const today1 = new Date();
  const afterOneYear = new Date(today1);
  afterOneYear.setFullYear(today1.getFullYear() + 1);
  const afterOneYearString = afterOneYear.toISOString().split('T')[0];

  const dayfun = (d) => {
    const dayarr = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]

    let newDay = d.getDay();
    console.log("newDay", newDay)

    setGetDay(dayarr[newDay])

  }

  const Visitdatefun = (e) => {
    const VDate = new Date(e.target.value);
    console.log("inVisitdate", VDate)
    if(e == "Invalid date"){
      return ;
    } 

    setVisitDate(e.target.value)
    
    dayfun(VDate);
  }




  const getVisitTime = (e) => {
    const selectedInputTime = e.target.value;

    setVisitTime(selectedInputTime)

  }


  const getTheRepeat = (e) => {
    setRepeat(e);
  }

  const getDaysBetweenVisits = (e) => {

    setDaysBetweenVisits(e);
  }


  const getTheVisitReason = async (id) => {

    setVisitReason(id);

    try {
      let getloginInfo: string | null = localStorage.getItem('appLoginInfo')

      if (getloginInfo !== null) {

        const Token = JSON.parse(getloginInfo).data.accessToken;

        const clinicId = JSON.parse(getloginInfo).data.clinicId;

        const header = {
          headers: { 'x-access-token': Token }
        }


        const visitReasonList = await axios.get(`${APIkey}/api/admin/getReasonForVisitById/${id}`, header);

        // console.log(visitReasonList.data.data);

        let data = visitReasonList?.data?.data;

        if (data) {
          setVisitLength(data.time)
          setColordiv(data.color)
        }

      }
      else {
        console.log("No login info found in localStorage");
      }
    }
    catch (err) {
      console.log(err);
    }


  }

  const getTheResource = (e) => {
    setResource(e);

  }
  const getTheNotes = (e) => {
    setNotes(e.target.value);

  }



  const createAppointment = async (data) => {

    try {
      let getloginInfo: string | null = localStorage.getItem('appLoginInfo')

      if (getloginInfo !== null) {

        const Token = JSON.parse(getloginInfo).data.accessToken;

        const header = {
          headers: { 'x-access-token': Token }
        }
        const response = await axios.post(`${APIkey}/api/admin/createAppointment`, data, header);
        console.log('this is response', response);

        if (response) {
          setLoading(false)
          setCreateAppointmentStatus(true)
          setconfirmAppointmentPop(true)
        }
      }
    }
    catch (err) {
      setLoading(false)
      console.log(err);
    }

  }



  const appointmentValidation = (value) => {
    const error: any = {};


    if (!value.patientId) {
      error.patientId = inputText.patientID.ErrMsg;
    }

    if (!value.appointmentMode) {
      error.appointmentMode = inputText.appointmentMode.ErrMsg;
    }

    if (!value.appointmentType) {
      error.appointmentType = inputText.appointmentType.ErrMsg;
    }

    if (!value.clinicName) {
      error.clinicName = inputText.ClinicName.ErrMsg;
    }
    if (!value.designationId) {
      error.designationId = inputText.with.ErrMsg;
    }

    let appointmentDateTime = value.appointmentDate?.includes(":") ? value.appointmentDate : value.appointmentDate + " " + new Date().toLocaleTimeString();

    // Create Date objects
    let appointmentDateObj = new Date(appointmentDateTime);

    if (!value.appointmentDate || value.appointmentDate == "Invalid date" || value.appointmentDate == undefined ) {
      error.appointmentDate = inputText.onDay.ErrMsg;
    }
    else if (today1 > appointmentDateObj) {
      error.appointmentDate = inputText.onDay.validErrMsg;
    }
    else if (afterOneYear < appointmentDateObj) {
      error.appointmentDate = inputText.onDay.validErrMsg;
    }



    if (!value.appointmentTime) {
      error.appointmentTime = inputText.At.ErrMsg;
    }
    else {
      let currentTime = new Date().toLocaleTimeString('en-US', { hour12: false });

      let [hours1, minutes1] = value?.appointmentTime.split(':').map(Number);
      let [hours2, minutes2] = currentTime.split(':').map(Number);

      const selctedDate1 = new Date(0, 0, 0, hours1, minutes1);
      const currentDate2 = new Date(0, 0, 0, hours2, minutes2);

      if (today == appointmentDate) {
        if (selctedDate1 < currentDate2) {
          error.appointmentTime = inputText.At.ErrMsg;
        }
      }

    }

    return error
  }

  const SaveTheInfo = () => {

    setLoading(true)
    console.log("date: ", appointmentDate);



    let data = {
      patientId,
      patientInsuranceId,
      appointmentMode,
      appointmentType,
      repeat,
      daysBetweenVisits,
      clinicName,
      designationId,
      userId,
      appointmentDate,
      appointmentTime,
      reasonForVisit,
      visitLength,
      resourceId,
      colorCode,
      notes,
    }

    const err = appointmentValidation(data)

    console.log(err);


    if (
      Object.keys(err).length === 0
    ) {

      createAppointment(data)

      setErrMRN(null)
      setErrAppointMentMode(null)
      setErrAppointMentType(null)
      setErrClinicName(null)
      setErrDesignation(null)
      setErrAppointmentDate(null)
      setErrAppointmentTime(null)
    }
    else {
      setLoading(false)

      setErrMRN(err.patientId)
      setErrAppointMentMode(err.appointmentMode)
      setErrAppointMentType(err.appointmentType)
      setErrClinicName(err.clinicName)
      setErrDesignation(err.designationId)
      setErrAppointmentDate(err.appointmentDate)
      setErrAppointmentTime(err.appointmentTime)
    }



  }

  const closeModal = () => {
    setconfirmAppointmentPop(false);
  };

  useEffect(() => {

    if (confirmAppointmentPop) {

      const timeoutId = setTimeout(() => {
        closeModal();

        setPatientId('')
        setMrn('')
        setLastName('')
        setFirstName('')
        setSsn('')
        setDob('')
        setCountrycode1('')
        setmobileno('')
        setCountrycode2('')
        setHomeno('')
        setPatientInsuranceId(null)
        setAppointmentMode(null)
        setAppointmentType(null)
        setClinicName(null)
        setUserId(null)
        setDaysBetweenVisits(null)
        setVisitDate(null)
        setVisitTime(null)
        setVisitLength('')
        setGetDay('')
        setNotes('')
        setColordiv('')
        setResource(null)
        setVisitReason(null)
        setRepeat(null)
        setPatientInsuranceList([])
        setdesignation(null)

      }, 3000);

      return () => clearTimeout(timeoutId);
    }

  }, [confirmAppointmentPop]);

  useEffect(() => {
    if (patientId && appointmentTime) {
      const timeoutId = setTimeout(() => {
        setVisitTime(null)
      }, 200000);
      return () => clearTimeout(timeoutId);
    }
  }, [patientId, appointmentTime]);

  const date = (d) => moment(d).format('DD/MM/yyyy')

  // List View ... 

  const switchScreen = (data) => {

    setShowListPage(data);

  }

  const ResetTheInfo = () => {
    // Reset the data
    setLoading(false)
    setPatientId('')
    setPatientInsuranceId('')
    setAppointmentMode('')
    setAppointmentType('')
    setRepeat(null)
    setDaysBetweenVisits(null)
    setClinicName('')
    setdesignation('')
    setUserId('')
    setGetDay('')
    // setAppointmentDate('')
    setVisitTime('')
    setVisitReason('')
    setVisitLength('')
    setResource('')
    setColordiv('')
    

    setNotes('')

    setErrMRN(null)
    setErrAppointMentMode(null)
    setErrAppointMentType(null)
    setErrClinicName(null)
    setErrDesignation(null)
    setErrAppointmentDate(null)
    setErrAppointmentTime(null)
  }

  console.log("appointmentModeData", appointmentModeData)


  return (
    <>
      {
        showListPage ?
          <Container style={{ display: 'contents' }}>
            <Row className='contentMargin'>
              <Col lg={4}>
                <div className='header-top   '>
                  <label className='top-label-margin  '>Add New Appointments</label>
                </div>

                <div className='col-md-12'>
                  <label className='titleFont mt-1'>Patient Information</label>
                </div>
                <div className='row col-md-12 padding-label '>
                  <div className='col-md-1 ' style={{ width: '50%' }}>
                    <div className='d-flex justify-content-between align-items-center mb-1'>

                      <label className='patient-info-title required '>{inputText?.mrn.label}</label>

                      <button type="button" className='blueButton' onClick={handleSearchClick}>
                        Search
                      </button>
                    </div>
                    <input
                      type='text'
                      className='form-control SearchInputbox'
                      name='mrn'
                      placeholder=''
                      required
                      value={mrn}
                      disabled
                    />

                    {
                      errMRN &&

                      <span className='form-label errMsg'>
                        <i className="fa-solid fa-triangle-exclamation"></i>
                        &nbsp;
                        {inputText.patientID.ErrMsg}
                      </span>}
                  </div>
                  {/* <div className='col-md-1 searchformfld' style={{ width: '15%' }}> */}
                  {/* <label className='btn btn-sm btn-left' onClick={handleSearchClick}>
                <span className='btn-font'>Search</span>
              </label> */}
                  {/* <button type="button" className='blueButton' onClick={handleSearchClick}>
                      Search
                    </button>
                  </div> */}


                  {/* Automated Eligibility ....  */}
                  {/* <div className='col-md-4 d-flex  align-items-center  '>
                    <span className='lable-font-class'>Automated Eligibility </span>
                    &nbsp;<input type='checkbox'/>
                  </div> */}
                </div>

                <div className='row col-md-12'>
                  <div className='col-md-2 ' style={{ width: '50%' }}>
                    <label className='patient-info-title'>{inputText?.lastName.label}</label>
                    <input
                      type='text'
                      value={lastName}
                      className='form-control SearchInputbox'
                      name='lastName'
                      placeholder=''
                      disabled
                    />
                  </div>

                  <div className='col-md-2 ' style={{ width: '50%' }}>
                    <label className='patient-info-title'>{inputText?.firstName.label}</label>
                    <input
                      type='text'
                      className='form-control SearchInputbox'
                      name='firstName'
                      placeholder=' '
                      value={firstName}
                      disabled
                    />
                  </div>
                </div>

                <div className='row col-md-12'>
                  <div className='col-md-2 ' style={{ width: '50%' }}>
                    <label className='patient-info-title'>{inputText?.SSN.label}</label>
                    <input
                      type='text'
                      className='form-control SearchInputbox'
                      name='ssn'
                      placeholder=' '
                      value={ssn}
                      disabled
                    />
                  </div>

                  <div className='col-md-2' style={{ width: '50%' }}>
                    <label className='patient-info-title'>{inputText?.dateOfBirth.label}</label>

                          {/* <Datepicker 
                      selectedDate={dob}
                      // gettingDate={Visitdatefun}
                      disabled={true}
                      /> */}
                                        <input
                    type='date'
                    value={dob || ''}
                    // onChange={Visitdatefun}
                    disabled={true}
                    className='form-control SearchInputbox' />
                  
                  </div>
                </div>

                <div className='row col-md-12'>
                  <div className='col-md-2 ' style={{ width: '50%' }}>
                    {/* <label className='form-label'>Cell No:</label>
        <input type='text' className='form-control textbox-height' /> */}
                    <label className='patient-info-title'>{inputText?.cellNo.label}</label>
                    <div className='d-flex  gap-1 '>
                      <input
                        type='text'
                        className='form-control SearchInputbox w-25'
                        disabled
                        value={Countrycode1}
                      />
                      <input
                        type='text'
                        className='form-control SearchInputbox'
                        name='cellNo'
                        placeholder=''
                        disabled
                        value={mobileno}
                      />
                      { /* <PhoneInputCompo 
                      contactnoValue ={`${Countrycode1}${mobileno}`}
                      /> */ }
                    </div>

                  </div>

                  <div className='col-md-2 ' style={{ width: '50%' }}>
                    {/* <label className='form-label'>Phone:</label>
        <input type='text' className='form-control textbox-height' /> */}
                    <label className='patient-info-title'>{inputText?.homeNo.label}</label>
                    <div className='d-flex  gap-1 '>
                      <input
                        type='text'
                        className='form-control SearchInputbox w-25'
                        disabled
                        value={Countrycode2}
                      />
                      <input
                        type='text'
                        className='form-control SearchInputbox'
                        name='cellNo'
                        placeholder=' '
                        value={homeNo}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className='row  col-md-12 ' >
                  <div className='col-md-2 '
                    style={{ width: '50%' }}>
                    {/* <label className='form-label'>Health Plan Eligibility Name:</label>
        <input type='text' className='form-control textbox-height' /> */}
                    <label className='patient-info-title'>{inputText?.patientInsurance.label}</label>
                    <div className='d-flex gap-2'>
                      <SearchableDropdown
                        InitialValue={patientInsuranceId}
                        gettingData={getThePatientInsuranceId}
                        options={patientInsuranceList}
                        disabled={patientId === ''}
                         />

                      {/* <input
                      type='text'
                      className='form-control textbox-height'
                      name='helthPlanEligibility'
                      placeholder=''
                      /> */}

                      <button
                        className={patientId === '' ? '' : 'blueButton'}
                        style={{ borderRadius: '5px', }}
                        onClick={healthClickSearchClick}
                        disabled={patientId === ''}
                      >

                        <i className="fa fa-plus"></i>
                      </button>
                    </div>

                    {
                      errPatientInsuranceId &&

                      <span className='form-label errMsg'>
                        <i className="fa-solid fa-triangle-exclamation"></i>
                        &nbsp;
                        {inputText.patientInsurance.ErrMsg}
                      </span>}

                  </div>

                  <div className='col-md-4 d-flex align-items-center '>
                    {/* <span className='lable-font-class '>Check Eligibility</span>
                    &nbsp;<input type='checkbox' className='' /> */}
                    {/* <label className='form-label'>Insurance Name:</label>
        <input type='text' className='form-control textbox-height' /> */}
                    {/* <input
                type='text'
                className='form-control textbox-height'
                name='helthPlanEligibility'
                placeholder=' '
              />
              <label>Insurance Name</label> */}
                  </div>
                </div>

                <div className='col-md-12'>
                  <label className='titleFont'>Appointment Information</label>
                </div>

                <div className='row col-md-12 align-items-center padding-label'>
                  <div className='col-md-2  ' style={{ width: '50%' }}>
                    {/* <input name='radio' type='radio' defaultChecked /> <label className='form-label'>{inputText?.INOffice.label}</label>
                      &nbsp;&nbsp;
                  <input name='radio' type='radio' disabled /> <label className='form-label'>{inputText?.VirtualVisit.label}</label> */}

                    <SearchableDropdown
                      InitialValue={appointmentMode}
                      label={inputText.appointmentMode.label}
                      required={true}
                      gettingData={getTheAppointMode}
                      placeHolder={inputText.appointmentMode.placeholder}
                      options={appointmentModeData} />
                    {
                      errAppointMentMode &&
                      <span className='form-label errMsg'>
                        <i className="fa-solid fa-triangle-exclamation"></i>
                        &nbsp;
                        {inputText.appointmentMode.ErrMsg}
                      </span>}

                  </div>
                  <div className='col-md-2 ' style={{ width: '50%' }}>
                    <SearchableDropdown
                      label={inputText.appointmentType.label}
                      InitialValue={appointmentType}
                      required={true}
                      gettingData={getAppointmentType}
                      placeHolder={inputText.appointmentType.placeholder}
                      options={appointmentTypeData} />
                    {
                      errAppointMentType &&

                      <span className='form-label errMsg'>
                        <i className="fa-solid fa-triangle-exclamation"></i>
                        &nbsp;
                        {inputText.appointmentType.ErrMsg}
                      </span>}
                  </div>
                </div>

             


                <div className='row col-md-12 padding-label'>
                  <div className='col-md-2' style={{ width: '50%' }}>
                    {/* <label className='form-label'>With:</label> */}
                    {/* <select
                className='form-select'
                data-control='select2'
                data-placeholder='Select an option'
              >
                <option value=''>With:</option>
                <option value='1'>Option 1</option>
                <option value='2'>Option 2</option>
              </select> */}
                    <SearchableDropdown
                      InitialValue={clinicName}
                      label={inputText?.ClinicName.label}
                      required={true}
                      gettingData={getClinicName}
                      placeHolder={inputText.ClinicName.placeholder}
                      options={clinicNameList}
                    />

                    {
                      errClinicName &&

                      <span className='form-label errMsg'>
                        <i className="fa-solid fa-triangle-exclamation"></i>
                        &nbsp;
                        {inputText.ClinicName.ErrMsg}
                      </span>}
                  </div>

                  <div className='col-md-2  align-items-end' style={{ width: '50%' }}>
               

                    {/* this is work  */}
                    <SearchableGroup
                      InitialValue={userId}
                      label={inputText?.with.label}
                      required={true}
                      gettingData={gettingWith}
                      placeHolder={inputText.with.placeholder}
                      options={withList} />

                    {
                      errDesignation &&

                      <span className='form-label errMsg'>
                        <i className="fa-solid fa-triangle-exclamation"></i>
                        &nbsp;
                        {inputText.with.ErrMsg}
                      </span>}
                  </div>
                </div>


                <div className='row col-md-12 padding-label'>
                  <div className='col-md-2' style={{ width: '50%' }}>
                    <label className='patient-info-title required'>{getDay ? `On(${getDay}):` : inputText.onDay.label}</label>
                    {/* <input
                      value={appointmentDate || ''}
                      type='date'
                      min={today}
                      max={afterOneYearString}
                      onChange={(e) => Visitdatefun(e)}
                      className='form-control SearchInputbox' />
                    {
                      errAppointmentDate &&

                      <span className='form-label errMsg'>
                        <i className="fa-solid fa-triangle-exclamation"></i>
                        &nbsp;
                        {errAppointmentDate}
                      </span>} */}
                   
                 
<br />
                      {/* <Datepicker 
                      selectedDate={appointmentDate || ''}
                      gettingDate={Visitdatefun}
                      disabled={false}
                      /> */}
                           <input
                    type='date'
                    value={appointmentDate || ''}
                    onChange={Visitdatefun}
                    className='form-control SearchInputbox' />
                    
                      <div>
                      <ErrorMsgShow errorMsg={errAppointmentDate}  />
                      </div>
                      
                  </div>

                  <div className='col-md-2 ' style={{ width: '50%' }}>
                    <label
                      htmlFor=""
                      className='patient-info-title required'>
                      {inputText.At.label}

                    </label>
                    <input
                      onChange={getVisitTime}
                      value={appointmentTime || ''}
                      type="time"
                      className='form-control SearchInputbox' />
                    {
                      errAppointmentTime &&

                      <span className='form-label errMsg'>
                        <i className="fa-solid fa-triangle-exclamation"></i>
                        &nbsp;
                        {inputText.At.ErrMsg}
                      </span>}
                  </div>

                </div>

                <div className='row col-md-12 padding-label'>
                  <div className='col-md-2  ' style={{ width: '50%', position: 'relative' }}>
                    {/* <label htmlFor="" className='form-label'>Visit Reason:</label>
                    <input

                      type='text'
                      className='form-control textbox-height '
                      name='Visit Reason'
                      placeholder='Visit Reason'
                    />
                    <button
                      style={{
                        position: 'absolute',
                        top: '72.5%',
                        right: '10.8px',
                        border: "none",
                        borderRadius: "0px 5px 5px 0px",
                        height: "30px",
                        width: "30px",
                        transform: 'translateY(-50%)',
                        backgroundColor: "#DCDCDC"
                      }}
                      onClick={visitReasonClickSearch} >
                      <i
                        className='fa-solid fa-up-right-from-square'
                      ></i>
                    </button> */}
                    <SearchableDropdown
                      InitialValue={repeat}
                      label={inputText.repeat.label}
                      gettingData={getTheRepeat}
                      placeHolder={inputText.repeat.placeholder}
                      options={repeatData} />

                  </div>

                  <div className='col-md-2 ' style={{ width: '50%' }}>
                    {/* <label className='form-label'>Visit Length:</label> */}
                    {/* <select
                className='form-select'
                data-control='select2'
                data-placeholder='Select an option'
              >
                <option value=''>Please Select Visit Length</option>
                <option value='1'>Option 1</option>
                <option value='2'>Option 2</option>
              </select> */}
                    <SearchableDropdown
                      InitialValue={daysBetweenVisits}
                      label={inputText.DaysBetweenVisits.label}
                      gettingData={getDaysBetweenVisits}
                      placeHolder={inputText.DaysBetweenVisits.placeholder}
                      options={DaysBetweenVisitsdata} />

                  </div>
                </div>
                <div className='row col-md-12 padding-label'>
                  <div className='col-md-2  ' style={{ width: '50%', position: 'relative' }}>
                    {/* <label htmlFor="" className='form-label'>Visit Reason:</label>
                    <input

                      type='text'
                      className='form-control textbox-height '
                      name='Visit Reason'
                      placeholder='Visit Reason'
                    />
                    <button
                      style={{
                        position: 'absolute',
                        top: '72.5%',
                        right: '10.8px',
                        border: "none",
                        borderRadius: "0px 5px 5px 0px",
                        height: "30px",
                        width: "30px",
                        transform: 'translateY(-50%)',
                        backgroundColor: "#DCDCDC"
                      }}
                      onClick={visitReasonClickSearch} >
                      <i
                        className='fa-solid fa-up-right-from-square'
                      ></i>
                    </button> */}
                    <SearchableDropdown
                      InitialValue={reasonForVisit}
                      label={inputText.VisitReason.label}
                      gettingData={getTheVisitReason}
                      placeHolder={inputText.VisitReason.placeholder}
                      options={visitReasonData} />
                    {
                      errvisitReason &&

                      <span className='form-label errMsg'>
                        <i className="fa-solid fa-triangle-exclamation"></i>
                        &nbsp;
                        {inputText.VisitReason.ErrMsg}
                      </span>}

                  </div>

                  <div className='col-md-2 ' style={{ width: '50%' }}>
                    <label className='patient-info-title'>Visit Length:</label>
                    <input className='form-control SearchInputbox' value={visitLength} readOnly />
                    {/* <select
                className='form-select'
                data-control='select2'
                data-placeholder='Select an option'
              >
                <option value=''>Please Select Visit Length</option>
                <option value='1'>Option 1</option>
                <option value='2'>Option 2</option>
              </select> */}
                    {/* <SearchableDropdown label={inputText.VisitLength.label} gettingData={gettingOffice} placeHolder={inputText.VisitLength.placeholder} options={options} /> */}

                  </div>
                </div>

                <div className='row col-md-12 padding-label'>
                  <div className='col-md-2 pt-1' style={{ width: '50%' }}>
                    {/* <label className='form-label'>Resource:</label> */}
                    {/* <select
                className='form-select'
                data-control='select2'
                data-placeholder='Select an option'
              >
                <option value=''>Please Select Resource</option>
                <option value='1'>Option 1</option>
                <option value='2'>Option 2</option>
              </select> */}
                    <SearchableDropdown
                      InitialValue={resourceId}
                      label={inputText.Resource.label}
                      gettingData={getTheResource}
                      placeHolder={inputText.Resource.placeholder}
                      options={resourceData}
                    />

                  </div>

                  <div className='col-md-2 pt-1  align-items-center' style={{ width: '50%' }}>
                    {/* <label className='form-label lable-margin'></label> */}
                    {/* <select
                className='form-select'
                data-control='select2'
                data-placeholder='Select an option'
              >
                <option value=''>Please Select</option>
                <option value='1'>Option 1</option>
                <option value='2'>Option 2</option>
              </select> */}
                    {/* <SearchableDropdown label="" gettingData={gettingOffice} placeHolder="" options={options} /> */}

                    <label className='patient-info-title'>{inputText.color.label}</label>
                    <div
                      className='form-control'
                      style={{ backgroundColor: colorCode ? colorCode : '#e6e6e6', height: "30px", width: "30%" }}
                    ></div>

                  </div>
                </div>

                <div className='row col-md-12 padding-label'>
                  <div className='col-md-2' style={{ width: '100%', paddingTop: "10px" }}>
                    <label className='patient-info-title'>{inputText.notes.label}</label>
                    <textarea
                      value={notes || ''}
                      maxLength={500}
                      className='form-control SearchInputbox'
                      placeholder={inputText.notes.placeholder}
                      onChange={getTheNotes}
                      style={{ height: "40px" }} />
                  </div>

                </div>

                <div className='row col-md-12 padding-label'>
                  {/* <div className='col-md-2' style={{ width: '100%' }}>
                    {
                      loading ?
                        <div className='loader ' style={{ float: 'right' }}></div>
                        :
                        <input
                          type='button'
                          className='primarybtnBig'
                          onClick={SaveTheInfo}
                          value={'Save'}
                          style={{ float: 'right' }}
                        ></input>
                    }
                  </div> */}
                    <div className='col-md-2' style={{width: '100%'}}>
                  {loading ? (
                    <div className='loader ' style={{float: 'right'}}></div>
                  ) : (
                    <div className='d-flex gap-2 justify-content-center'>
                      <div>
                        <input
                          type='button'
                          className='primarybtnBig'
                          onClick={SaveTheInfo}
                          value={'Save'}
                          style={{float: 'right'}}
                        />
                      </div>
                      <div>
                        <input
                          type='button'
                          className='tertiarybig'
                          onClick={ResetTheInfo}
                          value={'Reset'}
                          style={{float: 'right'}}
                        />
                      </div>
                    </div>
                  )}
                </div>
                </div>
              </Col>

              <Col lg={8} >

                <div className=' mutiselectItem h-55px  row align-items-center ' >
                  <div className=' col-md-6  col-sm-12 col-lg-4   '>
                    <div className='d-flex p-2 justify-content-start'>

                      <label className='form-label fs-5 pt-1 px-2'>{inputText.Office.label}</label>
                      <MultiSelectDropdown InitialValue={officeFilter} getMultiselectData={gettingOfficeFilter} placeHolder={inputText.Office.placeholder} options={options} />
                    </div>
                  </div>

                  <div className='d-flex  col-md-6  col-sm-12  col-lg-4 '>
                    <div className='d-flex p-2 '>

                      <label className='form-label fs-5 pt-1 px-2 '>{inputText.Provider.label}</label>
                      <MultiSelectDropdown InitialValue={officeFilter} getMultiselectData={gettingProviderFilter} placeHolder={inputText.Provider.placeholder} options={options} />
                    </div>
                  </div>
                  <div className='d-flex col-md-12  col-sm-12  col-lg-4 justify-content-end align-items-center ' >
                    {/* page  switching button ... */}
                    <div>
                      <button className='switchViewbtnLeft switchViewbtnActive '>
                        Calendar
                      </button>
                      <button onClick={() => setShowListPage(false)} className='switchViewbtnRight '>
                        List View
                      </button>
                    </div>
                  </div>
                </div>

                {
                  officeFilter.length !== 0 ?
                    <h1>
                      This is filter  ...
                    </h1>
                    :
                    <div className='scroll '>

                      <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor='start'
                        endAccessor='end'
                        style={{ height: "auto !important", }}
                        components={{
                          event: CustomEvent, // Use the custom Event component
                        }}
                        onSelectEvent={handleEventSelect}
                        eventPropGetter={(events) => {
                          const backgroundColor = events.colorCode ? events.colorCode : 'blue';
                          const padding = "2px"
                          const color = 'black'
                          const fontSize = '10px'

                          // const color = events.color ? events.color : 'blue';
                          return { style: { backgroundColor, padding, color, fontSize } }
                        }}
                        defaultView="month"
                      />

                      {selectedEvent && (
                        <Modal
                          show={showModal}
                          onHide={handleCloseModal}
                          dialogClassName='event-modal'
                          size='sm'
                          centered
                        >
                          <Modal.Header className='h-30px' closeButton>


                            <div style={{ backgroundColor: selectedEvent.colorCode, height: '15px', width: '15px', borderRadius: '4px' }}></div>
                            <Modal.Title className='elipsisText ' title={selectedEvent.title}>
                              <div className=''>
                                 &nbsp;{selectedEvent.title}
                              </div>
                            </Modal.Title>

                            {/* <div>
                              ({selectedEvent.with})
                              &nbsp;
                              {selectedEvent.provider}
                            </div> */}


                          </Modal.Header>
                          <Modal.Body className='p-1'>
                            <ul className='appointmentUl px-1 '>
                              <li onClick={() => [hadleEditAppointment(selectedEvent.AppointMentId, selectedEvent.patientId), setShowSubMenuList('')]}>

                                <img src={EditAppointmentIcon} alt="" className='p-0 m-0' />
                                <div>

                                  <span className='font-icon-title'>Edit Appointment</span>
                                </div>

                              </li>
                              <li onClick={() => navigate(`/appointment/Charts/vitals/${selectedEvent.AppointMentId}`)}>
                                <div className='d-flex align-items-center'>
                                  <img src={takeVitals} alt="" className='p-0 m-0' />
                                </div>
                                <div>
                                  <span className='font-icon-title'>Take Vitals</span>
                                </div>

                              </li>
                              <li
                                className='mainMenu'
                                // onClick={() => navigate('/appointment/Charts')}
                                onClick={() => setShowSubMenuList('patientChart')}
                              >

                                <div className='d-flex align-items-center '>
                                  <img src={patientChart} alt="" className='p-0 m-0' />
                                </div>

                                <div>
                                  <span className='font-icon-title'>Patient Chart</span>
                                </div>
                                <div className='' >
                                  <img src={sideDown} />
                                </div>
                                {
                                  (showSubMenuList == "patientChart") && (

                                    <ul className=' subMenu'>
                                      <li onClick={() => navigate('/appointment/charts/')}>Preview</li>
                                      <li>HPI</li>
                                      <li onClick={() => navigate(`/appointment/charts/vitals/${selectedEvent.AppointMentId}`)}>Vitals</li>
                                      <li>History</li>
                                      <li>Problem List</li>
                                      <li>Allergies</li>
                                      <li>Immunization</li>
                                      <li>Physical Exam</li>
                                      <li>Lab Result</li>
                                      <li>Diagnosis</li>
                                      <li>Treatment Plan</li>
                                      <li>Medications</li>
                                      <li>ROS</li>
                                      <li>Health Maintenance</li>
                                      <li>Review of Results</li>
                                    </ul>
                                  )
                                }

                              </li>
                              <li
                                className='mainMenu'
                                //  onClick={() => navigate(`/patient/details/patientInformation/${selectedEvent.patientId}`)}
                                onClick={() => setShowSubMenuList('patientDemo')}
                              // onMouseOut={()=> setShowpatientDemoList(false)}
                              >
                                <div className='d-flex align-items-center'>
                                  <img src={patientDemo} alt="" className='p-0 ' />
                                </div>
                                <div>

                                  <span className='font-icon-title '>Patient Demographic </span>
                                </div>
                                <div className='' >
                                  <img src={sideDown} />
                                </div>
                                {
                                  showSubMenuList == "patientDemo" && (
                                    <ul className=' subMenu'>
                                      <li onClick={() => navigate(`/patient/details/patientInformation/${selectedEvent.patientId}/${selectedEvent.AppointMentId}`)}>Patient Information</li>
                                      <li 
                                      onClick={() => navigate(`/patient/details/additionalInformation/${selectedEvent.patientId}/${selectedEvent.AppointMentId}`)}
                                      >Additional Information</li>
                                      <li
                                      onClick={() => navigate(`/patient/details/insurance/${selectedEvent.patientId}/${selectedEvent.AppointMentId}`)}
                                       >Insurance </li>
                                      <li 
                                      onClick={() => navigate(`/patient/details/history/${selectedEvent.patientId}/${selectedEvent.AppointMentId}`)}
                                      >History </li>
                                      <li
                                       onClick={() => navigate(`/patient/details/financials/${selectedEvent.patientId}/${selectedEvent.AppointMentId}`)}
                                      >Financials </li>
                                      <li onClick={() => navigate(`/patient/details/documents/${selectedEvent.patientId}/${selectedEvent.AppointMentId}`)}>Documents </li>
                                      <li
                                      onClick={() => navigate(`/patient/details/appDetails/${selectedEvent.patientId}/${selectedEvent.AppointMentId}`)} 
                                       >Appointment Details </li>
                                      <li
                                      onClick={() => navigate(`/patient/details/employerSchoolDetails/${selectedEvent.patientId}/${selectedEvent.AppointMentId}`)} 
                                      >Employer / School Detail</li>
                                    </ul>
                                  )
                                }
                              </li>

                              {/* <li>
                                <div className='d-flex align-items-center'>

                                  <img src={clinicalNotes} alt="" className='p-0 ' />
                                </div>
                                <span className='font-icon-title'> Clinical Notes</span>
                              </li> */}
                              {/* <li onClick={() => navigate(`/patient/details/history/${selectedEvent.patientId}`)}>
                                <div className='d-flex align-items-center'>
                                  <img src={vitalsHistory} alt="" className='p-0 ' />
                                </div>
                                <span className='font-icon-title'>Visit History</span>
                              </li> */}
                              <li onClick={() => setShowSubMenuList('')}>
                                <div className='d-flex align-items-center'>
                                  <img src={vitalsDetail} alt="" className='p-0 ' />
                                </div>
                                <span className='font-icon-title'>Visit Detail</span>
                              </li>
                              {/* <li onClick={() => setShowSubMenuList('')}>
                                <div className='d-flex align-items-center'>
                                  <img src={Todo} alt="" className='p-0 ' />
                                </div>
                                <span className='font-icon-title'>To Do</span>
                              </li> */}
                              {/* <li>
                                <div className='d-flex align-items-center'>
                                  <img src={statusType} alt="" className='p-0 ' />
                                </div>
                                <span className='font-icon-title'>Change Status (Status Type)</span>
                              </li> */}
                              {/* <li>
                                <div className='d-flex align-items-center'>
                                  <img src={visitType} alt="" className='p-0 ' />
                                </div>
                                <span className='font-icon-title'>Change Visit Type</span>
                              </li> */}
                              {/* <li>
                                <div className='d-flex align-items-center'>
                                  <img src={changeDuration} alt="" className='p-0 ' />
                                </div>
                                <span className='font-icon-title'>Change Duration</span>
                              </li> */}
                              {/* <li>
                                <div className='d-flex align-items-center'>
                                  <img src={eligibilitySEarviceType} alt="" className='p-0 ' />
                                </div>
                                <span className='font-icon-title'>Change Eligibility Service Type </span>
                              </li> */}
                              {/* <li>
                                <div className='d-flex align-items-center'>
                                  <img src={cancelAppointment} alt="" className='p-0 ' />
                                </div>
                                <span className='font-icon-title'>Cancel Appointment </span>
                              </li> */}
                              {/* <li>
                                <div className='d-flex align-items-center'>
                                  <img src={changeDuration} alt="" className='p-0 ' />
                                </div>
                                <span className='font-icon-title'>Delete Appointment </span>
                              </li> */}
                              {/* <li>
                                <div className='d-flex align-items-center'>
                                  <img src={Todo} alt="" className='p-0 ' />
                                </div>
                                <span className='font-icon-title'>To Do </span>
                              </li> */}
                              <li onClick={() => setShowSubMenuList('')}>
                                <div className='d-flex align-items-center'>
                                  <img src={stickyNotes} alt="" className='p-0 ' />
                                </div>
                                <span className='font-icon-title'>Sticky Notes </span>
                              </li>
                              <li onClick={() => setShowSubMenuList('')}>
                                <div className='d-flex align-items-center'>
                                  <img src={examprep} alt="" className='p-0 ' />
                                </div>
                                <span className='font-icon-title'>Exam Prep</span>
                              </li>
                              {/* <li>
                                <div className='d-flex align-items-center'>
                                  <img src={comments} alt="" className='p-0 ' />
                                </div>
                                <span className='font-icon-title'>Comments </span>
                              </li> */}
                              {/* <li>
                                <div className='d-flex align-items-center'>
                                  <img src={externalAppointment} alt="" className='p-0 ' />
                                </div>
                                <span className='font-icon-title'>External Appointment(s) </span>
                              </li> */}
                              {/* <li>
                                <div className='d-flex align-items-center'>
                                  <img src={Medication} alt="" className='p-0 ' />
                                </div>
                                <span className='font-icon-title'>Medication</span>
                              </li> */}
                            </ul>
                          </Modal.Body>

                        </Modal>
                      )}
                    </div>



                }
              </Col>
            </Row>

            {/* Popup */}
            {showPopup && (
              // <Modal show={showPopup} onHide={handleClosePopup} size='lg' centered>
              //   <Modal.Header closeButton>
              //     <Modal.Title>Patient Search</Modal.Title>
              //   </Modal.Header>
              //   <Modal.Body>
              //     <div>
              //       {rows.map((row, index) => (
              //         <div key={index} className=' mb-2 row' >
              //           <div className='col-md-3'>
              //             <select
              //               className='form-select h-30px p-2'
              //               data-control='select2'
              //               data-placeholder='Select an option'
              //               value={row.lastName}
              //               onChange={(e) => {
              //                 const updatedRows = [...rows]
              //                 updatedRows[index].lastName = e.target.value
              //                 setRows(updatedRows)

              //               }}
              //             >
              //               <option value='1'>Last Name</option>
              //               <option value='2'>First Name</option>
              //               <option value='3'>SSN</option>
              //               <option value='4'>Mobile Number</option>
              //               <option value='5'>Date of Birth</option>
              //               <option value='6'>MRN</option>
              //             </select>
              //           </div>
              //           <div className='col-md-3'>
              //             <select
              //               className='form-select h-30px p-2'
              //               data-control='select2'
              //               data-placeholder='Select an option'
              //               value={row.startWith}
              //               onChange={(e) => {
              //                 const updatedRows = [...rows]
              //                 updatedRows[index].startWith = e.target.value
              //                 setRows(updatedRows)
              //               }}
              //             >
              //               <option value=''>Start With</option>
              //               <option value='1'>Equals To</option>
              //               <option value='2'>Contains</option>
              //             </select>
              //           </div>
              //           <div className='col-md-3'>
              //             <input
              //               type='text'
              //               className='form-control textbox-height'
              //               name=''
              //               placeholder=' '
              //             />
              //           </div>
              //           <div className='col-md-3  '>
              //             {index === 0 ? (
              //               <div className='d-flex gap-2'>

              //                 <button className='primarybtn' onClick={() => handleAddRow()}>
              //                   +
              //                 </button>

              //                 <button
              //                   className='primarybtn'
              //                   onClick={handleShowTable}

              //                 >
              //                   Search
              //                 </button>

              //                 <button
              //                   className='grayButton'
              //                   onClick={handleShowTable}

              //                 >
              //                   Cancel
              //                 </button>


              //               </div>
              //             ) : (
              //               <button

              //                 className='grayButton fw-bold'
              //                 onClick={() => handleDeleteRow(index)}
              //               >
              //                 - 
              //               </button>
              //             )}
              //           </div>
              //         </div>
              //       ))}
              //     </div>
              //     {showTable && ( // Conditionally render the table
              //       <div>
              //         <div>
              //           <input
              //             type='text'
              //             className='form-control textbox-height mb-2'
              //             style={{ width: '30%', float: 'right' }}
              //             placeholder='Search ......'
              //             value={searchPatientQuery}
              //             onChange={(e) => setSearchPatientQuery(e.target.value)}
              //           />
              //         </div>
              //         <br></br>
              //         <table className=' w-100 fullCurve '>
              //           <thead>
              //             <tr className='tableHead'>
              //               <th >

              //               </th>
              //               <th >
              //                 &nbsp;&nbsp; MRN
              //               </th>
              //               <th >Name</th>
              //               <th >Phone</th>
              //               <th >Date of Birth</th>
              //               <th >Sex</th>
              //               <th >
              //                 Primary insurance
              //               </th>
              //             </tr>
              //           </thead>
              //           <tbody>
              //             {currentPatientData.map((patient, index) => (
              //               <tr key={index} className='tableBody' >
              //                 <td className='px-2'><button className='blueButtonSmall'><i className ="fa-solid fa-arrow-pointer"></i>  Select</button></td>
              //                 <td>&nbsp;&nbsp;&nbsp;&nbsp;{patient.id}</td>
              //                 <td>{patient.lastName + ' ' + patient.firstName}</td>
              //                 <td>{patient.phone}</td>
              //                 <td>{patient.dateOfBirth}</td>
              //                 <td>{patient.sex}</td>
              //                 <td>{patient.primaryInsurance}</td>
              //               </tr>
              //             ))}
              //             {currentPatientData.length === 0 && (
              //               <tr >
              //                 <td colSpan={6} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
              //                   Record not found
              //                 </td>
              //               </tr>
              //             )}
              //           </tbody>
              //         </table>

              //         {/* Pagination */}
              //         <div className='pagination-container mt-3'>
              //           <ReactPaginate
              //             pageCount={Math.ceil(patientData.length / patientsPerPage)}
              //             pageRangeDisplayed={5}
              //             marginPagesDisplayed={1}
              //             onPageChange={handlePageChange}
              //             containerClassName='pagination'
              //             activeClassName='active'
              //             previousLabel='<' // Set the label for the "Previous" button to "<"
              //             nextLabel='>'
              //           />
              //         </div>

              //         <div className='showing-count'>
              //           <p>
              //             Showing {indexOfFirstPatient + 1} to{' '}
              //             {Math.min(indexOfLastPatient, patientData.length)} of {patientData.length}{' '}
              //             entries
              //           </p>
              //         </div>
              //       </div>
              //     )}
              //   </Modal.Body>

              //   <Modal.Footer className='model-footer-color'>
              //     <Button className='btn-sm btn-search-form' onClick={openAddNewPatient}>
              //       Add New Patient
              //     </Button>
              //     <Button className='btn-sm btn-search-form' onClick={handleClosePopup}>
              //       Close
              //     </Button>
              //   </Modal.Footer>
              // </Modal>
              <PatientSearchInApp
                open={showPopup}
                getThePatient={getThePatient}
                closePatientSearch={handleClosePopup}
              />
            )}

            {/* Popup */}
            {showHealthPlanClickPopup && (
              <AddInsurance
                open={showHealthPlanClickPopup}
                closeInsurance={handleCloseHealthPlanPopup}
                patientId={patientId}
              />
            )}

            {showVisitReasonClickPopup && (

              <Modal show={showVisitReasonClickPopup} onHide={handleCloseVisitResonPopup} size='lg'>
                <Modal.Header closeButton>
                  <Modal.Title>Reason For Visit List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <input
                      type='text'
                      className='form-control textbox-height'
                      style={{ width: '30%', float: 'right' }}
                      placeholder='Search ...'
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                  <br></br>
                  <div>
                    <table className='table table-margin'>
                      <thead>
                        <tr>
                          <th style={{ backgroundColor: '#1B283F', color: 'white', width: '5%' }}>
                            &nbsp;&nbsp;
                          </th>
                          <th style={{ backgroundColor: '#1B283F', color: 'white', width: '20%' }}>
                            Reason for visit
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {currentVisitReason.map((visitReason, index) => (
                          <tr key={index}>
                            <td className='datatable-cell '>
                              <span className='label font-weight-bold label-lg  label-light-danger label-inline '>
                                <label className='search-btn-payer'>
                                  <i className='fa fa-link icon-color' aria-hidden='true'></i>
                                  &nbsp;&nbsp;Select
                                </label>
                              </span>
                            </td>
                            <td>{visitReason.reasonForVisit}</td>
                          </tr>
                        ))} */}

                        {/* {currentVisitReason.length === 0 && (
                          <tr>
                            <td colSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                              Record not found 
                            </td>
                          </tr>
                        )} */}
                      </tbody>
                    </table>

                    {/* Pagination */}
                    <div className='pagination-container'>
                      <ReactPaginate
                        pageCount={Math.ceil(visitReasonData.length / visitReasonPerPage)}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={1}
                        onPageChange={handlePageChange}
                        containerClassName='pagination'
                        activeClassName='active'
                        previousLabel='<' // Set the label for the "Previous" button to "<"
                        nextLabel='>'
                      />
                    </div>

                    <div className='showing-count'>
                      <p>
                        {/* Showing {indexOfFirstVisitReason + 1} to{' '}
                        {Math.min(indexOfLastVisitReason, visitReasonData.length)} of{' '}
                        {visitReasonData.length} entries */}
                      </p>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            )}

            {/* {showAddNewPatientClickPopup && (
      
                  <AddNewPatient open={showAddNewPatientClickPopup}  closeAddNewPatient={handleCloseAddNewPatientPopup} />
           
            )} */}


            {confirmAppointmentPop && (
              <Modal show={confirmAppointmentPop}
                centered
                size='lg'
              >
                <Modal.Header className=' dialoge px-4 pt-3 pb-1 '>

                  <div
                    className='title-header'>
                    Appointment Confirmation
                    {/* <i className="fas fa-check-circle " style={{color : 'green' , fontSize:'30px' }}></i>  */}
                  </div>

                </Modal.Header>
                <Modal.Body className='dialoge m-0 p-2'>

                  <div className='card p-4'>
                    <div className='mb-2'>

                      <span className='normalLater'>
                        {firstName} {lastName}, your appointment has been confirmed on
                      </span>
                    </div>
                    <div>
                      <span className='title-header'>
                        {date(appointmentDate)} | {appointmentTime}
                      </span>
                    </div>
                  </div>

                </Modal.Body>
                <Modal.Footer
                  className='dialoge p-1'
                ></Modal.Footer>

              </Modal>
            )}

            {
              editAppointmentPopup && (
                <>
                  <EditAppointment
                    open={editAppointmentPopup}
                    closeEditAppointment={handleClosePopup}
                    editAppointmentId={editAppointmentId}
                    editAppPatientId={editappPatientId}
                  />
                </>
              )}


            <ToastContainer />

          </Container>
          :
          <AppointmentSearch screenSwitch={switchScreen} />
      }

    </>

  )
}
