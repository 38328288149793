import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';

const EditChiefComplain = ({ onShow, onHide, editId }) => {
    const APIkey = process.env.REACT_APP_SERVERAPI || '';

    const [chiefComplaintName, setChiefComplaintName] = useState('');
    const [notes, setNotes] = useState('');

    const handleCloseEditModal = () => {
        setChiefComplaintName('');
        setNotes('');
        onHide();
    };

    useEffect(() => {
        const fetchEditData = async () => {
            try {
                const getLoginInfo = localStorage.getItem('appLoginInfo');
                console.log("getLoginInfo", getLoginInfo);
                if (getLoginInfo !== null) {
                    const { data: { accessToken } } = JSON.parse(getLoginInfo);
                    const headers = { 'x-access-token': accessToken };
                    const response = await axios.get(
                        `${APIkey}/api/admin/getPreferredChiefComplaintById/${editId}`,
                        { headers }
                    );
                    const data = response.data.data;
                    setChiefComplaintName(data.chiefComplaintName);
                    setNotes(data.notes);
                } else {
                    console.log('No login info found in localStorage');
                }
            } catch (error) {
                console.error('Error in fetching data:', error);
            }
        };

        if (editId) {
            fetchEditData();
        }
    }, [editId]);

    const handleUpdate = async () => {
        try {
            const getLoginInfo = localStorage.getItem('appLoginInfo');
            if (getLoginInfo !== null) {
                const { accessToken } = JSON.parse(getLoginInfo).data;
                const headers = { 'x-access-token': accessToken };

                const response = await axios.post(
                    `${APIkey}/api/admin/updatePreferredChiefComplaint/${editId}`,
                    { notes },  
                    { headers }
                );
                console.log('Updated Data:', response.data.data);
                handleCloseEditModal();
            } else {
                console.error('No login info found in localStorage');
            }
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    return (
        <>
            <Modal show={onShow} onHide={handleCloseEditModal} size='lg' centered>
                <Modal.Header closeButton className='h-25px dialoge align-items-center pb-3'>
                    <div className='PageHeaderTitle'>Edit Chief Complaint</div>
                </Modal.Header>
                <Modal.Body className='p-2 dialoge'>
                    <div className='dashboard-card col-md-12 p-2'>
                        <div className='row'>
                            <div className='col-md-6 mb-3'>
                                <div className='addvaccinetitle'>Chief Complaint:</div>
                                <input
                                    type='text'
                                    className='SearchInputbox'
                                    value={chiefComplaintName}
                                    readOnly
                                    disabled
                                />
                            </div>
                            <div className='col-md-6 mb-3'>
                                <div className='addvaccinetitle'>Notes:</div>
                                <input
                                    type='text'
                                    className='SearchInputbox'
                                    value={notes}
                                    onChange={(e) => setNotes(e.target.value) }


                                    
                                    
                                />
                            </div>
                        </div>
                        <Modal.Footer>
                        <div className='my-2 d-flex justify-content-end gap-3'>
            <button type='button' className='primarybtnBig' onClick={handleUpdate}>Update</button>
            <button type='button' className='tertiarybig' onClick={handleCloseEditModal}>
              Cancel
            </button>
          </div>
          </Modal.Footer>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EditChiefComplain;
