import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react';

const ViewUserInfo = ({ onshow, onhide, viewId }) => {
  const APIkey = process.env.REACT_APP_SERVERAPI;

  const [npiId, setNpiId] = useState('');
  const [userId, setUserId] = useState('');
  const [username, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [designation, setDesination] = useState('');
  const [role, setRole] = useState('');
  const [speciality, setSpeciality] = useState(''); // Check this value
  const [createdDate, setCreatedDate] = useState(''); // activation date
  const [status, setStatus] = useState('');
  const [contactNumber1, setContactNumber1] = useState(''); // contact
  const [addressLine1, setAddressLine1] = useState(''); // Address
  const [user_profile, setUserprofile] = useState(''); // User profile
  const [fullName, setFullName] = useState(''); // Full name

  useEffect(() => {
    const fetchuserinfodata = async () => {
      try {
        const getLoginInfo = localStorage.getItem('appLoginInfo');

        if (getLoginInfo) {
          const { accessToken, clinicId } = JSON.parse(getLoginInfo).data;
          const headers = { 'x-access-token': accessToken };

          if (clinicId) {
            const response = await axios.get(
              `${APIkey}/api/admin/getUserById/${clinicId}/${viewId}`,
              { headers }
            );

            const data = response.data.data;

            const createdDateRaw = new Date(data.createdDate);
            const formattedDate = createdDateRaw.toLocaleDateString('en-GB');

            setNpiId(data.npiId);
            setUserId(data._id);
            setUserName(data.username);
            setEmail(data.email);
            setDesination(data.designationData?.designation); // Handle object property
            setRole(data.role?.role); // Handle object property
            setSpeciality(data.speciality?.specialityName || ''); // Ensure specialityName is extracted
            setCreatedDate(formattedDate);
            setStatus(data.status === 1 ? 'Active' : 'Inactive');
            setContactNumber1(data.contactNumber1);
            setAddressLine1(data.addressLine1);
            setUserprofile(data.user_profile);
            setFullName(`${data.firstName} ${data.lastName}`);
          } else {
            console.log('No clinicId found in localStorage');
          }
        } else {
          console.log('No login info found in localStorage');
        }
      } catch (error) {
        console.log('Error fetching data:', error);
      }
    };

    fetchuserinfodata();
  }, [viewId]);

  const handleCloseModal = () => {
    setNpiId('');
    setUserId('');
    setUserName('');
    setEmail('');
    setDesination('');
    setRole('');
    setSpeciality('');
    setCreatedDate('');
    setStatus('');
    setContactNumber1('');
    setAddressLine1('');

    onhide();
  };

  return (
    <>
      <Modal show={onshow} onHide={handleCloseModal} size="xl" centered>
        <Modal.Header closeButton className="h-25px dialoge align-items-center pb-3">
          <div className="PageHeaderTitle">User Information</div>
        </Modal.Header>
        <Modal.Body className="p-2 dialoge">
          <div className="dashboard-card">
            <div className="col md-12 d-flex">
              <div className="col-md-2 mb-2">
                <label className="viewlabels"></label>
                <img src={user_profile} className="profile-img vaccine-label-data" />
              </div>

              <div className="col-md-2 mb-3">
                <label className="viewlabels">NpiId:</label>
                <div className="vaccine-label-data">{npiId}</div>
                <br />
                <label className="viewlabels">Speciality:</label>
                <div className="vaccine-label-data">{speciality}</div>
                <br />
                <label className="viewlabels">Address:</label>
                <div className="vaccine-label-data">{addressLine1}</div>
              </div>

              <div className="col-md-2 mb-2">
                <label className="viewlabels">Full Name:</label>
                <div className="vaccine-label-data">{fullName}</div>
                <br />
                <label className="viewlabels">Activation date:</label>
                <div className="vaccine-label-data">{createdDate}</div>
              </div>

              <div className="col-md-2 mb-2">
                <label className="viewlabels">Username:</label>
                <div className="vaccine-label-data">{username}</div>
                <br />
                <label className="viewlabels">Status:</label>
                <div className="vaccine-label-data">{status}</div>
              </div>

              <div className="col-md-2 mb-2">
                <label className="viewlabels">Designation:</label>
                <div className="vaccine-label-data">{designation}</div>
                <br />
                <label className="viewlabels">Email:</label>
                <div className="vaccine-label-data">{email}</div>
              </div>

              <div className="col-md-2 mb-2">
                <label className="viewlabels">Role:</label>
                <div className="vaccine-label-data">{role}</div>
                <br />
                <label className="viewlabels">Contact:</label>
                <div className="vaccine-label-data">{contactNumber1}</div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="my-2 d-flex justify-content-end gap-3">
            <button type="button" className="tertiarybig" onClick={handleCloseModal}>
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewUserInfo;
