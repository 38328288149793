// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const APIkey = process.env.REACT_APP_SERVERAPI;

// const ClinicalTime = () => {
//   const [activeTab, setActiveTab] = useState('clinicTime');
//   const [timeFormat, setTimeFormat] = useState('12hr');
//   const [timeZone, setTimeZone] = useState('(GMT+05:30)Chicago (GMT-6), United States Time');
//   const [clinicTimes, setClinicTimes] = useState({});
//   const [userTimes, setUserTimes] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [userOptions, setUserOptions] = useState([]);
//   const [selectedUser, setSelectedUser] = useState('');

//   interface User {
//     id: string;
//     name: string;
//   }

//   useEffect(() => {
//     const fetchclinictimeData = async () => {
//       try {
//         const getloginInfo = localStorage.getItem('appLoginInfo');
//         if (getloginInfo) {
//           const { data: { accessToken, clinicId } } = JSON.parse(getloginInfo);

//           const header = {
//             headers: { 'x-access-token': accessToken },
//           };

//           const response = await axios.get(`${APIkey}/api/admin/getTimmingList`, header);
//           const { data } = response.data;

//           // Process the response data
//           if (data && data.length > 0) {
//             const times = data[0].time || {};
//             const defaultTimes = {
//               sun: { from: '', to: '' },
//               mon: { from: '', to: '' },
//               tue: { from: '', to: '' },
//               wed: { from: '', to: '' },
//               thu: { from: '', to: '' },
//               fri: { from: '', to: '' },
//               sat: { from: '', to: '' }
//             };

//             // Combine fetched times with default times
//             setClinicTimes({ ...defaultTimes, ...times });
//           }
//           setLoading(false);
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//         setLoading(false);
//       }
//     };
//     const fetchUsertimeData = async () => {
//       try {
//         const getloginInfo = localStorage.getItem('appLoginInfo');
//         if (getloginInfo) {
//           const { data: { accessToken, clinicId } } = JSON.parse(getloginInfo);

//           const header = {
//             headers: { 'x-access-token': accessToken },
//           };

//           const response = await axios.get(`${APIkey}/api/admin/getTimmingList`, header);
//           const { data } = response.data;

//           // Process the response data
//           if (data && data.length > 0) {
//             const times = data[0].time || {};
//             const defaultTimes = {
//               sun: { from: '', to: '' },
//               mon: { from: '', to: '' },
//               tue: { from: '', to: '' },
//               wed: { from: '', to: '' },
//               thu: { from: '', to: '' },
//               fri: { from: '', to: '' },
//               sat: { from: '', to: '' }
//             };

//             // Combine fetched times with default times
//             setUserTimes({ ...defaultTimes, ...times });
//           }
//           setLoading(false);
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//         setLoading(false);
//       }
//     };

//     fetchclinictimeData();
//     fetchUsertimeData();
//   }, []);

//   useEffect(() => {
//     const fetchUserOptions = async () => {
//       try {
//         const getloginInfo = localStorage.getItem('appLoginInfo');
//         if (getloginInfo) {
//           const { accessToken } = JSON.parse(getloginInfo).data;

//           const header = {
//             headers: { 'x-access-token': accessToken },
//           };

//           const response = await axios.get(`${APIkey}/api/admin/getTimmingById/user/66b21b8b877efe16b828868a`, header);
//           setUserOptions(response.data.data); // Assuming the API returns a data array
//         }
//       } catch (error) {
//         console.error('Error fetching user options:', error);
//       }
//     };

//     fetchUserOptions();
//   }, []);

//   const saveData = async () => {
//     try {
//       const getloginInfo = localStorage.getItem('appLoginInfo');
//       if (getloginInfo) {
//         const { data: { accessToken, clinicId } } = JSON.parse(getloginInfo);

//         const header = {
//           headers: { 'x-access-token': accessToken },
//         };

//         await axios.post(`${APIkey}/api/admin/saveTimmingList/${clinicId}`, {
//           clinicTimes,
//           timeFormat,
//           timeZone,
//         }, header);

//         console.log('Data saved successfully');
//       }
//     } catch (error) {
//       console.error('Error saving data:', error);
//     }
//   };

//   const handleTimeFormatChange = (format) => {
//     setTimeFormat(format);
//   };

//   const handleTimeZoneChange = (event) => {
//     setTimeZone(event.target.value);
//   };

//   const handleClinicTimeChange = (day, field, value) => {
//     setClinicTimes(prev => ({
//       ...prev,
//       [day]: { ...prev[day], [field]: value },
//     }));
//   };

//   const handleClosedChange = (day) => {
//     const isClosed = !clinicTimes[day].from && !clinicTimes[day].to;
//     setClinicTimes(prev => ({
//       ...prev,
//       [day]: isClosed ? { from: '', to: '' } : { from: '7:00', to: '11:00' },
//     }));
//   };

//   const renderClinicTimes = () => (
//     Object.keys(clinicTimes).map(day => (
//       <div key={day} className='day-row'>
//         <div className='day-label'>{day.charAt(0).toUpperCase() + day.slice(1)}</div>
//         <label className='checkbox'>
//           <input
//             type='checkbox'
//             checked={!clinicTimes[day].from && !clinicTimes[day].to}
//             onChange={() => handleClosedChange(day)}
//           />
//           Closed
//         </label>
//         {!(!clinicTimes[day].from && !clinicTimes[day].to) && (
//           <>
//             <div className='time-range'>
//               <input
//                 value={clinicTimes[day].from}
//                 onChange={(e) => handleClinicTimeChange(day, 'from', e.target.value)}
//                 placeholder='Start Time'
//               />
//               <select
//                 value={clinicTimes[day].startPeriod || 'AM'}
//                 onChange={(e) => handleClinicTimeChange(day, 'startPeriod', e.target.value)}
//               >
//                 <option value='AM'>AM</option>
//                 <option value='PM'>PM</option>
//               </select>
//             </div>
//             <div className='time-range'>
//               <input
//                 value={clinicTimes[day].to}
//                 onChange={(e) => handleClinicTimeChange(day, 'to', e.target.value)}
//                 placeholder='End Time'
//               />
//               <select
//                 value={clinicTimes[day].endPeriod || 'PM'}
//                 onChange={(e) => handleClinicTimeChange(day, 'endPeriod', e.target.value)}
//               >
//                 <option value='AM'>AM</option>
//                 <option value='PM'>PM</option>
//               </select>
//             </div>
//           </>
//         )}
//       </div>
//     ))
//   );

//   return (
//     <>
//       <div className='clinical-time-container'>
//         <div className='tab-header'>
//           <div className='tabNameStyle'>
//             <div
//               className={activeTab === 'clinicTime' ? 'activeTab' : 'inactiveTab'}
//               onClick={() => setActiveTab('clinicTime')}
//             >
//               <div className='tabname'>Clinic Time</div>
//             </div>
//             <div
//               className={activeTab === 'userTime' ? 'activeTab' : 'inactiveTab'}
//               onClick={() => setActiveTab('userTime')}
//             >
//               <div className='tabname'>User Time</div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className='clinical-time-container mt-2'>
// {activeTab === 'clinicTime' && (
//   <div className='clinic-time-content'>
//     <div className='clinical-time-header'>Clinic Time</div>
//     <div className='form-group-time'>
//       <label className='timeformat'>Time Format:</label>
//       <div className='radio-group form-group-time'>
//         <label>
//           <input
//             type='radio'
//             name='timeFormat'
//             checked={timeFormat === '12hr'}
//             onChange={() => handleTimeFormatChange('12hr')}
//           />
//           12hr
//         </label>
//         <label>
//           <input
//             type='radio'
//             name='timeFormat'
//             checked={timeFormat === '24hr'}
//             onChange={() => handleTimeFormatChange('24hr')}
//           />
//           24hr
//         </label>
//       </div>
//       <div className='form-group-time ps-5'>
//         <label className='timeformat'>Time Zone:</label>
//         <select
//           value={timeZone}
//           onChange={handleTimeZoneChange}
//           className='time-zone-select'
//         >
//           <option value='(GMT+05:30)Chicago (GMT-6), United States Time'>
//             (GMT+05:30)Chicago (GMT-6), United States Time
//           </option>
//           {/* Add other time zone options if needed */}
//         </select>
//       </div>
//     </div>

//     <div className='form-group'>
//       <label className='timeformat mb-3'>Opening Days & Time of Clinic:</label>
//       <div className='day-rows'>
//         {loading ? (
//           <p>Loading...</p>
//         ) : (
//           renderClinicTimes()
//         )}
//       </div>
//     </div>
//     <div className='d-flex justify-content-end'>
//       <button className='primarybtnBig' onClick={saveData}>
//         Save
//       </button>
//     </div>
//   </div>
// )}
//      {activeTab === 'userTime' && (
//       <div className='clinic-time-content'>
//         <div className='clinical-time-header'>User Time</div>
//         <label className='timeformat'>User:</label>
//        <div>
//   <label htmlFor="userSelect">Select Name:</label>
//   <select
//     id="userSelect"
//     value={selectedUser}
//     onChange={(e) => setSelectedUser(e.target.value)}
//   >
//     <option value="">Select Name</option>
//     {userOptions.map((user) => (
//       <option key={user.id} value={user.id}>
//         {user.name}
//       </option>
//     ))}
//   </select>
// </div>
//         <div className='form-group-time'>
//           <label className='timeformat'>Time Format:</label>
//           <div className='radio-group form-group-time'>
//             <label>
//               <input
//                 type='radio'
//                 name='timeFormat'
//                 checked={timeFormat === '12hr'}
//                 onChange={() => handleTimeFormatChange('12hr')}
//               />
//               12hr
//             </label>
//             <label>
//               <input
//                 type='radio'
//                 name='timeFormat'
//                 checked={timeFormat === '24hr'}
//                 onChange={() => handleTimeFormatChange('24hr')}
//               />
//               24hr
//             </label>
//           </div>
//           <div className='form-group-time ps-5'>
//             <label className='timeformat'>Time Zone:</label>
//             <select
//               value={timeZone}
//               onChange={handleTimeZoneChange}
//               className='time-zone-select'
//             >
//               <option value='(GMT+05:30)Chicago (GMT-6), United States Time'>
//                 (GMT+05:30)Chicago (GMT-6), United States Time
//               </option>
//             </select>
//           </div>
//         </div>

//         <div className='form-group'>
//           <label className='timeformat mb-3'>Opening Days & Time of Clinic:</label>
//           <div className='day-rows'>
//             {Object.keys(clinicTimes).map((day) => (
//               <div key={day} className='day-row'>
//                 <div className='day-label'>{day}</div>
//                 <label className='closed-checkbox'>
//                   <input
//                     type='checkbox'
//                     checked={clinicTimes[day].closed}
//                     onChange={() =>
//                       handleClinicTimeChange(day, 'closed', !clinicTimes[day].closed)
//                     }
//                   />
//                   closed
//                 </label>
//                 {!clinicTimes[day].closed && (
//                   <>
//                     <div className='time-range'>
//                       <input
//                         value={clinicTimes[day].start}
//                         onChange={(e) => handleClinicTimeChange(day, 'start', e.target.value)}
//                       />
//                       <select
//                         value={clinicTimes[day].startPeriod}
//                         onChange={(e) =>
//                           handleClinicTimeChange(day, 'startPeriod', e.target.value)
//                         }
//                       >
//                         <option value='AM'>AM</option>
//                         <option value='PM'>PM</option>
//                       </select>
//                     </div>
//                     <div className='time-range'>
//                       <input
//                         value={clinicTimes[day].end}
//                         onChange={(e) => handleClinicTimeChange(day, 'end', e.target.value)}
//                       />
//                       <select
//                         value={clinicTimes[day].endPeriod}
//                         onChange={(e) =>
//                           handleClinicTimeChange(day, 'endPeriod', e.target.value)
//                         }
//                       >
//                         <option value='AM'>AM</option>
//                         <option value='PM'>PM</option>
//                       </select>
//                     </div>
//                   </>
//                 )}
//               </div>
//             ))}
//           </div>
//         </div>
//         <div className='d-flex justify-content-end'>
//           <button className='primarybtnBig' onClick={saveData}>
//             Save
//           </button>
//         </div>
//       </div>
//     )}
//   </div>
//     {activeTab === 'userTime' && (
//   <div className='clinical-time-container mt-2'>
//     <div className='tab-header'>
//       <table className='user-time-table'>
//         <thead>
//           <tr>
//             <th>Sr. No</th>
//             <th>User</th>
//             <th>Working Day</th>
//             <th>Start Time</th>
//             <th>End Time</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td>1</td>
//             <td>John Doe</td>
//             <td>Monday</td>
//             <td>08:00 AM</td>
//             <td>04:00 PM</td>
//           </tr>
//           <tr>
//             <td>2</td>
//             <td>Jane Smith</td>
//             <td>Tuesday</td>
//             <td>09:00 AM</td>
//             <td>05:00 PM</td>
//           </tr>
//         </tbody>
//       </table>
//     </div>
//   </div>
// )}
//     </>
//   );
// };

// export default ClinicalTime;

import React, {useState, useEffect} from 'react'
import axios from 'axios'
// import edit from '../EHRIcon/edit.svg'
import CommonTable from '../../../ReUsableComponent/CommonTable'
import tablecolor from '../../../ReUsableComponent/ReyClinicalinputs.json'

const APIkey = process.env.REACT_APP_SERVERAPI

interface ClinicTime {
  from: string
  to: string
  startPeriod?: string
  endPeriod?: string
  closed?: boolean
}

interface User {
  firstName: string
  userId: '' | null | undefined
  id: string
  name: string
}

const ClinicalTime = () => {
  const [activeTab, setActiveTab] = useState('clinicTime')
  const [timeFormat, setTimeFormat] = useState('12hr')
  const [timeZone, setTimeZone] = useState('(GMT+05:30)Chicago (GMT-6), United States Time')
  const [clinicTimes, setClinicTimes] = useState<Record<string, ClinicTime>>({})
  const [userTimes, setUserTimes] = useState<Record<string, ClinicTime>>({})
  const [loading, setLoading] = useState(true)
  const [userOptions, setUserOptions] = useState<User[]>([])
  const [selectedUser, setSelectedUser] = useState('')
  const [totalCount, setTotalCount] = useState(0); // For pagination
  const [perPage, setPerPage] = useState(10); // Items per page
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchClinicTimeData = async () => {
      try {
        const getLoginInfo = localStorage.getItem('appLoginInfo')
        if (getLoginInfo) {
          const {
            data: {accessToken, clinicId},
          } = JSON.parse(getLoginInfo)

          const header = {
            headers: {'x-access-token': accessToken},
          }

          const response = await axios.get(`${APIkey}/api/admin/getTimmingList`, header)
          const {data} = response.data

          if (data && data.length > 0) {
            const times = data[0].time || {}
            const defaultTimes: Record<string, ClinicTime> = {
              sun: {from: '', to: ''},
              mon: {from: '', to: ''},
              tue: {from: '', to: ''},
              wed: {from: '', to: ''},
              thu: {from: '', to: ''},
              fri: {from: '', to: ''},
              sat: {from: '', to: ''},
            }

            setClinicTimes({...defaultTimes, ...times})
          }
          setLoading(false)
        }
      } catch (error) {
        console.error('Error fetching clinic time data:', error)
        setLoading(false)
      }
    }

    const fetchUserTimeData = async () => {
      try {
        const getLoginInfo = localStorage.getItem('appLoginInfo')
        if (getLoginInfo) {
          const {
            data: {accessToken, clinicId},
          } = JSON.parse(getLoginInfo)

          const header = {
            headers: {'x-access-token': accessToken},
          }

          const response = await axios.get(`${APIkey}/api/admin/getTimmingList/user`, header)
          const {data} = response.data

          if (data && data.length > 0) {
            const times = data[0].time || {}
            const defaultTimes: Record<string, ClinicTime> = {
              sun: {from: '', to: ''},
              mon: {from: '', to: ''},
              tue: {from: '', to: ''},
              wed: {from: '', to: ''},
              thu: {from: '', to: ''},
              fri: {from: '', to: ''},
              sat: {from: '', to: ''},
            }

            setUserTimes({...defaultTimes, ...times})
          }
          setLoading(false)
        }
      } catch (error) {
        console.error('Error fetching user time data:', error)
        setLoading(false)
      }
    }

    const fetchUserOptions = async () => {
      try {
        const getLoginInfo = localStorage.getItem('appLoginInfo')
        if (getLoginInfo) {
          const {accessToken, clinicId} = JSON.parse(getLoginInfo).data

          const header = {
            headers: {'x-access-token': accessToken},
          }

          const response = await axios.get(
            `${APIkey}}/api/admin/getTimmingById/user/${clinicId}`,
            header
          )
          setUserOptions(response.data.data)
        }
      } catch (error) {
        console.error('Error fetching user options:', error)
      }
    }

    fetchClinicTimeData()
    fetchUserTimeData()
    fetchUserOptions()
  }, [])

  const saveData = async () => {
    try {
      const getLoginInfo = localStorage.getItem('appLoginInfo')
      if (getLoginInfo) {
        const {
          data: {accessToken, _id},
        } = JSON.parse(getLoginInfo)

        const header = {
          headers: {'x-access-token': accessToken},
        }

        await axios.post(
          `${APIkey}/api/admin/saveTimmingList/${_id}`,
          {
            clinicTimes,
            timeFormat,
            timeZone,
          },
          header
        )

        console.log('Data saved successfully')
      }
    } catch (error) {
      console.error('Error saving data:', error)
    }
  }

  const columns = [
    { key: ['_id'], header: 'Sr. No' },
    { key: ['firstName'], header: 'User' },
    { key: ['day'], header: 'Working Day' },
    { key: ['startTime'], header: 'Start Time' },
    { key: ['endTime'], header: 'End Time' },
    { key: ['_id'], header: 'Action', isAction: true, ActionComponent: ({ id }: { id: string }) => (
      <img 
      // src={edit}
       alt="Edit" style={{ cursor: 'pointer' }} onClick={() => console.log(`Edit ${id}`)} />
    )}
  ];

  const handleTimeFormatChange = (format: string) => {
    setTimeFormat(format)
  }

  const handleTimeZoneChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTimeZone(event.target.value)
  }

  const handleClinicTimeChange = (day: string, field: string, value: string) => {
    setClinicTimes((prev) => ({
      ...prev,
      [day]: {...prev[day], [field]: value},
    }))
  }

  const handleClosedChange = (day: string) => {
    const isClosed = !clinicTimes[day].from && !clinicTimes[day].to
    setClinicTimes((prev) => ({
      ...prev,
      [day]: isClosed ? {from: '', to: ''} : {from: '7:00', to: '11:00'},
    }))
  }

  const renderClinicTimes = () =>
    Object.keys(clinicTimes).map((day) => (
      <div key={day} className='day-row'>
        <div className='day-label'>{day.charAt(0).toUpperCase() + day.slice(1)}</div>
        <label className='checkbox'>
          <input
            type='checkbox'
            checked={!clinicTimes[day].from && !clinicTimes[day].to}
            onChange={() => handleClosedChange(day)}
          />
          Closed
        </label>
        {!(!clinicTimes[day].from && !clinicTimes[day].to) && (
          <>
            <div className='time-range'>
              <input
                value={clinicTimes[day].from}
                onChange={(e) => handleClinicTimeChange(day, 'from', e.target.value)}
                placeholder='Start Time'
              />
              <select
                value={clinicTimes[day].startPeriod || 'AM'}
                onChange={(e) => handleClinicTimeChange(day, 'startPeriod', e.target.value)}
              >
                <option value='AM'>AM</option>
                <option value='PM'>PM</option>
              </select>
            </div>
            <div className='time-range'>
              <input
                value={clinicTimes[day].to}
                onChange={(e) => handleClinicTimeChange(day, 'to', e.target.value)}
                placeholder='End Time'
              />
              <select
                value={clinicTimes[day].endPeriod || 'PM'}
                onChange={(e) => handleClinicTimeChange(day, 'endPeriod', e.target.value)}
              >
                <option value='AM'>AM</option>
                <option value='PM'>PM</option>
              </select>
            </div>
          </>
        )}
      </div>
    ))

  return (
<>
      <div className='clinical-time-container'>
        <div className='tab-header'>
          <div className='tabNameStyle'>
            <div
              className={activeTab === 'clinicTime' ? 'activeTab' : 'inactiveTab'}
              onClick={() => setActiveTab('clinicTime')}
            >
              <div className='tabname'>Clinic Time</div>
            </div>
            <div
              className={activeTab === 'userTime' ? 'activeTab' : 'inactiveTab'}
              onClick={() => setActiveTab('userTime')}
            >
              <div className='tabname'>User Time</div>
            </div>
          </div>
        </div>
      </div>

      <div className='clinical-time-container mt-2'>
        {activeTab === 'clinicTime' && (
          <div className='clinic-time-content'>
            <div className='clinical-time-header'>Clinic Time</div>
            <div className='form-group-time'>
              <label className='timeformat'>Time Format:</label>
              <div className='radio-group form-group-time'>
                <label>
                  <input
                    type='radio'
                    name='timeFormat'
                    checked={timeFormat === '12hr'}
                    onChange={() => handleTimeFormatChange('12hr')}
                  />
                  12hr
                </label>
                <label>
                  <input
                    type='radio'
                    name='timeFormat'
                    checked={timeFormat === '24hr'}
                    onChange={() => handleTimeFormatChange('24hr')}
                  />
                  24hr
                </label>
              </div>
              <div className='form-group-time ps-5'>
                <label className='timeformat'>Time Zone:</label>
                <select
                  value={timeZone}
                  onChange={handleTimeZoneChange}
                  className='time-zone-select'
                >
                  <option value='(GMT+05:30)Chicago (GMT-6), United States Time'>
                    (GMT+05:30)Chicago (GMT-6), United States Time
                  </option>
                  {/* Add other time zone options if needed */}
                </select>
              </div>
            </div>

            <div className='form-group'>
              <label className='timeformat mb-3'>Opening Days & Time of Clinic:</label>
              <div className='day-rows'>{loading ? <p>Loading...</p> : renderClinicTimes()}</div>
            </div>
            <div className='d-flex justify-content-end'>
              <button className='primarybtnBig' onClick={saveData}>
                Save
              </button>
            </div>
          </div>
        )}
        {activeTab === 'userTime' && (
          <div className='user-time-content'>
            <div className='user-time-header'>User Time</div>
            <div className='form-group-time'>
              <label className='user-selection-label'>Select User:</label>
              <select
                className='user-selection-dropdown'
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
              >
                {userOptions.map((user) => (
                  <option key={user.userId}>{user.firstName}</option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>
      {activeTab === 'userTime' && (
        <div className='clinical-time-container mt-2'>
          <div className='tab-header'>
          <CommonTable
              data={userOptions}
              totalCount={totalCount}
              perPage={perPage}
              setPerPage={setPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              columns={columns}
              searchQuery={searchQuery}
              setQuery={setSearchQuery}
              onRowClick={(id) => console.log(`Row clicked: ${id}`)}
              headerColor={tablecolor.primaryTable.color}
                issetPaginationCompo = {false}
                isPagelength={false}
                isSearched={false}
            />
            {/* <table className='user-time-table'>
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>User</th>
                  <th>Working Day</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>John Doe</td>
                  <td>Monday</td>
                  <td>08:00 AM</td>
                  <td>04:00 PM</td>
                  <td>
                    <img src={edit} />
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Jane Smith</td>
                  <td>Tuesday</td>
                  <td>09:00 AM</td>
                  <td>05:00 PM</td>
                  <td>
                    <img src={edit} />
                  </td>
                </tr>
              </tbody>
            </table> */}
          </div>
        </div>
      )}
    </>
  )
}

export default ClinicalTime

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import CommonTable from '../../../ReUsableComponent/CommonTable';

// const APIkey = process.env.REACT_APP_SERVERAPI;

// const ClinicalTime = () => {
//   const [activeTab, setActiveTab] = useState('clinicTime');
//   const [timeFormat, setTimeFormat] = useState('12hr');
//   const [timeZone, setTimeZone] = useState('(GMT+05:30)Chicago (GMT-6), United States Time');
//   const [clinicTimes, setClinicTimes] = useState({});
//   const [userTimeData, setUserTimeData] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const getloginInfo = localStorage.getItem('appLoginInfo');
//         if (getloginInfo) {
//           const { data: { accessToken, clinicId } } = JSON.parse(getloginInfo);

//           const header = {
//             headers: { 'x-access-token': accessToken },
//           };

//           // Fetch clinic times
//           const clinicResponse = await axios.get(`${APIkey}/api/admin/getTimmingList`, header);
//           const clinicData = clinicResponse.data.data;

//           // Process the response data
//           if (clinicData && clinicData.length > 0) {
//             const times = clinicData[0].time || {};
//             const defaultTimes = {
//               sun: { from: '', to: '' },
//               mon: { from: '', to: '' },
//               tue: { from: '', to: '' },
//               wed: { from: '', to: '' },
//               thu: { from: '', to: '' },
//               fri: { from: '', to: '' },
//               sat: { from: '', to: '' }
//             };
//             setClinicTimes({ ...defaultTimes, ...times });
//           }

//           // Fetch user times
//           const userResponse = await axios.get(`${APIkey}/api/admin/getTimmingList/user`, header);
//           setUserTimeData(userResponse.data.data);

//           setLoading(false);
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   const saveData = async () => {
//     try {
//       const getloginInfo = localStorage.getItem('appLoginInfo');
//       if (getloginInfo) {
//         const { data: { accessToken, clinicId } } = JSON.parse(getloginInfo);

//         const header = {
//           headers: { 'x-access-token': accessToken },
//         };

//         await axios.post(`${APIkey}/api/admin/saveTimmingList/${clinicId}`, {
//           clinicTimes,
//           timeFormat,
//           timeZone,
//         }, header);

//         console.log('Data saved successfully');
//       }
//     } catch (error) {
//       console.error('Error saving data:', error);
//     }
//   };

//   const handleTimeFormatChange = (format) => {
//     setTimeFormat(format);
//   };

//   const handleTimeZoneChange = (event) => {
//     setTimeZone(event.target.value);
//   };

//   const handleClinicTimeChange = (day, field, value) => {
//     setClinicTimes(prev => ({
//       ...prev,
//       [day]: { ...prev[day], [field]: value },
//     }));
//   };

//   const handleClosedChange = (day) => {
//     const isClosed = !clinicTimes[day].from && !clinicTimes[day].to;
//     setClinicTimes(prev => ({
//       ...prev,
//       [day]: isClosed ? { from: '', to: '' } : { from: '09:00', to: '17:00' }, // Default time range when not closed
//     }));
//   };

//   const renderClinicTimes = () => (
//     Object.keys(clinicTimes).map(day => (
//       <div key={day} className='day-row'>
//         <div className='day-label'>{day.charAt(0).toUpperCase() + day.slice(1)}</div>
//         <label className='checkbox'>
//           <input
//             type='checkbox'
//             checked={!clinicTimes[day].from && !clinicTimes[day].to}
//             onChange={() => handleClosedChange(day)}
//           />
//           Closed
//         </label>
//         {!(!clinicTimes[day].from && !clinicTimes[day].to) && (
//           <>
//             <div className='time-range'>
//               <input
//                 value={clinicTimes[day].from}
//                 onChange={(e) => handleClinicTimeChange(day, 'from', e.target.value)}
//                 placeholder='Start Time'
//               />
//               <select
//                 value={clinicTimes[day].startPeriod || 'AM'}
//                 onChange={(e) => handleClinicTimeChange(day, 'startPeriod', e.target.value)}
//               >
//                 <option value='AM'>AM</option>
//                 <option value='PM'>PM</option>
//               </select>
//             </div>
//             <div className='time-range'>
//               <input
//                 value={clinicTimes[day].to}
//                 onChange={(e) => handleClinicTimeChange(day, 'to', e.target.value)}
//                 placeholder='End Time'
//               />
//               <select
//                 value={clinicTimes[day].endPeriod || 'PM'}
//                 onChange={(e) => handleClinicTimeChange(day, 'endPeriod', e.target.value)}
//               >
//                 <option value='AM'>AM</option>
//                 <option value='PM'>PM</option>
//               </select>
//             </div>
//           </>
//         )}
//       </div>
//     ))
//   );

//   const userColumns = [
//     { title: 'Sr. No', field: 'id' },
//     { title: 'User', field: 'name' },
//     { title: 'Working Day', field: 'day' },
//     { title: 'Start Time', field: 'start' },
//     { title: 'End Time', field: 'end' }
//   ];

//   return (
//     <>
//       <div className='clinical-time-container'>
//         <div className='tab-header'>
//           <div className='tabNameStyle'>
//             <div
//               className={activeTab === 'clinicTime' ? 'activeTab' : 'inactiveTab'}
//               onClick={() => setActiveTab('clinicTime')}
//             >
//               <div className='tabname'>Clinic Time</div>
//             </div>
//             <div
//               className={activeTab === 'userTime' ? 'activeTab' : 'inactiveTab'}
//               onClick={() => setActiveTab('userTime')}
//             >
//               <div className='tabname'>User Time</div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className='clinical-time-container mt-2'>
//         {activeTab === 'clinicTime' && (
//           <div className='clinic-time-content'>
//             <div className='clinical-time-header'>Clinic Time</div>
//             <div className='form-group-time'>
//               <label className='timeformat'>Time Format:</label>
//               <div className='radio-group form-group-time'>
//                 <label>
//                   <input
//                     type='radio'
//                     name='timeFormat'
//                     checked={timeFormat === '12hr'}
//                     onChange={() => handleTimeFormatChange('12hr')}
//                   />
//                   12hr
//                 </label>
//                 <label>
//                   <input
//                     type='radio'
//                     name='timeFormat'
//                     checked={timeFormat === '24hr'}
//                     onChange={() => handleTimeFormatChange('24hr')}
//                   />
//                   24hr
//                 </label>
//               </div>
//               <div className='form-group-time ps-5'>
//                 <label className='timeformat'>Time Zone:</label>
//                 <select
//                   value={timeZone}
//                   onChange={handleTimeZoneChange}
//                   className='time-zone-select'
//                 >
//                   <option value='(GMT+05:30)Chicago (GMT-6), United States Time'>
//                     (GMT+05:30)Chicago (GMT-6), United States Time
//                   </option>
//                   {/* Add other time zone options if needed */}
//                 </select>
//               </div>
//             </div>

//             <div className='form-group'>
//               <label className='timeformat mb-3'>Opening Days & Time of Clinic:</label>
//               <div className='day-rows'>
//                 {loading ? (
//                   <p>Loading...</p>
//                 ) : (
//                   renderClinicTimes()
//                 )}
//               </div>
//             </div>
//             <div className='d-flex justify-content-end'>
//               <button className='primarybtnBig' onClick={saveData}>
//                 Save
//               </button>
//             </div>
//           </div>
//         )}

//         {activeTab === 'userTime' && (
//           <div className='clinic-time-content'>
//             <div className='clinical-time-header'>User Time</div>
//             <label className='timeformat'>User:</label>
//             <select>
//               <option>Select Name</option>
//               {/* Populate options dynamically if needed */}
//             </select>
//             <div className='form-group-time'>
//               <label className='timeformat'>Time Format:</label>
//               <div className='radio-group form-group-time'>
//                 <label>
//                   <input
//                     type='radio'
//                     name='timeFormat'
//                     checked={timeFormat === '12hr'}
//                     onChange={() => handleTimeFormatChange('12hr')}
//                   />
//                   12hr
//                 </label>
//                 <label>
//                   <input
//                     type='radio'
//                     name='timeFormat'
//                     checked={timeFormat === '24hr'}
//                     onChange={() => handleTimeFormatChange('24hr')}
//                   />
//                   24hr
//                 </label>
//               </div>
//               <div className='form-group-time ps-5'>
//                 <label className='timeformat'>Time Zone:</label>
//                 <select
//                   value={timeZone}
//                   onChange={handleTimeZoneChange}
//                   className='time-zone-select'
//                 >
//                   <option value='(GMT+05:30)Chicago (GMT-6), United States Time'>
//                     (GMT+05:30)Chicago (GMT-6), United States Time
//                   </option>
//                 </select>
//               </div>
//             </div>

//             <div className='form-group'>
//               <label className='timeformat mb-3'>User Time Table:</label>
//               <div className='user-time-table'>
//                 {loading ? (
//                   <p>Loading...</p>
//                 ) : (
//                   <CommonTable
//                       columns={[]}
//                       data={userTimeData} totalCount={0} perPage={0} setPerPage={function (perPage: number): void {
//                         throw new Error('Function not implemented.');
//                       } } currentPage={0} setCurrentPage={function (currentPage: number): void {
//                         throw new Error('Function not implemented.');
//                       } } setQuery={function (query: string): void {
//                         throw new Error('Function not implemented.');
//                       } }                  />
//                 )}
//               </div>
//             </div>
//             <div className='d-flex justify-content-end'>
//               <button className='primarybtnBig' onClick={saveData}>
//                 Save
//               </button>
//             </div>
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

// export default ClinicalTime;
