// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Modal from 'react-bootstrap/Modal';
// import { useParams } from 'react-router-dom';

// const EditVaccination = ({ onshow, onHide }) => {
//   const APIkey = process.env.REACT_APP_SERVERAPI || '';

//   const [vaccinename, setVaccineName] = useState('');
//   const [manufacture, setManufacturer] = useState('');
//   const [typeofvaccine, setTypeOfVaccine] = useState('');
//   const [diseasetargeted, setDiseasesTargeted] = useState('');
//   const [dosage, setDosage] = useState('');
//   const [vaccineIdentifier, setVaccineIdentifier] = useState('');
//   const [notes, setNotes] = useState('');
//   const [loading, setLoading] = useState(true);
// const {_id} = useParams();

//   useEffect(() => {
//     const fetchEditData = async () => {
//       setLoading(true);
//       try {
//         const getLoginInfo = localStorage.getItem('apploginInfo');
//         if (getLoginInfo) {
//           const { Token, _id } = JSON.parse(getLoginInfo).data.acessToken;
//           const headers = { 'x-access-token': Token };

//           const response = await axios.get(`${APIkey}/api/admin/getPreferredVaccinationById/${_id}`, { headers });
//           const data = response.data.data;
//           console.log('Fetched data:', response.data.data);

//           setVaccineName(data.vaccineName);
//           setManufacturer(data.manufacturer);
//           setTypeOfVaccine(data.typeOfVaccine);
//           setDiseasesTargeted(data.diseasesTargeted);
//           setDosage(data.dosage);
//           setVaccineIdentifier(data.vaccineIdentifier);
//           setNotes(data.notes);
//         } else {
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchEditData();
//   }, []);

//   const handleCloseModal = () => {
//     setVaccineName('');
//     setManufacturer('');
//     setTypeOfVaccine('');
//     setDiseasesTargeted('');
//     setDosage('');
//     setVaccineIdentifier('');
//     setNotes('');
//     onHide();
//   };

//   const handleUpdate = async (_id) => {
//     setLoading(true);
//     try {
//       const getLoginInfo = localStorage.getItem('apploginInfo');
//       if (getLoginInfo) {
//         const  {Token, _id}  = JSON.parse(getLoginInfo).data.acessToken;
//         const headers = { 'x-access-token': Token };

//         const response = await axios.post(`${APIkey}/api/admin/updatePreferredVaccination/${_id}`, {
//           notes, // Include the fields you want to update
//           headers
//         });

//         console.log('Updated data:', response.data.data);

//         handleCloseModal(); // Close the modal after successful update
//       } else {

//       }
//     } catch (error) {
//       console.log('Error updating data:', error);
//     } finally {
//       setLoading(false); // Ensure loading state is turned off
//     }
//   };

//   return (
//     <Modal show={onshow} onHide={handleCloseModal} size='lg' centered>
//       <Modal.Header className='h-25px dialoge align-items-center pb-3'>
//         <div className='title-header'>Edit Vaccine</div>
//       </Modal.Header>
//       <Modal.Body className='p-2 dialoge'>
//         <div className='clinical-time-container col-md-12 p-2'>
//           <div className='row'>

//             {/* Vaccine Name */}
//             <div className='col-md-3 mb-3'>
//               <div className='addvaccinetitle'>Vaccine Name:</div>
//               <input
//                 type='text'
//                 className='SearchInputbox'
//                 value={vaccinename}
//                 readOnly
//                 disabled
//               />
//             </div>

//             {/* Manufacturer */}
//             <div className='col-md-3 mb-3'>
//               <div className='addvaccinetitle'>Manufacturer:</div>
//               <input
//                 type='text'
//                 className='SearchInputbox'
//                 value={manufacture}
//                 readOnly
//                 disabled
//               />
//             </div>

//             {/* Type of Vaccine */}
//             <div className='col-md-3 mb-3'>
//               <div className='addvaccinetitle'>Type of Vaccine:</div>
//               <input
//                 type='text'
//                 className='SearchInputbox'
//                 value={typeofvaccine}
//                 readOnly
//                 disabled
//               />
//             </div>

//             {/* Diseases Targeted */}
//             <div className='col-md-3 mb-3'>
//               <div className='addvaccinetitle'>Diseases Targeted:</div>
//               <input
//                 type='text'
//                 className='SearchInputbox'
//                 value={diseasetargeted}
//                 readOnly
//                 disabled
//               />
//             </div>

//             {/* Dosage */}
//             <div className='col-md-3 mb-3'>
//               <div className='addvaccinetitle'>Dosage:</div>
//               <input
//                 type='text'
//                 className='SearchInputbox'
//                 value={dosage}
//                 readOnly
//                 disabled
//               />
//             </div>

//             {/* Vaccine Identifier */}
//             <div className='col-md-3 mb-3'>
//               <div className='addvaccinetitle'>Vaccine Identifier:</div>
//               <input
//                 type='text'
//                 className='SearchInputbox'
//                 value={vaccineIdentifier}
//                 readOnly
//                 disabled
//               />
//             </div>

//             {/* Notes */}
//             <div className='col-md-4 mb-3'>
//               <div className='addvaccinetitle'>Note:</div>
//               <input
//                 type='text'
//                 className='SearchInputbox'
//                 value={notes}
//                 onChange={(e) => setNotes(e.target.value)}
//               />
//             </div>
//           </div>

//           {/* Update and Cancel buttons */}
//           <div>
//             <button type='button' className='search-orange-btn' onClick={handleUpdate}>
//               Update
//             </button>
//             <button type='button' className='clear-grey-btn' onClick={handleCloseModal}>
//               Cancel
//             </button>
//           </div>
//         </div>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export default EditVaccination;

import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'
import {useParams} from 'react-router-dom'

const EditVaccination = ({onshow, onHide, editId}) => {
  const APIkey = process.env.REACT_APP_SERVERAPI || ''

  const [vaccinename, setVaccineName] = useState('')
  const [manufacture, setManufacturer] = useState('')
  const [typeofvaccine, setTypeOfVaccine] = useState('')
  const [diseasetargeted, setDiseasesTargeted] = useState('')
  const [dosage, setDosage] = useState('')
  const [vaccineIdentifier, setVaccineIdentifier] = useState('')
  const [notes, setNotes] = useState('')
  const [loading, setLoading] = useState(false)
  const {_id} = useParams() // Ensure _id is from URL params
  

  useEffect(() => {
    const fetchEditData = async () => {
      setLoading(true)
      try {
        const getLoginInfo = localStorage.getItem('appLoginInfo')
        console.log('hey there: ', getLoginInfo)

        if (getLoginInfo !== null) {
          const { data : {accessToken}} = JSON.parse(getLoginInfo) // Corrected property name

          const headers = {'x-access-token': accessToken}

          const response = await axios.get(
            `${APIkey}/api/admin/getPreferredVaccinationById/${editId}`,
            {headers}
          )
          const data = response.data.data

          setVaccineName(data.vaccineName)
          setManufacturer(data.manufacture)
          setTypeOfVaccine(data.typeOfVaccine)
          setDiseasesTargeted(data.diseaseTargeted)
          setDosage(data.dosageSchedule)
          setVaccineIdentifier(data.vaccineIdentifier)
          setNotes(data.notes)
        } else {
          console.error('No login info found in localStorage')
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false)
      }
    }

    if (editId) {
      fetchEditData()
    }
  }, [editId])

  const handleCloseModal = () => {
    setVaccineName('')
    setManufacturer('')
    setTypeOfVaccine('')
    setDiseasesTargeted('')
    setDosage('')
    setVaccineIdentifier('')
    setNotes('')
    onHide()
  }

  const handleUpdate = async () => {
    setLoading(true)
    try {
      const getLoginInfo: any = localStorage.getItem('appLoginInfo')
      if (getLoginInfo !== null) {
        const Token = JSON.parse(getLoginInfo).data.accessToken // Corrected property name
        const headers = {'x-access-token': Token}

        const response = await axios.post(
          `${APIkey}/api/admin/updatePreferredVaccination/${editId}`,
          {notes}, // Ensure to include all fields to update if needed
          {headers}
        )

        console.log('Updated data:', response.data.data)
        handleCloseModal() // Close the modal after successful update
      } else {
        console.error('No login info found in localStorage')
      }
    } catch (error) {
      console.error('Error updating data:', error)
    } finally {
      setLoading(false) // Ensure loading state is turned off
    }
  }

  return (
    <Modal show={onshow} onHide={handleCloseModal} size='lg' centered>
      <Modal.Header closeButton className='h-25px dialoge align-items-center pb-3'>
        <div className='PageHeaderTitle'>Edit Vaccine</div>
      </Modal.Header>
      <Modal.Body className='p-2 dialoge'>
        <div className='dashboard-card col-md-12 p-2'>
          <div className='row'>
            {/* Vaccine Name */}
            <div className='col-md-3 mb-3'>
              <div className='addvaccinetitle'>Vaccine Name:</div>
              <input type='text' className='SearchInputbox' value={vaccinename} readOnly disabled />
            </div>

            {/* Manufacturer */}
            <div className='col-md-3 mb-3'>
              <div className='addvaccinetitle'>Manufacturer:</div>
              <input type='text' className='SearchInputbox' value={manufacture} readOnly disabled />
            </div>

            {/* Type of Vaccine */}
            <div className='col-md-3 mb-3'>
              <div className='addvaccinetitle'>Type of Vaccine:</div>
              <input
                type='text'
                className='SearchInputbox'
                value={typeofvaccine}
                readOnly
                disabled
              />
            </div>

            {/* Diseases Targeted */}
            <div className='col-md-3 mb-3'>
              <div className='addvaccinetitle'>Diseases Targeted:</div>
              <input
                type='text'
                className='SearchInputbox'
                value={diseasetargeted}
                readOnly
                disabled
              />
            </div>

            {/* Dosage */}
            <div className='col-md-3 mb-3'>
              <div className='addvaccinetitle'>Dosage:</div>
              <input type='text' className='SearchInputbox' value={dosage} readOnly disabled />
            </div>

            {/* Vaccine Identifier */}
            <div className='col-md-3 mb-3'>
              <div className='addvaccinetitle'>Vaccine Identifier:</div>
              <input
                type='text'
                className='SearchInputbox'
                value={vaccineIdentifier}
                readOnly
                disabled
              />
            </div>

            {/* Notes */}
            <div className='col-md-4 mb-3'>
              <div className='addvaccinetitle'>Note:</div>
              <input
                type='text'
                className='SearchInputbox'
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </div>
          </div>
          </div>
          {/* Update and Cancel buttons */}
          <div className='my-2 d-flex justify-content-end gap-3'>
            <button
              type='button'
              className='primarybtnBig'
              onClick={handleUpdate}
              disabled={loading}
            >Update
            </button>
            <button type='button' className='tertiarybig' onClick={handleCloseModal}>
              Cancel
            </button>
          </div>
       
      </Modal.Body>
    </Modal>

    
  )







}

export default EditVaccination
