import React from 'react'

function PatientTreatmentPlan() {
    return (
        <>
            <div>
                PatientTreatmentPlan
            </div>
        </>
    )
}

export default PatientTreatmentPlan