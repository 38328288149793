import React, {useEffect, useState} from 'react';
import UpArrow from '../EHRIcon/UpArrow.png';
import DownArrow from '../EHRIcon/DownArrow.png';
import {useNavigate} from 'react-router-dom';
import AddNewPatient from '../Appointment/AddNewPatient';
import axios from 'axios';
import moment from 'moment';
import patientdemo from '../EHRIcon/patientdemo.svg';
import SearchableDropdown from '../ReusablePages/ReusableAppointment/SearchableDropdown';
import SearchableGroup from '../../../ReUsableComponent/SearchableGroup';
import inputText from '../../../ReUsableComponent/ReyClinicalinputs.json';
import MaskedInput from 'react-text-mask';
import GenderComponent from '../../../ReUsableComponent/GenderComponent';
import {ToastContainer} from 'react-toastify';
import PaginationCompo from '../../../ReUsableComponent/PaginationCompo';
import CryptoConverter from '../../../ReUsableComponent/CryptoConverter';
import CommonTable from '../../../ReUsableComponent/CommonTable';
import fluentedit from '../../../ImgesLogos/fluentedit.svg';
import patientChart from '../EHRIcon/patientChart.svg';
import gridicons_dropdown from '../EHRIcon/gridicons_dropdown.svg';
import ToDo from '../EHRIcon/ToDo.svg';
import StickyNotes from '../EHRIcon/StickyNotes.svg';
import Notes from '../EHRIcon/Notes.svg';
import {actions} from 'react-table';
import tablecolor from '../../../ReUsableComponent/ReyClinicalinputs.json';
import TitleComponent from '../../../ReUsableComponent/TitleComponent';
import CommonButton, {
  CommonbigButton,
  CommonPopupButton,
} from '../../../SuperAdmin/Pages/ReausableComponent/CommonButton';

// import Datepicker from '../../../ReUsableComponent/Datepicker';

function PatientSearch(): JSX.Element {
  const APIkey = process.env.REACT_APP_SERVERAPI || '';

  const appointmentId = null;

  const [patientList, setPatientList] = useState<any[]>([]);
  const [patientSearchList, setPatientSearchList] = useState<any>(null);
  // console.log(patientList)
  const [patientCategoryList, setPatientCategoryList] = useState<any[]>([]);
  const [totalsearchPatient, setTotalsearchPatient] = useState<any>();
  const [patientInsuranceList, setPatientInsuranceList] = useState<any[]>([]);
  const [totalPatient, setTotalPatient] = useState<number>(0);
  const [showAddNewPatientClickPopup, setShowAddNewPatientClickPopup] = useState<boolean>(false);
  const [downSearch, setDownSearch] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const navigate = useNavigate();

  const [mrn, setMrn] = useState<any>();
  const [aadhaar, setAadhaar] = useState<any>();
  const [genderId, setSex] = useState<string | null>();
  const [patientFullName, setPatientFullName] = useState<string | null>();
  const [mobileNo, setmobileNo] = useState<string | null>();
  const [Dob, setDob] = useState<string | null>();
  const [patientCategory, setPatientCategory] = useState<string | null>();
  const [patientInsuranceId, setPatientInsuranceId] = useState<string | null>();

  const handleOpenAddPatient = (): void => {
    setShowAddNewPatientClickPopup(true);
  };

  const handleCloseAddNewPatientPopup = (): void => {
    setShowAddNewPatientClickPopup(false);
  };

  const getallPatientList = async (): Promise<void> => {
    try {
      const getloginInfo: string | null = localStorage.getItem('appLoginInfo');

      if (getloginInfo !== null) {
        const Token = JSON.parse(getloginInfo).data.accessToken;
        const Id = JSON.parse(getloginInfo).data.clinicId;
        const id = JSON.parse(getloginInfo).data._id;
        const header = {
          headers: {'x-access-token': Token},
        };

        const response = await axios.get(
          `${APIkey}/api/admin/getAllPatients?page=${currentPage}&limit=${perPage}&search=${searchQuery}`,
          header
        );
        setPatientList(
          response?.data?.data?.patient_list.map((item) => {
            return {
              // index : item.index + 1,
              // id: item.doc._id,
              index : item.index,
              mrn: item.doc.mrn,
              firstName: item.doc.firstName,
              mobileNo: item.doc.mobileNo,
              dob: item.doc.dob,
              gender: item.doc.genderData.gender,
              patientCategory: item.doc.patientcategoryData?.patientCategory,
              insurance: item?.doc.patientinsuranceData?.insurancePlanName,
              actions: Componentend(item._id),
              action: Component(item._id),
            };
          })
        );
        setTotalPatient(response?.data?.data?.pagination?.totalRecords);

        const getPatientCategoryList = await axios.get(
          `${APIkey}/api/admin/getPatientCategoryList`,
          header
        );

        setPatientCategoryList(
          getPatientCategoryList.data.data.map((i, id) => ({
            label: i.patientCategory,
            value: i.patientCategory,
            id: i._id,
          }))
        );

        const patientInsuranceData = await axios.get(
          `${APIkey}/api/admin/getPatientInsurance`,
          header
        );
        setPatientInsuranceList(
          patientInsuranceData.data.data.map((i) => ({
            label: i.insurancePlanName,
            value: i.insurancePlanName,
            id: i._id,
          }))
        );
      } else {
        console.log('No login info found in localStorage');
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getallPatientList();
  }, [currentPage, perPage, showAddNewPatientClickPopup, searchQuery]);

  const Component = (id) => {
    const action = () => {
      navigate(`/patient/Details/patientInformation/${id}/null`);
    };
    return (
      <>
        <span style={{cursor: 'pointer'}} onClick={action}>
          <img src={fluentedit} alt='' height={'15px'} />
        </span>
      </>
    );
  };

  const Componentend = (id) => {
    const action = () => {
      navigate(`/patient/Details/patientInformation/${id}/null`);
    };
    return (
      <div className='d-flex gap-2'>
        <div>
          <span style={{cursor: 'pointer'}} onClick={action}>
            <img src={patientChart} alt='' height={'15px'} />
          </span>
        </div>
        <div>
          <span style={{cursor: 'pointer'}} onClick={action}>
            <img src={gridicons_dropdown} alt='' height={'15px'} />
          </span>
        </div>

        <div>
          <span style={{cursor: 'pointer'}} onClick={action}>
            <img src={ToDo} alt='' height={'15px'} />
          </span>
        </div>
        <div>
          <span style={{cursor: 'pointer'}} onClick={action}>
            <img src={Notes} alt='' height={'15px'} />
          </span>
        </div>
        <div>
          <span style={{cursor: 'pointer'}} onClick={action}>
            <img src={StickyNotes} alt='' height={'15px'} />
          </span>
        </div>
        <div>
          <span style={{cursor: 'pointer'}} onClick={action}>
            <img src={patientdemo} alt='' height={'15px'} />
          </span>
        </div>
        {/* <span style={{cursor: 'pointer'}} onClick={action}>
          <img src={edit} alt='' height={'15px'} />
        </span> */}
      </div>
    );
  };

  // const validateAadhar = (aadhaar) => {
  //   const aadharStr = String(aadhaar)
  //   const aadharRegex = /^\d{12}$/
  //   return aadharRegex.test(aadharStr)
  // }

  const columns = [
    {key: ['index'], header: 'Sr No.'},
    {key: ['firstName'], header: 'Patient Name'},
    {key: ['mobileNo'], header: 'Mobile No', isEncrypted: true},
    {key: ['dob'], header: 'DOB', isDate: true},
    {key: ['mrn'], header: 'MRN'},
    {key: ['gender'], header: 'Sex'},
    {key: ['patientCategory'], header: 'Patient Category'},
    {key: ['insurance'], header: 'Insurance', validateAadhar: true},
    // {key: ['actions'], header: 'Action(s)', isAction: true, ActionComponent: Componentend},
    {key: ['actions'], header: 'Action(s)'},
  ];

  const handlePageChange = (pageNumber: number): void => {
    setCurrentPage(pageNumber);
  };

  const handlePerPageChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchQuery(e.target.value);
  };

  const filteredPatients = patientList.filter((patient) =>
    Object.values(patient).some((value) =>
      String(value).toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  console.log(filteredPatients);

  const dobfun = (dob) => moment(dob).format('DD/MM/yyyy');

  const submitSearch = async (data) => {
    try {
      console.log(' search Data : ', data);

      let getloginInfo: string | null = localStorage.getItem('appLoginInfo');

      if (getloginInfo !== null) {
        const Token = JSON.parse(getloginInfo).data.accessToken;
        const header = {
          headers: {'x-access-token': Token},
        };

        const response = await axios.post(`${APIkey}/api/admin/getAllPatientsSearch`, data, header);

        console.log(response?.data?.data?.patient_list);
        setCurrentPage(1);
        console.log('SearchedPatients', response?.data?.data?.patient_list);
        // setPatientSearchList(response?.data?.data?.patient_list)
        setPatientSearchList(
          response?.data?.data?.patient_list.map((item) => {
            return {
              // index : item.index + 1,
              // id: item.doc._id,
              mrn: item.mrn,
              firstName: item.firstName,
              mobileNo: item.mobileNo,
              dob: item.dob,
              gender: item.genderData.gender,
              patientCategory: item.patientcategoryData?.patientCategory,
              insurance: item?.patientinsuranceData?.insurancePlanName,
              actions: Componentend(item._id),
              action: Component(item._id),
            };
          })
        );

        setTotalsearchPatient(response?.data?.data?.pagination?.totalRecords);

        if (response) {
          setDownSearch(true);
        }
      } else {
        console.log('No access token found in localStorage');
      }
    } catch (error) {
      // Handle errors
      console.error('Error occurred while adding patient: ', error);
    }
  };

  const handleSearch = () => {
    let data = {
      patientFullName,
      // aadhaar,
      mobileNo,
      dob: Dob,
      mrn: Number(mrn),
      genderId,
      patientCategoryId: patientCategory,
      aadhaarSearchMode: 'equals',
      patientInsuranceId,
    };
    if (data) {
      submitSearch(data);
    } else {
      alert('Search data is invalid or incomplete.');
    }
  };

  const filteredsearchPatients = patientSearchList?.filter((patient) =>
    Object.values(patient).some((value) =>
      String(value).toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const indexOfLastItem = currentPage * perPage;
  const indexOfFirstItem = indexOfLastItem - perPage;
  const searchList = filteredsearchPatients?.slice(indexOfFirstItem, indexOfLastItem);

  const handleCancel = () => {
    setPatientSearchList(null);
    setMrn(null);
    setPatientFullName(null);
    setAadhaar(null);
    setmobileNo(null);
    setDob(null);
    setSex(null);
    setPatientCategory(null);
    setPatientInsuranceId(null);
  };

  const [inputTypedate, setInputTypedate] = useState('text');
  const [inputTypeStartTime, setInputTypeStartTime] = useState('text');
  const [inputTypeEndTime, setInputTypeEndTime] = useState('text');

  const getFullPatientName = (e) => {
    setPatientFullName(e.target.value);
  };

  const getTheaadhaar = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 12) {
      setAadhaar(value);
    }
  };

  const getMobileNo = (e) => {
    setmobileNo(e.target.value);
  };

  // const getTheDate = (e) => {
  //     setDob(e)
  // }

  const getmrn = (e) => {
    setMrn(e.target.value);
  };

  const getTheSex = (e) => {
    setSex(e);
  };

  const getThePatientCategory = (e) => {
    setPatientCategory(e);
  };

  const getPatientInsuranceId = (e) => {
    setPatientInsuranceId(e);
  };

  const handleFocus = () => {
    setInputTypedate('date');
  };
  const handleBlur = () => {
    setInputTypedate('text');
  };

  const handleFocusforstartTime = () => {
    setInputTypeStartTime('time');
  };
  const handleFocusforendTime = () => {
    setInputTypeEndTime('time');
  };
  const handleBlurforStartTime = () => {
    setInputTypeStartTime('text');
  };
  const handleBlurforEndTime = () => {
    setInputTypeEndTime('text');
  };

  const getTheDOB = (e) => {
    setDob(e.target.value);
  };

  console.log('search pateint Data', patientSearchList);

  return (
    <>
      <div className='mt-2 PageHeader'>
        <div>
          <TitleComponent className='px-1' type='title'>
            Patient
          </TitleComponent>
        </div>
        <div>
          <CommonbigButton onClick={handleOpenAddPatient}>Add Patient</CommonbigButton>
        </div>
      </div>
      <div className='card mt-2'>
        {/* <div className={'PatientChartCardHeader'}>
                    <div>
                        <span>Advance Search</span>
                    </div>
                    <div>
                        <div className="px-2 PatientChartCardHeaderIcon" onClick={() => setDownSearch(!downSearch)}>
                            { downSearch ? <img src={UpArrow} alt="" /> : <img src={DownArrow} alt="" /> }
                        </div>
                    </div>
                </div> */}
        <div className='row w-100 align-items-center   px-3 py-3   '>
          <div className='col '>
            <TitleComponent className='px-1' type='label'>
              Patient Name :{' '}
            </TitleComponent>
            <input
              type='text'
              onChange={getFullPatientName}
              value={patientFullName || ''}
              className='form-control SearchInputbox'
              placeholder='Patient Name'
            />
          </div>

          <div className='col  '>
            <TitleComponent className='px-1' type='label'>
              Mobile Number :{' '}
            </TitleComponent>
            <input
              type='number'
              onChange={getMobileNo}
              value={mobileNo || ''}
              className='form-control SearchInputbox'
              placeholder='Mobile Number'
            />
          </div>
          <div className='col  '>
            <TitleComponent className='px-1' type='label'>
              DOB :{' '}
            </TitleComponent>
            <input
              type='date'
              onChange={getTheDOB}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={Dob || ''}
              className='form-control SearchInputbox'
            />
          </div>
          <div className='col  '>
            <TitleComponent className='px-1' type='label'>
              MRN :{' '}
            </TitleComponent>
            <input
              type='text'
              onChange={getmrn}
              value={mrn || ''}
              className='form-control SearchInputbox'
              placeholder='MRN'
            />
          </div>
          <div className='col  '>
            <TitleComponent className='px-1' type='label'>
              SSN No:{' '}
            </TitleComponent>
            <MaskedInput
              mask={[
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              className='form-control SearchInputbox'
              onChange={getTheaadhaar}
              value={aadhaar || ''}
              placeholder={'SSN No.'}
            />
          </div>

          {/* <input

                            type={inputTypedate}
                            placeholder="Date Of Birth"
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onChange={getTheDate}
                            value={Dob || ''}
                            className='form-control SearchInputbox'

                        /> */}

          {/* <Datepicker 
                         selectedDate={Dob}
                         gettingDate={getTheDate}
                        /> */}
        </div>
        <div className='row w-100 align-items-center  p-1 px-3 py-2   '>
          <div className='col '>
            <TitleComponent className='px-1' type='label'>
              Category :{' '}
            </TitleComponent>
            <SearchableDropdown
              InitialValue={patientCategory}
              options={patientCategoryList}
              gettingData={getThePatientCategory}
              placeHolder='Category'
            />
          </div>
          <div className='col  '>
            <TitleComponent className='px-1' type='label'>
              Insurance :{' '}
            </TitleComponent>
            <SearchableDropdown
              InitialValue={patientInsuranceId}
              options={patientInsuranceList}
              gettingData={getPatientInsuranceId}
              placeHolder='Insurance'
            />
          </div>
          <div className='col'>
            <TitleComponent className='px-1' type='label'>
              Sex :{' '}
            </TitleComponent>
            <GenderComponent getTheGender={getTheSex} genderValue={genderId} />
          </div>
          <div className='col  '></div>
          <div className='col  '></div>
          <div className='col  '></div>

          <div className='col d-flex justify-content-end align-items-center gap-2'>
            <div>
              <CommonButton onClick={handleSearch}>Search</CommonButton>
            </div>
            <div className=''>
              <CommonButton onClick={handleCancel} color='#5E6278' border= '1px solid #DCDCDC'  background='##DCDCDC'>
                Clear
              </CommonButton>
            </div>
          </div>
        </div>
        <CommonTable
          data={patientSearchList && patientSearchList.length > 0 ? patientSearchList : patientList}
          columns={columns}
          currentPage={currentPage}
          perPage={perPage}
          totalCount={totalsearchPatient || patientList?.length}
          setCurrentPage={setCurrentPage}
          setPerPage={setPerPage}
          searchQuery={searchQuery}
          setQuery={setSearchQuery}
          onRowClick={(id: string) => navigate(`/ehr/patientchart/${CryptoConverter(id)}`)}
          headerColor={tablecolor.primaryTable.color}
          issetPaginationCompo={true}
          isPagelength={true}
          isSearched={true}
        />
        <ToastContainer />
      </div>

      {showAddNewPatientClickPopup && (
        <AddNewPatient
          open={showAddNewPatientClickPopup}
          closeAddNewPatient={handleCloseAddNewPatientPopup}
        />
      )}
    </>
  );
}

export default PatientSearch;

// import React, {useEffect, useState} from 'react'
// import UpArrow from '../EHRIcon/UpArrow.png'
// import DownArrow from '../EHRIcon/DownArrow.png'
// import {useNavigate} from 'react-router-dom'
// import AddNewPatient from '../Appointment/AddNewPatient'
// import axios from 'axios'
// import moment from 'moment'
// import PatientDemographicM from '../EHRIcon/PatientDemographicM.svg'
// import SearchableDropdown from '../ReusablePages/ReusableAppointment/SearchableDropdown'
// import SearchableGroup from '../../../ReUsableComponent/SearchableGroup'
// import inputText from '../../../ReUsableComponent/ReyClinicalinputs.json'
// import MaskedInput from 'react-text-mask'
// import GenderComponent from '../../../ReUsableComponent/GenderComponent'
// import {ToastContainer} from 'react-toastify'
// import PaginationCompo from '../../../ReUsableComponent/PaginationCompo'
// import CryptoConverter from '../../../ReUsableComponent/CryptoConverter'
// import CommonTable from '../../../ReUsableComponent/CommonTable'
// import edit from '../../../ImgesLogos/editcs.svg'
// import PatientChartM from '../EHRIcon/PatientChartM.svg'
// import gridiconsdropdownM from '../EHRIcon/gridiconsdropdownM.svg'
// import ToDoM from '../EHRIcon/ToDoM.svg'
// import StickyNotesM from '../EHRIcon/StickyNotesM.svg'
// import NotesM from '../EHRIcon/NotesM.svg'
// // import Datepicker from '../../../ReUsableComponent/Datepicker';

// function PatientSearch(): JSX.Element {
//   const APIkey = process.env.REACT_APP_SERVERAPI || ''

//   const appointmentId = null

//   const [patientList, setPatientList] = useState<any[]>([])
//   const [patientSearchList, setPatientSearchList] = useState<any>(null)
//   // console.log(patientList)
//   const [patientCategoryList, setPatientCategoryList] = useState<any[]>([])
//   const [totalsearchPatient, setTotalsearchPatient] = useState<any>()
//   const [patientInsuranceList, setPatientInsuranceList] = useState<any[]>([])
//   const [totalPatient, setTotalPatient] = useState<number>(0)
//   const [showAddNewPatientClickPopup, setShowAddNewPatientClickPopup] = useState<boolean>(false)
//   const [downSearch, setDownSearch] = useState<boolean>(false)
//   const [currentPage, setCurrentPage] = useState<number>(1)
//   const [perPage, setPerPage] = useState<number>(10)
//   const [searchQuery, setSearchQuery] = useState<string>('')
//   const navigate = useNavigate()

//   const [mrn, setMrn] = useState<any>()
//   const [aadhaar, setAadhaar] = useState<any>()
//   const [genderId, setSex] = useState<string | null>()
//   const [patientFullName, setPatientFullName] = useState<string | null>()
//   const [mobileNo, setmobileNo] = useState<string | null>()
//   const [Dob, setDob] = useState<string | null>()
//   const [patientCategory, setPatientCategory] = useState<string | null>()
//   const [patientInsuranceId, setPatientInsuranceId] = useState<string | null>()

//   const handleOpenAddPatient = (): void => {
//     setShowAddNewPatientClickPopup(true)
//   }

//   const handleCloseAddNewPatientPopup = (): void => {
//     setShowAddNewPatientClickPopup(false)
//   }

//   const getallPatientList = async (): Promise<void> => {
//     try {
//       const getloginInfo: string | null = localStorage.getItem('appLoginInfo')

//       if (getloginInfo !== null) {
//         const Token = JSON.parse(getloginInfo).data.accessToken
//         const Id = JSON.parse(getloginInfo).data.clinicId
//         const header = {
//           headers: {'x-access-token': Token},
//         }

//         const response = await axios.get(
//           `${APIkey}/api/admin/getAllPatients?page=${currentPage}&limit=${perPage}&search=${searchQuery}`,
//           header
//         )
//         console.log(response.data.data.patient_list.map((item)=>{
//           return {
//             _id: item.doc._id,
//             mrn: item.doc.mrn,
//           }
//         }))
//         setPatientList(response?.data?.data?.data?.patient_list.map((item)=>{
//           return {
//             _id: item.doc._id,
//             mrn: item.doc.mrn,
//           }
//         }))
//         setTotalPatient(response?.data?.data?.pagination?.totalRecords)

//         const getPatientCategoryList = await axios.get(
//           `${APIkey}/api/admin/getPatientCategoryList/${Id}`,
//           header
//         )

//         setPatientCategoryList(
//           getPatientCategoryList.data.data.map((i) => ({
//             label: i.patientCategory,
//             value: i.patientCategory,
//             id: i._id,
//           }))
//         )

//         const patientInsuranceData = await axios.get(
//           `${APIkey}/api/admin/getPatientInsurance`,
//           header
//         )
//         setPatientInsuranceList(
//           patientInsuranceData.data.data.map((i) => ({
//             label: i.insurancePlanName,
//             value: i.insurancePlanName,
//             id: i._id,
//           }))
//         )
//       } else {
//         console.log('No login info found in localStorage')
//       }
//     } catch (err) {
//       console.log(err)
//     }
//   }

//   useEffect(() => {
//     getallPatientList()
//   }, [currentPage, perPage, showAddNewPatientClickPopup, searchQuery])

//   const Component = ({id}) => {
//     const action = () => {
//       navigate(`/patient/Details/patientInformation/${id}/null`)
//     }
//     return (
//       <>
//         <span style={{cursor: 'pointer'}} onClick={action}>
//           <img src={edit} alt='' height={'15px'} />
//         </span>
//       </>
//     )
//   }

//   const Componentend = ({id}) => {
//     const action = () => {
//       navigate(`/patient/Details/patientInformation/${id}/null`)
//     }
//     return (
//       <div className='d-flex gap-2'>
//         <div>
//           <span style={{cursor: 'pointer'}} onClick={action}>
//             <img src={PatientChartM} alt='' height={'15px'} />
//           </span>
//         </div>
//         <div>
//           <span style={{cursor: 'pointer'}} onClick={action}>
//             <img src={gridiconsdropdownM} alt='' height={'15px'} />
//           </span>
//         </div>

//         <div>
//           <span style={{cursor: 'pointer'}} onClick={action}>
//             <img src={ToDoM} alt='' height={'15px'} />
//           </span>
//         </div>
//         <div>
//           <span style={{cursor: 'pointer'}} onClick={action}>
//             <img src={NotesM} alt='' height={'15px'} />
//           </span>
//         </div>
//         <div>
//           <span style={{cursor: 'pointer'}} onClick={action}>
//             <img src={StickyNotesM} alt='' height={'15px'} />
//           </span>
//         </div>
//         <div>
//           <span style={{cursor: 'pointer'}} onClick={action}>
//             <img src={PatientDemographicM} alt='' height={'15px'} />
//           </span>
//         </div>
//         {/* <span style={{cursor: 'pointer'}} onClick={action}>
//           <img src={edit} alt='' height={'15px'} />
//         </span> */}
//       </div>
//     )
//   }

//   const validateAadhar = (aadhaar) => {
//     const aadharStr = String(aadhaar)
//     const aadharRegex = /^\d{12}$/
//     return aadharRegex.test(aadharStr)
//   }

//   const columns = [
//     {key: ['actions'], header: 'Action(s)', isAction: true, ActionComponent: Component},
//     // {key: ['firstName'], header: 'Patient Name'},
//     // {key: ['mobileNo'], header: 'Mobile No', isEncrypted: true},
//     // {key: ['dob'], header: 'DOB', isDate: true},
//     {key: ['mrn'], header: 'MRN'},
//     // {key: ['genderData', 'gender'], header: 'Sex'},
//     // {key: ['patientcategoryData', 'patientCategory'], header: 'Patient Category'},
//     // {key: ['patient-insurance'], header: 'Insurance', validateAadhar: true},
//     // {key: ['actions'], header: 'Action(s)', isAction: true, ActionComponent: Componentend},
//   ]

//   const handlePageChange = (pageNumber: number): void => {
//     setCurrentPage(pageNumber)
//   }

//   const handlePerPageChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
//     setPerPage(Number(e.target.value))
//     setCurrentPage(1) // Reset to first page when changing items per page
//   }

//   const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
//     setSearchQuery(e.target.value)
//   }

//   const filteredPatients = patientList?.filter((patient) =>
//     Object.values(patient).some((value) =>
//       String(value).toLowerCase().includes(searchQuery.toLowerCase())
//     )
//   )

//   const dobfun = (dob) => moment(dob).format('DD/MM/yyyy')

//   const submitSearch = async (data) => {
//     try {
//       console.log(' search Data : ', data)

//       let getloginInfo: string | null = localStorage.getItem('appLoginInfo')

//       if (getloginInfo !== null) {
//         const Token = JSON.parse(getloginInfo).data.accessToken
//         const header = {
//           headers: {'x-access-token': Token},
//         }

//         const response = await axios.post(`${APIkey}/api/admin/getAllPatientsSearch`, data, header)

//         console.log(response?.data?.data?.patient_list)
//         setCurrentPage(1)
//         setPatientSearchList(response?.data?.data?.patient_list)
//         setTotalsearchPatient(response?.data?.data?.pagination?.totalRecords)

//         if (response) {
//           setDownSearch(true)
//         }
//       } else {
//         console.log('No access token found in localStorage')
//       }
//     } catch (error) {
//       // Handle errors
//       console.error('Error occurred while adding patient: ', error)
//     }
//   }

//   const handleSearch = () => {
//     let data = {
//       patientFullName,
//       aadhaar,
//       mobileNo,
//       dob: Dob,
//       mrn: Number(mrn),
//       genderId,
//       patientCategoryId: patientCategory,
//       aadhaarSearchMode: 'equals',
//       patientInsuranceId,
//     }
//     if (data) {
//       submitSearch(data)
//     } else {
//       alert('Search data is invalid or incomplete.')
//     }
//   }

//   const filteredsearchPatients = patientSearchList?.filter((patient) =>
//     Object.values(patient).some((value) =>
//       String(value).toLowerCase().includes(searchQuery.toLowerCase())
//     )
//   )

//   const indexOfLastItem = currentPage * perPage
//   const indexOfFirstItem = indexOfLastItem - perPage
//   const searchList = filteredsearchPatients?.slice(indexOfFirstItem, indexOfLastItem)

//   const handleCancel = () => {
//     setPatientSearchList(null)
//     setMrn(null)
//     setPatientFullName(null)
//     setAadhaar(null)
//     setmobileNo(null)
//     setDob(null)
//     setSex(null)
//     setPatientCategory(null)
//     setPatientInsuranceId(null)
//   }

//   const [inputTypedate, setInputTypedate] = useState('text')
//   const [inputTypeStartTime, setInputTypeStartTime] = useState('text')
//   const [inputTypeEndTime, setInputTypeEndTime] = useState('text')

//   const getFullPatientName = (e) => {
//     setPatientFullName(e.target.value)
//   }

//   const getTheaadhaar = (e) => {
//     const value = e.target.value.replace(/\D/g, '');
//     if (value.length <= 12) {
//       setAadhaar(value);
//     }
//   };

//   const getMobileNo = (e) => {
//     setmobileNo(e.target.value)
//   }

//   // const getTheDate = (e) => {
//   //     setDob(e)
//   // }

//   const getmrn = (e) => {
//     setMrn(e.target.value)
//   }

//   const getTheSex = (e) => {
//     setSex(e)
//   }

//   const getThePatientCategory = (e) => {
//     setPatientCategory(e)
//   }

//   const getPatientInsuranceId = (e) => {
//     setPatientInsuranceId(e)
//   }

//   const handleFocus = () => {
//     setInputTypedate('date')
//   }
//   const handleBlur = () => {
//     setInputTypedate('text')
//   }

//   const handleFocusforstartTime = () => {
//     setInputTypeStartTime('time')
//   }
//   const handleFocusforendTime = () => {
//     setInputTypeEndTime('time')
//   }
//   const handleBlurforStartTime = () => {
//     setInputTypeStartTime('text')
//   }
//   const handleBlurforEndTime = () => {
//     setInputTypeEndTime('text')
//   }

//   const getTheDOB = (e) => {
//     setDob(e.target.value)
//   }

//   return (
//     <>
//       <div className='mt-2 PageHeader'>
//         <div>
//           <span className='PageHeaderTitle'>Patient</span>
//         </div>
//         <div>
//           <button className='blueButton' onClick={handleOpenAddPatient}>
//             Add Patient
//           </button>
//         </div>
//       </div>
//       <div className='card mt-2'>
//         {/* <div className={'PatientChartCardHeader'}>
//                     <div>
//                         <span>Advance Search</span>
//                     </div>
//                     <div>
//                         <div className="px-2 PatientChartCardHeaderIcon" onClick={() => setDownSearch(!downSearch)}>
//                             { downSearch ? <img src={UpArrow} alt="" /> : <img src={DownArrow} alt="" /> }
//                         </div>
//                     </div>
//                 </div> */}
//         <div className='row w-100 align-items-center   px-3 py-3   '>
//           <div className='col '>
//             <label htmlFor='' className='formLabelTitle required'>Patient Name : </label>
//             <input
//               type='text'
//               onChange={getFullPatientName}
//               value={patientFullName || ''}
//               className='form-control SearchInputbox'
//               placeholder='Patient Name'
//             />
//           </div>

//           <div className='col  '>
//           <label htmlFor='' className='formLabelTitle'>Mobile Number : </label>
//             <input
//               type='number'
//               onChange={getMobileNo}
//               value={mobileNo || ''}
//               className='form-control SearchInputbox'
//               placeholder='Mobile Number'
//             />
//           </div>
//           <div className='col  '>
//           <label htmlFor='' className='formLabelTitle'>DOB : </label>
//             <input
//               type='date'
//               onChange={getTheDOB}
//               onFocus={handleFocus}
//               onBlur={handleBlur}
//               value={Dob || ''}
//               className='form-control SearchInputbox'
//             />
//           </div>
//           <div className='col  '>
//           <label htmlFor='' className='formLabelTitle'>MRN : </label>
//             <input
//               type='text'
//               onChange={getmrn}
//               value={mrn || ''}
//               className='form-control SearchInputbox'
//               placeholder='MRN'
//             />
//           </div>
//           <div className='col  '>
//           <label htmlFor='' className='formLabelTitle'>Aadhar : </label>
//             <MaskedInput
//               mask={[/\d/, /\d/,/\d/, /\d/, '-', /\d/,/\d/,/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
//               className='form-control SearchInputbox'
//               onChange={getTheaadhaar}
//               value={aadhaar || ''}
//               placeholder={'Aadhaar No.'}
//             />
//           </div>

//           {/* <input

//                             type={inputTypedate}
//                             placeholder="Date Of Birth"
//                             onFocus={handleFocus}
//                             onBlur={handleBlur}
//                             onChange={getTheDate}
//                             value={Dob || ''}
//                             className='form-control SearchInputbox'

//                         /> */}

//           {/* <Datepicker
//                          selectedDate={Dob}
//                          gettingDate={getTheDate}
//                         /> */}
//         </div>
//         <div className='row w-100 align-items-center  p-1 px-3 py-2   '>
//           <div className='col '>
//           <label htmlFor='' className='formLabelTitle'>Category : </label>
//             <SearchableDropdown
//               InitialValue={patientCategory}
//               options={patientCategoryList}
//               gettingData={getThePatientCategory}
//               placeHolder='Category'
//             />
//           </div>
//           <div className='col  '>
//           <label htmlFor='' className='formLabelTitle'>Insurance : </label>
//             <SearchableDropdown
//               InitialValue={patientInsuranceId}
//               options={patientInsuranceList}
//               gettingData={getPatientInsuranceId}
//               placeHolder='Insurance'
//             />
//           </div>
//           <div className='col'>
//           <label htmlFor='' className='formLabelTitle'>Sex : </label>
//             <GenderComponent getTheGender={getTheSex} genderValue={genderId} />
//           </div>
//           <div className='col  '></div>
//           <div className='col  '></div>
//           <div className='col  '></div>

//           <div className='col d-flex justify-content-end align-items-center gap-2'>
//             <div>
//               <button className='blueButton' onClick={handleSearch}>
//                 Search
//               </button>
//             </div>
//             <div className=''>
//               <button onClick={handleCancel} className='grayButton'>
//                 Clear
//               </button>
//             </div>
//           </div>
//         </div>
//         <CommonTable
//           data={patientSearchList && patientSearchList.length > 0 ? patientSearchList : patientList}
//           columns={columns}
//           currentPage={currentPage}
//           perPage={perPage}
//           totalCount={totalsearchPatient || patientList?.length}
//           setCurrentPage={setCurrentPage}
//           setPerPage={setPerPage}
//           searchQuery={searchQuery}
//           setQuery={setSearchQuery}
//           onRowClick={(id: string) => navigate(`/ehr/patientchart/${CryptoConverter(id)}`)}
//           headerColor={'#EB2226'}
//         />
//         <ToastContainer />
//       </div>

//       {showAddNewPatientClickPopup && (
//         <AddNewPatient
//           open={showAddNewPatientClickPopup}
//           closeAddNewPatient={handleCloseAddNewPatientPopup}
//         />
//       )}
//     </>
//   )
// }

// export default PatientSearch
