import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'
import {useParams} from 'react-router-dom'
const EditAllergies = ({onshow, onHide, editId}) => {
  const APIkey = process.env.REACT_APP_SERVERAPI || ''
  const [allergyname, setAllergyName] = useState('')
  const [allergenType, setAllergenType] = useState('')
  const [severity, setSeverity] = useState('')
  const [reaction, setReaction] = useState('')
  const [allergytype, setAllergType] = useState('')
  const [allergyCode, setAllergyCode] = useState('')
  const [notes, setNotes] = useState('')

  const handleCloseEditModal = () => {
    setAllergyName('')
    setAllergenType('')
    setSeverity('')
    setReaction('')
    setAllergType('')
    setAllergyCode('')
    setNotes('')
    onHide()
  }

  useEffect(()=>{
    const fetchEditData = async()=>{
        try{
            const getLoginInfo = localStorage.getItem('appLoginInfo')
            console.log('hello there: ', getLoginInfo)
            if(getLoginInfo!== null){
                const {data:{accessToken}}=JSON.parse(getLoginInfo)
                const headers = {'x-access-token': accessToken}

                const response = await axios.get(
                    `${APIkey}/api/admin/getPreferredAllergyById/${editId}`,
                    {headers}
                  )
                  const data = response.data.data
                  setAllergyName(data.allergyName)
                  setAllergenType(data.allergenType)
                  setSeverity(data.severity)
                  setReaction(data.reaction)
                  setAllergType(data.allergyType)
                  setAllergyCode(data.allergyCode)
                  setNotes(data.notes)

            }else{
                console.log('No Login Info found in localstorage')
            }

        }catch(error){
            console.log('Error fetching data:',error);

        }
    }
    if(editId){
        fetchEditData()
    }
  },[editId])



const handleUpdate = async()=>{
    try{
        const getLoginInfo: any = localStorage.getItem('appLoginInfo') 
        if(getLoginInfo !== null){
            const Token = JSON.parse(getLoginInfo).data.accessToken 
            const headers = {'x-access-token': Token}

            const response = await axios.post(`${APIkey}/api/admin/updatePreferredAllergy/${editId}`,
                {notes},
            {headers})
            console.log('Update Data:',response.data.data)
            handleCloseEditModal()
        }else{
            console.error('No login Info found in localStorage')
        }
    }catch(error){
console.error('Error updating data:',error)
    }
}








  return (
    <Modal show={onshow} onHide={handleCloseEditModal} size='lg' centered>
      <Modal.Header closeButton className='h-25px  dialoge align-items-center pb-3'>
        <div className='PageHeaderTitle'>Edit Allergy</div>
      </Modal.Header>
      <Modal.Body className='p-2 dialoge'>
        <div className='dashboard-card col-md-12 p-2'>
          <div className='row'>
            {/* Allergy Name */}
            <div className='col-md-3 mb-3'>
              <div className='addvaccinetitle'>Allergy Name:</div>
              <input type='text' className='SearchInputbox' value={allergyname} readOnly disabled/>
            </div>
            {/* Allergen type */}
            <div className='col-md-3 mb-3'>
              <div className='addvaccinetitle'>Allergen type:</div>
              <input type='text' className='SearchInputbox' value={allergenType} readOnly disabled />
            </div>
            {/* Severity */}
            <div className='col-md-3 mb-3'>
              <div className='addvaccinetitle'>Severity:</div>
              <input type='text' className='SearchInputbox' value={severity} readOnly disabled/>
            </div>
            {/* Reaction */}
            <div className='col-md-3 mb-3'>
              <div className='addvaccinetitle'>Reaction:</div>
              <input type='text' className='SearchInputbox' value={reaction} readOnly disabled/>
            </div>
            {/* Allergy Type */}

            <div className='col-md-3 mb-3'>
              <div className='addvaccinetitle'>Allergy Type:</div>
              <input type='text' className='SearchInputbox' value={allergytype} readOnly disabled />
            </div>
            {/* Allergy Code */}

            <div className='col-md-3 mb-3'>
              <div className='addvaccinetitle'>Allergy Code:</div>
              <input type='text' className='SearchInputbox' value={allergyCode} readOnly disabled/>
            </div>
            {/* Notes */}
           
            <div className='col-md-3 mb-3'>
              <div className='addvaccinetitle'>Notes:</div>
              <input type='text' className='SearchInputbox' value={notes} onChange={(e)=>setNotes(e.target.value)} />
            </div>
          </div>
          </div>
          <div className='my-2 d-flex justify-content-end gap-3'>
            <button type='button' className='primarybtnBig' onClick={handleUpdate}>Update</button>
            <button type='button' className='tertiarybig' onClick={handleCloseEditModal}>
              Cancel
            </button>
          </div>
       
      </Modal.Body>
    </Modal>
  )
}

export default EditAllergies
