import React from 'react'

function PatientTabHistory() {
  return (
    <>
    <div className='card py-2 px-2'>
            <div className='titleFont'>
            History
            </div>
    </div>
    
    </>
  )
}

export default PatientTabHistory ;