// import React from 'react';
// import { Modal } from 'react-bootstrap';
// import { CommonPopupButton } from '../../../SuperAdmin/Pages/ReausableComponent/CommonButton';
// import TitleComponent from '../../../ReUsableComponent/TitleComponent';

// function ViewInsuranceDetails({ insuranceData, open, closeInsurance , patientId}) {
//   const handleClose = () => {
//     closeInsurance();
//   };

//   console.log(insuranceData);

//   return (
//     <div>
//       <Modal show={open} onHide={handleClose} size="xl" centered>
//         <Modal.Header closeButton className="dialog p-2">
//           <Modal.Title>
//             <TitleComponent type="title" className="px-1">
//               View Insurance
//             </TitleComponent>
//           </Modal.Title>
//         </Modal.Header>

//         <Modal.Body>
//           <div className="details-grid">
//             <div className="d-flex gap-5">
//               <div className="detail-item">
//                 <strong>Insurance Type:</strong> {insuranceData?.insuranceType || '-'}
//               </div>
//               <div className="detail-item">
//                 <strong>BIN:</strong> {insuranceData?.bin || '-'}
//               </div>
//               <div className="detail-item">
//                 <strong>Insurance Plan Name:</strong> {insuranceData?.planName || '-'}
//               </div>
//               <div className="detail-item">
//                 <strong>PCN:</strong> {insuranceData?.pcn || '-'}
//               </div>
//             </div>

//             <div className="d-flex gap-5">
//               <div className="detail-item">
//                 <strong>Patient Insurance ID:</strong> {insuranceData?.insuranceId || '-'}
//               </div>
//               <div className="detail-item">
//                 <strong>Patient Person Code:</strong> {insuranceData?.personCode || '-'}
//               </div>
//               <div className="detail-item">
//                 <strong>Group ID:</strong> {insuranceData?.groupId || '-'}
//               </div>
//               <div className="detail-item">
//                 <strong>Coverage Type:</strong> {insuranceData?.coverageType || '-'}
//               </div>
//             </div>

//             <div className="d-flex gap-5">
//               <div className="detail-item">
//                 <strong>Insurance Effective Date:</strong> {insuranceData?.effectiveDate || '-'}
//               </div>
//               <div className="detail-item">
//                 <strong>Insurance End Date:</strong> {insuranceData?.endDate || '-'}
//               </div>
//               <div className="detail-item">
//                 <strong>Plan ID:</strong> {insuranceData?.planId || '-'}
//               </div>
//               <div className="detail-item">
//                 <strong>Payor/Plan Type:</strong> {insuranceData?.payorPlanType || '-'}
//               </div>
//             </div>

//             <div className="d-flex gap-5">
//               <div className="detail-item">
//                 <strong>Layer Of Coverage:</strong> {insuranceData?.layerOfCoverage || '-'}
//               </div>
//               <div className="detail-item">
//                 <strong>Front Side of Insurance:</strong>
//                 {insuranceData?.frontSide ? (
//                   <img
//                     src={insuranceData.frontSide}
//                     alt="Front side of insurance"
//                     className="insurance-image"
//                   />
//                 ) : (
//                   '-'
//                 )}
//               </div>
//               <div className="detail-item">
//                 <strong>Back Side of Insurance:</strong>
//                 {insuranceData?.backSide ? (
//                   <img
//                     src={insuranceData.backSide}
//                     alt="Back side of insurance"
//                     className="insurance-image"
//                   />
//                 ) : (
//                   '-'
//                 )}
//               </div>
//             </div>
//           </div>
//         </Modal.Body>

//         <Modal.Footer style={{ background: '#F2F3F7' }} className="p-2">
//           <CommonPopupButton
//             background={'#D7D7D7'}
//             color={'#818181'}
//             onClick={handleClose}
//           >
//             Close
//           </CommonPopupButton>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// }

// export default ViewInsuranceDetails;


import React from 'react';
import { Modal } from 'react-bootstrap';
import { CommonPopupButton } from '../../../SuperAdmin/Pages/ReausableComponent/CommonButton';
import TitleComponent from '../../../ReUsableComponent/TitleComponent';
import moment from 'moment';

function ViewInsuranceDetails({ insuranceData, open, closeInsurance , patientId}) {
  const handleClose = () => {
    closeInsurance();
  };

console.log('insuranceData', insuranceData);

  return (
    <div>
      <Modal show={open} onHide={handleClose} size="xl" centered>
        <Modal.Header closeButton className="dialog p-2">
          <Modal.Title>
            <TitleComponent type="title" className="px-1">
              View Insurance
            </TitleComponent>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="details-grid">
            <div className="d-flex gap-5">
                <div className=''>
              <div className="detail-item">
                <strong>Insurance Type:</strong>
              </div>
                <div> {insuranceData[0]?.patientInsuranceTypeDetails?.insuranceType || '-'}</div>
                </div>

             <div className=''>
              <div className="detail-item">
                <strong>BIN:</strong> 
              </div>
                <div>{insuranceData[0]?.bin || '-'}</div>
                </div>

            <div>
              <div className="detail-item">
                <strong>Insurance Plan Name:</strong> 
              </div>
                <div>{insuranceData[0]?.insurancePlanName || '-'}</div>
                </div>

                <div>
              <div className="detail-item">
                <strong>PCN:</strong> 
              </div>
                <div>{insuranceData[0]?.pcn || '-'}</div>
              </div>
            </div>

            <div className="d-flex gap-5">
                <div>
              <div className="detail-item">
                <strong>Patient Insurance ID:</strong> 
                </div>
                <div>
                {insuranceData[0]?.patientInsuranceId || '-'}
              </div>
              </div>

              <div>
              <div className="detail-item">
                <strong>Patient Person Code:</strong> 
                </div>
                <div>
                {insuranceData[0]?.patientPersonCode || '-'}
              </div>
              </div>

              <div>
              <div className="detail-item">
                <strong>Group ID:</strong> 
                </div>
                <div>
                {insuranceData[0]?.groupId || '-'}</div>
              </div>
             

              <div>
              <div className="detail-item">
                <strong>Coverage Type:</strong> 
              </div>
              <div>
                {insuranceData[0]?.coverageDetails?.coverageType || '-'}
              </div>
            </div>
            </div>

            <div className="d-flex gap-5">
                <div>
              <div className="detail-item">
                <strong>Insurance Effective Date:</strong> 
                </div>
                <div>
                    {insuranceData[0]?.insuranceEffectiveDate ? moment(insuranceData.insuranceEffectiveDate).format('MM/DD/YYYY') : '-'}
              </div>
              </div>

              <div>
              <div className="detail-item">
                <strong>Insurance End Date:</strong> 
                </div>
                <div>{insuranceData[0]?.insuranceEndDate ? moment(insuranceData.insuranceEndDate).format('MM/DD/YYYY') : '-'}
              </div>
              </div>

              <div>
              <div className="detail-item">
                <strong>Plan ID:</strong>
                </div><div> {insuranceData[0]?.planId || '-'}</div>
              </div>

              <div>
              <div className="detail-item">
                <strong>Payor/Plan Type:</strong> </div>
                <div>{insuranceData[0]?.payorPlanDetails?.payorPlanType || '-'}</div>
              </div>
            </div>

            <div className="d-flex gap-5">
                <div>
              <div className="detail-item">
                <strong>Layer Of Coverage:</strong> </div>
                <div>{insuranceData[0]?.layerOfCoverageDetails?.layerOfCoverage || '-'}
                    </div>
              </div>

              <div>
              <div className="detail-item">
                <strong>Front Side of Insurance:</strong>
                </div>
                <div>
                {insuranceData[0]?.attachments_front ? (
                  <img
                    src={insuranceData[0].attachments_front}
                    alt="Front side of insurance"
                    className="insurance-image"
                  />
                ) : (
                  '-'
                )}
                </div>
              </div>

              <div>
              <div className="detail-item">
                <strong>Back Side of Insurance:</strong>
                </div>
                <div>
                {insuranceData[0]?.attachments_back ? (
                  <img
                    src={insuranceData[0].attachments_back}
                    alt="Back side of insurance"
                    className="insurance-image"
                  />
                ) : (
                  '-'
                )}
              </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer style={{ background: '#F2F3F7' }} className="p-2">
          <CommonPopupButton
            background={'#D7D7D7'}
            color={'#818181'}
            onClick={handleClose}
          >
            Close
          </CommonPopupButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ViewInsuranceDetails;
