import React from 'react'

function PatientTabFinancials() {
  return (
    <div className='card px-2 py-2'>
        <div className='titleFont'>
        Financials
        </div>
        </div>
  )
}

export default PatientTabFinancials