import React from 'react'
import CryptoJS from 'crypto-js';

function CryptoConverter(data) {
    console.log("Data: " + data)
    let key = 'reyEhr'
    const bytes = CryptoJS.AES.decrypt(data, key);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    
    return decryptedData;
}

export default CryptoConverter