import React , {useState} from 'react'
// import Medication from '../EHRIcon/RxWhiteIcon.png'
import DownArrow from '../EHRIcon/DownArrow.png'
import UpArrowBlack from '../EHRIcon/UpArrowBlack.png'
import DownArrowBlack from '../EHRIcon/DownArrowBlack.png'
import UpArrow from '../EHRIcon/UpArrow.png'
import AppointmentPageHeader from '../ReusablePages/ReusableAppointment/AppointmentPageHeader'
import PatientIdCard from '../ReusablePages/ReusableAppointment/PatientIdCard'

function PatientMedication() {

    const [medicationDown, setMedicationDown] = useState(false)
    const [prescribeDown , setPrescribeDown ] = useState(false)
    const [ currentDown , setCurrentDown] = useState(false)



    const [currMedicationData, setCurrMedicationData] = useState([
        {
            PrescribedOn: "04/12/23 06:00 AM",
            medication: "rilpivirine 25 mg tablet",
            DoNotFill: "",
            sig: "1 tablet by mouth daily",
            dispense: "30 tablet",
            lastRefillOn: "",
            Refills: "1",
            prescribe: 'Hiren K Patel MD'
        },
        {
            PrescribedOn: "04/12/23 06:00 AM",
            medication: "rilpivirine 25 mg tablet",
            DoNotFill: "",
            sig: "1 tablet by mouth daily",
            dispense: "30 tablet",
            lastRefillOn: "",
            Refills: "1",
            prescribe: 'Hiren K Patel MD'
        },

    ])




  return (
    <div>

<AppointmentPageHeader name="Medications" />
            <PatientIdCard />
         <div className='card mt-2'>
                    <div className={medicationDown ? "PatientChartCardHeader " : "PatientChartCardHeadercondition"}>
                        <div className='d-flex'>

                            <div >
                                {/* <img src={Medication} alt="" className='PatientChartCardHeaderIcon' /> */}
                            </div>
                            <div>
                                <span>Medications</span>
                            </div>
                        </div>
                        <div>
                            <div className='PatientChartCardHeaderIcon'
                                onClick={() => setMedicationDown(!medicationDown)}
                            >
                                {
                                    medicationDown ?
                                        <img src={UpArrow} alt="" /> :
                                        <img src={DownArrow} alt="" />
                                }
                            </div>
                        </div>
                    </div>
                    {
                        medicationDown &&
                        <div>

                            <div className=' row align-items-center  p-2  w-100  ' >
                                <div className='col-md-1' >
                                    <span className='form-label d-flex'>Medications Notes:</span>
                                </div>
                                <div className='col-md-10 ' >
                                    <input type="text" id='' className='xlargeInput100 form-control' />
                                </div>
                                <div className='col-md-1 d-flex justify-content-end '>

                                    <button className='primarybtnBig '>Save</button>
                                </div>
                            </div>

                            <div className='row  p-2 ' >
                                <div className=' col-md-12 col-lg-6 justify-content-start d-flex gap-1 pt-2'>

                                    <div>
                                        <button className='switchViewbtnLeft  switchViewbtnActive'>
                                            Active
                                        </button>
                                        <button className="switchViewbtn2nd">
                                            Discontinued

                                        </button>
                                        <button className='switchViewbtnRight  '>
                                            Cancelled

                                        </button>
                                    </div>

                                    <div>
                                        <button type="button" className='blueButton'>
                                            Review
                                        </button>
                                    </div>
                                    <div>
                                        <button type="button" className='blueButton'>
                                            Medication not ordered
                                        </button>
                                    </div>
                                </div>
                                <div className='col-md-12 col-lg-6 justify-content-end  d-flex gap-2 pt-2'>
                                    <div className='form-check form-check-custom  form-check-sm'>
                                        <input type="checkbox" className='mx-1 form-check-input' />
                                        <label className='form-check-label mx-1 ' style={{ color: "black", fontWeight: '600' }} htmlFor="">Does not take Medication</label>
                                    </div>
                                    <div>
                                        <span>
                                            icon
                                        </span>
                                    </div>

                                    <div className='d-flex gap-2'>
                                        <div>

                                            <button type="button" className='blueButton'>
                                                <span >

                                                    Prescribe
                                                </span>
                                            </button>
                                        </div>
                                        <div>

                                            <button type="button" className='blueButton'>
                                                <span >

                                                    Manage
                                                </span>
                                            </button>
                                        </div>

                                        <div>

                                            <button type="button" className='blueButton'>
                                                <span >

                                                    Reconcile
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <span>
                                            icon
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            icon
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            icon
                                        </span>
                                    </div>

                                </div>



                            </div>

                            <div className=' p-2'>
                                <div className='SubCard '>
                                    <div className={prescribeDown ?'CardHeaderwithoutcolor ' : 'CardHeaderwithoutcolorCondition'}>
                                        <div>

                                        <span>Prescribed Medication(s)</span>
                                        </div>
                                        <div className='PatientChartCardHeaderIcon' onClick={()=> setPrescribeDown(!prescribeDown)}>
                                          {
                                                prescribeDown ? 

                                                <img src={UpArrowBlack} alt="" /> :
                                                      <img src={DownArrowBlack} alt="" />
                                            }

                                        </div>

                                    </div>

                                    {
                                    prescribeDown &&
                                    <div>

                                    <div className=''>

                                        <table className='w-100'>
                                            <thead>
                                                <tr className='tableHead'>

                                                    <th>
                                                        Reported on
                                                    </th>
                                                    <th>
                                                        Medication
                                                    </th>
                                                    <th>
                                                        Do Not Fill
                                                    </th>
                                                    <th>
                                                        Sig
                                                    </th>
                                                    <th>
                                                        Dispense
                                                    </th>
                                                    <th>
                                                        Last Refill on
                                                    </th>
                                                    <th>
                                                        Refills
                                                    </th>
                                                    <th>
                                                        Prescriber
                                                    </th>
                                                    <th>
                                                        <div className=''>

                                                            <input type="checkbox" className='' />
                                                        </div>
                                                    </th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                {/* <tr className='tableBody'>
                                        <td>
                                            
                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                    </tr> */}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className='PatientChartCardFooter'>
                                        <div>
                                            <span className='form-label p-2'>
                                                No Record(s) found.
                                            </span>
                                        </div>

                                        <div>
                                            pagination ...
                                        </div>
                                    </div>
                                    </div>
                                    }

                                </div>
                            </div>
                            <div className=' p-2'>
                                <div className='SubCard '>
                                    <div className={currentDown ?'CardHeaderwithoutcolor ' : 'CardHeaderwithoutcolorCondition'}>
                                        <div>

                                        <span>Current Medication(s)</span>
                                        </div>
                                        <div className='PatientChartCardHeaderIcon' onClick={()=> setCurrentDown(!currentDown)} >
                                         {
                                            currentDown ?
                                            <img src={UpArrowBlack} alt="" /> :
                                            <img src={DownArrowBlack} alt="" />
                                         }   
                                        </div>
                                    </div>


                                         {
                                            currentDown &&
                                         <div>

                                    <div className=''>

                                        <table className='w-100'>
                                            <thead>
                                                <tr className='tableHead'>

                                                    <th>
                                                        Prescribed on
                                                    </th>
                                                    <th>
                                                        Medication
                                                    </th>
                                                    <th>
                                                        Do Not Fill
                                                    </th>
                                                    <th>
                                                        Sig
                                                    </th>
                                                    <th>
                                                        Dispense
                                                    </th>
                                                    <th>
                                                        Last Refill on
                                                    </th>
                                                    <th>
                                                        Refills
                                                    </th>
                                                    <th>
                                                        Prescriber
                                                    </th>
                                                    <th>
                                                        <div className=''>

                                                            <input type="checkbox" className='' />
                                                            <img src="" alt="" />
                                                        </div>
                                                    </th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                {
                                        currMedicationData.map((item)=> (

                                            <tr className='tableBody'>
                                                        <td>
                                                        {item.PrescribedOn}
                                                        </td>
                                                        <td>

                                                        </td>
                                                        <td>

                                                        </td>
                                                        <td>

                                                        </td>
                                                        <td>

                                                        </td>
                                                        <td>

                                                        </td>
                                                        <td>

                                                        </td>
                                                        <td>

                                                        </td>
                                                        <td>

                                                        </td>
                                                    </tr>
                                                        ))

                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className='PatientChartCardFooter'>
                                        <div>
                                            {
                                            currMedicationData.length === 0  ? 
                                                <span className='form-label p-2'>
                                                No Record(s) found.
                                            </span> :
                                            <span className='form-label p-2'>
                                            Total Numbers of Recorde : {currMedicationData.length}
                                        </span>
                                            }
                                        </div>

                                        <div>
                                            pagination ...
                                        </div>
                                    </div>
                                    </div>
                                         }

                                </div>
                            </div>
                        </div>
                    }

                </div>
    </div>
  )
}

export default PatientMedication
