import React, { useState, useEffect } from 'react'
import CommonTable from '../../../../ReUsableComponent/CommonTable'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'
import tablecolor from '../../../../ReUsableComponent/ReyClinicalinputs.json'
const AddHealthIssues = ({ onShow, onHide,onClick }) => {
  const APIkey = process.env.REACT_APP_SERVERAPI

  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const [modalData, setModalData] = useState([])
  const [selectedId, setSelectedId] = useState<string[]>([])

  const fetchHealthIssuesData = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const headers = { 'x-access-token': Token }
        const response = await axios.get(`${APIkey}/api/admin/getHealthIssueList`, { headers })
        const responseData = response.data.data
        const formattedData = responseData.map((item, index) => ({
          srNo: index + 1,
          healthIssue: item.healthIssue,
          _id: item._id,
        }))
        setModalData(formattedData)
      } else {
        console.log('No login info found in localStorage')
      }
    } catch (error) {
      console.error('Error fetching health issues data:', error)
    }
  }

  useEffect(() => {
    fetchHealthIssuesData()
  }, [])

  const saveData = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const headers = { 'x-access-token': Token }
        const response = await axios.post(
          `${APIkey}/api/admin/createPreferredHealthIssue`,
          { healthIssueId: selectedId },
          { headers }
        )
        console.log("Response:", response)
        onHide()
      } else {
        console.log('No login info found in localStorage')
      }
    } catch (error) {
      console.error('Error saving health issues:', error)
    }
  }

  const Component = ({ id }) => {
    const handleCheckbox = (e) => {
      const { value, checked } = e.target
      setSelectedId((prev) => {
        if (checked) {
          if (!prev.includes(value)) {
            return [...prev, value]
          }
        } else {
          return prev.filter((item) => item !== value)
        }
        return prev
      })
    }

    return (
      <input
        type='checkbox'
        value={id}
        checked={selectedId.includes(id)}
        onChange={handleCheckbox}
      />
    )
  }

  const AddHealthIssueColumns = [
    { key: ['srNo'], header: 'Sr.No' },
    {
      key: ['select'],
      header: 'Select',
      isAction: true,
      ActionComponent: Component,
    },
    { key: ['healthIssue'], header: 'Health Issues' },
  ]

  const handleRowClick = (row) => {
    console.log('Row clicked:', row)
  }

  const handleCloseModal = () => {
    onHide()
  }

  return (
    <Modal show={onShow} onHide={handleCloseModal} size='xl' className='w-100' centered>
      <Modal.Header closeButton className='h-25px dialoge align-items-center pb-3'>
        <div className='PageHeaderTitle'>Add Health Issues:</div>
      </Modal.Header>
      <Modal.Body className='p-2 dialoge'>
      <div className='dashboard-card mt-2'>
            {/*  {loadingModalData ? (
              <p>Loading...</p>
            ) : ( */}
            <CommonTable
              columns={AddHealthIssueColumns}
              data={modalData}
              totalCount={modalData.length}
              setCurrentPage={setCurrentPage}
              setPerPage={setPerPage}
              searchQuery={searchQuery}
              perPage={perPage}
              currentPage={currentPage}
              setQuery={setSearchQuery}
              headerColor={tablecolor.primaryTable.color}
                issetPaginationCompo = {false}
                isPagelength={false}
                isSearched={false}
              onRowClick={handleRowClick}
            />
            {/* )} */}
          </div>
      </Modal.Body>
      <Modal.Footer>
      <div className='mt-3'>
            <button
              className='primarybtnBig '
              onClick={saveData}
              disabled={selectedId.length === 0}
            >
              Save
            </button>
            &nbsp;&nbsp;&nbsp;
            <button className='tertiarybig ' onClick={handleCloseModal}>
              Close
            </button>
          </div>
      </Modal.Footer>
    </Modal>
  )
}

export default AddHealthIssues
