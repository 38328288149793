import React, {useEffect, useState} from 'react'
import {useNavigate, Link} from 'react-router-dom'
import SerchableDropdown from '../ReausableComponent/SearchableDropdown'
import axios, { AxiosError } from 'axios';
import inputText from '../../../ReUsableComponent/ReyClinicalinputs.json'
import searchiconwhite from '../../../ImgesLogos/searchiconwhite.svg'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import DeleteModel from '../../../ReUsableComponent/DeleteModel'
import {toast, ToastContainer} from 'react-toastify'
import CommonTable from '../../../ReUsableComponent/CommonTable'
import moment from 'moment'
// import ViewDetails from '../ReausableComponent/ViewDetail'
import tablecolor from '../../../ReUsableComponent/ReyClinicalinputs.json'
import ViewDetails from './ViewDetails'
import TitleComponent from '../../../ReUsableComponent/TitleComponent';
import CommonButton, { CommonbigButton } from '../ReausableComponent/CommonButton';
function Clinics() {
  const APIkey = process.env.REACT_APP_SERVERAPI
  const navigate = useNavigate()

  const [listData, setListData] = useState<any[]>([])
  const [listclinic, setListclinic] = useState<any[]>([])
  const [searchCLinicList, setSearchCLinicList] = useState<any>([])
  const [clinicNameData, setClinicNameData] = useState<Array<any> | null>()
  const [downSearch, setDownSearch] = useState(false)
  const [clinicId, setClinicId] = useState<string | null>('')
  const [clinicName, setClinicName] = useState<string | null>('')
  const [contactPerson, setcontactperson] = useState<string | null>('')
  const [activationDate, setActivationDate] = useState<any>('')
  const [searchstatus, setsearchStatus] = useState<any>(null)
  const [referBy, setReferBy] = useState<string | null>('')
  const [searchQuery, setSearchQuery] = useState('')
  const [totalCount, setTotalCount] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [openDelete, setopenDelete] = useState(false)
  const [deleteID, setdeleteID] = useState<string | null>(null)
  const [showViewDetails, setShowViewDetails] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  // const deletedEntity = deleteID && listData.find((i) => i._id === deleteID)
  const deletedEntity = deleteID && listData.find((i) => i._id === deleteID)

  const clinicdelete = (id: string) => {
    setopenDelete(true)
    setdeleteID(id)
  }

  const handleClosedelete = () => {
    setopenDelete(false)
  }

  const deleteFunction = async (deleteId: string) => {
    try {
      const getloginInfo: string | null = localStorage.getItem('loginInfo')
      if (getloginInfo !== null) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const header = {headers: {'x-access-token': Token}}
        await axios.delete(`${APIkey}/api/admin/deleteClinic/${deleteId}`, header)
        setopenDelete(false)
        setListData((prev) => prev.filter((item) => item._id !== deleteId))
      }
    } catch (err) {
      console.log(err)
    }
  }

  const editFunction = (editId: string) => {
    navigate(`/clinics/editClinic/${editId}`)
  }

  function Action(id: string, item) {
    return (
      <div className='d-flex gap-2'>
        <button className='blueButtonSmall' onClick={() => handleShowDetails(item)}>
          <i className='fa-solid fa-eye'></i>
        </button>
        <button className='blueButtonSmall' onClick={() => editFunction(id)}>
          <i className='fa-solid fa-pencil'></i>
        </button>
        <button className='blueButtonSmall' onClick={() => clinicdelete(id)}>
          <i className='fa-solid fa-trash'></i>
        </button>
      </div>
    )
  }

  useEffect(() => {
    const getClinicList = async () => {
      try {
        const getloginInfo: string | null = localStorage.getItem('loginInfo')
        if (getloginInfo !== null) {
          const Token = JSON.parse(getloginInfo).data.accessToken
          const header = {headers: {'x-access-token': Token}}
          const clinicList = await axios.get(`${APIkey}/api/admin/listClinic`, header)
          setListData(
            clinicList?.data?.data?.map((data, index) => ({
              ...data,
              SerialNo: index + 1,
            }))
          )
          setListclinic(
            clinicList?.data?.data?.map((i) => ({
              label: i.clinicName,
              value: i.clinicName,
              id: i.clinicName,
            }))
          )
        }
      } catch (err) {
        console.log(err)
      }
    }
    getClinicList()
  }, [APIkey])


  
  const submitSearch = async (data: any) => {
    try {
      const getloginInfo: string | null = localStorage.getItem('loginInfo')
      if (getloginInfo !== null) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const header = {headers: {'x-access-token': Token}}
        const response = await axios.post(`${APIkey}/api/admin/listClinicSearch`, data, header)
        console.log("response: " + response)
        setSearchCLinicList(response?.data?.data || [])
      }
    } catch (error) {
      const axiosError : any= error as AxiosError; // Explicitly tell TypeScript this is an Axios error
    const errorMessage = axiosError?.response?.data?.message || 'An error occurred while searching';
    console.error('Error message:', errorMessage);
      toast.error(errorMessage, {
        type: "error",
        position:"top-right"
      })
      setSearchCLinicList([])
    }
  }

  const handleSearch = () => {
    if (!downSearch) {
      setDownSearch(true)
    } else {
      const data = {
        clinicCode: clinicId,
        clinicName,
        contactPerson,
        activationDate,
        statusCheck: searchstatus,
        referenceId: referBy,
      }
      submitSearch(data)
    }
  }

  const handelReset = () => {
    setSearchCLinicList([])
    setClinicId(null)
    setClinicName(null)
    setcontactperson(null)
    setActivationDate(null)
    setsearchStatus(null)
    setReferBy(null)
  }

  const handelCancel = () => {
    setDownSearch(false)
    // setSearchCLinicList(null)
    // setclinicId(null)
    // setClinicName(null)
    // setcontactperson(null)
    // setActivationDate(null)
    // setsearchStatus(null)
    // setReferBy(null)
  }

  const handleShowDetails = (item) => {
    setSelectedItem(item)
    setShowViewDetails(true)
  }

  const handleCloseDetails = () => {
    setShowViewDetails(false)
    setSelectedItem(null)
  }

  const getsearchClinicId = (e) => {
    setClinicId(e.target.value)
  }

  const getTheClinicName = (e) => {
    setClinicName(e)
  }

  const getsearchContactPerson = (e: React.ChangeEvent<HTMLInputElement>) => {
    setcontactperson(e.target.value)
  }

  const getsearchActivationDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = moment(e.target.value).format('YYYY-MM-DD')
    setActivationDate(value || '')
  }

  const getTheStatus = (e: any) => {
    setsearchStatus(e)
  }

  const getsearchReferBy = (e) => {
    setReferBy(e.target.value)
  }

  const statusList = [
    
    {label: 'Active', value: 'Active', id: 1},
    {label: 'Inactive', value: 'Inactive', id: 2},
  ]

  const rows = listData?.map((item, index) => ({
    SerialNo: index + 1,
    ClinicId: item.clinicCode,
    ClinicName: item.clinicName,
    Address: `${item.addressLine1}${item.addressLine2 ? ',' : ''} ${item.addressLine2 || ''}`,
    ContactPerson: `${item.firstName} ${item.middleName || ''} ${item.lastName}`,
    ActivationDate: item.createdDate,
    Status: item.status === 1 ? 'Active' : 'Inactive',
    referby: '',
    Action: Action(item._id, item),
  }))

  const searchRow = searchCLinicList?.map((item, index) => ({
    SerialNo: index + 1,
    ClinicId: item.clinicCode,
    ClinicName: item.clinicName,
    Address: `${item.addressLine1}${item.addressLine2 ? ',' : ''} ${item.addressLine2 || ''}`,
    ContactPerson: `${item.firstName} ${item.middleName || ''} ${item.lastName}`,
    ActivationDate: item.createdDate,
    Status: item.status === 1 ? 'Active' : 'Inactive',
    referby: '',
    Action: Action(item._id, item),
  }))

  const rowfun = (row: any, searchRow: any) => (searchCLinicList.length === 0 ? row : searchRow)

  console.log(searchCLinicList)

  const columns = [
    {key: ['SerialNo'], header: 'Serial No'},
    {key: ['ClinicId'], header: 'Clinic Id'},
    {key: ['ClinicName'], header: 'Clinic Name'},
    {key: ['Address'], header: 'Address'},
    {key: ['ContactPerson'], header: 'Contact Person'},
    {key: ['ActivationDate'], header: 'Activation Date', isDate: true},
    {key: ['Status'], header: 'Status'},
    {key: ['referby'], header: 'Refer By'},
    {
      key: ['Action'],
      header: 'Action(s)',
    },
  ]

  return (
    <>
      <div className='px-5 fw-bold'>
        <div className='PageHeader '>
        <TitleComponent type='title' className='px-1 '>
            Clinics
          </TitleComponent>
          <div>
            <Link to='/clinics/AddNew'>
            <CommonbigButton type='button'>
                Add Clinic
              </CommonbigButton>
            </Link>
          </div>
        </div>
        <div className='card mt-3 p-3'>
          {downSearch && (
            <>
              <div className='  d-flex justify-content-end'>
                <button className='grayButton' onClick={handelCancel}>
                  <i className='fa fa-close'></i>
                </button>
              </div>
              <div className='row'>
                <div className='col-md-6 col-lg-3'>
                <TitleComponent type='label' className='px-1 '>
                    {inputText.clinicId.label}
                  </TitleComponent>
                  <input
                    type='number'
                    className='form-control SearchInputbox'
                    onChange={getsearchClinicId}
                    value={clinicId || ''}
                  />
                </div>
                <div className='col-md-6 col-lg-3'>
                <TitleComponent type='label' className='px-1 '>
                    {inputText.ClinicName.label}
                  </TitleComponent>
                  <SerchableDropdown
                    gettingData={getTheClinicName}
                    InitialValue={clinicName}
                    options={listclinic}
                  />
                </div>

                <div className='col-md-6 col-lg-3'>
                <TitleComponent type='label' className='px-1 '>
                    {inputText.contactPerson.label}
                  </TitleComponent>
                  <input
                    type='text'
                    value={contactPerson || ''}
                    className='form-control SearchInputbox'
                    onChange={getsearchContactPerson}
                  />
                </div>
                <div className='col-md-6 col-lg-3 '>
                <TitleComponent type='label' className='px-1 '>
                    {inputText.activationDate.label}
                  </TitleComponent>
                  <input
                    type='date'
                    value={activationDate || ''}
                    className='form-control SearchInputbox'
                    onChange={getsearchActivationDate}
                  />
                </div>
              </div>
            </>
          )}
          {downSearch && (
            <div className='row mt-2'>
              <div className='col-md-6 col-lg-3'>
              <TitleComponent type='label' className='px-1 '>
                  {inputText.status.label}
                </TitleComponent>
                <SerchableDropdown
                  InitialValue={searchstatus}
                  gettingData={getTheStatus}
                  options={statusList}
                />
              </div>
              <div className='col-md-6 col-lg-3'>
              <TitleComponent type='label' className='px-1 '>
                  {inputText.referby.label}
                </TitleComponent>
                <input
                  type='text'
                  onChange={getsearchReferBy}
                  value={referBy || ''}
                  className='form-control SearchInputbox'
                />
              </div>

              <div className={'col-md-6 col-lg-3 '}></div>

              <div
                className={'col-md-6 col-lg-3  d-flex align-items-end justify-content-end gap-2'}
              >
                <CommonbigButton background= {'#D7D7D7'} color={'#818181'}  onClick={handelReset}>
                  Reset
                </CommonbigButton>
                <CommonbigButton  onClick={() => handleSearch()}>
                  <img src={searchiconwhite} alt='search icon' />
                  Search
                </CommonbigButton>
              </div>
            </div>
          )}
          {!downSearch && (
            <div className={'col-md-2 offset-md-10 d-flex justify-content-end '}>
              <CommonButton onClick={() => handleSearch()}>
                <img src={searchiconwhite} alt='search icon' />
                Search
              </CommonButton>
            </div>
          )}
        </div>

        <div className='card mt-3 p-3'>
          <div className='PageHeader'>
          <TitleComponent type='sectionTitle' className='px-1 '>
              <span>Search Result</span>
            </TitleComponent>
          </div>
          {/* <MDBDataTable
            striped
            bordered
            small
            data={data}
            className="custom-table"
          /> */}
          <CommonTable
            data={rowfun(rows, searchRow)}
            columns={columns}
            totalCount={rows.length}
            perPage={perPage}
            setPerPage={setPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            searchQuery={searchQuery}
            setQuery={setSearchQuery}
            headerColor={tablecolor.primaryTable.color}
            issetPaginationCompo={true}
            isPagelength={true}
            isSearched={true}
          />
        </div>

        <ToastContainer />
      </div>

      <ViewDetails
        show={showViewDetails}
        handleClose={handleCloseDetails}
        item={selectedItem}
      />

      {openDelete && (
        <DeleteModel
          openDelete={openDelete}
          closedelete={handleClosedelete}
          deleteID={deleteID}
          // deletedEntity={deletedEntity[0].clinicName}
          deletedEntity={deletedEntity ? deletedEntity.clinicName : 'No Clinic'}
          deleteFunction={deleteFunction}
        />
      )}
    </>
  )
}

export default Clinics