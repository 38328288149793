import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'

const ViewProblemList = ({onshow,onhide,viewId,}) => {
    const APIkey = process.env.REACT_APP_SERVERAPI
    const [icdCode,setIcdCode] = useState('')
    const [icdName,setIcdName] = useState('')
    const [notes,setNotes]= useState('')



    useEffect(()=>{
        const fetchAllergyData = async()=>{
            try{
                const getLoginInfo = localStorage.getItem('appLoginInfo')
                console.log('getLoginInfo: ', getLoginInfo)

                if(getLoginInfo){
                    const { data : {accessToken}} = JSON.parse(getLoginInfo) // Corrected property name
                    const headers = {'x-access-token': accessToken}

                    const response = await axios.get(
                        `${APIkey}/api/admin/getPreferredIcd/${viewId}`,
                        {headers}
                      )
                      const data = response.data.data
                      console.log("response:",data)

                      setIcdCode(data.icdCode)
                      setIcdName(data.icdName)
                      setNotes(data.notes)
          
                }else{
                    console.error('No login info found in localStorage')
                }
            }
            catch(error){
                console.error('Error fetching allergy data:', error)}
        }

        if (viewId) {
            fetchAllergyData()
        }  
    },[viewId]
)

const handleCloseModal = () => {
  setIcdCode('')
  setIcdName('')
  setNotes('')
  onhide()
}

  return (
   <Modal show={onshow} onHide={handleCloseModal} size='lg' centered>
          <Modal.Header closeButton className='h-25px dialoge align-items-center pb-3' >
        <div className='PageHeaderTitle'> Problem Information</div>
      </Modal.Header>
      <Modal.Body className='p-2 dialoge'>
        <div className='dashboard-card'>
            <div className='col-md-12 d-flex'>
                <div className='col-md-6 mb-3'>
                <div className='mb-2'>
             <label className='viewlabels'>ICD Code:</label>
             <div className='vaccine-label-data'>{icdCode}</div></div>

             <div className='mb-2'>
             <label className='viewlabels'>Notes:</label>
             <div className='vaccine-label-data'>{notes}</div></div>


                </div>
                <div className='col-md-6 mb-3'>
                <div className='mb-2'>
             <label className='viewlabels'>ICD Name:</label>
             <div className='vaccine-label-data'>{icdName}</div></div>
</div>

            </div>
        </div>
      </Modal.Body>
      <Modal.Footer>   
        <div className='my-2 d-flex justify-content-end gap-3'>
        <button type='button' className='tertiarybig' onClick={handleCloseModal}>
              Close
            </button>
        </div>
        </Modal.Footer>
   </Modal>
  )
}

export default ViewProblemList
