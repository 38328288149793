import { FC, useEffect, useState } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import { SuperAdmin } from '../SuperAdmin'
import SuperPrivateRoutes from './SuperPrivateRoutes'
import { AdminError } from '../../SuperAdmin/ErrorPage/AdminError'
import { AdminAuthPage } from '../../SuperAdmin/AdminAuth/AdminAuthPage'
import SuperLayout from '../../SuperAdmin/SuperLayout/SuperLayout'
import Clinics from '../../SuperAdmin/Pages/Clinics/Clinics'
import AddClinic from '../../SuperAdmin/Pages/Clinics/AddClinic'
import User from '../../SuperAdmin/Pages/User/User'
import AddUser from '../../SuperAdmin/Pages/User/AddUser'
import Billing from '../../SuperAdmin/Pages/Billing/Billing'
import AnalyticalReports from '../../SuperAdmin/Pages/AnalyticalReports/AnalyticalReports'
import UpdateUser from '../../SuperAdmin/Pages/User/UpdateUser'
import UpdateClinic from '../../SuperAdmin/Pages/Clinics/UpdateClinic'
import ManagePermission from '../../SuperAdmin/Pages/User/ManagePermission'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

console.log(PUBLIC_URL)

const AppRoutes: FC = () => {
  const { currentUser } = useAuth()
  
  console.log("currentUser", currentUser)

  // const Admin = localStorage.getItem("loginInfo");
  const loginApp = true;
  // const loginAdmin = true;

  return (
    <BrowserRouter basename={PUBLIC_URL} >
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {
            localStorage.getItem('appLoginInfo') ? (
              <>
                <Route path='/*' element={<PrivateRoutes />} />
                <Route index element={<Navigate to='/dashboard' />} />
              </>
            ) : (
              <>
                <Route path='/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/login' />} />
              </>
            )}
        </Route>



        <Route element={<SuperAdmin />}>
          {/* <Route path='Admin/AdminError/*' element={<AdminError />} /> */}

          {
            localStorage.getItem('loginInfo')  ? (

              <>

                <Route element={<SuperLayout />}>
                  {/* <Route path="/adminDashboard" element={<SuperPrivateRoutes />} /> */}
                  <Route path="/adminDashboard/*" element={<SuperPrivateRoutes />} />
                  <Route path="/clinics" element={<Clinics />} />
                  <Route path='clinics/addNew' element={<AddClinic />} ></Route>
                  <Route path='clinics/editClinic/:id' element={<UpdateClinic />} ></Route>
                  <Route path="user" element={<User />} ></Route>
                  <Route path='user/addNew' element={<AddUser />} ></Route>
                  <Route path='user/managePermission/:clinicId/:userId' element={<ManagePermission />} ></Route>
                  <Route path='user/editUser/:clinicparamsId/:userparamsId' element={<UpdateUser />} ></Route>
                  <Route path="billing" element={<Billing />}></Route>
                  <Route path="analyticalReports" element={<AnalyticalReports />}></Route>
                  <Route index element={<Navigate to='/adminDashboard' />} />
                </Route>

              </>

            )
              :
              (
                <>

                  <Route path='admin/*' element={<AdminAuthPage />} />
                  {/* <Route path='admin/*' element={<Navigate to='/admin/auth' />} /> */}
                </>
              )

          }

        </Route>

      </Routes>


    </BrowserRouter>
  )
}

export { AppRoutes }
