import React from 'react'
import { Outlet } from 'react-router-dom'
import { SuperHeaderWrap } from './SuperHeader.tsx/SuperHeaderWrap'
import { FooterWrapper } from './SuperFooter'


function SuperLayout() {
  return (
    <>
    
    <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
        <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
            <SuperHeaderWrap />
          {/* <HeaderWrapper /> */}
          <div className='wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
            {/* <Sidebar /> */}
            {/* <div className='app-main flex-column flex-row-fluid' id='kt_app_main'> */}
              <div className='d-flex flex-column flex-column-fluid'>
                {/* <ToolbarWrapper /> */}
              
                  <Outlet />
               
              </div>
              
              {/* <FooterWrapper /> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default SuperLayout
