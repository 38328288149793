import React, {useEffect} from 'react'
import {useState} from 'react'
import CommonTable from '../../../../ReUsableComponent/CommonTable'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'
import tablecolor from '../../../../ReUsableComponent/ReyClinicalinputs.json'

const AddMedication = ({onShow, onHide, onClick}) => {
  const APIkey = process.env.REACT_APP_SERVERAPI
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const [modalData, setModalData] = useState([])
  const [selectedId, setSelectedId] = useState<string[]>([])

  const [searchCriteria, setSearchCriteria] = useState({
    medication: '',
    brandName: '',
    form: '',
    frequency: '',
    routeOfAdministration: '',
    storageRequirements: '',
  })

  const handleSearchCriteriaChange = (e) => {
    const {name, value} = e.target
    setSearchCriteria((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSearch = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      console.log('SearchCt', searchCriteria)
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        console.log('token', Token)

        const header = {
          headers: {'x-access-token': Token},
        }
        console.log(header)

        const response = await axios.post(
          `${APIkey}/api/admin/searchMedication`,
          searchCriteria,
          header
        )

        const responseData = response.data.data

        const searchData = responseData.map((item, index) => ({
          srNo: index + 1,
          medication: item.medication,
          brandName: item.brandName,
          form: item.form,
          frequency: item.frequency,
          routeOfAdministration: item.routeOfAdministration,
          storageRequirements: item.storageRequirements,

          _id: item._id,
        }))
        setModalData(searchData)
      } else {
        console.log('No Login Info found in Local Storage')
      }
    } catch (error) {
      console.log('Error fetching vaccination data:', error)
    }
  }

  const saveData = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const headers = {'x-access-token': Token}

        const response = await axios.post(
          `${APIkey}/api/admin/createPreferredMedication`,
          {medicationId: selectedId},
          {headers}
        )
        console.log('API response:', response)
        if (response) {
          onHide()
        }
      } else {
        console.log('No login info found in localStorage')
      }
    } catch (error) {
      console.log('Error in posting data:', error)
    }
  }

  const Component = ({id}) => {
    const handleCheckbox = (e) => {
      const {value, checked} = e.target

      if (checked) {
        setSelectedId((prev) => {
          if (!prev.includes(value)) {
            return [...prev, value]
          }
          return prev
        })
      } else {
        setSelectedId((prev) => prev.filter((item) => item !== value))
      }
    }

    return (
      <input
        type='checkbox'
        value={id}
        checked={selectedId.some((item) => item === id)}
        onChange={(e) => handleCheckbox(e)}
      />
    )
  }

  const resetFun = () => {
    setSearchCriteria({
      medication: '',
      brandName: '',
      form: '',
      frequency: '',
      routeOfAdministration: '',
      storageRequirements: '',
    })
    fetchMedicationdata()
  }

  const AddMedicationcolumns = [
    {key: ['srNo'], header: 'Sr.No'},
    {
      key: ['select'],
      header: 'Select',
      isAction: true,
      ActionComponent: Component,
    },
    {key: ['medication'], header: 'Medicine Name'},
    {key: ['brandName'], header: 'Brand Name'},
    {key: ['form'], header: 'Types of Medicine'},
    {key: ['frequency'], header: 'Dosage Instruction'},
    {key: ['routeOfAdministration'], header: 'Way to Take Medicine'},
    {key: ['storageRequirements'], header: 'Storage requirements'},
  ]

  const fetchMedicationdata = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const headers = {'x-access-token': Token}
        const response = await axios.get(`${APIkey}/api/admin/getMedicationList`, {headers})
        const responseData = response.data.data

        const formattedData = responseData.map((item, index) => ({
          srNo: index + 1,
          medication: item.medication,
          brandName: item.brandName,
          form: item.form,
          frequency: item.frequency,
          routeOfAdministration: item.routeOfAdministration,
          storageRequirements: item.storageRequirements,

          _id: item._id,
        }))
        setModalData(formattedData)
      } else {
        console.log('No login found in LocalStorage')
      }
    } catch (error) {
      console.log('Error fetching medication data:', error)
    }
  }
  useEffect(() => {
    fetchMedicationdata()
  }, [])

  const handleRowClick = (row) => {
    console.log('Row clicked with ID:', row)
  }

  const handleCloseModal = () => {
    onHide()
  }

  return (
    <>
      <Modal show={onShow} onHide={handleCloseModal} size='xl' className='w-100'centered>
        <Modal.Header closeButton className=' h-25px dialoge align-items-center pb-3'>
          <div className='PageHeaderTitle'>Add Medication:</div>
         
        </Modal.Header>
        <Modal.Body className='p-2 dialoge'>
          <div className='dashboard-card p-2'>
            {/* <div className='d-flex gap-3 col-md-12'> */}
            <div className='col-md-12 d-flex'>
              <div className='col-md-4 '>
                <div className='mb-3'>
                  <div className='addvaccinetitle'>Medicine Name:</div>
                  <input
                    type='text'
                    name='medication'
                    className='SearchInputbox'
                    placeholder=''
                    value={searchCriteria.medication}
                    onChange={handleSearchCriteriaChange}
                  />
                </div>
                <div className='mb-3 '>
                  <div className='addvaccinetitle'>Way to Take Medicine:</div>
                  <input
                    type='text'
                    className='SearchInputbox'
                    name='routeOfAdministration'
                    placeholder=''
                    value={searchCriteria.routeOfAdministration}
                    onChange={handleSearchCriteriaChange}
                  />
                </div>
              </div>
              &nbsp;

              <div className='col-md-4'>
                <div className='mb-3 '>
                  <div className='addvaccinetitle '> Brand Name:</div>
                  <input
                    type='text'
                    className='SearchInputbox'
                    name='brandName'
                    placeholder=''
                    value={searchCriteria.brandName}
                    onChange={handleSearchCriteriaChange}
                  />
                </div>
                <div className='mb-3 '>
                  <div className='addvaccinetitle'>Dosage Instruction:</div>
                  <input
                    type='text'
                    className='SearchInputbox'
                    name='frequency'
                    placeholder=''
                    value={searchCriteria.frequency}
                    onChange={handleSearchCriteriaChange}
                  />
                </div>
              </div>
              &nbsp;
              <div className='col-md-4'>
                <div className='mb-3 '>
                  <div className='addvaccinetitle'>Type of Medicine:</div>
                  <input
                    type='text'
                    className='SearchInputbox'
                    name='form'
                    placeholder=''
                    value={searchCriteria.form}
                    onChange={handleSearchCriteriaChange}
                  />
                </div>

                <div className='mb-3 '>
                  <div className='addvaccinetitle'>Storage Requirements:</div>
                  <input
                    type='text'
                    className='SearchInputbox'
                    name='storageRequirements'
                    placeholder=''
                    value={searchCriteria.storageRequirements}
                    onChange={handleSearchCriteriaChange}
                  />
                </div>
              </div>
            </div>

            <div className='d-flex  gap-3 justify-content-end'>
              <button type='button' className='primarybtnBig' onClick={handleSearch}>
                Search
              </button>
              <button
                type='button'
                className='tertiarybig'
                onClick={() => [console.log('clear btn'), resetFun()]}
              >
                Clear
              </button>
              {/* </div> */}
            </div>
            {/* </div> */}
          </div>

          <div className='dashboard-card mt-2'>
            {/*  {loadingModalData ? (
              <p>Loading...</p>
            ) : ( */}
            <CommonTable
              columns={AddMedicationcolumns}
              data={modalData}
              totalCount={modalData.length}
              setCurrentPage={setCurrentPage}
              setPerPage={setPerPage}
              searchQuery={searchQuery}
              perPage={perPage}
              currentPage={currentPage}
              setQuery={setSearchQuery}
              headerColor={tablecolor.primaryTable.color}
              issetPaginationCompo = {false}
              isPagelength={false}
              isSearched={false}
              onRowClick={handleRowClick}
            />
            {/* )} */}
          </div>
        </Modal.Body>
        <Modal.Footer>
        <div className='mt-3'>
            <button
              className='primarybtnBig '
              onClick={saveData}
              disabled={selectedId.length === 0}
            >
              Save
            </button>
            &nbsp;&nbsp;&nbsp;
            <button className='tertiarybig ' onClick={handleCloseModal}>
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddMedication
