export const breakpoints = {
    large: { min: 1440 },
    laptop: { min: 1024 },
    tablet: { min: 744 },
    phone: { min: 390 },
  };
  
  export const stylesConfig = {
    title: {
      large: { fontSize: 20, fontWeight: 700, lineHeight: 24 },
      laptop: { fontSize: 18, fontWeight: 700, lineHeight: 22 },
      tablet: { fontSize: 14, fontWeight: 600, lineHeight: 16 },
      phone: { fontSize: 12, fontWeight: 600, lineHeight: 14 },
    },
    label: {
        large: { fontSize: 14, fontWeight: 500, lineHeight: 24 },
        laptop: { fontSize: 12, fontWeight: 500, lineHeight: 20 },
        tablet: { fontSize: 12, fontWeight: 500, lineHeight: 20 },
        phone: { fontSize: 12, fontWeight: 500, lineHeight: 20 },
      },
      popUpButton: {
        large: { fontSize: 14, fontWeight: 700, lineHeight: 16 },
        laptop: { fontSize: 12, fontWeight: 700, lineHeight: 14 },
        tablet: { fontSize: 10, fontWeight: 600, lineHeight: 12 },
        phone: { fontSize: 10, fontWeight: 600, lineHeight: 12 },
      },  
      smallSizeButton: {
        large: { fontSize: 10, fontWeight: 500, lineHeight: 12 },
        laptop: { fontSize: 10, fontWeight: 500, lineHeight: 12 },
        tablet: { fontSize: 10, fontWeight: 500, lineHeight: 12 },
        phone: { fontSize: 10, fontWeight: 500, lineHeight: 12 },
      },
      header: {
        large: { fontSize: 12, fontWeight: 500, lineHeight: 24 },
        laptop: { fontSize: 10, fontWeight: 500, lineHeight: 20 },
        tablet: { fontSize: 10, fontWeight: 500, lineHeight: 12 },
        phone: { fontSize: 10, fontWeight: 500, lineHeight: 12 },
      },
      sectionTitle: {
        large: { fontSize: 16, fontWeight: 600, lineHeight: 24 },
        laptop: { fontSize: 14, fontWeight: 600, lineHeight: 22 },
        tablet: { fontSize: 14, fontWeight: 600, lineHeight: 22 },
        phone: { fontSize: 14, fontWeight: 600, lineHeight: 22 },
      },
      mainButton: {
        large: { fontSize: 16, fontWeight: 600, lineHeight: 24 },
        laptop: { fontSize: 14, fontWeight: 600, lineHeight: 20 },
        tablet: { fontSize: 14, fontWeight: 600, lineHeight: 20 },
        phone: { fontSize: 12, fontWeight: 600, lineHeight: 16 },
      },
      tableCellHeader: {
        large: { fontSize: 16, fontWeight: 500, lineHeight: 24 },
        laptop: { fontSize: 14, fontWeight: 500, lineHeight: 20 },
        tablet: { fontSize: 14, fontWeight: 500, lineHeight: 20 },
        phone: { fontSize: 12, fontWeight: 500, lineHeight: 20 },
      },
      tableCell: {
        large: { fontSize: 14, fontWeight: 500, lineHeight: 24 },
        laptop: { fontSize: 14, fontWeight: 500, lineHeight: 20 },
        tablet: { fontSize: 12, fontWeight: 500, lineHeight: 16 },
        phone: { fontSize: 10, fontWeight: 500, lineHeight: 14 },
      },
      loginBodyText: {
        large: { fontSize: 18, fontWeight: 500, lineHeight: 24 },
        laptop: { fontSize: 16, fontWeight: 500, lineHeight: 22 },
        tablet: { fontSize: 16, fontWeight: 500, lineHeight: 22 },
        phone: { fontSize: 14, fontWeight: 500, lineHeight: 20 },
      },
      loginTitle:{
        large: { fontSize: 36, fontWeight: 600, lineHeight: 42 },
        laptop: { fontSize: 32, fontWeight: 600, lineHeight: 38 },
        tablet: { fontSize: 24, fontWeight: 600, lineHeight: 28 },
        phone: { fontSize: 20, fontWeight: 600, lineHeight: 28 },
      },
      loginButton:{
        large: { fontSize: 18, fontWeight: 700, lineHeight: 24 },
        laptop: { fontSize: 16, fontWeight: 600, lineHeight: 22 },
        tablet: { fontSize: 14, fontWeight: 600, lineHeight: 20 },
        phone: { fontSize: 14, fontWeight: 600, lineHeight: 20 },
      }
  };
  