// import { useState } from 'react';
// import clsx from 'clsx';
// import { Link, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { toAbsoluteUrl } from '../../../_metronic/helpers';
// import inputText from '../../../ReUsableComponent/ReyClinicalinputs.json';

// // Initial form values
// const initialValues = {
//   email: 'admin@demo.com',
//   password: 'demo',
// };

// export function Login() {
//   const APIKey = process.env.REACT_APP_SERVERAPI;
//   const [loading, setLoading] = useState(false);
//   const [showPassword, setShowPassword] = useState(false);
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const [invalidUserName, setInvalidUserName] = useState(false);
//   const [invalidPass, setInvalidPass] = useState(false);
//   const [invalid, setInvalid] = useState(false);

//   const navigate = useNavigate();

//   // Toggle password visibility
//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   // Handle input changes
//   const handleInputChange = (setter) => (e) => {
//     setter(e.target.value);
//     setInvalid(false); // Reset invalid states on input change
//   };

//   // Handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();
    
//     // Basic validation
//     let hasError = false;
//     if (!username) {
//       setInvalidUserName(true);
//       hasError = true;
//     } else {
//       setInvalidUserName(false);
//     }

//     if (!password) {
//       setInvalidPass(true);
//       hasError = true;
//     } else {
//       setInvalidPass(false);
//     }

//     if (hasError) {
//       return;
//     }

//     setLoading(true);
    
//     const data = { username, password };
//     try {
//       await login(data);
//     } catch (error) {
//       console.error(error);
//       setLoading(false);
//       setInvalid(true);
//     }
//   };

//   const login = async (data) => {
//     try {
//       const result = await axios.post(`${APIKey}/api/auth/signin`, data);
//       const loginInfo = JSON.stringify(result.data);
//       localStorage.setItem('loginInfo', loginInfo);
//       setLoading(false);
//       window.location.href = '/adminDashboard'
//     } catch (error) {
//       console.error(error);
//       setLoading(false);
//       setInvalid(true);
//     }
//   };

//   return (
//     <div>
//       <div className='card custom-card-width-login'>
//         <div className='card-body'>
//           <form
//             className='form w-100'
//             onSubmit={handleSubmit}
//             noValidate
//             id='kt_login_signin_form'
//           >

//             <div className='text-center mb-11'>
//               <h1 className='text-dark fw-bolder mb-3'>
//                 <img
//                   alt='Logo'
//                   src={toAbsoluteUrl('/media/logos/ReyCLinicalLogoSvg.svg')}
//                   className='h-20px h-lg-30px app-sidebar-logo-default'
//                 />
//               </h1>
//               <div className='fw-semibold'>Welcome to MediNiv Electronic Health Record</div>
//             </div>

//             {invalid && (
//               <div className='mb-lg-5 alert alert-danger'>
//                 <div className='alert-text fw-bold'>{inputText.loginForm.ErrMsg}</div>
//               </div>
//             )}

//             {/* begin::Form group */}
//             <div className='fv-row mb-8'>
//               <label className='formLabelTitle fw-bolder'>{inputText.username.label}</label>
//               <input
//                 onChange={handleInputChange(setUsername)}
//                 placeholder={inputText.username.label}
//                 className={clsx(
//                   'form-control bg-transparent',
//                   { 'is-invalid': invalidUserName }
//                 )}
//                 type='email'
//                 name='email'
//                 autoComplete='off'
//               />
//               {invalidUserName && (
//                 <div className='fv-plugins-message-container'>
//                   <span role='alert' style={{ color: 'red' }}>
//                     {inputText.username.ErrMsg || 'Please enter your username'}
//                   </span>
//                 </div>
//               )}
//             </div>
          
//             <div className='fv-row mb-3'>
//               <label className='formLabelTitle fw-bolder'>{inputText.password.label}</label>
//               <div className='input-group'>
//                 <input
//                   onChange={handleInputChange(setPassword)}
//                   placeholder={inputText.password.placeholder}
//                   type={showPassword ? 'text' : 'password'}
//                   autoComplete='off'
//                   className={clsx('form-control bg-transparent')}
//                 />
//                 <div className='input-group-text'>
//                   <i
//                     onClick={togglePasswordVisibility}
//                     className={`bi bi-eye${showPassword ? '-slash' : ''}`}
//                     style={{ cursor: 'pointer' }}
//                   ></i>
//                 </div>
//               </div>
//               {invalidPass && (
//                 <div className='fv-plugins-message-container'>
//                   <div className='fv-help-block'>
//                     <span role='alert'>{inputText.password.ErrMsg || 'Please enter your password'}</span>
//                   </div>
//                 </div>
//               )}
//             </div>
//             <div className='d-grid mb-10'>
//               <button
//                 //onClick={(e)=> handleSubmit}
//                 // onClick={handleSubmit}
//                 onClick={(e) => handleSubmit(e)}
//                 id='kt_sign_in_submit'
//                 className='primarybtnBig'
//                 type='button'

//                 // disabled={formik.isSubmitting || !formik.isValid}
//               >
//                 {/* <span className='indicator-label'>Continue</span> */}

//                 {!loading && <span className='indicator-label'>Login</span>}

//                 {loading && (
//                   <span className='indicator-progress' style={{display: 'block'}}>
//                     Please wait...
//                     <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
//                   </span>
//                 )}
//               </button>
//             </div>
//             <div className='text-gray-500 text-left fw-semibold fs-6 mb-6'>
//               <Link to='/Admin/retrieve-Usename'>
//                 Retrieve your username
//               </Link>
//             </div>
//             <div className='text-gray-500 text-left fw-semibold fs-6 mb-6'>
//               <Link to='/Admin/forgot-password'>
//                 Retrieve your password
//               </Link>
//             </div>
//           </form>
//         </div>
//       </div>

//       <div className='image-back'>
//         <img src={toAbsoluteUrl('/media/auth/backgoundoflogin.png')} className='image-back-width' />
//       </div>
//     </div>
//   );
// }







// // /* eslint-disable jsx-a11y/anchor-is-valid */
// // import {useEffect, useState} from 'react'
// // import * as Yup from 'yup'
// // import clsx from 'clsx'
// // import {Link , useNavigate} from 'react-router-dom'
// // import {useFormik} from 'formik'
// // import {getUserByToken, login} from '../../../app/modules/auth/core/_requests'
// // import {toAbsoluteUrl} from '../../../_metronic/helpers'
// // import {useAuth} from '../../../app/modules/auth/core/Auth'
// // import axios from 'axios'
// // import inputText from '../../../ReUsableComponent/ReyClinicalinputs.json'


// // const loginSchema = Yup.object().shape({
// //   email: Yup.string()
// //     .email('Wrong email format')
// //     .min(3, 'Minimum 3 symbols')
// //     .max(50, 'Maximum 50 symbols')
// //     .required('Email is required'),
// //   password: Yup.string()
// //     .min(3, 'Minimum 3 symbols')
// //     .max(50, 'Maximum 50 symbols')
// //     .required('Password is required'),
// // })

// // const initialValues = {
// //   email: 'admin@demo.com',
// //   password: 'demo',
// // }

// // /*
// //   Formik+YUP+Typescript:
// //   https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
// //   https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
// // */

// // export function Login() {

// //   const APIKey = process.env.REACT_APP_SERVERAPI ; 



// //     console.log("This is APIServer", APIKey);

  

// //   const [loading, setLoading] = useState(false)
// //   // const {saveAuth, setCurrentUser } = useAuth()
// //   const [showPassword, setShowPassword] = useState(false);

// //   const togglePasswordVisibility = () => {
// //     setShowPassword(!showPassword);
// //   };


// //   const navigate = useNavigate()

// //   // const formik = useFormik({
// //   //   initialValues,
// //   //   validationSchema: loginSchema,
// //   //   onSubmit: async (values, {setStatus, setSubmitting}) => {
// //   //     setLoading(true)
// //   //     try {
// //   //       const {data: auth} = await login(values.email, values.password)
// //   //       saveAuth(auth)
// //   //       const {data: user} = await getUserByToken(auth.api_token)
// //   //       setCurrentUser(user)
// //   //     } catch (error) {
// //   //       console.error(error)
// //   //       saveAuth(undefined)
// //   //       setStatus('The login details are incorrect')
// //   //       setSubmitting(false)
// //   //       setLoading(false)
// //   //     }
// //   //   },
// //   // })


// //   const [username , setUsername ] = useState<undefined | string>();
// //   const [password , setPassWord ] = useState<undefined | string>();
// //  const [invalidUserName , setinValidUserName] = useState<undefined | boolean>(false); 
// //  const [inValidPass , setinValidPass] = useState<undefined | boolean>(false); 
// //  const [invalid , setinvalid] =  useState<undefined | boolean>(false); 

// //   const getUsername = (e) => {
// //     setUsername(e.target.value)
// //   }
// //   const getPass = (e) => {
// //     setPassWord(e.target.value)
// //   }
  

 


  
// //   const handleSubmit = (e)=> {
// //     e.preventDefault();
    

// //     const data = {
// //       password, 
// //       username
// //     }

// //      setLoading(true)

// //          login(data); 
  
// //   }

// //   const login =  async(data) => {

// //     try{

      
      
// //       const result = await axios.post(`${APIKey}/api/auth/signin`, data)

// //       const loginInfo = JSON.stringify(result.data) ;
// //       const error = result.data;
// //       console.log(error);
      
// //       //console.log(loginInfo);

// //       localStorage.setItem("loginInfo", loginInfo )
      
// //       setLoading(false)
// //        //navigate("adminDashboard")
// //       window.location.href = "/adminDashboard";
// //     }
// //     catch(error){

// //       console.log(error);
// //       setLoading(false);
// //       setinvalid(true)

// //     }
// //    }

// //   return (
   
// // <div>
// // <div className="card custom-card-width-login">
// //     <div className="card-body">
// //     <form
// //       className='form w-100'
// //       // onSubmit={handleSubmit}
// //       noValidate
// //       id='kt_login_signin_form'
// //     >
// //       {/* begin::Heading */}
// //       <div className='text-center mb-11'>
// //         <h1 className='text-dark fw-bolder mb-3'>
// //         <img
// //                 alt='Logo'
// //                 src={toAbsoluteUrl('/media/logos/ReyCLinicalLogoSvg.svg')}
// //                 className='h-20px h-lg-30px app-sidebar-logo-default'
// //               />
// //         </h1>

// //         <div className='fw-semibold'>Welcome to Rey Clinical Electronic Health Record</div>

// //       </div>
// //       {/* begin::Heading */}

     

     

// //       {invalid && (
// //         <div className='mb-lg-5 alert alert-danger'>
// //           <div className='alert-text fw-bold'>{inputText.loginForm.ErrMsg}</div>
// //         </div>
// //       ) 
// //       // : (
// //       //   <div>
// //       //     <div className='text-info'>
// //       //       Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
// //       //       continue.
// //       //     </div>
// //       //   </div>
// //       // )
// //       }

// //       {/* begin::Form group */}
// //       <div className='fv-row mb-8'>
 
// //         <label className='formLabelTitle'>{inputText.username.label}</label>

// //         <input
// //         onChange={(e)=> getUsername(e) }
// //           placeholder={inputText.username.label}
// //           // {...formik.getFieldProps('email')}
// //           className={clsx(
// //             'form-control bg-transparent ', 
// //             // {'is-invalid': !username },
// //             // {
// //             //   'is-valid': username,
// //             // }
// //           )}
// //           type='email'
// //           name='email'
// //           autoComplete='off'
// //         />
// //         { invalidUserName && (
// //           <div className='fv-plugins-message-container'>
// //             <span role='alert ' style={{color:"red"}} >{inputText.username.ErrMsg}</span>
// //           </div>
// //         )}
// //       </div>
// //       {/* end::Form group */}

// //       {/* begin::Form group */}
// //       {/* <div className='fv-row mb-3'>
// //         <label className='form-label fw-bolder text-dark fs-6'>Password</label>
// //         <input
// //           type='password'
// //           autoComplete='off'
// //           {...formik.getFieldProps('password')}
// //           className={clsx(
// //             'form-control bg-transparent',
// //             {
// //               'is-invalid': formik.touched.password && formik.errors.password,
// //             },
// //             {
// //               'is-valid': formik.touched.password && !formik.errors.password,
// //             }
// //           )}
// //         />
// //         {formik.touched.password && formik.errors.password && (
// //           <div className='fv-plugins-message-container'>
// //             <div className='fv-help-block'>
// //               <span role='alert'>{formik.errors.password}</span>
// //             </div>
// //           </div>
// //         )}
// //       </div> */}
// //       <div className='fv-row mb-3'>

// //   <label className='formLabelTitle'>{inputText.password.label}</label>

// //   <div className='input-group'>
// //     <input
// //     onChange={(e)=> getPass(e)}
// //     placeholder={inputText.password.placeholder}
// //       type={showPassword ? 'text' : 'password'}
// //       autoComplete='off'
// //       // {...formik.getFieldProps('password')}
// //       className={clsx(
// //         'form-control bg-transparent',
// //         // {
// //         //   'is-invalid': formik.touched.password && formik.errors.password,
// //         // },
// //         // {
// //         //   'is-valid': formik.touched.password && !formik.errors.password,
// //         // }
// //       )}
// //     /> 

    
// //     <div className='input-group-text'>
// //       <i
// //         onClick={togglePasswordVisibility}
// //         className={`bi bi-eye${showPassword ? '-slash' : ''}`}
// //         style={{ cursor: 'pointer' }}
// //       ></i>
// //     </div>
// //   </div>
// //   { inValidPass && (
// //     <div className='fv-plugins-message-container'>
// //       <div className='fv-help-block'>
// //         <span role='alert'>{inputText.password.ErrMsg}</span>
// //       </div>
// //     </div>
// //   )}
// // </div>

// //       {/* end::Form group */}

// //       {/* begin::Wrapper */}
// //       <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
// //         <div />

// //         {/* begin::Link */}
        
// //         {/* end::Link */}
// //       </div>
// //       {/* end::Wrapper */}

// //       {/* begin::Action */}
// //       <div className='d-grid mb-10'>
// //         <button
// //           //onClick={(e)=> handleSubmit}
// //          // onClick={handleSubmit}
// //          onClick={(e) => handleSubmit(e)}
// //           id='kt_sign_in_submit'
// //           className='btn btn-primary'
// //           type='button'

// //           // disabled={formik.isSubmitting || !formik.isValid}
// //         >
// //           {/* <span className='indicator-label'>Continue</span> */}

// //           {!loading && <span className='indicator-label'>Login</span>}

// //           {loading && (
// //             <span className='indicator-progress' style={{display: 'block'}}>
// //               Please wait...
// //               <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
// //             </span>
// //           )}
// //         </button>
// //       </div>
// //       {/* end::Action */}
// //       <div className='text-gray-500 text-left fw-semibold fs-6 mb-6'>
// //         {/* Not a Member yet? */}

// //         <Link to='/Admin/retrieve-Usename' className=''>
// //         Retrieve your username
// //         </Link>
// //       </div>
// //       <div className='text-gray-500 text-left fw-semibold fs-6 mb-6'>
// //         {/* Not a Member yet? */}

// //         <Link to='/Admin/forgot-password' className=''>
// //         Retrieve your password
// //         </Link>
// //       </div>
// //     </form>
    
// //     </div>
    
// //     </div>
    
// //     <div className='image-back'>
// // <img  src={toAbsoluteUrl('/media/auth/backgoundoflogin.png')} className='image-back-width' />
// // </div>
// // </div>
   
    
   
// //   )
// // }


import { useState } from 'react';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import inputText from '../../../ReUsableComponent/ReyClinicalinputs.json';
import TitleComponent from '../../../ReUsableComponent/TitleComponent';

// Initial form values
const initialValues = {
  email: 'admin@demo.com',
  password: 'demo',
};

export function Login() {
  const APIKey = process.env.REACT_APP_SERVERAPI;
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [invalidUserName, setInvalidUserName] = useState(false);
  const [invalidPass, setInvalidPass] = useState(false);
  const [invalid, setInvalid] = useState(false);

  const navigate = useNavigate();

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Handle input changes
  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    setInvalid(false); // Reset invalid states on input change
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Basic validation
    let hasError = false;
    if (!username) {
      setInvalidUserName(true);
      hasError = true;
    } else {
      setInvalidUserName(false);
    }

    if (!password) {
      setInvalidPass(true);
      hasError = true;
    } else {
      setInvalidPass(false);
    }

    if (hasError) {
      return;
    }

    setLoading(true);
    
    const data = { username, password };
    try {
      await login(data);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setInvalid(true);
    }
  };

  const login = async (data) => {
    try {
      const result = await axios.post(`${APIKey}/api/auth/signin`, data);
      const loginInfo = JSON.stringify(result.data);
      localStorage.setItem('loginInfo', loginInfo);
      setLoading(false);
      window.location.href = '/adminDashboard'
    } catch (error) {
      console.error(error);
      setLoading(false);
      setInvalid(true);
    }
  };

  return (
    <>
    <div className='loginpage'>
      <div className='card custom-card-width-login'>
        <div className='card-body'>
          <form
            className='form w-100'
            onSubmit={handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >

            <div className='text-center mb-11'>
              <h1 className='text-dark fw-bolder mb-3'>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/ReyCLinicalLogoSvg.svg')}
                  className='h-24px h-lg-35px app-sidebar-logo-default'
                />
              </h1>
              <TitleComponent type='loginBodyText'>Welcome to ReyClinical Electronic Health Record</TitleComponent>
            </div>

            {invalid && (
              <div className='mb-lg-5 alert alert-danger'>
                <div className='alert-text fw-bold'>{inputText.loginForm.ErrMsg}</div>
              </div>
            )}

            {/* begin::Form group */}
            <div className='fv-row mb-8'>
            <TitleComponent type='label'>{inputText.username.label}</TitleComponent>
              <input
                onChange={handleInputChange(setUsername)}
                placeholder={inputText.username.label}
                className={clsx(
                  'form-control bg-transparent',
                  { 'is-invalid': invalidUserName }
                )}
                type='email'
                name='email'
                autoComplete='off'
              />
              {invalidUserName && (
                <div className='fv-plugins-message-container'>
                  <span role='alert' style={{ color: 'red' }}>
                    {inputText.username.ErrMsg || 'Please enter your username'}
                  </span>
                </div>
              )}
            </div>
          
            <div className='fv-row mb-3'>
            <TitleComponent type='label'>{inputText.password.label}</TitleComponent>
              <div className='input-group'>
                <input
                  onChange={handleInputChange(setPassword)}
                  placeholder={inputText.password.placeholder}
                  type={showPassword ? 'text' : 'password'}
                  autoComplete='off'
                  className={clsx('form-control bg-transparent')}
                />
                <div className='input-group-text'>
                  <i
                    onClick={togglePasswordVisibility}
                    className={`bi bi-eye${showPassword ? '-slash' : ''}`}
                    style={{ cursor: 'pointer' }}
                  ></i>
                </div>
              </div>
              {invalidPass && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{inputText.password.ErrMsg || 'Please enter your password'}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='d-grid mb-10'>
              <button
                //onClick={(e)=> handleSubmit}
                // onClick={handleSubmit}
                onClick={(e) => handleSubmit(e)}
                id='kt_sign_in_submit'
                className='primarybtnBig'
                type='button'

                // disabled={formik.isSubmitting || !formik.isValid}
              >
                {/* <span className='indicator-label'>Continue</span> */}

                {!loading && <span className='indicator-label'>Login</span>}

                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            <div className='text-gray-500 text-left fw-semibold fs-6 mb-6'>
              <Link to='/Admin/retrieve-Usename'>
                <span style={{color:'#7E8299'}}><TitleComponent type='popUpButton'>Retrieve your username</TitleComponent></span>
              </Link>
            </div>
            <div className='text-gray-500 text-left fw-semibold fs-6 mb-6'>
              <Link to='/Admin/forgot-password'>
              <span style={{color:'#7E8299'}}><TitleComponent type='popUpButton'>Retrieve your password</TitleComponent></span>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>

      <div className='image-back-wrapper'></div>
      </>
  );
}





// /* eslint-disable jsx-a11y/anchor-is-valid */
// import {useEffect, useState} from 'react'
// import * as Yup from 'yup'
// import clsx from 'clsx'
// import {Link, useNavigate} from 'react-router-dom'
// import {useFormik} from 'formik'
// import {getUserByToken, login} from '../../../app/modules/auth/core/_requests'
// import {toAbsoluteUrl} from '../../../_metronic/helpers'
// import {useAuth} from '../../../app/modules/auth/core/Auth'
// import axios from 'axios'
// import inputText from '../../../ReUsableComponent/ReyClinicalinputs.json'

// const loginSchema = Yup.object().shape({
//   email: Yup.string()
//     .email('Wrong email format')
//     .min(3, 'Minimum 3 symbols')
//     .max(50, 'Maximum 50 symbols')
//     .required('Email is required'),
//   password: Yup.string()
//     .min(3, 'Minimum 3 symbols')
//     .max(50, 'Maximum 50 symbols')
//     .required('Password is required'),
// })

// const initialValues = {
//   email: 'admin@demo.com',
//   password: 'demo',
// }

// /*
//   Formik+YUP+Typescript:
//   https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
//   https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
// */

// export function Login() {
//   const APIKey = process.env.REACT_APP_SERVERAPI

//   console.log('This is APIServer', APIKey)

//   const [loading, setLoading] = useState(false)
//   // const {saveAuth, setCurrentUser } = useAuth()
//   const [showPassword, setShowPassword] = useState(false)

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword)
//   }

//   const navigate = useNavigate()

//   // const formik = useFormik({
//   //   initialValues,
//   //   validationSchema: loginSchema,
//   //   onSubmit: async (values, {setStatus, setSubmitting}) => {
//   //     setLoading(true)
//   //     try {
//   //       const {data: auth} = await login(values.email, values.password)
//   //       saveAuth(auth)
//   //       const {data: user} = await getUserByToken(auth.api_token)
//   //       setCurrentUser(user)
//   //     } catch (error) {
//   //       console.error(error)
//   //       saveAuth(undefined)
//   //       setStatus('The login details are incorrect')
//   //       setSubmitting(false)
//   //       setLoading(false)
//   //     }
//   //   },
//   // })

//   const [username, setUsername] = useState<undefined | string>()
//   const [password, setPassWord] = useState<undefined | string>()
//   const [invalidUserName, setinValidUserName] = useState<undefined | boolean>(false)
//   const [inValidPass, setinValidPass] = useState<undefined | boolean>(false)
//   const [invalid, setinvalid] = useState<undefined | boolean>(false)

//   const getUsername = (e) => {
//     setUsername(e.target.value)
//   }
//   const getPass = (e) => {
//     setPassWord(e.target.value)
//   }

//   const handleSubmit = (e) => {
//     e.preventDefault()

//     const data = {
//       password,
//       username,
//     }

//     setLoading(true)

//     login(data)
//   }

//   const login = async (data) => {
//     try {
//       const result = await axios.post(`${APIKey}/api/auth/signin`, data)

//       const loginInfo = JSON.stringify(result.data)
//       const error = result.data
//       console.log(error)

//       //console.log(loginInfo);

//       localStorage.setItem('loginInfo', loginInfo)

//       setLoading(false)
//       //navigate("adminDashboard")
//       window.location.href = '/adminDashboard'
//     } catch (error) {
//       console.log(error)
//       setLoading(false)
//       setinvalid(true)
//     }
//   }

//   return (
//     <div>
//       <div className='card custom-card-width-login'>
//         <div className='card-body'>
//           <form
//             className='form w-100'
//             // onSubmit={handleSubmit}
//             noValidate
//             id='kt_login_signin_form'
//           >
//             {/* begin::Heading */}
//             <div className='text-center mb-11'>
//               <h1 className='text-dark fw-bolder mb-3'>
//                 <img
//                   alt='Logo'
//                   src={toAbsoluteUrl('/media/logos/MediNivlogoLogin.svg')}
//                   className='h-20px h-lg-30px app-sidebar-logo-default'
//                 />
//               </h1>

//               <div className='fw-semibold'>Welcome to MediNiv Electronic Health Record</div>
//             </div>
//             {/* begin::Heading */}

//             {
//               invalid && (
//                 <div className='mb-lg-5 alert alert-danger'>
//                   <div className='alert-text fw-bold'>{inputText.loginForm.ErrMsg}</div>
//                 </div>
//               )
//               // : (
//               //   <div>
//               //     <div className='text-info'>
//               //       Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
//               //       continue.
//               //     </div>
//               //   </div>
//               // )
//             }

//             {/* begin::Form group */}
//             <div className='fv-row mb-8'>
//               <label className='patient-info-title'>{inputText.username.label}</label>

//               <input
//                 onChange={(e) => getUsername(e)}
//                 placeholder={inputText.username.label}
//                 // {...formik.getFieldProps('email')}
//                 className={clsx(
//                   'form-control bg-transparent '
//                   // {'is-invalid': !username },
//                   // {
//                   //   'is-valid': username,
//                   // }
//                 )}
//                 type='email'
//                 name='email'
//                 autoComplete='off'
//               />
//               {invalidUserName && (
//                 <div className='fv-plugins-message-container'>
//                   <span role='alert ' style={{color: 'red'}}>
//                     {inputText.username.ErrMsg}
//                   </span>
//                 </div>
//               )}
//             </div>
//             {/* end::Form group */}

//             {/* begin::Form group */}
//             {/* <div className='fv-row mb-3'>
//         <label className='form-label fw-bolder text-dark fs-6'>Password</label>
//         <input
//           type='password'
//           autoComplete='off'
//           {...formik.getFieldProps('password')}
//           className={clsx(
//             'form-control bg-transparent',
//             {
//               'is-invalid': formik.touched.password && formik.errors.password,
//             },
//             {
//               'is-valid': formik.touched.password && !formik.errors.password,
//             }
//           )}
//         />
//         {formik.touched.password && formik.errors.password && (
//           <div className='fv-plugins-message-container'>
//             <div className='fv-help-block'>
//               <span role='alert'>{formik.errors.password}</span>
//             </div>
//           </div>
//         )}
//       </div> */}
//             <div className='fv-row mb-3'>
//               <label className='patient-info-title'>{inputText.password.label}</label>

//               <div className='input-group'>
//                 <input
//                   onChange={(e) => getPass(e)}
//                   placeholder={inputText.password.placeholder}
//                   type={showPassword ? 'text' : 'password'}
//                   autoComplete='off'
//                   // {...formik.getFieldProps('password')}
//                   className={clsx(
//                     'form-control bg-transparent'
//                     // {
//                     //   'is-invalid': formik.touched.password && formik.errors.password,
//                     // },
//                     // {
//                     //   'is-valid': formik.touched.password && !formik.errors.password,
//                     // }
//                   )}
//                 />

//                 <div className='input-group-text'>
//                   <i
//                     onClick={togglePasswordVisibility}
//                     className={`bi bi-eye${showPassword ? '-slash' : ''}`}
//                     style={{cursor: 'pointer'}}
//                   ></i>
//                 </div>
//               </div>
//               {inValidPass && (
//                 <div className='fv-plugins-message-container'>
//                   <div className='fv-help-block'>
//                     <span role='alert'>{inputText.password.ErrMsg}</span>
//                   </div>
//                 </div>
//               )}
//             </div>

//             {/* end::Form group */}

//             {/* begin::Wrapper */}
//             <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
//               <div />

//               {/* begin::Link */}

//               {/* end::Link */}
//             </div>
//             {/* end::Wrapper */}

//             {/* begin::Action */}
//             <div className='d-grid mb-10'>
//               <button
//                 //onClick={(e)=> handleSubmit}
//                 // onClick={handleSubmit}
//                 onClick={(e) => handleSubmit(e)}
//                 id='kt_sign_in_submit'
//                 className='primarybtnBig'
//                 type='button'

//                 // disabled={formik.isSubmitting || !formik.isValid}
//               >
//                 {/* <span className='indicator-label'>Continue</span> */}

//                 {!loading && <span className='indicator-label'>Login</span>}

//                 {loading && (
//                   <span className='indicator-progress' style={{display: 'block'}}>
//                     Please wait...
//                     <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
//                   </span>
//                 )}
//               </button>
//             </div>
//             {/* end::Action */}
//             <div className='text-gray-500 text-left fw-semibold fs-6 mb-6'>
//               {/* Not a Member yet? */}

//               <Link to='/Admin/retrieve-Usename' className=''>
//                 Retrieve your username
//               </Link>
//             </div>
//             <div className='text-gray-500 text-left fw-semibold fs-6 mb-6'>
//               {/* Not a Member yet? */}

//               <Link to='/Admin/forgot-password' className=''>
//                 Retrieve your password
//               </Link>
//             </div>
//           </form>
//         </div>
//       </div>

//       <div className='image-back'>
//         <img src={toAbsoluteUrl('/media/auth/backgoundoflogin.png')} className='image-back-width' />
//       </div>
//     </div>
//   )
// }
