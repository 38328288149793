import React, {useEffect, useState} from 'react'
import plusredbtn from '../../EHRIcon/plusredbtn.svg'
import CommonTable from '../../../../ReUsableComponent/CommonTable'
// import whiteplus from '../../EHRIcon/whiteplus.svg';
// import Vector from '../../EHRIcon/Vector.svg';
import axios from 'axios';
// import Deleteicon from '../../EHRIcon/Deleteicon.svg';
import DeleteModel from '../../../../ReUsableComponent/DeleteModel';
import AddAllergies from './AddAllergies';
import {useParams} from 'react-router-dom'
// import EditAllergies from './EditAllergies';
// import Eyebutton from '../../EHRIcon/Eyebutton.svg'
import ViewAllergies from './ViewAllergies';
import tablecolor from '../../../../ReUsableComponent/ReyClinicalinputs.json'
import EditAllergies from './EditAllergies';


const Allergies = () => {

  const APIkey = process.env.REACT_APP_SERVERAPI || '';
  const {tab} = useParams()
  const [data, setData] = useState([])

  const [isEditMode, setIsEditMode] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editId, setEditData] = useState(null);

const[showDeleteModal, setShowDeleteModal] = useState(false);
const [deleteId, setDeleteId]=useState(null);


const [showAddModal, setShowAddModal] = useState(false);
const [saveData, setSaveData] = useState(false)

const [ showViewModal, setShowViewModal ] = useState(false );
const [viewData,setViewData]=useState('')
const [viewId,setViewId]=useState(null)


  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')

  const handleAddClick = () => {
    console.log('Add clicked!')
    
    setShowAddModal(true)
  }

  const handleRowClick = (row) => {
    console.log('Row clicked:', row)
    // Logic to handle row click
  }

  const columns = [
    { key: ['srNo'], header: 'Sr.No' },
    {key: ['allergyName'], header: 'Allergy Name', },
    {key: ['allergenType'], header: 'Allergen Type'},
    {key: ['severity'], header: 'Severity'},
    {key: ['reaction'], header: 'Reaction'},
    {key: ['allergyType'], header: 'Allergy Type'},
    {key: ['allergyCode'], header: 'Allergy Code'},
    {key: ['notes'], header: 'Notes'},
    { key: ['actions'], header: 'Actions', isAction: true },
  ]

const handleEdit = (_id)=>{
  setEditData(_id);
  setIsEditMode(true);
  setShowEditModal(true);
}

const handleView = (_id) => {
  setViewData(_id)
  setViewId(_id)
  setShowViewModal(true)
}





  const fetchallergydata = async () =>{
    try{
      const getloginInfo = localStorage.getItem('appLoginInfo');
      if (getloginInfo){
        const Token = JSON.parse(getloginInfo).data.accessToken;
        const header = { headers: { 'x-access-token': Token } };
        const response = await axios.get(`${APIkey}/api/admin/getPreferredAllergyList`,header);
        console.log('Fetched data:', response.data.data);
     
        const responseData =  response.data.data;
        console.log(responseData);

        const formattedData = responseData.map((item,index)=>{
          return {
            srNo: index + 1,
            allergyName: item.allergyName,
            allergenType:item.allergenType,
            severity: item.severity,
            reaction: item.reaction,
            allergyType: item.allergyType,
            allergyCode: item.allergyCode,
            notes: item.notes,
            actions:(
              <div className='actionbtn'>
                   <button onClick={()=>handleView(item._id)} className='editbtn'>
                  <img 
                  // src={Eyebutton}
                   alt='view'/>
                </button>
                <button className='editbtn ' onClick={()=>handleEdit(item._id)}>
                <img
                //  src={Vector}
                 alt='Edit' />
                </button>
                <button className='editbtn' onClick={()=>openDeleteModal(item._id)}>
                <img 
                // src={Deleteicon} 
                alt='Delete' />
                </button>
             

              </div>
            )
          }
        });
        setData(formattedData);
      }else{
        console.log('No login info found in localstorage');
      }
    }catch (error) {
      console.error('Error fetching vaccination data:', error);
    }
  }

  useEffect(()=>{
    fetchallergydata();
  },[showAddModal,showEditModal]);

const openDeleteModal=(id)=>{
  setDeleteId(id);
  setShowDeleteModal(true);
}

const handleCloseModal=()=>{
  setShowDeleteModal(false);
  setShowEditModal(false);
  setShowAddModal(false);
  setShowViewModal(false);
}


const handleDelete = async () => {
  try {
    const getloginInfo = localStorage.getItem('appLoginInfo');
    if (getloginInfo) {
      const Token = JSON.parse(getloginInfo).data.accessToken;
      const header = { headers: { 'x-access-token': Token } };

      await axios.delete(`${APIkey}/api/admin/deletePreferredAllergy/${deleteId}`, header);
      console.log('Row deleted successfully');

      fetchallergydata();
      setShowDeleteModal(false); // Close the modal after successful deletion
    } else {
      console.log('No login info found in localStorage');
    }
  } catch (error) {
    console.error('Error deleting vaccination:', error);
  }
};

//Search Criteria Logic 
const [searchCriteria,setSearchCriteria]=useState({
  allergyName:'', 
  severity:'',
  reaction:'',
  allergyType:'',
  allergyCode:'',
  
})
const handleSearchCriteriaChange=(e)=>{
  const {name,value}= e.target
  setSearchCriteria((prevState)=>({
    ...prevState,
    [name]:value,
  }))
}

const handleSearch = async()=>{
  try{
    const getloginInfo=localStorage.getItem('appLoginInfo');
if(getloginInfo){
  const Token = JSON.parse(getloginInfo).data.accessToken
  console.log("token" ,Token);
  
  const header = {
    headers: {'x-access-token': Token},
  }
  
    const response = await axios.post(`${APIkey}/api/admin/searchPreferredAllergy`,searchCriteria,header)
    const responseData = response.data.data
    const searchData = responseData.map((item,index)=>({
      srNo: index + 1,
      allergyName: item.allergyName,
      severity: item.severity,
      reaction: item.reaction,
      allergyType: item.allergyType,
      allergyCode: item.allergyCode,
      allergenType:item.allergenType,
      notes: item.notes,
      actions: (
        <div className='actionbtn'>
          <button onClick={() => handleView(item._id)} className='editbtn'>
            <img 
            // src={Eyebutton} 
            alt='view' />
          </button>
          <button onClick={() => handleEdit(item._id)} className='editbtn'>
            <img 
            // src={Vector} 
            alt='Edit' />
          </button>
          <button onClick={() => openDeleteModal(item._id)} className='editbtn'>
            <img 
            // src={Deleteicon} 
            alt='Delete' />
          </button>
        </div>
      ),


    }))
    setData(searchData)
}
  }catch(error){
 console.log('Error fetching data')
  }
}






const handleClearClick = () => {
  setSearchCriteria({
    allergyName: '',
    severity: '',
    reaction: '',
    allergyType: '',
    allergyCode: '',
  });
  fetchallergydata();
};


  return (
    <>
     <div className='clinical-time-header'>
        <div className='titletextformat'>
          <div className='PageHeaderTitle'>Allergies</div>
          <div>
            {' '}
            <button className='add-Vaccine-btn'  onClick={handleAddClick} >
              <img 
              // src={whiteplus}
               alt='Add Vaccine' /> Add Allergies
            </button>
          </div>
        </div>
      </div>

      <div className='d-flex gap-2 col-md-12 mt-3'>
   
        <div className='col-md-2'>
          <input type='text' className='SearchInputbox' placeholder='Allergy Name' name='allergyName' value={searchCriteria.allergyName} onChange={handleSearchCriteriaChange} />
        </div>
        <div className='col-md-2'>
          <input type='text' className='SearchInputbox' placeholder='Severity' name='severity' value={searchCriteria.severity} onChange={handleSearchCriteriaChange} />
        </div>
        <div className='col-md-2'>
          <input type='text' className='SearchInputbox' placeholder='Reaction' name='reaction' value={searchCriteria.reaction} onChange={handleSearchCriteriaChange}/>
        </div>
        <div className='col-md-2'>
          <input type='text' className='SearchInputbox' placeholder='Allergy Type' name='allergyType' value={searchCriteria.allergyType} onChange={handleSearchCriteriaChange} />
        </div>
        <div className='col-md-2'>
          <input type='text' className='SearchInputbox' placeholder='Allergy Code' name='allergyCode' value={searchCriteria.allergyCode} onChange={handleSearchCriteriaChange}/>
        </div>
        <div className='col-md-2 gap-1 d-flex'>
          <button type='button' className='AddBtn' onClick={handleSearch}  >
           Search
          </button>
          <button type='button' className='grayButton' onClick={handleClearClick} >
            Clear
          </button>
        </div>
      </div>
      <br />
      <div className='clinical-time-container '>
        <div>
      <CommonTable
        data={data}
        
        totalCount={data.length}
        perPage={perPage}
        setPerPage={setPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        columns={columns}
        searchQuery={searchQuery}
        setQuery={setSearchQuery}
        onRowClick={handleRowClick}
        headerColor={tablecolor.primaryTable.color}
        issetPaginationCompo={true}
        isPagelength={true}
        isSearched={true}
      />
      </div>
      </div>
      


      <DeleteModel
      openDelete={showDeleteModal}
      closedelete={handleCloseModal}
      deletedEntity={handleDelete}
      deleteID={deleteId}
      deleteFunction={handleDelete}/>

<AddAllergies
onShow={showAddModal}
onHide={handleCloseModal}
onClick={saveData}
/>

<EditAllergies
onshow={showEditModal}
onHide={handleCloseModal}   
editId={editId}/>


<ViewAllergies
onshow={showViewModal}
onhide={handleCloseModal}
viewId={viewId}/>

      
    </>
   
  )
}



export default Allergies
