import React, {useEffect, useState} from 'react'
import axios from 'axios'
import CommonTable from '../../../ReUsableComponent/CommonTable'
import tablecolor from '../../../ReUsableComponent/ReyClinicalinputs.json'

const APIkey = process.env.REACT_APP_SERVERAPI

interface ChiefComplaint {
  chiefComplaintName: string
  chiefComplaintId: string
}

interface MedicalHealth {
  medication: string
  medicationId: string
}

interface Allergy {
  allergyName: string
  allergyId: string
}

interface SocialHistory {
  socialHistory: string
  socialHistoryId: string
}
function PatientEncounterTemplate() {
  const [templatedata, setTemplatedata] = useState<any[]>([])
  const [Savetemplatedata, setSaveTemplatedata] = useState<any[]>([])
  const [chiefComplainList, setChiefComplainList] = useState<ChiefComplaint[]>([])
  const [medicalHealthList, setMedicalHealthList] = useState<MedicalHealth[]>([])
  const [allergyList, setAllergyList] = useState<Allergy[]>([])
  const [socialHistory, setSocialHistory] = useState<SocialHistory[]>([])
  const [selectedChiefComplaints, setSelectedChiefComplaints] = useState<string[]>([])
  const [selectedMedicalHealth, setSelectedMedicalHealth] = useState<string[]>([])
  const [selectedAllergyList, setSelectedAllergyList] = useState<string[]>([])
  const [selectedSocialHistory, setSelectedSocialHistory] = useState<string[]>([])
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setQuery] = useState('')

  const responsiveColumns = 'col-md-2' // For 4 columns layout

  const getTemplatedata = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const header = {headers: {'x-access-token': Token}}
        const response = await axios.get(`${APIkey}/api/admin/getPatientEncounterTemplate`, header)

        const responseData = response.data.data
        const templatedata = responseData.map((item) => ({}))
        setTemplatedata(response.data.data)
      } else {
        console.log('No login info found in local storage')
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const handleSavedata = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const header = {headers: {'x-access-token': Token}}

        const response = await axios.post(
          `${APIkey}/api/admin/updatePatientEncounterTemplate`,
          header
        )
        const responseData = response.data.data
        const Savetemplatedata = {
          chiefComplaint: [responseData.chiefComplaint],
          traumaHistory: responseData.traumaHistory,
          pastDentalTreatment: {
            treatment: responseData.pastDentalTreatment,
            date: responseData.pastDentalTreatmentDate,
            place: responseData.pastDentalTreatmentPlace,
            note: responseData.pastDentalTreatmentNote,
          },
          healthIssue: responseData.healthIssue,
          allergy: responseData.allergy,
          womenHealth: {
            isPregnant: responseData.IsPregnant,
            isNursingAChild: responseData.IsNursingAChild,
            isTakingPills: responseData.IsTakingPills,
            dueDate: responseData.dueDate,
            womenMedicine: responseData.womenMedicine,
          },
          socialHistory: responseData.socialHistory,
          historyOfPain: responseData.historyOfPain,
          oralHealth: {
            softTissueFinding: responseData.softTissueFinding,
            stains: responseData.stains,
            calculus: responseData.calculus,
            caries: responseData.caries,
            mobility: responseData.mobility,
            recession: responseData.recession,
            bleedingGums: responseData.bleedingGums,
            pockets: responseData.pockets,
            rootPiece: responseData.rootPiece,
            rcTreatedTeeth: responseData.rcTreatedTeeth,
            capOnTeeth: responseData.capOnTeeth,
            malaligned: responseData.malaligned,
            missingTeeth: responseData.missingTeeth,
            impactedTeeth: responseData.impactedTeeth,
            filledTeeth: responseData.filledTeeth,
          },
          iopa: responseData.iopa,
          treatmentPlan: {
            scalingPolishing: responseData.scalingPolishing,
            dentalFilling: responseData.dentalFilling,
            extractions: responseData.extractions,
            rct: responseData.rct,
            implant: responseData.implant,
            crownBridge: responseData.crownBridge,
            cdrpd: responseData.cdrpd,
            orthoTreatment: responseData.orthoTreatment,
            disImpaction: responseData.disImpaction,
            gumSurgeries: responseData.gumSurgeries,
            otherTreatment: responseData.otherTreatment,
          },
          status: responseData.status,
        }

        // Set the mapped data in state
        // setSaveTemplatedata(Savetemplatedata);
        setSaveTemplatedata((prevState) => [...prevState, Savetemplatedata])
      } else {
        console.log('No login info found in local storage')
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const fetchChiefComplainData = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const header = {headers: {'x-access-token': Token}}
        const response = await axios.get(
          `${APIkey}/api/admin/getPreferredChiefComplaintList`,
          header
        )

        const responseData = response.data.data
        const chiefComplainList = responseData.map((item) => ({
          chiefComplaintName: item.chiefComplaintName,
          chiefComplaintId: item.chiefComplaintId,
        }))
        setChiefComplainList(chiefComplainList)
      } else {
        console.log('No login info found in local storage')
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const fetchMedicalHistory = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const header = {headers: {'x-access-token': Token}}
        const response = await axios.get(`${APIkey}/api/admin/getPreferredMedicationList`, header)

        const responseData = response.data.data
        const medicalHealthList = responseData.map((item) => ({
          medication: item.medication,
          medicationId: item.medicationId,
        }))
        setMedicalHealthList(medicalHealthList)
      } else {
        console.log('No login info found in local storage')
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const fetchAllergyList = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const header = {headers: {'x-access-token': Token}}
        const response = await axios.get(`${APIkey}/api/admin/getPreferredAllergyList`, header)

        const responseData = response.data.data
        const allergyList = responseData.map((item) => ({
          allergyName: item.allergyName,
          allergyId: item.allergyId,
        }))
        setAllergyList(allergyList)
      } else {
        console.log('No login info found in local storage')
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const fetchSocialHistory = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const header = {headers: {'x-access-token': Token}}
        const response = await axios.get(
          `${APIkey}/api/admin/getPreferredSocialHistoryList`,
          header
        )

        const responseData = response.data.data
        const socialHistoryList = responseData.map((item) => ({
          socialHistory: item.socialHistory,
          socialHistoryId: item.socialHistoryId,
        }))
        setSocialHistory(socialHistoryList)
      } else {
        console.log('No login info found in local storage')
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const handleChiefComplainChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    if (e.target.checked) {
      setSelectedChiefComplaints([...selectedChiefComplaints, id])
    } else {
      setSelectedChiefComplaints(
        selectedChiefComplaints.filter((complaintId) => complaintId !== id)
      )
    }
  }

  const handleMedicalHealthChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    if (e.target.checked) {
      setSelectedMedicalHealth([...selectedMedicalHealth, id])
    } else {
      setSelectedMedicalHealth(selectedMedicalHealth.filter((medId) => medId !== id))
    }
  }

  const handleAllergyList = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    if (e.target.checked) {
      setSelectedAllergyList([...selectedAllergyList, id])
    } else {
      setSelectedAllergyList(selectedAllergyList.filter((allergyId) => allergyId !== id))
    }
  }

  const handleSocialHistory = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    if (e.target.checked) {
      setSelectedSocialHistory([...selectedSocialHistory, id])
    } else {
      setSelectedSocialHistory(
        selectedSocialHistory.filter((socialHistoryId) => socialHistoryId !== id)
      )
    }
  }

  const OptionsIntoChunks = <T,>(array: T[], chunkSize: number): T[][] => {
    const result: T[][] = []
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize))
    }
    return result
  }

  useEffect(() => {
    getTemplatedata()
    fetchChiefComplainData()
    fetchMedicalHistory()
    fetchAllergyList()
    fetchSocialHistory()
  }, [])

  const columns = [
    {key: ['srNo'], header: 'Sr.No'},
    {key: ['medicineName'], header: 'Medicine Name'},
    {key: ['medicineType'], header: 'Types Of Medicine'},
    {key: ['dose'], header: 'Dose'},
    {key: ['fromDate'], header: 'From Date', isDate: true},
    {key: ['endDate'], header: 'End Date', isDate: true},
    {key: ['routeOfAdministration'], header: 'Route of Administration'},
    {key: ['frequency'], header: 'Frequency'},
    {key: ['duration'], header: 'Duration'},
  ]

  const data = [
    {
      // srNo: 1,
      // medicineName: 'Paracetamol',
      // medicineType: 'Tablet',
      // dose: '500mg',
      // fromDate: '2023-09-15',
      // endDate: '2023-09-20',
      // routeOfAdministration: 'Oral',
      // frequency: 'Twice a day',
      // duration: '5 days',
    },
    // Add more rows as needed
  ]

  return (
    <>
      {/* Dental history */}
      <div className='PageHeaderTitle '>Patient Encounter Template</div>
      <div className='TemplateBackgroundLayout'>
        <div className='TemplateSamllHeaderFont'>Dental History</div>
        <div className='d-flex templatelabletext w-100 gap-3'>
          <div className='d-flex align-items-center'>
            <label className='w-100'>Chief Complain :</label>
            {/* <input type='search' placeholder='Search' className='SearchInputbox' /> */}
          </div>
          <div className='d-flex align-items-center'>
            {/* <label className='w-100'>Other Chief Complain :</label> */}
            {/* <input type='search' placeholder='' className='SearchInputbox' /> */}
          </div>
          {/* <button className='TemplateInnerBtn'>Add</button> */}
        </div>
        <div className='row'>
          {chiefComplainList.length
            ? OptionsIntoChunks(chiefComplainList, 1).map((chunk, index) => (
                <div className={responsiveColumns} key={index}>
                  {chunk.map((clinicData) => (
                    <div key={clinicData.chiefComplaintId}>
                      <div className='form-check'>
                        <input
                          type='checkbox'
                          id={`chiefComplain_${clinicData.chiefComplaintId}`}
                          name={'chief_complaint'}
                          className=''
                          checked={selectedChiefComplaints.includes(clinicData.chiefComplaintId)}
                          onChange={(e) =>
                            handleChiefComplainChange(e, clinicData.chiefComplaintId)
                          }
                        />
                        &nbsp;
                        <label
                          htmlFor={`chiefComplain_${clinicData.chiefComplaintId}`}
                          className=''
                        >
                          {clinicData.chiefComplaintName}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              ))
            : Array.from({length: 8}).map((_, index) => (
                <div className={`${responsiveColumns} mt-2`} key={index}>
                  <div className='skeleton'></div>
                </div>
              ))}
        </div>
        <div className='d-flex templatelabletext gap-2 align-items-center'>
          <div>
            <label className='w-100'>History Of Trauma / Road Accident :</label>
            <input type='search' placeholder='' className='SearchInputbox' />
          </div>
          <div>
            <label className='w-100'>Past Dental Treatment :</label>
            <input type='search' placeholder='' className='SearchInputbox' />
          </div>
          <div>
            <label className='w-100'>Date :</label>
            <input type='date' placeholder='' className='SearchInputbox' />
          </div>
          <div>
            <label className='w-100'>Where :</label>
            <input type='' placeholder='' className='SearchInputbox' />
          </div>
        </div>
        <div className='align-items-center templatelabletext'>
          <label className='w-100'>Note :</label>
          <input type='text' placeholder='' className='SearchInputbox' />
        </div>
      </div>

      {/* Medical History */}
      <div className='TemplateBackgroundLayout'>
        <div className='TemplateSamllHeaderFont'>Medical History</div>
        <div className='d-flex templatelabletext gap-3'>
          <div className='d-flex align-items-center  '>
            <label className='w-100'>Patient Health Issues :</label>
            {/* <input type='search' placeholder='Search' className='SearchInputbox' /> */}
          </div>
        </div>
        <div className='row'>
          {medicalHealthList.length
            ? OptionsIntoChunks(medicalHealthList, 1).map((chunk, index) => (
                <div className={responsiveColumns} key={index}>
                  {chunk.map((clinicData) => (
                    <div
                      key={clinicData.medicationId}
                      style={{padding: '3px 0', minWidth: '180px', display: 'inline-block'}}
                    >
                      <div
                        className='form-check'
                        style={{whiteSpace: 'nowrap', display: 'flex', alignItems: 'center'}}
                      >
                        <input
                          type='checkbox'
                          id={`medHealth_${clinicData.medicationId}`}
                          name='medical_health'
                          checked={selectedMedicalHealth.includes(clinicData.medicationId)}
                          onChange={(e) => handleMedicalHealthChange(e, clinicData.medicationId)}
                        />
                        {/* &nbsp; */}

                        <label
                          htmlFor={`medHealth_${clinicData.medicationId}`}
                          title={clinicData.medication}
                          style={{
                            display: 'inline-block',
                            marginLeft: '3px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '150px',
                            verticalAlign: 'middle',
                          }}
                        >
                          {clinicData.medication}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              ))
            : Array.from({length: 8}).map((_, index) => (
                <div className={`${responsiveColumns} mt-2`} key={index}>
                  <div className='skeleton'></div>
                </div>
              ))}
        </div>
      </div>

      {/* current medication  */}
      <div className='TemplateBackgroundLayout'>
        <div className='TemplateSamllHeaderFont'>Current Medication</div>
        <div className='templatelabletext'>
          <CommonTable
            columns={columns}
            data={data}
            totalCount={data.length}
            perPage={perPage}
            setPerPage={setPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            searchQuery={searchQuery}
            setQuery={setQuery}
            headerColor={tablecolor.primaryTable.color}
                issetPaginationCompo = {false}
                isPagelength={false}
                isSearched={false}
          />
        </div>
      </div>

      <div className='TemplateBackgroundLayout'>
        <div className='TemplateSamllHeaderFont'>Allergic To:</div>
        <div className='row'>
          {allergyList.length
            ? OptionsIntoChunks(allergyList, 1).map((chunk, index) => (
                <div className={responsiveColumns} key={index}>
                  {chunk.map((clinicData) => (
                    <div key={clinicData.allergyId}>
                      <div className='form-check'>
                        <input
                          type='checkbox'
                          id={`medHealth_${clinicData.allergyId}`}
                          name='medical_health'
                          checked={selectedAllergyList.includes(clinicData.allergyId)}
                          onChange={(e) => handleAllergyList(e, clinicData.allergyId)}
                        />
                        &nbsp;
                        <label htmlFor={`medHealth_${clinicData.allergyId}`}>
                          {clinicData.allergyName}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              ))
            : Array.from({length: 8}).map((_, index) => (
                <div className={`${responsiveColumns} mt-2`} key={index}>
                  <div className='skeleton'></div>
                </div>
              ))}
        </div>
      </div>

      {/* woman */}
      <div className='TemplateBackgroundLayout'>
        <div className='TemplateSamllHeaderFont'>
          Woman : <input type='checkbox' />
        </div>
        <div className='d-flex templatelabletext gap-5 align-items-center'>
          <div className='d-flex gap-2'>
            <div className='gap-2'>
              <label className='w-100 '>Are You Pregnant ?</label>
            </div>
            Yes
            <input type='radio' placeholder='' className='' /> No
            <input type='radio' placeholder='' className='' />
          </div>
          <div className='d-flex gap-2'>
            <div className='gap-2'>
              <label className='w-100 '>Are You Nursing a Child ?</label>
            </div>
            Yes
            <input type='radio' placeholder='' className='' /> No
            <input type='radio' placeholder='' className='' />
          </div>
          <div className='d-flex gap-2'>
            <div className='gap-2'>
              <label className='w-100 '>Are You Taking any Oral Contraceptive Pills ?</label>
            </div>
            Yes
            <input type='radio' placeholder='' className='' /> No
            <input type='radio' placeholder='' className='' />
          </div>
        </div>
        <div className='d-flex templatelabletext gap-4'>
          <div className='d-flex align-items-center w-100'>
            <label className='w-50'>If, Yes give your due date </label>
            <input type='date' placeholder='' className='SearchInputbox' />
          </div>
          <div className='d-flex align-items-center w-100'>
            <label className='w-50'>If, Yes Name of Medicine </label>
            <input type='text' placeholder='' className='SearchInputbox' />
          </div>
          <button className='TemplateInnerBtn'>Add</button>
        </div>
      </div>

      {/* social history  */}
      <div className='TemplateBackgroundLayout'>
        <div className='TemplateSamllHeaderFont mb-3'>Social History :</div>

        <div className=''>
          <div className='row'>
            {socialHistory.length
              ? OptionsIntoChunks(socialHistory, 1).map((chunk, index) => (
                  <div className={responsiveColumns} key={index}>
                    {chunk.map((clinicData) => (
                      <div key={clinicData.socialHistoryId}>
                        <div className='form-check'>
                          <input
                            type='checkbox'
                            id={`medHealth_${clinicData.socialHistoryId}`}
                            name='medical_health'
                            checked={selectedSocialHistory.includes(clinicData.socialHistoryId)}
                            onChange={(e) => handleSocialHistory(e, clinicData.socialHistoryId)}
                          />
                          &nbsp;
                          <label
                            title={clinicData?.socialHistory}
                            htmlFor={`medHealth_${clinicData.socialHistoryId}`}
                          >
                            {clinicData?.socialHistory}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                ))
              : Array.from({length: 8}).map((_, index) => (
                  <div className={`${responsiveColumns} mt-2`} key={index}>
                    <div className='skeleton'></div>
                  </div>
                ))}
          </div>
        </div>
      </div>

      {/* History of Pain/Swelling :  */}
      <div className='TemplateBackgroundLayout d-flex'>
        <div className='TemplateSamllHeaderFont'>
          History of Pain/Swelling :
          <input type='search' placeholder='' className='SearchInputbox w-100' />
        </div>
        <div className='TemplateSamllHeaderFont'>
          Soft Tissue Finding :
          <input type='search' placeholder='' className='SearchInputbox w-100' />
        </div>
      </div>

      {/* Clinical Finding :  */}
      <div className='TemplateBackgroundLayout '>
        <div className='TemplateSamllHeaderFont'> Clinical Finding :</div>
        <div className='d-flex col-md-12 mt-2'>
          <div className='templatelabletext col-md-3'>
            <label>Stains :</label>
            <input type='search' placeholder='' className='SearchInputbox w-100' />
          </div>
          <div className='templatelabletext col-md-3'>
            Calculus :<input type='' placeholder='' className='SearchInputbox w-100' />
          </div>
          <div className='templatelabletext col-md-3'>
            Caries :<input type='' placeholder='' className='SearchInputbox w-100' />
          </div>
          <div className='templatelabletext col-md-3'>
            Mobility :<input type='' placeholder='' className='SearchInputbox w-100' />
          </div>
        </div>

        <div className='d-flex col-md-12 mt-2'>
          <div className='templatelabletext col-md-3'>
            Recession :
            <input type='search' placeholder='' className='SearchInputbox w-100' />
          </div>
          <div className='templatelabletext col-md-3'>
            Bleeding Gums :<input type='' placeholder='' className='SearchInputbox w-100' />
          </div>
          <div className='templatelabletext col-md-3'>
            Pockets :<input type='' placeholder='' className='SearchInputbox w-100' />
          </div>
          <div className='templatelabletext col-md-3'>
            Root Piece :<input type='' placeholder='' className='SearchInputbox w-100' />
          </div>
        </div>

        <div className='d-flex col-md-12 mt-2'>
          <div className='templatelabletext col-md-3'>
            R.C. Treated Teeth :
            <input type='search' placeholder='' className='SearchInputbox w-100' />
          </div>
          <div className='templatelabletext col-md-3'>
            Cap on Teeth :<input type='' placeholder='' className='SearchInputbox w-100' />
          </div>
          <div className='templatelabletext col-md-3'>
            Malaligned :<input type='' placeholder='' className='SearchInputbox w-100' />
          </div>
          <div className='templatelabletext col-md-3'>
            Missing Teeth :<input type='' placeholder='' className='SearchInputbox w-100' />
          </div>
        </div>

        <div className='d-flex col-md-12 mt-2'>
          <div className='templatelabletext col-md-3'>
            Impacted Teeth :
            <input type='search' placeholder='' className='SearchInputbox w-100' />
          </div>
          <div className='templatelabletext col-md-3'>
            Filled Teeth :<input type='' placeholder='' className='SearchInputbox w-100' />
          </div>
          {/* <div className='TemplateSamllHeaderFont col-md-3'>
        Malaligned :<input type='' placeholder='' className='SearchInputbox w-100' />
        </div>
        <div className='TemplateSamllHeaderFont col-md-3'>
        Missing Teeth :<input type='' placeholder='' className='SearchInputbox w-100' />
        </div> */}
        </div>
      </div>

      <div className='TemplateBackgroundLayout '>
        <div className='TemplateSamllHeaderFont'> Treatment Advised :</div>
        <div className='d-flex col-md-12 mt-2'>
          <div className='templatelabletext col-md-3'>
            I.O.P.A. :
            <input type='search' placeholder='' className='SearchInputbox w-100' />
          </div>
          <div className='templatelabletext col-md-3'>
            Scaling & Polishing :<input type='' placeholder='' className='SearchInputbox w-100' />
          </div>
          <div className='templatelabletext col-md-3'>
            Dental Fillings :<input type='' placeholder='' className='SearchInputbox w-100' />
          </div>
          <div className='templatelabletext col-md-3'>
            Extractions :<input type='' placeholder='' className='SearchInputbox w-100' />
          </div>
        </div>

        <div className='d-flex col-md-12 mt-2'>
          <div className='templatelabletext col-md-3'>
            R.C.T. :
            <input type='search' placeholder='' className='SearchInputbox w-100' />
          </div>
          <div className='templatelabletext col-md-3'>
            Implant :<input type='' placeholder='' className='SearchInputbox w-100' />
          </div>
          <div className='templatelabletext col-md-3'>
            Crown & Bridge :<input type='' placeholder='' className='SearchInputbox w-100' />
          </div>
          <div className='templatelabletext col-md-3'>
            C.D. / R.P.D :<input type='' placeholder='' className='SearchInputbox w-100' />
          </div>
        </div>

        <div className='d-flex col-md-12 mt-2'>
          <div className='templatelabletext col-md-3'>
            Ortho Treatment :
            <input type='search' placeholder='' className='SearchInputbox w-100' />
          </div>
          <div className='templatelabletext col-md-3'>
            Disimpaction :<input type='' placeholder='' className='SearchInputbox w-100' />
          </div>
          <div className='templatelabletext col-md-3'>
            Gum Surgeries :<input type='' placeholder='' className='SearchInputbox w-100' />
          </div>
          <div className='templatelabletext col-md-3'>
            Other Treatment :<input type='' placeholder='' className='SearchInputbox w-100' />
          </div>
        </div>
      </div>

      <div className='d-flex justify-content-end gap-2'>
        <button className='primarybtnBig' onClick={handleSavedata}>
          Save
        </button>
        <button className='tertiarybig'>Clear</button>
      </div>
    </>
  )
}

export default PatientEncounterTemplate
