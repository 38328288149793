import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

const ClinicDashboardChart: React.FC = () => {
  const [selectedRange, setSelectedRange] = useState('All');

  const data = {
    All: {
      Patient: [11, 32, 45, 32, 34, 52, 41, 109, 100, 101, 99, 87],
    },
    '1M': {
     
      Patient: [100, 101],
    },
    '3M': {
   
      Patient: [100, 101, 99, 87],
    },
    '1Y': {

      Patient: [41, 109, 100, 101, 99, 87, 93, 88, 45, 80, 90, 100],
    },
  };

  const chartOptions: { series: ApexAxisChartSeries; options: ApexOptions } = {
    series: [
      {
        name: 'Patient',
        data: data[selectedRange].Patient,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      colors: ['#18A0FB'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      xaxis: {
        type: 'category',
        categories: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ],
        labels: {
          format: 'month',
          style: {
            colors: ['black'],
            fontSize: '12px',
          },
        },
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
        y: {
          formatter: (val: number) => `${val.toFixed(0)}`,
        },
      },
      yaxis: {
        labels: {
          formatter: (val: number) => `${val.toFixed(0)}`,
        },
      },
      grid: {
        borderColor: '#e7e7e7',
      },
      markers: {
        size: 0,
        hover: {
          size: 7,
        },
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
      },
    },
  };

  return (
    <div id="chart">
      <div className="toolbar">
        <button
          className="toolbaroption"
          onClick={() => setSelectedRange('All')}
        >
          All
        </button>
        <button
          className="toolbaroption"
          onClick={() => setSelectedRange('1M')}
        >
          1M
        </button>
        <button
          className="toolbaroption"
          onClick={() => setSelectedRange('3M')}
        >
          3M
        </button>
        <button
          className="toolbaroption"
          onClick={() => setSelectedRange('1Y')}
        >
          1Y
        </button>
      </div>
      <ReactApexChart
        series={chartOptions.series}
        options={chartOptions.options}
        type="line"
        height={350}
      />
    </div>
  );
};

export default ClinicDashboardChart;