import React, {useEffect, useState} from 'react'
import Glyph from '../../../ImgesLogos/Glyph.svg'
import users from '../../../ImgesLogos/users.svg'
import filterlines from '../../../ImgesLogos/filter-lines.svg'
import arrowright from '../../../ImgesLogos/arrowright.svg'
import Subscription from '../../../ImgesLogos/Subscription.svg'
import SubscriptionR from '../../../ImgesLogos/SuscriptionR.svg'
import DashboardChart from '../Dashboard/DashboardChart'
import axios from 'axios'
import {toast} from 'react-toastify'
import trendup from '../../../ImgesLogos/trendup.svg'
import TitleComponent from '../../../ReUsableComponent/TitleComponent'

const APIkey = process.env.REACT_APP_SERVERAPI

function Dashboard() {
  const [totalUsers, setTotalUsers] = useState(0)
  const [totalClinics, setTotalClinics] = useState(0)

  // useEffect(() => {
  //   const fetchDashBoardData = async () => {
  //     try {
  //       const getLoginInfo = localStorage.getItem('appLoginInfo')
  //       if (getLoginInfo) {
  //         const {accessToken} = JSON.parse(getLoginInfo).data
  //         const response = await axios.get(`${APIkey}/api/admin/superadminDashboard/totalCounts`, {
  //           headers: {'x-access-token': accessToken},
  //         })
  //         const responseData = response.data.data
  //         console.log('Dashboard:', responseData)
  //         setTotalClinics(responseData.totalClinics)
  //         setTotalUsers(responseData.totalUsers)
  //       }
  //     } catch (error) {
  //       toast.error('Failed to load dashboard data')
  //     }
  //   }

  //   fetchDashBoardData()
  // }, [])

  useEffect(() => {

    const fetchAdminDashBoard = async()=>{
      try{
        const getLoginInfo = localStorage.getItem('loginInfo');
        if(getLoginInfo){
          const { accessToken } = JSON.parse(getLoginInfo).data;
          const response = await axios.get(`${APIkey}/api/admin/superadminDashboard/totalCounts`, {
            headers: { 'x-access-token': accessToken },
          });
          const responseData = response.data.data
          console.log("Dashboard:",responseData);
          setTotalUsers(responseData.totalUsers)
          setTotalClinics(responseData.totalClinics)
        }
      } catch (error) {
        toast.error('Failed to load dashboard data')
      }
    }
    fetchAdminDashBoard();
   },[]);

  return (
    <>
      <div className='dashboard-wrapper d-flex'>
        {/* Clinics Card */}
        <div className='dashboard-card '>
          <div className='card-header d-flex'>
          <div className='dashboard-image-bg'>
            <img src={Glyph} alt='Clinics' className='dashboard-image' />
            </div>
            <TitleComponent type='title' className='px-1'>Clinics</TitleComponent>
            <img src={filterlines} alt='Filter' className='filter-icon' />
          </div>
          <div className='card-body'>
            <div className='card-subtitle'>Total</div>
            <div className='card-data d-flex'>
              <span className='total-number'>{totalClinics} </span>
              <span className=''><img src={trendup}/> 45% from the day</span>
            </div>
            <div className='borderbottomLine' style={{marginTop:'2px', border: '1px solid #DCDCDC'}}></div>
          </div>
          <div className='card-footer d-flex'>
            View More
            <img src={arrowright} alt='Arrow Right' />
          </div>
        </div>

        {/* Users Card */}
        <div className='dashboard-card'>
          <div className='card-header d-flex'>
          <div className='dashboard-image-bg'>
            <img src={users} alt='Users' className='dashboard-image' />
            </div>
            <TitleComponent type='title' className='px-1'>Users</TitleComponent>
            <img src={filterlines} alt='Filter' className='filter-icon' />
          </div>
          <div className='card-body'>
            <div className='card-subtitle'>Total</div>
            <div className='card-data d-flex'>
              <span className='total-number'>{totalUsers}</span>
              <span className=''><img src={trendup}/> 45% from the day</span>
            </div>
            <div className='borderbottomLine' style={{marginTop:'2px', border: '1px solid #DCDCDC'}}></div>
          </div>
          <div className='card-footer d-flex'>
            View More
            <img src={arrowright} alt='Arrow Right' />
          </div>
        </div>

        {/* Placeholder for Subscription and Plan Expired */}
        <div className='dashboard-card '>
          <div className='card-header d-flex'>
            <div className='dashboard-image-bg'>
            <img src={Subscription} alt='Clinics' className='dashboard-image' />
            </div>
            <div className='flex-wrap'>
            <TitleComponent type='title' className='px-1'>Subscription</TitleComponent>
              <div className='card-subtitle'>&nbsp;&nbsp;&nbsp;(Expiring Soon)</div>
            </div>
            <img src={filterlines} alt='Filter' className='filter-icon' />
          </div>
          <div className='card-body'>
            <div className='card-subtitle'>Total</div>
            <div className='card-data d-flex'>
              <span className='total-number'>500 + </span>
              <span className=''><img src={trendup}/> 45% from the day</span>
            </div>
            <div className='borderbottomLine' style={{marginTop:'2px', border: '1px solid #DCDCDC'}}></div>
          </div>
          <div className='card-footer d-flex'>
            View More
            <img src={arrowright} alt='Arrow Right' />
          </div>
        </div>

        <div className='dashboard-card '>
          <div className='card-header d-flex'>
          <div className='dashboard-image-bg'>
            <img src={SubscriptionR} alt='Clinics' className='dashboard-image' />
            </div>
            <div className='flex-wrap'>
            <TitleComponent type='title' className='px-1'>Plan Expired</TitleComponent>
              <div className='card-subtitle'>&nbsp;&nbsp;&nbsp;(Action Needed)</div>
            </div>
            <img src={filterlines} alt='Filter' className='filter-icon' />
          </div>
          <div className='card-body'>
            <div className='card-subtitle'>Total</div>
            <div className='card-data d-flex'>
              <span className='total-number'>500 + </span>
              <span className=''><img src={trendup}/> 45% from the day</span>
            </div>
            <div className='borderbottomLine' style={{marginTop:'2px', border: '1px solid #DCDCDC'}}></div>
          </div>
          <div className='card-footer d-flex'>
            View More
            <img src={arrowright} alt='Arrow Right' />
          </div>
        </div>
      </div>

      {/* Placeholder for Annual Growth Chart */}
      <div className='dashboard-wrapper d-flex'>
      <div className='dashboard-card'>
      <TitleComponent type='sectionTitle'>Annual Growth of Users and Clinics</TitleComponent>
        <DashboardChart />
      </div>
      </div>
    </>
  )
}

export default Dashboard
