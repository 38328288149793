import React from 'react'
import {useEffect} from 'react'
import {useState} from 'react'
import CommonTable from '../../../../ReUsableComponent/CommonTable'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import tablecolor from '../../../../ReUsableComponent/ReyClinicalinputs.json'
const AddProblemList = ({onShow, onHide}) => {
  const APIkey = process.env.REACT_APP_SERVERAPI
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedId, setSelectedId] = useState<string[]>([])
  const [modalData, setModalData] = useState([])

  const [searchCriteria, setSearchCriteria] = useState({
    icdCode: '',
    icdName: '',
  })
  const handleSearchCriteriaChange = (e) => {
    const {name, value} = e.target
    setSearchCriteria((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const Component = ({id}) => {
    const handleCheckbox = (e) => {
      const {value, checked} = e.target

      if (checked) {
        setSelectedId((prev) => {
          if (!prev.includes(value)) {
            return [...prev, value]
          }
          return prev
        })
      } else {
        setSelectedId((prev) => prev.filter((item) => item !== value))
      }
    }

    return (
      <input
        type='checkbox'
        value={id}
        checked={selectedId.some((item) => item === id)}
        onChange={(e) => handleCheckbox(e)}
      />
    )
  }

  const AddAllergiesColumns = [
    {key: ['srNo'], header: 'Sr.No'},
    {
      key: ['select'],
      header: 'Select',
      isAction: true,
      ActionComponent: Component,
    },
    {key: ['icdCode'], header: 'ICD Code'},
    {key: ['icdName'], header: 'ICD Name'},
  ]

  const FetchProblemList = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const headers = {'x-access-token': Token}
        const response = await axios.get(`${APIkey}/api/admin/getIcdList`, {headers})
        const responseData = response.data.data

        const formattedData = responseData.map((item, index) => ({
          srNo: index + 1,
          icdCode: item.icdCode,
          icdName: item.icdName,
          select: item._id,
          _id: item._id,
        }))
        setModalData(formattedData)
      } else {
        console.log('No login found in LocalStorage')
      }
    } catch (error) {
      console.log('error in fetching allergy data:', error)
    }
  }
  useEffect(() => {
    FetchProblemList()
  }, [])

  const handleRowClick = (row) => {
    console.log('Row clicked with ID:', row)
  }

  const handleCloseModal = () => {
    onHide()
  }
  const resetFun = () => {
    setSearchCriteria({
      icdCode: '',
      icdName: '',
    })
    FetchProblemList()
  }
  const saveData = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const headers = {'x-access-token': Token}

        const response = await axios.post(
          `${APIkey}/api/admin/createPreferredIcd`,
          {icdId: selectedId},
          {headers}
        )
        console.log('API response:', response)
        if (response) {
          onHide()
        }
      } else {
        console.log('No login info found in localStorage')
      }
    } catch (error) {
      console.log('Error in posting data:', error)
    }
  }

  return (
    <>
      <Modal show={onShow} onHide={handleCloseModal} size='lg' centered>
        <Modal.Header closeButton className='h-25px dialoge align-items-center pb-3'>
          <div className='PageHeaderTitle'>Add Problem List</div>
          <div className='cross-button'></div>
        </Modal.Header>
        <Modal.Body className='p-2 dialoge'>
          <div className='dashboard-card p-2'>
            <div className='d-flex gap-3'>
              <div className='mb-3'>
                <div className='addvaccinetitle'>ICD Code:</div>
                <input
                  type='text'
                  name='icdCode'
                  className='SearchInputbox'
                  value={searchCriteria.icdCode}
                  onChange={handleSearchCriteriaChange}
                />
              </div>

              <div className='mb-3'>
                <div className='addvaccinetitle'>ICD Name:</div>
                <input
                  type='text'
                  name='icdName'
                  className='SearchInputbox'
                  value={searchCriteria.icdName}
                  onChange={handleSearchCriteriaChange}
                />
              </div>
            </div>
            <div>
              <div className='d-flex justify-content-end'>
                <button type='button' className='primarybtnBig'>
                  Search
                </button>
                &nbsp;&nbsp;
                <button
                  type='button'
                  className='tertiarybig'
                  onClick={() => [console.log('clear btn'), resetFun()]}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>

          <div className='dashboard-card mt-2'>
            {/*  {loadingModalData ? (
              <p>Loading...</p>
            ) : ( */}
            <CommonTable
              columns={AddAllergiesColumns}
              data={modalData}
              totalCount={modalData.length}
              setCurrentPage={setCurrentPage}
              setPerPage={setPerPage}
              searchQuery={searchQuery}
              perPage={perPage}
              currentPage={currentPage}
              setQuery={setSearchQuery}
              headerColor={tablecolor.primaryTable.color}
              issetPaginationCompo={true}
              isPagelength={true}
              isSearched={true}
              onRowClick={handleRowClick}
            />
            {/* )} */}
          </div>
        </Modal.Body>
        <Modal.Footer>
        <div className='mt-3'>
            <button
              className='primarybtnBig '
              onClick={saveData}
              disabled={selectedId.length === 0}
            >
              Save
            </button>
            &nbsp;&nbsp;&nbsp;
            <button className='tertiarybig ' onClick={handleCloseModal}>
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddProblemList
