import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';

const AddPatientInstructions = ({ onShow, onHide, onClick }) => {
  const APIkey = process.env.REACT_APP_SERVERAPI;

  const [category, setCategory] = useState('');
  const [instruction, setInstruction] = useState('');
  const [loading, setLoading] = useState(false);


  const saveData = async (_id) => {
    setLoading(true);
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo');
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken;
        const headers = { 'x-access-token': Token };
        const response = await axios.post(
          `${APIkey}/api/admin/createPreferredPatientInstruction`,
          { category,instruction },
          { headers }
        );
        console.log('API response:', response.data);
        onHide();
      } else {
        console.log('No login info found in localStorage');
      }
    } catch (error) {
      console.error('Error in posting data:', error);
    }
    setLoading(false);
  };

  const handleCloseModal = () => {
    onHide();
  };

  return (
    <>
      <Modal show={onShow} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton className="h-25px dialoge align-items-center pb-3">
          <div className="PageHeaderTitle">Add Patient Instructions:</div>
        </Modal.Header>
        <Modal.Body className="p-2 dialoge">
          <div className="dashboard-card">
            <div className="addvaccinetitle">Patient Instructions</div>
            <div className="form-group mt-2">
              <label htmlFor="category">Category</label>
              <input
                type="text"
                id="category"
                className="form-control"
                placeholder="Enter category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              />
            </div>
            <div className="form-group mt-2">
              <label htmlFor="instructions">Instructions</label>
              <textarea
                id="instructions"
                className="form-control"
               
                value={instruction}
                onChange={(e) => setInstruction(e.target.value)}
                placeholder="Enter instructions here"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
        <div className='mt-3'>
            <button
              className='primarybtnBig '
              onClick={saveData}
             
            >
              Save
            </button>
            &nbsp;&nbsp;&nbsp;
            <button className='tertiarybig ' onClick={handleCloseModal}>
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddPatientInstructions;
