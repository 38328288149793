import React, {ReactNode} from 'react';
import TitleComponent from '../../../ReUsableComponent/TitleComponent';

interface CommonButtonProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children?: ReactNode;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  background ? : string;
  color ? : string;
  border?: string;
}

const CommonButton: React.FC<CommonButtonProps> = ({
  onClick,
  children,
  className = '',
  type = 'button',
  background =  '#00A3FF',
  color = '#FFFFFF',
  border = '1px solid #DCDCDC'
}) => {
  return (
    <TitleComponent type='smallSizeButton' className='d-flex'>
    <button
      onClick={onClick}
      className={className}
      type={type}
      style={{
        padding: '4px 8px',
        borderRadius: '4px',
        height: '24px',
        letterSpacing: '0.5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border:  `${border}`,
        background: `${background}`,
        color: `${color}`,
      }}
    >
      {children}
    </button>
    </TitleComponent>

  );
};

export const CommonPopupButton: React.FC<CommonButtonProps> = ({
  onClick,
  children,
  className = '',
  type = 'button',
  background =  '#00A3FF',
  color = '#FFFFFF',
}) => {
  return (
  <TitleComponent type='popUpButton' className='d-flex'>
    <button
      onClick={onClick}
      className={className}
      type={type}
      style={{
        padding: '8px 24px',
        gap:'8px',
        borderRadius: '4px',
        background: `${background}`,
        color: `${color}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
      }}
    >
      {children}
    </button>
    </TitleComponent>

  );
};

export const CommonbigButton: React.FC<CommonButtonProps> = ({
  onClick,
  children,
  className = '',
  type = 'button',
  background =  '#00A3FF',
  color = '#FFFFFF',
}) => {
  return (
  <TitleComponent type='mainButton' className='d-flex'>
    <button
      onClick={onClick}
      className={className}
      type={type}
      style={{
        padding: '8px 24px',
        gap:'10px',
        borderRadius: '4px',
        background: `${background}`,
        color: `${color}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
      }}
    >
      {children}
    </button>
    </TitleComponent>

  );
};

export const CommonLoginDisplayButton: React.FC<CommonButtonProps> = ({
  onClick,
  children,
  className = '',
  type = 'button',
  background =  '#3699FF',
  color = '#FFFFFF',
}) => {
  return (
  <TitleComponent type='mainButton' className='d-flex'>
    <button
      onClick={onClick}
      className={className}
      type={type}
      style={{
        padding: '12px 165px',
        gap:'10px',
        borderRadius: '4px',
        background: `${background}`,
        color: `${color}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
      }}
    >
      {children}
    </button>
    </TitleComponent>

  );
};

export default CommonButton;

    