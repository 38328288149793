import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import SearchableDropdown from '../ReusablePages/ReusableAppointment/SearchableDropdown';
import inputText from '../../../ReUsableComponent/ReyClinicalinputs.json';
import iIcon from '../EHRIcon/infoIcon.svg';
import axios from 'axios';
import {nameRegEx, groupIdRegExp} from '../../../ReUsableComponent/regEx';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TitleComponent from '../../../ReUsableComponent/TitleComponent';
import { CommonPopupButton } from '../../../SuperAdmin/Pages/ReausableComponent/CommonButton';

function AddInsurance({open, closeInsurance, patientId}) {
  const APIkey = process.env.REACT_APP_SERVERAPI;

  const [insuranceTypeData, setInsuranceTypeData] = useState([]);
  const [coverageTypeData, setCoverageTypeData] = useState([]);
  const [payorPlanTypeData, setPayorPlanTypeData] = useState([]);
  const [layerOfCoverageData, setLayerOfCoverageData] = useState([]);
  const [alreadyExistInsurance, setAlreadyExistInsurance] = useState([]);

  // variable starts here ...
  // const [patientInsuranceType, setInsuranceType] = useState<String | undefined>()
  // const [bin, setBin] = useState<String | undefined>()
  // const [pcn, setPcn] = useState<String | undefined>()
  // const [groupId, setGroupId] = useState<String | undefined>()
  // const [insurancePlanName, setInsurancePlan] = useState<String | undefined>()
  // const [patientInsuranceId, setPatientInsuranceId] = useState<String | undefined>()
  // const [patientPersonCode, setPatientPersonCode] = useState<String | undefined>()
  // const [coverageType, setCoverageType] = useState<String | undefined>()
  // const [insuranceEffectiveDate, setInsuranceEffectiveDate] = useState<String | undefined>()
  // const [insuranceEndDate, setInsuranceEndDate] = useState<String | undefined>()
  // const [planId, setPlanId] = useState<String | undefined>()
  // const [payorPlanTypeId, setPayorPlanType] = useState<String | undefined>()
  // const [notes, setNotes] = useState<String | undefined>()

  // const [attachments_front, setAttachmentsFront] = useState<File | null>(null);
  // const [attachments_back, setAttachmentsBack] = useState<File | null>(null);

  //const [patientId, setPatientId] = useState("");

  const [loading, setLoading] = useState(false);

  const [patientInsuranceType, setInsuranceType] = useState<string | undefined>();
  const [bin, setBin] = useState<string | undefined>();
  const [pcn, setPcn] = useState<string | undefined>();
  const [groupId, setGroupId] = useState<string | undefined>();
  const [insurancePlanName, setInsurancePlan] = useState<string | undefined>();
  const [patientInsuranceId, setPatientInsuranceId] = useState<string | undefined>();
  const [patientPersonCode, setPatientPersonCode] = useState<string | undefined>();
  const [coverageType, setCoverageType] = useState<string | undefined>();
  const [insuranceEffectiveDate, setInsuranceEffectiveDate] = useState<string | undefined>();
  const [insuranceEndDate, setInsuranceEndDate] = useState<string | undefined>();
  const [planId, setPlanId] = useState<string | undefined>();
  const [payorPlanTypeId, setPayorPlanType] = useState<string | undefined>();
  const [layerOfCoverage, setTheLayerOfCoverage] = useState<string | undefined>();
  const [notes, setNotes] = useState<string | undefined>();
  const [attachments_front, setAttachmentsFront] = useState<any>(null);
  const [attachments_back, setAttachmentsBack] = useState<any>(null);

  const [frontName, setFrontName] = useState<String | undefined>();
  const [backName, setBackName] = useState<String | undefined>();

  // Allerror variables

  const [errInsuranceType, setErrInsuranceType] = useState<any>();
  const [errBin, setErrBin] = useState<any>('');
  const [errPcn, setErrPcn] = useState('');
  const [errGroupId, setErrGroupId] = useState('');
  const [errInsurancePlanName, setErrInsurancePlanName] = useState('');
  const [errInsuranceId, setErrInsuranceId] = useState('');
  const [errPersonCode, setErrPersonCode] = useState('');
  const [errCoverageType, setErrCoverageType] = useState<any>('');
  const [errEffectiveDate, setErrEffectiveDate] = useState(false);
  const [errEndDate, setErrEndDate] = useState(false);
  const [errPlanId, setErrPlanId] = useState('');
  const [errPayorPlanType, setErrPayorPlanType] = useState<any>();
  const [errLayerOfCoverage, setErrLayerOfCoverage] = useState('');
  const [errfront, setErrFront] = useState<any>();
  const [errBack, setErrBack] = useState<any>();
  const [errNotes, setErrNotes] = useState('');
  const [alreadyExistInsuranceErr, setAlreadyExistInsuranceErr] = useState('');

  const [primary, setPrimary] = useState('');
  const [secondary, setSecondary] = useState('');

  useEffect(() => {
    const getThePatientInsuranceTypeList = async () => {
      try {
        let getloginInfo: string | null = localStorage.getItem('appLoginInfo');

        if (getloginInfo !== null) {
          const Token = JSON.parse(getloginInfo).data.accessToken;

          const Id = JSON.parse(getloginInfo).data.clinicId;

          const header = {
            headers: {'x-access-token': Token},
          };

          const insuranceTypeList = await axios.get(
            `${APIkey}/api/admin/getInsuranceTypeList`,
            header
          );

          setInsuranceTypeData(
            insuranceTypeList?.data?.data?.map((i) => ({
              label: i.insuranceType,
              value: i.insuranceType,
              id: i._id,
            }))
          );

          const coverageTypeList = await axios.get(
            `${APIkey}/api/admin/getCoverageTypeList`,
            header
          );

          setCoverageTypeData(
            coverageTypeList?.data?.data?.map((i) => ({
              label: i.coverageType,
              value: i.coverageType,
              id: i._id,
            }))
          );

          const patientInsuranceData = await axios.get(
            `${APIkey}/api/admin/getPatientInsuranceByPatientId/${patientId}`,
            header
          );

          // setAlreadyExistInsurance(patientInsuranceData?.data?.data?.map((i)=> i?.layerOfCoverageDetails?._id));
          patientInsuranceData?.data?.data?.map((i) => {
            if (i?.layerOfCoverageDetails?._id == '65e1bf9efaa2c2d3a1c82151') {
              setPrimary(i?.layerOfCoverageDetails?._id);
            } else if (i?.layerOfCoverageDetails?._id == '65e1c060faa2c2d3a1c82153') {
              setSecondary(i?.layerOfCoverageDetails?._id);
            }
          });

          const LayerOfCoverageList = await axios.get(
            `${APIkey}/api/admin/getLayerOfCoverageList`,
            header
          );

          //     let patientInsuranceList = patientInsuranceData?.data?.data.map((i: any) => {
          //            if(i?.layerOfCoverageDetails?._id == "65e1bf9efaa2c2d3a1c82151"){
          //            return{
          //                 layerOfCoverageId : i?.layerOfCoverageDetails?._id ,
          //                 layerOfCoverageName : i?.layerOfCoverageDetails?.layerOfCoverage
          //             }
          //          }
          //          else if(i?.layerOfCoverageDetails?._id == "65e1bf9efaa2c2d3a1c82151" ||   i?.layerOfCoverageDetails?._id == "65e1c060faa2c2d3a1c82153" ){

          //             return{
          //                 layerOfCoverageId : i?.layerOfCoverageDetails?._id ,
          //                 layerOfCoverageName : i?.layerOfCoverageDetails?.layerOfCoverage
          //             }
          //         }

          // })

          // console.log(patientInsuranceList);

          let layercoverageList = LayerOfCoverageList?.data?.data?.map((i) => ({
            label: i.layerOfCoverage,
            value: i.layerOfCoverage,
            id: i._id,
          }));

          // console.log(layercoverageList);

          setLayerOfCoverageData(layercoverageList);

          const payorPlanTypeList = await axios.get(
            `${APIkey}/api/admin/getPayorPlanTypeList`,
            header
          );

          setPayorPlanTypeData(
            payorPlanTypeList?.data?.data?.map((i) => ({
              label: i.payorPlanType,
              value: i.payorPlanType,
              id: i._id,
            }))
          );
        } else {
          console.log('No login info found in localStorage');
        }
      } catch (err) {
        console.log(err);
      }
    };
    getThePatientInsuranceTypeList();
  }, []);

  const handelClose = () => {
    closeInsurance();
  };

  const getTheInsuranceType = (e) => {
    setInsuranceType(e);
    // console.log(e);
  };

  const getTheBin = (e) => {
    const maxLength = 10; // Maximum number of digits allowed
    let value = e.target.value; // Get the value entered by the user
    // If the length of the value exceeds the maximum length, trim it
    if (value.length > maxLength) {
      value = value.slice(0, maxLength);
    }
    setBin(value);
  };
  const getThePcn = (e) => {
    const maxLength = 10; // Maximum number of digits allowed
    let value = e.target.value; // Get the value entered by the user
    // If the length of the value exceeds the maximum length, trim it
    if (value.length > maxLength) {
      value = value.slice(0, maxLength);
    }
    setPcn(value);
  };
  const getTheGroupId = (e) => {
    setGroupId(e.target.value);
  };

  const getTheInsurancePlan = (e) => {
    setInsurancePlan(e.target.value);
  };

  const getThePatientInsuranceId = (e) => {
    setPatientInsuranceId(e.target.value);
  };

  const getThePatientPersonCode = (e) => {
    setPatientPersonCode(e.target.value);
  };
  const getTheCoverageType = (e) => {
    setCoverageType(e);
  };
  const getTheInsuranceEffectiveDate = (e) => {
    setInsuranceEffectiveDate(e.target.value);
  };
  const getTheInsuranceEndDate = (e) => {
    setInsuranceEndDate(e.target.value);
  };
  const getThePlanId = (e) => {
    setPlanId(e.target.value);
  };
  const getThePayorPlanType = (e) => {
    setPayorPlanType(e);
  };
  const getTheLayerOfCoverage = (e) => {
    // console.log(alreadyExistInsurance.reduce((acc, curr) => acc + (curr === e ? 'true' : 'false'), ''));
    setTheLayerOfCoverage(e);
  };
  const getTheNotes = (e) => {
    setNotes(e.target.value);
  };

  const handleFrontAttachmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('this is file : ', event.target.value);

    if (event.target.files && event.target.files.length > 0) {
      setAttachmentsFront(event.target.files[0]);
      setFrontName(event.target.files[0].name);
    }
  };

  const handleBackAttachmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setAttachmentsBack(event.target.files[0]);
      setBackName(event.target.files[0].name);
    }
  };

  const postInsurance = async (formData: FormData) => {
    try {
      const getloginInfo: string | null = localStorage.getItem('appLoginInfo');

      if (getloginInfo !== null) {
        const Token = JSON.parse(getloginInfo).data.accessToken;
        const header = {
          headers: {'x-access-token': Token},
          'Content-Type': 'multipart/form-data',
        };

        const response = await axios.post(
          `${APIkey}/api/admin/createPatientInsurance`,
          formData,
          header
        );
        console.log('this is response ', response);

        if (response) {
          handelClose();

          setLoading(false);

          toast.success('Insurance added successfully!', {
            position: 'top-right',
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const validation = (value: any) => {
    const error: any = {};

    if (!value.patientInsuranceType) {
      error.patientInsuranceType = inputText.insuranceType.ErrMsg;
    }

    if (value.groupId) {
      if (!groupIdRegExp.test(value.groupId)) {
        error.groupId = inputText.groupId.ErrMsg;
      }
    }

    if (!value.insurancePlanName || value.insurancePlanName.trim() == '') {
      error.insurancePlanName = inputText.InsurancePlaneName.ErrMsg;
    }

    if (value.patientInsuranceId) {
      if (!groupIdRegExp.test(value.patientInsuranceId)) {
        error.patientInsuranceId = inputText.PatientInsuranceID.validErrMsg;
      }
    } else {
      error.patientInsuranceId = inputText.PatientInsuranceID.ErrMsg;
    }

    if (value.patientPersonCode) {
      if (!groupIdRegExp.test(value.patientPersonCode)) {
        error.patientPersonCode = inputText.PatientPersonCode.validErrMsg;
      }
    }

    if (!value.coverageType) {
      error.coverageType = inputText.CoverageType.ErrMsg;
    }

    if (!insuranceEffectiveDate) {
      error.insuranceEffectiveDate = inputText.InsuranceEffectiveDate.ErrMsg;
    }

    if (!insuranceEndDate) {
      error.insuranceEndDate = inputText.InsuranceEndDate.ErrMsg;
    }

    if (value.planId) {
      if (!groupIdRegExp.test(value.planId)) {
        error.planId = inputText.PlanID.validErrMsg;
      }
    }

    if (!payorPlanTypeId) {
      error.payorPlanTypeId = inputText.PayorPlanType.ErrMsg;
    }

    if (!value.layerOfCoverage) {
      error.layerOfCoverage = inputText.layerofCoverage.ErrMsg;
    } else if (primary == value.layerOfCoverage) {
      error.layerOfCoverage = 'Already exist';
    } else if (secondary == value.layerOfCoverage) {
      error.layerOfCoverage = 'Already exist';
    }

    const maxSizeInBytes = 2 * 1024 * 1024;
    // console.log("the front .. " , value.attachments_front);

    if (value.attachments_front) {
      if (value.attachments_front.size > maxSizeInBytes) {
        error.attachments_front = inputText.UploadInsuranceCard.ErrMsg;
      } else {
        const allowedExtensions = ['.jpeg', '.jpg', '.png'];
        const fileExtension = value.attachments_front.name
          .toLowerCase()
          .slice(value.attachments_front.name.lastIndexOf('.'));
        if (!allowedExtensions.includes(fileExtension)) {
          error.attachments_front = 'Please upload a valid file type (.jpeg, .jpg, or .png)';
        }
      }
    }

    if (value.attachments_back) {
      if (value.attachments_back.size > maxSizeInBytes) {
        error.attachments_back = inputText.UploadInsuranceCard.ErrMsg;
      } else {
        const allowedExtensions = ['.jpeg', '.jpg', '.png'];
        const fileExtension = value.attachments_back.name
          .toLowerCase()
          .slice(value.attachments_back.name.lastIndexOf('.'));
        if (!allowedExtensions.includes(fileExtension)) {
          error.attachments_back = 'Please upload a valid file type (.jpeg, .jpg, or .png)';
        }
      }
    }

    return error;
  };

  const createInsurance = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let data = {
      patientId,
      patientInsuranceType,
      bin,
      pcn,
      groupId,
      insurancePlanName,
      patientInsuranceId,
      patientPersonCode,
      coverageType,
      insuranceEffectiveDate,
      insuranceEndDate,
      planId,
      payorPlanTypeId,
      notes,
      layerOfCoverage,
      attachments_front,
      attachments_back,
    };

    let err = validation(data);

    console.log('this is error', err);

    if (Object.keys(err).length === 0) {
      setLoading(true);

      let formData = new FormData();

      formData.append('patientId', patientId);
      formData.append('patientInsuranceType', patientInsuranceType || '');
      formData.append('bin', bin || '');
      formData.append('pcn', pcn || '');
      formData.append('groupId', groupId || '');
      formData.append('insurancePlanName', insurancePlanName || '');
      formData.append('patientInsuranceId', patientInsuranceId || '');
      formData.append('patientPersonCode', patientPersonCode || '');
      formData.append('coverageType', coverageType || '');
      formData.append('insuranceEffectiveDate', insuranceEffectiveDate || '');
      formData.append('insuranceEndDate', insuranceEndDate || '');
      formData.append('planId', planId || '');
      formData.append('payorPlanTypeId', payorPlanTypeId || '');
      formData.append('notes', notes || '');
      formData.append('layerOfCoverageId', layerOfCoverage || '');
      if (attachments_front) {
        formData.append('attachments_front', attachments_front);
      }
      if (attachments_back) {
        formData.append('attachments_back', attachments_back);
      }

      postInsurance(formData);

      setErrInsuranceType(err.patientInsuranceType);
      setErrGroupId(err.groupId);
      setErrInsurancePlanName(err.insurancePlanName);
      setErrInsuranceId(err.patientInsuranceId);
      setErrPersonCode(err.patientPersonCode);
      setErrCoverageType(err.coverageType);
      setErrEffectiveDate(err.insuranceEffectiveDate);
      setErrEndDate(err.insuranceEndDate);
      setErrPlanId(err.planId);
      setErrPayorPlanType(err.payorPlanTypeId);
      setErrLayerOfCoverage(err.layerOfCoverage);
      setErrFront(err.attachments_front);
      setErrBack(err.attachments_back);
    } else {
      setLoading(false);

      setErrInsuranceType(err.patientInsuranceType);
      setErrGroupId(err.groupId);
      setErrInsurancePlanName(err.insurancePlanName);
      setErrInsuranceId(err.patientInsuranceId);
      setErrPersonCode(err.patientPersonCode);
      setErrCoverageType(err.coverageType);
      setErrEffectiveDate(err.insuranceEffectiveDate);
      setErrEndDate(err.insuranceEndDate);
      setErrPlanId(err.planId);
      setErrPayorPlanType(err.payorPlanTypeId);
      setErrLayerOfCoverage(err.layerOfCoverage);
      setErrFront(err.attachments_front);
      setErrBack(err.attachments_back);
    }
  };

  const resetHandel = () => {
    setInsuranceType('');
    setBin('');
    setPcn('');
    setGroupId('');
    setInsurancePlan('');
    setPatientInsuranceId('');
    setPatientPersonCode('');
    setCoverageType('');
    setInsuranceEffectiveDate('');
    setInsuranceEndDate('');
    setPlanId('');
    setPayorPlanType('');
    setTheLayerOfCoverage('');
    setFrontName('');
    setAttachmentsFront('');
    setBackName('');
    setAttachmentsBack('');
    setNotes('');
    setErrInsuranceType('');
    setErrGroupId('');
    setErrInsurancePlanName('');
    setErrInsuranceId('');
    setErrPersonCode('');
    setErrCoverageType('');
    setErrEffectiveDate(false);
    setErrEndDate(false);
    setErrPlanId('');
    setErrPayorPlanType('');
    setErrLayerOfCoverage('');
    setErrFront('');
    setErrBack('');
  };

  return (
    <Modal show={open} onHide={handelClose} className='' size='xl' centered>
      <Modal.Header closeButton style={{background: '#F2F3F7'}} className='py-1 px-3'>
        <Modal.Title className='px-0'>
          <TitleComponent type='title'>
            Add Insurance
          </TitleComponent>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={createInsurance} className='' encType='multipart/form-data'>
        <Modal.Body className='dialoge p-1'>
          <div className='card p-2'>
            <div className='row'>
              <div className='col-md-6 col-lg-3 '>
                <TitleComponent type='label' className='px-1'>
                  {inputText.insuranceType.label}
                </TitleComponent>
                <SearchableDropdown
                  required={true}
                  gettingData={getTheInsuranceType}
                  InitialValue={patientInsuranceType}
                  placeHolder={inputText.insuranceType.placeholder}
                  options={insuranceTypeData}
                />
                {errInsuranceType && (
                  <span className='form-label errMsg'>
                    <i className='fa-solid fa-triangle-exclamation'></i>
                    &nbsp;
                    {errInsuranceType}
                  </span>
                )}
              </div>
              <div className='col-md-6 col-lg-3'>
                <TitleComponent type='label' className='px-1'>
                  {inputText.bin.label}
                </TitleComponent>
                <input
                  type='number'
                  value={bin}
                  onChange={getTheBin}
                  placeholder={inputText.bin.placeholder}
                  className='form-control SearchInputbox'
                />
                {errBin && (
                  <span className='form-label errMsg'>
                    <i className='fa-solid fa-triangle-exclamation'></i>
                    &nbsp;
                    {/* {inputText.bin.ErrMsg} */}
                    {errBin}
                  </span>
                )}
              </div>
              <div className='col-md-6 col-lg-3 '>
                <TitleComponent type='label' className='px-1'>
                  {inputText.pcn.label}
                </TitleComponent>
                <input
                  type='number'
                  value={pcn}
                  onChange={getThePcn}
                  placeholder={inputText.pcn.placeholder}
                  className='form-control SearchInputbox'
                />
                {errPcn && (
                  <span className='form-label errMsg'>
                    <i className='fa-solid fa-triangle-exclamation'></i>
                    &nbsp;
                    {/* {inputText.bin.ErrMsg} */}
                    {errPcn}
                  </span>
                )}
              </div>
              <div className='col-md-6 col-lg-3 '>
                <TitleComponent type='label' className='px-1'>
                  {inputText.groupId.label}
                </TitleComponent>
                <input
                  type='text'
                  maxLength={10}
                  onChange={getTheGroupId}
                  placeholder={inputText.groupId.placeholder}
                  className='form-control SearchInputbox'
                />

                {errGroupId && (
                  <span className='form-label errMsg'>
                    <i className='fa-solid fa-triangle-exclamation'></i>
                    &nbsp;
                    {/* {inputText.bin.ErrMsg} */}
                    {errGroupId}
                  </span>
                )}
              </div>
            </div>
            <div className='row mt-2 '>
              <div className='col-md-6 col-lg-3'>
                <TitleComponent type='label' className='px-1 required'>
                  {inputText.InsurancePlaneName.label}
                </TitleComponent>
                <input
                  type='text'
                  maxLength={50}
                  value={insurancePlanName}
                  placeholder={inputText.InsurancePlaneName.placeholder}
                  onChange={getTheInsurancePlan}
                  className='form-control SearchInputbox '
                />
                {errInsurancePlanName && (
                  <span className='form-label errMsg'>
                    <i className='fa-solid fa-triangle-exclamation'></i>
                    &nbsp;
                    {/* {inputText.InsurancePlaneName.ErrMsg} */}
                    {errInsurancePlanName}
                  </span>
                )}
              </div>
              <div className='col-md-6 col-lg-3 '>
                <TitleComponent type='label' className='px-1'>
                  {inputText.PatientInsuranceID.label}
                </TitleComponent>
                <input
                  maxLength={15}
                  type='text'
                  onChange={getThePatientInsuranceId}
                  placeholder={inputText.PatientInsuranceID.placeholder}
                  className='form-control SearchInputbox'
                />
                {errInsuranceId && (
                  <span className='form-label errMsg'>
                    <i className='fa-solid fa-triangle-exclamation'></i>
                    &nbsp;
                    {/* {inputText.InsurancePlaneName.ErrMsg} */}
                    {errInsuranceId}
                  </span>
                )}
              </div>
              <div className='col-md-6 col-lg-3 '>
                <TitleComponent type='label' className='px-1'>
                  {inputText.PatientPersonCode.label}
                </TitleComponent>
                <input
                  maxLength={10}
                  type='text'
                  onChange={getThePatientPersonCode}
                  placeholder={inputText.PatientPersonCode.placeholder}
                  className='form-control SearchInputbox'
                />
                {errPersonCode && (
                  <span className='form-label errMsg'>
                    <i className='fa-solid fa-triangle-exclamation'></i>
                    &nbsp;
                    {/* {inputText.InsurancePlaneName.ErrMsg} */}
                    {errPersonCode}
                  </span>
                )}
              </div>

              <div className='col-md-6 col-lg-3  '>
              <TitleComponent type='label' className='px-1 required'>
                  {inputText.CoverageType.label}
                </TitleComponent>
                <SearchableDropdown
                  InitialValue={coverageType}
                  required={true}
                  gettingData={getTheCoverageType}
                  placeHolder={inputText.CoverageType.placeholder}
                  options={coverageTypeData}
                />
                {errCoverageType && (
                  <span className='form-label errMsg'>
                    <i className='fa-solid fa-triangle-exclamation'></i>
                    &nbsp;
                    {/* {inputText.CoverageType.ErrMsg} */}
                    {errCoverageType}
                  </span>
                )}
              </div>
            </div>
            <div className='row mt-2 '>
              <div className='col-md-6 col-lg-3'>
              <TitleComponent type='label' className='px-1 required'>
                  {inputText.InsuranceEffectiveDate.label}
                </TitleComponent>
                <input
                  type='date'
                  placeholder={inputText.InsuranceEffectiveDate.placeholder}
                  onChange={getTheInsuranceEffectiveDate}
                  className='form-control SearchInputbox '
                />

                {errEffectiveDate && (
                  <span className='form-label errMsg'>
                    <i className='fa-solid fa-triangle-exclamation'></i>
                    &nbsp;
                    {errEffectiveDate}
                    {/* {inputText.InsuranceEffectiveDate.ErrMsg} */}
                  </span>
                )}
              </div>
              <div className='col-md-6 col-lg-3 '>
              <TitleComponent type='label' className='px-1 required'>
                  {inputText.InsuranceEndDate.label}
                </TitleComponent>
                <input
                  type='date'
                  onChange={getTheInsuranceEndDate}
                  placeholder={inputText.InsuranceEndDate.placeholder}
                  disabled={insuranceEffectiveDate ? false : true}
                  min={insuranceEffectiveDate}
                  className='form-control SearchInputbox'
                />
                {errEndDate && (
                  <span className='form-label errMsg '>
                    <i className='fa-solid fa-triangle-exclamation'></i>
                    &nbsp;
                    {errEndDate}
                    {/* {inputText.InsuranceEndDate.ErrMsg} */}
                  </span>
                )}
              </div>
              <div className='col-md-6 col-lg-3 '>
              <TitleComponent type='label' className='px-1'>
                  {inputText.PlanID.label}
                </TitleComponent>
                <input
                  type='text'
                  maxLength={15}
                  placeholder={inputText.PlanID.placeholder}
                  onChange={getThePlanId}
                  className='form-control SearchInputbox'
                />
                {errPlanId && (
                  <span className='form-label errMsg '>
                    <i className='fa-solid fa-triangle-exclamation'></i>
                    &nbsp;
                    {/* {inputText.InsuranceEndDate.ErrMsg} */}
                    {errPlanId}
                  </span>
                )}
              </div>

              <div className='col-md-6 col-lg-3  '>
              <TitleComponent type='label' className='px-1 required'>
                  {inputText.PayorPlanType.label}
                </TitleComponent>
                <SearchableDropdown
                  InitialValue={payorPlanTypeId}
                  required={true}
                  gettingData={getThePayorPlanType}
                  placeHolder={inputText.PayorPlanType.placeholder}
                  options={payorPlanTypeData}
                />
                {errPayorPlanType && (
                  <span className='form-label errMsg'>
                    <i className='fa-solid fa-triangle-exclamation'></i>
                    &nbsp;
                    {errPayorPlanType}
                    {/* {inputText.PayorPlanType.ErrMsg} */}
                  </span>
                )}
              </div>
            </div>
            <div className='row mt-2 '>
              <div className='col-md-6 col-lg-3'>
              <TitleComponent type='label' className='px-1 required'>
                  {inputText.layerofCoverage.label}
                </TitleComponent>
                <SearchableDropdown
                  InitialValue={layerOfCoverage}
                  required={true}
                  gettingData={getTheLayerOfCoverage}
                  placeHolder={inputText.layerofCoverage.placeholder}
                  options={layerOfCoverageData}
                />
                {errLayerOfCoverage && (
                  <span className='form-label errMsg'>
                    <i className='fa-solid fa-triangle-exclamation'></i>
                    &nbsp;
                    {errLayerOfCoverage}
                    {/* {inputText.layerofCoverage.ErrMsg} */}
                  </span>
                )}
              </div>
              <div className='col-md-12 col-lg-6 '>
              <TitleComponent type='label' className='px-1'>
                  {inputText.UploadInsuranceCard.label}
                  <img src={iIcon} title='Upload file with size upto 2 MB'></img>{' '}
                </TitleComponent>

                {/* <input type="file" onChange={handleFrontAttachmentChange} accept="image/*" /> */}
                {/* <input type="file" onChange={handleBackAttachmentChange} accept="image/*" /> */}
                <div className='d-flex gap-5' >
                  <div className='w-50'>
                    <label
                      htmlFor='upload-photo1'
                      className={
                        errfront
                          ? 'uploadErrbtn  d-flex align-items-center justify-content-center'
                          : ' uploadbtn  d-flex align-items-center justify-content-center'
                      }
                    >
                      {frontName ? (
                        <div>
                          <span>
                            <span style={{color: '#0027FF', textDecoration: 'underLine'}}>
                              browse
                            </span>
                            {frontName}
                          </span>
                        </div>
                      ) : (
                        <div>
                          <i className='fa fa-cloud-upload mx-2' aria-hidden='true'></i>Front Side
                          of Insurance{' '}
                        </div>
                      )}
                      <input
                        type='file'
                        onChange={handleFrontAttachmentChange}
                        id='upload-photo1'
                        accept='image/*'
                        style={{display: 'none'}}
                      />
                    </label>

                    {errfront && (
                      <span className='form-label errMsg'>
                        <i className='fa-solid fa-triangle-exclamation'></i>
                        &nbsp;
                        {errfront}
                        {/* {inputText.UploadInsuranceCard.ErrMsg} */}
                      </span>
                    )}
                  </div>
                  <div className='w-50'>
                    <label
                      htmlFor='upload-photo2'
                      className={
                        errBack
                          ? 'uploadErrbtn  d-flex align-items-center justify-content-center'
                          : ' uploadbtn  d-flex align-items-center justify-content-center'
                      }
                    >
                      {backName ? (
                        <div>
                          <i className='fa fa-cloud-upload mx-2' aria-hidden='true'></i>
                          <span> {backName} / Back Side </span>
                        </div>
                      ) : (
                        <div>
                          <i className='fa fa-cloud-upload mx-2' aria-hidden='true'></i>Back Side of
                          Insurance{' '}
                        </div>
                      )}

                      <input
                        type='file'
                        onChange={handleBackAttachmentChange}
                        id='upload-photo2'
                        accept='image/*'
                        style={{display: 'none'}}
                      />
                    </label>
                    {errBack && (
                      <span className='form-label errMsg '>
                        <i className='fa-solid fa-triangle-exclamation'></i>
                        &nbsp;
                        {errBack}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12 col-lg-6'>
                  <div>
                  <TitleComponent type='label' className='px-1'>{inputText.notes.label}</TitleComponent>
                  </div>
                  <div>
                    <textarea
                      maxLength={500}
                      value={notes}
                      className='form-control SearchInputbox'
                      onChange={getTheNotes}
                      placeholder={inputText.notes.placeholder}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </form>
      <Modal.Footer className='dialoge p-1'>
      <div className='d-flex gap-2 justify-content-end '>
            {loading ? (
              <div className='loader'></div>
            ) : (
              <CommonPopupButton type='submit'>
                Save
              </CommonPopupButton>
            )}
            <CommonPopupButton color='#5E6278' background='#F5F8FA' border='1px solid #DCDCDC ' type='reset' onClick={resetHandel}>
              Reset
            </CommonPopupButton>
            <CommonPopupButton color='#818181' background='#D7D7D7' onClick={handelClose}>
              Close
            </CommonPopupButton>
          </div>
      </Modal.Footer>
    </Modal>
    // <div>
    //     <h1>
    //         Add Insurance
    //     </h1>
    // </div>
  );
}

export default AddInsurance;
