import Modal from 'react-bootstrap/Modal'
import ModalImage from 'react-modal-image'
import TitleComponent from '../../../ReUsableComponent/TitleComponent'
import { CommonPopupButton } from '../ReausableComponent/CommonButton'

const ViewUserDetail = ({show, handleClose, item}) => {
  if (!item) return null // Return null to render nothing if there is no item.
console.log(item)
  return (
    <Modal show={show} onHide={handleClose} size='lg'>
      <Modal.Header closeButton style={{background: '#F2F3F7'}} className='py-0'>
        <Modal.Title><TitleComponent type='title'>User Information</TitleComponent></Modal.Title>
      </Modal.Header>
      <Modal.Body style={{background: '#F2F3F7', padding: '5px 10px'}}>
        <div className='d-flex' style={{background: 'white', borderRadius: '8px', padding: '10px'}}>
          {/* Left Section: Clinic Information */}
          <div className='form-label'>
          <TitleComponent type='sectionTitle'>
              <strong>User Information:</strong>
            </TitleComponent>
            {/* <img
              src={item.clinicLogo || '/default-logo.png'}
              alt={`${item.clinicName || 'Default'} Clinic Logo`}
              className="clinic-logo"
            /> */}
            <ModalImage
              small={item.clinicLogo}
              large={item.clinicLogo}
              alt=''
              hideDownload={true} // Optionally hide download button
              hideZoom={true} // Optionally hide zoom button
            />
          <TitleComponent type='label' className='px-1 '>
              <strong>Clinic Name:</strong> {item.cData.clinicName}
            </TitleComponent>
            <TitleComponent type='label' className='px-1 '>
              <strong>Clinic Website:</strong> {item.cData.clinicWebsite}
            </TitleComponent>
            <TitleComponent type='label' className='px-1 '>
              <strong>Address:</strong>{' '}
              {`${item.cData.addressLine1}, ${item.cData.addressLine2}, 
            ${item.cData.cityName}, ${item.cData.stateName},${item.cData.countryName}`
            }
            </TitleComponent>
            <TitleComponent type='label' className='px-1 '>
              <strong>Group ID:</strong> {item?.cData?.clinicName}
            </TitleComponent>
          </div>

          {/* Right Section: Contact Person Information */}
          <div className='form-label'>
          <TitleComponent type='sectionTitle'>
              <strong>Contact Person:</strong>
            </TitleComponent>
            <TitleComponent type='label' className='px-1 '>
              <strong>User Name:</strong> {item.username}
            </TitleComponent>
            <TitleComponent type='label' className='px-1 '>
              <strong>Full Name:</strong> {`${item.firstName} ${item.middleName || ''} ${item.lastName}`}
            </TitleComponent>
            <TitleComponent type='label' className='px-1 '>
              <strong>Email:</strong> {item.email}
            </TitleComponent>
            <TitleComponent type='label' className='px-1 '>
              <strong>Cell Phone No.:</strong> {item.contactNumber1}
            </TitleComponent>
            <TitleComponent type='label' className='px-1 '>
              <strong>Work Phone No.:</strong> {item.contactNumber2} 
              </TitleComponent>
              <TitleComponent type='label' className='px-1 '>
              <strong>Ext:</strong>{' '}
              {item.ext || 'N/A'}
            </TitleComponent>
            <TitleComponent type='label' className='px-1 '>
              <strong>Refer by:</strong> {item.referBy || 'N/A'}
            </TitleComponent>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer style={{background: '#F2F3F7'}}>
        <CommonPopupButton background={'#D7D7D7'} color={'#818181'} onClick={handleClose}>
          Close
        </CommonPopupButton>
      </Modal.Footer>
    </Modal>
  )
}

export default ViewUserDetail
