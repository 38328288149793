import React from 'react'
import AppointmentPageHeader from '../ReusablePages/ReusableAppointment/AppointmentPageHeader'
import PatientIdCard from '../ReusablePages/ReusableAppointment/PatientIdCard'
import SerchableDropdown from '../ReusablePages/ReusableAppointment/SearchableDropdown'

function PatientReviewOfResult() {
  return (
    <>
     <AppointmentPageHeader name="Review of Results" />
     <PatientIdCard />

     <div className='card p-2 mt-2 '>
        <div className="row   mt-1 ">
            <div className='col-md-4 d-flex align-items-center '>
        <label htmlFor="" className='form-label'>Type : <span style={{color :"red"}}>*</span></label>
        <div className='largeInput75'>

        <SerchableDropdown placeHolder=""  />
        </div>
            </div>
            <div className='col-md-4  d-flex align-items-center '>
        <label htmlFor="" className='form-label'>Provider : <span style={{color :"red"}}>*</span></label>
        <div className='largeInput75'>

        <SerchableDropdown placeHolder="" />
        </div>
            </div>
            <div className='col-md-4 d-flex align-items-center '>
        <label htmlFor="" className='form-label'>Date:</label>
        <input type="date" className='mediumInput50 form-control ' />
            </div>

            <div className='mt-2'>
                <div>
                    <label  className='form-label'>Attach File(s):</label>
                    <label htmlFor="upload-photo" className=' uploadbtn mx-2'  ><i className="fa fa-cloud-upload mx-2"  aria-hidden="true"></i>Drop file to attach, or <span style={{color:"blue"  , textDecoration:"underline"}}>browse</span>
                    <input type="file" id='upload-photo' style={{display: "none"}} />
                    </label>
                </div>
            </div>

            <div className='d-flex mt-2'>
                <div className='' style={{width:"110px "}}>

                <label  className='form-label '>Observation: <span style={{color: "red"}}>*</span></label>
                </div>
                <textarea className='form-control mx-1' name="" id="" ></textarea>
            </div>

          
        <div className=' row mt-2 '>
              <div className='col d-flex  d-grid gap-2'>
                <div>

                <button className='primarybtnBig'>Save</button>
                </div>
                <div>

                <button className=' tertiarybig'>Cancel</button>
                </div>
                <div>

                <button className=' primarybtnBig'>Review</button>
                </div>
              </div>
         

        </div>
           
        </div>

     </div>

     <div className='card mt-2'>

        <div>
            <table className='CurveTable w-100 '>
                <thead>
                    <tr className='tableHead'>
                        <th>

                        </th>
                        <th>
                            Date 
                        </th>
                        <th>
                          Type
                        </th>
                        <th>
                        Observation
                        </th>
                        <th>
                        Provider
                        </th>
                        <th>
                        Added By
                        </th>
                        <th>
                        Attachment
                        </th>
                        <th>

                        </th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr className='tableBody'>
                        <td>
                            <button className='blueButton'><i className="fa fa-check" aria-hidden="true"></i></button>
                        </td>
                        <td>
                            
                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>
                            icon 
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className='PatientChartCardFooter'>
            <div>
                <span>Total Numbers Of Records : {}</span>
            </div>
            <div>
                pagination ... 
            </div>
        </div>

     </div>
    </>
  )
}

export default PatientReviewOfResult
