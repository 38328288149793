import React, {useEffect, useState} from 'react';
import SearchableDropdown from '../ReausableComponent/SearchableDropdown';
import {useNavigate} from 'react-router-dom';
import NameCompo from '../ReausableComponent/NameCompo';
import inputText from '../../../ReUsableComponent/ReyClinicalinputs.json';
import ContactComponent from '../ReausableComponent/ContactComponent';
import AddressComponent from '../../../ReUsableComponent/AddressComponent';
import ModalImage from 'react-modal-image';
import search from '../../../ImgesLogos/search.svg';
import searchiconwhite from '../../../ImgesLogos/searchiconwhite.svg';
import {
  nameRegEx,
  npiNoRegExp,
  EmailRegExp,
  phoneNoRegexp,
  AddressRegExp,
  ZipRegEx,
} from '../../../ReUsableComponent/regEx';
import axios from 'axios';
import UserValidation from './UserValidation';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CancelConfirmation from '../../../ReUsableComponent/CancelConfirmation';
import SearchableDropdownForGroupId from '../Clinics/SearchableDropdownForGroupId';
import TitleComponent from '../../../ReUsableComponent/TitleComponent';
import CommonButton, {CommonbigButton} from '../ReausableComponent/CommonButton';

function AddUser() {
  const APIkey = process.env.REACT_APP_SERVERAPI;
  const [listclinic, setListclinic] = useState<Array<any> | null>(null);
  const [designationList, setdesignationList] = useState<Array<any> | null>(null);
  const [roleList, setRoleList] = useState<Array<any> | null>(null);
  const [specialityList, setSpecialityList] = useState<Array<any> | null>(null);
  const [cancelPopUp, setCancelPopUp] = useState<any>(false);
  const [npiId, setNpi] = useState<any>('');
  const [clinicId, setClinicId] = useState();
  const [designationId, setdesignationId] = useState();
  const [roleId, setRole] = useState();
  const [specialityId, setSpecialityId] = useState('');
  const [email, setEmail] = useState();
  const [addressLine1, setAddressLine1] = useState<string | undefined>();
  const [addressLine2, setAddressLine2] = useState<string | undefined>();
  const [cityId, setCity] = useState();
  const [stateId, setState] = useState();
  const [countryId, setCountry] = useState();
  const [zipcodeId, setZipcode] = useState();
  const [prefixId, setprefix] = useState();
  const [firstName, setfirstName] = useState();
  const [lastName, setLastName] = useState();
  const [middleName, setmiddleName] = useState();
  const [suffixId, setsuffix] = useState();
  const [contactNumber1, setcellNumber] = useState<any>();
  const [countryCode1, setcountryCode1] = useState<any>();
  const [contactNumber2, setworkNumber] = useState();
  const [countryCode2, setcountryCode2] = useState();
  const [extID, setExt] = useState();
  const [userProfilePicDetails, setUserProfilePicDetails] = useState<any>();
  const [user_profile, setUser_profile] = useState<any>();
  const [npiNumber, setNpiNumber] = useState<any>();
  //error starts here
  const [errNpi, setErrNpi] = useState('');
  const [errClinicName, setErrClinicName] = useState('');
  const [errDesignation, setErrDesignation] = useState('');
  const [errRole, setErrRole] = useState('');
  const [errSpeciality, setErrSpeciality] = useState('');
  const [errSpecialityId, setErrSpecialityId] = useState('');
  const [errAddressLine1, setErrAddressLine1] = useState('');
  const [errAddressLine2, setErrAddressLine2] = useState('');
  const [errCity, setErrCity] = useState('');
  const [errState, setErrState] = useState('');
  const [errcountry, setErrcountry] = useState('');
  const [errZipcode, setErrZipcode] = useState('');
  const [errLastName, setErrLastName] = useState('');
  const [errFirstName, setErrFirstName] = useState('');
  const [errMiddleName, setErrMiddleName] = useState('');
  const [errEmail, setErrEmail] = useState('');
  const [errCellPhone, setErrCellPhone] = useState('');
  const [errWorkPhone, setErrWorkPhone] = useState('');
  const [errExt, setErrExt] = useState('');
  const [errUserProfile, setErrUserProfile] = useState('');
  let [RedirectManagePermission, setRedirectManagePermission] = useState<any>(false);
  const navigate = useNavigate();
  const getTheNpi = (e) => {
    const maxLength = 10; // Maximum number of digits allowed
    let value = e.target.value; // Get the value entered by the user

    // If the length of the value exceeds the maximum length, trim it
    if (value.length > maxLength) {
      value = value.slice(0, maxLength);
    }

    setNpi(value);
  };

  const getprefix = (e) => {
    setprefix(e);
  };

  const getFirstName = (e) => {
    setfirstName(e);
  };
  const getLastName = (e) => {
    setLastName(e);
  };
  const getMiddleName = (e) => {
    setmiddleName(e);
  };

  const getsuffix = (e) => {
    setsuffix(e);
  };

  useEffect(() => {
    const getClinicList = async () => {
      try {
        let getloginInfo: string | null = localStorage.getItem('loginInfo');

        if (getloginInfo !== null) {
          const Token = JSON.parse(getloginInfo).data.accessToken;
          const header = {
            headers: {'x-access-token': Token},
          };

          const clinicList = await axios.get(`${APIkey}/api/admin/listClinic`, header);
          // console.log(clinicList);

          setListclinic(
            clinicList.data.data.map((i) => ({
              label: i.clinicName,
              value: i.clinicName,
              id: i._id,
            }))
          );

          const res = await axios.get(`${APIkey}/api/admin/getRoleList`, header);

          // console.log(response);

          setRoleList(
            res.data.data.map((i) => ({
              label: i.role,
              value: i.role,
              id: i._id,
            }))
          );

          const specialityList = await axios.get(`${APIkey}/api/admin/getSpecialityList`, header);
          // console.log(clinicList);

          setSpecialityList(
            specialityList.data.data.map((i) => ({
              label: i.specialityName,
              value: i.specialityName,
              id: i._id,
            }))
          );
        } else {
          console.log('No login info found in localStorage');
        }
      } catch (err) {
        console.log(err);
      }
    };
    getClinicList();
  }, []);

  const getTheClinicID = async (id) => {
    setClinicId(id);

    try {
      let getloginInfo: string | null = localStorage.getItem('loginInfo');

      if (getloginInfo !== null) {
        const Token = JSON.parse(getloginInfo).data.accessToken;
        const header = {
          headers: {'x-access-token': Token},
        };

        const response = await axios.get(`${APIkey}/api/admin/getDesignationList/${id}`, header);
        setdesignationList(
          response.data.data.map((i) => ({
            label: i.designation,
            value: i.designation,
            id: i._id,
          }))
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getTheDesignation = (id) => {
    setdesignationId(id);
  };

  const getTheRole = (e) => {
    setRole(e);
  };
  const getThespecialityName = (e) => {
    setRole(e);
  };
  const getTheEmail = (e) => {
    setEmail(e.target.value);
  };
  const getTheSpeciality = (e) => {
    setSpecialityId(e);
  };

  const getAddress1 = (e) => {
    setAddressLine1(e);
  };
  const getAddress2 = (e) => {
    setAddressLine2(e);
  };

  const getCity = (e) => {
    setCity(e);
  };
  const getState = (e) => {
    setState(e);
  };

  const getCountry = (e) => {
    setCountry(e);
  };

  const getZipcode = (e) => {
    setZipcode(e);
  };

  const getCellNumber = (e) => {
    setcellNumber(e.getTheNumber);
    setcountryCode1(e.gettheCode);
  };

  const getWorkNumber = (e) => {
    setworkNumber(e.getTheNumber);
    setcountryCode2(e.gettheCode);
  };

  const getExt = (e) => {
    setExt(e);
  };

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];

    setUserProfilePicDetails(file);
    if (file) {
      const reader = new FileReader();

      reader?.readAsDataURL(file);
      reader.onloadend = () => {
        setUser_profile(reader?.result);
      };
    }
  };

  const searchNpiNumber = async () => {
    try {
      let getloginInfo: string | null = localStorage.getItem('loginInfo');

      if (getloginInfo !== null) {
        const Token = JSON.parse(getloginInfo).data.accessToken;
        const header = {
          headers: {'x-access-token': Token},
        };

        const apiUrl = `${APIkey}/api/admin/getnpiById/${npiId}`;

        const response = await axios.get(apiUrl, header);

        let NpiData = response?.data?.data?.results[0];

        console.log('NPI Data : ', response?.data?.data?.results[0].addresses[0]);

        // Handle the response data
        let namedetails = NpiData?.basic;
        let addressDetails = NpiData?.addresses[0];

        console.log(addressDetails);
        setNpiNumber(NpiData?.number);
        setfirstName(namedetails?.first_name);
        setLastName(namedetails?.last_name);
        setAddressLine1(addressDetails?.address_1);
        // setcellNumber(a)
        if (addressDetails?.country_code === 'US') {
          setcountryCode1('+1');
          const onlyNumbers = addressDetails?.telephone_number?.replace(/\D/g, '');
          setcellNumber(onlyNumbers);
        }
      }
    } catch (error) {
      // Handle errors
      console.error('Error:', error);
    }
  };

  const createUser = async (data, redirectManage) => {
    try {
      let getloginInfo: string | null = localStorage.getItem('loginInfo');

      if (getloginInfo !== null) {
        const Token = JSON.parse(getloginInfo).data.accessToken;
        const header = {
          headers: {'x-access-token': Token},
        };

        const response = await axios.post(`${APIkey}/api/admin/createUser`, data, header);
        console.log(response);

        if (response) {
          if (redirectManage) {
            navigate(
              `/user/managePermission/${response.data.data.clinicId}/${response.data.data._id}`
            );

            toast.success('User added successfully!', {
              position: 'top-right',
            });
          } else {
            navigate('/user');

            toast.success('User added successfully!', {
              position: 'top-right',
            });
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const SaveData = (redirectManage) => {
    let listOfData = {
      npiId: npiNumber,
      clinicId,
      prefixId,
      firstName,
      lastName,
      middleName,
      suffixId,
      designationId,
      addressLine1,
      addressLine2,
      cityId,
      stateId,
      countryId,
      zipcodeId,
      contactNumber1,
      contactNumber2,
      countryCode1,
      countryCode2,
      ext: extID,
      roleId,
      email,
      specialityId,
      userProfilePicDetails,
      user_profile,
    };

    let errors = UserValidation(listOfData);

    if (Object.keys(errors).length === 0) {
      createUser(listOfData, redirectManage);
      console.log(' form submitted Successfully');

      setErrNpi(errors.npiId);
      setErrClinicName(errors.clinicId);
      setErrDesignation(errors.designationId);
      setErrRole(errors.roleId);
      setErrSpecialityId(errors.specialityId);
      setErrAddressLine1(errors.addressLine1);
      setErrAddressLine2(errors.addressLine2);
      setErrZipcode(errors.zipcodeId);
      // setErrSpecialityId(err.specialityId)
      setErrLastName(errors.lastName);
      setErrFirstName(errors.firstName);
      setErrMiddleName(errors.middleName);
      setErrEmail(errors.email);
      setErrCellPhone(errors.contactNumber1);
      setErrWorkPhone(errors.contactNumber2);
      setErrExt(errors.extID);

      setErrcountry(errors.countryId);
      setErrState(errors.stateId);
      setErrCity(errors.cityId);
      setErrUserProfile(errors.profilePicErr);
    } else {
      setErrNpi(errors.npiId);
      setErrClinicName(errors.clinicId);
      setErrDesignation(errors.designationId);
      setErrRole(errors.roleId);
      setErrSpecialityId(errors.specialityId);
      setErrAddressLine1(errors.addressLine1);
      setErrAddressLine2(errors.addressLine2);
      setErrZipcode(errors.zipcodeId);
      // setErrSpecialityId(err.specialityId)
      setErrLastName(errors.lastName);
      setErrFirstName(errors.firstName);
      setErrMiddleName(errors.middleName);
      setErrEmail(errors.email);
      setErrCellPhone(errors.contactNumber1);
      setErrWorkPhone(errors.contactNumber2);
      setErrExt(errors.extID);

      setErrcountry(errors.countryId);
      setErrState(errors.stateId);
      setErrCity(errors.cityId);
      setErrUserProfile(errors.profilePicErr);
    }
  };

  const handleManagePermission = () => {
    let redirectManage = true;
    if (redirectManage) {
      SaveData(redirectManage);
    }
  };

  const handleWithOutRedirect = () => {
    let redirectManage = false;

    SaveData(redirectManage);
  };

  const closeCancel = () => {
    setCancelPopUp(false);
  };

  return (
    <>
      <div className='px-4'>
        <div className='PageHeader'>
          <TitleComponent type='title'>Add New User</TitleComponent>
        </div>
        <div className='card mt-3  p-3'>
          <div>
            <div className='PageHeader'>
              <TitleComponent type='sectionTitle'>Contact Person:</TitleComponent>
            </div>
            <div className='row mt-2 '>
              <div className='col-md-6  col-lg-4 '>
                <TitleComponent type='label' className='px-1'>
                  {inputText.NpiID.label}
                </TitleComponent>
                <div className='d-flex gap-2 align-items-center'>
                  <div className='w-75'>
                    <input
                      onChange={getTheNpi}
                      value={npiId}
                      type='number'
                      className='form-control SearchInputbox'
                    ></input>
                    {/* {errNpi && (
                      <span className='formLabelTitle errMsg'>
                        <i className='fa-solid fa-triangle-exclamation'></i>
                        &nbsp;
                        {errNpi}
                      </span>
                    )} */}
                  </div>

                  <CommonButton onClick={searchNpiNumber}>
                    <img src={searchiconwhite} alt='search icon' />
                    Search
                  </CommonButton>
                </div>

                {/* {errNpi && (
                  <span className='formLabelTitle errMsg'>
                    <i className='fa-solid fa-triangle-exclamation'></i>
                    &nbsp;
                    {errNpi}
                  </span>
                )} */}
              </div>
            </div>

            <div className='row mt-2 mb-2'>
              <div className='col-md-6  col-lg-4  '>
                <div className={user_profile ? 'd-flex justify-content-between  gap-2' : 'w-100'}>
                  <div className={user_profile ? 'w-75' : 'w-100'}>
                    <TitleComponent type='label' className='px-1'>
                      Profile Picture:
                    </TitleComponent>
                    <label
                      className={
                        errUserProfile
                          ? 'uploadErrbtn  d-flex align-items-center justify-content-center formLabelTitle'
                          : ' uploadbtn  d-flex align-items-center justify-content-center formLabelTitle'
                      }
                    >
                      {userProfilePicDetails ? (
                        <div className=' d-flex align-items-center justify-content-center gap-2'>
                          <label
                            htmlFor='upload-photo1'
                            style={{
                              color: '#0027FF',
                              textDecoration: 'underLine',
                              cursor: 'pointer',
                            }}
                          >
                            browse
                          </label>
                          {userProfilePicDetails?.name}
                        </div>
                      ) : (
                        <div className=''>
                          <i
                            className='fa fa-cloud-upload mx-2'
                            title='Upload file with size upto 2 MB'
                            aria-hidden='true'
                          ></i>
                          Upload Your Clinic Logo &nbsp;
                          <label
                            htmlFor='upload-photo1'
                            style={{
                              color: '#0027FF',
                              textDecoration: 'underLine',
                              cursor: 'pointer',
                            }}
                          >
                            browse
                          </label>
                        </div>
                      )}
                      <input
                        type='file'
                        onChange={handleProfilePicChange}
                        id='upload-photo1'
                        accept='image/*'
                        style={{display: 'none'}}
                      />
                    </label>
                  </div>
                  {user_profile && (
                    <div className='w-25 form-control p-2'>
                      <ModalImage
                        small={user_profile}
                        large={user_profile}
                        alt=''
                        hideDownload={true} // Optionally hide download button
                        hideZoom={true} // Optionally hide zoom button
                      />
                    </div>
                  )}
                </div>

                {errUserProfile && (
                  <span className='formLabelTitle errMsg'>
                    <i className='fa-solid fa-triangle-exclamation'></i>
                    &nbsp;
                    {errUserProfile}
                  </span>
                )}
              </div>

              <div className='col-md-6 col-lg-4 '>
                <TitleComponent type='label' className='px-1 required'>
                  NPI:
                </TitleComponent>
                <input
                  type='number'
                  // value={npiNumber}
                  className='form-control SearchInputbox'
                  // maxLength={15}
                  onChange={(e) => setNpiNumber(e.target.value)}
                  readOnly={false}
                  value={npiNumber}
                  // type='number'
                  // className='form-control SearchInputbox'
                />
                {errNpi && (
                  <span className='formLabelTitle errMsg'>
                    <i className='fa-solid fa-triangle-exclamation'></i>
                    &nbsp;
                    {/* {inputText.NpiID.ErrMsg} */}
                    {errNpi}
                  </span>
                )}
              </div>
            </div>

            <div className='row '>
              <NameCompo
                colno={'4'}
                prefixValue={prefixId}
                lastNameValue={lastName}
                firstNameValue={firstName}
                middleNameValue={middleName}
                suffixValue={suffixId}
                getPrefixData={getprefix}
                getSuffixData={getsuffix}
                getfName={getFirstName}
                getlName={getLastName}
                getmName={getMiddleName}
                lastNameErr={errLastName}
                firstNameErr={errFirstName}
                middleNameErr={errMiddleName}
                isUserFixes={true}
              />
            </div>

            <div className='row mt-2 '>
              <div className='col-md-3  col-lg-3 mb-4'>
                <TitleComponent type='label' className='px-1 required'>
                  {inputText.ClinicName.label}
                </TitleComponent>
                <SearchableDropdownForGroupId
                  InitialValue={clinicId}
                  placeHolder={inputText.ClinicName.placeholder}
                  gettingData={getTheClinicID}
                  options={listclinic}
                />

                {errClinicName && (
                  <span className='formLabelTitle errMsg'>
                    <i className='fa-solid fa-triangle-exclamation'></i>
                    &nbsp;
                    {errClinicName}
                  </span>
                )}
              </div>
              <div className='col-md-3  col-lg-3 mb-4'>
                <TitleComponent type='label' className='px-1 required'>
                  {inputText.designation.label}
                </TitleComponent>
                <SearchableDropdown
                  InitialValue={designationId}
                  placeHolder={inputText.designation.placeholder}
                  gettingData={getTheDesignation}
                  options={designationList}
                />

                {errDesignation && (
                  <span className='formLabelTitle errMsg'>
                    <i className='fa-solid fa-triangle-exclamation'></i>
                    &nbsp;
                    {/* {inputText.designation.ErrMsg} */}
                    {errDesignation}
                  </span>
                )}
              </div>

              <div className='col-md-3 col-lg-3 mb-4'>
                <TitleComponent type='label' className='px-1 required'>
                  {inputText.role.label}
                </TitleComponent>
                <SearchableDropdown
                  InitialValue={roleId}
                  placeHolder={inputText.role.placeholder}
                  gettingData={getTheRole}
                  options={roleList}
                />

                {errRole && (
                  <span className='formLabelTitle errMsg'>
                    <i className='fa-solid fa-triangle-exclamation'></i>
                    &nbsp;
                    {/* {inputText.role.ErrMsg} */}
                    {errRole}
                  </span>
                )}
              </div>

              <div className='col-md-3 col-lg-3 mb-4'>
                <TitleComponent type='label' className='px-1 required'>
                  {inputText.specialityName.label}
                </TitleComponent>
                <SearchableDropdown
                  InitialValue={roleId}
                  placeHolder={inputText.specialityName.placeholder}
                  gettingData={getTheSpeciality}
                  options={specialityList}
                />

                {errSpeciality && (
                  <span className='formLabelTitle errMsg'>
                    <i className='fa-solid fa-triangle-exclamation'></i>
                    &nbsp;
                    {/* {inputText.role.ErrMsg} */}
                    {errSpeciality}
                  </span>
                )}
              </div>
            </div>

            <div className='row'>
              <ContactComponent
                col={4}
                adminfield={1}
                emailValue={email}
                contactno1Value={`${countryCode1}${contactNumber1}`}
                contactno2Value={`${countryCode2}${contactNumber2}`}
                contactno3Value={''}
                specialityId={''}
                faxValue={''}
                extValue={extID}
                getTheEmail={getTheEmail}
                getTheCellNumber={getCellNumber}
                getTheWorkNumber={getWorkNumber}
                getTheExt={getExt}
                emailErr={errEmail}
                workNoErr={errWorkPhone}
                cellNoErr={errCellPhone}
                extErr={errExt}
              />
            </div>

            <div className='row'>
              <AddressComponent
                col={'4'}
                address1value={addressLine1}
                address2value={addressLine2}
                countryValue={countryId}
                stateValue={stateId}
                cityValue={cityId}
                zipcodevalue={zipcodeId}
                specialityId={''}
                Address1get={getAddress1}
                Address2get={getAddress2}
                Cityget={getCity}
                StateGet={getState}
                countryget={getCountry}
                zipcodeget={getZipcode}
                address1Err={errAddressLine1}
                address2Err={errAddressLine2}
                cityErr={errCity}
                stateErr={errState}
                countryErr={errcountry}
                zipErr={errZipcode}
              />
            </div>

            <div className='d-flex mt-3 justify-content-end'>
              <div className='d-flex gap-2'>
                <div>
                  <CommonbigButton
                    onClick={() => handleWithOutRedirect()}
                  >
                    Save
                  </CommonbigButton>
                </div>
                <div>
                  <CommonbigButton
                    onClick={() => handleManagePermission()}
                  >
                    Save And Manage Permission{' '}
                  </CommonbigButton>
                </div>
                <div>
                  <CommonbigButton
                    background={'#D7D7D7'}
                    color={'#818181'}
                    onClick={() => setCancelPopUp(true)}
                  >
                    Cancel
                  </CommonbigButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {cancelPopUp && (
        <CancelConfirmation openCancel={cancelPopUp} closeCancel={closeCancel} Routes='/user' />
      )}
    </>
  );
}

export default AddUser;
