import React, {useEffect, useState} from 'react';
import SearchableDropdown from '../ReausableComponent/SearchableDropdown';
import {useNavigate, useParams} from 'react-router-dom';
import NameCompo from '../ReausableComponent/NameCompo';
import inputText from '../../../ReUsableComponent/ReyClinicalinputs.json';
import ContactComponent from '../ReausableComponent/ContactComponent';
import AddressComponent from '../../../ReUsableComponent/AddressComponent';
import ModalImage from 'react-modal-image';
import searchiconwhite from '../.././../ImgesLogos/searchiconwhite.svg';

import axios from 'axios';

import {
  phoneNoRegexp,
  AddressRegExp,
  ZipRegEx,
  EmailRegExp,
  nameRegEx,
  npiNoRegExp,
} from '../../../ReUsableComponent/regEx';
import UserValidation from './UserValidation';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CancelConfirmation from '../../../ReUsableComponent/CancelConfirmation';
import SearchableDropdownForGroupId from '../Clinics/SearchableDropdownForGroupId';
import { CommonbigButton } from '../ReausableComponent/CommonButton';
import TitleComponent from '../../../ReUsableComponent/TitleComponent';

function UpdateUser() {
  const APIkey = process.env.REACT_APP_SERVERAPI;
  const {clinicparamsId, userparamsId} = useParams();

  // console.log(clinicparamsId , userparamsId);

  const [listclinic, setListclinic] = useState<Array<any> | null>(null);
  const [designationList, setdesignationList] = useState<Array<any> | null>(null);
  const [roleList, setRoleList] = useState<Array<any> | null>(null);

  const [npiId, setNpi] = useState();
  const [clinicId, setClinicId] = useState();
  const [designationId, setdesignationId] = useState();
  const [roleId, setRole] = useState();
  const [email, setEmail] = useState();

  const [addressLine1, setAddressLine1] = useState<string | undefined>();
  const [addressLine2, setAddressLine2] = useState<string | undefined>();
  const [cityId, setCity] = useState();
  const [stateId, setState] = useState();
  const [countryId, setCountry] = useState();
  const [zipcodeId, setZipcode] = useState();

  const [prefixId, setprefix] = useState();
  const [firstName, setfirstName] = useState();
  const [lastName, setLastName] = useState();
  const [middleName, setmiddleName] = useState();
  const [suffixId, setsuffix] = useState();

  const [contactNumber1, setcellNumber] = useState();
  const [countryCode1, setcountryCode1] = useState();

  const [contactNumber2, setworkNumber] = useState();
  const [countryCode2, setcountryCode2] = useState();

  const [extID, setExt] = useState();

  const [status, setStatus] = useState();

  const [userProfilePicDetails, setUserProfilePicDetails] = useState<any>();
  const [user_profile, setUser_profile] = useState<any>();

  const [npiNumber, setNpiNumber] = useState<any>();

  // errorVariable  starts here ...

  const [errNpi, setErrNpi] = useState('');

  const [errClinicName, setErrClinicName] = useState('');
  const [errDesignation, setErrDesignation] = useState('');
  const [errRole, setErrRole] = useState('');

  const [errAddressLine1, setErrAddressLine1] = useState('');
  const [errAddressLine2, setErrAddressLine2] = useState('');
  const [errCity, setErrCity] = useState('');
  const [errState, setErrState] = useState('');
  const [errcountry, setErrcountry] = useState('');
  const [errZipcode, setErrZipcode] = useState('');
  const [errLastName, setErrLastName] = useState('');
  const [errFirstName, setErrFirstName] = useState('');
  const [errMiddleName, setErrMiddleName] = useState('');
  const [errEmail, setErrEmail] = useState('');
  const [errCellPhone, setErrCellPhone] = useState('');
  const [errWorkPhone, setErrWorkPhone] = useState('');

  const [errExt, setErrExt] = useState('');

  const [errUserProfile, setErrUserProfile] = useState('');

  const [cancelPopUp, setCancelPopUp] = useState<any>(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getTheUserByID = async (clinicid, userid) => {
      try {
        let getloginInfo: string | null = localStorage.getItem('loginInfo');

        if (getloginInfo !== null) {
          const Token = JSON.parse(getloginInfo).data.accessToken;
          const header = {
            headers: {'x-access-token': Token},
          };

          const getTheUserByID = await axios.get(
            `${APIkey}/api/admin/getUserById/${clinicid}/${userid}`,
            header
          );
          // console.log(getTheUserByID.data.data);

          const data = getTheUserByID?.data?.data;

          if (data) {
            console.log(data);
            setStatus(data.status);
            setNpi(data.npiId);
            setprefix(data.prefixId);
            setLastName(data.lastName);
            setfirstName(data.firstName);
            setmiddleName(data.middleName);
            setsuffix(data.suffixId);

            setClinicId(data.clinicId);
            setdesignationId(data.designationId);
            setRole(data.roleId);

            setEmail(data.email);

            setcountryCode1(data.countryCode1);
            setcellNumber(data.contactNumber1);
            setcountryCode2(data.countryCode2);
            setworkNumber(data.contactNumber2);
            setExt(data.ext);

            setAddressLine1(data.addressLine1);
            setAddressLine2(data.addressLine2);
            setCity(data.cityId);
            setState(data.stateId);
            setCountry(data.countryId);
            setZipcode(data.zipcodeId);
            setUser_profile(data.user_profile);
            setNpiNumber(data.npiId);
            // setUserProfilePicDetails()
          }
        } else {
          console.log('No login info found in localStorage');
        }
      } catch (err) {
        console.log(err);
      }
    };

    getTheUserByID(clinicparamsId, userparamsId);
  }, []);

  const getTheNpi = (e) => {
    setNpi(e.target.value);

    // console.log(e);
  };

  const getprefix = (e) => {
    setprefix(e);
  };

  const getFirstName = (e) => {
    setfirstName(e);
  };
  const getLastName = (e) => {
    setLastName(e);
  };
  const getMiddleName = (e) => {
    setmiddleName(e);
  };

  const getsuffix = (e) => {
    setsuffix(e);
  };

  useEffect(() => {
    const getClinicList = async (clinicId) => {
      try {
        let getloginInfo: string | null = localStorage.getItem('loginInfo');

        if (getloginInfo !== null) {
          const Token = JSON.parse(getloginInfo).data.accessToken;
          const header = {
            headers: {'x-access-token': Token},
          };

          const clinicList = await axios.get(`${APIkey}/api/admin/listClinic`, header);
          // console.log(clinicList.data.data);

          setListclinic(
            clinicList.data.data.map((i) => ({
              label: i.clinicName,
              value: i.clinicName,
              id: i._id,
            }))
          );

          const response = await axios.get(
            `${APIkey}/api/admin/getDesignationList/${clinicId}`,
            header
          );
          setdesignationList(
            response.data.data.map((i) => ({
              label: i.designation,
              value: i.designation,
              id: i._id,
            }))
          );

          const res = await axios.get(`${APIkey}/api/admin/getRoleList`, header);

          setRoleList(
            res.data.data.map((i) => ({
              label: i.role,
              value: i.role,
              id: i._id,
            }))
          );
        } else {
          console.log('No login info found in localStorage');
        }
      } catch (err) {
        console.log(err);
      }
    };
    getClinicList(clinicparamsId);
  }, []);

  const getTheClinicID = async (id) => {
    setClinicId(id);

    try {
      let getloginInfo: string | null = localStorage.getItem('loginInfo');

      if (getloginInfo !== null) {
        const Token = JSON.parse(getloginInfo).data.accessToken;
        const header = {
          headers: {'x-access-token': Token},
        };

        const response = await axios.get(`${APIkey}/api/admin/getDesignationList/${id}`, header);
        setdesignationList(
          response.data.data.map((i) => ({
            label: i.designation,
            value: i.designation,
            id: i._id,
          }))
        );

        const res = await axios.get(`${APIkey}/api/admin/getRoleList`, header);

        setRoleList(
          res.data.data.map((i) => ({
            label: i.role,
            value: i.role,
            id: i._id,
          }))
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getTheDesignation = async (id) => {
    setdesignationId(id);
  };

  const getTheRole = (e) => {
    setRole(e);
  };

  const getTheEmail = (e) => {
    setEmail(e.target.value);
  };

  const getAddress1 = (e) => {
    setAddressLine1(e);
  };
  const getAddress2 = (e) => {
    setAddressLine2(e);
  };

  const getCity = (e) => {
    setCity(e);
  };
  const getState = (e) => {
    setState(e);
  };

  const getCountry = (e) => {
    setCountry(e);
  };

  const getZipcode = (e) => {
    setZipcode(e);
  };

  const getCellNumber = (e) => {
    setcellNumber(e.getTheNumber);
    setcountryCode1(e.gettheCode);
  };

  const getWorkNumber = (e) => {
    setworkNumber(e.getTheNumber);
    setcountryCode2(e.gettheCode);
  };

  const getExt = (e) => {
    setExt(e);
  };

  const getThestatus = (e) => {
    setStatus(e);
  };

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];

    setUserProfilePicDetails(file);
    if (file) {
      const reader = new FileReader();

      reader?.readAsDataURL(file);
      reader.onloadend = () => {
        setUser_profile(reader?.result);
      };
    }
  };

  const updateTheUser = async (data, clinicID, userID, redirectManage) => {
    console.log(data);

    try {
      let getloginInfo: string | null = localStorage.getItem('loginInfo');

      if (getloginInfo !== null) {
        const Token = JSON.parse(getloginInfo).data.accessToken;
        const header = {
          headers: {'x-access-token': Token},
        };

        const response = await axios.post(
          `${APIkey}/api/admin/updateUserById/${clinicID}/${userID}`,
          data,
          header
        );
        console.log('this is response ', response);

        if (response) {
          if (redirectManage) {
            navigate(
              `/user/managePermission/${response?.data?.data?.clinicId}/${response?.data?.data?._id}`
            );
            toast.success('User updated successfully!', {
              position: 'top-right',
            });
          } else {
            navigate('/user');
            toast.success('User updated successfully!', {
              position: 'top-right',
            });
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const statusList = [
    {
      label: 'Active',
      value: 'Active',
      id: 1,
    },
    {
      label: 'Inactive',
      value: 'Inactive',
      id: 0,
    },
  ];

  const SaveData = (cId, UiD, redirectManage) => {
    let listOfData = {
      npiId: npiNumber,
      clinicId,
      prefixId,
      firstName,
      lastName,
      middleName,
      suffixId,
      designationId,
      addressLine1,
      addressLine2,
      cityId,
      stateId,
      countryId,
      zipcodeId,
      contactNumber1,
      contactNumber2,
      countryCode1,
      countryCode2,
      ext: extID,
      roleId,
      email,
      status,
      user_profile,
      userProfilePicDetails,
    };

    let err = UserValidation(listOfData);

    if (Object.keys(err).length === 0) {
      updateTheUser(listOfData, cId, UiD, redirectManage);
      // console.log(" form submitted Successfully ...");

      setErrNpi(err.npiId);
      setErrClinicName(err.clinicId);
      setErrDesignation(err.designationId);
      setErrRole(err.roleId);
      setErrAddressLine1(err.addressLine1);
      setErrAddressLine2(err.addressLine2);
      setErrZipcode(err.zipcodeId);

      setErrLastName(err.lastName);
      setErrFirstName(err.firstName);
      setErrMiddleName(err.middleName);
      setErrEmail(err.email);
      setErrCellPhone(err.contactNumber1);
      setErrWorkPhone(err.contactNumber2);
      setErrExt(err.extID);

      setErrcountry(err.countryId);
      setErrState(err.stateId);
      setErrCity(err.cityId);
      setErrUserProfile(err.profilePicErr);
    } else {
      setErrNpi(err.npiId);
      setErrClinicName(err.clinicId);
      setErrDesignation(err.designationId);
      setErrRole(err.roleId);
      setErrAddressLine1(err.addressLine1);
      setErrAddressLine2(err.addressLine2);
      setErrZipcode(err.zipcodeId);
      setErrLastName(err.lastName);
      setErrFirstName(err.firstName);
      setErrMiddleName(err.middleName);
      setErrEmail(err.email);
      setErrCellPhone(err.contactNumber1);
      setErrWorkPhone(err.contactNumber2);
      setErrExt(err.extID);
      setErrcountry(err.countryId);
      setErrState(err.stateId);
      setErrCity(err.cityId);
      setErrUserProfile(err.profilePicErr);
    }
  };

  const handleManagePermission = (cId, UiD) => {
    let redirectManage = true;
    if (redirectManage) {
      SaveData(cId, UiD, redirectManage);
    }
  };

  const handleWithOutRedirect = (cId, UiD) => {
    let redirectManage = false;
    SaveData(cId, UiD, redirectManage);
  };

  const closeCancel = () => {
    setCancelPopUp(false);
  };

  return (
    <>
      <div className='px-4'>
        <div className='PageHeader'>
        <TitleComponent type='title'>Edit User</TitleComponent>
        </div>
        <div className='card mt-3  p-3'>
          <div>
            <div className='PageHeader'>
              <TitleComponent type='sectionTitle'>Contact Person:</TitleComponent>
            </div>
            <div className='row mt-2 '>
              {/* <div className='col-md-6  col-lg-4 mb-4'>
                <label htmlFor='' className='formLabelTitle required'>
                  {inputText.NpiID.label}
                </label>
                <div className='d-flex gap-2 align-items-center'>
                  <div className='w-75'>
                    <input
                      onChange={getTheNpi}
                      value={npiId}
                      type='text'
                      className='form-control SearchInputbox'
                    ></input>
                  </div>
                  <button type='button' className='primarybtnBig'>
                    <img src={searchiconwhite} alt='search icon' />
                    Search
                  </button>
                </div>

                {errNpi && <span className='formLabelTitle errMsg'>{errNpi}</span>}
              </div> */}
              <div className='col-md-6 col-lg-4 mb-4'>
              <TitleComponent type='label' className='px-1'>
                  Status:
                </TitleComponent>
                <SearchableDropdown
                  placeHolder={inputText.ClinicName.placeholder}
                  gettingData={getThestatus}
                  InitialValue={status}
                  options={statusList}
                />
              </div>
            </div>

            <div className='row mt-2 mb-2'>
              <div className='col-md-6  col-lg-4  '>
                <div className={user_profile ? 'd-flex justify-content-between  gap-2' : 'w-100'}>
                  <div className={user_profile ? 'w-75' : 'w-100'}>
                  <TitleComponent type='label' className='px-1'>
                      Profile Picture:
                    </TitleComponent>
                    <label
                      className={
                        errUserProfile
                          ? 'uploadErrbtn  d-flex align-items-center justify-content-center formLabelTitle'
                          : ' uploadbtn  d-flex align-items-center justify-content-center formLabelTitle'
                      }
                    >
                      {userProfilePicDetails ? (
                        <div className=' d-flex align-items-center justify-content-center gap-2'>
                          <label
                            htmlFor='upload-photo1'
                            style={{
                              color: '#0027FF',
                              textDecoration: 'underLine',
                              cursor: 'pointer',
                            }}
                          >
                            browse
                          </label>
                          {userProfilePicDetails?.name}
                        </div>
                      ) : (
                        <div className=''>
                          <i className='fa fa-cloud-upload mx-2' aria-hidden='true'></i>
                          Upload Your Clinic Logo &nbsp;
                          <label
                            htmlFor='upload-photo1'
                            style={{
                              color: '#0027FF',
                              textDecoration: 'underLine',
                              cursor: 'pointer',
                            }}
                          >
                            browse
                          </label>
                        </div>
                      )}
                      <input
                        type='file'
                        onChange={handleProfilePicChange}
                        id='upload-photo1'
                        accept='image/*'
                        style={{display: 'none'}}
                      />
                    </label>
                  </div>
                  {user_profile && (
                    <div className='w-25 form-control p-2'>
                      <ModalImage
                        small={user_profile}
                        large={user_profile}
                        alt=''
                        hideDownload={true} // Optionally hide download button
                        hideZoom={true} // Optionally hide zoom button
                      />
                    </div>
                  )}
                </div>

                {errUserProfile && (
                  <span className='formLabelTitle errMsg'>
                    <i className='fa-solid fa-triangle-exclamation'></i>
                    &nbsp;
                    {errUserProfile}
                  </span>
                )}
              </div>

              <div className='col-md-6 col-lg-4 '>
              <TitleComponent type='label' className='px-1 required'>
                  NPI:
                </TitleComponent>
                <input
                  type='text'
                  value={npiNumber}
                  className='form-control SearchInputbox'
                  maxLength={10}
                  onChange={(e) => setNpiNumber(e.target.value)}
                  readOnly={false}
                />
                {errNpi && (
                  <span className='formLabelTitle errMsg'>
                    <i className='fa-solid fa-triangle-exclamation'></i>
                    &nbsp;
                    {/* {inputText.NpiID.ErrMsg} */}
                    {errNpi}
                  </span>
                )}
              </div>
            </div>

            <div className='row'>
              <NameCompo
                colno={'4'}
                prefixValue={prefixId}
                lastNameValue={lastName}
                firstNameValue={firstName}
                middleNameValue={middleName}
                suffixValue={suffixId}
                getPrefixData={getprefix}
                getSuffixData={getsuffix}
                getfName={getFirstName}
                getlName={getLastName}
                getmName={getMiddleName}
                lastNameErr={errLastName}
                firstNameErr={errFirstName}
                middleNameErr={errMiddleName}
                isUserFixes={true}
              />
            </div>

            <div className='row mt-2'>
              <div className='col-md-6  col-lg-4 mb-4'>
              <TitleComponent type='label' className='px-1 required'>
                  {inputText.ClinicName.label}
                </TitleComponent>
                <SearchableDropdownForGroupId
                  placeHolder={inputText.ClinicName.placeholder}
                  gettingData={getTheClinicID}
                  InitialValue={clinicId}
                  options={listclinic}
                />
                {errClinicName && (
                  <span className='formLabelTitle errMsg'>
                    <i className='fa-solid fa-triangle-exclamation'></i>
                    &nbsp;
                    {errClinicName}
                  </span>
                )}
              </div>
              <div className='col-md-6  col-lg-4 mb-4'>
              <TitleComponent type='label' className='px-1 required'>
                  {inputText.designation.label}
                </TitleComponent>
                <SearchableDropdown
                  InitialValue={designationId}
                  placeHolder={inputText.designation.placeholder}
                  gettingData={getTheDesignation}
                  options={designationList}
                />
                {errDesignation && (
                  <span className='formLabelTitle errMsg'>
                    <i className='fa-solid fa-triangle-exclamation'></i>
                    &nbsp;
                    {errDesignation}
                  </span>
                )}
              </div>
              <div className='col-md-6  col-lg-4 mb-4'>
              <TitleComponent type='label' className='px-1 required'>
                  {inputText.role.label}
                </TitleComponent>
                <SearchableDropdown
                  InitialValue={roleId}
                  placeHolder={inputText.designation.placeholder}
                  gettingData={getTheRole}
                  options={roleList}
                />
                {errRole && (
                  <span className='formLabelTitle errMsg'>
                    <i className='fa-solid fa-triangle-exclamation'></i>
                    &nbsp;
                    {errRole}
                  </span>
                )}
              </div>
            </div>
            <div className='row'>
              <ContactComponent
                col={4}
                adminfield={1}
                emailValue={email}
                contactno1Value={`${countryCode1}${contactNumber1}`}
                contactno2Value={`${countryCode2}${contactNumber2}`}
                contactno3Value={''}
                extValue={extID}
                getTheEmail={getTheEmail}
                getTheCellNumber={getCellNumber}
                getTheWorkNumber={getWorkNumber}
                getTheExt={getExt}
                emailErr={errEmail}
                workNoErr={errWorkPhone}
                cellNoErr={errCellPhone}
                extErr={errExt}
              />
            </div>

            <div className='row '>
              <AddressComponent
                col={'4'}
                address1value={addressLine1}
                address2value={addressLine2}
                zipcodevalue={zipcodeId}
                countryValue={countryId}
                stateValue={stateId}
                cityValue={cityId}
                Address1get={getAddress1}
                Address2get={getAddress2}
                Cityget={getCity}
                StateGet={getState}
                countryget={getCountry}
                zipcodeget={getZipcode}
                address1Err={errAddressLine1}
                cityErr={errCity}
                stateErr={errState}
                countryErr={errcountry}
                zipErr={errZipcode}
                address2Err={errAddressLine2}
              />
            </div>

            <div className='d-flex mt-3 justify-content-end'>
              <div className='d-flex gap-2'>
                <div>
                  <CommonbigButton
                    onClick={() => handleWithOutRedirect(clinicparamsId, userparamsId)}
                  >
                    Save
                  </CommonbigButton>
                </div>
                <div>
                  <CommonbigButton
                    onClick={() => handleManagePermission(clinicparamsId, userparamsId)}
                  >
                    Save And Manage Permission
                  </CommonbigButton>
                </div>
                <div>
                <CommonbigButton background= {'#D7D7D7'} color={'#818181'} onClick={() => setCancelPopUp(true)} >
                    Cancel
                  </CommonbigButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {cancelPopUp && (
        <CancelConfirmation openCancel={cancelPopUp} closeCancel={closeCancel} Routes='/user' />
      )}
    </>
  );
}

export default UpdateUser;
