import React from 'react'
import CryptoConverter from './CryptoConverter'
import moment from 'moment'
import PaginationCompo from './PaginationCompo'
import TitleComponent from './TitleComponent'

interface Column {
  key: string[]
  header: string
  isEncrypted?: boolean
  isMasked?: boolean
  isDate?: boolean
  isPhone?: boolean
  isAction?: boolean
  isSearched?: boolean
  ActionComponent?: React.FC<{id: string}>
  headerColor?: string
  issetPaginationCompo?: boolean
  isPagelength?: boolean
  
}

interface CommonTableProps {
  data: any[]
  isSearched?: boolean
  totalCount: number
  perPage: number
  setPerPage: (perPage: number) => void
  currentPage: number
  setCurrentPage: (currentPage: number) => void
  columns: Column[]
  searchQuery?: string
  setQuery: (query: string) => void
  onRowClick?: (id: string) => void
  maskedInputClassName?: string
  issetPaginationCompo?: boolean
  headerColor?: string
  isPagelength: boolean
}

const CommonTable: React.FC<CommonTableProps> = ({
  data,
  totalCount,
  perPage,
  setPerPage,
  currentPage,
  setCurrentPage,
  columns,
  searchQuery,
  setQuery,
  onRowClick,
  headerColor = 'black',
  issetPaginationCompo = true,
  isPagelength , 
  isSearched 
}) => {
  console.log(data)

  const dobfun = (dob: string) => moment(dob).format('DD/MM/yyyy')

  const handlePageChange = (pageNumber: number): void => {
    setCurrentPage(pageNumber)
  }

  return (
    <div>
     

      <div className=' pb-2 d-flex justify-content-between'>
      { isPagelength && (
        <div className='input-group' style={{width: '130px'}}>
          <span className='input-group-text' style={{height: '35px', width: '55px'}}>
            Show
          </span>
          <select
            className='form-select'
            onChange={(e) => setPerPage(parseInt(e.target.value))}
            defaultValue={perPage}
            style={{height: '35px', width: '50px'}}
          >
            <option value='10'>10</option>
            <option value='20'>20</option>
            <option value='30'>30</option>
            <option value='40'>40</option>
            <option value='50'>50</option>
          </select>
        </div>
      )
    }
    
    {
      isSearched && (


        <div>
          <input
            type='text'
            className='form-control  SearchInputbox'
            placeholder='Search'
            value={searchQuery}
            onChange={(e) => setQuery(e.target.value)}
            style={{}}
          />
        </div>
              )
    }
      </div>
  
      <div className='table-container mt-2 table-responsive ' style={{maxHeight: '480px'}}>
        <table className='table table-hover text-center fullCurve w-100'>
          <thead className='table-dark tableHead headerRow'>
            <tr>
              {columns?.map((col) => (
                <th style={{backgroundColor: headerColor}} key={col.key[0]} className='text-center'>
                  {/* <span></span> */}
                  <TitleComponent type='tableCellHeader'>{col.header}</TitleComponent>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((row) => (
              <tr key={row._id} className='tableBody mx-3'>
                {columns?.map((col) => (
                  <td key={col.key[0]}>
                    <TitleComponent type='tableCell'>
                    {col.key?.length > 1 ? (
                      row[col.key[0]] && (
                        <span
                          style={col.key[0] === 'mrn' ? {color: '#2972a5', cursor: 'pointer'} : {}}
                          // onClick={
                          //   col.key[0] === 'mrn' && onRowClick
                          //     ? () => onRowClick(row._id)
                          //     : undefined
                          // }
                        >
                          {row[col.key[0]][col.key[1]]}
                        </span>
                      )
                    ) : col.isEncrypted ? (
                      <span
                        style={col.key[0] === 'mrn' ? {color: '#2972a5', cursor: 'pointer'} : {}}
                        // onClick={
                        //   col.key[0] === 'mrn' && onRowClick ? () => onRowClick(row._id) : undefined
                        // }
                      >
                        {CryptoConverter(row[col.key[0]])}
                      </span>
                    ) : col.isDate ? (
                      <span
                        style={col.key[0] === 'mrn' ? {color: '#2972a5', cursor: 'pointer'} : {}}
                        // onClick={
                        //   col.key[0] === 'mrn' && onRowClick ? () => onRowClick(row._id) : undefined
                        // }
                      >
                        <TitleComponent type='tableCell'>{dobfun(row[col.key[0]])}</TitleComponent>

                      </span>
                    ) : col.isAction && col.ActionComponent ? (
                      <col.ActionComponent id={row._id} />
                    ) : (
                      <span
                        style={col.key[0] === 'mrn' ? {color: '#2972a5'} : {}}
                        // onClick={
                        //   col.key[0] === 'mrn' && onRowClick ? () => onRowClick(row._id) : undefined
                        // }
                      >
                      <TitleComponent type='tableCell'>{row[col.key[0]]}</TitleComponent>

                      </span>
                    )}
                </TitleComponent>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* {data && totalCount > perPage ? ( */}
      {issetPaginationCompo && (
        <div className='container-fluid d-flex justify-content-between'>
          <div className='d-flex align-item-center py-2 '>
            <span>{totalCount ? `Total ${totalCount} entries` : `No records found`}</span>
          </div>
          <PaginationCompo
            totalData={totalCount}
            perPage={perPage}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
          />
        </div>
      )}
      {/* // ) : (
      //   totalCount > perPage && (
      //     <PaginationCompo
      //       totalRecords={totalCount}
      //       recordsPerPage={perPage}
      //       currentPage={currentPage}
      //       onPageChange={handlePageChange}
      //     />
      //   )
      // )} */}
    </div>
  )
}

export default CommonTable
