import React, {useEffect, useState} from 'react';
import {MDBDataTable} from 'mdbreact';
import {useNavigate, Link} from 'react-router-dom';
import SerchableDropdown from '../ReausableComponent/SearchableDropdown';
import axios, {AxiosError} from 'axios';
import searchiconwhite from '../../../ImgesLogos/searchiconwhite.svg';
import moment from 'moment';
import DeleteModel from '../../../ReUsableComponent/DeleteModel';
import {ToastContainer, toast} from 'react-toastify';
import SearchableDropdownForGroupId from '../Clinics/SearchableDropdownForGroupId';
import CommonTable from '../../../ReUsableComponent/CommonTable';
import deletcs from '../../../ImgesLogos/deletcs.svg';
import editcs from '../../../ImgesLogos/editcs.svg';
// import ViewDetails from '../ReausableComponent/ViewDetail'
import SearchableDropdown from '../ReausableComponent/SearchableDropdown';
import tablecolor from '../../../ReUsableComponent/ReyClinicalinputs.json';
import ViewUserDetail from '../User/ViewUserDetail';
import CommonButton, {CommonbigButton} from '../ReausableComponent/CommonButton';
import TitleComponent from '../../../ReUsableComponent/TitleComponent';

function User() {
  const APIkey = process.env.REACT_APP_SERVERAPI;

  const navigate = useNavigate();

  const [listData, setListData] = useState<any[]>([]);
  const [searchUserList, setSearchUserList] = useState<any>(null);

  const [listclinic, setListclinic] = useState<any>(null);
  const [designationList, setdesignationList] = useState<any>(null);

  const [npiId, setnpiId] = useState<any>(null);
  const [clinicName, setClinicName] = useState<string | null>(null);
  const [userName, setUserName] = useState<string | null>(null);

  const [clinicId, setClinicId] = useState<any>(null);
  const [designationId, setDesignationId] = useState<any>(null);
  const [statusCheck, setStatusCheck] = useState<any>(null);
  const [activationDate, setActivationDate] = useState<any>(null);

  const [totalCount, setTotalCount] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState('');
  const [downSearch, setDownSearch] = useState(false);

  const [openDelete, setopenDelete] = useState(false);
  const [deleteID, setdeleteID] = useState();
  const [showViewDetails, setShowViewDetails] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [errNpi, setErrNpi] = useState('');

  const deletedEntity: any = deleteID && searchUserList?.filter((i) => i._id === deleteID);

  // console.log("this is list data ..." , listData);

  // console.log(deletedEntity);

  // delete Functionality starts here ...
  const userDelete = (id) => {
    setopenDelete(true);
    setdeleteID(id);
    console.log('User ID for delete ... ', id);
  };

  const handleClosedelete = () => {
    setopenDelete(false);
  };

  const deleteFunction = async (deleteId) => {
    try {
      let getloginInfo: string | null = localStorage.getItem('loginInfo');

      if (getloginInfo !== null) {
        const Token = JSON.parse(getloginInfo).data.accessToken;
        const header = {
          headers: {'x-access-token': Token},
        };

        const deleteUser = await axios.delete(
          `${APIkey}/api/admin/deleteUserById/${clinicId}/${deleteId}`,
          header
        );
        console.log(deleteUser);

        setopenDelete(false);

        const updatedList = searchUserList?.filter((item) => item._id !== deleteId);

        setSearchUserList(updatedList);
      } else {
        console.log('No login info found in localStorage');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const editFunction = (clinicId, editId) => {
    // return;
    navigate(`/user/editUser/${clinicId}/${editId}`);
  };

  function Action(clinicid, userId, item) {
    return (
      <div className='d-flex  gap-2 '>
        <div>
          <button className='blueButtonSmall' onClick={() => handleShowDetails(item)}>
            <i className='fa-solid fa-eye'></i>
          </button>
        </div>
        <div>
          <button onClick={() => editFunction(clinicid, userId)} className='blueButtonSmall'>
            <i className='fa-solid fa-pencil'></i>
          </button>
        </div>
        <div>
          <button onClick={() => userDelete(userId)} className='blueButtonSmall'>
            <i className='fa-solid fa-trash'></i>
          </button>
        </div>
      </div>
    );
  }

  useEffect(() => {
    const getClinicList = async () => {
      try {
        let getloginInfo: string | null = localStorage.getItem('loginInfo');

        if (getloginInfo !== null) {
          const Token = JSON.parse(getloginInfo).data.accessToken;
          const header = {
            headers: {'x-access-token': Token},
          };

          const clinicList = await axios.get(`${APIkey}/api/admin/listClinic`, header);
          console.log(clinicList.data.data);

          setListclinic(
            clinicList?.data?.data?.map((i) => {
              return {
                label: i.clinicName,
                value: i.clinicName,
                id: i._id,
              };
            })
          );
        } else {
          console.log('No login info found in localStorage');
        }
      } catch (err) {
        console.log(err);
      }
    };
    getClinicList();
  }, []);

  const handleShowDetails = (item) => {
    setSelectedItem(item);
    setShowViewDetails(true);
  };

  const handleCloseDetails = () => {
    setShowViewDetails(false);
    setSelectedItem(null);
  };

  const getTheClinicID = async (id) => {
    setClinicId(id);

    try {
      let getloginInfo: string | null = localStorage.getItem('loginInfo');

      if (getloginInfo !== null) {
        const Token = JSON.parse(getloginInfo).data.accessToken;
        const header = {
          headers: {'x-access-token': Token},
        };

        // const response = await axios.get(`${APIkey}/api/admin/listUsersByClinicId/${id}`, header)
        // setListData(response.data.data)

        const res = await axios.get(`${APIkey}/api/admin/getClinicById/${id}`, header);
        // setClinicName(res.data.data.clinicName)
        //   //  setClinicId(res.data.data._id)

        const getTheDesignation = await axios.get(
          `${APIkey}/api/admin/getDesignationList/${id}`,
          header
        );
        setdesignationList(
          getTheDesignation?.data?.data?.map((i) => ({
            label: i?.designation,
            value: i?.designation,
            id: i?._id,
          }))
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getTheDesignationId = (e) => {
    setDesignationId(e);
  };

  const getTheStatus = (e) => {
    setStatusCheck(e);
  };

  console.log('searchlistData', searchUserList);

  const rows = searchUserList
    ? searchUserList?.map((item, index) => {
        console.log('in ...', item);

        const activeationDate = (createDate) => {
          let date = moment(createDate).format('DD-MM-yyyy');
          return date;
        };

        const status = (status) => {
          if (status ? 1 : 2) {
            let active = 'Active';
            return active;
          } else {
            let inactive = 'Inactive';
            return inactive;
          }
        };

        return {
          id: item._id,
          SerialNo: index + 1,
          npiId: item.npiId,
          Userid: item.userCode,
          FullName: `${item.firstName} ${
            item.middleName !== null && item.middleName !== undefined ? item.middleName : ''
          } ${item.lastName} `,
          username: item.username,
          ClinicName: item?.cData?.clinicName,
          Designation: item?.designation?.designation,
          Role: item?.role?.role,
          Activationdate: activeationDate(item.createdDate),
          Status: item.status === 1 ? 'Active' : 'Inactive',
          Action: Action(item.cData._id, item._id, item),
        };
      })
    : [];

  const columns = [
    {key: ['SerialNo'], header: 'Serial No'},
    {key: ['npiId'], header: 'NPI'},
    {key: ['Userid'], header: 'User Id'},
    {key: ['username'], header: 'User Name'},
    {key: ['FullName'], header: 'Full Name'},
    {key: ['ClinicName'], header: 'Clinic Name'},
    {key: ['Designation'], header: 'Designation'},
    {key: ['Status'], header: 'Status'},
    {key: ['Role'], header: 'Role'},
    {key: ['Activationdate'], header: 'Activation Date'},
    {key: ['Action'], header: 'Action(s)'},
  ];

  const statusList = [
    {
      label: 'Active',
      value: 'Active',
      id: 1,
    },
    {
      label: 'Inactive',
      value: 'Inactive',
      id: '0',
    },
    // ,
    // {
    //     label: 'Deleted',
    //     value: 'Deleted',
    //     id: 2
    // },
  ];

  const submitSearch = async (data) => {
    try {
      let getloginInfo: string | null = localStorage.getItem('loginInfo');

      if (getloginInfo !== null) {
        const Token = JSON.parse(getloginInfo)?.data?.accessToken;
        const header = {
          headers: {'x-access-token': Token},
        };

        const response = await axios.post(`${APIkey}/api/admin/listUsersSearch`, data, header);

        // console.log("harshang");

        console.log('clinicdata', response.data);
        setSearchUserList(
          response?.data?.data.users.map((data, index) => ({
            ...data,
            cData: response?.data?.data?.clinicData,
            SerialNo: index + 1,
          }))
        );
      } else {
        console.log('No access token found in localStorage');
      }
    } catch (error) {
      const axiosError: any = error as AxiosError; // Explicitly tell TypeScript this is an Axios error
      const errorMessage =
        axiosError?.response?.data?.message || 'An error occurred while searching';
      console.error('Error message:', errorMessage);
      toast.error(errorMessage, {
        type: 'error',
        position: 'top-right',
      });
      setSearchUserList(null);
      console.error(error);
    }
  };

  const handleSearch = () => {
    if (downSearch === false) {
      setDownSearch(true);
    } else {
      let data = {
        clinicId,
        npiId: npiId,
        userName,
        designationId,
        activationDate,
        statusCheck,
      };

      console.log(data);

      submitSearch(data);
    }
  };

  const handelCancel = () => {
    setDownSearch(false);
    // setSearchUserList([])
    // setnpiId(null)
    // setUseName(null)
    // setClinicId(null)
    // setDesignationId(null)
    // setActivationDate(null)
    // setStatusCheck(null)
  };

  const handelReset = () => {
    setSearchUserList([]);
    setnpiId('');
    setUserName(null);
    setClinicName(null);
    setClinicId('');
    setDesignationId(null);
    setActivationDate(null);
    setStatusCheck('');
  };

  return (
    <>
      <div className='px-5 fw-bold'>
        <div className='PageHeader '>
        <TitleComponent type='title' className='px-1 '>User</TitleComponent>
          <div>
            <Link to='/user/AddNew'>
              <CommonbigButton type='button'>Add User</CommonbigButton>
            </Link>
          </div>
        </div>
        <div className='card mt-3 p-3'>
          {downSearch && (
            <>
              <div className='  d-flex justify-content-end'>
                <button className='grayButton' onClick={handelCancel}>
                  <i className='fa fa-close'></i>
                </button>
              </div>
              <div className='row'>
                <div className='col-md-6 col-lg-3'>
                  {/* <label htmlFor='' className='formLabelTitle'>
                    NPI:
                  </label>
                  <input
                    type='number'
                    value={npiId || ''}
                    onChange={(e) => setnpiId(e.target.value)}
                    className='form-control SearchInputbox'
                  /> */}
                  <TitleComponent type='label' className='px-1 '>
                    NPI:
                  </TitleComponent>
                  <input
                    type='text'
                    value={npiId}
                    className='form-control SearchInputbox'
                    maxLength={10}
                    onChange={(e) => setnpiId(e.target.value)}
                  />
                  {errNpi && (
                    <span className='formLabelTitle errMsg'>
                      <i className='fa-solid fa-triangle-exclamation'></i>
                      &nbsp;
                      {/* {inputText.NpiID.ErrMsg} */}
                      {errNpi}
                    </span>
                  )}
                </div>
                <div className='col-md-6 col-lg-3'>
                <TitleComponent type='label' className='px-1 '>
                    User Name:
                  </TitleComponent>
                  <input
                    type='text'
                    value={userName || ''}
                    onChange={(e) => setUserName(e.target.value)}
                    className='form-control SearchInputbox'
                  />
                </div>
                <div className='col-md-6 col-lg-3'>
                <TitleComponent type='label' className='px-1 '>
                    Clinic Name:
                  </TitleComponent>
                  <SearchableDropdown
                    InitialValue={clinicId}
                    gettingData={getTheClinicID}
                    options={listclinic}
                  />
                </div>

                <div className='col-md-6 col-lg-3'>
                <TitleComponent type='label' className='px-1 '>
                    Designation:
                  </TitleComponent>
                  <SerchableDropdown
                    InitialValue={designationId}
                    gettingData={getTheDesignationId}
                    options={designationList}
                  />
                </div>
              </div>
            </>
          )}
          {downSearch && (
            <div className='row mt-2'>
              <div className='col-md-6 col-lg-3 '>
              <TitleComponent type='label' className='px-1 '>
                  Activation Date:
                </TitleComponent>
                <input
                  type='date'
                  value={activationDate || ''}
                  onChange={(e) => setActivationDate(e.target.value)}
                  className='form-control SearchInputbox'
                />
              </div>
              <div className='col-md-6 col-lg-3 '>
              <TitleComponent type='label' className='px-1 '>
                  Status:
                </TitleComponent>
                <SerchableDropdown
                  InitialValue={statusCheck}
                  gettingData={getTheStatus}
                  options={statusList}
                />
              </div>

              <div
                className={
                  'col-md-6 col-lg-3 offset-lg-3  d-flex align-items-end justify-content-end  gap-2 '
                }
              >
                <CommonbigButton background= {'#D7D7D7'} color={'#818181'} onClick={handelReset}>
                  Reset
                </CommonbigButton>
                <CommonbigButton onClick={() => handleSearch()}>
                  {/* <img src={searchiconwhite} alt='search icon' /> */}
                  Search
                </CommonbigButton>
              </div>
            </div>
          )}
          {!downSearch && (
            <div className={'col-md-2 offset-md-10 d-flex justify-content-end '}>
              <CommonButton onClick={() => handleSearch()}>
                <img src={searchiconwhite} alt='search icon' />
                Search
              </CommonButton>
            </div>
          )}
        </div>

        <div className='card mt-3 p-3'>
          <div className='PageHeader'>
            <div className='sectionTitle'>Search Result</div>
          </div>

          <div className='clinical-time-container'>
            <CommonTable
              data={rows}
              totalCount={rows.length}
              perPage={perPage}
              setPerPage={setPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              columns={columns}
              setQuery={setQuery}
              headerColor={tablecolor.primaryTable.color}
              issetPaginationCompo={true}
              isPagelength={true}
              isSearched={true}
            />
          </div>

          {/* <MDBDataTable
            striped
            bordered
            small
            data={data}
            className="custom-table"
          /> */}
        </div>
      </div>
      <ToastContainer />

      <ViewUserDetail show={showViewDetails} handleClose={handleCloseDetails} item={selectedItem} />

      {openDelete && (
        <DeleteModel
          openDelete={openDelete}
          closedelete={handleClosedelete}
          deleteID={deleteID}
          deletedEntity={`${deletedEntity[0]?.firstName} ${deletedEntity[0]?.lastName}`}
          deleteFunction={deleteFunction}
        />
      )}
    </>
  );
}

export default User;

// import React, { useEffect, useState } from 'react'
// import { MDBDataTable } from 'mdbreact'
// import { useNavigate, Link } from 'react-router-dom';
// import SerchableDropdown from '../ReausableComponent/SearchableDropdown';
// import axios from 'axios';

// import moment from 'moment'
// import DeleteModel from '../../../ReUsableComponent/DeleteModel';
// import { ToastContainer, toast } from 'react-toastify'

// function User() {

//   const APIkey = process.env.REACT_APP_SERVERAPI;

//   const navigate =useNavigate()

//   const [listData, setListData] = useState<any[]>([]);
//   const [searchUserList, setSearchUserList] = useState<any>(null);

//   const [listclinic, setListclinic] = useState<any>(null);
//   const [designationList, setdesignationList] = useState<any>(null);

//   const [npiId, setnpiId] = useState<any>(null)
//   const [clinicName, setClinicName] = useState<string | null>(null)
//   const [userName, setUseName] = useState<string | null>(null)

//   const [clinicId, setClinicId] = useState<any>(null);
//   const [designationId, setDesignationId] = useState<any>(null);
//   const [statusCheck, setStatusCheck] = useState<any>(null);
//   const [activationDate, setActivationDate] = useState<any>(null);

//   const [downSearch, setDownSearch] = useState(false)

//   const [openDelete, setopenDelete] = useState(false)
//   const [deleteID, setdeleteID] = useState()

//   const deletedEntity : any = deleteID && searchUserList?.filter(i => i._id === deleteID)

//   // console.log("this is list data ..." , listData);

//   // console.log(deletedEntity);

//   // delete Functionality starts here ...
//   const userDelete = (id) => {
//     setopenDelete(true)
//     setdeleteID(id)
//     console.log("User ID for delete ... ", id);

//   }

//   const handleClosedelete = () => {
//     setopenDelete(false)
//   }

//   const deleteFunction = async (deleteId) => {

//     try {
//       let getloginInfo: string | null = localStorage.getItem('loginInfo')

//       if (getloginInfo !== null) {

//         const Token = JSON.parse(getloginInfo).data.accessToken;
//         const header = {
//           headers: { 'x-access-token': Token }
//         }

//         const deleteUser = await axios.delete(`${APIkey}/api/admin/deleteUserById/${clinicId}/${deleteId}`, header);
//         console.log(deleteUser);

//         setopenDelete(false)

//         const updatedList = searchUserList?.filter(item => item._id !== deleteId)

//         setSearchUserList(updatedList)

//       }
//       else {
//         console.log("No login info found in localStorage");
//       }
//     }
//     catch (err) {
//       console.log(err);
//     }

//   }

//   const editFunction = ( clinicId , editId)=> {
//     navigate(`/user/editUser/${clinicId}/${editId}`)
//   }

//   function Action(userId) {

//     return (
//       <div className='d-flex  gap-2 ' >
//         <div>

//           <button className='blueButtonSmall' >
//             <i className="fa-solid fa-eye" ></i>
//           </button>
//         </div>
//         <div>

//           <button  onClick={() => editFunction( clinicId , userId)} className='blueButtonSmall'>
//             <i className="fa-solid fa-pencil" ></i>
//           </button>
//         </div>
//         <div>

//           <button onClick={() => userDelete(userId)} className='blueButtonSmall'>
//             <i className="fa-solid fa-trash" ></i>
//           </button>
//         </div>

//       </div>
//     );
//   }

//   useEffect(() => {

//     const getClinicList = async () => {

//       try {
//         let getloginInfo: string | null = localStorage.getItem('loginInfo')

//         if (getloginInfo !== null) {

//           const Token = JSON.parse(getloginInfo).data.accessToken;
//           const header = {
//             headers: { 'x-access-token': Token }
//           }

//           const clinicList = await axios.get(`${APIkey}/api/admin/listClinic`, header);
//           // console.log(clinicList.data.data);

//           setListclinic(clinicList?.data?.data?.map(i => {

//             return {
//               label: i.clinicName,
//               value: i.clinicName,
//               id : i._id
//             }
//           }
//           ))

//         }
//         else {
//           console.log("No login info found in localStorage");
//         }
//       }
//       catch (err) {
//         console.log(err);

//       }

//     }
//     getClinicList();

//   }, [])

//   const getTheClinicID = async (id) => {

//     setClinicId(id);

//     try {
//       let getloginInfo: string | null = localStorage.getItem('loginInfo')

//       if (getloginInfo !== null) {

//         const Token = JSON.parse(getloginInfo).data.accessToken;
//         const header = {
//           headers: { 'x-access-token': Token }
//         }

//         // const response = await axios.get(`${APIkey}/api/admin/listUsersByClinicId/${id}`, header)
//         // setListData(response.data.data)

//         const res = await axios.get(`${APIkey}/api/admin/getClinicById/${id}`, header)
//         setClinicName(res.data.data.clinicName)
//         //   //  setClinicId(res.data.data._id)

//           const getTheDesignation = await axios.get(`${APIkey}/api/admin/getDesignationList`, header)
//           setdesignationList(getTheDesignation.data.data.map((i) => (
//               {
//                   label: i.designation,
//                   value: i.designation,
//                   id: i._id
//               }
//           )))

//       }
//     }
//     catch (err) {
//       console.log(err);
//     }

//   }

//   const getTheDesignationId = (e)=> {
//     setDesignationId(e)
//   }

//   const getTheStatus = (e)=> {
//     setStatusCheck(e)
//   }

//   // console.log(searchUserList);

//   const rows = searchUserList ? searchUserList?.map((item, index) => {

//     // console.log("in ...");

//     const activeationDate = (createDate) => {
//       let date = moment(createDate).format('DD-MM-yyyy');
//       return date
//     }

//     const status = (status) => {

//       if (status === 1) {
//         let active = "Active";
//         return active;
//       }
//       else {
//         let inactive = "Inactive";
//         return inactive;
//       }

//     }

//     return {

//       SerialNo: index + 1,
//       npiId: item.npiId,
//       Userid: item.userCode,
//       UserName: `${item.firstName} ${ item.middleName !== null && item.middleName !== undefined  ? item.middleName : ''} ${item.lastName} `,
//       ClinicName: item?.clinicDetails?.clinicName ,
//       Designation: item?.designation?.designation,
//       Role: item?.role?.role,
//       Activationdate: activeationDate(item.createdDate),
//       Status: status(item.status),
//       Action: Action(item._id)

//     }
//   }) : [];

//   const data = {
//     columns: [
//       {
//         label: 'Serial No',
//         field: 'SerialNo',
//         sort: 'asc',
//         width: 50
//       },
//       {
//         label: 'NPI',
//         field: 'npiId',
//         sort: 'asc',
//         width: 50
//       },
//       {
//         label: 'User Id',
//         field: 'Userid',
//         sort: 'asc',
//         width: 150
//       },
//       {
//         label: 'User Name',
//         field: 'UserName',
//         sort: 'asc',
//         width: 270
//       },
//       {
//         label: 'Clinic Name',
//         field: 'ClinicName',
//         sort: 'asc',
//         width: 200
//       },
//       {
//         label: 'Designation',
//         field: 'Designation',
//         sort: 'asc',
//         width: 100
//       },
//       {
//         label: 'Role',
//         field: 'Role',
//         sort: 'asc',
//         width: 150
//       },
//       {
//         label: 'Activation Date',
//         field: 'Activationdate',
//         sort: 'asc',
//         width: 100
//       },

//       {
//         label: 'Status',
//         field: 'Status',
//         width: 25
//       },
//       {
//         label: 'Action(s)',
//         field: 'Action',
//         width: 25
//       }
//     ],
//     // rows: [
//     //   {
//     //     SerialNo: '1',
//     //     npiId:" 1437279569",
//     //     Userid: 123,
//     //     UserName: 'Edinburgh',
//     //     ClinicName: '27, toranto ...',
//     //     Designation: 'Roy ... ',
//     //     Role: '$320' ,
//     //     Activationdate :"",
//     //     referby:"active" ,
//     //     Status:"active" ,
//     //     Action : Action()
//     //   }]
//     rows
//   };

//   const statusList = [
//     {
//         label: 'Active',
//         value: 'Active',
//         id: 1
//     },
//     {
//         label: 'Inactive',
//         value: 'Inactive',
//         id: 0
//     }
//     // ,
//     // {
//     //     label: 'Deleted',
//     //     value: 'Deleted',
//     //     id: 2
//     // },
// ]

// const submitSearch = async(data)=> {
//   try {

//     let getloginInfo: string | null = localStorage.getItem('loginInfo')

//     if (getloginInfo !== null) {
//         const Token = JSON.parse(getloginInfo)?.data?.accessToken;
//         const header = {

//             headers: { 'x-access-token': Token }

//         }

//         const response = await axios.post(`${APIkey}/api/admin/listUsersSearch`, data, header);

//         // console.log("harshang");

//         console.log(response?.data?.data);
//         setSearchUserList(response?.data?.data);

//     } else {
//         console.log('No access token found in localStorage');
//     }
// } catch (error) {

//   // console.log("harshang1");

//   setSearchUserList(null);
//     console.error( error);
// }
// }

// const handleSearch = () => {

//   if (downSearch === false) {
//     setDownSearch(true)
//   }
//   else {
//    let data ={
//     clinicId  ,
//     npiId : Number(npiId),
//     userName,
//     designationId,
//     activationDate,
//     statusCheck
//    }

//    console.log(data);

//    submitSearch(data)

//   }
// }

// const handelCancel =()=> {
//   setDownSearch(false)
//   // setSearchUserList([])
//   // setnpiId(null)
//   // setUseName(null)
//   // setClinicId(null)
//   // setDesignationId(null)
//   // setActivationDate(null)
//   // setStatusCheck(null)
// }

// const handelReset =()=> {
//    setSearchUserList([])
//   setnpiId(null)
//   setUseName(null)
//   setClinicId(null)
//   setDesignationId(null)
//   setActivationDate(null)
//   setStatusCheck(null)
// }

//   return (
//     <>
//       <div className='px-5 fw-bold'>
//         <div className='PageHeader '>
//           <div className='PageHeaderTitle'>
//             <span className='' >
//               User
//             </span>
//           </div>
//           <div>
//             <Link to="/user/AddNew">
//               <button type='button' className='btn btn-primary '>
//                 Add User
//               </button>
//             </Link>

//           </div>
//         </div>
//         <div className='card mt-3 p-3'>
//           {
//             downSearch &&
//             <>
//               <div className='  d-flex justify-content-end'>
//                 <button className='grayButton' onClick={handelCancel}>
//                   <i className="fa fa-close" ></i>
//                 </button>
//               </div>
//               <div className='row'>
//                 <div className='col-md-6 col-lg-3'>
//                   <label htmlFor="" className='formLabelTitle'>NPI:</label>
//                   <input
//                    type="number"
//                     value={npiId || ''}
//                    onChange={(e)=> setnpiId(e.target.value)}
//                    className='form-control SearchInputbox' />
//                 </div>
//                 <div className='col-md-6 col-lg-3'>
//                 <label htmlFor="" className='formLabelTitle'>User Name:</label>
//                   <input
//                    type="text"
//                    value={userName || ''}
//                    onChange={(e)=> setUseName(e.target.value)}
//                     className='form-control SearchInputbox' />
//                 </div>
//                 <div className='col-md-6 col-lg-3'>
//                 <label htmlFor="" className='formLabelTitle'>Clinic Name:</label>
//                   <SerchableDropdown

//                   InitialValue={clinicId}
//                    gettingData={getTheClinicID}
//                     options={listclinic}
//                      />
//                 </div>
//                 <div className='col-md-6 col-lg-3'>

//                 <label htmlFor="" className='formLabelTitle'>Designation:</label>
//                   <SerchableDropdown
//                   InitialValue={designationId}
//                   gettingData={getTheDesignationId}
//                   options={designationList}
//                   />

//                 </div>
//               </div>
//             </>

//           }
//           {
//             downSearch &&
//             <div className='row mt-2'>
//               <div className='col-md-6 col-lg-3 '>
//                 <label htmlFor="" className='formLabelTitle'>Activation Date:</label>
//                 <input type="date"
//                 value={activationDate || ''}
//                 onChange={(e) => setActivationDate(e.target.value)}
//                 className='form-control SearchInputbox'
//                 />

//               </div>
//               <div className='col-md-6 col-lg-3 '>
//                 <label htmlFor="" className='formLabelTitle'>Status:</label>
//                 <SerchableDropdown
//                 InitialValue={statusCheck}
//                 gettingData={getTheStatus}
//                 options={statusList}
//                 />
//               </div>

//               <div className={'col-md-6 col-lg-3 offset-lg-3  d-flex align-items-end justify-content-end  gap-2 '} >
//                 <button className='btn btn-secondary h-45px' onClick={handelReset}>
//                   Reset
//                 </button>
//                 <button className='btn btn-primary h-45px' onClick={() => handleSearch()}>
//                   <i className="fa-solid fa-magnifying-glass"></i> Search
//                 </button>
//               </div>

//             </div>

//           }
//           {
//             !downSearch &&
//             <div className={'col-md-2 offset-md-10 d-flex justify-content-end '} >
//               <button className='btn btn-primary' onClick={() => handleSearch()}>
//                 <i className="fa-solid fa-magnifying-glass"></i> Search
//               </button>
//             </div>
//           }

//         </div>

//         <div className='card mt-3 p-3'>
//           <div className='PageHeader'>
//             <div className='PageHeaderTitle'>
//               <span>
//                 Search Result
//               </span>
//             </div>
//           </div>
//           <MDBDataTable
//             striped
//             bordered
//             small
//             data={data}
//             className="custom-table"
//           />
//         </div>

//       </div>
//         <ToastContainer />

//       {

//         openDelete &&
//         (
//           <DeleteModel
//             openDelete={openDelete}
//             closedelete={handleClosedelete}
//             deleteID={deleteID}
//             deletedEntity={`${deletedEntity[0]?.firstName} ${deletedEntity[0]?.lastName}`}
//             deleteFunction={deleteFunction}
//           />
//         )
//       }

//     </>
//   )
// }

// export default User
