import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
// import {MenuInnerWithSub} from './MenuInnerWithSub'
// import {MegaMenu} from './MegaMenu'

export function MenuInner() {
  const intl = useIntl()
  
  return (
    <>
      {/* <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' /> */}
      {/* <MenuItem title='Inbox' to='/builder' /> */}
      <MenuItem title='Dashboard' to='/adminDashboard'  />
      <MenuItem title='Clinics' to='/clinics'  />
      <MenuItem title='User' to='/user' />
      <MenuItem title='Billing' to='/billing' />
      <MenuItem title='Analytical Reports' to='/analyticalReports' />
    
    </>
  )
}
