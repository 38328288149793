import React, {useEffect, useState} from 'react'
import plusredbtn from '../../EHRIcon/plusredbtn.svg'
import CommonTable from '../../../../ReUsableComponent/CommonTable'
// import whiteplus from '../../EHRIcon/whiteplus.svg'
// import Vector from '../../EHRIcon/Vector.svg'
import axios from 'axios'
// import Deleteicon from '../../EHRIcon/Deleteicon.svg'
import DeleteModel from '../../../../ReUsableComponent/DeleteModel'
import EditMedication from './EditMedication'
import AddMedication from './AddMedication'
import ViewMedication from './ViewMedication'
// import Eyebutton from '../../EHRIcon/Eyebutton.svg'
import tablecolor from '../../../../ReUsableComponent/ReyClinicalinputs.json'



const Medication = () => {
  const APIkey = process.env.REACT_APP_SERVERAPI || ''
  const [data, setData] = useState([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null)

  const [showAddModal, setShowAddModal] = useState(false)
  const [saveData, setSaveData] = useState(false)

  const [isEditMode, setIsEditMode] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [editId, setEditData] = useState(null)

  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')


  const [ showViewModal, setShowViewModal ] = useState(false );
  const [viewData,setViewData]=useState('')
  const [viewId,setViewId]=useState(null)


  const columns = [
    {key: ['srNo'], header: 'Sr.No'},
    {key: ['medication'], header: 'Medicine Name'},
    {key: ['brandName'], header: 'Brand Name'},
    {key: ['form'], header: 'Types of Medicine'},
    {key: ['frequency'], header: 'Dosage Instruction'},
    {key: ['routeOfAdministration'], header: 'Way to Take Medicine'},
    {key: ['storageRequirements'], header: 'Storage requirements'},
    {key: ['notes'], header: 'Notes'},
    {key: ['actions'], header: 'Actions', isAction: true},
  ]

  const fetchMedicationdata = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const header = {headers: {'x-access-token': Token}}
        const response = await axios.get(`${APIkey}/api/admin/getPreferredMedicationList`, header)
        console.log('Fetched data:', response.data.data)
        const responseData = response.data.data
        console.log(responseData)

        const formattedData = responseData.map((item, index) => {
          return {
            srNo: index + 1,
            medication: item.medication,
            brandName: item.brandName,
            form: item.form,
            frequency: item.frequency,
            routeOfAdministration: item.routeOfAdministration,
            storageRequirements: item.storageRequirements,
            notes: item.notes,
            actions: (
              <div className='actionbtn'>
                   <button onClick={()=>handleView(item._id)} className='editbtn'>
                  <img 
                  // src={Eyebutton} 
                  alt='view'/>
                </button>
                <button className='editbtn ' onClick={() => handleEdit(item._id)}>
                  <img 
                  // src={Vector} 
                  alt='Edit'  />
                </button>
                <button className='editbtn' onClick={()=>openDeleteModal(item._id)}>
                  <img 
                  // src={Deleteicon} 
                  alt='Delete'   />
                </button>
             
              </div>
            ),
          }
        })
        setData(formattedData)
      } else {
        console.log('No login info found in localStorage')
      }
    } catch (error) {
      console.error('Error fetching vaccination data:', error)
    }
  }
  useEffect(() => {
    fetchMedicationdata()
  }, [showAddModal,showDeleteModal,showEditModal])
  const openDeleteModal=(id)=>{
    setDeleteId(id);
    setShowDeleteModal(true);
  }

  
  const handleView = (_id) => {
    setViewData(_id)
    setViewId(_id)
    setShowViewModal(true)

  }
  const handleDelete = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const header = {headers: {'x-access-token': Token}}

        await axios.delete(`${APIkey}/api/admin/deletePreferredMedication/${deleteId}`, header)
        console.log('Row deleted successfully')

        fetchMedicationdata()
        setShowDeleteModal(false) // Close the modal after successful deletion
      } else {
        console.log('No login info found in localStorage')
      }
    } catch (error) {
      console.error('Error deleting vaccination:', error)
    }
  }
  const [searchCriteria, setSearchCriteria] = useState({
    medication: '',
    brandName: '',
    form: '',
    frequency: '',
    routeOfAdministration: '',
    storageRequirements: '',
    notes:''
  })
  const handleSearchCriteriaChange = (e) => {
    const {name, value} = e.target
    setSearchCriteria((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSearch = async () => {
    try {
      const getloginInfo = localStorage.getItem('appLoginInfo')
      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        console.log('token', Token)

        const header = {
          headers: {'x-access-token': Token},
        }

        const response = await axios.post(
          `${APIkey}/api/admin/searchPreferredMedication`,
          searchCriteria,
          header
        )
        const responseData = response.data.data
        const searchData = responseData.map((item, index) => ({
          srNo: index + 1,
          medication: item.medication,
          brandName: item.brandName,
          form: item.form,
          frequency: item.frequency,
          routeOfAdministration: item.routeOfAdministration,
          storageRequirements: item.storageRequirements,
          notes: item.notes,
          actions: (
            <div className='actionbtn'>
              <button onClick={() => handleView(item._id)} className='editbtn'>
                <img 
                // src={Eyebutton} 
                alt='view' />
              </button>
              <button onClick={() => handleEdit(item._id)} className='editbtn'>
                <img 
                // src={Vector} 
                alt='Edit' />
              </button>
              <button onClick={() => openDeleteModal(item._id)} className='editbtn'>
                <img 
                // src={Deleteicon} 
                alt='Delete' />
              </button>
            </div>
          ),
  
        }))
        setData(searchData)
      }
    } catch (error) {
      console.log('Error fetching data')
    }
  }

  const handleAddClick = () => {
    console.log('Add clicked!')
    setShowAddModal(true)
  }
  const handleRowClick = (row) => {
    console.log('Row clicked:', row)
    // Logic to handle row click
  }
  const handleCloseModal = ()=>{
    setShowDeleteModal(false);
    setShowAddModal(false);
    setShowEditModal(false);
    setShowViewModal(false);
  }


  const handleEdit=(_id)=>{
    setEditData(_id);
    setIsEditMode(true);
    setShowEditModal(true);

  }

  return (
    <>
     <div className='clinical-time-header'>
        <div className='titletextformat'>
          <div className='PageHeaderTitle'>Medication</div>
          <div>
            {' '}
            <button className='add-Vaccine-btn'  onClick={handleAddClick} >
              <img 
              // src={whiteplus} 
              alt='Add Vaccine' /> Add Medication
            </button>
          </div>
        </div>
      </div>
      <div>
        <div className='d-flex gap-2  mt-3'>
          <div>
            <input type='text' className='SearchInputbox' placeholder='Medicine Name' name='medication' value={searchCriteria.medication} onChange={handleSearchCriteriaChange}/>
          </div>
          <div>
            <input type='text' className='SearchInputbox' placeholder='Brand Name' name='brandName' value={searchCriteria.brandName} onChange={handleSearchCriteriaChange}/>
          </div>
          <div>
            <input type='text' className='SearchInputbox' placeholder='Types Of Medication' name='form' value={searchCriteria.form} onChange={handleSearchCriteriaChange}/>
          </div>
          <div>
            <input type='text' className='SearchInputbox' placeholder='Dosage Instruction' name='frequency' value={searchCriteria.frequency} onChange={handleSearchCriteriaChange} />
          </div>
          <div>
            <input type='text' className='SearchInputbox' placeholder='Route Of Administration' name='routeOfAdministration' value={searchCriteria.routeOfAdministration} onChange={handleSearchCriteriaChange}/>
          </div>
          <div>
            <input type='text' className='SearchInputbox' placeholder='Storage' name='storageRequirements' value={searchCriteria.storageRequirements} onChange={handleSearchCriteriaChange}/>
          </div>
          <div>
            <input type='text' className='SearchInputbox' placeholder='Notes' name='notes' value={searchCriteria.notes} onChange={handleSearchCriteriaChange}/>
          </div>

          <div className='col-md-1'>
            <button type='button' className='AddBtn' onClick={handleSearch}>
              Search
            </button>
          </div>
        </div>
        <br />
        <div className='clinical-time-container '>
        <div>
      <CommonTable
        data={data}
        
        totalCount={data.length}
        perPage={perPage}
        setPerPage={setPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        columns={columns}
        searchQuery={searchQuery}
        setQuery={setSearchQuery}
        onRowClick={handleRowClick}
        headerColor={tablecolor.primaryTable.color}
        issetPaginationCompo={true}
        isPagelength={true}
        isSearched={true}
      />
      </div>
      <DeleteModel
      openDelete={showDeleteModal}
      closedelete={handleCloseModal}
      deletedEntity={handleDelete}
      deleteID={deleteId}
      deleteFunction={handleDelete}/>
      


      <EditMedication
      onHide={handleCloseModal}
      onShow={showEditModal}
      editId={editId}

      />
    <AddMedication
      onHide={handleCloseModal}
      onShow={showAddModal}
      onClick={saveData}
      />
      <ViewMedication onshow={showViewModal} onhide={handleCloseModal} viewId={viewId} />


      </div>
      </div>
    </>
  )
}

export default Medication
