import {useState} from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import {useFormik} from 'formik';
import {requestPassword} from '../core/_requests';
import {toAbsoluteUrl} from '../../../../_metronic/helpers';
import TitleComponent from '../../../../ReUsableComponent/TitleComponent';
import {CommonLoginDisplayButton} from '../../../../SuperAdmin/Pages/ReausableComponent/CommonButton';
const initialValues = {
  email: 'admin@demo.com',
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
});

export function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true);
      setHasErrors(undefined);
      setTimeout(() => {
        requestPassword(values.email)
          .then(({data: {result}}) => {
            setHasErrors(false);
            setLoading(false);
          })
          .catch(() => {
            setHasErrors(true);
            setLoading(false);
            setSubmitting(false);
            setStatus('The login detail is incorrect');
          });
      }, 1000);
    },
  });

  return (
    <>
    <div className='loginpage '>
      <div className='card custom-card-width-login align-items-center'>
        <div className='card-body '>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework p-3'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
          >
            <div className='text-center mb-10'>
              {/* begin::Title */}
              {/* <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1> */}
              <TitleComponent type='loginTitle' className='align-items-left d-flex'>
                {' '}
                Retrieve your Password
              </TitleComponent>
              {/* end::Title */}

              {/* begin::Link */}
              <div className='text-gray-500 fw-semibold fs-6 title-center'>
                <TitleComponent type='loginBodyText' className=' d-flex pt-4'>
                  {' '}
                  Enter the email associated to your account and we’ll send you a link to retrieve
                  your password
                </TitleComponent>
              </div>
              {/* end::Link */}
            </div>

            {/* begin::Title */}
            {hasErrors === true && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>
                  Sorry, looks like there are some errors detected, please try again.
                </div>
              </div>
            )}

            {hasErrors === false && (
              <div className='mb-10 bg-light-info p-8 rounded'>
                <div className='text-info'>Sent password reset. Please check your email</div>
              </div>
            )}
            {/* end::Title */}

            {/* begin::Form group */}
            {/* <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
        <input
          type='email'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div> */}
            <TitleComponent
              type='loginBodyText'
              className='d-flex justify-content-center align-items-center my-4'
            >
              <input
                type='text'
                style={{
                  backgroundColor: '#F5F8FA',
                  color: '#181C32',
                  padding: '10px 24px',
                  gap: '10px',
                  borderRadius: '4px',
                  width: '100%',
                  border: '1px solid #DCDCDC',
                }}
                className='styled-input'
                placeholder='Email associate to your account'
              />
            </TitleComponent>

            {/* end::Form group */}

            {/* begin::Form group */}
            <>
              <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                {/* <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4' > */}
                <CommonLoginDisplayButton>Continue</CommonLoginDisplayButton>
              </div>
              <span style={{display: 'flex', justifyContent: 'end', marginTop: '8px'}}>
                <Link to='/login' style={{color: '#181C32'}}>
                  <TitleComponent type='popUpButton' className='my-4'>
                    Back to Login
                  </TitleComponent>
                </Link>
              </span>
            </>
            {/* end::Form group */}
          </form>
        </div>
      </div>
    </div>
    <div className='image-back-wrapper'></div>
    </>
  );
}
