import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'

const ViewChiefComplain = ({onshow,onhide,viewId,}) => {
    const APIkey = process.env.REACT_APP_SERVERAPI
    const [chiefComplaintName, setChiefComplaintName] = useState('')
    const [notes, setNotes] = useState('')
    useEffect(() => {
        const fetchChiefComplainData = async () => {
         
          try {
            const getLoginInfo = localStorage.getItem('appLoginInfo')
            console.log('getLoginInfo: ', getLoginInfo)
    
            if (getLoginInfo !== null) {
              const { data : {accessToken}} = JSON.parse(getLoginInfo) // Corrected property name
    
              const headers = {'x-access-token': accessToken}
    
              const response = await axios.get(
                `${APIkey}/api/admin/getPreferredChiefComplaintById/${viewId}`,
                {headers}
              )
              const data = response.data.data
              console.log("chief complain data ", data)
              
              setChiefComplaintName(data.chiefComplaintName)
              setNotes(data.notes)
    
  

            } else {
              console.error('No login info found in localStorage')
            }
          } catch (error) {
            console.error('Error fetching data:', error)
          } 
        }
    
        if (viewId) {
            fetchChiefComplainData()
        }
    },[viewId])


    const handleCloseModal = () => {
        setChiefComplaintName('')
        setNotes('')
      onhide()
    }
    
  return (
  <>
  <Modal show={onshow} onHide={handleCloseModal} size='lg' centered>
    <Modal.Header closeButton className='h-25px dialoge align-items-center pb-3' >
    <div className='PageHeaderTitle'> Chief Complain Information</div>
    </Modal.Header>
    <Modal.Body className='p-2 dialoge'>
        <div className='dashboard-card '>
            <div className='col-md-12'>
                <div className='mb-3'>
             <label className='viewlabels'>Chief Complain:</label>
             <div className='vaccine-label-data'>{chiefComplaintName}</div>

            </div>
            <div className='mb-3'>
             <label className='viewlabels'>Notes:</label>
             <div className='vaccine-label-data'>{notes}</div>

            </div>
        </div>
        </div>
    </Modal.Body>
    <Modal.Footer>
    <div className='my-2 d-flex justify-content-end gap-3'>
        <button type='button' className='tertiarybig' onClick={handleCloseModal}>
              Close
            </button>
        </div>
    </Modal.Footer>
  </Modal>
  
  
  </>
  )
}

export default ViewChiefComplain
