// import React, { useEffect, useRef, useState } from 'react'
// import inputText from '../../../../ReUsableComponent/ReyClinicalinputs.json'
// import TagsInput from '../../../../ReUsableComponent/TagsInput'
// import { Modal } from 'react-bootstrap'
// import SearchableDropdown from '../../ReusablePages/ReusableAppointment/SearchableDropdown'
// import { groupIdRegExp } from '../../../../ReUsableComponent/regEx'
// import ModalImage from 'react-modal-image';
// import iIcon from '../../EHRIcon/infoIcon.svg'
// import axios from 'axios'
// import { toast } from 'react-toastify'
// import ErrorMsgShow from '../../../../ReUsableComponent/ErrorMsgShow'
// import { useParams } from 'react-router-dom'
// import moment from 'moment'

// function AddNewDocument({ open, closeDocumentPopUp, patientData, disabled }) {

//     const APIkey = process.env.REACT_APP_SERVERAPI;

//     const {appointmentId} = useParams()

//     const inputRef = useRef(null);

//     const maxSizeInBytes =  10 * 1024 * 1024;
//     const allowedExtensions = ['.jpeg', '.jpg', '.png'];
//     const fileLimit = null ;

//     const [loading, setLoading] = useState<any>(false)

//     const [clinicId, setClinicId] = useState<any>()
//     const [provideName, setProviderName] = useState<any>([])
//     const [mrn, setMrn] = useState()

//     const [attachFile, setAttachFile] = useState("")
//     const [attachFileName, setAttachFileName] = useState<any>([])

//     const [attachFileErr, setAttachFileErr] = useState("")

//     const [patientId, setPatientId] = useState<any>(patientData?._id)
//     const [patientName, setPatientName] = useState<any>()

//     const [visitDate, setVisitDate] = useState<any>()
//     const [visitTime, setVisitTime] = useState<any>()
//     const [documentName, setDocumentName] = useState<any>()
//     const [description, setDescription] = useState<any>()
//     const [documentTags, setDocumentTags] = useState<any>([])
//     const [folderList, setFolderList] = useState<any>([])

//     const [documentTypeList, setDocumentTypeList] = useState<any>([])

//     const [advanceDirectiveList, setAdvanceDirectiveList] = useState([])

//     const [statusList, setStatusList] = useState([])

//     const [folderId, setFolderId] = useState<any>()

//     const [providerId, setProviderId] = useState<any>()
//     const [documentTypeId, setDocumentTypeId] = useState<any>()
//     const [advanceDirectiveId, setAdvanceDirectiveId] = useState<any>()
//     const [status, setStatus] = useState<any>()
//     const [attachment, setAttachment] = useState<any>([])

//     const [sendToReview, setSendToReview] = useState<any>(false)

//     const [uploadedBy, setUploadedBy] = useState<any>()

//     const [openCreateFolder, setOpenCreateFolder] = useState<any>(false)
//     const [folderName, setFolderName] = useState<any>()

//     const [documentNameErr, setDocumentNameErr] = useState<any>()
//     const [folderIdErr, setFolderIdErr] = useState<any>()
//     const [patientNameErr , setPatientNameErr ] = useState<any>()
//     const [attachmentBasicErr , setAttachmentBasicErr ] = useState<any>()

//     const [folderNameErr, setFolderNameErr] = useState<any>()

//     const [documentTypeErr , setDocumentTypeErr] = useState<any>()

//     useEffect(() => {
//         const getloginInfo = localStorage.getItem('appLoginInfo');

//         if (getloginInfo !== null) {
//             const loginInfo = JSON.parse(getloginInfo);
//             const Token = loginInfo.data.accessToken;
//             const Id = loginInfo.data.clinicId;
//             const userId = loginInfo.data.id;
//             const header = {
//                 headers: { 'x-access-token': Token }
//             };

//             setClinicId(Id);

//             // Call each API function
//             fetchFolderList(Id, header);
//             fetchDocumentTypeList(Id, header);
//             fetchAdvanceDirectiveList(Id, header);
//             fetchDocumentStatusList(Id, header);
//             fetchUploadedBy(Id, userId, header);

//             if (disabled) {
//                 setPatientName(`${patientData?.firstName} ${patientData?.lastName}`);
//                 setMrn(patientData?.mrn);
//                 console.log("appointment Id : " , typeof appointmentId);
//                 if(appointmentId != "null"){
//                     fetchAppointmentDataById(appointmentId, header);
//                 }
//             }
//         } else {
//             console.log("No login info found in localStorage");
//         }
//     }, [openCreateFolder]);

//     // Define each API call as a separate function
//     const fetchFolderList = async (Id, header) => {
//         try {
//             const response = await axios.get(`${APIkey}/api/admin/getFolderList/${Id}`, header);
//             setFolderList(response?.data?.data?.map(i => ({
//                 label: i.folderName,
//                 value: i.folderName,
//                 id: i._id
//             })));
//         } catch (err) {
//             console.log(err);
//         }
//     };

//     const fetchDocumentTypeList = async (Id, header) => {
//         try {
//             const response = await axios.get(`${APIkey}/api/admin/getDocumentTypeList/${Id}`, header);
//             setDocumentTypeList(response?.data?.data?.map(i => ({
//                 label: i.documentTypeName,
//                 value: i.documentTypeName,
//                 id: i._id
//             })));
//         } catch (err) {
//             console.log(err);
//         }
//     };

//     const fetchAdvanceDirectiveList = async (Id, header) => {
//         try {
//             const response = await axios.get(`${APIkey}/api/admin/getAdvanceDirectiveList/${Id}`, header);
//             setAdvanceDirectiveList(response?.data?.data?.map(i => ({
//                 label: i.advanceDirectiveName,
//                 value: i.advanceDirectiveName,
//                 id: i._id
//             })));
//         } catch (err) {
//             console.log(err);
//         }
//     };

//     const fetchDocumentStatusList = async (Id, header) => {
//         try {
//             const response = await axios.get(`${APIkey}/api/admin/getDocumentStatusList/${Id}`, header);
//             setStatusList(response?.data?.data?.map(i => ({
//                 label: i.documentStatusName,
//                 value: i.documentStatusName,
//                 id: i._id
//             })));
//         } catch (err) {
//             console.log(err);
//         }
//     };

//     const fetchUploadedBy = async (Id, userId, header) => {
//         try {
//             const response = await axios.get(`${APIkey}/api/admin/getUserById/${Id}/${userId}`, header);
//             const uploadedBy = `${response?.data?.data?.firstName} ${response?.data?.data?.lastName}`;
//             setUploadedBy(uploadedBy);
//         } catch (err) {
//             console.log(err);
//         }
//     };

//     const fetchAppointmentDataById = async (appointmentId, header) => {
//         try {
//             const response = await axios.get(`${APIkey}/api/admin/getAppointmentById/${appointmentId}`, header);
//             const appointmentData = response?.data?.data;
//             console.log(appointmentData);

//             if (appointmentData) {
//                 setVisitDate(moment(appointmentData?.appointmentDate).format('YYYY-MM-DD'));
//                 setVisitTime(appointmentData?.appointmentTime);
//                 setProviderName([]);
//             }
//         } catch (err) {
//             console.log(err);
//         }
//     };

//     const handelClose = () => {
//         closeDocumentPopUp()
//     }

//     const addFolder = async (data) => {
//         try {
//             const getloginInfo: string | null = localStorage.getItem('appLoginInfo');

//             if (getloginInfo !== null) {
//                 const Token = JSON.parse(getloginInfo).data.accessToken;
//                 const header = {
//                     headers: { 'x-access-token': Token },
//                 };

//                 console.log(header);

//                 const response = await axios.post(`${APIkey}/api/admin/createFolder`, data, header);
//                 console.log('this is response ', response);

//                 if (response) {

//                     setOpenCreateFolder(false)

//                     toast.success('Folder added successfully!', {
//                         position: 'top-right'
//                     });
//                 }
//             }
//         }
//         catch (err) {
//             console.log(err);
//         }
//     }

//     const submitFolderName = (e) => {

//         if (e.key === 'Enter' || e.key === 'Tab') {

//             e.preventDefault();

//             let data = {
//                 folderName
//             }

//             if (!folderName || folderName.trim() === '') {

//                 setFolderNameErr("Please enter a folder name");

//             } else {

//                 addFolder(data);
//             }
//         }
//     }

//     const handleAttachFile = (e) => {
//         const files = e.target.files;
//         const fileArray: any = [];

//         const filesName: any = [];

//         // Loop through each selected file
//         for (let i = 0; i < files.length; i++) {
//             const reader = new FileReader();

//             reader.onload = () => {
//                 // Convert the file to base64 and push it to the fileArray
//                 fileArray.push(
//                     // name: files[i],
//                     reader.result
//                 );

//                 filesName.push(files[i]);

//                 // If all files are processed, you can now use the fileArray
//                 if (fileArray.length === files.length) {
//                     setAttachment([...attachment, ...fileArray]);

//                     setAttachFileName([...attachFileName, ...filesName]);

//                     // Here, you can do whatever you want with the fileArray
//                 }
//             };

//             // Read the selected file as data URL
//             reader.readAsDataURL(files[i]);
//         }
//     };

//     const deleteTag =(index)=> {
//         setAttachment(attachment.filter((_, i) => i !== index));
//         setAttachFileName(attachFileName.filter((_, i) => i !== index));
//     }

//     const validation = (value: any) => {

//         const error: any = {};

//         if (!value.documentName || value.documentName.trim() == '') {
//             error.documentName = inputText.documentName.ErrMsg;
//         }

//         if(!value.patientId){
//            error.patientId = inputText.patientName.ErrMsg ;
//         }
//         // if (value.planId) {
//         //     if (!groupIdRegExp.test(value.planId)) {
//         //         error.planId = inputText.PlanID.validErrMsg;
//         //     }
//         // }

//         if (!value.folderId) {
//             error.folderId = inputText.folderName.ErrMsg
//         }

//         // if (!value.layerOfCoverage) {
//         //     error.layerOfCoverage = inputText.layerofCoverage.ErrMsg;
//         // }

//         if(!value.documentTypeId){
//             error.documentTypeId  = inputText.documentType.ErrMsg
//         }

//         if(!value.attachment.length){
//             error.attachment = "Please enter attachment(s)"
//         }
//         else{
//                 if( fileLimit && value?.attachment?.length >= fileLimit){

//                     error.attachment = `${fileLimit} attachment(s) Limit`
//                 }
//         }
//         // if (value.attachments_front) {
//         //     if (value.attachments_front.size > maxSizeInBytes) {
//         //         error.attachments_front = inputText.UploadInsuranceCard.ErrMsg
//         //     }
//         //     else {
//         //         const allowedExtensions = ['.jpeg', '.jpg', '.png'];
//         //         const fileExtension = value.attachments_front.name.toLowerCase().slice(value.attachments_front.name.lastIndexOf('.'));
//         //         if (!allowedExtensions.includes(fileExtension)) {
//         //             error.attachments_front = 'Please upload a valid file type (.jpeg, .jpg, or .png)';
//         //         }
//         //     }
//         // }

//         // if (value.attachments_back) {
//         //     if (value.attachments_back.size > maxSizeInBytes) {
//         //         error.attachments_back = inputText.UploadInsuranceCard.ErrMsg
//         //     }
//         //     else {
//         //         const allowedExtensions = ['.jpeg', '.jpg', '.png'];
//         //         const fileExtension = value.attachments_back.name.toLowerCase().slice(value.attachments_back.name.lastIndexOf('.'));
//         //         if (!allowedExtensions.includes(fileExtension)) {
//         //             error.attachments_back = 'Please upload a valid file type (.jpeg, .jpg, or .png)';
//         //         }
//         //     }
//         // }

//         return error

//     }

//     const submitData = async (data) => {
//         try {
//             const getloginInfo: string | null = localStorage.getItem('appLoginInfo');

//             if (getloginInfo !== null) {
//                 const Token = JSON.parse(getloginInfo).data.accessToken;
//                 const header = {
//                     headers: { 'x-access-token': Token },
//                     // 'Content-Type': 'multipart/form-data'
//                 };

//                 const response = await axios.post(`${APIkey}/api/admin/createPatientDocuments`, data, header);
//                 console.log('this is response ', response);

//                 if (response) {
//                     handelClose();

//                     setLoading(false)

//                     toast.success('Document added successfully!', {
//                         position: 'top-right'
//                     });
//                 }
//             }
//         }
//         catch (err) {
//             console.log("error occur : ", err);
//         }
//     }

//     const createDocument = (e: React.FormEvent<HTMLFormElement>) => {
//         e.preventDefault();
//         setLoading(true)

//         let data = {

//             patientId,
//             visitDate,
//             visitTime,
//             providerId,
//             documentName,
//             description,
//             documentTags,
//             folderId,
//             documentTypeId,
//             advanceDirectiveId,
//             status,
//             attachment,
//             sendToReview
//         }

//         console.log("data:", data);

//         let err = validation(data)

//         console.log("error", err);

//         if (Object.keys(err).length === 0) {

//             submitData(data)

//             setDocumentNameErr(err.documentName)
//             setFolderIdErr(err.folderId)
//             setPatientNameErr(err.patientId)
//             setAttachmentBasicErr(err.attachment)
//             setDocumentTypeErr(err.documentTypeId)

//         }
//         else {
//             setLoading(false)

//             setDocumentNameErr(err.documentName)
//             setFolderIdErr(err.folderId)
//             setPatientNameErr(err.patientId)
//             setAttachmentBasicErr(err.attachment)
//             setDocumentTypeErr(err.documentTypeId)

//         }
//     }

//     return (
//         <Modal
//             show={open}
//             onHide={handelClose}
//             className=''
//             size='xl'
//             centered
//         >
//             <Modal.Header closeButton className='dialoge p-3'>
//                 <Modal.Title >Add New Documents</Modal.Title>
//             </Modal.Header>
//             <form onSubmit={createDocument} className=''>

//                 <Modal.Body className=' dialoge p-1'>
//                     <div className='card p-3'>
//                         <div className='row'>
//                             <div className='col-md-6 col-lg-4 '>
//                                 <label className='formLabelTitle required formLabelTitle' htmlFor="">{inputText.patientName.label}</label>
//                                 {
//                                 disabled ?
//                                 <input
//                                     type="text"
//                                     value={patientName}
//                                     // onChange={getTheBin}
//                                     disabled={disabled}
//                                     placeholder={inputText.patientName.placeholder}
//                                     className='form-control SearchInputbox' />
//                                     :
//                                     <SearchableDropdown

//                                      />
//                                 }
//                                 <ErrorMsgShow errorMsg={patientNameErr} />

//                             </div>
//                             <div className='col-md-6 col-lg-4'>
//                                 <label className='formLabelTitle required formLabelTitle' htmlFor="">{inputText.mrn.label}</label>
//                                 <input
//                                     type="number"
//                                     disabled
//                                     value={mrn}
//                                     // onChange={getTheBin}
//                                     placeholder={inputText.mrn.placeholder}
//                                     className='form-control SearchInputbox' />

//                             </div>
//                             <div className='col-md-6 col-lg-4 '>
//                                 <label className='formLabelTitle formLabelTitle' htmlFor="">{inputText.visitDate.label}</label>
//                                 <input
//                                     type="date"
//                                     disabled={disabled ? true : false}
//                                     value={visitDate}
//                                     // value  ={pcn}
//                                     // onChange={getThePcn}
//                                     placeholder={inputText.visitDate.placeholder}
//                                     className='form-control SearchInputbox'
//                                 />

//                             </div>

//                         </div>
//                         <div className='row mt-2 '>

//                             <div className='col-md-6 col-lg-4'>
//                                 <label className='formLabelTitle  formLabelTitle' htmlFor="">{inputText.visitTime.label}</label>
//                                 <input
//                                     type="time"
//                                     disabled={disabled}
//                                     value={visitTime}

//                                     placeholder={inputText.visitTime.placeholder}
//                                     // onChange={getTheInsurancePlan}
//                                     className='form-control SearchInputbox ' />
//                                 {
//                                     // errInsurancePlanName &&

//                                     // <span className='formLabelTitle errMsg'>
//                                     //     <i className="fa-solid fa-triangle-exclamation"></i>
//                                     //     &nbsp;
//                                     //     {/* {inputText.InsurancePlaneName.ErrMsg} */}
//                                     //     {errInsurancePlanName}
//                                     // </span>
//                                 }
//                             </div>
//                             <div className='col-md-6 col-lg-4 '>
//                                 <label className='formLabelTitle  formLabelTitle' htmlFor="">{inputText.Provider.label}</label>
//                     {
//                         disabled ?
//                         <input type="text"
//                         disabled
//                         value={provideName}
//                          className="form-control SearchInputbox " />
//                          :
//                                 <SearchableDropdown
//                                     // InitialValue={coverageType}
//                                     // required={true}

//                                     // gettingData={getTheCoverageType}
//                                     placeHolder={inputText.Provider.placeholder}
//                                 // options={coverageTypeData}
//                                 />

//                             }

//                             </div>
//                             <div className='col-md-6 col-lg-4 '>
//                                 <label className='formLabelTitle required formLabelTitle' htmlFor="">{inputText.documentName.label}</label>
//                                 <input
//                                     // maxLength={10}
//                                     type="text"
//                                     onChange={(e) => setDocumentName(e.target.value)}
//                                     maxLength={100}
//                                     // value={patientPersonCode}
//                                     placeholder={inputText.documentName.placeholder}
//                                     className='form-control SearchInputbox'
//                                 />
//                                 <ErrorMsgShow errorMsg={documentNameErr} />
//                             </div>

//                         </div>
//                         <div className='row mt-2 '>

//                             <div className='col-md-6 col-lg-4'>
//                                 <label className='formLabelTitle  formLabelTitle ' htmlFor="">{inputText.description.label}</label>
//                                 <input
//                                     type="text"
//                                     maxLength={500}
//                                     // value={insuranceEffectiveDate}
//                                     placeholder={inputText.description.placeholder}
//                                     onChange={(e) => setDescription(e.target.value)}
//                                     className='form-control SearchInputbox ' />

//                                 {
//                                     // errEffectiveDate &&

//                                     // <span className='formLabelTitle errMsg'>
//                                     //     <i className="fa-solid fa-triangle-exclamation"></i>
//                                     //     &nbsp;
//                                     //     {errEffectiveDate}
//                                     // </span>
//                                 }

//                             </div>
//                             <div className='col-md-6 col-lg-4 '>
//                                 <label className='formLabelTitle  formLabelTitle' htmlFor="">{inputText.documentTags.label}</label>
//                                 <TagsInput
//                                     duplicate={false}
//                                     max={5}
//                                     tagsValue={documentTags}
//                                     getTheTags={(e) => setDocumentTags(e)
//                                     }
//                                 />
//                                 {
//                                     // errEndDate &&

//                                     // <span className='formLabelTitle errMsg ' >
//                                     //     <i className="fa-solid fa-triangle-exclamation"></i>
//                                     //     &nbsp;
//                                     //     {errEndDate}
//                                     // </span>
//                                 }

//                             </div>
//                             <div className='col-md-6 col-lg-4 '>

//                                 {
//                                     openCreateFolder ?
//                                         (

//                                             <>

//                                                 <label className='formLabelTitle  formLabelTitle ' htmlFor="">Create New Folder:</label>
//                                                 <div className='d-flex gap-2 '>
//                                                     <input
//                                                         autoFocus
//                                                         ref={inputRef}
//                                                         onBlur={() => [setOpenCreateFolder(false), setFolderNameErr('')]}
//                                                         onChange={(e) => setFolderName(e.target.value)}
//                                                         onKeyDown={submitFolderName}

//                                                         type='text'
//                                                         className='form-control SearchInputbox'
//                                                     />
//                                                 </div>
//                                                 {
//                                                     folderNameErr && (
//                                                         <>
//                                                             <span className='formLabelTitle errMsg'>
//                                                                 <i className="fa-solid fa-triangle-exclamation"></i>
//                                                                 &nbsp;
//                                                                 {folderNameErr}
//                                                             </span>
//                                                         </>
//                                                     )
//                                                 }
//                                             </>

//                                         )

//                                         : (

//                                             <>
//                                                 <label className='formLabelTitle required formLabelTitle ' htmlFor="">{inputText.folderName.label}</label>
//                                                 <div className='d-flex gap-2 '>
//                                                     <div className='w-100'>
//                                                         <SearchableDropdown
//                                                             InitialValue={folderId}
//                                                             // required={true}
//                                                             gettingData={(e) => setFolderId(e)}
//                                                             placeHolder={inputText.folderName.placeholder}
//                                                             options={folderList}
//                                                         />
//                                                     </div>
//                                                     <div>
//                                                         <button className='blueButton' onClick={() => setOpenCreateFolder(true)}>
//                                                             <i className="fa-solid fa-plus "></i>
//                                                         </button>
//                                                     </div>

//                                                 </div>
//                                                 <ErrorMsgShow errorMsg={folderIdErr} />
//                                             </>
//                                         )

//                                 }

//                                 {
//                                     // errPlanId &&

//                                     // <span className='formLabelTitle errMsg ' >
//                                     //     <i className="fa-solid fa-triangle-exclamation"></i>
//                                     //     &nbsp;
//                                     //     {/* {inputText.InsuranceEndDate.ErrMsg} */}
//                                     //     {errPlanId}
//                                     // </span>
//                                 }

//                             </div>

//                         </div>
//                         <div className='row mt-2 '>

//                             <div className='col-md-6 col-lg-4'>
//                                 <label className='formLabelTitle  formLabelTitle required' htmlFor="">{inputText.documentType.label}</label>
//                                 <SearchableDropdown
//                                     InitialValue={documentTypeId}
//                                     // required={true}
//                                     gettingData={(e) => setDocumentTypeId(e)}
//                                     placeHolder={inputText.documentType.placeholder}
//                                     options={documentTypeList}
//                                 />
//                                  <ErrorMsgShow errorMsg={documentTypeErr} />

//                                 {
//                                     // errEffectiveDate &&

//                                     // <span className='formLabelTitle errMsg'>
//                                     //     <i className="fa-solid fa-triangle-exclamation"></i>
//                                     //     &nbsp;
//                                     //     {errEffectiveDate}
//                                     // </span>
//                                 }

//                             </div>
//                             <div className='col-md-6 col-lg-4 '>
//                                 <label className='formLabelTitle  formLabelTitle' htmlFor="">{inputText.typesofAdvancedDirectives.label}</label>
//                                 <SearchableDropdown
//                                     InitialValue={advanceDirectiveId}
//                                     // required={true}
//                                     gettingData={(e) => setAdvanceDirectiveId(e)}
//                                     placeHolder={inputText.typesofAdvancedDirectives.placeholder}
//                                     options={advanceDirectiveList}
//                                 />

//                                 {
//                                     // errEndDate &&

//                                     // <span className='formLabelTitle errMsg ' >
//                                     //     <i className="fa-solid fa-triangle-exclamation"></i>
//                                     //     &nbsp;
//                                     //     {errEndDate}
//                                     // </span>
//                                 }

//                             </div>
//                             <div className='col-md-6 col-lg-4 '>
//                                 <label className='formLabelTitle  formLabelTitle' htmlFor="">{inputText.uploadedBy.label}</label>
//                                 <input
//                                     type="text"
//                                     disabled
//                                     // maxLength={15}
//                                     placeholder={inputText.uploadedBy.placeholder}
//                                     // onChange={getThePlanId}
//                                     value={uploadedBy}
//                                     className='form-control SearchInputbox' />
//                                 {
//                                     // errPlanId &&

//                                     // <span className='formLabelTitle errMsg ' >
//                                     //     <i className="fa-solid fa-triangle-exclamation"></i>
//                                     //     &nbsp;
//                                     //     {/* {inputText.InsuranceEndDate.ErrMsg} */}
//                                     //     {errPlanId}
//                                     // </span>
//                                 }

//                             </div>

//                         </div>
//                         <div className='row mt-2 '>

//                             <div className='col-md-6 col-lg-4'>
//                                 <label className='formLabelTitle formLabelTitle' htmlFor="">{inputText.status.label}</label>
//                                 <SearchableDropdown
//                                     InitialValue={status}
//                                     // required={true}
//                                     gettingData={(e) => setStatus(e)}
//                                     placeHolder={inputText.status.placeholder}
//                                     options={statusList}
//                                 />
//                                 {
//                                     // errLayerOfCoverage &&

//                                     // <span className='formLabelTitle errMsg'>
//                                     //     <i className="fa-solid fa-triangle-exclamation"></i>
//                                     //     &nbsp;
//                                     //     {inputText.layerofCoverage.ErrMsg}
//                                     // </span>
//                                 }
//                                 {
//                                     // alreadyExistInsuranceErr &&

//                                     // <span className='formLabelTitle errMsg'>
//                                     //     <i className="fa-solid fa-triangle-exclamation"></i>
//                                     //     &nbsp;
//                                     //     {alreadyExistInsuranceErr}
//                                     // </span>
//                                 }
//                             </div>
//                             <div className=' col-md-12 col-lg-4'>

//                                 <label htmlFor="" className='formLabelTitle d-flex align-items-center   required'>Attach File(s):</label>

//                                 {/* <input type="file" onChange={handleFrontAttachmentChange} accept="image/*" /> */}
//                                 {/* <input type="file" onChange={handleBackAttachmentChange} accept="image/*" /> */}
//                                 <div className=''>

//                                     <div  className={attachFileErr ? "uploadErrbtn  d-flex align-items-center justify-content-center" : ' uploadbtn  d-flex align-items-center justify-content-center formLabelTitle'} >
//                                         {attachFileName.length ?
//                                             <div className='w-100'>
//                                                 <label htmlFor="upload-photo1" style={{ color: "#0027FF", textDecoration: "underLine", cursor: "pointer" }}>
//                                                     browse
//                                                 </label>
//                                                 <div className='documentFilesContainer' style={{overflow :"hidden", maxHeight : '200px' , overflowY:"scroll" }}>

//                                                 {
//                                                     attachFileName?.map((i: any, index) => {

//                                                         let err = false;
//                                                         if(i.size >= maxSizeInBytes ) {
//                                                             err = true;
//                                                         }else{
//                                                             const fileExtension = i?.name?.toLowerCase()?.slice(i?.name?.lastIndexOf('.'));
//                                                             if(!allowedExtensions.includes(fileExtension)){
//                                                                 err = true;
//                                                             }
//                                                         }

//                                                         return (
//                                                             <div key={index} className={ "documentFiles my-2"} >
//                                                             <span className={err ? "errDocumentsFile" : "documentFileName"} >
//                                                                 {i?.name}
//                                                             </span>
//                                                                 <span className='documentFileCancelBtn' style={{cursor :"pointer" }} onClick={() => deleteTag(index)}>
//                                                                     &times;
//                                                                 </span>
//                                                             </div>

//                                                         )
//                                                     })

//                                                 }
//                                                 </div>

//                                             </div>
//                                             :
//                                             <div>
//                                                 <i className="fa fa-cloud-upload mx-2" aria-hidden="true"></i>
//                                                 Drop file to attach, or &nbsp;
//                                                 <label htmlFor="upload-photo1" style={{ color: "#0027FF", textDecoration: "underLine", cursor: "pointer" }}>
//                                                     browse
//                                                 </label>
//                                             </div>
//                                         }
//                                         <input
//                                             type="file"
//                                             onChange={handleAttachFile}
//                                             multiple
//                                             id='upload-photo1' accept="image/*" style={{ display: 'none' }} />
//                                     </div>
//                                         <ErrorMsgShow errorMsg={attachmentBasicErr} />

//                                 </div>
//                                 {/* <div className='col-md-2 col-lg-1 form-control' style={{ width: '50px', height: '50px' }}>
//                             <ModalImage
//                                 // small={att_front_image}
//                                 // large={att_front_image}
//                                 alt=""
//                                 hideDownload={true} // Optionally hide download button
//                                 hideZoom={true}     // Optionally hide zoom button
//                             />
//                         </div> */}

//                             </div>
//                             <div className=' col-md-12 col-lg-4 py-7 '>
//                                 <div className='d-flex align-item-center gap-2'>

//                                     <input
//                                         type="checkbox"
//                                         className='my-1'
//                                         name=""
//                                         checked={sendToReview}
//                                         onChange={() => setSendToReview(!sendToReview)}
//                                         id="stdfr" />

//                                     <label htmlFor='stdfr'>Send To Do for Review</label>

//                                 </div>
//                             </div>

//                         </div>
//                     </div>

//                     <div className='dialoge d-flex justify-content-end gap-2 p-2'>
//                         {
//                             loading ?
//                                 <div className='loader'></div>
//                                 :
//                                 <>
//                                     {

//                                         !openCreateFolder && (
//                                             <button
//                                                 className='primarybtnBig'
//                                                 type='submit'
//                                             >
//                                                 Save
//                                             </button>
//                                         )

//                                     }

//                                 </>

//                         }

//                         <button
//                             className='tertiarybig'
//                             onClick={handelClose}
//                         >
//                             Close
//                         </button>
//                     </div>

//                 </Modal.Body>

//             </form>

//             <Modal.Footer className='dialoge p-1'>

//             </Modal.Footer>

//         </Modal>
//     )
// }

// export default AddNewDocument

import React, {useEffect, useRef, useState} from 'react';
import inputText from '../../../../ReUsableComponent/ReyClinicalinputs.json';
import TagsInput from '../../../../ReUsableComponent/TagsInput';
import {Modal} from 'react-bootstrap';
import SearchableDropdown from '../../ReusablePages/ReusableAppointment/SearchableDropdown';
import {groupIdRegExp} from '../../../../ReUsableComponent/regEx';
import ModalImage from 'react-modal-image';
import iIcon from '../../EHRIcon/infoIcon.svg';
import axios from 'axios';
import {toast} from 'react-toastify';
import ErrorMsgShow from '../../../../ReUsableComponent/ErrorMsgShow';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import TitleComponent from '../../../../ReUsableComponent/TitleComponent';
import { CommonPopupButton } from '../../../../SuperAdmin/Pages/ReausableComponent/CommonButton';

function AddNewDocument({open, closeDocumentPopUp, patientData, disabled}) {
  const APIkey = process.env.REACT_APP_SERVERAPI;

  const {appointmentId} = useParams();

  const inputRef = useRef(null);

  const maxSizeInBytes = 10 * 1024 * 1024;
  const allowedExtensions = ['.jpeg', '.jpg', '.png'];
  const fileLimit = null;

  const [loading, setLoading] = useState<any>(false);
  const [isFileFieldVisible, setIsFileFieldVisible] = useState(false);
  const [clinicId, setClinicId] = useState<any>();
  const [provideName, setProviderName] = useState<any>([]);
  const [mrn, setMrn] = useState();

  const [attachFile, setAttachFile] = useState('');
  const [attachFileName, setAttachFileName] = useState<any>([]);

  const [attachFileErr, setAttachFileErr] = useState('');

  const [patientId, setPatientId] = useState<any>(patientData?._id);
  const [patientName, setPatientName] = useState<any>();
  const [patientList, setPatientList] = useState<any>();
  const [providerList, setProviderList] = useState<any>();

  const [visitDate, setVisitDate] = useState<any>();
  const [visitTime, setVisitTime] = useState<any>();
  const [documentName, setDocumentName] = useState<any>();
  const [description, setDescription] = useState<any>();
  const [documentTags, setDocumentTags] = useState<any>([]);
  const [folderList, setFolderList] = useState<any>([]);

  const [documentTypeList, setDocumentTypeList] = useState<any>([]);

  const [advanceDirectiveList, setAdvanceDirectiveList] = useState([]);

  const [statusList, setStatusList] = useState([]);

  const [folderId, setFolderId] = useState<any>();

  const [providerId, setProviderId] = useState<any>();
  const [documentTypeId, setDocumentTypeId] = useState<any>();
  const [advanceDirectiveId, setAdvanceDirectiveId] = useState<any>();
  const [status, setStatus] = useState<any>();
  const [attachment, setAttachment] = useState<any>([]);

  const [sendToReview, setSendToReview] = useState<any>(false);

  const [uploadedBy, setUploadedBy] = useState<any>();

  const [openCreateFolder, setOpenCreateFolder] = useState<any>(false);
  const [folderName, setFolderName] = useState<any>();

  const [documentNameErr, setDocumentNameErr] = useState<any>();
  const [folderIdErr, setFolderIdErr] = useState<any>();
  const [patientNameErr, setPatientNameErr] = useState<any>();
  const [attachmentBasicErr, setAttachmentBasicErr] = useState<any>();

  const [folderNameErr, setFolderNameErr] = useState<any>();

  const [documentTypeErr, setDocumentTypeErr] = useState<any>();

  useEffect(() => {
    if (documentTypeId === '66a0ce6ad8f32da37de2a94b') {
      setIsFileFieldVisible(true);
    } else {
      setIsFileFieldVisible(false);
    }
  }, [documentTypeId]);

  useEffect(() => {
    const getloginInfo = localStorage.getItem('appLoginInfo');

    if (getloginInfo !== null) {
      const loginInfo = JSON.parse(getloginInfo);
      const Token = loginInfo.data.accessToken;
      const Id = loginInfo.data.clinicId;
      const userId = loginInfo.data.id;
      const header = {
        headers: {'x-access-token': Token},
      };

      setClinicId(Id);

      // Call each API function
      fetchFolderList(Id, header);
      fetchDocumentTypeList(Id, header);
      fetchAdvanceDirectiveList(Id, header);
      fetchDocumentStatusList(Id, header);
      fetchUploadedBy(Id, userId, header);

      if (disabled) {
        setPatientName(`${patientData?.firstName} ${patientData?.lastName}`);
        setMrn(patientData?.mrn);
        console.log('appointment Id : ', typeof appointmentId);
        if (appointmentId != 'null') {
          fetchAppointmentDataById(appointmentId, header);
        }
      } else {
        fetchPatientsBy(Id, header);
        fetchProvidersBy(Id, header);
      }
    } else {
      console.log('No login info found in localStorage');
    }
  }, [openCreateFolder]);

  // Define each API call as a separate function
  const fetchFolderList = async (Id, header) => {
    try {
      const response = await axios.get(`${APIkey}/api/admin/getFolderList`, header);
      setFolderList(
        response?.data?.data?.map((i) => ({
          label: i.folderName,
          value: i.folderName,
          id: i._id,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };
  const fetchPatientsBy = async (Id, header) => {
    try {
      const response = await axios.get(`${APIkey}/api/admin/getAllPatients`, header);
      console.log('patient: ', response?.data?.data.patient_list);

      setPatientList(
        response?.data?.data.patient_list.map((i) => ({
          label: `${i.doc.firstName} ${i.doc.lastName}`,
          value: `${i.doc.firstName} ${i.doc.lastName}`,
          mrn: i.doc.mrn,
          id: i._id,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };
  const fetchProvidersBy = async (Id, header) => {
    try {
      const response = await axios.get(`${APIkey}/api/admin/listUsersByClinicId/${Id}`, header);
      console.log('provider:', response?.data?.data);

      setProviderList(
        response?.data?.data?.users.map((i) => ({
          label: `${i.firstName} ${i.lastName}`,
          value: `${i.firstName} ${i.lastName}`,
          id: i._id,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDocumentTypeList = async (Id, header) => {
    try {
      const response = await axios.get(`${APIkey}/api/admin/getDocumentTypeList`, header);
      setDocumentTypeList(
        response?.data?.data?.map((i) => ({
          label: i.documentTypeName,
          value: i.documentTypeName,
          id: i._id,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAdvanceDirectiveList = async (Id, header) => {
    try {
      const response = await axios.get(`${APIkey}/api/admin/getAdvanceDirectiveList`, header);
      setAdvanceDirectiveList(
        response?.data?.data?.map((i) => ({
          label: i.advanceDirectiveName,
          value: i.advanceDirectiveName,
          id: i._id,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDocumentStatusList = async (Id, header) => {
    try {
      const response = await axios.get(`${APIkey}/api/admin/getDocumentStatusList`, header);
      setStatusList(
        response?.data?.data?.map((i) => ({
          label: i.documentStatusName,
          value: i.documentStatusName,
          id: i._id,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const fetchUploadedBy = async (Id, userId, header) => {
    try {
      const response = await axios.get(`${APIkey}/api/admin/getUserById/${Id}/${userId}`, header);
      const uploadedBy = `${response?.data?.data?.firstName} ${response?.data?.data?.lastName}`;
      setUploadedBy(uploadedBy);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAppointmentDataById = async (appointmentId, header) => {
    try {
      const response = await axios.get(
        `${APIkey}/api/admin/getAppointmentById/${appointmentId}`,
        header
      );
      const appointmentData = response?.data?.data;
      console.log(appointmentData);

      if (appointmentData) {
        setVisitDate(moment(appointmentData?.appointmentDate).format('YYYY-MM-DD'));
        setVisitTime(appointmentData?.appointmentTime);
        setProviderName([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handelClose = () => {
    closeDocumentPopUp();
  };

  const addFolder = async (data) => {
    try {
      const getloginInfo: string | null = localStorage.getItem('appLoginInfo');

      if (getloginInfo !== null) {
        const Token = JSON.parse(getloginInfo).data.accessToken;
        const header = {
          headers: {'x-access-token': Token},
        };

        console.log(header);

        const response = await axios.post(`${APIkey}/api/admin/createFolder`, data, header);
        console.log('this is response ', response);

        if (response) {
          setOpenCreateFolder(false);

          toast.success('Folder added successfully!', {
            position: 'top-right',
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const submitFolderName = (e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();

      let data = {
        folderName,
      };

      if (!folderName || folderName.trim() === '') {
        setFolderNameErr('Please enter a folder name');
      } else {
        addFolder(data);
      }
    }
  };

  const handleAttachFile = (e) => {
    const files = e.target.files;
    const fileArray: any = [];

    const filesName: any = [];

    // Loop through each selected file
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();

      reader.onload = () => {
        // Convert the file to base64 and push it to the fileArray
        fileArray.push(
          // name: files[i],
          reader.result
        );

        filesName.push(files[i]);

        // If all files are processed, you can now use the fileArray
        if (fileArray.length === files.length) {
          setAttachment([...attachment, ...fileArray]);

          setAttachFileName([...attachFileName, ...filesName]);

          // Here, you can do whatever you want with the fileArray
        }
      };

      // Read the selected file as data URL
      reader.readAsDataURL(files[i]);
    }
  };

  const deleteTag = (index) => {
    setAttachment(attachment.filter((_, i) => i !== index));
    setAttachFileName(attachFileName.filter((_, i) => i !== index));
  };

  const validation = (value: any) => {
    const error: any = {};

    if (!value.documentName || value.documentName.trim() == '') {
      error.documentName = inputText.documentName.ErrMsg;
    }

    if (!value.patientId) {
      error.patientId = inputText.patientName.ErrMsg;
    }
    // if (value.planId) {
    //     if (!groupIdRegExp.test(value.planId)) {
    //         error.planId = inputText.PlanID.validErrMsg;
    //     }
    // }

    if (!value.folderId) {
      error.folderId = inputText.folderName.ErrMsg;
    }

    // if (!value.layerOfCoverage) {
    //     error.layerOfCoverage = inputText.layerofCoverage.ErrMsg;
    // }

    if (!value.documentTypeId) {
      error.documentTypeId = inputText.documentType.ErrMsg;
    }

    if (!value.attachment.length) {
      error.attachment = 'Please enter attachment(s)';
    } else {
      if (fileLimit && value?.attachment?.length >= fileLimit) {
        error.attachment = `${fileLimit} attachment(s) Limit`;
      }
    }
    // if (value.attachments_front) {
    //     if (value.attachments_front.size > maxSizeInBytes) {
    //         error.attachments_front = inputText.UploadInsuranceCard.ErrMsg
    //     }
    //     else {
    //         const allowedExtensions = ['.jpeg', '.jpg', '.png'];
    //         const fileExtension = value.attachments_front.name.toLowerCase().slice(value.attachments_front.name.lastIndexOf('.'));
    //         if (!allowedExtensions.includes(fileExtension)) {
    //             error.attachments_front = 'Please upload a valid file type (.jpeg, .jpg, or .png)';
    //         }
    //     }
    // }

    // if (value.attachments_back) {
    //     if (value.attachments_back.size > maxSizeInBytes) {
    //         error.attachments_back = inputText.UploadInsuranceCard.ErrMsg
    //     }
    //     else {
    //         const allowedExtensions = ['.jpeg', '.jpg', '.png'];
    //         const fileExtension = value.attachments_back.name.toLowerCase().slice(value.attachments_back.name.lastIndexOf('.'));
    //         if (!allowedExtensions.includes(fileExtension)) {
    //             error.attachments_back = 'Please upload a valid file type (.jpeg, .jpg, or .png)';
    //         }
    //     }
    // }

    return error;
  };

  const submitData = async () => {
    let data = {
      patientId,
      visitDate,
      visitTime,
      providerId,
      documentName,
      description,
      documentTags,
      folderId,
      documentTypeId,
      advanceDirectiveId,
      status,
      attachment,
      sendToReview,
    };
    console.log('data in submission', data);
    try {
      const getloginInfo: string | null = localStorage.getItem('appLoginInfo');

      if (getloginInfo !== null) {
        const Token = JSON.parse(getloginInfo).data.accessToken;
        const header = {
          headers: {'x-access-token': Token},
        };

        const response = await axios.post(
          `${APIkey}/api/admin/createPatientDocuments`,
          data,
          header
        );
        console.log('this is response ', response);

        if (response) {
          handelClose();

          setLoading(false);

          toast.success('Document added successfully!', {
            position: 'top-right',
          });
        }
      }
    } catch (err) {
      console.log('error occur : ', err);
      setLoading(false);
    }
  };

  const createDocument = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    let data = {
      patientId,
      visitDate,
      visitTime,
      providerId,
      documentName,
      description,
      documentTags,
      folderId,
      documentTypeId,
      advanceDirectiveId,
      status,
      attachment,
      sendToReview,
    };

    console.log('data:', data);

    let err = validation(data);

    console.log('error', err);

    if (Object.keys(err).length === 0) {
      console.log('in valid');
      submitData();

      setDocumentNameErr(err.documentName);
      setFolderIdErr(err.folderId);
      setPatientNameErr(err.patientId);
      setAttachmentBasicErr(err.attachment);
      setDocumentTypeErr(err.documentTypeId);
    } else {
      setLoading(false);

      setDocumentNameErr(err.documentName);
      setFolderIdErr(err.folderId);
      setPatientNameErr(err.patientId);
      setAttachmentBasicErr(err.attachment);
      setDocumentTypeErr(err.documentTypeId);
    }
  };

  const getMrn = (id) => {
    setMrn(patientList.find((item) => item.id == id).mrn);
  };

  return (
    <Modal show={open} onHide={handelClose} className='' size='xl' centered>
      <Modal.Header closeButton style={{background: '#F2F3F7'}} className='py-1 px-3'>
        <Modal.Title className='px-0'>
        <TitleComponent type='title' className=' px-1'>Add New Documents</TitleComponent>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={createDocument} className=''>
        <Modal.Body className=' dialoge p-1'>
          <div className='card p-3'>
            <div className='row'>
              <div className='col-md-6 col-lg-4 '>
              <TitleComponent type='label' className='required px-1'>
                  {inputText.patientName.label}
                </TitleComponent>
                {disabled ? (
                  <input
                    type='text'
                    value={patientName}
                    // onChange={getTheBin}
                    disabled={disabled}
                    placeholder={inputText.patientName.placeholder}
                    className='form-control SearchInputbox'
                  />
                ) : (
                  <SearchableDropdown
                    options={patientList}
                    InitialValue={patientId}
                    gettingData={(id) => [setPatientId(id), getMrn(id)]}
                  />
                )}
                <ErrorMsgShow errorMsg={patientNameErr} />
              </div>
              <div className='col-md-6 col-lg-4'>
                <TitleComponent type='label' className='required px-1'>
                  {inputText.mrn.label}
                </TitleComponent>
                <input
                  type='number'
                  disabled
                  value={mrn}
                  // onChange={getTheBin}
                  placeholder={inputText.mrn.placeholder}
                  className='form-control SearchInputbox'
                />
              </div>
              <div className='col-md-6 col-lg-4 '>
              <TitleComponent type='label' className=' px-1'>
                  {inputText.visitDate.label}
                </TitleComponent>
                <input
                  type='date'
                  disabled={disabled ? true : false}
                  value={visitDate}
                  // value  ={pcn}
                  // onChange={getThePcn}
                  placeholder={inputText.visitDate.placeholder}
                  className='form-control SearchInputbox'
                />
              </div>
            </div>
            <div className='row mt-2 '>
              <div className='col-md-6 col-lg-4'>
              <TitleComponent type='label' className='px-1'>
                  {inputText.visitTime.label}
                </TitleComponent>
                <input
                  type='time'
                  disabled={disabled}
                  value={visitTime}
                  placeholder={inputText.visitTime.placeholder}
                  // onChange={getTheInsurancePlan}
                  className='form-control SearchInputbox '
                />
                {
                  // errInsurancePlanName &&
                  // <span className='formLabelTitle errMsg'>
                  //     <i className="fa-solid fa-triangle-exclamation"></i>
                  //     &nbsp;
                  //     {/* {inputText.InsurancePlaneName.ErrMsg} */}
                  //     {errInsurancePlanName}
                  // </span>
                }
              </div>
              <div className='col-md-6 col-lg-4 '>
              <TitleComponent type='label' className='px-1'>
                  {inputText.Provider.label}
                </TitleComponent>
                {disabled ? (
                  <input
                    type='text'
                    disabled
                    value={provideName}
                    className='form-control SearchInputbox '
                  />
                ) : (
                  <SearchableDropdown
                    InitialValue={providerId}
                    gettingData={(id) => [setProviderId(id)]}
                    placeHolder={inputText.Provider.placeholder}
                    options={providerList}
                  />
                )}
              </div>
              <div className='col-md-6 col-lg-4 '>
              <TitleComponent type='label' className='required px-1'>
                  {inputText.documentName.label}
                </TitleComponent>
                <input
                  // maxLength={10}
                  type='text'
                  onChange={(e) => setDocumentName(e.target.value)}
                  maxLength={100}
                  // value={patientPersonCode}
                  placeholder={inputText.documentName.placeholder}
                  className='form-control SearchInputbox'
                />
                <ErrorMsgShow errorMsg={documentNameErr} />
              </div>
            </div>
            <div className='row mt-2 '>
              <div className='col-md-6 col-lg-4'>
              <TitleComponent type='label' className='px-1'>
                  {inputText.description.label}
                </TitleComponent>
                <input
                  type='text'
                  maxLength={500}
                  // value={insuranceEffectiveDate}
                  placeholder={inputText.description.placeholder}
                  onChange={(e) => setDescription(e.target.value)}
                  className='form-control SearchInputbox '
                />

                {
                  // errEffectiveDate &&
                  // <span className='formLabelTitle errMsg'>
                  //     <i className="fa-solid fa-triangle-exclamation"></i>
                  //     &nbsp;
                  //     {errEffectiveDate}
                  // </span>
                }
              </div>
              <div className='col-md-6 col-lg-4 '>
              <TitleComponent type='label' className=' px-1'>
                  {inputText.documentTags.label}
                </TitleComponent>
                <TagsInput
                  duplicate={false}
                  max={5}
                  tagsValue={documentTags}
                  getTheTags={(e) => setDocumentTags(e)}
                />
                {
                  // errEndDate &&
                  // <span className='formLabelTitle errMsg ' >
                  //     <i className="fa-solid fa-triangle-exclamation"></i>
                  //     &nbsp;
                  //     {errEndDate}
                  // </span>
                }
              </div>
              <div className='col-md-6 col-lg-4 '>
                {openCreateFolder ? (
                  <>
                  <TitleComponent type='label' className=' px-1'>
                      Create New Folder:
                    </TitleComponent>
                    <div className='d-flex gap-2 '>
                      <input
                        autoFocus
                        ref={inputRef}
                        onBlur={() => [setOpenCreateFolder(false), setFolderNameErr('')]}
                        onChange={(e) => setFolderName(e.target.value)}
                        onKeyDown={submitFolderName}
                        type='text'
                        className='form-control SearchInputbox'
                      />
                    </div>
                    {folderNameErr && (
                      <>
                        <span className='formLabelTitle errMsg'>
                          <i className='fa-solid fa-triangle-exclamation'></i>
                          &nbsp;
                          {folderNameErr}
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  <>
                  <TitleComponent type='label' className='required px-1'>
                      {inputText.folderName.label}
                    </TitleComponent>
                    <div className='d-flex gap-2 '>
                      <div className='w-100'>
                        <SearchableDropdown
                          InitialValue={folderId}
                          // required={true}
                          gettingData={(e) => setFolderId(e)}
                          placeHolder={inputText.folderName.placeholder}
                          options={folderList}
                        />
                      </div>
                      <div>
                        <CommonPopupButton color='#00A3FF' background='#BFE6FC'  onClick={() => setOpenCreateFolder(true)}>
                          <i className='fa-solid fa-plus'></i>
                        </CommonPopupButton>
                      </div>
                    </div>
                    <ErrorMsgShow errorMsg={folderIdErr} />
                  </>
                )}

                {
                  // errPlanId &&
                  // <span className='formLabelTitle errMsg ' >
                  //     <i className="fa-solid fa-triangle-exclamation"></i>
                  //     &nbsp;
                  //     {/* {inputText.InsuranceEndDate.ErrMsg} */}
                  //     {errPlanId}
                  // </span>
                }
              </div>
            </div>
            <div className='row mt-2 '>
              <div className='col-md-6 col-lg-4'>
              <TitleComponent type='label' className='required px-1'>
                  {inputText.documentType.label}
                </TitleComponent>
                <SearchableDropdown
                  InitialValue={documentTypeId}
                  // required={true}
                  gettingData={(e) => setDocumentTypeId(e)}
                  placeHolder={inputText.documentType.placeholder}
                  options={documentTypeList}
                />
                <ErrorMsgShow errorMsg={documentTypeErr} />

                {
                  // errEffectiveDate &&
                  // <span className='formLabelTitle errMsg'>
                  //     <i className="fa-solid fa-triangle-exclamation"></i>
                  //     &nbsp;
                  //     {errEffectiveDate}
                  // </span>
                }
              </div>
              <div className='col-md-6 col-lg-4 '>
              <TitleComponent type='label' className=' px-1'>
                  {inputText.typesofAdvancedDirectives.label}
                </TitleComponent>
                <SearchableDropdown
                  InitialValue={advanceDirectiveId}
                  // required={true}
                  gettingData={(e) => setAdvanceDirectiveId(e)}
                  placeHolder={inputText.typesofAdvancedDirectives.placeholder}
                  options={advanceDirectiveList}
                />

                {
                  // errEndDate &&
                  // <span className='formLabelTitle errMsg ' >
                  //     <i className="fa-solid fa-triangle-exclamation"></i>
                  //     &nbsp;
                  //     {errEndDate}
                  // </span>
                }
              </div>
              <div className='col-md-6 col-lg-4 '>
              <TitleComponent type='label' className='px-1'>
                  {inputText.uploadedBy.label}
                </TitleComponent>
                <input
                  type='text'
                  disabled
                  // maxLength={15}
                  placeholder={inputText.uploadedBy.placeholder}
                  // onChange={getThePlanId}
                  value={uploadedBy}
                  className='form-control SearchInputbox'
                />
                {
                  // errPlanId &&
                  // <span className='formLabelTitle errMsg ' >
                  //     <i className="fa-solid fa-triangle-exclamation"></i>
                  //     &nbsp;
                  //     {/* {inputText.InsuranceEndDate.ErrMsg} */}
                  //     {errPlanId}
                  // </span>
                }
              </div>
            </div>
            <div className='row mt-2 '>
              <div className='col-md-6 col-lg-4'>
              <TitleComponent type='label' className=' px-1'>
                  {inputText.status.label}
                </TitleComponent>
                <SearchableDropdown
                  InitialValue={status}
                  // required={true}
                  gettingData={(e) => setStatus(e)}
                  placeHolder={inputText.status.placeholder}
                  options={statusList}
                />
                {
                  // errLayerOfCoverage &&
                  // <span className='formLabelTitle errMsg'>
                  //     <i className="fa-solid fa-triangle-exclamation"></i>
                  //     &nbsp;
                  //     {inputText.layerofCoverage.ErrMsg}
                  // </span>
                }
                {
                  // alreadyExistInsuranceErr &&
                  // <span className='formLabelTitle errMsg'>
                  //     <i className="fa-solid fa-triangle-exclamation"></i>
                  //     &nbsp;
                  //     {alreadyExistInsuranceErr}
                  // </span>
                }
              </div>
              <div className=' col-md-12 col-lg-4'>
              <TitleComponent type='label' className='required px-1'>
                  Attach File(s):
                </TitleComponent>

                {/* <input type="file" onChange={handleFrontAttachmentChange} accept="image/*" /> */}
                {/* <input type="file" onChange={handleBackAttachmentChange} accept="image/*" /> */}
                <div className=''>
                  <div
                    className={
                      attachFileErr
                        ? 'uploadErrbtn  d-flex align-items-center justify-content-center'
                        : ' uploadbtn  d-flex align-items-center justify-content-center formLabelTitle'
                    }
                  >
                    {attachFileName.length ? (
                      <div className='w-100'>
                        <label
                          htmlFor='upload-photo1'
                          style={{color: '#0027FF', textDecoration: 'underLine', cursor: 'pointer'}}
                        >
                          browse
                        </label>
                        <div
                          className='documentFilesContainer'
                          style={{overflow: 'hidden', maxHeight: '200px', overflowY: 'scroll'}}
                        >
                          {attachFileName?.map((i: any, index) => {
                            let err = false;
                            if (i.size >= maxSizeInBytes) {
                              err = true;
                            } else {
                              const fileExtension = i?.name
                                ?.toLowerCase()
                                ?.slice(i?.name?.lastIndexOf('.'));
                              if (!allowedExtensions.includes(fileExtension)) {
                                err = true;
                              }
                            }

                            return (
                              <div key={index} className={'documentFiles my-2'}>
                                <span className={err ? 'errDocumentsFile' : 'documentFileName'}>
                                  {i?.name}
                                </span>
                                <span
                                  className='documentFileCancelBtn'
                                  style={{cursor: 'pointer'}}
                                  onClick={() => deleteTag(index)}
                                >
                                  &times;
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <i className='fa fa-cloud-upload mx-2' aria-hidden='true'></i>
                        Drop file to attach, or &nbsp;
                        <label
                          htmlFor='upload-photo1'
                          style={{color: '#0027FF', textDecoration: 'underLine', cursor: 'pointer'}}
                        >
                          browse
                        </label>
                      </div>
                    )}
                    <input
                      type='file'
                      onChange={handleAttachFile}
                      multiple
                      id='upload-photo1'
                      accept='image/*'
                      style={{display: 'none'}}
                    />
                  </div>
                  <ErrorMsgShow errorMsg={attachmentBasicErr} />
                </div>
                {/* <div className='col-md-2 col-lg-1 form-control' style={{ width: '50px', height: '50px' }}>
                            <ModalImage
                                // small={att_front_image}
                                // large={att_front_image}
                                alt=""
                                hideDownload={true} // Optionally hide download button
                                hideZoom={true}     // Optionally hide zoom button
                            />
                        </div> */}
              </div>
              <div className=' col-md-12 col-lg-4 py-7 '>
                <div className='d-flex align-item-center gap-2'>
                  <input
                    type='checkbox'
                    className='my-1'
                    name=''
                    checked={sendToReview}
                    onChange={() => setSendToReview(!sendToReview)}
                    id='stdfr'
                  />

                  <label htmlFor='stdfr'><TitleComponent type='label' > Send To Do for Review</TitleComponent></label>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </form>

      <Modal.Footer className='p-2 dialoge'>
        <div className='d-flex gap-2 '>
          <CommonPopupButton type='submit' onClick={submitData}>
            Save
          </CommonPopupButton>
          <CommonPopupButton color='#818181' background='#D7D7D7' onClick={handelClose}>
            Close
          </CommonPopupButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default AddNewDocument;
