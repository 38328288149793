import { breakpoints, stylesConfig } from './breakpoints';

// Get the current screen size and return the exact style for that breakpoint
export const getResponsiveStyles = (screenWidth, type) => {
  let currentBreakpoint;

  // Determine which breakpoint the screen size fits into
  if (screenWidth >= breakpoints.large.min) {
    currentBreakpoint = 'large';
  } else if (screenWidth >= breakpoints.laptop.min) {
    currentBreakpoint = 'laptop';
  } else if (screenWidth >= breakpoints.tablet.min) {
    currentBreakpoint = 'tablet';
  } else {
    currentBreakpoint = 'phone';
  }

  // Return the exact style configuration for the current breakpoint
  const currentStyle = stylesConfig[type][currentBreakpoint];

  return {
    fontSize: `${currentStyle.fontSize}px`,
    fontWeight: currentStyle.fontWeight,
    lineHeight: `${currentStyle.lineHeight}px`,
  };
};
