/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
// import {useAuth} from '../../../../app/modules/auth'
// import {Languages} from './Languages'
// import {toAbsoluteUrl} from '../../../helpers'

const HeaderUserMenu: FC = () => {
  // const {currentUser, logout} = useAuth()


  const  logout = ()=> {
    
    localStorage.removeItem('appLoginInfo');
    window.location.href = "/login";
    // console.log("cascxas");
    //  navigate('/Admin')
   }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-175px'
      data-kt-menu='true'
    >
    
      <div className='menu-item '>
        <Link to={''} className='menu-link profileColour px-5'>
        Purchases
        </Link>
      </div>
      <div className='menu-item '>
        <Link to={'/clinicalSetting/accountInfo'} className='menu-link profileColour px-5 '>
        Clinical Setting
        </Link>
      </div>
      <div className='menu-item '>
        <Link to={''} className='menu-link px-5'>
        Help
        </Link>
      </div>

      

      <div className='separator '></div>

      

      <div className='menu-item '>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
