import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import CommonTable from '../../../ReUsableComponent/CommonTable';
import DeleteModel from '../../../ReUsableComponent/DeleteModel';
// import deletcs from '../EHRIcon/deletcs.svg';
// import searchiconwhite from '../EHRIcon/searchiconwhite.svg';
// import editcs from '../EHRIcon/editcs.svg';
import EditCsCalender from './EditCsCalender';
import tablecolor from '../../../ReUsableComponent/ReyClinicalinputs.json'

const APIkey = process.env.REACT_APP_SERVERAPI;

const CsCalendar = () => {
  const [calendardata, setcalendarData] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState<string | null>(null);
  const [editItemId, setEditItemId] = useState<string | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  
  // Input state
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [occasion, setOccasion] = useState('');
  const [notes, setNotes] = useState('');

  // Fetch calendar data
  const fetchUserList = async () => {
    try {
      const getLoginInfo = localStorage.getItem('appLoginInfo');
      if (getLoginInfo) {
        const { accessToken, clinicId } = JSON.parse(getLoginInfo).data;
        const { data } = await axios.get(`${APIkey}/api/admin/getCalenderList`, {
          headers: { 'x-access-token': accessToken },
        });

        const formatDate = (dateString) => {
          const date = new Date(dateString);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = date.getFullYear();
          return `${day}/${month}/${year}`;
        };

        const formatDateRange = (startDate, endDate) => {
          const fromDate = formatDate(startDate);
          const toDate = formatDate(endDate);
          return `${fromDate} - ${toDate}`;
        };

        const calculateNumberOfDays = (from, to) => {
          const fromDate = new Date(from);
          const toDate = new Date(to);
          const timeDiff = toDate.getTime() - fromDate.getTime();
          return Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 1;
        };

        const processedData = data?.data.map((item) => {
          const combinedDateRange = formatDateRange(item.from, item.to);
          const numberOfDays = calculateNumberOfDays(item.from, item.to);
          return { ...item, combinedDateRange, numberOfDays };
        });

        setcalendarData(processedData || []);
        setTotalCount(processedData?.length || 0);
      }
    } catch (error) {
      toast.error('Failed to load calendar data');
    }
  };

  useEffect(() => {
    fetchUserList();
  }, [showEditModal]);

  const handleDelete = async (id: string) => {
    try {
      const getLoginInfo = localStorage.getItem('appLoginInfo');
      if (getLoginInfo) {
        const { accessToken, clinicId } = JSON.parse(getLoginInfo).data;
        await axios.delete(`${APIkey}/api/admin/deleteCalendar/${id}`, {
          headers: { 'x-access-token': accessToken },
        });
        toast.success('Calendar entry deleted successfully');
        setcalendarData(calendardata.filter((item: any) => item._id !== id));
        setIsDeleteModalOpen(false);
      }
    } catch (error) {
      toast.error('Failed to delete calendar entry');
    }
  };

  const handleEdit = (id) => {
    setEditItemId(id);
    setShowEditModal(true);
  };

  const addCalendarData = async () => {
    try {
      const getLoginInfo = localStorage.getItem('appLoginInfo');
      if (getLoginInfo) {
        const { accessToken, clinicId } = JSON.parse(getLoginInfo).data;
        const headers = { 'x-access-token': accessToken };

        const calendarEntry = {
          from: fromDate,
          to: toDate,
          occasion,
          notes,
          clinicId,
        };

        const response = await axios.post(
          `${APIkey}/api/admin/createCalender`,
          calendarEntry,
          { headers }
        );

        const newEntry = response.data;
        setcalendarData([...calendardata, newEntry]);
        toast.success('Calendar entry added successfully');

        // Clear input fields
        setFromDate('');
        setToDate('');
        setOccasion('');
        setNotes('');

        // Refetch data
        fetchUserList();
      } else {
        console.log('No login info found in local storage');
      }
    } catch (error) {
      console.log('Error adding calendar data:', error);
      toast.error('Failed to add calendar entry');
    }
  };

  const handleClearClick = () => {
    setFromDate('');
    setToDate('');
    setOccasion('');
    setNotes('');
  };

  const columns = [
    { key: ['combinedDateRange'], header: 'Date Range' },
    { key: ['numberOfDays'], header: 'No. Of Days' },
    { key: ['occasion'], header: 'Occasion' },
    { key: ['notes'], header: 'Notes' },
    {
      key: [''],
      header: 'Action',
      isAction: true,
      ActionComponent: ({ id }: { id: string }) => (
        <div>
          <img
            // src={editcs}
            className='px-2'
            alt='Edit'
            onClick={() => handleEdit(id)}
          />
          <img
            // src={deletcs}
            className='px-2'
            alt='Delete'
            onClick={() => {
              setDeleteItemId(id);
              setIsDeleteModalOpen(true);
            }}
          />
        </div>
      ),
    },
  ];

  const handleCloseModal = () => {
    setShowEditModal(false);
  };

  return (
    <div className='p-3 clinical-time-container' style={{ backgroundColor: '#FFFFFF' }}>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <div className='clinicNavTitle'>Calendar</div>
      </div>
      <div className='d-flex col-md-12 gap-2 mb-3'>
        <div className='col-md-2.5'>
          <label className='form-label' style={{ color: '#464E5F', fontSize: '14px', fontWeight: '500' }}>
            Add holiday when Clinic is closed
          </label>
        </div>
        <div className='col-md-2 d-flex'>
          <label className='form-label' style={{ color: '#5E6278', fontWeight: '600', fontSize: '12px' }}>
            From
          </label>
          <input type='date' className='SearchInputbox' placeholder='MM/DD/YYYY' />
        </div>
        <div className='col-md-2 d-flex'>
          <label className='form-label' style={{ color: '#5E6278', fontWeight: '600', fontSize: '12px' }}>
            To
          </label>
          <input type='date' className='SearchInputbox' placeholder='MM/DD/YYYY' />
        </div>
        <div className='col-md-2'>
          <input type='text' className='SearchInputbox' placeholder='Occasion' />
        </div>
        <div className='col-md-2'>
          <input type='text' className='SearchInputbox' placeholder='Notes' />
        </div>
        <div className='col-md-1'>
          <button
            type='button'
            className='placeholdfont'
            value={searchQuery}
            style={{
              backgroundColor: '#eb2226',
              border: 'none',
              padding: '3px 5px',
              color: '#FFFFFF',
              cursor: 'pointer',
              borderRadius: '3px',
            }}
          >
            <img 
            // src={searchiconwhite} 
            alt='search icon' />
            Search
          </button>
        </div>
      </div>
      <div className='clinical-time-container'>
        <CommonTable
          data={calendardata}
          totalCount={totalCount}
          perPage={perPage}
          setPerPage={setPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          columns={columns}
          setQuery={setQuery}
          headerColor={tablecolor.primaryTable.color}
                issetPaginationCompo = {false}
                isPagelength={false}
                isSearched={false}
        />
      </div>

    <DeleteModel
      openDelete={isDeleteModalOpen}
      closedelete={() => setIsDeleteModalOpen(false)}
      deleteID={deleteItemId}
      deleteFunction={handleDelete}
      deletedEntity="calendar entry"
    />

    <EditCsCalender
      onShow={showEditModal}
      onHide={handleCloseModal}
      editId={editItemId}
    />
  </div>
);
};

export default CsCalendar;
